import styled, { css } from 'styled-components';
import { BadgeProps } from './types';

interface StyledBadgeProps extends BadgeProps {
  circular?: boolean;
}

export const StyledBadge = styled.div<StyledBadgeProps>`
  background-color: ${({ theme }) => theme.colors.neutral[900]};
  color: ${({ theme }) => theme.colors.neutral[50]};
  display: inline flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: ${({ theme }) => theme.spacing[5]};
  width: ${({ circular, theme }) => (circular ? theme.spacing[5] : 'auto')};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: ${({ theme }) => theme.fontFamily.sans};
  padding: 0
    ${({ circular, theme }) => (circular ? theme.spacing[0] : theme.spacing[3])};
  font-weight: lighter;
  border-radius: ${({ theme }) => theme.spacing[2.5]};

  ${({ color, colorStep = '50', backgroundColorStep = '500' }) =>
    color === 'primary' &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.primary[backgroundColorStep]};
      color: ${({ theme }) => theme.colors.neutral[colorStep]};
    `}

  ${({ color, colorStep = '600', backgroundColorStep = '200' }) =>
    color === 'gray' &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.neutral[backgroundColorStep]};
      color: ${({ theme }) => theme.colors.neutral[colorStep]};
    `}

  ${({ color, colorStep = '50', backgroundColorStep = '500' }) =>
    color === 'danger' &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.danger[backgroundColorStep]};
      color: ${({ theme }) => theme.colors.neutral[colorStep]};
    `}

  ${({ color, colorStep = '50', backgroundColorStep = '400' }) =>
    color === 'secondary' &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.secondary[backgroundColorStep]};
      color: ${({ theme }) => theme.colors.neutral[colorStep]};
    `}
`;
