import React from 'react';

import * as S from './TaxpayerDropdown.styles';
import Avatar from '@src/components/ui/Avatar';
import { Check } from '@src/components/ui/Icon/outline';

type TapTaxPayerItemProps = {
  imageUrl?: string;
  name?: string;
  selected?: boolean;
  strong?: boolean;
};

function TaxpayerItem(props: TapTaxPayerItemProps) {
  const { imageUrl, name, selected, strong } = props;
  return (
    <S.TaxPayerOption strong={strong}>
      <div className="taxpayer-avatar">
        <Avatar size="sm" src={imageUrl} />
      </div>
      <p>{name}</p>
      {selected && <Check size={18} />}
    </S.TaxPayerOption>
  );
}

export default TaxpayerItem;
