import { gql } from '@apollo/client';

export const EXPORT_INVOICES_MUTATION = gql`
  mutation ExportInvoices(
    $businessId: ID!
    $invoiceIds: [ID!]
    $search: String
    $businessEmitterId: [ID!]
  ) {
    exportInvoices(
      businessId: $businessId
      invoiceIds: $invoiceIds
      search: $search
      businessEmitterId: $businessEmitterId
    ) {
      id
    }
  }
`;

export type ExportInvoicesVariables = {
  businessId: string;
  invoiceIds?: Array<string>;
  businessEmitterId?: Array<string>;
  search?: string;
};

export type ExportInvoicesReturn = {
  exportInvoices: {
    id;
  };
};

export default EXPORT_INVOICES_MUTATION;
