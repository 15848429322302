import React, { useState } from 'react';

import { Tooltip } from '@src/components/ui';
import { Star as StarOutline } from '@src/components/ui/Icon/outline';
import { Star as StarSolid } from '@src/components/ui/Icon/solid';
import * as S from './StarButton.styles';

function StarButton({
  active,
  onActivate,
  onDeactivate,
  TooltipProps,
  ...otherProps
}) {
  const [featuredClassName, setFeaturedClassName] = useState('');
  const handleActivate = (event) => {
    setFeaturedClassName('featured-heartbeat');
    onActivate(event);
  };

  return active ? (
    <S.Button
      type="button"
      className={featuredClassName}
      onClick={onDeactivate}
      color="warning"
      {...otherProps}
    >
      <StarSolid />
    </S.Button>
  ) : (
    <Tooltip place="bottom" effect="solid" {...TooltipProps}>
      <S.Button onClick={handleActivate} color="neutral">
        <StarOutline />
      </S.Button>
    </Tooltip>
  );
}

export default StarButton;
