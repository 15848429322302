import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabPanel } from 'react-tabs';

import * as S from './TransactionsTable.styles';
import Table from '@src/components/shared/TransactionsTable';
import useTransactionsListQuery from '@src/hooks/useTransactionsListQuery';
import useSettings from '@src/hooks/useSettings';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import useQueryParams from '@src/hooks/useQueryParams';
import StatusFilter from '@src/components/shared/TransactionsTable/components/StatusFilter';
import DateFilter from '@src/components/shared/TransactionsTable/components/DateFilter';
import SuppliersFilter from '@src/components/shared/TransactionsTable/components/SuppliersFilter';
import useSortFilters from '@src/hooks/useSortFilters';
import { Tab, TabList } from '@src/components/shared/Tabs';
import { trackPageView } from '@src/lib/analytics';
import {
  Clock as ClockOutline,
  Transaction as TransactionOutline,
} from '@src/components/ui/Icon/outline';
import { Clock as ClockSolid } from '@src/components/ui/Icon/solid';
import Card from '@src/components/ui/Card';
import HistoricalTransactionsTable from './HistoricalTransactions';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { Grid } from '@src/components/shared/layouts';

enum TransactionsTab {
  Pending = 0,
  Historial = 1,
}

const limit = RESULTS_PER_PAGE;

function TransactionsTable() {
  const [
    {
      tabIndex,
      search,
      status,
      providerId,
      createdAtTo,
      createdAtFrom,
      before,
      after,
      first,
      last,
      page,
    },
    setParams,
  ] = useQueryParams();

  const [sortBy, handleSort, sortParams] = useSortFilters();
  const business = useSettings('business');
  const {
    loading: pendingLoading,
    data: pendingData,
    count: pendingCount,
    next: pendingNext,
    hasNext: pendingHasNext,
    previous: pendingPrevious,
    hasPrevious: pendingHasPrevious,
    page: pendingPage,
    resetPaginationParams,
  } = useTransactionsListQuery({
    businessId: business?.id,
    search,
    status,
    providerId,
    createdAtFrom,
    createdAtTo,
    sortBy,
    pending: true,
    ...getPaginationValuesForQuery({ first, last, page, before, after }, limit),
  });
  const {
    loading: allLoading,
    data: allData,
    count: allCount,
    next: allNext,
    hasNext: allHasNext,
    previous: allPrevious,
    hasPrevious: allHasPrevious,
    page: allPage,
    nodes: allNodes,
  } = useTransactionsListQuery({
    businessId: business?.id,
    search,
    status,
    providerId,
    createdAtFrom,
    createdAtTo,
    sortBy,
    pending: false,
    ...getPaginationValuesForQuery({ first, last, page, before, after }, limit),
  });

  useEffect(() => {
    if (!pendingLoading && !allLoading && pendingCount === 0 && allCount > 0) {
      setParams({ tabIndex: TransactionsTab.Historial });
    }
  }, [pendingLoading, allLoading]);

  const selectedTabIndex = parseInt(tabIndex) || 0;

  const handleTabSelect = (index: number) => {
    resetPaginationParams({
      status: undefined,
      providerId: undefined,
      createdAtTo: undefined,
      createdAtFrom: undefined,
      tabIndex: index,
    });
  };

  return (
    <Card>
      <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
        <TabList>
          <Tab
            defaultIcon={<ClockOutline />}
            selectedIcon={<ClockSolid />}
            onClick={() => {
              trackPageView('transactions_pending', 'core');
            }}
          >
            <FormattedMessage
              defaultMessage="Pendientes ({count})"
              id="PB0a2c"
              values={{ count: pendingCount }}
            />
          </Tab>
          <Tab
            defaultIcon={<TransactionOutline />}
            onClick={() => {
              trackPageView('transactions_all', 'core');
            }}
          >
            <FormattedMessage
              defaultMessage="Historial ({count})"
              id="XmJq8/"
              values={{ count: allCount }}
            />
          </Tab>
        </TabList>
        <S.Content>
          <S.TableActions>
            <Grid container spacing={2}>
              <Grid item xs={4} md={3}>
                <SuppliersFilter
                  value={providerId}
                  onChange={resetPaginationParams}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <DateFilter
                  value={{ start: createdAtFrom, end: createdAtTo }}
                  onChange={resetPaginationParams}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <StatusFilter value={status} onChange={resetPaginationParams} />
              </Grid>
            </Grid>
          </S.TableActions>
          <TabPanel>
            <Table
              columns={{
                transactionIdentifier: 50,
                hashId: 80,
                createdAt: 100,
                recipient: 100,
                invoice: 80,
                providerContact: 100,
                transactionType: 100,
                amount: 80,
                sendEmail: 120,
              }}
              limit={limit}
              page={pendingPage}
              data={pendingData}
              loading={pendingLoading}
              count={pendingCount}
              hasNext={pendingHasNext}
              onNext={pendingNext}
              sort={sortParams}
              hasPrevious={pendingHasPrevious}
              onPrevious={pendingPrevious}
              onSort={handleSort}
            />
          </TabPanel>
          <TabPanel>
            <HistoricalTransactionsTable
              data={allData}
              nodes={allNodes}
              loading={allLoading}
              count={allCount}
              next={allNext}
              hasNext={allHasNext}
              previous={allPrevious}
              hasPrevious={allHasPrevious}
              page={allPage}
            />
          </TabPanel>
        </S.Content>
      </Tabs>
    </Card>
  );
}

export default TransactionsTable;
