import 'intl';

import enMessages from '@src/translations/locales/en.json';
import esMessages from '@src/translations/locales/es.json';

// load polyfill data in case Intl does not exist
if (!window.Intl) {
  import(
    /* webpackChunkName: 'intlPolyfillLocaleData.[request]' */
    'intl/locale-data/jsonp/es.js'
  );
  import(
    /* webpackChunkName: 'intlPolyfillLocaleData.[request]' */
    'intl/locale-data/jsonp/en.js'
  );
}

export const SUPPORTED_LOCALES = process.env.REACT_APP_SUPPORTED_LOCALES || [
  'es',
  'es-MX',
  'es-PE',
  'en',
];

export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || 'es';

export const MESSAGES = {
  es: esMessages,
  en: enMessages,
};

export class I18N {
  static getLanguageFromLocale(locale: string): string {
    const regionCodeSeparator = /[_-]+/;
    const [language] = locale.split(regionCodeSeparator);
    return language;
  }

  static getLocale(locale?: string): string {
    if (!locale) {
      const browserLanguage =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        //@ts-ignore
        navigator.userLanguage;

      return SUPPORTED_LOCALES.includes(browserLanguage)
        ? browserLanguage
        : DEFAULT_LOCALE;
    }

    if (SUPPORTED_LOCALES.includes(locale)) {
      return locale;
    } else {
      const language = I18N.getLanguageFromLocale(locale);
      return SUPPORTED_LOCALES.includes(language) ? language : DEFAULT_LOCALE;
    }
  }

  static getMessages(locale: string) {
    const language = I18N.getLanguageFromLocale(locale);

    return MESSAGES[locale] || MESSAGES[language] || MESSAGES[DEFAULT_LOCALE];
  }

  static getTimeZone(timeZone?: string): string {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timeZone || browserTimeZone;
  }
}
