/* eslint-disable react/style-prop-object */
import React, { Fragment } from 'react';
import { FormattedNumber, FormattedMessage, IntlProvider } from 'react-intl';

import { FormattedNumberParts } from 'react-intl';

import { withSettings } from '@src/components/SettingsProvider';

import { Business } from '@src/types/models';

export const DEFAULT_CURRENCY = 'MXN';

type CurrencyProps = {
  style?: any;
  currencyDisplay?: any;
  value: number;
  maximumFractionDigits?: number;
  valueInDecimal?: boolean;
  business?: Business;
  currency?: string;
  showMissingPriceLabel?: boolean;
  smallDecimals?: boolean;
  textComponent?: React.ComponentType | keyof React.ReactHTML;
};

export function Currency({
  value,
  business,
  valueInDecimal = true,
  smallDecimals = false,
  currency,
  showMissingPriceLabel,
  textComponent,
  maximumFractionDigits = 5,
  ...rest
}: CurrencyProps) {
  if (typeof value === 'string') valueInDecimal = false;
  const businessCurrency = business?.setting?.currencyCode;

  if (showMissingPriceLabel && value === 0) {
    return <FormattedMessage defaultMessage="Por confirmar" id="tezccW" />;
  }

  return smallDecimals ? (
    <IntlProvider locale="es-mx" textComponent={textComponent || Fragment}>
      <FormattedNumberParts
        value={valueInDecimal ? value / 100 : value}
        currency={currency || businessCurrency || DEFAULT_CURRENCY}
      >
        {(parts) => {
          let integer = '';
          let fraction = '';

          parts.forEach((part) => {
            if (part.type === 'decimal' || part.type === 'fraction') {
              fraction += part.value;
            } else {
              integer += part.value;
            }
          });

          return (
            <>
              <span>
                <small>$ </small>
                {integer}
                <small>{fraction !== '' ? fraction : '.00'}</small>
              </span>
            </>
          );
        }}
      </FormattedNumberParts>
    </IntlProvider>
  ) : (
    <IntlProvider locale="es-mx" textComponent={textComponent || Fragment}>
      <FormattedNumber
        style="currency"
        currencyDisplay="symbol"
        value={valueInDecimal ? value / 100 : value}
        currency={currency || businessCurrency || DEFAULT_CURRENCY}
        {...rest}
        maximumFractionDigits={maximumFractionDigits}
      />
    </IntlProvider>
  );
}

export default withSettings(Currency);
