import styled from 'styled-components';

import PageUI from '@src/components/shared/Page';

export const Page = styled(PageUI)`
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.neutral[50]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    margin: 0;
    flex-direction: column;
  }
`;

export const Nav = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
  position: fixed;
  top: 0;
  width: 100%;
`;
