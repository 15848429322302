import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as S from './CreateUser.styles';
import CreateUserForm from './components/CreateUserForm';
import { loggedIn } from '@src/lib/auth';
import usePageTrack from '@src/hooks/usePageTrack';
import useQueryParams from '@src/hooks/useQueryParams';
import Header from '@src/components/ui/Header';
// import { HIGO_PAY_TOKEN_STATUS } from '@src/graphql/queries';
import OnboardingLayout from '@src/components/layouts/OnboardingLayout';

export const CreateUserContainer = () => {
  usePageTrack('user_registration', 'sign_up');
  const intl = useIntl();
  const { search } = useLocation();
  const [query] = useQueryParams();
  const isPayeeFlow = !!query?.token;

  // const { data, loading } = useQuery(HIGO_PAY_TOKEN_STATUS, {
  //   skip: !isPayeeFlow,
  //   variables: {
  //     token: query?.token,
  //   },
  // });
  //
  // if (!data?.valid && !loading) {
  //   return <Redirect to={`/p/invalid_token`} />;
  // }

  if (loggedIn()) {
    let newPath = `/${search}`;
    if (query.redirectTo) {
      newPath = `${query.redirectTo}`;
    }
    return <Redirect to={newPath} />;
  }

  return (
    <OnboardingLayout
      step={1}
      title={intl.formatMessage({
        defaultMessage: 'Crear cuenta',
        id: '4sjoNr',
      })}
      isPayeeFlow={isPayeeFlow}
    >
      <Header as="h2">
        {isPayeeFlow
          ? intl.formatMessage({
              defaultMessage: 'Crea tu cuenta para disponer de tu dinero.',
              id: 'BpmCZ0',
            })
          : intl.formatMessage({
              defaultMessage: 'Comienza Ahora',
              id: 'eGznVz',
            })}
      </Header>
      <S.Legend>
        {isPayeeFlow
          ? intl.formatMessage({
              defaultMessage:
                'Tienes un pago pendiente por aceptar en Higo. Crea tu cuenta para disponer de tu dinero.',
              id: 'wKK3UG',
            })
          : intl.formatMessage({
              defaultMessage:
                'Estás a punto de olvidarte para siempre de tus problemas de liquidez y optimizar tu flujo de efectivo. Tan sencillo como comenzar a utilizar Higo.',
              id: 'EFnYsl',
            })}
      </S.Legend>
      <CreateUserForm />
    </OnboardingLayout>
  );
};

export default CreateUserContainer;
