import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Select } from '@src/components/ui';
import useProviderOptionsQuery from '@src/hooks/useProviderOptionsQuery';
import useSettings from '@src/hooks/useSettings';

type SupplierRelationshipsSelectProps = {
  value: string;
  error: boolean;
  isDisabled: boolean;
  onChange?: (value: string) => void;
};

function SupplierRelationshipsSelect(
  { value, onChange, ...props }: SupplierRelationshipsSelectProps,
  ref
) {
  const intl = useIntl();
  const { business } = useSettings();

  const { data, loading } = useProviderOptionsQuery({
    variables: {
      businessId: business?.id,
    },
    fetchPolicy: 'cache-first',
  });

  const supplierBusinessRelationshipsOptions = useMemo(
    () =>
      data.map((businessRelationship) => ({
        label: businessRelationship.supplier.name,
        value: businessRelationship.id,
      })),
    [data]
  );

  const handleChange = (option: { label: string; value: string }) => {
    onChange?.(option?.value);
  };

  const selected = supplierBusinessRelationshipsOptions.find(
    (option) => option.value === value
  );

  return (
    <Select
      ref={ref}
      isClearable
      value={selected}
      placeholder={intl.formatMessage({
        id: 'ni/RGJ',
        defaultMessage: 'Seleccionar proveedor',
      })}
      isLoading={loading}
      options={supplierBusinessRelationshipsOptions}
      onChange={handleChange}
      {...props}
    />
  );
}

export default forwardRef(SupplierRelationshipsSelect);
