import { gql } from '@apollo/client';
import { PaginatedResponse } from '@src/types/utils';

export const TAX_PAYER_INFOS_QUERY_NAME = 'TaxPayerInfos';

const TAX_PAYER_INFOS_QUERY = gql`
  query TaxPayerInfos($businessId: ID!) {
    taxPayerInfos(businessId: $businessId) {
      edges {
        node {
          id
          taxpayerIdentifier
          taxpayerName
          hasSatPassword
          satSyncStatus
          satUpdatedAt
        }
      }
    }
  }
`;

type TaxPayerInfo = {
  id: string;
  taxpayerIdentifier: string;
  taxpayerName: string;
  hasSatPassword: string;
  satSyncStatus: string;
  satUpdatedAt: string;
};

export type TaxPayerInfosQueryReturn = {
  taxPayerInfos: PaginatedResponse<TaxPayerInfo>;
};

export type TaxPayerInfosQueryVariables = {
  businessId?: string;
};

export default TAX_PAYER_INFOS_QUERY;
