import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as S from './DeclineCreditCard.styles';
import Button from '@src/components/ui/Button';
import Header from '@src/components/ui/Header';
import { HelpCircle } from '@src/components/ui/Icon/outline';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';

import { ReactComponent as Art } from './img/artboard.svg';

export const DeclinedCreditCard = () => {
  const openCustomerSupporChat = useCustomerSupportChat();

  return (
    <S.StyledCard
      padding="8"
      borderColor="primary"
      backgroundColor="primary"
      backgroundColorStep="50"
    >
      <Header as="h5" color="primary" colorStep="800">
        <FormattedMessage
          defaultMessage="Accede a financiamiento aumentando tus pagos en Higo"
          id="W3tTj1"
        />
      </Header>
      <p>
        <FormattedMessage
          defaultMessage="Queremos conocerte mejor. Paga a tus proveedores a traves de Higo para mejorar tu historial crediticio y acceder a las mejores tasas de crédito."
          id="JTlaXq"
        />
      </p>
      <Button
        startIcon={<HelpCircle />}
        variant="outlined"
        onClick={openCustomerSupporChat}
      >
        <FormattedMessage defaultMessage="Conoce más" id="uEjhR4" />
      </Button>
      <Art className="bottom-art" />
    </S.StyledCard>
  );
};

export default DeclinedCreditCard;
