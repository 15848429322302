import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PENDING: {
    defaultMessage: 'Por aprobar',
    id: 'djFdu3',
  },
  APPROVED: {
    defaultMessage: 'Aprobada',
    id: 'qQozp2',
  },
  REJECTED: {
    defaultMessage: 'Rechazada',
    id: 'DzzVj2',
  },
  FINANCE_REQUESTED: {
    defaultMessage: 'Financiamiento solicitado',
    id: 'KiXOgZ',
  },
});

export default messages;
