import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryParams from '@src/hooks/useQueryParams';
import * as qs from '@src/utils/querystring';

import type {
  DefaultSort,
  SortParams,
  Sort,
  SortDirection,
} from '@src/types/utils';

type SortTuple = [Sort, (key: string) => void, SortParams];

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

function useSortFilters(defaultSort?: DefaultSort): SortTuple {
  const location = useLocation();
  const [query] = useQueryParams();
  const history = useHistory();

  const sort = useMemo(() => {
    const { sortDirection, sort } = query;

    let direction: SortDirection = 'ASC';
    let field: string;

    if (!sort && !defaultSort) {
      return {};
    }

    if (!sort && defaultSort) {
      direction = defaultSort.direction === 'ascending' ? 'ASC' : 'DESC';
      field = camelToSnakeCase(defaultSort.field).toUpperCase();
      return { field, direction };
    }

    if (sortDirection) {
      direction = sortDirection === 'ascending' ? 'ASC' : 'DESC';
    }

    return {
      field: camelToSnakeCase(`${sort}`).toUpperCase(),
      direction,
    };
  }, [location, defaultSort, query]);

  const params = { sortDirection: query.sortDirection, sort: query.sort };

  const setSort = useCallback(
    (key: string) => {
      const newQuery = { ...query };

      if (key !== query.sort) {
        newQuery.sort = key;
      }
      switch (query.sortDirection) {
        case 'ascending':
          newQuery.sortDirection = 'descending';
          break;
        case 'descending':
          delete newQuery.sort;
          delete newQuery.sortDirection;
          break;
        default:
          newQuery.sortDirection = 'ascending';
          break;
      }

      history.push(`${location.pathname}${qs.stringify(newQuery)}`);
    },
    [query, location, history]
  );

  return [sort, setSort, params];
}

export default useSortFilters;
