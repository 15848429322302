import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tag } from '@src/components/ui';
import EditButton from './components/EditButton';

import { BusinessUser } from '@src/types/models';
import { AttributesObject } from '@src/types/resourceList';

const attributes: AttributesObject<BusinessUser> = {
  name: {
    name: <FormattedMessage defaultMessage="Nombre" id="hCOqfl" />,
    render: (datum) => `${datum.firstName} ${datum.lastName}`,
  },
  email: {
    name: <FormattedMessage defaultMessage="Correo" id="otXL2t" />,
    render: (datum) => datum.email,
  },
  status: {
    name: <FormattedMessage defaultMessage="Estatus" id="dWihL7" />,
    render: (datum) =>
      datum.status === 'ACTIVE' ? (
        <Tag
          compact
          color="success"
          label={<FormattedMessage defaultMessage="Activo" id="/61g0d" />}
        />
      ) : (
        <Tag
          compact
          color="warning"
          label={<FormattedMessage defaultMessage="Pendiente" id="uZ+xW0" />}
        />
      ),
  },
  actions: {
    name: '',
    render: (datum) => <EditButton businessUser={datum} />,
  },
};

export default attributes;
