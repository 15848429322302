import React from 'react';
import { useIntl } from 'react-intl';

import Checkbox from '@src/components/ui/Checkbox';
import { TERMS_URL, PRIVACY_POLICY_URL } from '@src/constants/publicUrls';
import * as S from './TermsCheckbox.styles';

interface TermsCheckboxProps {
  onChange?: (event: React.SyntheticEvent) => void;
  checked?: boolean;
  acceptPrivacy?: boolean;
  label?: string | React.ReactNode;
}

export function TermsLink() {
  const intl = useIntl();
  return (
    <a href={TERMS_URL} target="_blank" rel="noreferrer">
      {intl.formatMessage({
        defaultMessage: 'Términos y Condiciones',
        id: 'VkHZxm',
      })}
    </a>
  );
}

export function PrivacyPolicyLink() {
  const intl = useIntl();
  return (
    <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
      {intl.formatMessage({
        defaultMessage: 'Política de Privacidad',
        id: 'U7xlBy',
      })}
    </a>
  );
}

function TermsCheckbox({
  onChange,
  checked,
  label,
  acceptPrivacy,
  ...otherProps
}: TermsCheckboxProps) {
  const intl = useIntl();
  return (
    <S.Terms>
      <Checkbox
        checked={checked}
        onChange={onChange}
        id="terms"
        label={
          <p>
            {label && `${label} `}
            <TermsLink />
            {acceptPrivacy &&
              ` ${intl.formatMessage({
                defaultMessage: 'y la',
                id: 'RED+Xk',
              })} `}
            {acceptPrivacy && <PrivacyPolicyLink />}
          </p>
        }
        {...otherProps}
      />
    </S.Terms>
  );
}

export default TermsCheckbox;
