import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Alert } from '@src/components/ui';

function STPPaymentTimeAlert() {
  const intl = useIntl();
  const isDelayTime = useMemo(() => {
    const now = new Date();
    const minutes = now.getMinutes();
    const hour = now.getHours();
    return (hour === 17 && minutes >= 45) || (hour === 18 && minutes <= 35);
  }, []);

  return isDelayTime ? (
    <Alert color="warning" hasBorder>
      {intl.formatMessage({
        defaultMessage:
          'Los pagos entre las 5:50PM Y 6:30PM pueden tardar hasta 40 minutos en ser procesados.',
        id: '+g9cE5',
      })}
    </Alert>
  ) : null;
}

export default STPPaymentTimeAlert;
