import React, { useEffect, useState } from 'react';
import posthog from 'posthog-js';

import { isPosthogEnabled } from '@src/lib/analytics';

export enum FeatureFlag {
  REQUEST_CLABE = 'request-clabe',
  AUTOMATIC_FINANCING_FUND = 'automatic-financing-fund',
  INVOICES_CACHE_OPTIMIZATION = 'invoices-cache-optimization',
  CREDIT_PAGE = 'credit-page',
  PAYMENTS_WITHOUT_INVOICE = 'payments-without-invoice',
}

const useFeatureFlag = (name: FeatureFlag) => {
  const [enabled, setEnabled] = useState(
    isPosthogEnabled && posthog.isFeatureEnabled(name)
  );

  useEffect(() => {
    if (isPosthogEnabled) {
      posthog.onFeatureFlags((_, flags) => {
        setEnabled(Boolean(flags[name]));
      });
    }
  }, [name]);

  return enabled;
};

export default useFeatureFlag;
