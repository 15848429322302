import { UserRole, PermissionName, BusinessActsAs } from '@src/types/enums';
import { CurrentUser, Business } from '@src/types/models';

export function hasPermission(
  permissions: Array<keyof typeof PermissionName>,
  minimumRoles: Array<keyof typeof UserRole> = [],
  currentUser?: CurrentUser
) {
  if (!currentUser) {
    return false;
  }

  const { role } = currentUser;

  if (
    minimumRoles.length &&
    minimumRoles.filter((minimumRole) => minimumRole === role).length === 0
  ) {
    return false;
  }

  if (role === UserRole.ADMIN) {
    return true;
  }

  const splitPermission = (permission: string) => {
    if (!permission) {
      return [];
    }
    const permissionParts = permission.split('_');
    const permissionAction = permissionParts[0];
    const permissionResource = permissionParts.slice(1).join('_');
    return [permissionAction, permissionResource];
  };

  const permissionParts = permissions.map((permit) => splitPermission(permit));
  const currentUserPermissions = (currentUser && currentUser.permissions) || [];

  const userPermissions = currentUserPermissions.filter((userPermission) => {
    const userPermit = permissionParts.filter(
      (permit) => splitPermission(userPermission.name)[1] === permit[1]
    );

    return userPermit.length > 0;
  });

  if (userPermissions.length === 0) {
    return false;
  }

  return (
    userPermissions.filter((userPermission) => {
      const permissionAction = splitPermission(userPermission.name)[0];
      return (
        permissionParts.filter(
          (permit) =>
            permissionAction === permit[0] || permissionAction === 'MANAGE'
        ).length > 0
      );
    }).length >= 1
  );
}

export function actsAs(
  business: Business,
  actsAs: keyof typeof BusinessActsAs
) {
  if (!business) {
    return null;
  }
  return business.actsAs === actsAs || business.actsAs === BusinessActsAs.BOTH;
}
