import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Flag: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path d="M8 1C6.52717 1 5.45661 1.17455 4.69816 1.42569C4.31886 1.55128 4.00134 1.70142 3.74686 1.87107C3.51126 2.02814 3.25674 2.25046 3.10557 2.55279C3.03615 2.69164 3 2.84475 3 3V15V22C3 22.5523 3.44772 23 4 23C4.55228 23 5 22.5523 5 22V15.5879C5.56482 15.3201 6.49162 15 7.5 15C9.29861 15 10.4007 15.438 11.5494 15.9216L11.6872 15.9798C12.8365 16.465 14.1038 17 16 17C17.1038 17 18.1548 16.8172 18.9622 16.5865C19.3651 16.4714 19.729 16.3382 20.0207 16.1974C20.1651 16.1277 20.3126 16.0462 20.4446 15.9516C20.5486 15.8772 20.7612 15.7136 20.8944 15.4472C20.9639 15.3084 21 15.1552 21 15V3C21 2.59554 20.7564 2.2309 20.3827 2.07612C20.009 1.92134 19.578 2.00735 19.292 2.29335C19.2817 2.30171 19.2247 2.34808 19.0978 2.4164C18.9555 2.493 18.7495 2.58419 18.4733 2.6716C17.922 2.84605 17.1081 3 16 3C14.7631 3 13.8342 2.60865 12.663 2.11521L12.5756 2.07836C11.3679 1.5699 9.96184 1 8 1ZM19.28 14.3257L19.2755 14.3288C19.2877 14.3188 19.2908 14.318 19.28 14.3257Z" />
      </svg>
    );
  }
);

Flag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Flag.displayName = 'Flag';

export default Flag;
