import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { trackEvent } from '@src/lib/analytics';
import { RESET_PASSWORD } from '@src/constants/events';
import UpdatePasswordForm from '@src/components/shared/form/UpdatePasswordForm';
import MinimalLayout from '@src/components/layouts/MinimalLayout';
import { UPDATE_PASSWORD_MUTATION } from '@src/graphql/mutations';
import useToast from '@src/hooks/useToast';
import { PASSWORD_TOKEN_QUERY } from '@src/graphql/queries';
import { UpdatePasswordFormData } from '@src/components/shared/form/UpdatePasswordForm/UpdatePasswordForm';
import * as S from './UpdatePassword.styles';
import { Header } from '@src/components/ui';

function UpdatePassword() {
  const intl = useIntl();
  const { token } = useParams<{ token: string }>();
  const { data, loading, error } = useQuery(PASSWORD_TOKEN_QUERY, {
    skip: !token,
    variables: {
      token,
    },
  });
  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION);
  const { toast } = useToast();
  const history = useHistory();

  if (error) {
    toast.error(
      intl.formatMessage({
        defaultMessage: 'Error',
        id: 'KN7zKn',
      }),
      intl.formatMessage({
        defaultMessage:
          'El link para restablecer contraseña ya expiró o es invalido',
        id: '/YIP1Y',
      })
    );
    return <Redirect to="/p/login" />;
  }

  if (!loading && !data?.passwordToken) {
    return <Redirect to="/p/login" />;
  }

  const handleSubmit = async ({ newPassword }: UpdatePasswordFormData) => {
    if (!token) {
      return;
    }

    try {
      await updatePassword({
        variables: {
          token,
          password: newPassword,
        },
      });

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Contraseña actualizada',
          id: '8wR3lj',
        }),
        intl.formatMessage({
          defaultMessage:
            'Tu contraseña ha sido actualizada, puedes iniciar sesión nuevamente.',
          id: '2g1UbI',
        })
      );

      history.push('/p/login');

      trackEvent(RESET_PASSWORD);
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error',
          id: 'KN7zKn',
        }),
        e.message
      );
    }
  };

  return (
    <MinimalLayout
      title={intl.formatMessage({
        defaultMessage: 'Cambiar contraseña',
        id: 'BHSyD0',
      })}
    >
      <S.Container>
        <S.FormContainer>
          <Header as="h2">
            {intl.formatMessage({
              defaultMessage: 'Nueva contraseña',
              id: 'Or996K',
            })}
          </Header>
          <S.Legend>
            {intl.formatMessage({
              defaultMessage:
                'Crea una nueva contraseña para ingresar a tu cuenta de Higo.',
              id: 'drBAiv',
            })}
          </S.Legend>
          <UpdatePasswordForm loading={loading} onSubmit={handleSubmit} />
        </S.FormContainer>
      </S.Container>
    </MinimalLayout>
  );
}

export default UpdatePassword;
