import { PaymentInstructionInvoiceStatus } from '@src/types/enums';
import { PaymentInstructionInvoice } from '@src/types/models';

const filterPaymentInstructions = (
  paymentInstructionInvoices: PaymentInstructionInvoice[]
) => {
  return paymentInstructionInvoices.filter(
    (instructionInvoice) =>
      ![
        PaymentInstructionInvoiceStatus.COMPLETED,
        PaymentInstructionInvoiceStatus.REJECTED,
      ].includes(instructionInvoice.status)
  );
};

export default filterPaymentInstructions;
