import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { add, format } from 'date-fns';
import BigNumber from 'bignumber.js';

import * as S from './WithdrawFromCreditModal.styles';
import TextField from '@src/components/ui/TextField';
import { trackEvent } from '@src/lib/analytics';
import {
  WITHDRAW_FROM_CREDIT_REQUESTED,
  WITHDRAW_FROM_CREDIT_STARTED,
} from '@src/constants/events';
import Button from '@src/components/ui/Button';
import { Currency } from '@src/components/i18n/Currency';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import {
  centsToFloat,
  centsToString,
  floatToCents,
  stringToCents,
} from '@src/utils/currency';
import {
  CenteredFlexContainer,
  FlexContainer,
  Spacer,
} from '@src/components/shared/layouts';
import Text from '@src/components/ui/Text';
import { Divider, Header } from '@src/components/ui';
import CreditInterestTag from '@src/components/shared/tag/CreditInterestTag';
import useSettings from '@src/hooks/useSettings';
import WalletDropdown from '@src/components/shared/dropdown/WalletDropdown';
import { DATE_FORMAT } from '@src/constants/date';
import { CREDIT_TERM_DAYS } from '@src/constants/credit';
import useToast from '@src/hooks/useToast';
import useCreditAccount from '@src/hooks/useCreditAccount';
import { useMutation } from '@apollo/client';
import {
  CreateFinancingRequestMutationReturn,
  CreateFinancingRequestMutationVariables,
  CREATE_FINANCING_REQUEST,
} from '@src/graphql/mutations/createFinancingRequest';
import {
  CREDIT_ACCOUNT_QUERY,
  FINANCING_REQUESTS_QUERY,
} from '@src/graphql/queries';
import WALLET_QUERY from '@src/graphql/queries/wallet';
import TermsCheckbox from '@src/components/shared/TermsCheckbox';

type CreditOption = {
  value: number;
  days: number;
  label: string;
};

interface WithdrawFromCreditModalProps {
  amountCents?: number;
  onClose?: () => void;
}

function WithdrawFromCreditModal({
  amountCents = 0,
  onClose,
}: WithdrawFromCreditModalProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const [creditAccount] = useCreditAccount();
  const { toast } = useToast();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [createFinancingRequest, { loading }] = useMutation<
    CreateFinancingRequestMutationReturn,
    CreateFinancingRequestMutationVariables
  >(CREATE_FINANCING_REQUEST, {
    refetchQueries: [
      CREDIT_ACCOUNT_QUERY,
      WALLET_QUERY,
      FINANCING_REQUESTS_QUERY,
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      trackEvent(WITHDRAW_FROM_CREDIT_REQUESTED);
      onClose?.();
      toast.success(
        intl.formatMessage(
          {
            id: 'SqoIPA',
            defaultMessage:
              'Se han enviado {amount} de tu crédito a tu saldo disponible',
          },
          {
            amount: `$${centsToString(data.createFinancingRequest.amount)}`,
          }
        )
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          id: 's/wT28',
          defaultMessage: 'Error al intentar transferir crédito',
        }),
        e.message
      );
    },
  });

  const availableCreditTerms = creditAccount?.creditTermsJson || [];
  const creditOptions: Array<CreditOption> = useMemo(
    () =>
      availableCreditTerms
        .filter(({ days }) => CREDIT_TERM_DAYS.includes(days))
        .map((option) => ({
          value: option.days,
          days: option.days,
          label: intl.formatMessage(
            {
              id: 'aNRWjR',
              defaultMessage: '{days} días',
            },
            {
              days: option.days,
            }
          ),
        })),
    [availableCreditTerms]
  );

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      amount: centsToFloat(amountCents),
      creditDays: creditOptions?.[0],
    },
  });

  const inputAmount = watch('amount', 0) || 0;
  const creditLimitCents = creditAccount?.creditLimitCents || 0;
  const owedAmountCents = creditAccount?.owedAmountCents || 0;
  const remainingCreditCents =
    creditLimitCents - owedAmountCents - stringToCents(inputAmount);

  const creditDays = watch('creditDays');
  const paymentDate = add(new Date(), {
    days: creditDays.value,
  });

  const annualInterestRate = creditAccount?.annualInterestRate || 0;
  const ivaTax = creditAccount?.ivaTax || 0;
  const daysInterestRate = new BigNumber(annualInterestRate)
    .dividedBy(365)
    .multipliedBy(creditDays.value);
  const interestAmount = daysInterestRate
    .multipliedBy(inputAmount)
    .multipliedBy(100)
    .toNumber();
  const ivaInterestAmount = new BigNumber(interestAmount)
    .multipliedBy(ivaTax)
    .toNumber();
  const totalInterest = interestAmount + ivaInterestAmount;

  const onSubmit = async (values) => {
    if (business?.id && values?.amount) {
      const amountCents = stringToCents(values.amount || 0);
      createFinancingRequest({
        variables: {
          businessId: business.id,
          creditDays: creditDays.value,
          amountCents,
        },
      });
    }
  };

  useEffect(() => {
    trackEvent(WITHDRAW_FROM_CREDIT_STARTED);
  }, []);

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          id: '9IOi+O',
          defaultMessage: 'Disponer crédito',
        })}
      </Header>
      <Spacer margin="3" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <WalletDropdown disabled fullWidth defaultValue="credit" />
        <Spacer margin="6" />
        <TextField
          type="currency"
          required
          label={intl.formatMessage({
            id: 'Gyn4Ct',
            defaultMessage: 'Monto a disponer',
          })}
          currency="MXN"
          fullWidth
          helperText={errors?.amount?.message}
          error={!!errors?.amount?.message}
          {...register('amount', {
            validate: {
              positive: (value: number) =>
                value > 0 ||
                intl.formatMessage({
                  id: 'htbxyi',
                  defaultMessage: 'Debe ser mayor a $0',
                }),
              lessThanBalance: (value: number) =>
                value <= (creditLimitCents - owedAmountCents || 0) / 100 ||
                intl.formatMessage({
                  id: 'IIFeAm',
                  defaultMessage: 'Su saldo es menor al crédito disponible',
                }),
            },
            setValueAs: (value: string) => parseCurrency(value),
            required: intl.formatMessage({
              id: '7Vvfe3',
              defaultMessage: 'Campo requerido',
            }),
          })}
        />
        <Controller
          control={control}
          name="creditDays"
          rules={{
            required: intl.formatMessage({
              id: '7Vvfe3',
              defaultMessage: 'Campo requerido',
            }),
          }}
          render={({ field }) => {
            return (
              <TextField
                label={intl.formatMessage({
                  id: 'USPtju',
                  defaultMessage: 'Términos del crédito',
                })}
                type="select"
                fullWidth
                required
                options={creditOptions}
                {...field}
              />
            );
          }}
        />

        <Spacer margin="3" />

        <FlexContainer justifyContent="space-between">
          <Text colorStep="500">
            {intl.formatMessage({
              id: 'lnTg/E',
              defaultMessage: 'Fecha de pago',
            })}
          </Text>
          <Text>{format(paymentDate, DATE_FORMAT)}</Text>
        </FlexContainer>

        <Divider margin="2" />

        <FlexContainer justifyContent="space-between">
          <CenteredFlexContainer>
            <Text colorStep="500">
              {intl.formatMessage({
                id: 'SyXZeV',
                defaultMessage: 'Tasa de interés',
              })}
            </Text>
            <Spacer margin="2" direction="horizontal" />
            <CreditInterestTag
              interestRateAmount={interestAmount}
              interestRateAmountWithTaxes={totalInterest}
              annualInterestRate={annualInterestRate * 100}
              ivaInterestAmount={ivaInterestAmount}
            />
          </CenteredFlexContainer>
          <CenteredFlexContainer>
            <Text>
              <Currency value={totalInterest} maximumFractionDigits={2} />
            </Text>
          </CenteredFlexContainer>
        </FlexContainer>
        <Spacer margin="8" />
        <CenteredFlexContainer>
          <TermsCheckbox
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
            label={intl.formatMessage({
              id: 'pPfR6f',
              defaultMessage: 'He leído y acepto los',
            })}
          />
        </CenteredFlexContainer>
        <Spacer margin="8" />
        <Button
          loading={loading}
          block
          type="submit"
          disabled={!isValid || remainingCreditCents < 0 || !termsAccepted}
        >
          {intl.formatMessage(
            {
              id: 'BXPbMf',
              defaultMessage: 'Enviar {amount} a mi saldo',
            },
            {
              amount: <Currency value={floatToCents(inputAmount)} />,
            }
          )}
        </Button>
      </form>
    </S.Container>
  );
}

WithdrawFromCreditModal.displayName = 'WithdrawFromCreditModal';

export default WithdrawFromCreditModal;
