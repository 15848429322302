import styled from 'styled-components';
import Card from '../Card';

export const ModalCard = styled(Card)`
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  .close-button {
    position: absolute;
    transform: translate(25%, -25%);
    top: ${({ theme }) => theme.spacing[8]};
    right: ${({ theme }) => theme.spacing[8]};
  }

  &.modal-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.modal-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;
