import styled from 'styled-components';

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[6]}; ;
`;

export const Search = styled.div`
  flex-grow: 1;
`;

export const HistoricalTransactionsTable = styled.div`
  position: relative;
  z-index: 0;
`;
