import styled from 'styled-components';

type SearchContainerProps = {
  width: number;
  zIndex: number;
};

export const SearchContainer = styled.div<SearchContainerProps>`
  position: relative;
  width: ${({ width }) => width}px;
  z-index: ${({ zIndex }) => zIndex};
  transition: 300ms ease-in-out;
  .results-card {
    top: calc(100% + ${({ theme }) => theme.spacing[2]});
    position: absolute;
    width: 100%;
    max-height: ${({ theme }) => theme.spacing[80]};
    overflow-y: auto;
  }
`;
