import styled, { Color, ColorStep } from 'styled-components';

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  strong?: boolean;
  light?: boolean;
  small?: boolean;
  underline?: boolean;
  colorStep?: ColorStep;
  color?: Color;
  textAlign?: 'center' | 'end' | 'start' | 'right' | 'left';
}

export const Text = styled.p<TextProps>`
  font-size: ${({ theme, small }) =>
    small ? theme.fontSize['2xs'] : theme.fontSize.sm};
  font-weight: ${({ theme, strong, light }) =>
    strong
      ? theme.fontWeight.bold
      : light
      ? theme.fontWeight.light
      : theme.fontWeight.regular};
  color: ${({ theme, color = 'neutral', colorStep = '900' }) =>
    theme.colors[color][colorStep]};
  text-decoration: ${({ underline }) => underline && 'underline'};
  text-align: ${({ textAlign }) => textAlign};
  margin: 0;
`;

export default Text;
