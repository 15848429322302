import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './Error500.styles';
import Button from '@src/components/ui/Button';
import { useHistory } from 'react-router-dom';
import useQueryParams from '@src/hooks/useQueryParams';
import Page from '@src/components/shared/Page';
import { Header, Text } from '@src/components/ui';

const ServerError = () => {
  const intl = useIntl();
  const history = useHistory();
  const [{ error }] = useQueryParams();

  return (
    <Page title="500">
      <S.Container>
        <S.Error>
          <label>500</label>
          <span role="img" aria-label="broken">
            🔥
          </span>
        </S.Error>
        <Header as="h3">
          {intl.formatMessage({
            id: 'ndgaFc',
            defaultMessage: '¡Ups! Algo salió mal.',
          })}
        </Header>
        <Text>
          {intl.formatMessage({
            id: 'XASk/7',
            defaultMessage:
              'Estamos teniendo dificultades técnicas. Intenta más tarde.',
          })}
        </Text>
        <pre>{error}</pre>
        <Button onClick={() => history.replace(`/`)}>
          {intl.formatMessage({
            id: '0jrhNQ',
            defaultMessage: 'Regresar al inicio',
          })}
        </Button>
      </S.Container>
    </Page>
  );
};

export default ServerError;
