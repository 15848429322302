import React from 'react';
import { useIntl } from 'react-intl';

import Select from '@src/components/ui/Select';
import useSettings from '@src/hooks/useSettings';
import useProvidersListQuery from '@src/hooks/useProvidersListQuery';

type SuppliersSelectProps = {
  onChange?: (...args: any) => void;
  value?: string;
};

const SuppliersSelect = ({ onChange, value }: SuppliersSelectProps) => {
  const intl = useIntl();
  const { business } = useSettings();
  const { data } = useProvidersListQuery(business?.id);
  const providers = data.map((provider) => ({
    label: provider.name,
    value: provider.id,
  }));

  const handleChange = (option) => {
    if (onChange) {
      onChange({
        providerId: option?.value,
      });
    }
  };

  const selected = providers.find((option) => option.value === value);
  return (
    <Select
      isClearable
      value={selected}
      placeholder={intl.formatMessage({
        defaultMessage: 'Selecciona proveedor',
        id: 'LECn6O',
      })}
      options={providers}
      onChange={handleChange}
    />
  );
};

export default SuppliersSelect;
