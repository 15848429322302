import React, { useMemo, useState, forwardRef } from 'react';

import Menu from '@src/components/ui/Menu';
import Tooltip from '@src/components/ui/Tooltip';
import * as S from './Dropdown.styles';
import { ChevronDown, Info } from '@src/components/ui/Icon/outline';

import type { DropdownProps } from './types';

function Dropdown(
  {
    children: childrenProp,
    value,
    fullWidth,
    selectedNode,
    rows,
    label,
    disabled,
    error,
    color = 'neutral',
    variant = 'outlined',
    tooltipTitle,
    onChange,
    onBlur,
  }: DropdownProps,
  ref
) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedChild, setSelectedChild] = useState<React.ReactNode>(null);
  const [anchor, setAnchor] = useState<HTMLElement>();
  const endIcon = useMemo(() => {
    if (tooltipTitle) {
      return (
        <Tooltip
          title={tooltipTitle}
          id="dropdown-tooltip"
          place="top"
          effect="solid"
          width={240}
        >
          <Info />
        </Tooltip>
      );
    }
    if (disabled) {
      return null;
    }
    return <ChevronDown />;
  }, [disabled, tooltipTitle]);

  const children = useMemo(
    () =>
      React.Children.map(childrenProp, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const childValue = child.props.value;
        const selected = childValue && value && childValue === value;

        if (selected) {
          setSelectedChild(child);
        }

        return React.cloneElement(child, {
          onChange,
          selected,
        });
      }),
    [childrenProp, value]
  );

  const handleButtonFocus = () => {
    !disabled && setOpen(!open);
  };

  const handleMenuBlur = () => {
    setOpen(false);
    onBlur && onBlur();
  };

  const mergeRefs = (element) => {
    setAnchor(element);
    if (element) {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    }
  };

  return (
    <S.DropdownWrapper fullWidth={fullWidth}>
      <S.DropdownButton
        error={error}
        ref={mergeRefs}
        type="button"
        open={open}
        color={color}
        variant={variant}
        onFocus={handleButtonFocus}
        onClick={(e) => e.currentTarget.focus()}
        fullWidth={fullWidth}
        endIcon={endIcon}
      >
        {selectedNode || label || selectedChild}
      </S.DropdownButton>
      <Menu
        onBlur={handleMenuBlur}
        open={open}
        anchorEl={anchor}
        rows={rows}
        placement="bottom-start"
      >
        {children}
      </Menu>
    </S.DropdownWrapper>
  );
}

export default forwardRef(Dropdown);
