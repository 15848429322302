import React from 'react';
import { useIntl } from 'react-intl';

import FinancingCard from '@src/components/pages/WalletView/components/FinancingCard';
import Page from '@src/components/shared/Page';
import Header from '@src/components/ui/Header';
import Modal from '@src/components/shared/Modal';
import usePageTrack from '@src/hooks/usePageTrack';
import useSatBot from '@src/hooks/useSatBot';
import useSettings from '@src/hooks/useSettings';
import WithdrawalModal from './components/WithdrawalModal';
import WithdrawalSuccess from '@src/components/pages/WalletView/components/WithdrawalSuccess';
import WithdrawalFullscreenModal from '@src/components/pages/WalletView/components/WithdrawalFullscreenModal';
import WalletTransactionModal from '@src/components/pages/WalletView/components/WalletTransactionModal/WalletTransactionModal';
import WithdrawalValidationModal from '@src/components/shared/modals/WithdrawalValidationModal';
import FactoringSuccessModal from '@src/components/pages/RequestFactoring/components/FactoringSuccessModal';
import Permission from '@src/components/shared/Permission';
import PaymentsReconciliationModal from '@src/components/shared/modals/PaymentsReconciliationModal';
import WithdrawalUnavailableModal from './components/WithdrawalUnavailableModal';

import { PermissionName } from '@src/types/enums';
import { Grid, Spacer } from '@src/components/shared/layouts';
import WalletTransactions from '@src/components/pages/WalletView/WalletTransactions';
import { getGreetingTime } from '@src/utils/date';

import AccountsPayableTable from './components/AccountsPayableTable';
import AccountsReceivableTable from './components/AccountsReceivableTable';
import messages from './messages';
import WalletCard from './WalletCard';

export function WalletView() {
  usePageTrack('wallet', 'core');
  useSatBot();
  const intl = useIntl();
  const { currentUser } = useSettings();

  return (
    <Page
      title={intl.formatMessage(messages[getGreetingTime()], {
        userName: currentUser && `${currentUser.firstName}`,
      })}
    >
      <Header as="h4">
        {intl.formatMessage(messages[getGreetingTime()], {
          userName: currentUser && `${currentUser.firstName}`,
        })}
      </Header>
      <Spacer />
      <Grid container spacing={2}>
        <Grid item sm={12} md={6} lg={6}>
          <WalletCard />
          <Spacer />
          <WalletTransactions />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <div>
            <FinancingCard />
          </div>
          <Spacer />
          <Permission permissions={[PermissionName.MANAGE_INVOICES_RECEIVED]}>
            <>
              <AccountsPayableTable />
              <Spacer />
            </>
          </Permission>
          <Permission permissions={[PermissionName.MANAGE_INVOICES_EMITTED]}>
            <AccountsReceivableTable />
          </Permission>
        </Grid>
      </Grid>

      <Modal component={FactoringSuccessModal} />
      <Modal component={WalletTransactionModal} />
      <Modal component={WithdrawalModal} />
      <Modal component={WithdrawalValidationModal} />
      <Modal component={WithdrawalUnavailableModal} />
      <Modal
        component={WithdrawalFullscreenModal}
        type="fullscreen"
        zIndex={50}
      />
      <Modal component={WithdrawalSuccess} type="fullscreen" />
      <Modal component={PaymentsReconciliationModal} />
    </Page>
  );
}

export default WalletView;
