import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import INVOICE_QUERY, {
  InvoiceReturn,
  InvoiceVariables,
} from '@src/graphql/queries/invoice';
import CreditNoteView from './CreditNoteView';
import Page from '@src/components/shared/Page/Page';
import { Alert } from '@src/components/ui';
import RejectInvoiceForm from '@src/components/shared/form/RejectInvoiceForm';
import Loader from '@src/components/shared/Loader';
import PageTitle from '@src/components/shared/PageTitle';
import useSettings from '@src/hooks/useSettings';
import { useQuery } from '@apollo/client';
import Modal from '@src/components/shared/Modal';

function CreditNoteViewContainer() {
  const intl = useIntl();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { business } = useSettings();
  const { data, loading, error } = useQuery<InvoiceReturn, InvoiceVariables>(
    INVOICE_QUERY,
    {
      variables: {
        businessId: business?.id,
        invoiceId: invoiceId,
      },
      skip: !invoiceId || !business?.id,
    }
  );

  if (loading) {
    return (
      <Page
        title={intl.formatMessage({
          id: 'ARhEHP',
          defaultMessage: 'Cargando...',
        })}
      >
        <PageTitle>
          {intl.formatMessage({
            id: 'ARhEHP',
            defaultMessage: 'Cargando...',
          })}
        </PageTitle>
        <div>
          <Loader fullWidth />
        </div>
      </Page>
    );
  }
  if (error) {
    return (
      <Alert color="danger" hasBorder>
        {intl.formatMessage({
          id: 'yM0cd/',
          defaultMessage: 'Error al cargar los datos',
        })}{' '}
        {error.message}
      </Alert>
    );
  }

  if (!data?.invoice) {
    return null;
  }

  return (
    <Page
      title={intl.formatMessage(
        {
          id: 'upM9Gc',
          defaultMessage: 'Nota crédito No.{invoiceId}',
        },
        { invoiceId: invoiceId }
      )}
    >
      <CreditNoteView invoice={data.invoice} />

      <Modal component={RejectInvoiceForm} />
    </Page>
  );
}

export default CreditNoteViewContainer;
