import { gql } from '@apollo/client';

const UPDATE_INVOICE_EMAIL_MUTATION = gql`
  mutation UpdateInvoiceEmail($businessId: ID!, $mailboxEmail: String!) {
    updateInvoiceEmail(businessId: $businessId, mailboxEmail: $mailboxEmail) {
      id
      mailboxEmail
    }
  }
`;

export type UpdateInvoiceEmailReturn = {
  updateInvoiceEmail: {
    id: string;
    mailboxEmail: string;
  };
};

export type UpdateInvoiceEmailVariables = {
  businessId: string;
  mailboxEmail: string;
};

export default UPDATE_INVOICE_EMAIL_MUTATION;
