import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import SuccessEmailUpdate from '../SuccessEmailUpdate/SuccessEmailUpdate';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_INVOICE_EMAIL } from '@src/constants/events';
import useToast from '@src/hooks/useToast';
import UPDATE_INVOICE_EMAIL_MUTATION, {
  UpdateInvoiceEmailReturn,
  UpdateInvoiceEmailVariables,
} from '@src/graphql/mutations/updateInvoiceEmail';
import useSettings from '@src/hooks/useSettings';
import useModal from '@src/hooks/useModal';
import { Button, Header, Text, TextField } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';
import * as S from './UpdateInvoiceEmailForm.styles';

function UpdateInvoiceEmailForm() {
  const intl = useIntl();
  const { toast } = useToast();
  const { business } = useSettings();
  const { open: openSuccess } = useModal(SuccessEmailUpdate);
  const [updateEmail, { loading }] = useMutation<
    UpdateInvoiceEmailReturn,
    UpdateInvoiceEmailVariables
  >(UPDATE_INVOICE_EMAIL_MUTATION, {
    onCompleted: (data) => {
      trackEvent(UPDATE_INVOICE_EMAIL);

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Email configurado',
          id: '9EdXGG',
        }),
        intl.formatMessage({
          defaultMessage: 'El email para tu buzón se configuró correctamente',
          id: '1GbUyv',
        })
      );

      openSuccess({
        email: data.updateInvoiceEmail.mailboxEmail,
      });
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al configurar email',
          id: 'hkyzUA',
        }),
        e.message
      );
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = async (data: { email: string }) => {
    if (business?.id) {
      updateEmail({
        variables: {
          businessId: business.id,
          mailboxEmail: data.email.toLowerCase().concat('@invoices.higo.io'),
        },
      });
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Tu buzón de Higo',
          id: 'f6It30',
        })}
      </Header>
      <Spacer margin="2" />
      <Text color="neutral" colorStep="500">
        {intl.formatMessage({
          defaultMessage:
            'Configura tu dirección de tu buzón para recibir, aprobar, administrar y pagar todas las facturas de tus proveedores',
          id: 'Ztgbqp',
        })}
      </Text>
      <TextField
        {...register('email', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
          minLength: {
            value: 4,
            message: intl.formatMessage(
              {
                defaultMessage: 'Debe contener mínimo {length} caracteres.',
                id: 'Isn6i2',
              },
              {
                length: 4,
              }
            ),
          },
          pattern: {
            value: /[A-Za-z0-9\.\-]{4,}/,
            message: intl.formatMessage({
              defaultMessage: 'Acepta solo letras, números, guiones y puntos',
              id: '/eNo0i',
            }),
          },
        })}
        fullWidth
        placeholder={intl.formatMessage({
          defaultMessage: 'facturacion',
          id: '89RDsK',
        })}
        error={errors?.email?.message}
        helperText={
          errors?.email?.message ||
          intl.formatMessage({
            defaultMessage: 'Acepta solo letras, números, guiones y puntos',
            id: '/eNo0i',
          })
        }
        endIcon={<span>@invoices.higo.io</span>}
        autoFocus
      />
      <Spacer />
      <Button block type="submit" disabled={loading} loading={loading}>
        {intl.formatMessage({
          defaultMessage: 'Guardar',
          id: 'oN4aGu',
        })}
      </Button>
    </S.Form>
  );
}

UpdateInvoiceEmailForm.displayName = 'UpdateInvoiceEmailForm';

export default UpdateInvoiceEmailForm;
