import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import * as S from './SatSyncCard.styles';
import { Card, Button, Label, Text, Spinner, Icon } from '@src/components/ui';
import FormattedDate, {
  defaultTimeFormat,
} from '@src/components/i18n/FormattedDate';
import useSatBot from '@src/hooks/useSatBot';
import useToast from '@src/hooks/useToast';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { trackEvent } from '@src/lib/analytics';
import { SYNC_INVOICES_WITH_SAT } from '@src/constants/events';
import { TAX_PAYER_INFOS_QUERY_NAME } from '@src/graphql/queries/taxpayerInfos';
import useSettings from '@src/hooks/useSettings';
import SYNC_INVOICES_WITH_SAT_MUTATION, {
  SyncInvoicesWithSatParams,
  SyncInvoicesWithSatReturn,
} from '@src/graphql/mutations/syncInvoicesSat';
import SynchronizeSatInvoices from '@src/components/pages/Inbox/components/SynchronizeSatInvoices';
import useModal from '@src/hooks/useModal';

import { SatStatus } from '@src/types/enums';

import { ReactComponent as SyncSatSvg } from './img/sync-sat.svg';

export const eventData = {
  view: 'SatSyncCardContainer',
};

const StatusImage = ({ satSyncStatus }) => {
  if (satSyncStatus === SatStatus.NOT_SYNCED) {
    return <Icon name="Radio" color="neutral" colorStep="400" />;
  } else if (
    satSyncStatus === SatStatus.SYNCED ||
    satSyncStatus === SatStatus.SYNCING_HISTORICAL
  ) {
    return <Icon name="Radio" color="success" colorStep="400" />;
  } else if (satSyncStatus === SatStatus.SYNCING) {
    return <Spinner />;
  } else if (satSyncStatus === SatStatus.VALIDATING_CREDENTIALS) {
    return <Spinner />;
  } else if (satSyncStatus === SatStatus.SAT_LOADING_ERROR) {
    return <Icon name="AlertCircle" color="danger" colorStep="500" />;
  } else if (satSyncStatus === SatStatus.WRONG_CREDENTIALS) {
    return <Icon name="AlertCircle" color="danger" colorStep="500" />;
  }
  return <Icon name="Radio" color="neutral" colorStep="400" />;
};

export function SatSyncCard() {
  const { formatMessage } = useIntl();
  const { satSyncStatus, satUpdatedAt, id } = useSatBot();
  const { business } = useSettings();
  const { toast } = useToast();
  const { open: openSyncModal } = useModal(SynchronizeSatInvoices);
  const [syncInvoices, { loading }] = useMutation<
    SyncInvoicesWithSatReturn,
    SyncInvoicesWithSatParams
  >(SYNC_INVOICES_WITH_SAT_MUTATION, {
    refetchQueries: [TAX_PAYER_INFOS_QUERY_NAME],
  });

  const showSyncButton =
    satSyncStatus !== SatStatus.SYNCING &&
    satSyncStatus !== SatStatus.VALIDATING_CREDENTIALS;
  const isSynced =
    satSyncStatus === SatStatus.SYNCED ||
    satSyncStatus === SatStatus.SYNCING_HISTORICAL;

  const handleSyncClick = async () => {
    if (
      satSyncStatus === SatStatus.NOT_SYNCED ||
      satSyncStatus === SatStatus.WRONG_CREDENTIALS
    ) {
      openSyncModal({});
      return;
    }

    if (business?.id && id) {
      try {
        await syncInvoices({
          variables: {
            businessId: business.id,
            taxPayerInfoId: id,
          },
        });

        trackEvent(SYNC_INVOICES_WITH_SAT, eventData);
      } catch (e) {
        toast.error(
          formatMessage({
            defaultMessage: 'Error al sincronizar con el SAT',
            id: 'RwOxi2',
          })
        );
      }
    }
  };

  if (!satSyncStatus) {
    return null;
  }

  return (
    <Card padding="4">
      <S.Container>
        <FlexContainer alignItems="center">
          <StatusImage satSyncStatus={satSyncStatus} />
          <Spacer margin="2" direction="horizontal" />
          <SyncSatSvg />
        </FlexContainer>
        <Spacer margin="2" />
        <FlexContainer alignItems="flex-end" justifyContent="space-between">
          <div>
            <Label colorStep="500">
              {formatMessage({
                defaultMessage: 'Última actualización',
                id: 'kgS8Wz',
              })}
            </Label>

            {satUpdatedAt ? (
              <Text small>
                <FormattedDate value={satUpdatedAt} {...defaultTimeFormat} />
              </Text>
            ) : (
              <Text small>
                {formatMessage({
                  defaultMessage: 'Sin información',
                  id: 'ulWfWZ',
                })}
              </Text>
            )}
          </div>
          <>
            {showSyncButton && (
              <Button loading={loading} compact onClick={handleSyncClick}>
                {isSynced
                  ? formatMessage({
                      defaultMessage: 'Actualizar',
                      id: 'hiXQoN',
                    })
                  : formatMessage({
                      defaultMessage: 'Sincronizar',
                      id: '1BbBuF',
                    })}
              </Button>
            )}
          </>
        </FlexContainer>
      </S.Container>
    </Card>
  );
}

export default SatSyncCard;
