import React from 'react';
import { useIntl } from 'react-intl';

import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCell,
} from '@src/components/ui';
import Currency from '@src/components/i18n/Currency';
import ProductImage from '@src/components/pages/InvoiceView/components/ProductImage';

import { InvoiceLineItem } from '@src/types/models';

type InvoiceLineItemsTableProps = {
  currency: string;
  invoiceLineItems: Array<InvoiceLineItem>;
};

export function InvoiceLineItemsTable({
  invoiceLineItems,
  currency,
}: InvoiceLineItemsTableProps) {
  const intl = useIntl();

  return (
    <Table>
      <TableHeader>
        <TableHeaderCell />
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Producto',
            id: 'otbaM/',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Unidad',
            id: 'yyjc9d',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Cantidad',
            id: 'wuSXS3',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Precio unitario',
            id: 'Ck7ZL6',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Total',
            id: 'MJ2jZQ',
          })}
        </TableHeaderCell>
      </TableHeader>
      <tbody>
        {invoiceLineItems.map(
          ({ name, imageUrl, pricePerUnit, quantity, unit, total }, index) => (
            <TableRow key={index}>
              <TableCell>
                <ProductImage url={imageUrl} smaller />
              </TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{unit ? unit : 'N/A'}</TableCell>
              <TableCell>{quantity}</TableCell>
              <TableCell>
                <Currency value={pricePerUnit} currency={currency} />
              </TableCell>
              <TableCell>
                <Currency value={total} currency={currency} />
              </TableCell>
            </TableRow>
          )
        )}
      </tbody>
    </Table>
  );
}

export default InvoiceLineItemsTable;
