import React, { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@src/components/ui';
import { QueryResult, useMutation, useQuery } from '@apollo/client';
import useToast from '@src/hooks/useToast';

import MANUAL_SYNC_BILLS_MUTATION, {
  ManualSyncBillsReturn,
  ManualSyncBillsVariables,
} from '@src/graphql/mutations/manualSync';
import { CODAT_COMPANY_QUERY } from '@src/graphql/queries';

interface ManualSyncButtonProps {
  businessId: string | undefined;
  codatConnectionId: string;
}

export const ManualSyncButton: FunctionComponent<ManualSyncButtonProps> = ({
  businessId,
  codatConnectionId,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const { data: codatCompanyData }: QueryResult = useQuery(
    CODAT_COMPANY_QUERY,
    {
      variables: { businessId: businessId },
      skip: !businessId,
    }
  );

  const [manualSyncbillsMutation] = useMutation<
    ManualSyncBillsReturn,
    ManualSyncBillsVariables
  >(MANUAL_SYNC_BILLS_MUTATION, {
    onCompleted: () => {
      setIsLoading(false);
    },
  });
  const handleManualSync = () => {
    console.log(codatCompanyData?.codatCompany?.codatId);
    if (codatCompanyData?.codatCompany?.codatId && businessId) {
      setIsLoading(true);
      manualSyncbillsMutation({
        variables: {
          codatId: codatCompanyData?.codatCompany?.codatId,
          businessId: businessId,
        },
      });
    }
  };
  return (
    <Button
      onClick={handleManualSync}
      variant="solid"
      color="primary"
      loading={isLoading}
      style={{ marginLeft: 'auto', marginBottom: 12 }}
    >
      {intl.formatMessage({
        id: 'hiXQoN',
        defaultMessage: 'Actualizar',
      })}
    </Button>
  );
};
