import React, { forwardRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactDatePicker from 'react-datepicker';

import Input from '@src/components/ui/Input';
import IconButton from '@src/components/ui/IconButton';
import * as S from './DatePicker.styles';
import {
  Calendar,
  X as Close,
  ChevronLeft,
  ChevronRight,
} from '@src/components/ui/Icon/outline';
import Card from '@src/components/ui/Card';

import type { DatePickerProps } from './types';
import { format, formatISO } from 'date-fns';

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <S.Header>
    <IconButton
      compact
      rounded
      onClick={decreaseMonth}
      variant="transparent"
      color="neutral"
      disabled={prevMonthButtonDisabled}
    >
      <ChevronLeft />
    </IconButton>

    <span className="date-picker-current-month">
      {/* FIXME: Use locale from settings */}
      {format(date, 'MMM yyyy')}
    </span>

    <IconButton
      compact
      rounded
      onClick={increaseMonth}
      variant="transparent"
      color="neutral"
      disabled={nextMonthButtonDisabled}
    >
      <ChevronRight />
    </IconButton>
  </S.Header>
);

export const DATE_FORMAT = 'dd/M/yyyy';
export const TIME_FORMAT = 'kk:mm';

function DatePicker(
  {
    id,
    maxDate,
    minDate,
    maxTime,
    minTime,
    name,
    placeholder,
    disabled,
    dateFormat,
    timeFormat,
    timeIntervals,
    showTimeSelect,
    showTimeSelectOnly,
    startDate,
    endDate,
    value,
    selectsStart,
    selectsEnd,
    error,
    fullWidth,
    selectsRange,
    onChange,
    onClear,
    ...otherProps
  }: DatePickerProps,
  ref
) {
  const intl = useIntl();
  const [internalValue, setInternalValue] = useState<string | null | undefined>(
    null
  );

  const selected = value || internalValue;

  const handleChange = (date) => {
    let start, end;
    if (Array.isArray(date)) {
      [start, end] = date;
    } else {
      start = date;
    }

    if (selectsRange) {
      onChange?.(formatISO(start), end && formatISO(end));
    } else {
      onChange?.(formatISO(start));
    }
    setInternalValue(formatISO(start));
  };

  const handleClear = () => {
    onClear?.();
    setInternalValue(null);
  };

  return (
    <S.DateInput fullWidth={fullWidth}>
      <ReactDatePicker
        id={id}
        name={name}
        customInput={
          <Input
            error={error}
            placeholder={placeholder}
            fullWidth={fullWidth}
            ref={ref}
            endIcon={
              !value ? (
                <Calendar size={18} />
              ) : (
                <Close onClick={handleClear} size={18} />
              )
            }
          />
        }
        placeholderText={placeholder}
        minDate={minDate && new Date(minDate)}
        maxDate={maxDate && new Date(maxDate)}
        minTime={minTime && new Date(minTime)}
        maxTime={maxTime && new Date(maxTime)}
        startDate={startDate && new Date(startDate)}
        endDate={endDate && new Date(endDate)}
        selected={selected && new Date(selected)}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        autoComplete="off"
        popperComponent={Card}
        disabled={disabled}
        dateFormat={dateFormat || DATE_FORMAT}
        timeFormat={timeFormat || TIME_FORMAT}
        timeIntervals={timeIntervals}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        locale={intl.locale}
        onChange={handleChange}
        calendarClassName="date-picker-calendar"
        clearButtonClassName="date-picker-clear-button"
        dayClassName={(date) => 'date-picker-day'}
        weekDayClassName={(date) => 'date-picker-weekday'}
        popperClassName="date-picker-popper"
        timeClassName="date-picker-time"
        wrapperClassName="date-picker-wrapper"
        showPopperArrow={false}
        renderCustomHeader={CustomHeader}
        selectsRange={selectsRange}
      />
    </S.DateInput>
  );
}

export default forwardRef(DatePicker);
