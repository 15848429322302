import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './MinimalLayout.styles';
import Logo from '@src/components/shared/Logo';

function MinimalLayout({ children, title }) {
  return (
    <S.Page title={title}>
      <S.Nav>
        <Link to="/">
          <Logo />
        </Link>
      </S.Nav>
      {children}
    </S.Page>
  );
}

export default MinimalLayout;
