import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import differenceInDays from 'date-fns/differenceInDays';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import CREATE_FINANCING_REQUEST_REPAYMENT_MUTATION, {
  CreateFinancingRequestRepaymentReturn,
  CreateFinancingRequestRepaymentVariables,
} from '@src/graphql/mutations/createFinancingRequestRepayment';
import { FINANCING_REQUESTS_QUERY } from '@src/graphql/queries';
import CREDIT_ACCOUNT_QUERY from '@src/graphql/queries/creditAccount';
import { WALLET_QUERY_NAME } from '@src/graphql/queries/wallet';

import FinancingRequestsSelect from '../FinancingRequestsSelect';
import TextField from '@src/components/ui/TextField';
import { trackEvent } from '@src/lib/analytics';
import { FINANCING_REQUEST_REPAYMENT_EVENT } from '@src/constants/events';
import Card from '@src/components/ui/Card';
import Button from '@src/components/ui/Button';
import { Currency } from '@src/components/i18n/Currency';
import * as S from './PayFinancingForm.styles';
import useWallet from '@src/hooks/useWallet';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import TermsCheckbox from '@src/components/shared/TermsCheckbox';
import useToast from '@src/hooks/useToast';
import {
  stringToCents,
  centsToString,
  centsToFloat,
} from '@src/utils/currency';
import {
  CenteredFlexContainer,
  FlexContainer,
  Spacer,
} from '@src/components/shared/layouts';
import Text from '@src/components/ui/Text';
import { Divider, Label } from '@src/components/ui';
import CreditInterestTag from '@src/components/shared/tag/CreditInterestTag';
import useFinancingRepaymentAmounts from '@src/hooks/useFinancingRepaymentAmounts';
import { HigoConcentradoraAccount } from '@src/components/shared/AccountInformation';

import type { Business, FinancingRequest } from '@src/types/models';

interface PayFinancingFormProps {
  business?: Business;
  defaultRequest?: FinancingRequest;
  onSubmit?: () => void;
}

function PayFinancingForm({
  business,
  defaultRequest,
  onSubmit: onSubmitProp,
}: PayFinancingFormProps) {
  const intl = useIntl();
  const { balance: balanceCents } = useWallet();
  const { snack, toast } = useToast();
  const history = useHistory();
  const [termsApproved, setTermsApproved] = useState(false);
  const [selectedFinancingRequest, setSelectedFinancingRequest] = useState<
    FinancingRequest & { interestDays: number }
  >();
  const [createFinancingRequestRepayment, { loading }] = useMutation<
    CreateFinancingRequestRepaymentReturn,
    CreateFinancingRequestRepaymentVariables
  >(CREATE_FINANCING_REQUEST_REPAYMENT_MUTATION, {
    refetchQueries: [
      WALLET_QUERY_NAME,
      CREDIT_ACCOUNT_QUERY,
      FINANCING_REQUESTS_QUERY,
    ],
    onCompleted: () => {
      trackEvent(FINANCING_REQUEST_REPAYMENT_EVENT);
      onSubmitProp && onSubmitProp();
      snack(
        intl.formatMessage({
          defaultMessage: 'Financiamiento pagado exitosamente',
          id: 'r4N29y',
        }),
        {
          label: intl.formatMessage({
            defaultMessage: 'Ir a transacciones',
            id: 'arPhpH',
          }),
          onClick: () => {
            history.push('/transactions');
          },
        }
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al pagar el financiamiento',
          id: 'rPHunB',
        }),
        e.message
      );
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      amount: 0,
      financingRequestId: defaultRequest?.id || null,
    },
  });
  const inputAmount = watch('amount', 0) || 0;
  const financingRequestId = watch('financingRequestId');

  const { includeInterest, initialAmount } = useFinancingRepaymentAmounts({
    selectedFinancingRequest,
    inputAmount,
  });

  const onSubmit = (values) => {
    if (business?.id && selectedFinancingRequest) {
      const inputAmountCents = stringToCents(values?.amount || 0);
      createFinancingRequestRepayment({
        variables: {
          businessId: business?.id,
          financingRequestRepayment: {
            financingRequestId: values.financingRequestId,
            amountCents:
              includeInterest && selectedFinancingRequest.owedAmountCents
                ? selectedFinancingRequest.owedAmountCents
                : inputAmountCents,
            interestAmountCents:
              includeInterest &&
              selectedFinancingRequest.totalInterestAmountCents
                ? inputAmountCents - selectedFinancingRequest.owedAmountCents
                : 0,
          },
        },
      });
    }
  };

  const financingRequestRegisterProps = () => {
    const { onChange, ...rest } = register('financingRequestId', {
      required: intl.formatMessage({
        defaultMessage: 'Campo requerido',
        id: '7Vvfe3',
      }),
    });

    return {
      ...rest,
      defaultValue: defaultRequest,
      onChange: (event) => {
        // We need to use dates without considering their time, so we avoid making the calculation too specific and error-prone
        const formattedPaidAt =
          event.paidAt && new Date(event.paidAt.substr(0, 10));
        const formattedDate = new Date(format(new Date(), 'yyyy-MM-dd'));
        const interestDays = event.paidAt
          ? differenceInDays(formattedDate, formattedPaidAt) + 1
          : 0;

        setSelectedFinancingRequest({
          id: event.id,
          interestDays,
          owedAmountCents: event.owedAmountCents,
          interestAmountCents: event.interestAmountCents,
          ivaInterestAmountCents: event.ivaInterestAmountCents,
          moratoryInterestAmountCents: event.moratoryInterestAmountCents,
          totalInterestAmountCents: event.totalInterestAmountCents,
          annualInterestRate: event.annualInterestRate,
          annualMoratoryRate: event.annualMoratoryRate,
        });
        setValue('financingRequestId', event.id);
        onChange(event);
        return event;
      },
    };
  };

  useEffect(() => {
    if (selectedFinancingRequest) {
      clearErrors('amount');
      setValue('amount', Number(centsToString(initialAmount)), {
        shouldValidate: true,
      });
    }
  }, [selectedFinancingRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <S.SelectWrapper>
          <FinancingRequestsSelect
            required
            label={intl.formatMessage({
              defaultMessage: 'Crédito',
              id: 'kbZ9Ki',
            })}
            helperText={errors?.financingRequestId?.message}
            error={!!errors?.financingRequestId?.message}
            {...financingRequestRegisterProps()}
            fullWidth
          />
        </S.SelectWrapper>
      </Card>
      <Card padding="8">
        <TextField
          disabled={!financingRequestId}
          type="currency"
          variant="bold"
          required
          label={intl.formatMessage({
            defaultMessage: 'Monto a pagar',
            id: 'ZzlLUg',
          })}
          currency="MXN"
          fullWidth
          helperText={errors?.amount?.message}
          error={!!errors?.amount?.message}
          {...register('amount', {
            validate: {
              positive: (value: number) =>
                value > 0 ||
                intl.formatMessage({
                  defaultMessage: 'Debe ser mayor a $0',
                  id: 'htbxyi',
                }),
              lessThanFinanced: (value: number) => {
                const maxAmountCents =
                  (selectedFinancingRequest?.owedAmountCents || 0) +
                  (selectedFinancingRequest?.totalInterestAmountCents || 0);
                return (
                  value <= centsToFloat(maxAmountCents) ||
                  intl.formatMessage(
                    {
                      defaultMessage: 'No debe ser mayor a ${maxAmount}',
                      id: '3tFLcC',
                    },
                    {
                      maxAmount: centsToFloat(maxAmountCents),
                    }
                  )
                );
              },
              lessThanBalance: (value: number) =>
                value <= (balanceCents || 0) / 100 ||
                intl.formatMessage({
                  defaultMessage: 'Su saldo es menor al monto',
                  id: 'Qdd5DC',
                }),
              noInterestIncluded: (value: number) => {
                if (
                  value ===
                    centsToFloat(
                      selectedFinancingRequest?.owedAmountCents || 0
                    ) &&
                  (selectedFinancingRequest?.totalInterestAmountCents || 0) > 0
                ) {
                  return intl.formatMessage({
                    defaultMessage:
                      'No es posible pagar la totalidad del crédito sin intereses',
                    id: 'sIBUMk',
                  });
                }
              },
              noPartialInterestPayment: (value: number) => {
                if (
                  value >
                    centsToFloat(
                      selectedFinancingRequest?.owedAmountCents || 0
                    ) &&
                  value <
                    centsToFloat(
                      (selectedFinancingRequest?.owedAmountCents || 0) +
                        (selectedFinancingRequest?.totalInterestAmountCents ||
                          0)
                    )
                ) {
                  return intl.formatMessage({
                    defaultMessage:
                      'No es posible pagar los intereses parcialmente',
                    id: 'quDxW6',
                  });
                }
              },
            },
            setValueAs: (value: string) => parseCurrency(value),
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
        />

        <div>
          <Label colorStep="500">
            {intl.formatMessage({
              defaultMessage: 'Beneficiario',
              id: 'dtkaPZ',
            })}
          </Label>
          <Spacer margin="2" />
          <HigoConcentradoraAccount />
        </div>

        {selectedFinancingRequest && includeInterest && (
          <>
            <Spacer margin="8" />
            <FlexContainer justifyContent="space-between">
              <Text colorStep="500">
                {intl.formatMessage({
                  defaultMessage: 'Monto financiado:',
                  id: 'ViLMol',
                })}
              </Text>
              <Text>
                <Currency value={selectedFinancingRequest.owedAmountCents} />
              </Text>
            </FlexContainer>

            <Divider margin="2" />

            <FlexContainer justifyContent="space-between">
              <CenteredFlexContainer>
                <Text colorStep="500">
                  {intl.formatMessage({
                    defaultMessage: 'Tasa de interés',
                    id: 'SyXZeV',
                  })}
                </Text>
                <Spacer margin="2" direction="horizontal" />
                <CreditInterestTag
                  interestRateAmount={
                    selectedFinancingRequest.interestAmountCents || 0
                  }
                  interestRateAmountWithTaxes={
                    selectedFinancingRequest.totalInterestAmountCents || 0
                  }
                  annualInterestRate={
                    (selectedFinancingRequest.annualInterestRate || 0) * 100
                  }
                  ivaInterestAmount={
                    selectedFinancingRequest.ivaInterestAmountCents
                  }
                  moratoryInterestAmount={
                    selectedFinancingRequest.moratoryInterestAmountCents
                  }
                  annualMoratoryRate={
                    (selectedFinancingRequest.annualMoratoryRate || 0) * 100
                  }
                />
              </CenteredFlexContainer>
              <CenteredFlexContainer>
                <Text colorStep="500">
                  {`(${
                    selectedFinancingRequest.interestDays
                  } ${intl.formatMessage({
                    defaultMessage: 'Días',
                    id: 'THT5so',
                  })})`}
                </Text>
                <Spacer margin="1" direction="horizontal" />
                <Text>
                  <Currency
                    value={
                      selectedFinancingRequest.totalInterestAmountCents || 0
                    }
                  />
                </Text>
              </CenteredFlexContainer>
            </FlexContainer>
          </>
        )}
      </Card>
      <TermsCheckbox
        checked={termsApproved}
        onChange={() => setTermsApproved(!termsApproved)}
        label={intl.formatMessage({
          defaultMessage: 'Para pagar, acepto los',
          id: 'A5JncW',
        })}
      />
      <Button
        loading={loading}
        block
        type="submit"
        disabled={!isValid || !termsApproved || !balanceCents}
      >
        {intl.formatMessage({
          defaultMessage: 'Pagar',
          id: 'KFphJU',
        })}{' '}
        <Currency value={inputAmount * 100} />
      </Button>
    </form>
  );
}

export default PayFinancingForm;
