import { gql } from '@apollo/client';

import { BankingInfo } from '@src/types/models';

export const ACTIVATE_BUSINESS_RELATIONSHIPS_MUTATION = gql`
  mutation ActivateBusinessRelationships(
    $businessId: ID!
    $businessRelationships: [BusinessRelationshipInput!]!
  ) {
    activateBusinessRelationships(
      businessId: $businessId
      businessRelationships: $businessRelationships
    ) {
      id
    }
  }
`;

export type ActivateBusinessRelationshipsVariables = {
  businessId: string;
  businessRelationships: {
    id: string;
    paymentTerms?: number;
    bankingInfo?: BankingInfo;
    providerContact?: { email: string };
  }[];
};

export type ActivateBusinessRelationshipsReturn = {
  activateBusinessRelationships: {
    id;
  };
};

export default ACTIVATE_BUSINESS_RELATIONSHIPS_MUTATION;
