import styled, { css } from 'styled-components';
import { InputProps } from './types';

export const inputStyle = css<InputProps>`
  width: 100%;
  outline: none;
  border: none;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-family: ${({ theme }) => theme.fontFamily.sans};
  height: ${({ theme }) => theme.spacing[9]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  border-width: ${({ theme }) => theme.spacing.px};
  box-shadow: inset 0 0 0 1px
    ${({ error, theme }) => (error ? theme.colors.danger[500] : 'transparent')};
  padding: 0px
    ${({ theme, endIcon }) => (endIcon ? theme.spacing[10] : theme.spacing[4])}
    0px
    ${({ theme, startIcon }) =>
      startIcon ? theme.spacing[10] : theme.spacing[4]};
  transition: box-shadow 100ms ease-in;

  path {
    transition: fill 100ms ease-in;
  }

  &::placeholder {
    font-style: italic;
    color: ${({ theme }) => theme.colors.neutral[300]};
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.primary[500]};

    ~ .input-start-icon,
    ~ .input-end-icon {
      color: ${({ theme }) => theme.colors.primary[500]};
    }
  }

  &:disabled {
    opacity: ${({ theme }) => theme.opacity[40]};
    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral[300]};
    }
  }
`;

export const StyledInputContainer = styled.div<InputProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[50]};
  position: relative;
  width: ${({ theme, fullWidth }) => (fullWidth ? '100%' : theme.spacing[64])};

  .input-start-icon,
  .input-end-icon {
    width: ${({ theme }) => theme.spacing[6]};
    position: absolute;
    display: flex;
    align-items: center;
    color: ${({ theme, error }) =>
      error ? theme.colors.danger[500] : theme.colors.neutral[300]};
  }

  .input-start-icon {
    justify-content: flex-start;
    left: ${({ theme }) => theme.spacing['2.5']};
  }
  .input-end-icon {
    justify-content: flex-end;
    right: ${({ theme }) => theme.spacing['2.5']};
  }
`;

export const StyledInput = styled.input<{
  error?: boolean;
  startIcon?: boolean;
  endIcon?: boolean;
}>`
  ${inputStyle}
`;
