import styled from 'styled-components';

import { FlexRowContainer } from '@src/components/shared/layouts';

export const TopContent = styled(FlexRowContainer)`
  padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[8]}`};
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  max-height: ${({ theme }) => theme.spacing[8]};
  max-width: ${({ theme }) => theme.spacing[16]};
`;
