import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    defaultMessage: 'Integraciones',
    id: '49ZJi7',
  },
});

export const descriptions = defineMessages({
  quickBooks: {
    defaultMessage:
      'Sincroniza tu información automáticamente entre Higo y QuickBooks Online.',
    id: '/74Lwq',
  },
  netsuite: {
    defaultMessage:
      'Sincroniza tu información automáticamente entre Higo y NetSuite.',
    id: '79UY5a',
  },
  sap: {
    defaultMessage:
      'Sincroniza tu información automáticamente entre Higo y SAP Business One.',
    id: 'Df3gUS',
  },
});

export const buttons = defineMessages({
  quickBooks: {
    defaultMessage: 'Conectar a QuickBooks Online',
    id: 'JP4BLV',
  },
  netsuite: {
    defaultMessage: 'Conectar a NetSuite',
    id: 'sz+llD',
  },
  sap: {
    defaultMessage: 'Conectar a SAP Business One',
    id: 'ode2oJ',
  },
});
