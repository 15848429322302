import React from 'react';
import { useIntl } from 'react-intl';

import { Currency, FormattedDate } from '@src/components/i18n';
import { FlexContainer } from '@src/components/shared/layouts';
import { Card, Text } from '@src/components/ui';

import { WalletEntry } from '@src/types/models';

import { InfoBlock } from '..';
import { defaultDateFormat } from '@src/components/i18n/FormattedDate';

interface AdvancePaymentInfoProps {
  walletEntry: WalletEntry;
  amount: number;
}

function AdvancePaymentInfo({ walletEntry, amount }: AdvancePaymentInfoProps) {
  const { entryable, createdAt } = walletEntry;
  const { id, metadata } = entryable;

  const intl = useIntl();

  return (
    <Card depth="md" padding="4">
      <FlexContainer gap="56px" alignItems="center">
        <InfoBlock
          title={intl.formatMessage({
            id: 'RbQXdO',
            defaultMessage: 'ID Anticipo',
          })}
          data={id ?? '-'}
          iconName="Receipt"
        />
        <InfoBlock
          title={intl.formatMessage({
            id: 'RMvOYP',
            defaultMessage: 'Proveedor',
          })}
          data={metadata?.receiverName ?? '-'}
          iconName="Suitcase"
        />
        <InfoBlock
          title={intl.formatMessage({
            id: 'LfPlRc',
            defaultMessage: 'Fecha de anticipo',
          })}
          data={
            createdAt ? (
              <Text strong={true}>
                <FormattedDate value={createdAt} {...defaultDateFormat} />
              </Text>
            ) : (
              '-'
            )
          }
          iconName="Calendar"
        />
        <InfoBlock
          title={intl.formatMessage({
            id: 'rcEuYU',
            defaultMessage: 'Saldo de la transacción',
          })}
          data={
            <Text
              strong={true}
              color={amount < 0 ? 'danger' : 'info'}
              colorStep={amount < 0 ? '500' : '900'}
            >
              <Currency value={amount ?? 0} />
            </Text>
          }
          iconName="Money"
        />
      </FlexContainer>
    </Card>
  );
}

export default AdvancePaymentInfo;
