import styled, { css } from 'styled-components';

type ModalHeaderContainerProps = {
  sticky?: boolean;
};

export const ModalHeaderContainer = styled.div<ModalHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing[5]};
  background: ${({ theme }) => theme.colors.neutral[50]};
  height: ${({ theme }) => theme.spacing[19]};
  position: sticky;
  ${({ sticky }) =>
    sticky &&
    css`
      top: 0;
      z-index: ${({ theme }) => theme.zIndex[40]};
    `}
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing[6]};
  i {
    font-size: ${({ theme }) => theme.spacing[6]};
  }
`;

export const Title = styled.h3`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.xl};
`;
