import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
  height: 100vh;

  > div {
    height: 100%;
  }
`;

export const ConfirmationInfo = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[300]};
  border-radius: ${({ theme }) => theme.spacing[4]};
  margin: ${({ theme }) => theme.spacing[8]} 0;
`;

export const ContainerData = styled.div`
  padding: ${({ theme }) => theme.spacing[4]};
`;

export const CurrencyNumber = styled.p`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: 0;
`;
