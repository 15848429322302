import React, { useState, useMemo, useEffect } from 'react';
import toPairs from 'lodash/toPairs';
import { useIntl } from 'react-intl';

import * as S from './SelectProviders.styles';
import attributes from './attributes';
import ActivateBusinessRelationships from './components/ActivateBusinessRelationships/ActivateBusinessRelationships';
import OnboardingLayout from '@src/components/layouts/OnboardingLayout';
import TableFilter from '@src/components/shared/TableFilter';
import Button from '@src/components/ui/Button';
import Card from '@src/components/ui/Card';
import Header from '@src/components/ui/Header';
import TableCell from '@src/components/ui/TableCell';
import SelectableTableHeader from '@src/components/ui/TableHeader/SelectableTableHeader';
import TableHeaderCell from '@src/components/ui/TableHeaderCell';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import useSettings from '@src/hooks/useSettings';
import useSatBot from '@src/hooks/useSatBot';
import useSortFilters from '@src/hooks/useSortFilters';
import useBusinessRelationships from '@src/hooks/useBusinessRelationshipsQuery';
import { ChevronDown } from '@src/components/ui/Icon/outline';
import { trackPageView } from '@src/lib/analytics';
import usePageTrack from '@src/hooks/usePageTrack';
import useErrorReporter from '@src/hooks/useErrorReporter';
import Modal from '@src/components/shared/Modal';
import useModal from '@src/hooks/useModal';
import MoveInvoicesToPayModal from './components/MoveInvoicesToPayModal';
import BankingInfoModal from './components/BankingInfoModal';

import { SatStatus } from '@src/types/enums';
import type { BusinessRelationshipsFormInputs } from './components/ActivateBusinessRelationships/ActivateBusinessRelationships';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';

export function SelectProviders(props) {
  usePageTrack('supplier_activation', 'sign_up');
  const { business } = useSettings();
  const satBot = useSatBot();
  const intl = useIntl();
  const { open: openTermsModal } = useModal(ActivateBusinessRelationships, 40);
  const errorReporter = useErrorReporter();
  const [refetched, setRefetched] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [sortBy, handleSort, sortParams] = useSortFilters({
    field: 'invoicesCount',
    direction: 'descending',
  });
  const satSyncing = satBot?.satSyncStatus === SatStatus.SYNCING;
  const {
    loading: queryLoading,
    error,
    data,
    refetch,
  } = useBusinessRelationships({
    businessId: business?.id,
    sortBy,
  });
  const loading = queryLoading || satSyncing;
  const formatBusinessRelationships = useMemo(() => {
    const prov: BusinessRelationshipsFormInputs[] = [];
    data.businessRelationships.forEach((businessRelationship) => {
      if (selectedIds.includes(businessRelationship.id)) {
        prov.push({
          id: businessRelationship.id,
          taxPayerName:
            businessRelationship.provider?.defaultTaxPayerInfo.taxpayerName ||
            '',
          taxPayerIdentifier:
            businessRelationship.provider?.defaultTaxPayerInfo
              .taxpayerIdentifier || '',
          paymentTerms: '30',
          email: undefined,
          bankingInfo: undefined,
          invoicesCount: businessRelationship.invoicesCount,
        });
      }
    });
    return prov;
  }, [data.businessRelationships, selectedIds]);

  useEffect(() => {
    if (
      !refetched &&
      !loading &&
      satBot?.satSyncStatus &&
      satBot.satSyncStatus !== SatStatus.SYNCING &&
      data.businessRelationships.length === 0
    ) {
      refetch();
      setRefetched(true);
    }
  }, [satBot, data, loading, refetched]);

  useEffect(() => {
    // Intended for debugging only.
    if (!loading && refetched && data.businessRelationships.length === 0) {
      errorReporter('Supplier fetch after business creation', {
        satSyncStatus: satBot.satSyncStatus,
        satUpdatedAt: satBot.satUpdatedAt,
        taxPayerIdentifier: business?.taxPayerInfos?.[0]?.taxpayerIdentifier,
        businessId: business?.id,
      });
    }
  }, [refetched, data, loading]);

  useEffect(() => {
    trackPageView('supplier_activation', 'onboarding');
  }, []);

  const columns = {
    avatar: 30,
    provider: 60,
    provider2: 60,
    invoicesCount: 50,
  };
  const hasAllSelected =
    selectedIds.length > 0 &&
    data.businessRelationships.length > 0 &&
    selectedIds.length === data.businessRelationships.length;

  const fields = Object.keys(columns);
  const columnsWidth = Object.values(columns);
  columnsWidth.push(30);

  const handleHeaderClick = (id) => {
    if (attributes[id] && attributes[id].sortable) {
      handleSort(id);
    }
  };

  const handleSelectAll = () => {
    if (hasAllSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(data.businessRelationships.map(({ id }) => id));
    }
  };

  const handleSelect = (targetId) => {
    if (selectedIds.includes(targetId)) {
      setSelectedIds(selectedIds.filter((id) => id !== targetId));
    } else {
      setSelectedIds([targetId, ...selectedIds]);
    }
  };

  const handleNextClick = () => {
    openTermsModal({
      businessRelationships: formatBusinessRelationships,
      businessRelationshipsTotalCount: data.businessRelationships.length,
    });
  };

  return (
    <OnboardingLayout
      step={3}
      title={intl.formatMessage({
        defaultMessage: 'Selecciona a tus proveedores',
        id: 'MUZKTT',
      })}
    >
      <Header as="h3">
        {intl.formatMessage({
          defaultMessage: 'Selecciona a tus proveedores',
          id: 'MUZKTT',
        })}
        <span>.</span>
      </Header>
      <p>
        {intl.formatMessage(
          {
            defaultMessage:
              'Hemos encontrado {providersCount} proveedores que te han emitido alguna factura en el último mes. Selecciona de cuáles quieres ver sus facturas en tu buzón de entrada.',
            id: '56sX3R',
          },
          {
            providersCount: data.totalAmount,
          }
        )}
      </p>
      <Card padding="8">
        <S.Table columnsWidth={columnsWidth}>
          <SelectableTableHeader
            selectable
            selected={hasAllSelected}
            onSelect={handleSelectAll}
            tablePosition="end"
          >
            {toPairs(attributes).map(
              ([key, { name }]) =>
                fields.includes(key) && (
                  <TableHeaderCell
                    key={`${key}-header`}
                    onClick={() => {
                      handleHeaderClick(key);
                    }}
                  >
                    {name}
                    {attributes[key].sortable && (
                      <TableFilter
                        active={sortParams?.sort === key}
                        rotate={sortParams?.sortDirection === 'ascending'}
                      >
                        <ChevronDown />
                      </TableFilter>
                    )}
                  </TableHeaderCell>
                )
            )}{' '}
          </SelectableTableHeader>

          <tbody>
            {loading &&
              [...Array(8).keys()].map((i) => (
                <SelectableTableRow
                  disabled
                  key={`businessRelationship-${i}-row`}
                  selectable
                >
                  {toPairs(attributes).map(
                    ([key]) =>
                      fields.includes(key) && (
                        <TableCell
                          key={`businessRelationship-${i}-${key}`}
                          loading
                        />
                      )
                  )}
                </SelectableTableRow>
              ))}

            {!loading &&
              data.businessRelationships.map((businessRelationship) => (
                <SelectableTableRow
                  key={`businessRelationship-${businessRelationship.id}-row`}
                  selectable
                  selected={selectedIds.includes(businessRelationship.id)}
                  tablePosition="end"
                  onSelect={() => handleSelect(businessRelationship.id)}
                >
                  {toPairs(attributes).map(
                    ([key, { render }]) =>
                      fields.includes(key) && (
                        <TableCell
                          loading={loading}
                          key={`businessRelationship-${businessRelationship.id}-${key}`}
                        >
                          {render && render(businessRelationship, props)}
                        </TableCell>
                      )
                  )}
                </SelectableTableRow>
              ))}
          </tbody>
        </S.Table>
        <Spacer margin="8" />
        <FlexContainer justifyContent="flex-end">
          <Button onClick={handleNextClick} disabled={selectedIds.length === 0}>
            {intl.formatMessage({
              defaultMessage: 'Siguiente',
              id: 'w2xatL',
            })}
          </Button>
        </FlexContainer>
      </Card>

      <Modal
        component={ActivateBusinessRelationships}
        type="fullscreen"
        zIndex={40}
      />
      <Modal component={BankingInfoModal} zIndex={50} />
      <Modal component={MoveInvoicesToPayModal} zIndex={50} />
    </OnboardingLayout>
  );
}

export default SelectProviders;
