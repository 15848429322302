import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Receipt: Icon = forwardRef(
  ({ color = 'currentColor', size = 16, ...rest }, ref: any) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={ref}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        {...rest}
      >
        <g clipPath="url(#clip0_2731_135748)">
          <path
            d="M14 15.9333L11.558 14.2593L9.782 15.476L8.00466 14.2587L6.22733 15.4747L4.44667 14.2573L2 15.9333V2C2 1.46957 2.21071 0.960859 2.58579 0.585786C2.96086 0.210714 3.46957 0 4 0L12 0C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V15.9333ZM4.44667 12.6413L6.22666 13.8587L8.00466 12.6413L9.782 13.8587L11.5587 12.6407L12.6667 13.4V2C12.6667 1.82319 12.5964 1.65362 12.4714 1.5286C12.3464 1.40357 12.1768 1.33333 12 1.33333H4C3.82319 1.33333 3.65362 1.40357 3.52859 1.5286C3.40357 1.65362 3.33333 1.82319 3.33333 2V13.4L4.44667 12.6413Z"
            fill={color}
          />
          <path
            d="M11.3333 5.33203H4.66666V6.66537H11.3333V5.33203Z"
            fill={color}
          />
          <path d="M10 8H4.66666V9.33334H10V8Z" fill={color} />
        </g>
        <defs>
          <clipPath id="clip0_2731_135748">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Receipt.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Receipt.displayName = 'Receipt';

export default Receipt;
