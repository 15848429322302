import { MutationHookOptions, useMutation } from '@apollo/client';
import { EXPORT_TRANSACTIONS_PDF } from '@src/graphql/mutations';
import {
  ExportTransactionsPDFInput,
  ExportTransactionsPDFResponse,
} from '@src/graphql/mutations/exportTransactionsPDF';

function useExportTransactionPDFMutation(
  options?:
    | MutationHookOptions<
        ExportTransactionsPDFResponse,
        ExportTransactionsPDFInput
      >
    | undefined
) {
  const [mutate, { data, loading, error }] = useMutation<
    ExportTransactionsPDFResponse,
    ExportTransactionsPDFInput
  >(EXPORT_TRANSACTIONS_PDF, options);
  return {
    mutate,
    data,
    loading,
    error,
  };
}

export default useExportTransactionPDFMutation;
