import styled from 'styled-components';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const Link = styled(RouterLink)<LinkProps>`
  color: ${({ theme }) => theme.colors.primary[500]};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.primary[500]};
    text-decoration: underline;
  }
`;

export default Link;
