import { gql } from '@apollo/client';
import { InvoicePaymentType } from '@src/types/enums';

export const CREATE_PAYMENT_INSTRUCTION_DRAFT = gql`
  mutation CreatePaymentInstructionDraft(
    $businessId: ID!
    $paymentInstructionInvoices: [PaymentInstructionInvoicesInput!]!
  ) {
    createPaymentInstructionDraft(
      businessId: $businessId
      paymentInstructionInvoices: $paymentInstructionInvoices
    ) {
      success
    }
  }
`;

export type CreatePaymentInstructionsDraftQueryReturn = {
  success: boolean;
};

export type CreatePaymentInstructionsDraftQueryVariables = {
  businessId: string;
  paymentInstructionInvoices: Array<{
    invoiceId: string;
    providerEmails: string[];
    amountCents?: number;
    paymentMethod?:
      | InvoicePaymentType.HIGO_PAYMENT
      | InvoicePaymentType.STP_PAYMENT
      | InvoicePaymentType.REQUEST_BANKING_INFO;
    clabe?: string;
    paymentConcept?: string;
  }>;
};
