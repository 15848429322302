import React, { forwardRef } from 'react';
import FormControl from '@src/components/ui/FormControl';
import Input from '@src/components/ui/Input';
import CurrencyInput from '@src/components/ui/CurrencyInput';
import PasswordInput from '@src/components/ui/PasswordInput';
import PhoneInput from '@src/components/ui/PhoneInput';
import Select from '@src/components/ui/Select';
import { TextFieldProps } from './types';

function TextField(props: TextFieldProps, ref) {
  const {
    error,
    label,
    type,
    required,
    id,
    helperText,
    fullWidth,
    hiddenLabel,
  } = props;

  let input;
  switch (type) {
    case 'password':
      input = <PasswordInput {...props} ref={ref} />;
      break;
    case 'currency':
      input = <CurrencyInput {...props} ref={ref} />;
      break;
    case 'tel':
      input = <PhoneInput {...props} ref={ref} />;
      break;
    case 'select':
      input = <Select {...props} ref={ref} />;
      break;
    default:
      input = <Input {...props} ref={ref} />;
      break;
  }
  return (
    <FormControl
      fullWidth={fullWidth}
      id={id}
      error={error}
      label={label}
      required={required}
      helperText={helperText}
      hiddenLabel={hiddenLabel}
    >
      {input}
    </FormControl>
  );
}

export default forwardRef(TextField);
