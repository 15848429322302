import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const FileZIP: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path
          fillRule="evenodd"
          d="M6.04651 23H18.8372C19.9675 23 20.8837 22.0837 20.8837 20.9535V7.1907C20.8837 6.6652 20.6816 6.15984 20.3192 5.77931L16.3723 1.63512C15.9861 1.22955 15.4504 1 14.8904 1H6.04651C4.91625 1 4 1.91625 4 3.04651V20.9535C4 22.0837 4.91625 23 6.04651 23ZM19.1679 6.19121L15.6925 2.71577C15.5314 2.55462 15.2558 2.66875 15.2558 2.89666V6.37209C15.2558 6.51338 15.3703 6.62791 15.5116 6.62791H18.9871C19.215 6.62791 19.3291 6.35236 19.1679 6.19121ZM7.5814 16.8604H11.4077V15.911H9.05996L11.4101 12.6893V12.0232H7.58376V12.9727H9.9315L7.5814 16.1944V16.8604ZM13.2943 12.0232H12.1251V16.8604H13.2943V12.0232ZM15.1974 16.8604H14.0283V12.0232H16.0264C17.1129 12.0232 17.7908 12.7035 17.7908 13.6978C17.7908 14.7016 17.0987 15.3583 15.991 15.3583H15.1974V16.8604ZM15.1974 12.9656V14.4371H15.7737C16.2981 14.4371 16.5791 14.1442 16.5791 13.6978C16.5791 13.2491 16.2981 12.9656 15.7737 12.9656H15.1974Z"
        />
      </svg>
    );
  }
);

FileZIP.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FileZIP.displayName = 'FileZIP';

export default FileZIP;
