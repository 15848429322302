import React from 'react';
import { Control, useWatch } from 'react-hook-form';

import {
  NewSTPAccount,
  STPAccount,
} from '@src/components/shared/AccountInformation';
import BankingInfoModal from '../BankingInfoModal';
import useModal from '@src/hooks/useModal';
import * as S from './BankingInfoField.styles';

import { BankingInfo } from '@src/types/models';

interface BankingInfoFieldProps {
  control: Control<any>;
  name: string;
  taxpayerName: string;
  onSubmit: (bankingInfo: BankingInfo) => void;
}

function BankingInfoField({
  control,
  name,
  taxpayerName,
  onSubmit,
}: BankingInfoFieldProps) {
  const { open: openBankingForm } = useModal(BankingInfoModal, 50);
  const bankingInfo = useWatch({ control, name });
  return bankingInfo?.clabe ? (
    <STPAccount
      bankName={bankingInfo?.bankName}
      logoUrl={bankingInfo?.bankLogoUrl}
      clabe={bankingInfo?.clabe}
    />
  ) : (
    <S.Button
      type="button"
      onClick={() => {
        openBankingForm({
          taxpayerName,
          onSubmit,
        });
      }}
    >
      <NewSTPAccount />
    </S.Button>
  );
}

export default BankingInfoField;
