import React from 'react';
import { useIntl } from 'react-intl';

import DownloadSuccess from '@src/components/shared/DownloadSuccess';
import DownloadForm from '../DownloadForm';
import { Header } from '@src/components/ui';
import * as S from './DownloadTransactionsPDF.styles';
import useSettings from '@src/hooks/useSettings';
import useModal from '@src/hooks/useModal';
import useExportTransactionMutationPDF from '@src/hooks/useExportTransactionMutationPDF';
import { AcceptedFormat } from '@src/components/shared/DownloadSuccess/DownloadSuccess';

interface DownloadTransactionsPDFProps {
  totalCount: number;
  filterCount: number;
  selectedCount: number;
  hasAllSelected?: boolean;
  walletEntriesId?: string[];
  filters?: any;
  onClose?: () => void;
}
function DownloadTransactionsPDF({
  totalCount,
  walletEntriesId,
  filterCount,
  selectedCount,
  filters,
  onClose,
}: DownloadTransactionsPDFProps) {
  const intl = useIntl();
  const currentUser = useSettings('currentUser');
  const business = useSettings('business');
  const { open } = useModal(DownloadSuccess);

  const { mutate: exportTransactions } = useExportTransactionMutationPDF();

  const onSubmit = async (data) => {
    if (data.option === 'filtered') {
      await exportTransactions({
        variables: {
          businessId: business?.id,
          status: filters?.status,
          providerId: filters?.providerId,
          createdAtFrom: filters?.createdAtFrom,
          createdAtTo: filters?.createdAtTo,
        },
      });
    } else if (data.option === 'selected') {
      await exportTransactions({
        variables: {
          businessId: business?.id,
          walletEntriesIds: walletEntriesId,
        },
      });
    } else {
      await exportTransactions({
        variables: {
          businessId: business?.id,
        },
      });
    }

    open({
      email: currentUser.email,
      acceptedFormat: AcceptedFormat.PDF,
    });
  };

  return (
    <S.DownloadTransactionsPDF>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Descargar comprobantes en PDF',
          id: '6XkmHb',
        })}
      </Header>
      <S.Subtitle>
        {intl.formatMessage({
          defaultMessage:
            'Se descargarán los comprobantes de tus transacciones en archivo .PDF',
          id: 'EG38et',
        })}
      </S.Subtitle>

      <DownloadForm
        onSubmit={onSubmit}
        totalCount={totalCount}
        filterCount={filterCount}
        selectedCount={selectedCount}
        onClose={onClose}
        filters={filters}
      />
    </S.DownloadTransactionsPDF>
  );
}

DownloadTransactionsPDF.displayName = 'DownloadTransactionsPDF';

export default DownloadTransactionsPDF;
