import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const CheckCircle: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM13.9783 8.44439C14.2697 8.08819 14.2172 7.56317 13.861 7.27173C13.5048 6.98029 12.9798 7.03279 12.6884 7.38899L9.41232 11.393L7.12232 9.89754C6.73698 9.64589 6.22059 9.75426 5.96894 10.1396C5.71728 10.5249 5.82566 11.0413 6.211 11.293L9.12767 13.1978C9.48412 13.4305 9.95871 13.3572 10.2283 13.0277L13.9783 8.44439Z"
          fill={color}
        />
      </svg>
    );
  }
);

CheckCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckCircle.displayName = 'CheckCircle';

export default CheckCircle;
