import React from 'react';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Currency } from '@src/components/i18n/Currency';
import PayFinancingModal from '@src/components/pages/WalletView/components/PayFinancingModal';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { Button, Label, Skeleton } from '@src/components/ui';
import CircledIcon from '@src/components/ui/CircledIcon';
import { ArrowRightLine } from '@src/components/ui/Icon/outline';
import { Smile } from '@src/components/ui/Icon/solid';
import Calendar from '@src/components/ui/Icon/solid/Calendar';
import Warning from '@src/components/ui/Icon/solid/Warning';
import useModal from '@src/hooks/useModal';
import * as S from './DuePaymentsWidget.styles';
import { PAY_INDIVIDUAL_CREDIT_CLICKED } from '@src/constants/events';
import { trackEvent } from '@src/lib/analytics';
import useCreditAccount from '@src/hooks/useCreditAccount';

function DuePaymentsWidget() {
  const intl = useIntl();
  const { open } = useModal(PayFinancingModal);
  const [creditAccount, { loading, error }] = useCreditAccount();

  const financingRequest = creditAccount?.nextFinancingRequest;

  if (error) {
    return (
      <S.ErrorContainer horizontalPadding="6" fullHeight fullWidth>
        <CircledIcon backgroundColor="primary" backgroundColorStep="50">
          <Calendar size={20} />
        </CircledIcon>
        <S.ErrorMessageContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <S.NoMarginLabel size="lg" strong color="neutral" colorStep="600">
            {intl.formatMessage({
              defaultMessage: 'La información no está disponible',
              id: 'ev3mC6',
            })}
          </S.NoMarginLabel>
          <Label size="md" color="neutral" colorStep="500">
            {intl.formatMessage({
              defaultMessage: 'Por favor, prueba volviendo a cargar la página',
              id: 'YgaISp',
            })}
          </Label>
        </S.ErrorMessageContainer>
      </S.ErrorContainer>
    );
  }

  if (loading) {
    return (
      <S.LoadingContainer
        horizontalPadding="6"
        fullHeight
        fullWidth
        data-testid="due-payments-widget-loading"
      >
        <CircledIcon backgroundColor="primary" backgroundColorStep="50">
          <Calendar size={20} />
        </CircledIcon>
        <Spacer margin="4" direction="horizontal" />
        <S.SkeletonContainer>
          <Skeleton />
          <Spacer margin="1" direction="vertical" />
          <Skeleton />
        </S.SkeletonContainer>
      </S.LoadingContainer>
    );
  }

  if (!financingRequest) {
    return (
      <S.CardContainer horizontalPadding="6" fullHeight fullWidth>
        <CircledIcon backgroundColor="primary" backgroundColorStep="50">
          <Smile size={20} />
        </CircledIcon>
        <Spacer margin="4" direction="horizontal" />
        <div>
          <S.NoMarginHeader as="h5" color="neutral" colorStep="900">
            {intl.formatMessage({
              defaultMessage: 'Estás al día con tus pagos',
              id: '1OCpj2',
            })}
          </S.NoMarginHeader>
          <Label size="md" color="neutral" colorStep="500">
            {intl.formatMessage({
              defaultMessage:
                'Te avisaremos cuando tengas una próxima fecha de pago.',
              id: '8zE6C0',
            })}
          </Label>
        </div>
      </S.CardContainer>
    );
  }

  const isCreditOverdue = financingRequest.overdue;
  const creditDueDate =
    financingRequest?.dueDate &&
    format(new Date(financingRequest.dueDate), 'dd LLL yyyy');

  const openPayFinancingModal = () => {
    const creditId = financingRequest.id;
    const eventData = {
      view: 'CreditWidgets',
      credit_id: creditId,
    };

    trackEvent(PAY_INDIVIDUAL_CREDIT_CLICKED, eventData);
    open({
      defaultRequest: financingRequest,
    });
  };

  if (isCreditOverdue) {
    return (
      <S.CardContainer horizontalPadding="6" fullHeight fullWidth>
        <CircledIcon backgroundColor="danger" backgroundColorStep="50">
          <Warning size={20} />
        </CircledIcon>
        <Spacer margin="4" direction="horizontal" />
        <div>
          <Label size="md" color="danger" colorStep="500">
            {intl.formatMessage(
              {
                defaultMessage: 'Venció el {date}',
                id: '/sfd4R',
              },
              {
                date: creditDueDate,
              }
            )}
          </Label>
          <S.NoMarginHeader as="h5" color="danger" colorStep="500">
            <Currency value={financingRequest.owedTotalCents || 0} />
          </S.NoMarginHeader>
        </div>
        <S.FlexSpacer />
        <Button
          variant="ghosted"
          onClick={openPayFinancingModal}
          startIcon={<ArrowRightLine size={16} />}
        >
          {intl.formatMessage({
            defaultMessage: 'Pagar crédito',
            id: 'UKACVk',
          })}
        </Button>
      </S.CardContainer>
    );
  }

  return (
    <S.CardContainer horizontalPadding="6" fullHeight fullWidth>
      <CircledIcon color="primary" backgroundColorStep="50">
        <Calendar size={20} />
      </CircledIcon>
      <Spacer margin="4" direction="horizontal" />
      <FlexContainer justifyContent="center" flexDirection="column">
        <Label size="md" color="neutral" colorStep="500">
          {intl.formatMessage(
            {
              defaultMessage: 'Por vencer el {date}',
              id: 'tJsWE2',
            },
            {
              date: creditDueDate,
            }
          )}
        </Label>
        <S.NoMarginHeader as="h5" color="neutral" colorStep="900">
          <Currency value={financingRequest.owedTotalCents || 0} />
        </S.NoMarginHeader>
      </FlexContainer>
      <S.FlexSpacer />
      <Button
        variant="ghosted"
        onClick={openPayFinancingModal}
        startIcon={<ArrowRightLine size={16} />}
      >
        {intl.formatMessage({
          defaultMessage: 'Pagar crédito',
          id: 'UKACVk',
        })}
      </Button>
    </S.CardContainer>
  );
}

export default DuePaymentsWidget;
