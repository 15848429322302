import { gql } from '@apollo/client';
import { CostCenter } from '@src/types/models';
import { PaginatedResponse } from '@src/types/utils';

export const COST_CENTERS_QUERY = gql`
  query CostCenterData($businessId: ID!) {
    costCenters(businessId: $businessId) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export type CostCentersQueryVariables = {
  businessId?: string;
};

export type CostCentersQueryReturn = {
  costCenters: PaginatedResponse<Pick<CostCenter, 'id' | 'name'>>;
};

export default COST_CENTERS_QUERY;
