import styled from 'styled-components';
import { Link as RRDLink } from 'react-router-dom';

interface LinkProps {
  $block?: boolean;
}

export const Link = styled(RRDLink)<LinkProps>`
  display: ${({ $block }) => $block && 'block'};
  width: ${({ $block }) => $block && '100%'};
`;
