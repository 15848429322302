import { TabList as ReactTabList } from 'react-tabs';
import styled from 'styled-components';

const TabList = styled(ReactTabList)`
  position: relative;
  display: flex;
  margin-bottom: 0 !important;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
`;

export default TabList;
