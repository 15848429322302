import styled, { css } from 'styled-components';

type StyledTableProps = {
  fixedLayout?: boolean;
  stickyHeader?: boolean;
  minHeight?: string | number;
};

export const StyledTable = styled.div<StyledTableProps>`
  width: 100%;
  overflow-x: auto;
  min-height: ${({ minHeight }) => minHeight && minHeight};

  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    position: relative;
    display: table;
    ${({ fixedLayout }) => fixedLayout && 'table-layout: fixed;'}

    th,
    td {
      border-collapse: collapse;
    }
    th,
    td {
      padding: 0 ${({ theme }) => theme.spacing[4]};
    }

    th:last-child,
    th:first-child,
    td:last-child,
    td:first-child {
      padding: 0 ${({ theme }) => theme.spacing[8]};
    }

    thead {
      display: table-header-group;
    }

    th {
      ${({ stickyHeader }) =>
        stickyHeader &&
        css`
          position: sticky;
          top: 0px;
        `};
    }
  }
`;
