import React from 'react';

import { StyledTableHeader } from './TableHeader.styles';

import type { TableHeaderProps } from './types';

export default function TableHeader(props: TableHeaderProps) {
  const { children, ...otherProps } = props;

  return (
    <StyledTableHeader {...otherProps}>
      <tr>{children}</tr>
    </StyledTableHeader>
  );
}
