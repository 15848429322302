import { defineMessages } from 'react-intl';

export default defineMessages({
  CostCenterEvent: {
    defaultMessage: 'Factura configurada',
    id: 'ToSmgZ',
  },
  InvoiceManualPaymentEvent: {
    defaultMessage: 'Pago manual',
    id: 'spOcNl',
  },
  InvoiceSTPPaymentEvent: {
    defaultMessage: 'Pago enviado',
    id: 'Et3gOY',
  },
  EDITED: {
    defaultMessage: 'Factura actualizada',
    id: 'FZAoOc',
  },
  APPROVED: {
    defaultMessage: 'Factura aprobada',
    id: '9U8hij',
  },
  FINANCE_REQUESTED: {
    defaultMessage: 'Factura con financiamiento solicitado',
    id: 'OIvscV',
  },
  REJECTED: {
    defaultMessage: 'Factura rechazada',
    id: 'xrzb9N',

    description:
      'HistoricalRecord title for REJECTED InvoiceStatusEvent notificacion',
  },
  PENDING: {
    defaultMessage: 'Factura pendiente',
    id: '+e9dLq',
  },
  PENDING_REVISION: {
    defaultMessage: 'Factura pendiente para revisar',
    id: '7Zpnah',
  },
  APPROVED_message: {
    defaultMessage: 'aprobado',
    id: '5OcW/I',
  },
  EDITED_message: {
    defaultMessage: 'actualizada',
    id: 'sQElBf',
  },
  REJECTED_message: {
    defaultMessage: 'rechazado',
    id: 'C4+UdC',
  },
  PENDING_message: {
    defaultMessage: 'pendiente',
    id: 'YvurJu',
  },
  FINANCE_REQUESTED_message: {
    defaultMessage: 'solicitado financiamiento de',
    id: 'iN5enL',
  },
  PENDING_REVISION_message: {
    defaultMessage: 'actualizado a pendiente de revisar',
    id: 'wB4h94',
  },
  RECEIVED_EVENT: {
    defaultMessage: 'Factura recibida',
    id: 'VPV2DL',
  },
});
