import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import Supplier from '@src/components/pages/Contacts/components/Supplier';
import PaginationControls from '@src/components/shared/PaginationControls';
import EmptyResults from '@src/components/shared/EmptyResults';

import { SupplierRelationship } from '@src/types/models';

interface SupplierResultsProps {
  data?: any;
  loading?: boolean;
  error?: any;
  count?: number;
  hasNext?: any;
  hasPrevious?: any;
  next?: any;
  previous?: any;
  page?: number;
  limit?: number;
  emptyElement?: React.ReactNode;
  onDelete?: () => void;
  onCreate?: () => void;
  onClick?: (supplier: SupplierRelationship) => void;
}

function SupplierResults({
  onDelete,
  onClick,
  onCreate,
  data,
  loading,
  error,
  count,
  hasNext,
  hasPrevious,
  next,
  previous,
  page,
  limit,
  emptyElement,
}: SupplierResultsProps) {
  const intl = useIntl();

  return (
    <div>
      {loading
        ? [...Array(limit).keys()].map((n) => <Supplier key={n} loading />)
        : data.length > 0 &&
          data.map((provider) => (
            <Supplier
              key={provider.supplier.hashId}
              datum={provider}
              onCreate={onCreate}
              onDelete={onDelete}
              onClick={onClick}
            />
          ))}

      <PaginationControls
        limit={limit}
        loading={loading}
        page={page}
        itemCount={data.length}
        count={count}
        hasNext={hasNext}
        onNext={next}
        hasPrevious={hasPrevious}
        onPrevious={previous}
      />

      {!loading &&
        data.length === 0 &&
        (emptyElement || (
          <EmptyResults
            label={intl.formatMessage(messages.suppliersEmptyState)}
          />
        ))}
    </div>
  );
}

export default SupplierResults;
