import { UserRole } from '@src/types/enums';
import { CurrentUser } from '@src/types/models';

const userIsAdmin = (currentUser?: CurrentUser) => {
  if (!currentUser) {
    return false;
  }

  return currentUser.role === UserRole.ADMIN;
};

export default userIsAdmin;
