import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const FileXML: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path
          fillRule="evenodd"
          d="M6.04651 23H18.8372C19.9675 23 20.8837 22.0837 20.8837 20.9535V7.1907C20.8837 6.6652 20.6816 6.15984 20.3192 5.77931L16.3723 1.63512C15.9861 1.22955 15.4504 1 14.8904 1H6.04651C4.91625 1 4 1.91625 4 3.04651V20.9535C4 22.0837 4.91625 23 6.04651 23ZM19.1679 6.19121L15.6925 2.71577C15.5314 2.55462 15.2558 2.66875 15.2558 2.89666V6.37209C15.2558 6.51338 15.3703 6.62791 15.5116 6.62791H18.9871C19.215 6.62791 19.3291 6.35236 19.1679 6.19121ZM14.3829 13.1408H15.2914C15.288 12.4543 14.7586 12 13.9115 12C13.0798 12 12.4923 12.4475 12.4974 13.1135C12.4957 13.66 12.8749 13.964 13.4948 14.0972L13.8432 14.1724C14.2326 14.2578 14.3658 14.3551 14.3692 14.5139C14.3658 14.6864 14.2087 14.8077 13.9047 14.8077C13.5409 14.8077 13.3257 14.6369 13.3104 14.3158H12.4086C12.412 15.1936 13.0064 15.5864 13.9184 15.5864C14.8047 15.5864 15.3359 15.2039 15.3393 14.5139C15.3359 13.9811 15.0182 13.6071 14.2463 13.4414L13.9593 13.3799C13.628 13.3099 13.4794 13.2126 13.488 13.0452C13.4897 12.8915 13.6178 12.7788 13.9115 12.7788C14.2189 12.7788 14.3641 12.9137 14.3829 13.1408ZM6.03416 12.0478H7.09984L7.67367 13.0657H7.701L8.28166 12.0478H9.34051L8.29532 13.7966L9.3815 15.5455H8.29532L7.701 14.5071H7.67367L7.07935 15.5455H6L7.07252 13.7966L6.03416 12.0478ZM12.1136 15.5455H9.74997V12.0478H10.6995V14.7803H12.1136V15.5455ZM15.6527 12.0478H16.7183L17.2922 13.0657H17.3195L17.9002 12.0478H18.959L17.9138 13.7966L19 15.5455H17.9138L17.3195 14.5071H17.2922L16.6978 15.5455H15.6185L16.691 13.7966L15.6527 12.0478Z"
        />
      </svg>
    );
  }
);

FileXML.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FileXML.displayName = 'FileXML';

export default FileXML;
