import React from 'react';

import { StyledTable } from './Table.styles';

import type { TableProps } from './types';

export default function Table(props: TableProps) {
  const { children, stickyHeader, columnsWidth, ...otherProps } = props;

  return (
    <StyledTable
      stickyHeader={stickyHeader}
      fixedLayout={!!columnsWidth}
      {...otherProps}
    >
      <table>
        {columnsWidth && (
          <colgroup>
            {columnsWidth.map((width, i) => (
              <col width={width} key={`${width}-${i}`} />
            ))}
          </colgroup>
        )}
        {children}
      </table>
    </StyledTable>
  );
}
