import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import * as S from './SynchronizeSatInvoices.styles';
import { INITIALIZE_INVOICES_SYNC_MUTATION } from '@src/graphql/mutations/initializeInvoicesSync';
import { trackEvent } from '@src/lib/analytics';
import { INITIALIZE_INVOICES_SYNC } from '@src/constants/events';
import useSettings from '@src/hooks/useSettings';
import { Header, TextField, Tooltip, Button } from '@src/components/ui';
import useSatBot from '@src/hooks/useSatBot';
import useToast from '@src/hooks/useToast';

import { ReactComponent as Illustration } from './img/illustration.svg';

type SynchronizeSatInvoicesProps = {
  onClose?: () => void;
};

export const eventData = {
  view: 'SynchronizeSatInvoicesContainer',
};

export function SynchronizeSatInvoices({
  onClose,
}: SynchronizeSatInvoicesProps) {
  const { formatMessage } = useIntl();
  const business = useSettings('business');
  const { id } = useSatBot();
  const { toast } = useToast();
  const [mutate, { loading }] = useMutation(INITIALIZE_INVOICES_SYNC_MUTATION);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const onSubmit = async ({ taxPayerInfoPassword }) => {
    if (business?.id) {
      try {
        await mutate({
          variables: {
            businessId: business.id,
            taxPayerInfoId: id,
            taxPayerInfoPassword,
          },
        });
        toast.success(
          formatMessage({
            defaultMessage: 'Contraseña del SAT guardada con éxito',
            id: '2qrFt+',
          }),
          formatMessage({
            defaultMessage:
              'Hemos guardado tu contraseña y estamos sincronizando tus facturas',
            id: 'XPaErD',
          })
        );
        toast.success(
          formatMessage({
            defaultMessage: 'Iniciando sincronización con el SAT',
            id: 'qz5Dyt',
          }),
          formatMessage({
            defaultMessage:
              'Recibirás un correo cuando el proceso haya terminado',
            id: 'Y86IL7',
          })
        );

        trackEvent(INITIALIZE_INVOICES_SYNC, eventData);

        onClose && onClose();
      } catch (e) {
        toast.error(
          formatMessage({
            defaultMessage: 'Error al intentar sincronizar con el SAT',
            id: 'yAq2r9',
          }),
          formatMessage({
            defaultMessage:
              'Verifica que la contraseña CIEC sea correcta accediendo al portal del SAT',
            id: 'e/DlEq',
          })
        );
      }
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.Header>
        <Illustration />
        <Header as="h4">
          {formatMessage({
            defaultMessage: 'Sincroniza tu buzón tributario del SAT',
            id: 'T3Y1ME',
          })}
        </Header>
        <p>
          {formatMessage({
            defaultMessage:
              'Agrega tu contraseña CIEC para sincronizarnos directamente con tu buzón tributario del SAT y traer todas tus facturas',
            id: 'yXHXaK',
          })}
        </p>
      </S.Header>
      <TextField
        {...register('taxPayerInfoPassword', {
          required: formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        fullWidth
        required
        type="password"
        label={formatMessage({
          defaultMessage: 'Contraseña CIEC',
          id: 'bNmcp4',
        })}
        placeholder="**********"
        helperText={
          errors?.taxPayerInfoPassword?.message || (
            <Tooltip
              id="signup_tooltip"
              place="top"
              width={400}
              title={formatMessage({
                defaultMessage:
                  'Tu contraseña CIEC la utilizamos únicamente para verificar que la información de tus facturas y proveedores es real y poder determinar tu capacidad de endeudamiento.',
                id: 'H2TpmL',
              })}
            >
              <S.TooltipSpan>
                {formatMessage({
                  defaultMessage: '¿Para que te pedimos la contraseña CIEC?',
                  id: 'OtcRJr',
                })}
              </S.TooltipSpan>
            </Tooltip>
          )
        }
        error={!!errors?.taxPayerInfoPassword?.message}
        autoFocus
      />

      <Button type="submit" loading={loading} block>
        {formatMessage({
          defaultMessage: 'Sincronizar SAT',
          id: 'jO1q9l',
        })}
      </Button>
    </S.Form>
  );
}

SynchronizeSatInvoices.displayName = 'SynchronizeSatInvoices';

export default SynchronizeSatInvoices;
