import { trackPageView } from '@src/lib/analytics';
import { useEffect } from 'react';
import { setTag } from '@sentry/react';

const usePageTrack = (page: string, category: string) => {
  useEffect(() => {
    trackPageView(page, category);
  }, []);

  useEffect(() => {
    setTag('component', page);
    return () => {
      setTag('component', undefined);
    };
  }, []);
};

export default usePageTrack;
