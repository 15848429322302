import styled from 'styled-components';

export const Form = styled.form`
  width: 27.5rem;
  @media (max-width: 576px) {
    width: 100vw;
    padding: 0 1rem;
  }

  .terms {
    margin: ${({ theme }) => theme.spacing[4]} 0;
  }
`;
