import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as S from './BankingInformation.styles';
import useToast from '@src/hooks/useToast';
import Header from '@src/components/ui/Header';
import Button from '@src/components/ui/Button';
import Alert from '@src/components/ui/Alert';
import Modal from '@src/components/shared/Modal';
import BusinessUserValidationModal from '@src/components/shared/modals/BusinessUserValidationModal';
import OnboardingLayout from '@src/components/layouts/OnboardingLayout';
import BankingInfoValidation from '@src/components/shared/BankingInfoValidation';
import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import useBusinessBankingInfoValidation from '@src/hooks/useBusinessBankingInfoValidation';
import useQueryParams from '@src/hooks/useQueryParams';
import useBusinessUserValidationToken from '@src/hooks/useBusinessUserValidationToken';

export const BankingInformation = () => {
  const { open: openBankingInfo } = useModal(BusinessUserValidationModal);
  const user = useSettings('currentUser');
  const history = useHistory();
  const [query] = useQueryParams();
  const { toast } = useToast();
  const {
    mutate: validateUser,
    loading,
    error,
  } = useBusinessUserValidationToken();

  const callBusinessUserValidationToken = async () => {
    try {
      await validateUser({
        variables: {
          token: query?.token,
        },
      });
    } catch (e: any) {
      toast.error('Error', e.message);
    }
  };

  useEffect(() => {
    if (!user?.businessUserValidation && query?.token) {
      callBusinessUserValidationToken();
    }
  }, []);

  const business = user?.businessUserValidation.business;

  const {
    isVerified,
    isRejected,
    isValidating,
    bankingInfo,
    rejectedAccount,
  } = useBusinessBankingInfoValidation(business);

  if (!user?.businessUserValidation || loading) {
    return (
      <OnboardingLayout step={2} title="Banking Information" isPayeeFlow>
        <Alert color="danger">{error?.message}</Alert>
      </OnboardingLayout>
    );
  }

  const onAddClick = () => {
    openBankingInfo({
      taxpayerIdentifier:
        business.hasTaxPayerInfo &&
        business.taxPayerInfos[0].taxpayerIdentifier,
      recipient: business?.name,
    });
  };

  const showActions = !!bankingInfo && !isRejected;

  return (
    <OnboardingLayout step={2} title="Banking Information" isPayeeFlow>
      <Header as="h2">
        <FormattedMessage defaultMessage="Información bancaria" id="a5uzPv" />
      </Header>
      <S.Subtitle>
        <FormattedMessage
          defaultMessage="Completa tu información bancaria para terminar."
          id="gexnY5"
        />
      </S.Subtitle>
      <S.Container>
        <S.StyledCard padding="2">
          {showActions && (
            <div className="action-title">
              <Header as="h5">
                <FormattedMessage
                  defaultMessage="CLABE de retiro"
                  id="hxkp8t"
                />
              </Header>
            </div>
          )}
          <BankingInfoValidation
            isVerified={isVerified}
            isRejected={isRejected}
            isValidating={isValidating}
            bankingInfo={bankingInfo}
            onAddClick={onAddClick}
            rejectedAccount={rejectedAccount}
          />
        </S.StyledCard>
        {showActions && (
          <Button
            onClick={() => history.replace('/wallet')}
            disabled={isValidating}
          >
            <FormattedMessage defaultMessage="Terminar" id="31kdnk" />
          </Button>
        )}
      </S.Container>
      <Modal component={BusinessUserValidationModal} />
    </OnboardingLayout>
  );
};

export default BankingInformation;
