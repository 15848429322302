import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { parseJSON, format } from 'date-fns';

import { FlexContainer } from '@src/components/shared/layouts';
import { Card, Label, Text } from '@src/components/ui';
import Icon from '@src/components/ui/Icon';

import qbIcon from '@src/assets/img/qbIcon.svg';

interface codatSyncedAtProps {
  syncTime: string;
}

const CodatSyncedAt: FunctionComponent<codatSyncedAtProps> = ({ syncTime }) => {
  const intl = useIntl();

  return (
    <Card padding="6">
      <FlexContainer flexDirection="row">
        <div style={{ marginRight: 5 }}>
          <img src={qbIcon} alt="qbIcon" width={18} />
        </div>
        <FlexContainer flexDirection="column">
          <Text>
            {intl.formatMessage({
              id: 'btOM/6',
              defaultMessage: 'Actualizado en Quickbooks',
            })}
          </Text>
          <Label color="neutral" colorStep="500">{`${format(
            parseJSON(syncTime),
            'hh:mm a - dd/MM/yyyy'
          )}`}</Label>
        </FlexContainer>
        <Icon
          style={{ marginLeft: 'auto', alignSelf: 'center' }}
          size={18}
          color="primary"
          colorStep="400"
          variant="outlined"
          name="Link2"
        />
      </FlexContainer>
    </Card>
  );
};

export default CodatSyncedAt;
