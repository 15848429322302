import { gql } from '@apollo/client';
import { Invoice } from '@src/types/models';

const INVOICE_QUERY = gql`
  query Invoice($businessId: ID!, $invoiceId: ID!) {
    invoice(invoiceId: $invoiceId, businessId: $businessId) {
      id
      hasXmlFile
      isComplete
      invoiceUrl
      businessEmitter {
        id
        name
        hashId
        bankingInfos {
          id
          bankId
          recipient
          bankName
          clabe
        }
      }
      businessRelationship {
        id
        pendingTotalCredit
        paymentTerms
        supplier {
          id
          name
          defaultBankingInfo {
            id
            bankId
            clabe
            bankName
            recipient
          }
        }
        providerContacts {
          contactable {
            email
            firstName
            id
            lastName
          }
          defaultContact
          id
        }
      }
      businessEmitterEmail
      businessReceiver {
        id
        name
      }
      satVerified
      codatSyncedAt
      createdAt
      currency
      exchangeRate
      foreignTotal
      emissionStatus
      approvalStatus
      mailboxStatus
      globalStatus
      expiryDate
      paymentStatus
      paymentTerms
      fiscalIdentifier
      outstandingBalance
      valueAddedTaxAmount
      valueAddedRetainedTaxAmount
      iepsRetainedAmount
      iepsAmount
      isrAmount
      subtotal
      invoiceLineItems {
        category
        id
        imageUrl
        name
        pricePerUnit
        quantity
        total
        unit
        valueAddedTaxPercent
        valueAddedRetainedTaxAmount
        valueAddedTaxAmount
        valueDiscountAmount
        iepsRetainedAmount
        iepsAmount
      }
      receiverCountryTaxPayerInfo {
        __typename
        ... on MexicanTaxPayerInfo {
          cfdiUsage
          paymentForm
          paymentMethod
          voucherType
          satStamp
          cfdiDigitalStamp
          cfdiOriginalChain
          fiscalUuid
          folio
          regime
          serie
        }
      }
      invoiceables {
        __typename
        ... on SubscriptionStatement {
          id
        }
        ... on Order {
          hashId
        }
      }
      invoicingDate
      matchedAt
      receiverTaxpayerIdentifier
      receiverTaxpayerName
      emitterTaxpayerIdentifier
      emitterTaxpayerName
      receiverTaxpayerAddress {
        city
        colony
        country
        id
        interiorNumber
        number
        postalCode
        state
        street
      }
      serialNumber
      total
      costCenter {
        id
        name
      }
      invoices {
        id
        appliedCreditByCreditNote(creditNoteId: $invoiceId)
      }
      pendingCredit
      appliedCredit
      voucherType
    }
  }
`;

export type CreditNote = {
  id: string;
  currency: string;
  total: number;
  pendingCredit: number;
  appliedCredit: number;
};

export type InvoiceReturn = {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'hasXmlFile'
    | 'isComplete'
    | 'invoiceUrl'
    | 'businessEmitter'
    | 'businessRelationship'
    | 'businessEmitterEmail'
    | 'businessReceiver'
    | 'satVerified'
    | 'createdAt'
    | 'currency'
    | 'exchangeRate'
    | 'foreignTotal'
    | 'emissionStatus'
    | 'approvalStatus'
    | 'mailboxStatus'
    | 'globalStatus'
    | 'expiryDate'
    | 'paymentStatus'
    | 'paymentTerms'
    | 'fiscalIdentifier'
    | 'outstandingBalance'
    | 'valueAddedTaxAmount'
    | 'valueAddedRetainedTaxAmount'
    | 'iepsRetainedAmount'
    | 'iepsAmount'
    | 'isrAmount'
    | 'subtotal'
    | 'invoiceLineItems'
    | 'receiverCountryTaxPayerInfo'
    | 'invoiceables'
    | 'invoicingDate'
    | 'matchedAt'
    | 'receiverTaxpayerIdentifier'
    | 'receiverTaxpayerName'
    | 'emitterTaxpayerIdentifier'
    | 'emitterTaxpayerName'
    | 'receiverTaxpayerAddress'
    | 'serialNumber'
    | 'total'
    | 'costCenter'
    | 'invoices'
    | 'pendingCredit'
    | 'appliedCredit'
    | 'voucherType'
  >;
};

export type InvoiceVariables = {
  businessId?: string;
  invoiceId: string;
};

export default INVOICE_QUERY;
