import styled from 'styled-components';

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;
