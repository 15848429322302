import styled from 'styled-components';
import { TableHeaderProps } from './types';

export const StyledTableHeader = styled.thead<TableHeaderProps>`
  height: ${({ theme, compact }) =>
    compact ? theme.spacing[10] : theme.spacing[16]};
  font-size: ${({ theme, compact }) =>
    compact ? theme.fontSize.xs : theme.fontSize.xs};
  white-space: nowrap;
`;
