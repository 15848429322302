import { gql } from '@apollo/client';

export const PASSWORD_TOKEN_QUERY_NAME = 'passwordToken';

export const PASSWORD_TOKEN_QUERY = gql`
  query ${PASSWORD_TOKEN_QUERY_NAME}($token: String!) {
    passwordToken(token: $token) {
      id
    }
  }
`;
