import styled from 'styled-components';
import { FlexContainer } from '@src/components/shared/layouts';

interface RoundIconProps {
  $src?: string;
}

export const DataContent = styled(FlexContainer)`
  min-width: ${({ theme }) => theme.spacing[48]};
  justify-content: left;
`;

export const RoundIcon = styled.div<RoundIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing[2]};
  width: ${({ theme }) => theme.spacing[8]};
  height: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.neutral['100']};
  background-image: ${({ $src }) => $src && `url('${$src}')`};
  background-size: cover;
  background-position: center;
`;
