import Loadable from '@src/components/shared/Loadable';

const NotAuthorizedLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "403" */
      './Error403'
    ),
});

export default NotAuthorizedLoader;
