import { gql } from '@apollo/client';

export const CREATE_CODAT_COMPANY = gql`
  mutation CreateCodatCompany($businessId: ID!) {
    createCodatCompany(businessId: $businessId) {
      businessId
      codatId
      codatLinkUrl
    }
  }
`;
