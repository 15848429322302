import styled from 'styled-components';

export const Comment = styled.div`
  padding: ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.spacing[4]};
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin-right: ${({ theme }) => theme.spacing[2]};
  margin-bottom: 0;
`;
