import React from 'react';

import Sidebar from '@src/components/navigation/Sidebar';
import Navbar from '@src/components/navigation/Navbar';
import * as S from './SidebarLayout.styles';

function SidebarLayout(props) {
  const { children } = props;

  return (
    <S.Wrapper>
      <Sidebar />
      <S.RightWrapper>
        <Navbar />
        <S.PageContent>{children}</S.PageContent>
      </S.RightWrapper>
    </S.Wrapper>
  );
}

export default SidebarLayout;
