import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

import { SettingsProviderContext } from './SettingsProvider';

export default function withSettings(Component: (...args: Array<any>) => any) {
  const C = (props) => (
    <SettingsProviderContext.Consumer>
      {(value) => (
        <Component
          business={value.business}
          currentUser={value.currentUser}
          {...props}
        />
      )}
    </SettingsProviderContext.Consumer>
  );

  //@ts-ignore
  C.displayName = `withSettings(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;

  return hoistStatics(C, Component);
}
