import styled from 'styled-components';

export const PaymentStatusBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    margin-bottom: 0;
  }

  svg {
    height: ${({ theme }) => theme.spacing[4]};
    margin-right: ${({ theme }) => theme.spacing[1]};
  }
`;
