import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as S from './PaymentsApprovalButton.styles';
import { Text, Badge, Button } from '@src/components/ui';
import { FlexContainer } from '@src/components/shared/layouts';

function PaymentsApprovalButton({
  paymentRequestsCount,
}: {
  paymentRequestsCount: number;
}) {
  const intl = useIntl();
  const history = useHistory();
  if (paymentRequestsCount === 0) return null;

  return (
    <S.Notification
      color="primary"
      icon={
        <Badge
          label={paymentRequestsCount}
          backgroundColorStep="400"
          colorStep="50"
          color="primary"
        />
      }
    >
      <FlexContainer alignItems="center" justifyContent="space-between">
        <Text color="primary" colorStep="500">
          <strong>
            {intl.formatMessage({
              defaultMessage: 'Solicitudes de aprobación de pagos.',
              id: '6R1and',
            })}
          </strong>
          &nbsp;
          {intl.formatMessage({
            defaultMessage:
              'Tienes solicitudes de aprobación de pago pendientes por pagar.',
            id: 'tIONuu',
          })}
        </Text>
        <Button
          compact
          variant="outlined"
          onClick={() => history.push('/payment_requests')}
        >
          {intl.formatMessage({
            defaultMessage: 'Ver solicitudes de pago',
            id: 'WsXEc3',
          })}
        </Button>
      </FlexContainer>
    </S.Notification>
  );
}
export default PaymentsApprovalButton;
