import React, { useMemo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { addDays, formatISO, format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import {
  UPDATE_BULK_INVOICE_STATUS,
  UpdateInvoiceStatusResponse,
  UpdateInvoiceStatusParameters,
} from '@src/graphql/mutations/updateBulkInvoiceStatus';
import ModalHeader from '@src/components/ui/ModalHeader';
import useSettings from '@src/hooks/useSettings';
import { getExpiryDate } from '@src/utils/invoices';
import Button from '@src/components/ui/Button';
import useToast from '@src/hooks/useToast';
import * as S from './UpdateTermsModal.styles';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import {
  TableCell,
  TableHeaderCell,
  Table,
  TextField,
  DatePicker,
} from '@src/components/ui';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import { Currency } from '@src/components/i18n/Currency';
import CostCentersSelect from '@src/components/shared/select/CostCentersSelect';

import { InvoiceApprovalStatus } from '@src/types/enums';
import { Invoice } from '@src/types/models';
import { DATE_FORMAT } from '@src/constants/date';

type PartialInvoice = Pick<
  Invoice,
  | 'id'
  | 'businessRelationship'
  | 'invoicingDate'
  | 'businessEmitter'
  | 'total'
  | 'emitterTaxpayerName'
  | 'currency'
  | 'costCenter'
>;

type InvoiceField = {
  id: string;
  expiryDate: string;
  paymentTerms: number;
};

type FormData = {
  invoices: Array<PartialInvoice & InvoiceField>;
};

interface UpdateTermsModalProps {
  invoices: Array<PartialInvoice>;
  selectedIds: string[];
  onClose?: () => void;
  onSuccess?: () => void;
}

const UpdateTermsModal = ({
  selectedIds,
  invoices,
  onClose,
  onSuccess,
}: UpdateTermsModalProps) => {
  const intl = useIntl();
  const { business } = useSettings();
  const history = useHistory();
  const { snack, toast } = useToast();
  const invoicesToApprove = useMemo(
    () =>
      invoices
        ?.filter((invoice) => selectedIds.includes(invoice.id))
        ?.map((invoice) => ({
          ...invoice,
          paymentTerms: invoice?.businessRelationship?.paymentTerms,
          expiryDate: getExpiryDate(
            invoice.invoicingDate,
            invoice.businessRelationship?.paymentTerms
          ),
        })),
    [invoices, selectedIds]
  );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      termsAccepted: false,
      invoices: invoicesToApprove,
    },
  });
  const { fields, remove } = useFieldArray({
    control,
    keyName: 'key',
    name: 'invoices',
  });
  const [updateTerms, { loading }] = useMutation<
    UpdateInvoiceStatusResponse,
    UpdateInvoiceStatusParameters
  >(UPDATE_BULK_INVOICE_STATUS, {
    refetchQueries: ['InvoicesList'],
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
    onCompleted: () => {
      snack(
        intl.formatMessage({
          defaultMessage: 'Facturas agregadas',
          id: 'osldg7',
        }),
        {
          label: intl.formatMessage({
            defaultMessage: 'Ir a cuentas por pagar',
            id: 'YQPju/',
          }),
          onClick: () => history.push('/accounts_payable'),
        }
      );
      onSuccess?.();
      onClose?.();
    },
    onError: () => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar',
          id: 'mtahgR',
        })
      );
    },
  });

  const onSubmit = async ({ invoices: invoicesToApprove }: FormData) => {
    if (business?.id) {
      updateTerms({
        variables: {
          businessId: business?.id,
          invoices: invoicesToApprove?.map(
            ({ paymentTerms, costCenter, id, expiryDate }) => ({
              paymentTerms: +paymentTerms,
              costCenterId: costCenter?.id,
              status: InvoiceApprovalStatus.APPROVED,
              expiryDate,
              id,
            })
          ),
        },
      });
    }
  };

  if (!invoices || !business) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader
        sticky
        title={intl.formatMessage({
          defaultMessage: 'Agregar facturas',
          id: 'GT/ZN9',
        })}
        onClose={onClose}
        actions={
          <Button loading={loading} type="submit">
            {intl.formatMessage({
              defaultMessage: 'Agregar a cuentas por pagar',
              id: 'TO7qak',
            })}
          </Button>
        }
      />
      <S.Container>
        <S.TableCard>
          <Table stickyHeader minHeight="calc(100vh - 134px - 64px)">
            <SelectableTableHeader cancelable>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'ID',
                  id: 'qlcuNQ',
                })}
              </TableHeaderCell>

              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Proveedor',
                  id: 'RMvOYP',
                })}
              </TableHeaderCell>

              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Total',
                  id: 'MJ2jZQ',
                })}
              </TableHeaderCell>

              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Términos de pagos',
                  id: 'f7d5/9',
                })}
              </TableHeaderCell>

              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Fecha de vencimiento',
                  id: 'XF6Ipl',
                })}
              </TableHeaderCell>

              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Centro de costos (Opcional)',
                  id: 'B14Txi',
                })}
              </TableHeaderCell>
            </SelectableTableHeader>
            <tbody>
              {fields?.map((item, index) => (
                <SelectableTableRow
                  key={`invoice-${item.id}-row`}
                  cancelable
                  onCancel={() => {
                    if (fields.length === 1 && onClose) onClose();
                    remove(index);
                  }}
                >
                  <input
                    type="hidden"
                    defaultValue={item.id}
                    {...register(`invoices.${index}.id`)}
                  />

                  <TableCell>{item.id}</TableCell>

                  <TableCell>
                    {item?.businessEmitter?.name || item.emitterTaxpayerName}
                  </TableCell>

                  <TableCell>
                    <Currency value={item.total} currency={item.currency} />
                  </TableCell>

                  <TableCell>
                    <TextField
                      error={!!errors?.invoices?.[index]?.paymentTerms?.message}
                      helperText={
                        errors?.invoices?.[index]?.paymentTerms?.message
                      }
                      type="number"
                      {...register(`invoices.${index}.paymentTerms`, {
                        required: intl.formatMessage({
                          defaultMessage: 'Campo requerido',
                          id: '7Vvfe3',
                        }),
                        onChange: (event) => {
                          setValue(
                            `invoices.${index}.expiryDate`,
                            formatISO(
                              addDays(
                                new Date(item.invoicingDate),
                                event.target.value
                              )
                            )
                          );
                        },
                      })}
                    />
                  </TableCell>

                  <TableCell>
                    <Controller
                      control={control}
                      rules={{
                        required: intl.formatMessage({
                          defaultMessage: 'Campo requerido',
                          id: '7Vvfe3',
                        }),
                      }}
                      name={`invoices.${index}.expiryDate`}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          error={!!error?.message}
                          minDate={
                            item.invoicingDate
                              ? formatISO(new Date(item.invoicingDate))
                              : formatISO(new Date())
                          }
                          placeholder={format(new Date(), DATE_FORMAT)}
                          onClear={() => {
                            setValue(`invoices.${index}.expiryDate`, undefined);
                          }}
                          {...field}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`invoices.${index}.costCenter`}
                      render={({ field }) => <CostCentersSelect {...field} />}
                    />
                  </TableCell>
                </SelectableTableRow>
              ))}
            </tbody>
          </Table>
        </S.TableCard>
      </S.Container>
    </form>
  );
};

UpdateTermsModal.displayName = 'UpdateTermsModal';

export default UpdateTermsModal;
