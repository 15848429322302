import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { uploadFile } from '@src/lib/s3';
import * as S from './FilePicker.styles';
import useToast from '@src/hooks/useToast';
import { Button, FormControl } from '@src/components/ui';
import { File, UploadCloud } from '@src/components/ui/Icon/outline';

type FilePickerProps = {
  value?: string;
  accept?: string;
  disabled?: boolean;
  onChange?: (url?: string) => void;
};

export function FilePicker({ disabled, accept, onChange }: FilePickerProps) {
  const intl = useIntl();
  const { toast } = useToast();
  const [uploading, setUploading] = useState(false);
  const [filename, setFilename] = useState('');
  const [error, setError] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files: Array<any>) => {
      setUploading(true);
      setError('');

      if (files.length !== 1) {
        setError(
          intl.formatMessage({
            defaultMessage: 'Selecciona 1 archivo',
            id: 'cWSVEi',
          })
        );
        setUploading(false);
        return;
      }

      const file = files[0];
      try {
        const upload = await uploadFile(file);

        onChange?.(upload?.Location);
        setUploading(false);
        setFilename(file.name);
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error',
            id: 'KN7zKn',
          }),
          intl.formatMessage({
            defaultMessage: 'Error al cargar imagen',
            id: '5cNd+t',
          })
        );
        setUploading(false);
      }
    },
    accept,
    multiple: false,
    disabled,
  });

  return (
    <S.FileContainer {...getRootProps()}>
      <FormControl
        error={!!error}
        helperText={
          error ||
          intl.formatMessage(
            {
              defaultMessage: 'Acepta archivos {formats}',
              id: 'pSpgTV',
            },
            {
              formats: accept,
            }
          )
        }
      >
        <input type="fileUrl" disabled={uploading} {...getInputProps()} />

        <Button
          compact
          disabled={disabled}
          variant="outlined"
          startIcon={filename ? <UploadCloud /> : <File />}
          loading={uploading}
        >
          {filename ||
            intl.formatMessage({
              defaultMessage: 'Buscar archivo',
              id: 'uDgL22',
            })}
        </Button>
      </FormControl>
    </S.FileContainer>
  );
}

export default FilePicker;
