import { Card, IconButton } from '@src/components/ui';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing[7]};
`;

export const RequestContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[10]};
  margin-bottom: ${({ theme }) => theme.spacing[10]};
  position: relative;

  > div {
    margin-bottom: ${({ theme }) => theme.spacing[5]};
  }

  @media (min-width: 768px) {
    flex-direction: row;
    > div {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.spacing[16]};
    }
  }
`;

export const IconButtonWrapper = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing[10]};
  top: ${({ theme }) => theme.spacing[10]};
`;

export const IconCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[500]};
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing[10]};
  height: ${({ theme }) => theme.spacing[10]};
  display: flex;
  align-items: center;
  justify-content: center;
`;
