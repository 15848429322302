import React from 'react';
import MUIGrid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

function Grid(props) {
  return <MUIGrid theme={theme} {...props} />;
}

export default Grid;
