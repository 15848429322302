import { gql } from '@apollo/client';
import { TAXPAYER_INFO_ATTRIBUTES } from '@src/graphql/fragments/taxpayerInfoAttributes';

export const INITIALIZE_INVOICES_SYNC_MUTATION = gql`
  mutation InitializeInvoicesSync(
    $businessId: ID!
    $taxPayerInfoId: ID!
    $taxPayerInfoPassword: String!
  ) {
    initializeInvoicesSync(
      businessId: $businessId
      taxPayerInfoId: $taxPayerInfoId
      taxPayerInfoPassword: $taxPayerInfoPassword
    ) {
      ...TaxPayerInfoDataAttributes
    }
  }
  ${TAXPAYER_INFO_ATTRIBUTES}
`;

export default INITIALIZE_INVOICES_SYNC_MUTATION;
