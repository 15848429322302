import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import * as S from './AvailableOffers.styles';
import HeaderActionBar from '@src/components/shared/HeaderActionBar';
import { FactoringOffer } from '@src/types/models';
import EmptyResults from '@src/components/shared/EmptyResults';
import {
  Button,
  IconButton,
  Table,
  TableCell,
  TableHeaderCell,
  Tooltip,
} from '@src/components/ui';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import { Currency } from '@src/components/i18n';
import InvoiceGlobalStatusBadge from '@src/components/shared/InvoiceGlobalStatusBadge';
import useTableSelection from '@src/hooks/useTableSelection';
import { Factoring as FactoringIcon } from '@src/components/ui/Icon/solid';
import { DATE_FORMAT } from '@src/constants/date';

interface AvailableOffersProps {
  factoringOffers: FactoringOffer[];
  loading: boolean;
}

function AvailableOffers({ factoringOffers }: AvailableOffersProps) {
  const history = useHistory();
  const intl = useIntl();

  const {
    selectedIds,
    handleSelect,
    hasItemsSelected,
    hasAllSelected,
    handleSelectAll,
  } = useTableSelection({ items: factoringOffers });

  const handleNextClick = () => {
    history.push(
      `/request-factoring?factoringOfferIds=${selectedIds.join(',')}`
    );
  };

  if (factoringOffers.length === 0) {
    return (
      <EmptyResults
        label={intl.formatMessage({
          defaultMessage: 'Aún no tienes facturas por adelantar',
          id: 'nKYsQX',
        })}
        description={intl.formatMessage({
          defaultMessage:
            'Aquí verás todas las facturas que puedes adelantar en Higo.',
          id: 'F13wKS',
        })}
      />
    );
  }

  return (
    <S.Container>
      {hasItemsSelected && (
        <HeaderActionBar
          indeterminate={selectedIds.length !== factoringOffers.length}
          checked={hasAllSelected}
          onChange={handleSelectAll}
          actions={
            <Button onClick={handleNextClick}>
              <FormattedMessage
                defaultMessage="Adelantar facturas"
                id="z1M8S/"
              />
            </Button>
          }
        />
      )}

      <Table>
        <SelectableTableHeader
          selected={hasAllSelected}
          onSelect={handleSelectAll}
          selectable
        >
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'No Folio',
              id: 'gdN/OU',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Fecha emisión',
              id: 'g0vsQl',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Cliente',
              id: 'DshuY+',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Costo financiamiento',
              id: 'MRGlAW',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Monto total',
              id: 'SF6Pf+',
            })}
          </TableHeaderCell>
          <TableHeaderCell />
          <TableHeaderCell>...</TableHeaderCell>
        </SelectableTableHeader>
        <tbody>
          {factoringOffers.map((offer) => (
            <S.SelectableTableRow
              key={offer.id}
              selectable
              onSelect={() => handleSelect(String(offer.id))}
              selected={selectedIds.includes(String(offer.id))}
              onClick={() => {
                history.push(`/invoices/${offer.invoice.id}`);
              }}
            >
              <TableCell>{offer.invoice.fiscalIdentifier || '-'}</TableCell>
              <TableCell>
                {format(new Date(offer.offeredAt), DATE_FORMAT)}
              </TableCell>
              <TableCell>{offer.invoice.businessReceiver?.name}</TableCell>
              <TableCell>
                <Currency value={offer.discountAmountCents} />
              </TableCell>
              <TableCell>
                <Currency value={offer.invoice.total || 0} />
              </TableCell>
              <TableCell>
                <InvoiceGlobalStatusBadge
                  receivable={true}
                  expiryDate={offer.invoice.expiryDate}
                  status={offer.invoice.globalStatus || 'PENDING_PAYMENT'}
                />
              </TableCell>
              <TableCell>
                <Tooltip
                  id={`offer-${offer.id}`}
                  title={intl.formatMessage({
                    defaultMessage: 'Adelantar factura',
                    id: 'WW2SE2',
                  })}
                  effect="solid"
                  place="bottom"
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `/request-factoring?factoringOfferIds=${offer.id}`
                      );
                    }}
                  >
                    <FactoringIcon color="blue" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </S.SelectableTableRow>
          ))}
        </tbody>
      </Table>
    </S.Container>
  );
}

export default AvailableOffers;
