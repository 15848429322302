import styled from 'styled-components';
import { FlexContainer } from '@src/components/shared/layouts';
import { Card, Header, Label as BaseLabel } from '@src/components/ui';

export const CardContainer = styled(Card)`
  display: flex;
  align-items: center;
`;

export const NoMarginLabel = styled(BaseLabel)`
  margin: 0;
`;

export const NoMarginHeader = styled(Header)`
  margin: 0;
`;

export const LoadingContainer = styled(Card)`
  display: flex;
  align-items: center;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  & :first-child {
    height: ${({ theme }) => theme.spacing[6]};
    width: ${({ theme }) => theme.spacing[72]};
  }
  & :last-child {
    height: ${({ theme }) => theme.spacing[6]};
    width: ${({ theme }) => theme.spacing[96]};
  }
`;

export const ErrorContainer = styled(Card)`
  display: flex;
  align-items: center;
`;

export const ErrorMessageContainer = styled(FlexContainer)`
  width: 100%;
`;

export const FlexSpacer = styled.div`
  flex: 1;
`;
