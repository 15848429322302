import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';

import PaymentMethodDropdown from '@src/components/shared/dropdown/PaymentMethodDropdown';
import useModal from '@src/hooks/useModal';
import SupplierBankingInformation from '@src/components/shared/modals/SupplierBankingInformationModal';

import { BankingInfo, Invoice, PaymentMethod } from '@src/types/models';
import { InvoicePaymentType } from '@src/types/enums';
import useInvoicePaymentMethods from '@src/hooks/useInvoicePaymentMethods';
import { trackEvent } from '@src/lib/analytics';
import {
  BANKING_INFO_CREATED,
  BANKING_INFO_MODAL_OPENED,
  PAYMENT_METHOD_CHANGED,
} from '@src/constants/events';

type PartialInvoice = Pick<Invoice, 'id' | 'factoringOffer'>;

interface InvoicePaymentMethodDropdownProps {
  bankingInfo?: BankingInfo;
  businessRelationshipId?: string;
  supplierName?: string;
  disabled?: boolean;
  invoice: PartialInvoice;
  paymentMethods?: InvoicePaymentType[];
  value?: PaymentMethod;
  onChange?: (method: PaymentMethod) => void;
  onNewBankingInfo?: (
    businessRelationshipId: string,
    bankingInfo: BankingInfo
  ) => void;
}

function InvoicePaymentMethodDropdown(
  {
    bankingInfo,
    supplierName,
    businessRelationshipId,
    disabled,
    invoice,
    paymentMethods,
    value,
    onChange,
    onNewBankingInfo,
    ...otherProps
  }: InvoicePaymentMethodDropdownProps,
  ref
) {
  const intl = useIntl();
  const { open: openNew } = useModal(SupplierBankingInformation);
  const factored = !!invoice?.factoringOffer?.acceptedAt;
  const bankingInfos = bankingInfo ? [bankingInfo] : [];
  const getInvoicePaymentMethods = useInvoicePaymentMethods();
  const { paymentTypes } = getInvoicePaymentMethods(invoice, paymentMethods);
  const tooltipTitle = useMemo(() => {
    if (factored) {
      return intl.formatMessage({
        defaultMessage:
          'El beneficiario no se puede modificar debido a que tu proveedor ha hecho factoraje de esta factura previamente.',
        id: 'j9P6n1',
      });
    }

    if (value?.paymentType === InvoicePaymentType.HIGO_PAYMENT) {
      return intl.formatMessage({
        defaultMessage:
          'Esta empresa hace parte de la red de Higo y recibirá el pago directamente a su cuenta de Higo.',
        id: '+VmFnt',
      });
    }

    return undefined;
  }, [factored, value]);

  const handleNewClick = () => {
    if (businessRelationshipId) {
      openNew({
        businessRelationshipId,
        recipient: supplierName,
        onSuccess: (data) => {
          trackEvent(BANKING_INFO_CREATED, {
            bankId: data?.bankId,
          });
          if (onNewBankingInfo && data?.id) {
            onNewBankingInfo(businessRelationshipId, data);
          }

          onChange?.({
            paymentType: InvoicePaymentType.STP_PAYMENT,
            value: data?.id,
          });
        },
      });
      trackEvent(BANKING_INFO_MODAL_OPENED);
    }
  };

  const handlePaymentMethodChange = (method: PaymentMethod) => {
    if (onChange) onChange(method);
    trackEvent(PAYMENT_METHOD_CHANGED, {
      value: method.paymentType,
    });
  };

  return (
    <PaymentMethodDropdown
      fullWidth
      value={value}
      paymentMethods={paymentTypes}
      bankingInfos={bankingInfos}
      onNewClick={bankingInfos.length === 0 ? handleNewClick : undefined}
      recipient={supplierName}
      onChange={handlePaymentMethodChange}
      ref={ref}
      tooltipTitle={tooltipTitle}
      disabled={factored || disabled}
      {...otherProps}
    />
  );
}

export default forwardRef(InvoicePaymentMethodDropdown);
