import React from 'react';

import Input from '@src/components/ui/Input';
import { CancelButton } from './Search.styles';
import {
  X as Close,
  Search as SearchIcon,
} from '@src/components/ui/Icon/outline';
import { Spinner } from '@src/components/ui';

import type { InputProps } from '../Input/types';

interface SearchProps extends InputProps {
  onClear?: (event: React.SyntheticEvent) => void;
  loading?: boolean;
}

export default function Search(props: SearchProps) {
  const { value, onClear, loading } = props;
  let endIcon;
  if (value && onClear) {
    endIcon = (
      <CancelButton>
        <Close onClick={onClear} />
      </CancelButton>
    );
  }
  if (loading) {
    endIcon = <Spinner />;
  }
  return (
    <Input
      value={value}
      {...props}
      startIcon={<SearchIcon size={18} />}
      endIcon={endIcon}
    />
  );
}
