import styled from 'styled-components';

type EmptyStateProps = {
  compact?: boolean;
};

export const EmptyState = styled.div<EmptyStateProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  height: ${({ theme, compact }) =>
    compact ? theme.spacing[32] : theme.spacing[96]};
  width: 32rem;
  max-width: 90%;
  h2 {
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.neutral[600]};
  }
  p {
    color: ${({ theme }) => theme.colors.neutral[500]};
    margin: 0;
  }
`;

export const IconCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[500]};
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing[18]};
  height: ${({ theme }) => theme.spacing[18]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    stroke: currentColor;
  }
`;

export const Icon = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

export const Action = styled.div`
  margin-top: ${({ theme }) => theme.spacing[4]};
`;
