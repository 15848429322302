import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as S from './Home.styles';
import Page from '@src/components/shared/Page';
import Permission from '@src/components/shared/Permission';
import { UserRole, PermissionName } from '@src/types/enums';
import { Card, Header } from '@src/components/ui';
import { Grid } from '@src/components/shared/layouts';

export function Home() {
  const intl = useIntl();

  return (
    <Permission
      permissions={[PermissionName.MANAGE_ADDRESSES]}
      minimumRoles={[UserRole.ADMIN]}
      redirect
    >
      <Page
        title={intl.formatMessage({
          defaultMessage: 'Configuración de la organización',
          id: 'k1slg3',
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <Link to="/settings/business">
              <Card padding="8">
                <S.CardContent>
                  <Header as="h5">
                    {intl.formatMessage({
                      defaultMessage: 'Perfil de la organización',
                      id: 'uz8OLr',
                    })}
                  </Header>
                  <p>
                    {intl.formatMessage({
                      defaultMessage: 'Detalles generales de tu organización.',
                      id: 'TH76LN',
                    })}
                  </p>
                </S.CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Link to="/settings/users">
              <Card padding="8">
                <S.CardContent>
                  <Header as="h5">
                    {intl.formatMessage({
                      defaultMessage: 'Usuarios',
                      id: 'Zw94jw',
                    })}
                  </Header>
                  <p>
                    {intl.formatMessage({
                      defaultMessage:
                        'Permisos de usuarios en la organización.',
                      id: '5cN5m1',
                    })}
                  </p>
                </S.CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Link to="/settings/integrations">
              <Card padding="8">
                <S.CardContent>
                  <Header as="h5">
                    {intl.formatMessage({
                      defaultMessage: 'Integraciones',
                      id: '49ZJi7',
                    })}
                  </Header>
                  <p>
                    {intl.formatMessage({
                      defaultMessage: 'Conexiones con ERPs.',
                      id: 'PhwKhU',
                    })}
                  </p>
                </S.CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Page>
    </Permission>
  );
}

export default Home;
