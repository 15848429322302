import React from 'react';
import { useIntl } from 'react-intl';

import LinkButton from '@src/components/shared/buttons/LinkButton';

import { BusinessUser } from '@src/types/models';
import useSettings from '@src/hooks/useSettings';

interface EditButtonProps {
  businessUser: BusinessUser;
}

function EditButton({ businessUser }: EditButtonProps) {
  const intl = useIntl();
  const { currentUser } = useSettings();

  if (currentUser?.businessUsers?.some((user) => user.id === businessUser.id)) {
    return null;
  }

  return (
    <LinkButton
      compact
      variant="ghosted"
      href={`/business_users/${businessUser.id}/edit`}
    >
      {intl.formatMessage({
        id: 'VGHwB5',
        defaultMessage: 'Editar',
      })}
    </LinkButton>
  );
}

export default EditButton;
