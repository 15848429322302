import React from 'react';
import { useForm } from 'react-hook-form';

import * as S from './WithdrawalForm.styles';
import { CREATE_WITHDRAWAL_WALLET_ENTRY } from '@src/constants/events';
import { useIntl } from 'react-intl';
import { stringToCents } from '@src/utils/currency';
import useWallet from '@src/hooks/useWallet';
import useSettings from '@src/hooks/useSettings';
import { useMutation } from '@apollo/client';
import { trackEvent } from '@src/lib/analytics';
import {
  CreateWithdrawalWalletEntryParams,
  CreateWithdrawalWalletEntryReturn,
  CREATE_WITHDRAWAL_WALLET_ENTRY_MUTATION,
} from '@src/graphql/mutations';
import useToast from '@src/hooks/useToast';
import Currency from '@src/components/i18n/Currency';
import BankingInfo from '@src/components/shared/BankingInfo';
import Button from '@src/components/ui/Button';
import TextField from '@src/components/ui/TextField';
import FormControl from '@src/components/ui/FormControl';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import useModal from '@src/hooks/useModal';
import WithdrawalSuccess from '@src/components/pages/WalletView/components/WithdrawalSuccess';
import WithdrawalFullscreenModal from '@src/components/pages/WalletView/components/WithdrawalFullscreenModal';
import { BankingInfoValidationStatus } from '@src/types/enums';
import { WALLET_QUERY_NAME } from '@src/graphql/queries/wallet';
import SecurePaymentsNotification from '@src/components/shared/form/InvoicePaymentForm/components/SecurePaymentsNotification';
import PaymentConceptField from '@src/components/shared/react-hook-fields/PaymentConceptField';

function WithdrawalForm() {
  const intl = useIntl();
  const wallet = useWallet();
  const balance = wallet.balance;
  const { toast } = useToast();
  const business = useSettings('business');
  const bankingInfo = business?.defaultBankingInfo;
  const { close } = useModal(WithdrawalFullscreenModal);
  const { open } = useModal(WithdrawalSuccess);
  const [mutate, { loading }] = useMutation<
    CreateWithdrawalWalletEntryReturn,
    CreateWithdrawalWalletEntryParams
  >(CREATE_WITHDRAWAL_WALLET_ENTRY_MUTATION, {
    refetchQueries: [WALLET_QUERY_NAME],
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      amount: (wallet?.balance || 0) / 100,
      paymentConcept: intl.formatMessage({
        defaultMessage: 'Retiro cuenta propia',
        id: 'OLq6hD',
      }),
    },
  });
  const amount = watch('amount');

  const onSubmit = async (data) => {
    if (business && data.amount) {
      try {
        await mutate({
          variables: {
            businessId: business.id,
            amount: stringToCents(data.amount),
            paymentConcept: data.paymentConcept,
          },
        });

        trackEvent(CREATE_WITHDRAWAL_WALLET_ENTRY, {
          creator: 'Buyer',
          view: 'WithdrawalView',
        });

        close();
        open({
          bankingInfo,
          withdrawalAmount: data.amount,
        });
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al realizar el retiro',
            id: 'blnwHv',
          }),
          e.message
        );
      }
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <S.FormElement>
          <S.Card padding="8">
            <TextField
              {...register('amount', {
                validate: {
                  positive: (value: number) =>
                    value > 0 ||
                    intl.formatMessage({
                      defaultMessage: 'Debe ser mayor a $0',
                      id: 'htbxyi',
                    }),
                  lessThanBalance: (value: number) =>
                    value <= (balance || 0) / 100 ||
                    intl.formatMessage({
                      defaultMessage: 'Su balance es menor al monto',
                      id: 'KOPFJQ',
                    }),
                },
                setValueAs: (value: string) => parseCurrency(value),
                required: intl.formatMessage({
                  defaultMessage: 'Campo requerido',
                  id: '7Vvfe3',
                }),
              })}
              error={!!errors?.amount?.message}
              helperText={errors?.amount?.message}
              type="currency"
              fullWidth
              required
              currency="MXN"
              variant="bold"
              label={intl.formatMessage({
                defaultMessage: 'Monto a retirar',
                id: 'Yln3Q3',
              })}
            />
            {bankingInfo && (
              <FormControl
                fullWidth
                label={intl.formatMessage({
                  defaultMessage: 'CLABE del beneficiario',
                  id: 'ZSv5uM',
                })}
              >
                <BankingInfo
                  showStatus
                  hasBorder
                  id={bankingInfo.id}
                  clabe={bankingInfo.clabe}
                  bankName={bankingInfo.bankName}
                  verified={
                    bankingInfo.validationStatus ===
                    BankingInfoValidationStatus.VALIDATED
                  }
                />
              </FormControl>
            )}
            <PaymentConceptField
              fullWidth
              name="paymentConcept"
              label={intl.formatMessage({
                defaultMessage: 'Concepto de Pago',
                id: 'NGmqCa',
              })}
              error={!!errors?.paymentConcept?.message}
              helperText={errors?.paymentConcept?.message}
              register={register}
            />
          </S.Card>
        </S.FormElement>
        <S.FormElement>
          <Button
            type="submit"
            block
            disabled={!amount || !isValid}
            loading={loading}
          >
            {intl.formatMessage({
              defaultMessage: 'Retirar',
              id: '2OwuoE',
            })}{' '}
            <Currency value={amount} valueInDecimal={false} />
          </Button>
        </S.FormElement>
        <S.FormElement>
          <SecurePaymentsNotification />
        </S.FormElement>
      </div>
    </S.Form>
  );
}

export default WithdrawalForm;
