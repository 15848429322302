import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import { InvoiceFilters } from '@src/hooks/useInvoiceFilters';
import { PageInfo } from '@src/types/utils';

const merge = (_, incoming) => {
  return incoming;
};

type CacheEntry = {
  __ref: string;
};

type Edge = {
  node: CacheEntry;
  __typename: string;
};

type Incoming = {
  edges: Edge[];
  pageInfo: PageInfo;
  totalCount: number;
  pendingTotal: number;
  __typename: string;
};

type ReadReturn = Incoming | undefined;

type Existing = Record<
  string,
  {
    edges: Edge[];
    pageInfo: Record<number, PageInfo>;
    pendingTotal: number;
    totalCount: number;
    __typename: string;
  }
>;

const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      keyFields: ['hashId'],
    },
    Query: {
      fields: {
        businessRelationshipsAsClient: relayStylePagination(['businessId']),
        financingRequests: {
          keyArgs: [
            'businessId',
            'creditAccountId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
          merge,
        },
        invoiceStatusCount: relayStylePagination(['businessId']),
        providers: relayStylePagination(['businessId']),
        supplierRelationship: relayStylePagination([
          'businessId',
          'supplierHashId',
        ]),
        wallet: relayStylePagination(['businessId']),
        creditAccount: {
          keyArgs: ['businessId'],
        },
        invoicesReceived: {
          keyArgs: false,
          merge(existing: Existing, incoming: Incoming, args): Existing {
            const variables = args.variables;
            const {
              first,
              page = 1,
              after,
              before,
              last,
              ...filters
            } = variables as InvoiceFilters;
            /**
             * This is the way apollo hashes the variables, we could use `args.storeFieldName`
             * but for some reason it doesn't work, but JSON.stringify(filters) it's a better approach,
             * because with `args.storeFieldName` if in the future we add more filters or variables, we would
             * need to add them mannually to the keyArgs array. With JSON.stringify(filters) will work automatically
             * thanks to the destructuring.
             */
            const cacheKey = `invoicesReceived:${JSON.stringify(filters)}`;

            const incomingEdges = incoming?.edges || [];
            /**
             * We're faking a offset pagination here [https://www.apollographql.com/docs/react/pagination/offset-based/].
             * The `offset` argument indicate where in the list the new items should be inserted.
             * The `limit` argument indicate how many items should be inserted. This is the same as the `first` or `last` argument.
             * If you're moving to the next page, first will have a value and last will be undefined. If you're moving to the previous page,
             * first will be undefined and last will have a value, but both variables have the same value.
             */
            const limit = first || last || RESULTS_PER_PAGE;
            const offset = page === 1 ? 0 : (page - 1) * limit;

            const mergedEdges = existing?.[cacheKey]
              ? existing[cacheKey].edges?.slice(0)
              : [];
            /**
             * Update the existing cache with the new incoming values.
             */
            incomingEdges.forEach((incomingEdge, index) => {
              mergedEdges[offset + index] = incomingEdge;
            });
            /**
             * The structure of the cache is:
             * {
             *  [cacheKey]: {
             *   edges: [...],
             *   pageInfo: {
             *    [page]: {...}
             *  }
             * Where the cacheKey is the key that we use to hash the variables.
             * The edges array is the list of items that we have in the cache.
             * The pageInfo object is a map of page numbers to pageInfo objects.
             */
            return {
              ...existing,
              [cacheKey]: {
                ...incoming,
                edges: mergedEdges,
                pageInfo: {
                  ...existing?.[cacheKey]?.pageInfo,
                  [page]: {
                    ...incoming.pageInfo,
                  },
                },
              },
            };
          },
          read(existing: Existing, args): ReadReturn {
            const variables = args.variables;
            const {
              first,
              page = 1,
              after,
              before,
              last,
              ...filters
            } = variables as InvoiceFilters;
            const cacheKey = `invoicesReceived:${JSON.stringify(filters)}`;
            const limit = first || last || RESULTS_PER_PAGE;
            const offset = page === 1 ? 0 : (page - 1) * limit;

            // A read function should always return undefined if existing is
            // undefined. Returning undefined signals that the field is
            // missing from the cache, which instructs Apollo Client to
            // fetch its value from your GraphQL server.
            if (!cacheKey || !existing || !existing[cacheKey]) {
              return undefined;
            }

            /**
             * We get the edges based on the offset and limit.
             * We need to filter the edges to remove the undefined values because the merge function
             * sometimes adds undefined values to the edges array.
             */
            const edges = existing?.[cacheKey].edges
              ?.slice(offset, offset + limit)
              .filter(Boolean);
            // if the edges array is empty, we return undefined so Apollo Client will fetch the data from the server
            if (edges.length === 0) {
              return undefined;
            }

            const pageInfo = existing?.[cacheKey].pageInfo[page];
            return {
              ...existing[cacheKey],
              edges,
              pageInfo,
            };
          },
        },
        receivedCreditNotes: relayStylePagination([
          'businessId',
          'supplierHashId',
          'uncredited',
          'approvalStatus',
        ]),
        purchaseOrders: relayStylePagination(),
        invoicesEmitted: relayStylePagination(),
        clientRelationships: relayStylePagination([
          'businessId',
          'search',
          'before',
          'after',
        ]),
        salesOrders: relayStylePagination(),
        supplierProducts: relayStylePagination(),
        providerRelationships: {
          keyArgs: [
            'businessId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
          merge,
        },
        verifiedProviders: relayStylePagination(),
        walletEntries: {
          keyArgs: [
            'sortBy',
            'before',
            'after',
            'pending',
            'first',
            'last',
            'createdAtFrom',
            'createdAtTo',
            'status',
            'search',
          ],
          merge,
        },
        locations: relayStylePagination(),
        taxPayerInfos: relayStylePagination(['businessId']),
        businessUsers: relayStylePagination(['businessId', 'status']),
      },
    },
  },
  possibleTypes: {
    Invoiceable: ['SubscriptionStatement', 'Order'],
    CountryTaxPayerInfos: ['MexicanTaxPayerInfo'],
    Itemable: ['PriceListItem', 'SupplierProduct'],
    Eventable: [
      'CommentEvent',
      'CostCenterEvent',
      'InvoiceStatusEvent',
      'InvoiceManualPaymentEvent',
      'InvoiceSTPPaymentEvent',
    ],
    ProviderContact: ['User', 'Contact'],
    BusinessContact: ['Contact', 'User'],
    Entryable: [
      'DepositEntry',
      'InvoicePaymentEntry',
      'WithdrawalEntry',
      'RefundEntry',
    ],
    Refundable: ['InvoicePayment', 'STPPayment'],
  },
  //@ts-ignore
  dataIdFromObject: (object) => {
    const _id = object.id || object.hashId;

    if (_id) {
      return `${object.__typename}:${_id}`;
    }

    return null;
  },
});

export default cache;
