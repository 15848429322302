import { gql } from '@apollo/client';
import { BusinessUser } from '@src/types/models';

const BUSINESS_USER_INVITATION_QUERY = gql`
  query BusinessUserInvitation($token: String!) {
    businessUserInvitation(token: $token) {
      businessUser {
        email
        firstName
        lastName
        business {
          id
          name
        }
      }
      inviteeEmail
      inviterUser {
        id
        firstName
      }
      nextStep
      token
    }
  }
`;

export type BusinessUserInvitationVariables = {
  token: string;
};

export type BusinessUserInvitationReturn = {
  businessUserInvitation: {
    businessUser: Pick<
      BusinessUser,
      'email' | 'firstName' | 'lastName' | 'business'
    >;
    inviteeEmail: string;
    inviterUser: {
      id: string;
      firstName: string;
    };
    nextStep: string;
    token: string;
  };
};

export default BUSINESS_USER_INVITATION_QUERY;
