import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const CheckFile: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 18 20"
        fill={color}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6667 5.83333V0.383333C11.4376 0.674446 12.1378 1.12609 12.7209 1.70833L15.6243 4.61333C16.2072 5.19573 16.6591 5.89574 16.9501 6.66666H11.5001C11.2791 6.66666 11.0671 6.57887 10.9108 6.42259C10.7545 6.26631 10.6667 6.05434 10.6667 5.83333ZM17.3334 8.7375V15.8333C17.3321 16.938 16.8927 17.997 16.1116 18.7781C15.3305 19.5593 14.2714 19.9987 13.1667 20H4.83342C3.72875 19.9987 2.66971 19.5593 1.8886 18.7781C1.10748 17.997 0.668071 16.938 0.666748 15.8333V4.16667C0.668071 3.062 1.10748 2.00296 1.8886 1.22185C2.66971 0.440735 3.72875 0.00132321 4.83342 0L8.59592 0C8.73175 0 8.86592 0.0108333 9.00008 0.02V5.83333C9.00008 6.49637 9.26347 7.13226 9.73232 7.6011C10.2012 8.06994 10.837 8.33333 11.5001 8.33333H17.3134C17.3226 8.4675 17.3334 8.60166 17.3334 8.7375ZM12.9368 11.9258C12.7844 11.7659 12.5748 11.673 12.354 11.6676C12.1333 11.6621 11.9193 11.7445 11.7592 11.8967L8.75925 14.7558C8.67909 14.8365 8.58327 14.8998 8.4777 14.9421C8.37213 14.9843 8.25904 15.0044 8.14538 15.0013C8.03172 14.9982 7.91991 14.9719 7.81681 14.9239C7.71372 14.8759 7.62153 14.8074 7.54592 14.7225L6.22175 13.5442C6.14 13.4713 6.0447 13.4152 5.94128 13.3792C5.83787 13.3431 5.72836 13.3278 5.61902 13.334C5.3982 13.3467 5.19144 13.4466 5.04425 13.6117C4.97136 13.6934 4.9153 13.7887 4.87924 13.8921C4.84319 13.9955 4.82786 14.1051 4.83413 14.2144C4.84679 14.4352 4.94665 14.642 5.11175 14.7892L6.39842 15.9342C6.86481 16.4007 7.49668 16.6639 8.15633 16.6666C8.81598 16.6692 9.44995 16.4111 9.92008 15.9483L12.9076 13.1033C13.0675 12.951 13.1604 12.7414 13.1658 12.5206C13.1713 12.2998 13.0889 12.0859 12.9368 11.9258Z"
          fill={color}
        />
      </svg>
    );
  }
);

CheckFile.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckFile.displayName = 'CheckFile';

export default CheckFile;
