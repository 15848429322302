import { gql } from '@apollo/client';
import { PermissionName, UserRole } from '@src/types/enums';

const UPDATE_BUSINESS_USER = gql`
  mutation UpdateBusinessUser(
    $businessUser: UpdateBusinessUserInput!
    $businessId: ID!
  ) {
    updateBusinessUser(businessUser: $businessUser, businessId: $businessId) {
      id
      userId
      firstName
      lastName
      status
      email
      profileImage
      role
      jobPosition
      permissions {
        id
        name
        taxPayerInfo {
          id
        }
      }
    }
  }
`;

export type PermissionInput = {
  name: PermissionName;
  id?: string;
  subjectId?: string;
  _destroy?: boolean;
};

export type UpdateBusinessUserInput = {
  id?: string;
  jobPosition?: string;
  role?: UserRole;
  permissions?: Array<PermissionInput>;
};

export type UpdateBusinessUserParams = {
  businessId?: string;
  businessUser: UpdateBusinessUserInput;
};

export type UpdateBusinessUserReturn = {
  updateBusinessUser: {
    id: string;
  };
};

export default UPDATE_BUSINESS_USER;
