import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './DisabledFinancingCard.styles';
import Button from '@src/components/ui/Button';
import Header from '@src/components/ui/Header';
import Background from './components/Background';

export const DisbledFinancingCard = () => {
  const intl = useIntl();
  const openHelp = () => {
    const url = 'https://1bkth8xwjkr.typeform.com/to/tvqjShdQ';
    window.open(url, '_blank');
  };

  return (
    <S.StyledCard
      padding="8"
      backgroundColor="primary"
      backgroundColorStep="400"
    >
      <Header as="h5" color="primary" colorStep="50">
        {intl.formatMessage({
          defaultMessage: '¿Te gusta Higo?',
          id: '5HYU3T',
        })}
      </Header>

      <p>
        {intl.formatMessage({
          defaultMessage:
            'Refiere a tus proveedores o clientes y comienza a enviar y recibir pagos más rápido. Ingresa sus datos de contacto para que nuestro equipo de ventas se ponga en contacto con ellos.',
          id: 'MnDKhI',
        })}
      </p>
      <Button onClick={openHelp} variant="outlined">
        {intl.formatMessage({
          defaultMessage: 'Enviar datos',
          id: '5yf3Vo',
        })}
      </Button>
      <Background className="bottom-art" />
    </S.StyledCard>
  );
};

export default DisbledFinancingCard;
