import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import { IntlProvider } from '@src/components/i18n';
import PageViewRecorder from '@src/components/PageViewRecorder';
import { PublicRoutes as Routes } from '@src/routes';
import theme from '@src/styles/theme';
import GlobalStyles from '@src/styles/globalStyles';
import { Container as ToastContainer } from '@src/components/ui/Toast/Toast';
import { Container as SnackbarContainer } from '@src/components/ui/Snackbar/Snackbar';
import publicClient from '@src/lib/apollo/publicClient';
import { ModalProvider } from '@src/context/modalContext';

function PublicApp() {
  return (
    <ModalProvider>
      <ApolloProvider client={publicClient}>
        <IntlProvider>
          <ThemeProvider theme={theme}>
            <PageViewRecorder />
            <GlobalStyles />
            <Routes />
            <ToastContainer />
            <SnackbarContainer />
          </ThemeProvider>
        </IntlProvider>
      </ApolloProvider>
    </ModalProvider>
  );
}

export default PublicApp;
