import React from 'react';

import Page from '@src/components/shared/Page';
import InboxHeader from './components/InboxHeader';
import SatBotAlert from '@src/components/pages/Inbox/components/SatBotAlert';
import PendingInvoices from './components/PendingInvoices';
import PendingCreditNotesTable from './components/PendingCreditNotesTable';
import UpdateTermsModal from './components/UpdateTermsModal';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import usePageTrack from '@src/hooks/usePageTrack';
import { INITIAL_PAGINATION_PARAMS } from '@src/utils/pagination';
import Modal from '@src/components/shared/Modal';
import SynchronizeSatInvoices from '@src/components/pages/Inbox/components/SynchronizeSatInvoices';
import Permission from '@src/components/shared/Permission';

import { InvoiceVoucherType, PermissionName } from '@src/types/enums';

export function Inbox() {
  usePageTrack('inbox_featured', 'payables');
  const [filters, setFilters] = useInvoiceFilters();

  const activeTab = Array.isArray(filters.voucherType)
    ? filters.voucherType[0]
    : filters.voucherType;

  const handleSwitcherChange = (value) => {
    setFilters({
      ...INITIAL_PAGINATION_PARAMS,
      voucherType: [value],
      tabIndex: undefined,
    });
  };

  return (
    <Permission
      permissions={[PermissionName.MANAGE_INVOICES_RECEIVED]}
      redirect
    >
      <Page title="Inbox">
        <SatBotAlert />
        <InboxHeader
          activeTab={activeTab}
          onSwitchChange={handleSwitcherChange}
        />
        {activeTab === InvoiceVoucherType.INVOICE ? (
          <PendingInvoices />
        ) : (
          <PendingCreditNotesTable />
        )}

        <Modal component={UpdateTermsModal} type="fullscreen" />
        <Modal component={SynchronizeSatInvoices} />
      </Page>
    </Permission>
  );
}

export default Inbox;
