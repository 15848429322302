import styled, { keyframes } from 'styled-components';

const appear = keyframes`
    from {
    transform: scale3d(0, 0, 0);
  }

  50% {
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;

export const StyledSidebarItem = styled.div<{ active?: boolean }>`
  height: ${({ theme }) => theme.spacing[12]};
  width: ${({ theme }) => theme.spacing[12]};
  padding: 0;

  a {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.primary[400] : 'transparent'};
    border-radius: ${({ theme }) => theme.spacing[3]};
    transition: background-color 100ms linear;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-item-active {
    background-color: ${({ theme }) => theme.colors.primary[400]};
    svg * {
      stroke: ${({ theme, active }) => !active && theme.colors.neutral[50]};
    }
  }

  svg {
    height: ${({ theme }) => theme.spacing[5]};
    width: ${({ theme }) => theme.spacing[5]};
    * {
      stroke: ${({ theme, active }) =>
        active ? theme.colors.neutral[50] : theme.colors.neutral[900]};
    }
  }

  &:hover a {
    background-color: ${({ theme, active }) =>
      !active && theme.colors.primary[50]};
    svg * {
      stroke: ${({ theme, active }) => !active && theme.colors.primary[400]};
    }
  }
  &:hover .sidebar-item-active {
    background-color: ${({ theme }) => theme.colors.primary[400]};
    svg * {
      stroke: ${({ theme, active }) => !active && theme.colors.primary[50]};
    }
  }
`;

export const Alert = styled.div<{ appear?: boolean }>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.danger[500]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[50]};
  width: ${({ theme }) => theme.spacing['2.5']};
  height: ${({ theme }) => theme.spacing['2.5']};
  top: 0;
  right: 0;
  border-radius: 50%;
  transform: scale3d(0, 0, 0);
  animation: ${appear} 100ms ease-out 900ms 1 forwards;
`;
