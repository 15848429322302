import React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import Button from '@src/components/ui/Button';
import { Header, Radio, TextField } from '@src/components/ui';
import { Grid, Spacer } from '@src/components/shared/layouts';
import { useMutation } from '@apollo/client';
import UPDATE_INVOICES_STATUS_MUTATION, {
  UpdateInvoiceStatusReturn,
  UpdateInvoiceStatusParams,
} from '@src/graphql/mutations/updateInvoiceStatus';
import { INVOICES_RECEIVED_QUERY_NAME } from '@src/graphql/queries/invoicesReceivedSearch';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import useSettings from '@src/hooks/useSettings';
import { InvoiceApprovalStatus } from '@src/types/enums';
import ARCHIVE_INVOICES_MUTATION, {
  ArchiveInvoicesParams,
  ArchiveInvoicesReturn,
} from '@src/graphql/mutations/archiveInvoices';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { ARCHIVE_INVOICES } from '@src/constants/events';

type RejectInvoiceFormProps = {
  invoiceId: string;
  onClose?: () => void;
};

const CUSTOM_MESSAGE = 'custom';
function RejectInvoiceForm({ invoiceId, onClose }: RejectInvoiceFormProps) {
  const intl = useIntl();
  const { toast } = useToast();
  const { business } = useSettings();
  const [reject, { loading: rejectLoading }] = useMutation<
    UpdateInvoiceStatusReturn,
    UpdateInvoiceStatusParams
  >(UPDATE_INVOICES_STATUS_MUTATION, {
    refetchQueries: [INVOICES_RECEIVED_QUERY_NAME, INVOICE_QUERY],
  });
  const [archive, { loading: archiveLoading }] = useMutation<
    ArchiveInvoicesReturn,
    ArchiveInvoicesParams
  >(ARCHIVE_INVOICES_MUTATION);
  const { register, handleSubmit, watch } = useForm();
  const isCustomMessage = watch('rejectionReason') === CUSTOM_MESSAGE;
  const options = [
    intl.formatMessage({
      defaultMessage:
        'Se esta cobrando un valor que no corresponde a lo entregado.',
      id: 'eiKCLJ',
    }),
    intl.formatMessage({
      defaultMessage: 'No se recibieron los productos que indica la factura.',
      id: 'axCZIn',
    }),
    intl.formatMessage({
      defaultMessage: 'Hay un error de facturación.',
      id: '0Q8B69',
    }),
  ];

  const onSubmit = async (data) => {
    let message = data.rejectionReason;
    if (data.rejectionReason === CUSTOM_MESSAGE) {
      message = data.customRejectionReason;
    }
    if (business?.id) {
      try {
        await archive({
          variables: {
            businessId: business.id,
            invoiceIds: [invoiceId],
            archived: true,
          },
        });
        trackEvent(ARCHIVE_INVOICES, {
          count: 1,
        });
        await reject({
          variables: {
            businessId: business?.id,
            invoiceId: invoiceId,
            status: InvoiceApprovalStatus.REJECTED,
            cancelationReason: message,
          },
        });
        onClose && onClose();
        toast.success(
          intl.formatMessage({
            defaultMessage: 'La factura fue rechazada con éxito',
            id: '7GRqKO',
          }),
          intl.formatMessage({
            defaultMessage:
              'Podrás encontrar tus facturas en el tab de Archivadas',
            id: 'McoDlj',
          })
        );
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al rechazar factura',
            id: '35Bcw9',
          }),
          e.message
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: '¿Por qué estas rechazando la factura?',
          id: 'IRvfhL',
        })}
      </Header>
      <Spacer margin="6" />
      <div>
        {options.map((option) => (
          <>
            <Radio
              key={option}
              {...register('rejectionReason', {
                required: true,
              })}
              label={option}
              value={option}
            />
            <Spacer margin="2" />
          </>
        ))}
        <Radio
          {...register('rejectionReason', {
            required: true,
          })}
          label={intl.formatMessage({
            defaultMessage: 'Otro',
            id: '+X6Q4H',
          })}
          value={CUSTOM_MESSAGE}
        />
        {isCustomMessage ? (
          <TextField
            {...register('customRejectionReason', {
              required: true,
            })}
            fullWidth
            placeholder={intl.formatMessage({
              defaultMessage: 'Escribe aquí el motivo...',
              id: 'I2AIf2',
            })}
          />
        ) : null}
      </div>
      <Spacer margin="4" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button block onClick={onClose} variant="transparent">
            {intl.formatMessage({
              defaultMessage: 'Cancelar',
              id: 'nZLeaQ',
            })}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button block type="submit" loading={rejectLoading || archiveLoading}>
            {intl.formatMessage({
              defaultMessage: 'Enviar',
              id: 'hKJZJR',
            })}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

RejectInvoiceForm.displayName = 'RejectInvoiceForm';

export default RejectInvoiceForm;
