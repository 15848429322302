export const getBankingInfo = (businessRelationship) => {
  const bankingInfo = businessRelationship?.bankingInfo;

  const hasBankingInfoComplete =
    bankingInfo &&
    bankingInfo.clabe &&
    bankingInfo.recipient &&
    bankingInfo.bankName;

  return { ...bankingInfo, isComplete: !!hasBankingInfoComplete };
};

export const formatClabe = (clabe?: string) =>
  clabe?.match(/.{1,4}/g)?.join(' ');
