import styled from 'styled-components';
import { Tag } from '@src/components/ui';

export const StyledTag = styled(Tag)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  height: ${({ theme }) => theme.spacing[5]};
  margin-right: ${({ theme }) => theme.spacing['1.5']};
  margin-top: ${({ theme }) => theme.spacing['1.5']};
`;

export const Fields = styled.div`
  input {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
  > label:last-child {
    margin-top: ${({ theme }) => theme.spacing[5]};
  }
`;

export const RadioLabel = styled.div`
  display: inline flex;
  align-items: center;
  > p:first-child {
    flex-basis: ${({ theme }) => theme.spacing[32]};
    margin: 0;
  }
  > p:last-child {
    color: ${({ theme }) => theme.colors.neutral[900]};
    margin: 0;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-bottom: ${({ theme }) => theme.spacing['1.5']};
    color: ${({ theme }) => theme.colors.neutral[500]};
  }
`;

export const Divisor = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  margin-top: ${({ theme }) => theme.spacing[5]};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.spacing[8]};
  > button {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;
