import { gql } from '@apollo/client';
import SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES, {
  SupplierBusinessRelationshipAttributes,
} from '../fragments/supplierBusinessRelationshipAttributes';
import { PaginatedResponse, Sort } from '@src/types/utils';

export const PROVIDER_RELATIONSHIPS_QUERY_NAME = 'SupplierRelationships';

const PROVIDER_RELATIONSHIPS_QUERY = gql`
  query ${PROVIDER_RELATIONSHIPS_QUERY_NAME}(
    $businessId: ID!
    $search: String
    $active: Boolean
    $first: Int
    $last: Int
    $before: String
    $after: String
    $sortBy: BusinessRelationshipsSortingParams
  ) {
    providerRelationships(
      businessId: $businessId
      search: $search
      active: $active
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...SupplierBusinessRelationshipAttributes
        }
      }
    }
  }
  ${SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES}
`;

export type ProviderRelationshipsQueryReturn = {
  providerRelationships: PaginatedResponse<SupplierBusinessRelationshipAttributes>;
};

export type ProviderRelationshipsQueryVariables = {
  businessId: string;
  search?: string;
  active?: boolean;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  sortBy?: Sort<'CREATED_AT' | 'INVOICES_COUNT'>;
};

export default PROVIDER_RELATIONSHIPS_QUERY;
