import { ApolloError, useQuery } from '@apollo/client';

import CREDIT_ACCOUNT_QUERY, {
  CreditAccountQueryReturn,
  CreditAccountQueryVariables,
} from '@src/graphql/queries/creditAccount';
import useSettings from './useSettings';

import { CreditAccount } from '@src/types/models';

type Options = {
  loading: boolean;
  error?: ApolloError;
};

type UseCreditAccountReturn = [
  creditAccount: CreditAccount | undefined,
  options: Options
];

function useCreditAccount(): UseCreditAccountReturn {
  const business = useSettings('business');
  const { data, loading, error } = useQuery<
    CreditAccountQueryReturn,
    CreditAccountQueryVariables
  >(CREDIT_ACCOUNT_QUERY, {
    variables: {
      businessId: business?.id,
    },
    skip: !business?.id || !business?.creditAccount?.active,
    fetchPolicy: 'cache-first',
  });

  return [data?.creditAccount, { loading, error }];
}

export default useCreditAccount;
