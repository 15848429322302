import styled, { css } from 'styled-components';

type CircleProps = {
  disabled?: boolean;
  $color?: 'primary' | 'neutral';
};

export const BankImage = styled.img`
  height: ${({ theme }) => theme.spacing[8]};
  width: ${({ theme }) => theme.spacing[8]};
  margin-right: ${({ theme }) => theme.spacing[4]};
`;

export const Account = styled.div`
  height: ${({ theme }) => theme.spacing[14]};
  display: flex;
  align-items: center;
  overflow: hidden;

  p,
  strong {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Circle = styled.div<CircleProps>`
  margin-right: ${({ theme }) => theme.spacing[4]};
  height: ${({ theme }) => theme.spacing[8]};
  width: ${({ theme }) => theme.spacing[8]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[500]};
  flex-shrink: 0;

  ${({ $color, theme }) =>
    $color === 'primary' &&
    css`
      background-color: ${theme.colors[$color][50]};
      color: ${({ theme }) => theme.colors[$color][500]};
    `}
`;

export const HigoPayLogo = styled(Circle)`
  background: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.neutral[300]
      : 'linear-gradient(90deg, #3c4cf4 0%, #8e69ff 100%)'};
  color: ${({ theme }) => theme.colors.neutral[50]};
`;
