import React from 'react';
import type { WalletEntry } from '@src/types/models';
import useTransaction from '@src/hooks/useTransaction';
interface TransactionRecipientProps {
  walletEntry: WalletEntry;
}

function TransactionRecipient({ walletEntry }: TransactionRecipientProps) {
  const { sender, receiver, positive } = useTransaction(walletEntry);
  return <span>{(positive ? sender?.name : receiver?.name) || '-'}</span>;
}

export default TransactionRecipient;
