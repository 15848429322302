import React from 'react';
import { FormattedMessage } from 'react-intl';

import Page from '@src/components/shared/Page';
import Button from '@src/components/ui/Button';
import HigoSuiteCard from './HigoSuiteCard';
import PageTitle from '@src/components/shared/PageTitle';
import { Grid } from '@src/components/shared/layouts';
import { Group } from '@src/types/models';

type HigoSuiteProps = {
  groups: Group[];
  invoiceBuyerChecked: boolean;
  invoiceProviderChecked: boolean;
  procurementChecked: boolean;
  salesChecked: boolean;
  handleSwitch: (...args: Array<any>) => any;
  updateBusinessFeatureGroups: (...args: Array<any>) => any;
};

export function HigoSuite(props: HigoSuiteProps) {
  const {
    groups,
    invoiceBuyerChecked,
    invoiceProviderChecked,
    handleSwitch,
    updateBusinessFeatureGroups,
  } = props;
  if (groups.length === 0) return null;

  const [invoiceBuyer, invoiceProvider] = groups;

  return (
    <Page title={'Higo Suite'}>
      <PageTitle
        actions={{
          render: () => (
            <Button onClick={updateBusinessFeatureGroups}>
              <FormattedMessage defaultMessage="Guardar" id="oN4aGu" />
            </Button>
          ),
        }}
      >
        <FormattedMessage defaultMessage="Higo Suite" id="Hr/ykW" />
      </PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <HigoSuiteCard
            name={invoiceBuyer.name}
            features={invoiceBuyer.features}
            onChange={handleSwitch}
            checked={invoiceBuyerChecked}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <HigoSuiteCard
            name={invoiceProvider.name}
            features={invoiceProvider.features}
            onChange={handleSwitch}
            checked={invoiceProviderChecked}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default HigoSuite;
