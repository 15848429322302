const isValidClabe = (accountNumber: string): boolean => {
  const strippedAccountNumber = accountNumber.replace(/\s/g, '').trim();
  if (strippedAccountNumber.length !== 18) {
    return false;
  }

  const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];
  const results = weights.map(
    (weight, index) => weight * +strippedAccountNumber[index]
  );
  const remainders = results.map((result) => result % 10);
  const total = remainders.reduce((acc, curr) => acc + curr);
  const totalRemainder = total % 10;
  const subtraction = 10 - totalRemainder;
  const verificationDigit = subtraction % 10;
  return +strippedAccountNumber[17] === verificationDigit;
};

export default isValidClabe;
