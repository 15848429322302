import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import * as S from './PaymentRequests.styles';
import {
  FlexColumnContainer,
  FlexRowContainer,
  Spacer,
} from '@src/components/shared/layouts';
import {
  Receipt,
  Calendar,
  User,
  Money,
  ArrowUpRight,
} from '@src/components/ui/Icon/outline';
import { ModalHeader, Text } from '@src/components/ui';
import { Currency } from '@src/components/i18n/Currency';
import userIsAdmin from '@src/utils/roles/userIsAdmin';
import useSettings from '@src/hooks/useSettings';
import Loader from '@src/components/shared/Loader';
import filterPaymentInstructions from './utils/filterPaymentInstructions';
import { PaymentInstructionResponse } from '@src/graphql/queries/paymentInstructions';
import usePaymentRequests from './hooks/usePaymentRequests';
import EmptyResults from '@src/components/shared/EmptyResults';
import Tag from '@src/components/ui/Tag';
import { DATE_FORMAT } from '@src/constants/date';

function PaymentRequests() {
  const history = useHistory();
  const intl = useIntl();
  const { paymentInstructions, loading } = usePaymentRequests({});

  const { currentUser } = useSettings();
  const isAdmin = userIsAdmin(currentUser);

  const goToRequestDetails = (request: PaymentInstructionResponse) => {
    history.push({
      pathname: '/invoice-payments',
      search: `?invoiceIds=${request.paymentInstructionInvoices
        .map((instructionInvoice) => instructionInvoice.invoice.id)
        .join(',')}&payment_instruction_id=${request.id}${
        isAdmin ? '' : '&mode=read'
      }`,
      state: {
        paymentInstruction: request,
      },
    });
  };

  if (loading || !paymentInstructions) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <ModalHeader
        onClose={() => history.push('/accounts_payable')}
        title={intl.formatMessage({
          defaultMessage: 'Solicitudes de aprobación de pago',
          id: 'aUeUi8',
        })}
      />
      <S.Container>
        {paymentInstructions.length === 0 && (
          <EmptyResults
            label={intl.formatMessage({
              defaultMessage: 'No tienes solicitudes de pago',
              id: 'B6E4bN',
            })}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas tus solicitudes de pago en Higo.',
              id: 'adt38T',
            })}
          />
        )}
        {paymentInstructions.map((request) => {
          const filteredPaymentInstructionInvoices = filterPaymentInstructions(
            request.paymentInstructionInvoices
          );

          if (request.totalInvoices === 0) {
            return null;
          }

          if (
            !request.requestedBy &&
            request.draftedBy &&
            request.draftedBy.id != currentUser?.id
          ) {
            return null;
          }

          return (
            <S.RequestContainer key={request.id}>
              <FlexRowContainer>
                <S.IconCircle>
                  <Receipt />
                </S.IconCircle>
                <Spacer margin="3" direction="horizontal" />
                <FlexColumnContainer>
                  <Text color="neutral" colorStep="500">
                    {intl.formatMessage({
                      defaultMessage: 'Solicitud',
                      id: 'RpIJJM',
                    })}{' '}
                    #{request.id}
                  </Text>
                  <Text strong>
                    {request.totalInvoices}{' '}
                    {intl.formatMessage({
                      defaultMessage: 'factura',
                      id: 'PXx0/M',
                    })}
                    {request.totalInvoices !== 1 ? 's' : ''}
                  </Text>
                </FlexColumnContainer>
              </FlexRowContainer>
              <FlexRowContainer>
                <S.IconCircle>
                  <Calendar size="16" />
                </S.IconCircle>
                <Spacer margin="3" direction="horizontal" />
                <FlexColumnContainer>
                  <Text color="neutral" colorStep="500">
                    {intl.formatMessage({
                      defaultMessage: 'Fecha de vencimiento',
                      id: 'XF6Ipl',
                    })}
                  </Text>
                  <Text strong>
                    {request.expiryDate
                      ? format(new Date(request.expiryDate), DATE_FORMAT)
                      : '-'}
                  </Text>
                </FlexColumnContainer>
              </FlexRowContainer>
              <FlexRowContainer>
                <S.IconCircle>
                  <User size="16" />
                </S.IconCircle>
                <Spacer margin="3" direction="horizontal" />
                <FlexColumnContainer>
                  <Text color="neutral" colorStep="500">
                    {intl.formatMessage({
                      defaultMessage: 'Solicitado por:',
                      id: 'rBZgrA',
                    })}
                  </Text>
                  <Text strong>
                    {request.requestedBy
                      ? `${request.requestedBy?.firstName} ${
                          request.requestedBy?.lastName || ''
                        }`
                      : request.draftedBy
                      ? `${request.draftedBy?.firstName} ${
                          request.draftedBy?.lastName || ''
                        }`
                      : '-'}
                  </Text>
                </FlexColumnContainer>
              </FlexRowContainer>
              <FlexRowContainer>
                <S.IconCircle>
                  <Money />
                </S.IconCircle>
                <Spacer margin="3" direction="horizontal" />
                <FlexColumnContainer>
                  <Text color="neutral" colorStep="500">
                    {intl.formatMessage({
                      defaultMessage: 'Total a pagar',
                      id: '8nNRxm',
                    })}
                  </Text>
                  <Text strong>
                    <Currency value={request.totalAmountCents} />
                  </Text>
                </FlexColumnContainer>
              </FlexRowContainer>
              {request.requestedBy == null && request.draftedBy && (
                <FlexRowContainer>
                  <FlexColumnContainer>
                    <Tag
                      color="primary"
                      label={intl.formatMessage({
                        defaultMessage: 'Borrador',
                        id: 'sRaboJ',
                      })}
                    />
                  </FlexColumnContainer>
                </FlexRowContainer>
              )}
              <FlexRowContainer>
                <FlexColumnContainer>
                  <S.IconButtonWrapper
                    variant="ghosted"
                    onClick={() =>
                      goToRequestDetails({
                        ...request,
                        paymentInstructionInvoices: filteredPaymentInstructionInvoices,
                      })
                    }
                  >
                    <ArrowUpRight />
                  </S.IconButtonWrapper>
                </FlexColumnContainer>
              </FlexRowContainer>
            </S.RequestContainer>
          );
        })}
      </S.Container>
    </>
  );
}

export default PaymentRequests;
