import { useCallback } from 'react';

import { isHigoAccount } from '@src/components/shared/dropdown/PaymentMethodDropdown/utils';

import { InvoicePaymentType } from '@src/types/enums';
import { Invoice, PaymentMethod } from '@src/types/models';

type PartialInvoice = Pick<Invoice, 'factoringOffer' | 'businessRelationship'>;

const useInvoicePaymentMethods = () => {
  const getPaymentMethods = useCallback(
    (
      invoice: PartialInvoice,
      paymentMethods?: InvoicePaymentType[]
    ): {
      paymentTypes: InvoicePaymentType[];
      defaultPaymentMethod?: PaymentMethod;
    } => {
      const factored = !!invoice?.factoringOffer?.acceptedAt;
      if (
        factored ||
        isHigoAccount(invoice?.businessRelationship?.bankingInfo?.clabe || '')
      ) {
        return {
          paymentTypes: [InvoicePaymentType.HIGO_PAYMENT],
          defaultPaymentMethod: {
            paymentType: InvoicePaymentType.HIGO_PAYMENT,
            value: undefined,
          },
        };
      }

      if (
        paymentMethods?.includes(InvoicePaymentType.STP_PAYMENT) &&
        invoice?.businessRelationship?.bankingInfo?.id
      ) {
        return {
          paymentTypes: paymentMethods || [],
          defaultPaymentMethod: {
            paymentType: InvoicePaymentType.STP_PAYMENT,
            value: invoice?.businessRelationship?.bankingInfo?.id,
          },
        };
      }

      return {
        paymentTypes: paymentMethods || [],
        defaultPaymentMethod: undefined,
      };
    },
    []
  );

  return getPaymentMethods;
};

export default useInvoicePaymentMethods;
