import { useMemo } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import INVOICES_EMITTED_QUERY, {
  InvoicesEmittedReturn,
  InvoicesEmittedVariables,
  PartialInvoice,
} from '@src/graphql/queries/invoicesEmitted';
import usePagination from '@src/hooks/usePagination';

interface UseInvoicesListQueryReturn {
  loading: boolean;
  error?: ApolloError;
  data: {
    invoices: PartialInvoice[];
  };
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  nodes: PartialInvoice[];
  next: () => void;
  previous: () => void;
  resetPaginationParams: (otherParams?: Record<string, any>) => void;
}

function useInvoicesEmittedQuery(
  variables?: InvoicesEmittedVariables & { page?: number }
): UseInvoicesListQueryReturn {
  const { loading, error, data, refetch } = useQuery<
    InvoicesEmittedReturn,
    InvoicesEmittedVariables
  >(INVOICES_EMITTED_QUERY, {
    variables: variables || {},
    skip: !variables?.businessId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    nodes,
    resetPaginationParams,
  } = usePagination<PartialInvoice>(
    data?.invoicesEmitted,
    refetch,
    variables?.first || variables?.last,
    variables?.page
  );

  const invoices: PartialInvoice[] = useMemo(() => {
    if (!data?.invoicesEmitted?.edges) {
      return [];
    }
    return data.invoicesEmitted.edges.map((edge) => edge.node);
  }, [data]);

  return {
    error,
    loading: !data && loading,
    data: {
      invoices,
    },
    count,
    hasNext,
    hasPrevious,
    page,
    nodes,
    next,
    previous,
    resetPaginationParams,
  };
}

export default useInvoicesEmittedQuery;
