import styled from 'styled-components';

export const DownloadTransactionsPDF = styled.div`
  width: 32rem;
  max-width: 90vw;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin-top: ${({ theme }) => theme.spacing[4]};
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  strong {
    color: ${({ theme }) => theme.colors.neutral[900]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;
