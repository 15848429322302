import React from 'react';

import * as S from './FactoringSuccessModal.styles';
import { Header, Text } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';
import { ToChargeSuccess } from '@src/components/ui/Icon/outline';
import { useIntl } from 'react-intl';
import { Currency } from '@src/components/i18n/Currency';

interface FactoringSuccessModalProps {
  amount: number;
}

function FactoringSuccessModal({ amount }: FactoringSuccessModalProps) {
  const intl = useIntl();
  return (
    <S.Container>
      <ToChargeSuccess size={37} />
      <Spacer margin="6" />
      <Header as="h4">
        {intl.formatMessage({
          defaultMessage: 'Tu adelanto está listo',
          id: '+mZrM0',
        })}{' '}
        <Currency value={amount} />
      </Header>
      <Spacer margin="3" />
      <Text colorStep="600">
        {intl.formatMessage({
          defaultMessage:
            'El monto ha sido depositado a tu cuenta y puedes verlo en tu saldo disponible, con el cual puedes pagar tus facturas o retirar a tu cuenta bancaria.',
          id: 'YMG+zQ',
        })}
      </Text>
    </S.Container>
  );
}

FactoringSuccessModal.displayName = 'FactoringSuccessModal';

export default FactoringSuccessModal;
