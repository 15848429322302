import useWallet from '@src/hooks/useWallet';
import useCreditAccount from './useCreditAccount';

function useBalance() {
  const wallet = useWallet();
  const [creditAccount] = useCreditAccount();
  const isCreditAccount = Boolean(creditAccount?.creditLimitCents);
  const financingBalance = isCreditAccount
    ? (creditAccount?.creditLimitCents || 0) -
      (creditAccount?.owedAmountCents || 0)
    : undefined;

  return {
    walletCents: wallet.balance,
    creditCents: financingBalance,
  };
}

export default useBalance;
