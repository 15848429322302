import { useMutation } from '@apollo/client';

import { WALLET_QUERY_NAME } from '@src/graphql/queries/wallet';
import { floatToCents } from '@src/utils/currency';
import CREATE_INVOICES_HIGO_PAYMENTS_MUTATION, {
  CreateInvoicesHigoPaymentsReturn,
  CreateInvoicesHigoPaymentsParams,
} from '@src/graphql/mutations/createInvoicesHigoPayments';
import CREATE_INVOICES_STP_PAYMENTS_MUTATION, {
  CreateInvoicesStpPaymentsReturn,
  CreateInvoicesStpPaymentsParams,
} from '@src/graphql/mutations/createInvoicesStpPayments';
import { trackEvent } from '@src/lib/analytics';
import {
  CREATE_INVOICES_HIGO_PAYMENTS_ACTION,
  CREATE_INVOICES_STP_PAYMENTS_ACTION,
  CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST_ACTION,
} from '@src/constants/events';
import CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST, {
  CreateInvoicePaymentsWithBankingInfoRequestParams,
  CreateInvoicePaymentsWithBankingInfoRequestReturn,
} from '@src/graphql/mutations/createInvoicePaymentsWithBankingInfoRequest';

import { InvoicePaymentType } from '@src/types/enums';
import { PaymentMethod } from '@src/types/models';

type InvoiceInputType = {
  id: string;
  email: string[];
  amount: number;
  paymentMethod: PaymentMethod;
  paymentConcept?: string;
};

type UsePaymentsMutationReturn = [
  (
    invoices: Array<InvoiceInputType>,
    options: { onError?: (e) => void; onSuccess: () => void }
  ) => void,
  {
    loading: boolean;
  }
];

interface UsePaymentsMutationParams {
  businessId?: string;
}

const usePaymentsMutation = ({
  businessId,
}: UsePaymentsMutationParams): UsePaymentsMutationReturn => {
  const [createHigoPayments, { loading: loadingHigoPayments }] = useMutation<
    CreateInvoicesHigoPaymentsReturn,
    CreateInvoicesHigoPaymentsParams
  >(CREATE_INVOICES_HIGO_PAYMENTS_MUTATION, {
    refetchQueries: [WALLET_QUERY_NAME],
  });
  const [createSPEIPayments, { loading: loadingSPEIPayments }] = useMutation<
    CreateInvoicesStpPaymentsReturn,
    CreateInvoicesStpPaymentsParams
  >(CREATE_INVOICES_STP_PAYMENTS_MUTATION, {
    refetchQueries: [WALLET_QUERY_NAME],
  });
  const [
    createInvoicePaymentsWithBankingInfoRequest,
    { loading: loadingRequestCLABEPayments },
  ] = useMutation<
    CreateInvoicePaymentsWithBankingInfoRequestReturn,
    CreateInvoicePaymentsWithBankingInfoRequestParams
  >(CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST, {
    refetchQueries: [WALLET_QUERY_NAME],
  });

  const mutate = async (
    invoices: Array<InvoiceInputType>,
    { onSuccess, onError }: { onError?: (e) => void; onSuccess: () => void }
  ) => {
    if (businessId) {
      const higoPayments = invoices
        .filter(
          (invoice) =>
            invoice.paymentMethod.paymentType ===
            InvoicePaymentType.HIGO_PAYMENT
        )
        .map((invoice) => ({
          invoiceId: invoice.id,
          amount: floatToCents(invoice.amount),
          paymentConcept: invoice?.paymentConcept?.trim(),
          users: invoice?.email?.map((email) => ({
            email,
          })),
        }));
      const speiPayments = invoices
        .filter(
          (invoice) =>
            invoice.paymentMethod.paymentType === InvoicePaymentType.STP_PAYMENT
        )
        .map((invoice) => ({
          invoiceId: invoice.id,
          amount: floatToCents(invoice.amount),
          paymentConcept: invoice?.paymentConcept?.trim(),
          providerContacts: invoice?.email?.map((email) => ({
            email,
          })),
        }));
      const requestCLABEPayments = invoices
        .filter(
          (invoice) =>
            invoice.paymentMethod.paymentType ===
            InvoicePaymentType.REQUEST_BANKING_INFO
        )
        .map((invoice) => ({
          invoiceId: invoice.id,
          amount: floatToCents(invoice.amount),
          paymentConcept: invoice?.paymentConcept?.trim(),
          emails: invoice?.email || [],
        }));

      try {
        if (higoPayments.length > 0) {
          await createHigoPayments({
            variables: {
              businessId: businessId,
              invoiceHigoPayments: higoPayments,
            },
          });
          trackEvent(CREATE_INVOICES_HIGO_PAYMENTS_ACTION);
        }
        if (speiPayments.length > 0) {
          await createSPEIPayments({
            variables: {
              businessId: businessId,
              invoiceStpPayments: speiPayments,
            },
          });
          trackEvent(CREATE_INVOICES_STP_PAYMENTS_ACTION);
        }
        if (requestCLABEPayments.length > 0) {
          await createInvoicePaymentsWithBankingInfoRequest({
            variables: {
              businessId: businessId,
              invoicePayments: requestCLABEPayments,
            },
          });

          trackEvent(CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST_ACTION);
        }
        if (onSuccess) onSuccess();
      } catch (e) {
        if (onError) onError(e);
      }
    }
  };

  return [
    mutate,
    {
      loading:
        loadingHigoPayments ||
        loadingSPEIPayments ||
        loadingRequestCLABEPayments,
    },
  ];
};

export default usePaymentsMutation;
