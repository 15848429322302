import React from 'react';
import { useIntl } from 'react-intl';

import Table from '@src/components/ui/Table';
import FormattedDate from '@src/components/i18n/FormattedDate';
import Currency from '@src/components/i18n/Currency';
import TransactionStatusBadge from '@src/components/shared/TransactionStatusBadge';
import { Trash2 } from '@src/components/ui/Icon/outline';
import Link from '@src/components/shared/Link';
import {
  IconButton,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@src/components/ui';

import { InvoicePaymentType } from '@src/types/enums';
import { InvoicePayment } from '@src/types/models';

interface PaymentsTableProps {
  payments?: Array<Required<InvoicePayment>>;
  currency: string;
  isPaymentReceiver: boolean;
  onDelete: (invoicePayments: Array<{ id: string; _destroy: boolean }>) => any;
}

interface InvoiceLinkProps {
  isPaymentReceiver: boolean;
  payment: {
    paymentType: InvoicePaymentType;
    paymentProof: string;
    walletEntryHashId: string;
    receiverWalletEntryHashId: string;
    creditNoteId: string;
  };
}

function InvoiceLink({ payment, isPaymentReceiver }: InvoiceLinkProps) {
  const intl = useIntl();

  if (payment.paymentType === InvoicePaymentType.MANUAL) {
    return payment.paymentProof ? (
      <Link as="a" href={payment.paymentProof} to={payment.paymentProof}>
        {intl.formatMessage({
          defaultMessage: 'Ver comprobante',
          id: 'BY0h13',
        })}
      </Link>
    ) : (
      <>
        {intl.formatMessage({
          defaultMessage: 'No hay comprobante',
          id: '27OQTF',
        })}
      </>
    );
  }

  if (payment.paymentType === InvoicePaymentType.STP_PAYMENT) {
    return payment.walletEntryHashId ? (
      <Link
        to={`/transactions/${payment.walletEntryHashId}`}
      >{`${payment.walletEntryHashId}`}</Link>
    ) : (
      <>
        {intl.formatMessage({
          defaultMessage: 'No hay transacción, asociada',
          id: 'aG3oqK',
        })}
      </>
    );
  }

  if (payment.paymentType === InvoicePaymentType.HIGO_PAYMENT) {
    return isPaymentReceiver ? (
      payment.receiverWalletEntryHashId ? (
        <Link
          to={`/transactions/${payment.receiverWalletEntryHashId}`}
        >{`${payment.receiverWalletEntryHashId}`}</Link>
      ) : (
        <>
          {intl.formatMessage({
            defaultMessage: 'No hay transacción, asociada',
            id: 'aG3oqK',
          })}
        </>
      )
    ) : payment.walletEntryHashId ? (
      <Link
        to={`/transactions/${payment.walletEntryHashId}`}
      >{`${payment.walletEntryHashId}`}</Link>
    ) : (
      <>
        {intl.formatMessage({
          defaultMessage: 'No hay transacción, asociada',
          id: 'aG3oqK',
        })}
      </>
    );
  }

  return (
    <Link to={`/credit_notes/${payment.creditNoteId}`}>
      {payment.creditNoteId}
    </Link>
  );
}

interface PaymentTypeProps {
  paymentType: InvoicePaymentType;
}

function PaymentType({ paymentType }: PaymentTypeProps) {
  const intl = useIntl();

  let message = intl.formatMessage({
    defaultMessage: 'Nota crédito',
    id: 'XyrDuO',
  });

  switch (paymentType) {
    case InvoicePaymentType.MANUAL:
      message = intl.formatMessage({
        id: 'spOcNl',
        defaultMessage: 'Pago manual',
      });
      break;

    case InvoicePaymentType.PAYMENT_COMPLEMENT:
      message = intl.formatMessage({
        id: 'iDWQxq',
        defaultMessage: 'Complemento de Pago',
      });
      break;

    case InvoicePaymentType.STP_PAYMENT:
      message = intl.formatMessage({
        id: '5NK1FG',
        defaultMessage: 'SPEI',
      });
      break;

    case InvoicePaymentType.HIGO_PAYMENT:
      message = intl.formatMessage({
        id: 'Re/h3s',
        defaultMessage: 'Higo Pay',
      });
      break;

    case InvoicePaymentType.BANKING_TRANSACTION:
      message = intl.formatMessage({
        id: 'ztnRJg',
        defaultMessage: 'Anticipo',
      });
      break;
  }

  return <>{message}</>;
}

const PaymentsTable = ({
  payments,
  isPaymentReceiver,
  onDelete,
}: PaymentsTableProps) => {
  const intl = useIntl();

  if (payments?.length === 0) {
    return (
      <span>
        {intl.formatMessage({
          defaultMessage: 'No hay pagos registrados',
          id: 'QhfmRu',
        })}
      </span>
    );
  }

  return (
    <Table>
      <TableHeader compact>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Fecha',
            id: 'eF7+Pa',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Valor',
            id: 'xoYWAa',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Tipo',
            id: 'wt+XcA',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Comprobante de pago',
            id: 'Y/vOdM',
          })}
        </TableHeaderCell>
        <TableHeaderCell>
          {intl.formatMessage({
            defaultMessage: 'Estatus',
            id: 'dWihL7',
          })}
        </TableHeaderCell>
        <TableHeaderCell />
      </TableHeader>
      <tbody>
        {payments?.map((payment, index) => (
          <TableRow key={index} compact>
            <TableCell>
              <FormattedDate value={payment.createdAt} />
            </TableCell>
            <TableCell>
              <Currency value={payment.amount} />
            </TableCell>
            <TableCell>
              <PaymentType paymentType={payment.paymentType} />
            </TableCell>
            <TableCell>
              <InvoiceLink
                payment={payment}
                isPaymentReceiver={isPaymentReceiver}
              />
            </TableCell>
            <TableCell>
              <TransactionStatusBadge status={payment.status} />
            </TableCell>
            <TableCell>
              {payment.paymentType === InvoicePaymentType.MANUAL && (
                <IconButton
                  compact
                  rounded
                  color="neutral"
                  variant="transparent"
                  onClick={() => onDelete([{ id: payment.id, _destroy: true }])}
                >
                  <Trash2 size={20} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default PaymentsTable;
