import React from 'react';
import { useIntl } from 'react-intl';

import { Icon, IconButton, Tooltip } from '@src/components/ui';
import useModal from '@src/hooks/useModal';
import PayFinancingModal from '@src/components/pages/WalletView/components/PayFinancingModal';
import { FinancingRequest } from '@src/types/models';
import { FlexContainer } from '@src/components/shared/layouts';
import { PAY_INDIVIDUAL_CREDIT_CLICKED } from '@src/constants/events';
import { trackEvent } from '@src/lib/analytics';

interface PayCreditButtonProps {
  request: FinancingRequest;
}

function PayCreditButton({ request }: PayCreditButtonProps) {
  const intl = useIntl();
  const { open } = useModal(PayFinancingModal);

  const openPayFinancingModal = () => {
    const eventData = {
      view: 'CreditActive',
      credit_id: request.id,
    };

    trackEvent(PAY_INDIVIDUAL_CREDIT_CLICKED, eventData);
    open({
      defaultRequest: request,
    });
  };

  return (
    <FlexContainer justifyContent="flex-end">
      <Tooltip
        id={`pay-credit-${request.id}`}
        title={intl.formatMessage({
          defaultMessage: 'Pagar crédito',
          id: 'UKACVk',
        })}
        place="left"
        effect="solid"
      >
        <IconButton
          color="neutral"
          variant="ghosted"
          onClick={openPayFinancingModal}
        >
          <Icon name="ArrowRightLine" size={20} />
        </IconButton>
      </Tooltip>
    </FlexContainer>
  );
}

export default PayCreditButton;
