import React, { ReactNode, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import Storage from 'local-storage-fallback';
import * as Sentry from '@sentry/react';

import { CURRENT_BUSINESS_ID } from '@src/constants/storageKeys';
import Loader from '@src/components/shared/Loader';
import CURRENT_USER_QUERY, {
  CurrentUserReturn,
} from '@src/graphql/queries/currentUser';
import { loggedIn } from '@src/lib/auth';
import { setUser, trackGroup } from '@src/lib/analytics';

import { Business, CurrentUser } from '@src/types/models';
import { useHistory } from 'react-router-dom';
import { getCurrentBusiness, getCurrentUser } from './utils';

export type SettingsContext = {
  business?: Business;
  currentUser?: CurrentUser;
  setCurrentBusinessId: (businessId: string) => void;
};

export const SettingsProviderContext = React.createContext<SettingsContext>({
  business: undefined,
  currentUser: undefined,
  setCurrentBusinessId: (id) => Storage.setItem(CURRENT_BUSINESS_ID, id),
});

type BusinessId = string | undefined;

interface SettingsProviderProps {
  business?: Business;
  currentUser?: CurrentUser;
  children: ReactNode;
}

function SettingsProvider({ children }: SettingsProviderProps) {
  const history = useHistory();
  const storedBusinessId = Storage.getItem(CURRENT_BUSINESS_ID);
  const [currentBusinessId, setCurrentBusinessId] = useState<BusinessId>(
    storedBusinessId || undefined
  );

  const { data, loading } = useQuery<CurrentUserReturn>(CURRENT_USER_QUERY, {
    skip: !loggedIn(),
    variables: undefined,
    onCompleted: (data) => {
      const { currentUser } = data;
      if (!currentUser) return;

      const business = getCurrentBusiness(currentUser, currentBusinessId);
      if (currentUser) {
        setUser({
          id: currentUser.id,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          email: currentUser.email,
          businessId: business?.id,
          businessName: business?.name,
        });
        Sentry.setUser({
          id: currentUser.id,
          email: currentUser.email,
          username: `${currentUser.firstName} ${currentUser.lastName}`,
          businessId: business?.id,
          businessName: business?.name,
        });
        if (business?.id) {
          trackGroup(business.id);
        }
      }
    },
    onError: () => {
      history.replace('/p/500');
    },
  });

  const settings = useMemo<SettingsContext>(() => {
    const { currentUser } = data || {};
    const business = getCurrentBusiness(currentUser, currentBusinessId);
    return {
      business,
      currentUser: getCurrentUser(currentUser, business),
      setCurrentBusinessId: (id: string) => {
        Storage.setItem(CURRENT_BUSINESS_ID, id);
        setCurrentBusinessId(id);
      },
    };
  }, [data, currentBusinessId]);

  if (loading) {
    return <Loader fullWidth />;
  }

  return (
    <SettingsProviderContext.Provider value={settings}>
      {children}
    </SettingsProviderContext.Provider>
  );
}

export default SettingsProvider;
