import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import TransactionsIdentifier from '@src/components/shared/TransactionsIdentifier/TransactionsIdentifier';
import Currency from '@src/components/i18n/Currency';
import FormattedDate, {
  dateDigitsFormat,
} from '@src/components/i18n/FormattedDate';
import LinkToTransactionDetails from '@src/components/pages/WalletView/components/LinkToTransactionDetails';
import * as S from './WalletTransactionModal.styles';
import Button from '@src/components/ui/Button';
import Header from '@src/components/ui/Header';
import Card from '@src/components/ui/Card';
import useTransaction from '@src/hooks/useTransaction';
import { Divider } from '@src/components/ui';
import TransactionStatusBadge from '@src/components/shared/TransactionStatusBadge';
import Clabe from '@src/components/shared/Clabe';
import messages from '@src/translations/messages/transactions';

import type { WalletEntry } from '@src/types/models';

interface WalletTransactionModalProps {
  walletEntry: WalletEntry;
  onClose?: () => void;
}
const KeyValue = ({ label, value }) => (
  <S.Row key={label}>
    <p>{label}</p>
    <strong>{value}</strong>
  </S.Row>
);

function WalletTransactionModal({
  walletEntry,
  onClose,
}: WalletTransactionModalProps) {
  const history = useHistory();
  const intl = useIntl();
  const {
    receiver,
    createdAt,
    amount,
    paymentType,
    status,
    recipient,
    sender,
    invoice,
    claimed,
  } = useTransaction(walletEntry);

  const handleButtonClick = () => {
    onClose && onClose();
    history.push(`transactions/${walletEntry?.hashId}`);
  };

  return (
    <S.Container>
      <TransactionsIdentifier walletEntry={walletEntry} withLabel />
      <S.Header>
        <Header as="h3">
          <Currency value={amount} />
        </Header>
        <TransactionStatusBadge status={status} claimed={claimed} />
      </S.Header>
      <Card backgroundColor="neutral" backgroundColorStep="100">
        <S.Section>
          <KeyValue
            label={intl.formatMessage({
              defaultMessage: 'Fecha de procesamiento',
              id: 'B1KUjk',
            })}
            value={<FormattedDate value={createdAt} {...dateDigitsFormat} />}
          />
          {paymentType && messages[paymentType] && (
            <KeyValue
              label={intl.formatMessage({
                defaultMessage: 'Método de Pago',
                id: 'Efd39f',
              })}
              value={intl.formatMessage(messages[paymentType])}
            />
          )}
        </S.Section>
        {sender && (
          <>
            <Divider margin="0" borderColor="neutral" borderColorStep="200" />
            <S.Section>
              <S.Title>
                {intl.formatMessage({
                  defaultMessage: 'De',
                  id: 'Po4Dhk',
                })}
              </S.Title>
              <KeyValue
                label={intl.formatMessage({
                  defaultMessage: 'Nombre',
                  id: 'hCOqfl',
                })}
                value={sender.name}
              />
              {sender.clabe && (
                <KeyValue
                  label="CLABE"
                  value={<Clabe as="strong" account={sender.clabe} />}
                />
              )}
              {sender.bankName && (
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Banco',
                    id: '2MvH8U',
                  })}
                  value={sender.bankName}
                />
              )}
              {sender.rfc && <KeyValue label="RFC" value={sender.rfc} />}
            </S.Section>
          </>
        )}

        {receiver && (
          <>
            <Divider margin="0" borderColor="neutral" borderColorStep="200" />
            <S.Section>
              <S.Title>
                {intl.formatMessage({
                  defaultMessage: 'Para',
                  id: 'elNXJ8',
                })}
              </S.Title>
              <KeyValue
                label={intl.formatMessage({
                  defaultMessage: 'Nombre',
                  id: 'hCOqfl',
                })}
                value={receiver.name}
              />
              {receiver.rfc && <KeyValue label="RFC" value={receiver?.rfc} />}
              {receiver.bankName && (
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Banco',
                    id: '2MvH8U',
                  })}
                  value={receiver.bankName}
                />
              )}
            </S.Section>
          </>
        )}
        {recipient && (
          <>
            <Divider margin="0" borderColor="neutral" borderColorStep="200" />
            <S.Section>
              <S.Title>
                {intl.formatMessage({
                  defaultMessage: 'Para',
                  id: 'elNXJ8',
                })}
              </S.Title>
              <KeyValue
                label={intl.formatMessage({
                  defaultMessage: 'Titular',
                  id: 'C2qvs5',
                })}
                value={recipient?.name}
              />
              {recipient?.clabe && (
                <KeyValue
                  label="CLABE"
                  value={<Clabe as="strong" account={recipient?.clabe} />}
                />
              )}
              <KeyValue
                label={intl.formatMessage({
                  defaultMessage: 'Banco',
                  id: '2MvH8U',
                })}
                value={recipient?.bankName}
              />
            </S.Section>
          </>
        )}

        {invoice && (
          <>
            <Divider margin="0" borderColor="neutral" borderColorStep="200" />
            <S.Section>
              <S.Title>
                {intl.formatMessage({
                  defaultMessage: 'Factura',
                  id: 'yZ36Da',
                })}
              </S.Title>
              <div onClick={onClose}>
                <LinkToTransactionDetails walletEntry={walletEntry} />
              </div>
            </S.Section>
          </>
        )}
      </Card>
      <Button block variant="ghosted" onClick={handleButtonClick}>
        {intl.formatMessage({
          defaultMessage: 'Ver detalle completo',
          id: 'BabLiT',
        })}
      </Button>
    </S.Container>
  );
}

WalletTransactionModal.displayName = 'WalletTransactionModal';

export default WalletTransactionModal;
