import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const percentMask = createNumberMask({
  prefix: '',
  suffix: '%',
  integerLimit: 2,
  allowDecimal: true,
});

export const numberMask = (maxLength: number) => (rawValue: string) => {
  const mask: Array<RegExp> = [];
  for (let i = 0; i < rawValue.length && i < maxLength; i++) {
    mask.push(/\d/);
  }
  return mask;
};

export const groupNumberMask = (maxLength: number, groupBy: number) => (
  rawValue: string
) => {
  const mask: Array<RegExp | string> = [];

  let count = 0;
  for (let i = 0; i < rawValue.length && i < maxLength; i++) {
    if (count === groupBy - 1) {
      mask.push(/\d/, ' ');
      count = -1;
    } else {
      mask.push(/\d/);
    }
    count++;
  }
  return mask;
};

export const notWhiteSpace = (maxLength: number) => (rawValue: string) => {
  const mask: Array<RegExp | string> = [];
  for (let i = 0; i < rawValue.length && i < maxLength; i++) {
    mask.push(/^\w+(\s\w+)*$/);
  }
  return mask;
};

export const noSpecialChars = (maxLength: number) => (rawValue: string) => {
  const mask: Array<RegExp | string> = [];
  for (let i = 0; i < rawValue.length && i < maxLength; i++) {
    mask.push(/^[a-zA-Z0-9\s]$/);
  }
  return mask;
};
