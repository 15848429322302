import { gql } from '@apollo/client';

export const CREATE_CODAT_DATA_SOURCE = gql`
  mutation CreateCodatDataSource(
    $businessId: ID!
    $codatId: String!
    $codatConnectionId: String!
    $platformName: String!
  ) {
    createCodatDataSource(
      businessId: $businessId
      codatId: $codatId
      codatConnectionId: $codatConnectionId
      platformName: $platformName
    ) {
      id
    }
  }
`;
