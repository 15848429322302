import React from 'react';
import Badge from '../Badge';
import { StyledTab } from './Tab.styles';
import { TabProps, RoundConfig } from './types';

const isRadiusInConfig = (position: string, config?: RoundConfig) => {
  if (!config) {
    return false;
  }

  if (typeof config === 'object') {
    return config[position];
  }

  return config;
};

export default function Tab(props: TabProps) {
  const {
    label,
    icon,
    onChange,
    selected,
    value,
    onClick,
    rounded,
    BadgeProps,
    ...otherProps
  } = props;

  const handleClick = (event) => {
    if (!selected && onChange) {
      onChange(value);
    }

    if (onClick) {
      onClick(event);
    }
  };

  const topRightRadius = isRadiusInConfig('right', rounded);
  const topLeftRadius = isRadiusInConfig('left', rounded);

  return (
    <StyledTab
      type="button"
      topRightRadius={topRightRadius}
      topLeftRadius={topLeftRadius}
      selected={selected}
      onClick={handleClick}
      {...otherProps}
    >
      <div className="tag-content">
        {icon ? icon : null}
        <p>{label}</p>
        {BadgeProps?.label ? <Badge {...BadgeProps} /> : null}
      </div>
    </StyledTab>
  );
}
