import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import * as S from './RequestFactoring.styles';
import { FlexContainer, Grid, Spacer } from '@src/components/shared/layouts';
import {
  Button,
  Divider,
  Header,
  Label,
  ModalHeader,
  Alert,
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  Text,
} from '@src/components/ui';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import { Currency } from '@src/components/i18n/Currency';
import useModal from '@src/hooks/useModal';
import ConfirmationContract from './components/ConfirmationContractModal';
import { REQUEST_FACTORING_STEP } from './types';
import ProcessingLoaderPage from '@src/components/shared/ProcessingLoaderPage';
import { DepositMoney } from '@src/components/ui/Icon/outline';
import useFactoringCalculations from './hooks/useFactoringCalculations';
import { FactoringOffer } from '@src/types/models';

type RequestFactoringProps = {
  factoringOffers: FactoringOffer[];
  onSubmit: (selectedInvoices: FactoringOffer[], amount: number) => void;
  view: REQUEST_FACTORING_STEP;
};

function RequestFactoring({
  factoringOffers,
  onSubmit,
  view,
}: RequestFactoringProps) {
  const intl = useIntl();
  const history = useHistory();

  const {
    open: openConfirmationContract,
    close: closeConfirmationContract,
  } = useModal(ConfirmationContract);

  const [selectedOffers, setSelectedOffers] = React.useState<FactoringOffer[]>(
    factoringOffers
  );

  const {
    cashableAmountCents,
    reserveAmountCents,
    discountAmountCents,
    totalInvoicesAmount,
    subtotalAmount,
    cashableAmountCentsWithoutDiscount,
  } = useFactoringCalculations(selectedOffers);

  const hideInvoice = (id: number) => {
    setSelectedOffers(selectedOffers.filter((i) => i.id !== id));
  };

  const handleSubmit = () => {
    openConfirmationContract({
      onClose: closeConfirmationContract,
      onSubmit: () => onSubmit(selectedOffers, cashableAmountCents),
    });
  };

  if (view === REQUEST_FACTORING_STEP.SUBMITTING_FACTORING) {
    return (
      <ProcessingLoaderPage
        image={<DepositMoney />}
        title={intl.formatMessage({
          defaultMessage: 'Tu adelanto esta siendo procesado...',
          id: 'b9mBwy',
        })}
        description={intl.formatMessage({
          defaultMessage:
            'Estamos procesando tu solicitud. Este proceso puede tomar unos minutos.',
          id: 'D76wvk',
        })}
      />
    );
  }

  return (
    <>
      <ModalHeader
        onClose={history.goBack}
        title={intl.formatMessage({
          defaultMessage: 'Adelantar facturas',
          id: 'z1M8S/',
        })}
      />
      <S.Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <S.InnerCard>
              <Table>
                <TableHeader>
                  <TableHeaderCell>
                    {intl.formatMessage({
                      defaultMessage: 'No Folio',
                      id: 'gdN/OU',
                    })}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {intl.formatMessage({
                      defaultMessage: 'Cliente',
                      id: 'DshuY+',
                    })}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {intl.formatMessage({
                      defaultMessage: 'Plazo',
                      id: 'uV75Sr',
                    })}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {intl.formatMessage({
                      defaultMessage: 'Costo financiamiento',
                      id: 'MRGlAW',
                    })}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {intl.formatMessage({
                      defaultMessage: 'Monto',
                      id: 'Axixya',
                    })}
                  </TableHeaderCell>
                  <TableHeaderCell />
                </TableHeader>
                <tbody>
                  {selectedOffers.map((offer) => (
                    <SelectableTableRow
                      key={offer.id}
                      cancelable
                      onCancel={() => {
                        hideInvoice(offer.id);
                        if (selectedOffers.length === 1) {
                          history.push('/factoring_offers');
                        }
                      }}
                    >
                      <TableCell>{offer.invoice.fiscalIdentifier}</TableCell>
                      <TableCell>
                        {offer.invoice.businessReceiver?.name}
                      </TableCell>
                      <TableCell>
                        {offer.paymentTerms}{' '}
                        {intl.formatMessage({
                          defaultMessage: 'días',
                          id: 'vTYp+4',
                        })}
                      </TableCell>
                      <TableCell>
                        <Currency value={offer.discountAmountCents} />
                      </TableCell>
                      <TableCell>
                        <S.AmountContainer>
                          <Currency value={offer.invoice.total || 0} />
                        </S.AmountContainer>
                      </TableCell>
                    </SelectableTableRow>
                  ))}
                </tbody>
              </Table>
            </S.InnerCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <S.InnerCard>
              <Header as="h5">
                {intl.formatMessage({
                  defaultMessage: 'Resumen',
                  id: 'hrWvfS',
                })}
              </Header>
              <Spacer margin="4" />
              <FlexContainer justifyContent="space-between">
                <Text colorStep="500">
                  {intl.formatMessage({
                    defaultMessage: 'Monto total',
                    id: 'SF6Pf+',
                  })}
                </Text>
                <Text>
                  <Currency value={totalInvoicesAmount} />
                </Text>
              </FlexContainer>
              <Spacer margin="5" />
              <FlexContainer justifyContent="space-between">
                <Text colorStep="500">
                  {intl.formatMessage({
                    defaultMessage: 'Total a recibir por adelanto',
                    id: '0U2HGE',
                  })}
                </Text>
                <Text strong>
                  <Currency value={subtotalAmount} />
                </Text>
              </FlexContainer>
              <Spacer margin="5" />
              <FlexContainer justifyContent="space-between">
                <Text colorStep="500">
                  {intl.formatMessage({
                    defaultMessage: 'Anticipo',
                    id: 'ztnRJg',
                  })}
                </Text>
                <Text>
                  <Currency value={cashableAmountCentsWithoutDiscount} />
                </Text>
              </FlexContainer>
              <Spacer margin="5" />
              <FlexContainer justifyContent="space-between">
                <Text colorStep="500">
                  {intl.formatMessage({
                    defaultMessage: 'Costo total de financiamiento + IVA',
                    id: 'w+Hk1x',
                  })}
                </Text>
                <Text color="danger" colorStep="500">
                  - <Currency value={discountAmountCents} />
                </Text>
              </FlexContainer>
              <Divider />
              <FlexContainer justifyContent="space-between">
                <Label colorStep="500" size="lg" strong>
                  {intl.formatMessage({
                    defaultMessage: 'Anticipo inmediato',
                    id: '3m7bZq',
                  })}
                </Label>
                <Label size="lg" strong>
                  <Currency value={cashableAmountCents} />
                </Label>
              </FlexContainer>
              <Spacer margin="6" />
              <Alert hasBorder>
                <FlexContainer justifyContent="space-between">
                  {intl.formatMessage({
                    defaultMessage: 'Al vencimiento',
                    id: 'U/EeE6',
                  })}
                  <S.ResultLabel size="lg" color="info" colorStep="500" strong>
                    <Currency value={reserveAmountCents} />
                  </S.ResultLabel>
                </FlexContainer>
              </Alert>
              <Spacer margin="6" />
              <Button onClick={handleSubmit} block>
                {intl.formatMessage({
                  defaultMessage: 'Adelantar',
                  id: 'hx5Tot',
                })}{' '}
                <Currency value={cashableAmountCents} />
              </Button>
            </S.InnerCard>
          </Grid>
        </Grid>
      </S.Container>
    </>
  );
}

export default RequestFactoring;
