import styled from 'styled-components';

export const BlueCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.primary[900]};
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing[12]};
  height: ${({ theme }) => theme.spacing[12]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GrayCircle = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[200]};
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing[18]};
  height: ${({ theme }) => theme.spacing[18]};
  display: flex;
  align-items: center;
  justify-content: center;
`;
