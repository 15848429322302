import { gql } from '@apollo/client';

const CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST = gql`
  mutation CreateInvoicePaymentsWithBankingInfoRequest(
    $businessId: ID!
    $invoicePayments: [InvoicePaymentsWithBankingInfoRequestInput!]!
  ) {
    createInvoicePaymentsWithBankingInfoRequest(
      businessId: $businessId
      invoicePayments: $invoicePayments
    ) {
      id
    }
  }
`;

export type InvoicePaymentsWithBankingInfoRequestInput = {
  invoiceId: string;
  amount: number;
  emails: string[];
  paymentConcept?: string;
};

export type CreateInvoicePaymentsWithBankingInfoRequestParams = {
  businessId: string;
  invoicePayments: InvoicePaymentsWithBankingInfoRequestInput[];
};

export type CreateInvoicePaymentsWithBankingInfoRequestReturn = {
  data: {
    createInvoicePaymentsWithBankingInfoRequest: { id };
  };
};

export default CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST;
