import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import { I18N } from '@src/lib/i18n';
import { trackEvent } from '@src/lib/analytics';
import { CREATE_BUSINESS_USER_ACCOUNT } from '@src/constants/events';
import { set as setAuthToken } from '@src/lib/auth';
import CREATE_BUSINESS_USER_ACCOUNT_MUTATION, {
  CreateBusinessUserAccountReturn,
  CreateBusinessUserAccountVariables,
} from '@src/graphql/mutations/createBusinessUserAccount';
import Button from '@src/components/ui/Button';
import useToast from '@src/hooks/useToast';
import { Header, TextField } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';

import { BusinessUser } from '@src/types/models';

type FormData = {
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
};

type CreateBusinessUserAccountFormProps = {
  businessUserInvitation: {
    businessUser: Pick<
      BusinessUser,
      'email' | 'firstName' | 'lastName' | 'business'
    >;
    inviteeEmail: string;
    inviterUser: {
      id: string;
      firstName: string;
    };
    nextStep: string;
    token: string;
  };
};

export function CreateBusinessUserAccountForm({
  businessUserInvitation,
}: CreateBusinessUserAccountFormProps) {
  const intl = useIntl();
  const history = useHistory();
  const { toast } = useToast();
  const [createBusinessUserAccount, { loading }] = useMutation<
    CreateBusinessUserAccountReturn,
    CreateBusinessUserAccountVariables
  >(CREATE_BUSINESS_USER_ACCOUNT_MUTATION, {
    onCompleted: (data) => {
      const receivedToken = data.createBusinessUserAccount.token;
      if (receivedToken) {
        setAuthToken(receivedToken);
      }
      trackEvent(CREATE_BUSINESS_USER_ACCOUNT);
      history.replace(`/`);
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          id: '6Rh3qr',
          defaultMessage: 'Error al crear tu cuenta',
        }),
        e.message
      );
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: businessUserInvitation.businessUser.firstName,
      lastName: businessUserInvitation.businessUser.lastName,
    },
  });

  const onSubmit = async (formData: FormData) => {
    if (businessUserInvitation.token) {
      createBusinessUserAccount({
        variables: {
          invitationToken: businessUserInvitation.token,
          businessUserAccount: {
            locale: I18N.getLocale().replace('-', '_'),
            ...formData,
          },
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage(
          {
            id: '+txm2y',
            defaultMessage:
              'Registrate como miembro del equipo de {businessName}.',
          },
          {
            businessName: businessUserInvitation?.businessUser?.business.name,
          }
        )}
      </Header>

      <div>
        <TextField
          required
          fullWidth
          {...register('firstName', {
            required: intl.formatMessage({
              id: '7Vvfe3',
              defaultMessage: 'Campo requerido',
            }),
          })}
          label={intl.formatMessage({
            id: '0t5BoY',
            defaultMessage: 'Nombre(s)',
          })}
          placeholder={intl.formatMessage({
            id: 'bqapLV',
            defaultMessage: 'Nombre del usuario',
          })}
          autoFocus
          error={!!errors.firstName?.message}
          helperText={errors.firstName?.message}
        />
        <TextField
          required
          fullWidth
          {...register('lastName', {
            required: intl.formatMessage({
              id: '7Vvfe3',
              defaultMessage: 'Campo requerido',
            }),
          })}
          label={intl.formatMessage({
            id: 'nVSFBU',
            defaultMessage: 'Apellidos',
          })}
          placeholder={intl.formatMessage({
            id: 'zZc09u',
            defaultMessage: 'Apellidos del contacto',
          })}
          error={!!errors.lastName?.message}
          helperText={errors.lastName?.message}
        />

        <TextField
          required
          fullWidth
          type="tel"
          {...register('phone', {
            required: intl.formatMessage({
              id: '7Vvfe3',
              defaultMessage: 'Campo requerido',
            }),
            minLength: 4,
            pattern: {
              value: /^\+?[1-9]\d{1,14}$/,
              message: intl.formatMessage({
                id: 'L+Cza1',
                defaultMessage: 'Teléfono inválido',
              }),
            },
            setValueAs: (value) => value.replace(/(\s|\+)/g, ''),
          })}
          label={intl.formatMessage({
            id: 'S6W4PT',
            defaultMessage: 'Teléfono',
          })}
          placeholder={intl.formatMessage({
            id: 'Pslibq',
            defaultMessage: '+52 55 2222 2222',
          })}
          error={!!errors.phone?.message}
          helperText={errors.phone?.message}
        />

        <TextField
          required
          fullWidth
          type="password"
          {...register('password', {
            required: intl.formatMessage({
              id: '7Vvfe3',
              defaultMessage: 'Campo requerido',
            }),
            minLength: {
              value: 8,
              message: intl.formatMessage(
                {
                  id: 'Isn6i2',
                  defaultMessage: 'Debe contener mínimo {length} caracteres.',
                },
                {
                  length: 8,
                }
              ),
            },
            validate: {
              isStrong: (value) =>
                (/[A-Z]/.test(value) &&
                  /\d/.test(value) &&
                  /[a-z]/.test(value)) ||
                intl.formatMessage({
                  id: 'PQBnTZ',
                  defaultMessage:
                    'Tu contraseña debe tener mínimo 8 caracteres, un número y una mayúscula',
                }),
            },
          })}
          label={intl.formatMessage({
            id: 'QqT6DL',
            defaultMessage: 'Contraseña',
          })}
          placeholder={intl.formatMessage({
            id: '6DPD4u',
            defaultMessage: 'Escribe tu contraseña',
          })}
          error={!!errors.password?.message}
          helperText={errors.password?.message}
        />
        <Spacer />
        <Button block type="submit" loading={loading}>
          {intl.formatMessage({
            id: 'jrPcz6',
            defaultMessage: 'Registrarme',
          })}
        </Button>
      </div>
    </form>
  );
}

export default CreateBusinessUserAccountForm;
