import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PENDING_APPROVAL: {
    defaultMessage: 'Por aprobar',
    id: 'djFdu3',
  },
  PENDING_PAYMENT: {
    defaultMessage: 'Por pagar',
    id: 'YkWZGw',
  },
  PAID: {
    defaultMessage: 'Pagada',
    id: 'AvWWd0',
  },
  CLOSED: {
    defaultMessage: 'Cerrada',
    id: 'twIVv3',
  },
  REJECTED: {
    defaultMessage: 'Rechazada',
    id: 'DzzVj2',
  },
  EXPIRED: {
    defaultMessage: 'Vencida',
    id: 'V6Zcg0',
  },
  FINANCING_REQUESTED: {
    defaultMessage: 'Financiamiento solicitado',
    id: 'KiXOgZ',
  },
  today: {
    defaultMessage: 'hoy',
    id: 'SQxUxg',
  },
  inDays: {
    defaultMessage: 'en {finalDays} días',
    id: '5RMqnO',
  },
  days: {
    defaultMessage: '{finalDays} días',
    id: 'M+nSKR',
  },
});

export default messages;
