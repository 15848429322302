import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

export type QueryType = { [key: string]: any };
type SetQueryType = (incomingQuery: QueryType) => void;

type UseQueryParamsReturn = [value: QueryType, setValue: SetQueryType];

function useQueryParams(): UseQueryParamsReturn {
  const { search } = useLocation();
  const history = useHistory();

  const query = useMemo(
    () =>
      qs.parse(search, {
        ignoreQueryPrefix: true,
        comma: true,
      }),
    [search]
  );

  const setQuery: SetQueryType = useCallback(
    (incomingQuery) => {
      const nextQuery = { ...query, ...incomingQuery };
      const nextQueryString = qs.stringify(nextQuery, {
        skipNulls: true,
        arrayFormat: 'comma',
        encode: false,
      });

      history.replace({ search: nextQueryString });
    },
    [query, history]
  );

  return [query, setQuery];
}

export default useQueryParams;
