import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Wand: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M12.2361 8.16938C13.0161 7.3873 14.2808 7.3873 15.0608 8.16938L16.349 9.46102C17.129 10.2431 17.129 11.5111 16.349 12.2932L5.58608 23.0846C4.80607 23.8667 3.54141 23.8667 2.7614 23.0846L1.47317 21.793C0.693157 21.0109 0.693155 19.7429 1.47317 18.9608L12.2361 8.16938ZM14.9367 10.8771L13.6484 9.58546L2.88551 20.3769L4.17374 21.6685L14.9367 10.8771Z" />
        <path d="M8.35817 12.0582C9.13818 11.2762 10.4028 11.2762 11.1828 12.0582L12.4711 13.3499C13.2511 14.132 13.2511 15.4 12.4711 16.182L5.58616 23.0852C4.80614 23.8673 3.54149 23.8673 2.76147 23.0852L1.47325 21.7936C0.693232 21.0115 0.69323 19.7435 1.47325 18.9614L8.35817 12.0582ZM11.0587 14.766L9.77051 13.4743L2.88559 20.3775L4.17381 21.6691L11.0587 14.766Z" />
        <path d="M14.0059 0.00012207C14.5581 0.00012207 15.0059 0.447837 15.0059 1.00012V4.37539C15.0059 4.92768 14.5581 5.37539 14.0059 5.37539C13.4536 5.37539 13.0059 4.92768 13.0059 4.37539V1.00012C13.0059 0.447837 13.4536 0.00012207 14.0059 0.00012207Z" />
        <path d="M14.9902 15.9858C15.5425 15.9858 15.9902 16.4336 15.9902 16.9858V20.3611C15.9902 20.9134 15.5425 21.3611 14.9902 21.3611C14.4379 21.3611 13.9902 20.9134 13.9902 20.3611V16.9858C13.9902 16.4336 14.4379 15.9858 14.9902 15.9858Z" />
        <path d="M6.45106 2.89737C6.8421 2.50736 7.47526 2.5082 7.86527 2.89924L10.247 5.28724C10.637 5.67828 10.6361 6.31145 10.2451 6.70145C9.85405 7.09146 9.22089 7.09062 8.83088 6.69958L6.44918 4.31158C6.05918 3.92054 6.06002 3.28737 6.45106 2.89737Z" />
        <path d="M17.7655 14.2111C18.1565 13.8211 18.7897 13.8219 19.1797 14.213L21.5614 16.601C21.9514 16.992 21.9506 17.6252 21.5595 18.0152C21.1685 18.4052 20.5353 18.4043 20.1453 18.0133L17.7636 15.6253C17.3736 15.2343 17.3745 14.6011 17.7655 14.2111Z" />
        <path d="M2.78711 9.8042C2.78711 9.25191 3.23482 8.8042 3.78711 8.8042H7.15346C7.70575 8.8042 8.15346 9.25191 8.15346 9.8042C8.15346 10.3565 7.70575 10.8042 7.15346 10.8042H3.78711C3.23482 10.8042 2.78711 10.3565 2.78711 9.8042Z" />
        <path d="M18.5 10.462C18.5 9.90975 18.9477 9.46204 19.5 9.46204H22.8664C23.4186 9.46204 23.8664 9.90975 23.8664 10.462C23.8664 11.0143 23.4186 11.462 22.8664 11.462H19.5C18.9477 11.462 18.5 11.0143 18.5 10.462Z" />
        <path d="M20.9052 3.65738C21.2963 4.04738 21.2971 4.68055 20.9071 5.07159L18.5254 7.45959C18.1354 7.85063 17.5023 7.85147 17.1112 7.46146C16.7202 7.07146 16.7193 6.43829 17.1093 6.04725L19.491 3.65925C19.881 3.26821 20.5142 3.26737 20.9052 3.65738Z" />
      </svg>
    );
  }
);

Wand.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Wand.displayName = 'Wand';

export default Wand;
