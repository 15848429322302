import React from 'react';
import { useIntl } from 'react-intl';

import Tag from '@src/components/ui/Tag';

import { InvoiceEmissionStatus } from '@src/types/enums';

interface EmissionStatusBadgeProps {
  status: keyof typeof InvoiceEmissionStatus;
}

export function EmissionStatusBadge({ status }: EmissionStatusBadgeProps) {
  const intl = useIntl();
  let color;
  let label;

  switch (status) {
    case InvoiceEmissionStatus.EMITTED:
      color = 'success';
      label = intl.formatMessage({
        defaultMessage: 'Emitida',
        id: '05YWgR',
      });
      break;
    case InvoiceEmissionStatus.EMISSION_REQUESTED:
      color = 'warning';
      label = intl.formatMessage({
        defaultMessage: 'Solicitada',
        id: 'tUDY29',
      });
      break;
    case InvoiceEmissionStatus.EMITTABLE:
      color = 'warning';
      label = intl.formatMessage({
        defaultMessage: 'Emitible',
        id: 'eOEvOq',
      });
      break;
    case InvoiceEmissionStatus.CANCELED:
      color = 'danger';
      label = intl.formatMessage({
        defaultMessage: 'Rechazada',
        id: 'DzzVj2',
      });
      break;
    case InvoiceEmissionStatus.FAILED:
      color = 'danger';
      label = intl.formatMessage({
        defaultMessage: 'Fallo',
        id: 'L1wml9',
      });
      break;
    case InvoiceEmissionStatus.PROCESSING:
      color = 'warning';
      label = intl.formatMessage({
        defaultMessage: 'Procesando',
        id: '4ujRn4',
      });
      break;
    case InvoiceEmissionStatus.PROCESSED:
      color = 'success';
      label = intl.formatMessage({
        defaultMessage: 'Procesada',
        id: 'SaRjGi',
      });
      break;
    default:
      break;
  }

  return <Tag color={color} label={label} />;
}

export default EmissionStatusBadge;
