import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';

import COUNTRIES_QUERY, {
  CountriesReturn,
} from '@src/graphql/queries/countries';
import { TextField } from '@src/components/ui';

import { TextFieldProps } from '@src/components/ui/TextField/types';

function CurrencySelect(
  { value, onChange, ...otherProps }: TextFieldProps,
  ref
) {
  const intl = useIntl();
  const { data, loading } = useQuery<CountriesReturn>(COUNTRIES_QUERY);
  const options = useMemo(
    () =>
      data?.countries?.edges
        ?.map((country) => ({
          value: country.node.currencyCode,
        }))
        ?.sort((a, b) => a.value.localeCompare(b.value)) || [],
    [data]
  );

  const handleChange = (option: { value: string }) => {
    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <TextField
      {...otherProps}
      type="select"
      label={intl.formatMessage({
        defaultMessage: 'Moneda',
        id: 'xOX686',
      })}
      options={options}
      placeholder={intl.formatMessage({
        defaultMessage: 'Selecciona una opción',
        id: '4P//YL',
      })}
      loading={loading}
      value={value && { value }}
      ref={ref}
      getOptionLabel={(option) => option.value}
      onChange={handleChange}
    />
  );
}

export default forwardRef(CurrencySelect);
