import React from 'react';

import { SelectableCell, CancelableCell } from './TableRow.styles';
import TableRow from './TableRow';
import IconButton from '@src/components/ui/IconButton';
import { X as Close } from '@src/components/ui/Icon/outline';
import Checkbox from '@src/components/ui/Checkbox';

import type { SelectableTableRowProps } from './types';

export default function SelectableTableRow(props: SelectableTableRowProps) {
  const {
    children,
    selectable,
    cancelable,
    selected,
    onSelect,
    onCancel,
    disabled,
    tablePosition = 'start',
    ...otherProps
  } = props;

  const handleClickableCell = (event) => {
    event.stopPropagation();
  };

  return (
    <TableRow {...otherProps}>
      {selectable && tablePosition === 'start' && (
        <SelectableCell onClick={handleClickableCell}>
          <Checkbox
            checked={selected}
            onClick={onSelect}
            disabled={disabled}
            buffer
          />
        </SelectableCell>
      )}

      {children}

      {cancelable && (
        <CancelableCell onClick={handleClickableCell}>
          <IconButton
            onClick={onCancel}
            variant="transparent"
            color="neutral"
            compact
            rounded
          >
            <Close />
          </IconButton>
        </CancelableCell>
      )}

      {selectable && tablePosition === 'end' && !cancelable && (
        <SelectableCell onClick={handleClickableCell}>
          <Checkbox
            variant="lg"
            checked={selected}
            onClick={onSelect}
            disabled={disabled}
            buffer
          />
        </SelectableCell>
      )}
    </TableRow>
  );
}
