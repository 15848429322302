import { gql } from '@apollo/client';

export const BUSINESS_RELATIONSHIPS_AS_CLIENT_QUERY = gql`
  query BusinessRelationshipsAsClient(
    $businessId: ID!
    $limit: Int
    $cursor: String
    $sortBy: BusinessRelationshipsSortingParams
  ) {
    businessRelationshipsAsClient(
      businessId: $businessId
      first: $limit
      after: $cursor
      sortBy: $sortBy
    ) {
      totalCount
      edges {
        node {
          id
          invoicesCount
          providerImageColorCode
          provider {
            id
            defaultTaxPayerInfo {
              id
              taxpayerName
              taxpayerIdentifier
            }
          }
        }
      }
    }
  }
`;
