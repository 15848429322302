/* eslint-disable no-var */
import { CustomWindow } from '../../custom.window';
import posthog from 'posthog-js';

const { REACT_APP_POSTHOG_API_KEY } = process.env;

declare var window: CustomWindow;

type AnalyticsUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  businessId?: string;
  businessName?: string;
};

export const isPosthogEnabled = !!REACT_APP_POSTHOG_API_KEY;

export function setUser(user?: AnalyticsUser) {
  if (user) {
    const userAttributes = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      company_id: user.businessId,
      company_name: user.businessName,
    };

    if (window.analytics) {
      window.analytics.identify(user.id, userAttributes);
    }
    if (isPosthogEnabled) {
      posthog.identify(user.id, userAttributes);
    }
  }
}

export function trackEvent(event: string, properties?: { [key: string]: any }) {
  if (window.analytics) {
    window.analytics.track(event, properties || {});
  }
  if (isPosthogEnabled) {
    posthog.capture(event, properties || {});
  }
}

export function trackGroup(
  groupId: string,
  properties?: { [key: string]: any }
) {
  if (window.analytics) {
    window.analytics.group(groupId, properties || {});
  }
}

export function trackPageView(
  name: string,
  category?: string,
  properties?: { [key: string]: any }
) {
  if (window.analytics) {
    if (category && properties) {
      window.analytics.page(category, name, properties);
    } else if (category) {
      window.analytics.page(category, name);
    } else {
      window.analytics.page(name);
    }
  }
  if (isPosthogEnabled) {
    posthog.capture(name);
  }
}
