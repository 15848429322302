import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const ToPay: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M10.0596 6.04382V17.9555" />
        <path d="M12.8168 8.21143H8.68122C7.61216 8.21143 6.7541 9.06029 6.7541 10.1024C6.7541 11.1445 7.6192 11.9933 8.68122 11.9933H11.4383C12.5073 11.9933 13.3654 12.8422 13.3654 13.8843C13.3654 14.9333 12.5003 15.7753 11.4383 15.7753H6.74707" />
        <path d="M18.1203 18.7702C18.1203 20.5507 16.6433 22 14.8287 22H5.29158C3.47699 22 2 20.5507 2 18.7702V5.22981C2 3.44928 3.47699 2 5.29158 2H14.8287C16.6433 2 18.1203 3.44928 18.1203 5.22981" />
        <path d="M16.7039 12.4359L22 12.4359" />
        <path d="M19.3485 9.83334L22 12.4351L19.3485 15.03" />
      </svg>
    );
  }
);

ToPay.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ToPay.displayName = 'ToPay';

export default ToPay;
