import { gql } from '@apollo/client';

export const CREATE_BUSINESS = gql`
  mutation CreateBusiness(
    $business: BusinessInput!
    $taxPayerInfo: TaxPayerInfoInput!
  ) {
    createBusiness(business: $business, taxPayerInfo: $taxPayerInfo) {
      business {
        id
      }
    }
  }
`;
