import styled from 'styled-components';

export const Bank = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[500]};
  height: ${({ theme }) => theme.spacing[12]};

  img,
  svg {
    max-height: ${({ theme }) => theme.spacing[8]};
    max-width: ${({ theme }) => theme.spacing[16]};
  }

  p {
    margin: 0;
    &.bank-name {
      text-transform: uppercase;
    }
    &:before {
      content: '-';
      margin: 0 ${({ theme }) => theme.spacing[2]};
    }
  }
`;
