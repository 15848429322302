import styled from 'styled-components';

export const Result = styled.div`
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
  border-radius: ${({ theme }) => theme.spacing[2]};
  width: 100%;
  display: block;
  .result-link {
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    border: none;
    outline: none;
  }
`;
