import { gql } from '@apollo/client';
import { Wallet } from '@src/types/models';

export const WALLET_QUERY_NAME = 'Wallet';

const WALLET_QUERY = gql`
  query Wallet($businessId: ID!) {
    wallet(businessId: $businessId) {
      id
      balance
      stpClabe
    }
  }
`;

export type WalletAttributes = Pick<Wallet, 'id' | 'balance' | 'stpClabe'>;

export type WalletQueryReturn = {
  wallet: WalletAttributes;
};

export type WalletQueryVariables = {
  businessId?: string;
};

export default WALLET_QUERY;
