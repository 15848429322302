import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import intersection from 'lodash/intersection';

import messages from './messages';
import * as S from './DownloadForm.styles';
import { Button, Radio } from '@src/components/ui';
import FormattedDate from '@src/components/i18n/FormattedDate';
import useSettings from '@src/hooks/useSettings';
import useProvidersListQuery from '@src/hooks/useProvidersListQuery';

type DownloadFormProps = {
  totalCount: number;
  filterCount: number;
  selectedCount: number;
  filters: {
    createdAtTo: string;
    createdAtFrom: string;
    status: string;
    providerId: string;
  };
  onSubmit: (data: { option: string }) => void;
  onClose?: () => void;
};

const DownloadForm = ({
  onSubmit,
  totalCount,
  filterCount,
  selectedCount,
  filters,
  onClose,
}: DownloadFormProps) => {
  const intl = useIntl();
  const business = useSettings('business');
  const { data } = useProvidersListQuery(business?.id);
  const { register, handleSubmit } = useForm();

  const acceptedFilters = [
    'createdAtTo',
    'createdAtFrom',
    'status',
    'providerId',
  ];

  const hasFilters =
    filters && intersection(Object.keys(filters), acceptedFilters).length > 0;

  const filtersTag = useMemo(() => {
    const filterEntries = Object.entries(filters || {});

    return filterEntries.map(([filter = '', value = ''], index) => {
      if (!acceptedFilters.includes(filter)) {
        return null;
      }

      const filterLabel = intl.formatMessage(messages[filter]);

      let label: React.ReactNode = `${filterLabel}: ${value}`;

      if (filter === 'createdAtTo' || filter === 'createdAtFrom') {
        label = (
          <div>
            {`${filterLabel}: `}
            <FormattedDate value={value} />
          </div>
        );
      } else if (filter === 'providerId') {
        const supplier = data.find((supplier) => supplier.id === value);

        label = `${filterLabel}: ${supplier?.name}`;
      } else if (filter === 'status') {
        label = `${filterLabel}: ${intl.formatMessage(messages[value])}`;
      }

      return <S.StyledTag color="gray" key={index} label={label} />;
    });
  }, [filters]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.Fields>
        <Radio
          value="selected"
          label={
            <S.RadioLabel>
              <p>
                {intl.formatMessage({
                  defaultMessage: 'Seleccionadas',
                  id: 'TniFBw',
                })}
              </p>
              {totalCount && (
                <p>
                  {intl.formatMessage(
                    {
                      defaultMessage: '{count} transacciones',
                      id: '+X/Rfv',
                    },
                    { count: selectedCount }
                  )}
                </p>
              )}
            </S.RadioLabel>
          }
          {...register(`option`)}
        />
        <Radio
          value="filtered"
          disabled={!hasFilters}
          label={
            <S.RadioLabel>
              <p>
                {intl.formatMessage({
                  defaultMessage: 'Filtrado (Todo)',
                  id: 'wBIP/p',
                })}
              </p>
              {hasFilters && filterCount ? (
                <p>
                  {intl.formatMessage(
                    {
                      defaultMessage: '{count} transacciones',
                      id: '+X/Rfv',
                    },
                    { count: filterCount }
                  )}
                </p>
              ) : (
                <span>N/A</span>
              )}
            </S.RadioLabel>
          }
          {...register(`option`)}
        />
        {hasFilters && (
          <S.FiltersContainer>
            <span>
              {intl.formatMessage({
                defaultMessage: 'Filtros:',
                id: 'Du7HT8',
              })}
            </span>
            <div>{hasFilters && filtersTag}</div>
          </S.FiltersContainer>
        )}
        {!hasFilters && (
          <>
            <S.Divisor />
            <Radio
              value="historical"
              defaultChecked={!!!hasFilters}
              label={
                <S.RadioLabel>
                  <p>
                    {intl.formatMessage({
                      defaultMessage: 'Todo el historial',
                      id: 'dHC9rt',
                    })}
                  </p>
                  {totalCount && (
                    <p>
                      {intl.formatMessage(
                        {
                          defaultMessage: '{count} transacciones',
                          id: '+X/Rfv',
                        },
                        { count: totalCount }
                      )}
                    </p>
                  )}
                </S.RadioLabel>
              }
              {...register(`option`)}
            />
          </>
        )}
      </S.Fields>

      <S.Actions>
        <Button color="neutral" variant="outlined" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Button type="submit">
          {intl.formatMessage({
            defaultMessage: 'Descargar',
            id: 'LcOlm6',
          })}
        </Button>
      </S.Actions>
    </form>
  );
};

export default DownloadForm;
