import React from 'react';
import { useIntl } from 'react-intl';

import DownloadSuccess from '@src/components/shared/DownloadSuccess';
import DownloadForm from '../DownloadForm';
import { Header } from '@src/components/ui';
import * as S from './DownloadTransactionsCSV.styles';
import useSettings from '@src/hooks/useSettings';
import useModal from '@src/hooks/useModal';
import useExportTransactionMutation from '@src/hooks/useExportTransactionMutation';

interface DownloadTransactionsCSVProps {
  totalCount: number;
  filterCount: number;
  selectedCount: number;
  hasAllSelected?: boolean;
  walletEntriesId?: string[];
  filters?: any;
  onClose?: () => void;
}
function DownloadTransactionsCSV({
  totalCount,
  walletEntriesId,
  filters,
  filterCount,
  selectedCount,
  onClose,
}: DownloadTransactionsCSVProps) {
  const intl = useIntl();
  const currentUser = useSettings('currentUser');
  const business = useSettings('business');
  const { open } = useModal(DownloadSuccess);

  const { mutate: exportTransactions } = useExportTransactionMutation();

  const onSubmit = async (data) => {
    if (data.option === 'filtered') {
      await exportTransactions({
        variables: {
          businessId: business?.id,
          status: filters?.status,
          providerId: filters?.providerId,
          createdAtFrom: filters?.createdAtFrom,
          createdAtTo: filters?.createdAtTo,
        },
      });
    } else if (data.option === 'selected') {
      await exportTransactions({
        variables: {
          businessId: business?.id,
          walletEntriesIds: walletEntriesId,
        },
      });
    } else {
      await exportTransactions({
        variables: {
          businessId: business?.id,
        },
      });
    }

    open({
      email: currentUser.email,
    });
  };

  return (
    <S.DownloadTransactionsCSV>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Descargar transacciones',
          id: '/YMZ5H',
        })}
      </Header>
      <S.Subtitle>
        {intl.formatMessage({
          defaultMessage: 'Se enviara un archivo CSV a ',
          id: 'LiReRS',
        })}
        <strong>{currentUser.email}</strong>
        {intl.formatMessage({
          defaultMessage: ' con la descarga de:',
          id: 'FbmzJn',
        })}
      </S.Subtitle>
      <DownloadForm
        filters={filters}
        totalCount={totalCount}
        filterCount={filterCount}
        selectedCount={selectedCount}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </S.DownloadTransactionsCSV>
  );
}

DownloadTransactionsCSV.displayName = 'DownloadTransactionsCSV';

export default DownloadTransactionsCSV;
