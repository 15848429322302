import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import PaginationControls from '@src/components/shared/PaginationControls';
import Table from '@src/components/shared/Table';
import EmptyResults from '@src/components/shared/EmptyResults';
import attributes from './attributes';

import type { WalletEntry } from '@src/types/models';
import type { SortParams } from '@src/types/utils';

type WalletEntryProperty = keyof WalletEntry;

type TransactionsTableProps = {
  data: WalletEntry[];
  loading?: boolean;
  count?: number;
  page?: number;
  selectedIds?: string[];
  hasNext?: boolean;
  hasPrevious?: boolean;
  hasAllSelected?: boolean;
  onSelect?: (id: string) => void;
  emptyElement?: React.ReactNode;
  columns?:
    | { [key: string]: string | number }
    | Array<WalletEntryProperty | string>;
  limit?: number;
  selectable?: boolean;
  sort?: SortParams;
  onSort?: (param: string) => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onSelectAll?: () => void;
  onLimitChange?: (limit: number) => void;
};

const TransactionsTable = (props: TransactionsTableProps) => {
  const {
    data,
    loading,
    count,
    hasNext,
    hasPrevious,
    selectedIds,
    hasAllSelected,
    columns,
    limit,
    page,
    sort,
    onSelect,
    selectable = false,
    emptyElement,
    onNext,
    onSort,
    onPrevious,
    onSelectAll,
    onLimitChange,
  } = props;
  const intl = useIntl();
  const history = useHistory();

  const fields = Array.isArray(columns)
    ? columns
    : Object.keys(columns || attributes);
  const columnsWidth =
    Array.isArray(columns) || !columns ? undefined : Object.values(columns);

  const handleRowClick = async (targetId) => {
    const targetTransaction = data.find(
      (transaction) => transaction.id === targetId
    );
    history.push(`/transactions/${targetTransaction?.hashId}`);
  };

  return (
    <>
      <Table
        keyExtractor={(item) => item.id}
        data={data}
        attributes={attributes}
        headers={fields}
        emptyElement={
          emptyElement || (
            <EmptyResults
              label={intl.formatMessage({
                defaultMessage: 'Aún no tienes transacciones',
                id: 'd+4RJp',
              })}
              description={intl.formatMessage({
                defaultMessage:
                  'Aquí verás todas las entradas y salidas de dinero de tu cuenta de Higo.',
                id: 'lpBhgp',
              })}
            />
          )
        }
        loading={loading}
        onRowClick={handleRowClick}
        limit={limit}
        columnsWidth={columnsWidth}
        onSort={onSort}
        sort={sort}
        selectedIds={selectedIds}
        selectable={selectable}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        hasAllSelected={hasAllSelected}
      />

      <PaginationControls
        limit={limit}
        loading={loading}
        page={page}
        itemCount={data.length}
        count={count}
        hasNext={hasNext}
        onNext={onNext}
        hasPrevious={hasPrevious}
        onPrevious={onPrevious}
        onLimitChange={onLimitChange}
      />
    </>
  );
};

export default memo(TransactionsTable);
