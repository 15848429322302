import { gql } from '@apollo/client';
import { FinancingRequest, WalletEntry } from '@src/types/models';
import { PaginatedResponse, Sort } from '@src/types/utils';

export const FINANCING_REQUESTS_QUERY = gql`
  query FinancingRequests(
    $businessId: ID!
    $creditAccountId: ID!
    $repaid: Boolean
    $first: Int
    $last: Int
    $before: String
    $after: String
    $sortBy: FinancingRequestsSortingParams
  ) {
    financingRequests(
      businessId: $businessId
      creditAccountId: $creditAccountId
      repaid: $repaid
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          id
          owedAmountCents
          amountCents
          overdue
          paidAt
          repaidAt
          interestAmountCents
          moratoryInterestAmountCents
          ivaInterestAmountCents
          totalInterestAmountCents
          repaidAmountCents
          repaidInterestCents
          annualMoratoryRate
          annualInterestRate
          dueDate
          paymentStatus
          repaymentWalletEntries {
            id
            receiptUrl
          }
        }
      }
      totalCount
    }
  }
`;

export type FinancingRequestsVariables = {
  businessId?: string;
  creditAccountId?: string;
  repaid?: boolean;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  sortBy?: Sort<'ID' | 'CREATED_AT'>;
};

type RepaymentWalletEntries = Pick<WalletEntry, 'id' | 'receiptUrl'>;

export type FinancingRequestsReturn = {
  financingRequests: PaginatedResponse<
    Pick<
      FinancingRequest,
      | 'id'
      | 'owedAmountCents'
      | 'owedTotalCents'
      | 'overdue'
      | 'paidAt'
      | 'interestAmountCents'
      | 'moratoryInterestAmountCents'
      | 'ivaInterestAmountCents'
      | 'totalInterestAmountCents'
      | 'repaidAmountCents'
      | 'repaidInterestCents'
      | 'annualMoratoryRate'
      | 'annualInterestRate'
      | 'dueDate'
      | 'paymentStatus'
    > & {
      repaymentWalletEntries?: RepaymentWalletEntries[];
    }
  >;
};

export default FINANCING_REQUESTS_QUERY;
