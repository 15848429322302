import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Send: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 14 14"
        fill={color}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4871 0.403864C13.14 0.0476033 12.6262 -0.0851213 12.1472 0.0545888L0.977073 3.30285C0.471674 3.44326 0.113451 3.84632 0.0169532 4.35836C-0.0816274 4.87948 0.26271 5.54101 0.712571 5.81763L4.20524 7.96428C4.56346 8.18432 5.02582 8.12914 5.32225 7.83016L9.32171 3.80581C9.52304 3.59624 9.85627 3.59624 10.0576 3.80581C10.2589 4.00839 10.2589 4.3367 10.0576 4.54627L6.0512 8.57132C5.75407 8.8696 5.69853 9.33414 5.91721 9.69459L8.05127 13.2223C8.30119 13.6414 8.73162 13.8789 9.20369 13.8789C9.25923 13.8789 9.32171 13.8789 9.37725 13.8719C9.91875 13.8021 10.3492 13.4318 10.5088 12.9079L13.8203 1.75207C13.9661 1.27705 13.8342 0.760125 13.4871 0.403864Z"
          fill={color}
        />
      </svg>
    );
  }
);

Send.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Send.displayName = 'Send';

export default Send;
