import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './Result.styles';

interface ResultProps {
  children?: React.ReactNode;
  href: string;
  onClick?: () => void;
}

const Result = ({ children, href, onClick, ...otherProps }: ResultProps) => {
  return (
    <S.Result as={Link} to={href} onClick={onClick} {...otherProps}>
      {children}
    </S.Result>
  );
};

export default Result;
