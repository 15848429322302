import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FileDropZone from '@src/components/shared/FileDropZone';
import { Button, Header } from '@src/components/ui';

import { Invoice } from '@src/types/models';
import { useMutation } from '@apollo/client';
import UPDATE_INVOICES_PAYMENTS_MUTATION, {
  UpdateInvoicePaymentsReturn,
  UpdateInvoicePaymentsVariables,
} from '@src/graphql/mutations/updateInvoicePayment';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';
import INVOICE_PAYMENTS_QUERY from '@src/graphql/queries/invoicePayments';
import useToast from '@src/hooks/useToast';
import { uploadFile } from '@src/lib/s3';
import * as S from './MarkAsPaid.styles';
import { trackEvent } from '@src/lib/analytics';
import {
  INVOICE_MANUALLY_PAID,
  UPDATE_INVOICE_PAYMENTS,
} from '@src/constants/events';
import useSettings from '@src/hooks/useSettings';
import { InvoicePaymentType } from '@src/types/enums';
import InvoicePayments from '../InvoicePayments';
import useModal from '@src/hooks/useModal';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import MultiEmailSelect from '@src/components/shared/select/MultiEmailSelect';
import { HistoricalRecordAccountPayableEventsDocument } from '../HistoricalRecord/__graphql__/historicalRecordAccountPayableEvents.query';

interface MarkAsPaidProps {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'businessReceiver'
    | 'businessEmitter'
    | 'businessRelationship'
    | 'paymentStatus'
    | 'total'
    | 'outstandingBalance'
    | 'currency'
  >;
  onClose?: () => void;
}

function MarkAsPaid({ invoice, onClose }: MarkAsPaidProps) {
  const intl = useIntl();
  const { handleSubmit, control } = useForm();
  const [fileUrl, setFileUrl] = useState('');
  const { business } = useSettings();
  const { toast } = useToast();
  const { open: openInvoicePayments } = useModal(InvoicePayments);
  const [updateInvoicePayments, { loading }] = useMutation<
    UpdateInvoicePaymentsReturn,
    UpdateInvoicePaymentsVariables
  >(UPDATE_INVOICES_PAYMENTS_MUTATION, {
    refetchQueries: [
      HistoricalRecordAccountPayableEventsDocument,
      INVOICE_QUERY,
      INVOICE_PAYMENTS_QUERY,
      SUPPLIER_RELATIONSHIP_QUERY_NAME,
    ],
    onCompleted: () => {
      trackEvent(UPDATE_INVOICE_PAYMENTS, {
        creator: 'emitter',
        view: 'InvoiceView',
      });
      trackEvent(INVOICE_MANUALLY_PAID);
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Factura actualizada',
          id: 'FZAoOc',
        }),
        intl.formatMessage(
          {
            defaultMessage:
              'El estatus de pago de la factura {invoiceId} ha sido actualizado.',
            id: 'mkY+va',
          },
          {
            invoiceId: invoice.id,
          }
        )
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar el estado de la factura',
          id: 'VcpAgx',
        }),
        e.message
      );
    },
  });

  const handleCancelClick = () => {
    openInvoicePayments({
      invoice,
    });
    onClose?.();
  };

  const options = useMemo(
    () =>
      invoice?.businessRelationship?.providerContacts?.map(
        (contact) => contact.contactable.email
      ) || [],
    [invoice]
  );

  const onSubmit = (data) => {
    if (invoice.id && business?.id) {
      updateInvoicePayments({
        variables: {
          businessId: business.id,
          invoiceId: invoice.id,
          invoicePayments: [
            {
              amount: invoice.outstandingBalance,
              paymentProof: fileUrl || '',
              paymentType: InvoicePaymentType.MANUAL,
            },
          ],
          providerContacts:
            data.providerContacts?.map((email) => ({
              email,
            })) || [],
        },
      });
    }
  };

  const handleDrop = async (files: Array<any>) => {
    const file = files[0];

    try {
      const upload = await uploadFile(file);
      if (upload) {
        setFileUrl(upload.Location);
      }
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error',
          id: 'KN7zKn',
        }),
        intl.formatMessage({
          defaultMessage: 'Error al cargar el archivo',
          id: 'LllFI9',
        })
      );
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Marcar como pagada',
          id: 'CnH3hH',
        })}
      </Header>
      <Spacer />
      <FileDropZone
        title={intl.formatMessage({
          defaultMessage: 'Comprobante de pago',
          id: 'Y/vOdM',
        })}
        subtitle={intl.formatMessage({
          defaultMessage:
            'Arrastrar archivo PDF, JPG, PNG o buscar en tu computadora',
          id: 'dhNIfK',
        })}
        acceptedFileType=".pdf, .doc, .jpeg, .jpg, .png"
        onDrop={handleDrop}
      />
      <Controller
        control={control}
        name="providerContacts"
        render={({ field }) => (
          <MultiEmailSelect
            options={options}
            fullWidth
            placeholder={intl.formatMessage({
              defaultMessage: 'Selecciona los correos a notificar',
              id: 'fC1RGL',
            })}
            {...field}
          />
        )}
      />
      <Spacer />
      <FlexContainer justifyContent="flex-end">
        <Button variant="ghosted" onClick={handleCancelClick}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button type="submit" loading={loading}>
          {intl.formatMessage({
            defaultMessage: 'Confirmar',
            id: '+/MNWw',
          })}
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

MarkAsPaid.displayName = 'MarkAsPaid';

export default MarkAsPaid;
