import { ApolloLink, ApolloClient, HttpLink } from '@apollo/client';

import errorLink from './errorLink';
import authLink from './authLink';
import optimizedCache from './optimizedCache';
import { API_URL, GRAPHQL_NAMESPACE } from '../api/utils';

const httpLink = new HttpLink({ uri: API_URL + GRAPHQL_NAMESPACE });

const optimizedClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: optimizedCache,
});

export default optimizedClient;
