import { gql } from '@apollo/client';

export const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier(
    $businessId: ID!
    $businessRelationshipId: ID!
    $supplierBusinessName: String
    $taxPayerInfo: TaxPayerInfoInput
    $bankingInfo: BankingInfoInput
    $businessRelationship: UpdateBusinessRelationshipInput
  ) {
    updateSupplier(
      businessId: $businessId
      businessRelationshipId: $businessRelationshipId
      supplierBusinessName: $supplierBusinessName
      businessRelationship: $businessRelationship
      taxPayerInfo: $taxPayerInfo
      bankingInfo: $bankingInfo
    ) {
      id
    }
  }
`;
