import { ProviderContact } from '@src/types/models';

export function getDefaultSupplierContact(businessRelationship?: {
  id: string;
  providerContacts?: Array<ProviderContact>;
}) {
  if (
    !businessRelationship ||
    (businessRelationship && !businessRelationship.providerContacts)
  )
    return [];

  const { providerContacts } = businessRelationship;

  const defaultContact = providerContacts
    ? providerContacts.filter((contact) => contact.defaultContact)
    : [];

  if (defaultContact.length > 0) {
    return [
      {
        label: defaultContact[0].contactable.email,
        value: defaultContact[0].contactable.email,
      },
    ];
  }

  if (providerContacts && providerContacts.length > 0) {
    return [
      {
        label: providerContacts[0].contactable.email,
        value: providerContacts[0].contactable.email,
      },
    ];
  }

  return [];
}

export const getSupplierContacts = (
  providerContacts?: Array<ProviderContact>
) =>
  providerContacts?.map(
    (contact: ProviderContact) => contact?.contactable?.email
  ) || [];
