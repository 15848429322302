import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button, Dot } from '@src/components/ui';
import useSettings from '@src/hooks/useSettings';
import userIsAdmin from '@src/utils/roles/userIsAdmin';

interface CardButtonProps {
  isOverdue?: boolean;
}

function CardButton({ isOverdue }: CardButtonProps) {
  const intl = useIntl();
  const history = useHistory();
  const [referenceElement, setReferenceElement] = useState();

  const { currentUser } = useSettings();
  const isCurrentUserAdmin = userIsAdmin(currentUser);

  const handleButtonClick = () => {
    history.push('/credit');
  };

  if (!isCurrentUserAdmin) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handleButtonClick}
        color="primary"
        variant="ghosted"
        compact
        ref={setReferenceElement}
      >
        {intl.formatMessage({
          id: 'oRrBaE',
          defaultMessage: 'Ir a mi crédito',
        })}
      </Button>
      {isOverdue && (
        <Dot
          anchorEl={referenceElement}
          borderColor="primary"
          borderColorStep="400"
        />
      )}
    </>
  );
}

export default CardButton;
