import React, { forwardRef, useMemo, useState } from 'react';

import Dropdown, { DropdownItem } from '@src/components/ui/Dropdown';
import {
  STPAccount,
  NewSTPAccount,
  HigoPayAccount,
  AccountPlaceholder,
  RequestAccount,
} from '@src/components/shared/AccountInformation';

import { InvoicePaymentType } from '@src/types/enums';
import type { BankingInfo, PaymentMethod } from '@src/types/models';

export interface PaymentMethodDropdownProps {
  defaultValue?: PaymentMethod;
  bankingInfos?: Array<BankingInfo>;
  fullWidth?: boolean;
  recipient?: string;
  error?: boolean;
  disabled?: boolean;
  value?: PaymentMethod;
  tooltipTitle?: string;
  paymentMethods?: Array<InvoicePaymentType>;
  onNewClick?: () => void;
  onChange?: (event) => void;
  onBlur?: (event) => void;
}

function PaymentMethodDropdown(
  {
    value,
    defaultValue,
    bankingInfos,
    fullWidth,
    paymentMethods = [],
    recipient,
    error,
    disabled,
    tooltipTitle,
    onNewClick,
    onChange,
  }: PaymentMethodDropdownProps,
  ref
) {
  const isHigoPayAvailable = paymentMethods.includes(
    InvoicePaymentType.HIGO_PAYMENT
  );

  const isSPEIAvailable = paymentMethods.includes(
    InvoicePaymentType.STP_PAYMENT
  );
  const isRequestCLABEAvailable =
    paymentMethods.includes(InvoicePaymentType.REQUEST_BANKING_INFO) &&
    bankingInfos?.length === 0;
  const isNewSPEIAvailable =
    isSPEIAvailable && onNewClick && bankingInfos?.length === 0;
  const [selected, setSelected] = useState<PaymentMethod | undefined>(
    defaultValue
  );

  const selectedOption = useMemo(() => {
    if (value?.paymentType === InvoicePaymentType.STP_PAYMENT) {
      const bankingInfo = bankingInfos?.find(
        (option) => option.id === (value.value || selected?.value)
      );
      if (bankingInfo) {
        return (
          <STPAccount
            logoUrl={bankingInfo?.bankLogoUrl}
            bankName={bankingInfo?.bankName}
            clabe={bankingInfo?.clabe}
          />
        );
      }
    }

    if (value?.paymentType === InvoicePaymentType.HIGO_PAYMENT) {
      return <HigoPayAccount disabled={!isHigoPayAvailable} name={recipient} />;
    }

    if (value?.paymentType === InvoicePaymentType.REQUEST_BANKING_INFO) {
      return <RequestAccount />;
    }

    return <AccountPlaceholder />;
  }, [bankingInfos, isHigoPayAvailable, recipient, value, isSPEIAvailable]);

  const handleDropdownChange = (value) => {
    setSelected(value);
    onChange && onChange(value);
  };

  return (
    <Dropdown
      ref={ref}
      value={value?.value}
      error={error}
      onChange={handleDropdownChange}
      rows={5}
      disabled={disabled}
      fullWidth={fullWidth}
      selectedNode={selectedOption}
      tooltipTitle={tooltipTitle}
    >
      {isHigoPayAvailable && (
        <DropdownItem
          disabled={!isHigoPayAvailable}
          key={InvoicePaymentType.HIGO_PAYMENT}
          value={{
            paymentType: InvoicePaymentType.HIGO_PAYMENT,
            value: undefined,
          }}
        >
          <HigoPayAccount disabled={!isHigoPayAvailable} name={recipient} />
        </DropdownItem>
      )}

      {isRequestCLABEAvailable && (
        <DropdownItem
          key={InvoicePaymentType.REQUEST_BANKING_INFO}
          value={{
            paymentType: InvoicePaymentType.REQUEST_BANKING_INFO,
            value: undefined,
          }}
        >
          <RequestAccount />
        </DropdownItem>
      )}

      {isSPEIAvailable &&
        bankingInfos?.map(({ id, bankName, bankLogoUrl, clabe }) => (
          <DropdownItem
            key={id}
            value={{
              paymentType: InvoicePaymentType.STP_PAYMENT,
              value: id,
            }}
          >
            <STPAccount
              logoUrl={bankLogoUrl}
              bankName={bankName}
              clabe={clabe}
            />
          </DropdownItem>
        ))}

      {isNewSPEIAvailable && (
        <DropdownItem key="new" onClick={onNewClick}>
          <NewSTPAccount />
        </DropdownItem>
      )}
    </Dropdown>
  );
}

export default forwardRef(PaymentMethodDropdown);
