import styled from 'styled-components';

export const Form = styled.form`
  width: ${({ theme }) => theme.spacing[112]};
`;

export const RateLabel = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[12]};
`;

export const Description = styled.p`
  margin: ${({ theme }) => theme.spacing[6]} 0;
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  p {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;
