import React from 'react';

import { X as Close } from '@src/components/ui/Icon/outline';
import { StyledContainer } from '@src/components/ui/Toast/Toast.styles';

import { CloseButtonProps } from 'react-toastify';
import { ContentProps } from './types';

function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <button className="Toastify__close-button" onClick={closeToast}>
      <Close size={20} />
    </button>
  );
}

function Content({ message, description }: ContentProps) {
  return (
    <p id="toast-description">
      <strong>{message}</strong>
      {description && (
        <>
          &nbsp;
          <span className="Toastify__toast-body-subtitle">{description}</span>
        </>
      )}
    </p>
  );
}
function Container() {
  return (
    <StyledContainer
      autoClose={5000}
      draggable={false}
      closeOnClick={true}
      closeButton={CloseButton}
      enableMultiContainer
      containerId="toast-container"
    />
  );
}

export { Container, Content, CloseButton };
