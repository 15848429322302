import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import isEmail from 'validator/lib/isEmail';

import { CREATE_PASSWORD_RESET } from '@src/constants/events';
import Button from '@src/components/ui/Button';
import TextField from '@src/components/ui/TextField';
import useToast from '@src/hooks/useToast';
import { CREATE_PASSWORD_RESET_MUTATION } from '@src/graphql/mutations';
import { trackEvent } from '@src/lib/analytics';

type PasswordResetFormData = {
  email: string;
};

export function PasswordResetForm() {
  const intl = useIntl();
  const history = useHistory();
  const { toast } = useToast();
  const [createPasswordReset, { loading }] = useMutation(
    CREATE_PASSWORD_RESET_MUTATION
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetFormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const onSubmit = async (data) => {
    try {
      await createPasswordReset({
        variables: data,
      });
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Recuperación en proceso',
          id: 'BCxt8C',
        }),
        intl.formatMessage(
          {
            defaultMessage:
              'En unos minutos recibirás en tu correo {email} instrucciones para recuperar tu contraseña',
            id: 'AJj4dC',
          },
          {
            email: data.email,
          }
        )
      );

      history.push('/p/login');
      trackEvent(CREATE_PASSWORD_RESET);
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error',
          id: 'KN7zKn',
        }),
        e.message
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        required
        {...register('email', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
          validate: {
            isEmail: (value) =>
              isEmail(value) ||
              intl.formatMessage({
                defaultMessage: 'Email inválido',
                id: 'yWNLzv',
              }),
          },
        })}
        label={intl.formatMessage({
          defaultMessage: 'Correo electrónico',
          id: 'pPCiwO',
        })}
        placeholder="email@ejemplo.com"
        type="email"
        autoFocus
        helperText={errors?.email?.message}
        error={!!errors?.email?.message}
      />

      <Button block type="submit" loading={loading}>
        {intl.formatMessage({
          defaultMessage: 'Enviar instrucciones',
          id: 'TS5gSo',
        })}
      </Button>
    </form>
  );
}

export default PasswordResetForm;
