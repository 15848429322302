import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;

  h4 {
    margin: 0;
  }
`;

export const Breadcrumb = styled.div`
  margin-bottom: 1rem;
`;

export const PageTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;
