import React from 'react';
import { IntlShape } from 'react-intl';
import { UseFormReturn } from 'react-hook-form';

import { FlexContainer } from '@src/components/shared/layouts';
import { AttributesObject } from '@src/types/resourceList';
import { Invoice } from '@src/types/models';
import { Currency, FormattedDate } from '@src/components/i18n';
import { defaultDateFormat } from '@src/components/i18n/FormattedDate';
import PaymentStatusBadge from '@src/components/shared/PaymentStatusBadge';
import { centsToFloat, floatToCents, stringToCents } from '@src/utils/currency';
import { InvoicePaymentStatus } from '@src/types/enums';
import { Icon, IconButton, TextField, Text } from '@src/components/ui';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import { InvoiceField } from './PaymentsReconciliationModal';
import type { FormData } from './PaymentsReconciliationModal';

export const getTableAttributes = (
  intl: IntlShape,
  { register, formState: { errors }, watch, setValue }: UseFormReturn<FormData>,
  [currentAmount, setCurrentAmount]: [
    number,
    React.Dispatch<React.SetStateAction<number>>
  ]
): AttributesObject<Invoice> => {
  const invoicesFields = watch('invoices');

  return {
    folio: {
      name: intl.formatMessage({
        id: '0/n5o8',
        defaultMessage: 'No. Folio',
      }),
      type: 'STRING',
      render: ({ receiverCountryTaxPayerInfo }) =>
        receiverCountryTaxPayerInfo?.folio || '-',
    },
    emissionDate: {
      name: intl.formatMessage({
        id: 'tq6DTX',
        defaultMessage: 'Fecha de emision',
      }),
      type: 'DATE_TIME',
      render: ({ invoicingDate }) =>
        invoicingDate ? (
          <FormattedDate value={invoicingDate} {...defaultDateFormat} />
        ) : (
          '-'
        ),
    },
    payment: {
      name: intl.formatMessage({
        id: 'uohz7v',
        defaultMessage: 'Estado del pago',
      }),
      type: 'CUSTOM',
      render: ({ id, outstandingBalance }) => (
        <FlexContainer justifyContent="center">
          <PaymentStatusBadge
            id={`payment-status-${id}`}
            status={getPaymentStatus(
              invoicesFields,
              `invoice-${id}`,
              outstandingBalance
            )}
            tooltip
          />
        </FlexContainer>
      ),
    },
    outstandingBalance: {
      name: intl.formatMessage({
        id: 'WqJhet',
        defaultMessage: 'Saldo Pendiente',
      }),
      type: 'STRING',
      render: ({ id, outstandingBalance }) => (
        <>
          {outstandingBalance >= 0 ? (
            <FlexContainer alignItems="center" justifyContent="space-between">
              <Text strong colorStep="900">
                <Currency
                  value={
                    outstandingBalance -
                    stringToCents(
                      invoicesFields?.[`invoice-${id}`]?.amount ?? 0
                    )
                  }
                />
              </Text>

              <IconButton
                variant="outlined"
                compact={true}
                disabled={currentAmount <= 0}
                onClick={() => {
                  if (currentAmount > 0) {
                    const balanceAmount = outstandingBalance;

                    const fieldAmount =
                      floatToCents(
                        invoicesFields[`invoice-${id}`].amount ?? 0
                      ) + currentAmount;

                    const amount =
                      balanceAmount <= fieldAmount
                        ? balanceAmount
                        : fieldAmount;

                    const newAmount = fieldAmount - amount;

                    setCurrentAmount(newAmount);
                    setValue(
                      `invoices.invoice-${id}.amount`,
                      centsToFloat(amount)
                    );
                  }
                }}
              >
                <Icon name="ArrowRight" />
              </IconButton>
            </FlexContainer>
          ) : (
            '-'
          )}
        </>
      ),
    },
    amount: {
      name: intl.formatMessage({
        id: 'Aso1M0',
        defaultMessage: 'Monto Conciliado',
      }),
      type: 'CUSTOM',
      render: ({ id, outstandingBalance }) => (
        <TextField
          {...register(`invoices.invoice-${id}.amount`, {
            validate: {
              isLessEqualThanTotal: (value) => {
                if (stringToCents(value || 0) > outstandingBalance) {
                  return intl.formatMessage({
                    id: 'Jum/BY',
                    defaultMessage: 'No puede ser mayor al monto restante',
                  });
                }
              },
              positive: (value) => {
                if (value && value < 0) {
                  return intl.formatMessage({
                    id: '1CA8ow',
                    defaultMessage: 'Debe ser mayor a 0',
                  });
                }
              },
            },
            onChange: (value) => {
              // Add previous field value to reset the currentAmount and subtract the new field amount to get the new total
              // Ex: oldValue = 100 oldAmount = 200 => newValue = 150 newAmount = 150
              const newAmount =
                currentAmount +
                floatToCents(invoicesFields[`invoice-${id}`].amount ?? 0) -
                floatToCents(parseCurrency(value.target.value) ?? 0);

              setCurrentAmount(newAmount);
            },
            setValueAs: (value) => {
              return parseCurrency(`${value}`);
            },
          })}
          fullWidth
          id={`invoices.invoice-${id}.amount`}
          type="currency"
          error={!!errors?.invoices?.[`invoice-${id}`]?.amount?.message}
          helperText={errors?.invoices?.[`invoice-${id}`]?.amount?.message}
          placeholder="MXN $0.00"
        />
      ),
    },
  };
};

/**
 * It takes an invoice ID and a balance(current input balance), and returns the payment status of the invoice
 * @param invoicesFields - Array of invoice fields
 * @param id - The id of the invoice
 * @param balance - The balance of the invoice input in cents.
 * @returns The payment status of the invoice.
 */
const getPaymentStatus = (
  invoicesFields: InvoiceField,
  id: string,
  balance: number
) => {
  if (invoicesFields) {
    const amount = stringToCents(invoicesFields[id]?.amount ?? 0);

    if (amount === balance) {
      return InvoicePaymentStatus.PAID;
    }
    if (amount > 0) {
      return InvoicePaymentStatus.PARTIAL;
    }
  }
  return InvoicePaymentStatus.PENDING;
};
