import React from 'react';
import { useIntl } from 'react-intl';
import CreditLineOverviewCard from '@src/components/shared/CreditLineOverviewCard';
import DuePaymentsWidget from '../DuePaymentsWidget';
import RequestCreditWidget from '../RequestCreditWidget';
import * as S from './Widgets.styles';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';

function Widgets() {
  const intl = useIntl();
  const hasAutomaticFinancingFunds = useFeatureFlag(
    FeatureFlag.AUTOMATIC_FINANCING_FUND
  );

  return (
    <S.Container>
      <S.CreditUseContainer>
        <CreditLineOverviewCard
          theme="neutral"
          showCreditButton={false}
          cardTitle={intl.formatMessage({
            defaultMessage: 'Uso del crédito',
            id: 'ceAL5n',
          })}
        />
      </S.CreditUseContainer>
      <S.RequestCreditContainer>
        {hasAutomaticFinancingFunds && <RequestCreditWidget />}
      </S.RequestCreditContainer>
      <S.DuePaymentsContainer>
        <DuePaymentsWidget />
      </S.DuePaymentsContainer>
    </S.Container>
  );
}

export default Widgets;
