import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import isEmail from 'validator/lib/isEmail';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './CreateUserForm.styles';
import messages from './messages';
import useQueryParams from '@src/hooks/useQueryParams';
import Card from '@src/components/ui/Card';
import Button from '@src/components/ui/Button';
import TextField from '@src/components/ui/TextField';
import TermsCheckbox from '@src/components/shared/TermsCheckbox';
import { CREATE_USER } from '@src/graphql/mutations';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { set as setAuthToken } from '@src/lib/auth';
import { CREATE_USER_EVENT } from '@src/constants/events';

export type CreateUserData = {
  createUser?: {
    token: string;
  };
};

export type CreateUserVariables = {
  user: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
  };
  fromHigoPay?: boolean;
};

export const CreateUserForm = () => {
  const intl = useIntl();
  const [termsApproved, setTermsApproved] = useState(false);
  const [query] = useQueryParams();
  const { toast } = useToast();
  const location = useLocation();
  const history = useHistory();
  const [createUser, { loading }] = useMutation<
    CreateUserData,
    CreateUserVariables
  >(CREATE_USER);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: query?.email,
      firstName: query.firstName,
      lastName: query.lastName,
      phone: query.phone,
      password: '',
    },
  });

  const fromHigoPay = !!query?.token;

  const onSubmit = async (data) => {
    try {
      const newUser = await createUser({
        variables: {
          user: data,
          fromHigoPay,
        },
      });
      const token = newUser?.data?.createUser?.token;

      if (token) {
        setAuthToken(token);
      }

      trackEvent(CREATE_USER_EVENT);
      history.replace(`/${location.search}`);
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al crear cuenta',
          id: 'zDYQ2G',
        }),
        e.message
      );
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Card padding="8">
        <TextField
          required
          {...register('firstName', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          autoFocus={!query.firstName}
          fullWidth
          helperText={errors?.firstName?.message}
          error={!!errors?.firstName?.message}
          label={intl.formatMessage(messages.firstNameContactLabel)}
          placeholder={intl.formatMessage(messages.firstNameContactPlaceholder)}
          data-testid="user-first-name-input"
        />

        <TextField
          required
          {...register('lastName', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          fullWidth
          autoFocus={query.firstName && !query.lastName}
          helperText={errors?.lastName?.message}
          error={!!errors?.lastName?.message}
          label={intl.formatMessage(messages.lastNameContactLabel)}
          placeholder={intl.formatMessage(messages.lastNameContactPlaceholder)}
          data-testid="user-last-name-input"
        />
        <TextField
          required
          {...register('email', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            validate: {
              isEmail: (value) =>
                isEmail(value) ||
                intl.formatMessage({
                  defaultMessage: 'Email inválido',
                  id: 'yWNLzv',
                }),
            },
          })}
          type="email"
          fullWidth
          autoFocus={query.firstName && query.lastName && !query.email}
          helperText={errors?.email?.message}
          error={!!errors?.email?.message}
          label={intl.formatMessage(messages.emailLabel)}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          data-testid="email-input"
        />
        <TextField
          {...register('phone', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            pattern: {
              value: /^\+?[1-9]\d{1,14}$/,
              message: intl.formatMessage({
                defaultMessage: 'Teléfono inválido',
                id: 'L+Cza1',
              }),
            },
            setValueAs: (value) => value.replace(/(\s|\+)/g, ''),
          })}
          required
          type="tel"
          fullWidth
          autoFocus={
            query.firstName && query.lastName && query.email && !query.phone
          }
          defaultValue={query?.phone}
          helperText={errors?.phone?.message}
          error={!!errors?.phone?.message}
          label={intl.formatMessage(messages.phoneLabel)}
          placeholder={intl.formatMessage(messages.phonePlaceholder)}
          data-testid="phone-input"
        />
        <TextField
          required
          {...register('password', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            minLength: {
              value: 8,
              message: intl.formatMessage(
                {
                  defaultMessage: 'Debe contener mínimo {length} caracteres.',
                  id: 'Isn6i2',
                },
                {
                  length: 8,
                }
              ),
            },
            validate: {
              isStrong: (value) =>
                (/[A-Z]/.test(value) &&
                  /\d/.test(value) &&
                  /[a-z]/.test(value)) ||
                intl.formatMessage({
                  defaultMessage:
                    'Tu contraseña debe tener mínimo 8 caracteres, un número y una mayúscula',
                  id: 'PQBnTZ',
                }),
            },
          })}
          fullWidth
          autoFocus={
            query.firstName && query.lastName && query.email && query.phone
          }
          helperText={errors?.password?.message}
          error={!!errors?.password?.message}
          type="password"
          label={intl.formatMessage(messages.passwordLabel)}
          placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          data-testid="password-input"
        />
      </Card>
      <div className="terms">
        <TermsCheckbox
          data-testid="terms-input"
          checked={termsApproved}
          acceptPrivacy
          onChange={() => setTermsApproved(!termsApproved)}
          label={intl.formatMessage({
            defaultMessage: 'Al crear cuenta, acepto los',
            id: 'wQybVs',
          })}
        />
      </div>
      <Button
        block
        type="submit"
        disabled={!termsApproved}
        data-testid="create-user-submit"
        loading={loading}
      >
        {intl.formatMessage({
          defaultMessage: 'Crear cuenta',
          id: '4sjoNr',
        })}
      </Button>
    </S.Form>
  );
};

export default CreateUserForm;
