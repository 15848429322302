import {
  HIGO_CAPITAL_RFC,
  HIGO_CAPITAL_TAXPAYERNAME,
} from '@src/constants/financingRepayments';
import {
  ENTRY_DEPOSIT_ENTRY,
  ENTRY_INVOICE_PAYMENT_ENTRY,
  ENTRY_HIGO_PAYMENT_SENT_ENTRY,
  ENTRY_HIGO_PAYMENT_RECEIVED_ENTRY,
  ENTRY_REFUND_ENTRY,
  ENTRY_WITHDRAWAL_ENTRY,
  ENTRY_BANKING_TRANSACTION_ENTRY,
} from '@src/constants/transactions';
import useSettings from '@src/hooks/useSettings';

import type { WalletEntry, Transaction } from '@src/types/models';

function useTransaction(walletEntry: WalletEntry): Transaction {
  const { business } = useSettings();

  const type = walletEntry.entryable.__typename;
  const isHigoPay = ENTRY_HIGO_PAYMENT_SENT_ENTRY === type;
  const isFinancingRepayment = walletEntry.entryable.financingRequestRepayment;
  const isSPEI = ENTRY_INVOICE_PAYMENT_ENTRY === type;
  const isDeposit = ENTRY_DEPOSIT_ENTRY === type;
  const isCharge = ENTRY_HIGO_PAYMENT_RECEIVED_ENTRY === type;
  const isRefund = ENTRY_REFUND_ENTRY === type;
  const isWithdrawal = ENTRY_WITHDRAWAL_ENTRY === type;
  const isHigoFunding =
    ENTRY_HIGO_PAYMENT_RECEIVED_ENTRY === type &&
    !walletEntry?.entryable?.invoicePayment;
  const isHigoCharge =
    ENTRY_HIGO_PAYMENT_SENT_ENTRY === type &&
    !walletEntry?.entryable?.invoicePayment;

  const isBankingTransaction = ENTRY_BANKING_TRANSACTION_ENTRY === type;

  const transaction: Transaction = {
    createdAt: walletEntry?.createdAt,
    amount: walletEntry?.amount,
    claimed: walletEntry?.claimed,
    positive: walletEntry?.amount > 0,
    type: undefined,
    status: walletEntry?.status,
    emails:
      walletEntry?.providerContacts?.map(
        (contact) => contact?.contactable?.email
      ) || [],
    user:
      walletEntry?.user &&
      `${walletEntry.user.firstName} ${walletEntry.user.lastName}`,
    hashId: walletEntry?.hashId,
  };

  if (walletEntry?.entryable?.invoicePayment) {
    const invoice = walletEntry?.entryable?.invoicePayment?.invoice;
    transaction.invoice = {
      id: invoice?.id,
      uuid:
        invoice?.fiscalIdentifier ||
        invoice?.receiverCountryTaxPayerInfo?.fiscalUuid,
      emitterTaxpayerName: invoice?.businessEmitter?.name,
      emitterTaxpayerIdentifier: invoice?.emitterTaxpayerIdentifier,
      emitterId: invoice?.businessEmitter?.hashId,
      receiverTaxpayerName: invoice?.businessReceiver?.name,
      receiverTaxpayerIdentifier: invoice?.receiverTaxpayerIdentifier,
      receiverId: invoice?.businessReceiver?.hashId,
    };
    transaction.paymentType =
      walletEntry?.entryable?.invoicePayment?.paymentType;
  }

  if (walletEntry?.recipient) {
    transaction.recipient = {
      name: walletEntry?.recipient?.recipient,
      bankName: walletEntry?.recipient?.bankName,
      clabe: walletEntry?.recipient?.clabe,
    };
    transaction.receiver = {
      name: walletEntry?.recipient?.recipient,
    };
  }

  if (isHigoFunding) {
    transaction.type = 'HIGO_FUNDING';
    transaction.positive = true;
    transaction.sender = {
      name: 'Higo Capital',
      rfc: HIGO_CAPITAL_RFC,
    };
    transaction.description = walletEntry?.entryable?.paymentConcept;
    return transaction;
  }

  if (isHigoCharge) {
    transaction.type = 'PAYMENT';
    transaction.positive = false;
    transaction.receiver = {
      name: 'Higo Capital',
      rfc: HIGO_CAPITAL_RFC,
    };
    transaction.description = walletEntry?.entryable?.paymentConcept;
    return transaction;
  }

  if (isFinancingRepayment) {
    transaction.type = 'FINANCE_REPAYMENT';
    transaction.positive = false;
    transaction.receiver = {
      name: HIGO_CAPITAL_TAXPAYERNAME,
      rfc: HIGO_CAPITAL_RFC,
    };
    return transaction;
  }

  if (isHigoPay) {
    const invoice = walletEntry.entryable.invoicePayment?.invoice;
    transaction.type = 'PAYMENT';
    transaction.positive = false;
    transaction.description = walletEntry?.entryable?.paymentConcept;
    transaction.receiver = {
      name: invoice?.businessEmitter?.name,
      rfc: invoice?.emitterTaxpayerIdentifier,
    };
    return transaction;
  }

  if (isSPEI) {
    transaction.type = 'PAYMENT';
    transaction.positive = false;
    transaction.description = walletEntry.entryable?.stpPayment?.paymentConcept;
    transaction.trackingKey = walletEntry?.entryable?.trackingKey;
    return transaction;
  }

  if (isDeposit) {
    transaction.type = 'DEPOSIT';
    transaction.paymentType = 'STP_PAYMENT';
    transaction.positive = true;
    transaction.sender = {
      name: walletEntry?.entryable?.senderName,
      clabe: walletEntry?.entryable?.senderNumberAccount,
      bankName: walletEntry?.entryable?.senderBank,
    };
    transaction.trackingKey = walletEntry?.entryable?.trackingKey;
    return transaction;
  }

  if (isCharge) {
    const invoice = walletEntry?.entryable?.invoicePayment?.invoice;
    transaction.type = 'CHARGE';
    transaction.positive = true;
    transaction.sender = {
      name: invoice?.businessReceiver?.name,
      rfc: invoice?.receiverTaxpayerIdentifier,
    };
    transaction.description = walletEntry?.entryable?.paymentConcept;
    return transaction;
  }

  if (isRefund) {
    transaction.type = 'REFUND';
    transaction.positive = true;
    transaction.description = walletEntry?.entryable?.refundable?.errorMessage;
    const invoice = walletEntry?.entryable?.refundable?.invoice;
    if (invoice) {
      transaction.invoice = {
        id: invoice?.id,
        uuid: invoice?.receiverCountryTaxPayerInfo?.fiscalUuid,
        emitterTaxpayerName: invoice?.businessEmitter?.name,
        emitterId: invoice?.businessEmitter?.hashId,
      };
    }
    delete transaction.recipient;
    delete transaction.receiver;
    return transaction;
  }

  if (isWithdrawal) {
    transaction.type = 'WITHDRAWAL';
    return transaction;
  }

  if (isBankingTransaction) {
    transaction.type = 'ADVANCE_PAYMENT';
    transaction.recipient = {
      name: walletEntry?.entryable?.metadata?.receiverName,
      clabe: walletEntry?.entryable?.receiverAccount?.clabe,
      bankName: walletEntry.entryable.receiverAccount?.bank?.name,
    };
    transaction.description = walletEntry?.entryable?.paymentConcept;
    transaction.trackingKey = walletEntry?.entryable?.trackingKey;
    transaction.invoices = walletEntry?.entryable?.invoicePayments?.map(
      ({ invoice }) => ({
        id: invoice?.id,
        uuid: invoice?.receiverCountryTaxPayerInfo?.fiscalUuid,
        emitterTaxpayerName: invoice?.businessEmitter?.name,
        emitterId: invoice?.businessEmitter?.hashId,
      })
    );
    if (transaction.invoices && transaction.invoices?.length > 0) {
      transaction.invoice = transaction.invoices[0];
    }
  }

  // When the BankingTransaction is a deposit in Higo Pay
  if (
    isBankingTransaction &&
    business?.taxPayerInfos?.[0].taxpayerIdentifier ===
      walletEntry.entryable.receiverAccount?.taxId
  ) {
    transaction.type = 'DEPOSIT';
    transaction.trackingKey = walletEntry?.entryable?.trackingKey;
    transaction.sender = {
      name: walletEntry?.entryable?.metadata?.senderName,
      clabe: walletEntry?.entryable.senderAccount?.clabe,
      bankName: walletEntry.entryable.senderAccount?.bank?.name,
    };
    delete transaction.recipient;
  }

  return transaction;
}

export default useTransaction;
