import { gql } from '@apollo/client';
import { Invoice } from '@src/types/models';
import { INVOICE_LIST_ATTRIBUTES } from '../fragments/invoiceListAttributes';

const ARCHIVE_INVOICES_MUTATION = gql`
  mutation ArchiveInvoices(
    $businessId: ID!
    $invoiceIds: [ID!]!
    $archived: Boolean!
  ) {
    archiveInvoices(
      businessId: $businessId
      invoiceIds: $invoiceIds
      archived: $archived
    ) {
      ...InvoicesListAttributes
    }
  }
  ${INVOICE_LIST_ATTRIBUTES}
`;

export type ArchiveInvoicesParams = {
  businessId: string;
  invoiceIds: string[];
  archived: boolean;
};

export type ArchiveInvoicesReturn = {
  archiveInvoices: Invoice[];
};

export default ARCHIVE_INVOICES_MUTATION;
