import React, { memo } from 'react';
import toPairs from 'lodash/toPairs';
import { FormattedMessage, useIntl } from 'react-intl';

import TableUI from '@src/components/ui/Table';
import TableRow from '@src/components/ui/TableRow';
import TableCell from '@src/components/ui/TableCell';
import TableHeader from '@src/components/ui/TableHeader';
import TableHeaderCell from '@src/components/ui/TableHeaderCell';
import messages from './messages';
import type { ClientRelationship } from '@src/types/models';
import type { Attributes } from '@src/types/resourceList';
import Button from '@src/components/ui/Button';
import EmptyResults from '@src/components/shared/EmptyResults';

type TableProps = {
  fields: string[];
  attributes: Attributes<ClientRelationship>;
  data: ClientRelationship[];
  emptyElement?: React.ReactNode;
  loading?: boolean;
  limit?: number;
  columnsWidth?: Array<number | string>;
  onEdit?: (id: string) => void;
};

const Table = (props: TableProps) => {
  const {
    data,
    fields,
    attributes,
    emptyElement,
    loading,
    columnsWidth,
    limit = 25,
    onEdit,
  } = props;
  const intl = useIntl();

  return (
    <>
      <TableUI stickyHeader columnsWidth={columnsWidth}>
        <TableHeader>
          {toPairs(attributes).map(
            ([key, { name }]) =>
              fields.includes(key) && (
                <TableHeaderCell key={`${key}-header`}>{name}</TableHeaderCell>
              )
          )}
          {onEdit && <TableHeaderCell></TableHeaderCell>}
        </TableHeader>
        <tbody>
          {loading &&
            [...Array(limit).keys()].map((i) => (
              <TableRow key={`client-${i}-row`}>
                {toPairs(attributes).map(
                  ([key]) =>
                    fields.includes(key) && (
                      <TableCell key={`client-${i}-${key}`} loading />
                    )
                )}
              </TableRow>
            ))}
          {!loading &&
            data.length > 0 &&
            data.map((client) => (
              <TableRow key={`client-${client.id}-row`}>
                {toPairs(attributes).map(
                  ([key, { render }]) =>
                    fields.includes(key) && (
                      <TableCell key={`client-${client.id}-${key}`}>
                        {render && render(client, props)}
                      </TableCell>
                    )
                )}
                {onEdit && (
                  <TableCell>
                    <Button
                      compact
                      variant="ghosted"
                      onClick={() => {
                        onEdit(client.id);
                      }}
                    >
                      <FormattedMessage defaultMessage="Editar" id="VGHwB5" />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </tbody>
      </TableUI>

      {!loading && data.length === 0 && (
        <EmptyResults label={intl.formatMessage(messages.clientsEmptyState)} />
      )}
    </>
  );
};

export default memo(Table);
