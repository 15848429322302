import { gql } from '@apollo/client';

const CREATE_INVOICE_COMMENT_MUTATION = gql`
  mutation CreateInvoiceComment(
    $businessId: ID!
    $invoiceId: ID!
    $comment: String!
    $taggedUserIds: [ID!]
  ) {
    createInvoiceComment(
      businessId: $businessId
      invoiceId: $invoiceId
      comment: $comment
      taggedUserIds: $taggedUserIds
    ) {
      id
    }
  }
`;

export type CreateInvoiceCommentVariables = {
  businessId: string;
  invoiceId: string;
  comment: string;
  taggedUserIds?: string[];
};

export type CreateInvoiceCommentReturn = {
  createInvoiceComment: {
    id: string;
  };
};

export default CREATE_INVOICE_COMMENT_MUTATION;
