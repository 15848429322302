export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigAmount: any;
  DateTime: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Attributes used for creating an account */
export type AccountInput = {
  business: BusinessInput;
  countryId: Scalars['ID'];
  jobPosition?: InputMaybe<Scalars['String']>;
  user: UserInput;
};

export type AccountPayable = {
  __typename?: 'AccountPayable';
  business: Business;
  id: Scalars['ID'];
  paidTotalAmount: Scalars['BigAmount'];
  pastDueAmount: Scalars['BigAmount'];
  pendingTotalAmount: Scalars['BigAmount'];
};

export type AccountPayableEvent = {
  __typename?: 'AccountPayableEvent';
  createdAt: Scalars['DateTime'];
  eventable: Eventable;
  id: Scalars['ID'];
  invoice: Invoice;
  user?: Maybe<User>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  colony?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  interiorNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** Attributes for creating or updating an address */
export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  interiorNumber?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
};

export type Bank = {
  __typename?: 'Bank';
  clabe: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  supported: Scalars['Boolean'];
};

/** The connection type for Bank. */
export type BankConnection = {
  __typename?: 'BankConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BankEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Bank>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BankEdge = {
  __typename?: 'BankEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Bank>;
};

export type BankingAccount = {
  __typename?: 'BankingAccount';
  accountType: BankingAccountAccountTypeEnum;
  bank: BankingBank;
  clabe: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  taxId: Scalars['String'];
};

export enum BankingAccountAccountTypeEnum {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export type BankingBank = {
  __typename?: 'BankingBank';
  clabe: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  supported: Scalars['Boolean'];
};

export type BankingInfo = {
  __typename?: 'BankingInfo';
  bankId: Scalars['ID'];
  bankLogoUrl?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  clabe: Scalars['String'];
  id: Scalars['ID'];
  recipient: Scalars['String'];
  validationStatus?: Maybe<BankingInfoValidationStatusEnum>;
};

/** Attributes used for creating the banking info of a business */
export type BankingInfoInput = {
  bankId: Scalars['ID'];
  clabe: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  recipient?: InputMaybe<Scalars['String']>;
};

export type BankingInfoValidation = {
  __typename?: 'BankingInfoValidation';
  bankingInfo?: Maybe<BankingInfo>;
  clabe: Scalars['String'];
  id: Scalars['ID'];
  validationStatus: BankingInfoValidationStatusEnum;
};

export enum BankingInfoValidationStatusEnum {
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  VALIDATED = 'VALIDATED',
}

export type BankingTransaction = {
  __typename?: 'BankingTransaction';
  amountCents: Scalars['Int'];
  amountCurrency: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  hashId: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  invoicePayments: Array<InvoicePayment>;
  metadata?: Maybe<Scalars['JSON']>;
  paymentConcept?: Maybe<Scalars['String']>;
  receiverAccount: BankingAccount;
  senderAccount: BankingAccount;
  status: Scalars['String'];
  trackingKey?: Maybe<Scalars['String']>;
};

/** Attributes for adding an invoice payment */
export type BankingTransactionInvoicePaymentInput = {
  amountCents: Scalars['BigAmount'];
  bankingTransactionId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

/** Attributes for updating an invoice in bulk */
export type BulkInvoiceInput = {
  costCenterId?: InputMaybe<Scalars['ID']>;
  expiryDate: Scalars['DateTime'];
  id: Scalars['ID'];
  paymentTerms: Scalars['Int'];
  status: InvoiceApprovalStatusEnum;
};

export type Business = {
  __typename?: 'Business';
  accountPayable?: Maybe<AccountPayable>;
  actsAs: BusinessActsAsEnum;
  bankingInfoValidation?: Maybe<BankingInfoValidation>;
  bankingInfos?: Maybe<Array<BankingInfo>>;
  businessFeatures: Array<BusinessFeature>;
  businessGroups: BusinessGroupConnection;
  creditAccount?: Maybe<CreditAccount>;
  defaultBankingInfo?: Maybe<BankingInfo>;
  hasActiveProviders: Scalars['Boolean'];
  hasRelationship?: Maybe<Scalars['Boolean']>;
  hasTaxPayerInfo: Scalars['Boolean'];
  hashId: Scalars['ID'];
  id: Scalars['ID'];
  imageColorCode?: Maybe<Scalars['String']>;
  imageShortName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  integrations: Array<Integration>;
  invitation?: Maybe<Invitation>;
  mailboxEmail?: Maybe<Scalars['String']>;
  multipleTaxPayerInfos: Scalars['Boolean'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  setting: BusinessSetting;
  taxPayerInfos?: Maybe<Array<TaxPayerInfo>>;
  verified: Scalars['Boolean'];
  wallet?: Maybe<Wallet>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type BusinessBusinessGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type BusinessHasRelationshipArgs = {
  partnerBusinessId: Scalars['ID'];
};

export enum BusinessActsAsEnum {
  BOTH = 'BOTH',
  CLIENT = 'CLIENT',
  PROVIDER = 'PROVIDER',
}

/** The connection type for Business. */
export type BusinessConnection = {
  __typename?: 'BusinessConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BusinessEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Business>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** The business user or contact */
export type BusinessContact = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type BusinessEdge = {
  __typename?: 'BusinessEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Business>;
};

export type BusinessFeature = {
  __typename?: 'BusinessFeature';
  feature: Feature;
};

export type BusinessGroup = {
  __typename?: 'BusinessGroup';
  business: Business;
  group: Group;
  id: Scalars['ID'];
};

/** The connection type for BusinessGroup. */
export type BusinessGroupConnection = {
  __typename?: 'BusinessGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BusinessGroupEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BusinessGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BusinessGroupEdge = {
  __typename?: 'BusinessGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessGroup>;
};

/** Attributes for adding a business feature group */
export type BusinessGroupInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  groupId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};

/** Attributes used for updating the business on account creation */
export type BusinessInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type BusinessProfile = {
  __typename?: 'BusinessProfile';
  about?: Maybe<Scalars['String']>;
  activeClients: Scalars['Int'];
  bankingInfos?: Maybe<Array<BankingInfo>>;
  businessHashId: Scalars['ID'];
  businessId: Scalars['ID'];
  completedOrders: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultBankingInfo?: Maybe<BankingInfo>;
  defaultPriceList: PriceList;
  defaultTaxPayerInfo?: Maybe<TaxPayerInfo>;
  deliveryLeadTimeInDays: Scalars['Int'];
  deliveryOnSaturday: Scalars['Boolean'];
  deliveryOnSunday: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  hasPriceLists: Scalars['Boolean'];
  hasRelationship?: Maybe<Scalars['Boolean']>;
  hashId: Scalars['ID'];
  id: Scalars['ID'];
  imageColorCode?: Maybe<Scalars['String']>;
  imageShortName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  shippingCostCurrency: Scalars['String'];
  shippingCostValue: Scalars['Float'];
  users: Scalars['Boolean'];
  verified: Scalars['Boolean'];
  websiteUrl?: Maybe<Scalars['String']>;
};

export type BusinessProfileHasRelationshipArgs = {
  partnerBusinessId: Scalars['ID'];
};

/** The connection type for BusinessProfile. */
export type BusinessProfileConnection = {
  __typename?: 'BusinessProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BusinessProfileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BusinessProfile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BusinessProfileEdge = {
  __typename?: 'BusinessProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessProfile>;
};

export type BusinessRelationship = {
  __typename?: 'BusinessRelationship';
  active: Scalars['Boolean'];
  bankingInfo?: Maybe<BankingInfo>;
  client: BusinessProfile;
  clientContact?: Maybe<BusinessContact>;
  clientId: Scalars['String'];
  clientName: Scalars['String'];
  deliveryLeadTimeInDays: Scalars['Int'];
  deliveryOnSaturday: Scalars['Boolean'];
  deliveryOnSunday: Scalars['Boolean'];
  id: Scalars['ID'];
  invoicesCount: Scalars['Int'];
  last30DaysOrdersTotals: Array<CurrencyTotal>;
  lastOrderDate?: Maybe<Scalars['DateTime']>;
  nextShippingDate: Scalars['DateTime'];
  paymentTerms?: Maybe<Scalars['Int']>;
  pendingExpiredTotal: Scalars['String'];
  pendingTotal: Scalars['String'];
  pendingTotalCredit: Scalars['String'];
  priceList?: Maybe<PriceList>;
  priceListItemsCount?: Maybe<Scalars['Int']>;
  provider: BusinessProfile;
  providerContact?: Maybe<BusinessContact>;
  providerContacts: Array<ProviderContact>;
  providerHashId?: Maybe<Scalars['String']>;
  providerId: Scalars['String'];
  providerImageColorCode: Scalars['String'];
  providerImageShortName: Scalars['String'];
  providerImageUrl?: Maybe<Scalars['String']>;
  providerName: Scalars['String'];
  shippingCostCurrency: Scalars['String'];
  shippingCostType: ShippingCostTypeEnum;
  shippingCostValue: Scalars['Float'];
  status: BusinessRelationshipStatusEnum;
  supplier: BusinessProfile;
  totalAcceptedOrders: Scalars['Int'];
};

/** The connection type for BusinessRelationship. */
export type BusinessRelationshipConnection = {
  __typename?: 'BusinessRelationshipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BusinessRelationshipEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BusinessRelationship>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BusinessRelationshipEdge = {
  __typename?: 'BusinessRelationshipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessRelationship>;
};

/** Attributes used for activating business relationships */
export type BusinessRelationshipInput = {
  bankingInfo?: InputMaybe<BankingInfoInput>;
  id: Scalars['ID'];
  paymentTerms?: InputMaybe<Scalars['Int']>;
  providerContact?: InputMaybe<ProviderContactInput>;
};

export enum BusinessRelationshipStatusEnum {
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
}

export enum BusinessRelationshipsSortableField {
  CREATED_AT = 'CREATED_AT',
  INVOICES_COUNT = 'INVOICES_COUNT',
}

export type BusinessRelationshipsSortingParams = {
  direction: SortingDirectionEnum;
  field: BusinessRelationshipsSortableField;
};

export type BusinessSetting = {
  __typename?: 'BusinessSetting';
  currencyCode: Scalars['String'];
  id: Scalars['ID'];
};

export type BusinessUser = BusinessContact & {
  __typename?: 'BusinessUser';
  business?: Maybe<Business>;
  email?: Maybe<Scalars['String']>;
  features: Array<Feature>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobPosition?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  permissions: Array<Permission>;
  phone?: Maybe<Scalars['String']>;
  profileImage: Scalars['String'];
  role: UserRoleEnum;
  status: BusinessUserStatusEnum;
  userId?: Maybe<Scalars['ID']>;
};

/** Attributes used for creating a user when creating an account */
export type BusinessUserAccountInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: LocalesEnum;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

/** The connection type for BusinessUser. */
export type BusinessUserConnection = {
  __typename?: 'BusinessUserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BusinessUserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BusinessUser>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BusinessUserEdge = {
  __typename?: 'BusinessUserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BusinessUser>;
};

/** Attributes for updating sales order line item */
export type BusinessUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  jobPosition?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type BusinessUserInvitation = {
  __typename?: 'BusinessUserInvitation';
  businessUser: BusinessUser;
  inviteeEmail: Scalars['String'];
  inviterUser: User;
  nextStep: Scalars['String'];
  token: Scalars['String'];
};

export enum BusinessUserStatusEnum {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export type BusinessUserValidation = {
  __typename?: 'BusinessUserValidation';
  business: Business;
  email: Scalars['String'];
  id: Scalars['ID'];
  status: BusinessUserValidationStatusEnum;
};

export enum BusinessUserValidationStatusEnum {
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  VALIDATED = 'VALIDATED',
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

export type CheckoutOrder = {
  __typename?: 'CheckoutOrder';
  currency: Scalars['String'];
  id: Scalars['ID'];
  integrationResponse?: Maybe<IntegrationResponseType>;
  nextDeliveryDate?: Maybe<Scalars['DateTime']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  paymentTerms?: Maybe<Scalars['Int']>;
  sellerBusiness: Business;
  sellerContact?: Maybe<BusinessContact>;
  shippingCost: Scalars['Int'];
  shippingCostCurrency?: Maybe<Scalars['String']>;
  shippingCostType?: Maybe<ShippingCostTypeEnum>;
  shippingCostValue?: Maybe<Scalars['Float']>;
  shoppingCartItems: Array<ShoppingCartItem>;
  taxTotal: Scalars['Int'];
  totalPrice: Scalars['Int'];
};

/** Attributes for creating or updating a purchase order */
export type CheckoutOrderInput = {
  deliveryDate: Scalars['DateTime'];
  note?: InputMaybe<Scalars['String']>;
  paymentTerms: Scalars['String'];
  sellerBusinessId: Scalars['ID'];
  shippingLocationId: Scalars['ID'];
  shoppingCartItems: Array<ShoppingCartItemInput>;
  taxPayerInfoId?: InputMaybe<Scalars['ID']>;
};

/** The connection type for PriceListItem. */
export type ClientPriceListItemsConnection = {
  __typename?: 'ClientPriceListItemsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientPriceListItemsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PriceListItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ClientPriceListItemsEdge = {
  __typename?: 'ClientPriceListItemsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PriceListItem>;
};

export type CodatCompany = {
  __typename?: 'CodatCompany';
  businessId: Scalars['String'];
  codatDataSources?: Maybe<Array<CodatDataSource>>;
  codatId: Scalars['String'];
  codatLinkUrl: Scalars['String'];
  id: Scalars['ID'];
};

export type CodatDataSource = {
  __typename?: 'CodatDataSource';
  codatConnectionId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  platformName: Scalars['String'];
};

export type CommentEvent = {
  __typename?: 'CommentEvent';
  businessUsers?: Maybe<Array<BusinessUser>>;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Contact = BusinessContact & {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type CostCenter = {
  __typename?: 'CostCenter';
  business: Business;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for CostCenter. */
export type CostCenterConnection = {
  __typename?: 'CostCenterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CostCenterEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CostCenter>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CostCenterEdge = {
  __typename?: 'CostCenterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CostCenter>;
};

export type CostCenterEvent = {
  __typename?: 'CostCenterEvent';
  costCenter: CostCenter;
  id: Scalars['ID'];
};

export type Country = {
  __typename?: 'Country';
  currencyCode: Scalars['String'];
  currencySymbol: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for Country. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Country>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

/** Extra fields specific to each country */
export type CountryTaxPayerInfo = MexicanTaxPayerInfo;

/** Autogenerated return type of CreateBankingTransactionInvoicePaymentsMutation */
export type CreateBankingTransactionInvoicePaymentsMutationPayload = {
  __typename?: 'CreateBankingTransactionInvoicePaymentsMutationPayload';
  invoicePayments: Array<InvoicePayment>;
};

/** Autogenerated return type of CreateBusinessMutation */
export type CreateBusinessMutationPayload = {
  __typename?: 'CreateBusinessMutationPayload';
  business: Business;
};

/** Attributes for creating a business relationship */
export type CreateBusinessRelationshipInput = {
  deliveryLeadTimeInDays: Scalars['Int'];
  deliveryOnSaturday: Scalars['Boolean'];
  deliveryOnSunday: Scalars['Boolean'];
  paymentTerms: Scalars['Int'];
  shippingCostType: ShippingCostTypeEnum;
  shippingCostValue: Scalars['Float'];
};

/** Autogenerated return type of CreateFinancingRequestRepaymentMutation */
export type CreateFinancingRequestRepaymentMutationPayload = {
  __typename?: 'CreateFinancingRequestRepaymentMutationPayload';
  financingRequestRepayments: Array<FinancingRequestRepayment>;
};

/** Autogenerated return type of CreatePaymentInstructionDraftMutation */
export type CreatePaymentInstructionDraftMutationPayload = {
  __typename?: 'CreatePaymentInstructionDraftMutationPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of CreatePaymentInstructionMutation */
export type CreatePaymentInstructionMutationPayload = {
  __typename?: 'CreatePaymentInstructionMutationPayload';
  success: Scalars['Boolean'];
};

/** Attributes for creating or updating a purchase order */
export type CreatePurchaseOrderInput = {
  lineItems: Array<PurchaseLineItemInput>;
  note?: InputMaybe<Scalars['String']>;
  paymentTerms: Scalars['String'];
  sellerBusinessId: Scalars['ID'];
  sellerContactId?: InputMaybe<Scalars['ID']>;
  sellerEmail: Scalars['String'];
  shippingDate: Scalars['DateTime'];
  shippingLocationId: Scalars['ID'];
  taxPayerInfoId?: InputMaybe<Scalars['ID']>;
};

/** Attributes for creating a sales order */
export type CreateSalesOrderInput = {
  buyerBusinessId: Scalars['ID'];
  buyerContactId?: InputMaybe<Scalars['ID']>;
  buyerEmail: Scalars['String'];
  lineItems: Array<SalesLineItemInput>;
  note?: InputMaybe<Scalars['String']>;
  paymentTerms: Scalars['String'];
  sellerTaxPayerInfoId?: InputMaybe<Scalars['ID']>;
  shippingCostType: ShippingCostTypeEnum;
  shippingCostValue: Scalars['Float'];
  shippingDate: Scalars['DateTime'];
  shippingLocationId: Scalars['ID'];
  taxPayerInfoId?: InputMaybe<Scalars['ID']>;
};

export type CreditAccount = {
  __typename?: 'CreditAccount';
  activationRequestedAt?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  annualInterestRate: Scalars['Float'];
  annualMoratoryRate: Scalars['Float'];
  contractSignedAt?: Maybe<Scalars['DateTime']>;
  creditLimitCents: Scalars['Int'];
  creditTermsJson?: Maybe<Scalars['JSON']>;
  financingRequests: Array<FinancingRequest>;
  id: Scalars['ID'];
  ivaTax: Scalars['Float'];
  nextFinancingRequest?: Maybe<FinancingRequest>;
  overdueAmountCents: Scalars['BigAmount'];
  owedAmountCents: Scalars['BigAmount'];
  owedInterestCents: Scalars['BigAmount'];
  owedTotal: Scalars['BigAmount'];
};

/** Supported currency codes */
export enum CurrencyCodesEnum {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
}

export type CurrencyTotal = {
  __typename?: 'CurrencyTotal';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type DepositEntry = {
  __typename?: 'DepositEntry';
  id: Scalars['ID'];
  senderBank: Scalars['String'];
  senderName: Scalars['String'];
  senderNumberAccount: Scalars['String'];
  trackingKey: Scalars['String'];
};

export type Entryable =
  | BankingTransaction
  | DepositEntry
  | HigoPaymentReceivedEntry
  | HigoPaymentSentEntry
  | InvoicePaymentEntry
  | PennyValidationEntry
  | RefundEntry
  | WithdrawalEntry;

export type Eventable =
  | CommentEvent
  | CostCenterEvent
  | InvoiceCreditNotePaymentEvent
  | InvoiceManualPaymentEvent
  | InvoiceReceivedEvent
  | InvoiceStpPaymentEvent
  | InvoiceStatusEvent;

export type FactoringOffer = {
  __typename?: 'FactoringOffer';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  business: Business;
  cashableAmountCents: Scalars['BigAmount'];
  discountAmountCents: Scalars['BigAmount'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  interestRate: Scalars['Float'];
  invoice: Invoice;
  ivaTax: Scalars['Float'];
  offeredAt: Scalars['DateTime'];
  paymentTerms: Scalars['Int'];
  percentCashable: Scalars['Float'];
  repaidAt?: Maybe<Scalars['DateTime']>;
  reserveAmountCents: Scalars['BigAmount'];
};

export enum FactoringOfferStatusEnum {
  ACCEPTED = 'ACCEPTED',
  AVAILABLE = 'AVAILABLE',
  COMPLETED = 'COMPLETED',
}

export type Feature = {
  __typename?: 'Feature';
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  name: FeatureNameEnum;
};

export enum FeatureNameEnum {
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  AUTOMATIC_FINANCING_FUND = 'AUTOMATIC_FINANCING_FUND',
  CLIENT_LIST = 'CLIENT_LIST',
  CODAT_INTEGRATIONS = 'CODAT_INTEGRATIONS',
  FACTORING = 'FACTORING',
  FINANCING_REPAYMENTS = 'FINANCING_REPAYMENTS',
  GASTOWN = 'GASTOWN',
  HIGO_PAYMENTS = 'HIGO_PAYMENTS',
  INVOICES_BUYER = 'INVOICES_BUYER',
  INVOICES_PROVIDER = 'INVOICES_PROVIDER',
  MANUAL_PURCHASE_ORDER = 'MANUAL_PURCHASE_ORDER',
  MANUAL_SALES_ORDER = 'MANUAL_SALES_ORDER',
  PAYMENTS_APPROVAL = 'PAYMENTS_APPROVAL',
  PRICE_LISTS = 'PRICE_LISTS',
  PROCUREMENT = 'PROCUREMENT',
  PURCHASE_CATALOGS = 'PURCHASE_CATALOGS',
  PURCHASE_LISTS = 'PURCHASE_LISTS',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  SALES = 'SALES',
  SALES_ORDER = 'SALES_ORDER',
  SETTINGS = 'SETTINGS',
  SHARED_PRODUCTS = 'SHARED_PRODUCTS',
  SUPPLIERS_LIST = 'SUPPLIERS_LIST',
}

export type FinancingRequest = {
  __typename?: 'FinancingRequest';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  amountCents: Scalars['BigAmount'];
  annualInterestRate: Scalars['Float'];
  annualMoratoryRate: Scalars['Float'];
  creditDays?: Maybe<Scalars['Int']>;
  creditInterestRate?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  interestAmountCents: Scalars['BigAmount'];
  invoices: Array<Invoice>;
  ivaInterestAmountCents: Scalars['BigAmount'];
  moratoryInterestAmountCents: Scalars['BigAmount'];
  overdue: Scalars['Boolean'];
  owedAmountCents: Scalars['BigAmount'];
  owedTotalCents: Scalars['BigAmount'];
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentStatus: FinancingRequestPaymentStatusEnum;
  rejectedAt?: Maybe<Scalars['DateTime']>;
  repaidAmountCents: Scalars['BigAmount'];
  repaidAt?: Maybe<Scalars['DateTime']>;
  repaidInterestCents: Scalars['BigAmount'];
  repaymentWalletEntries: Array<WalletEntry>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  totalInterestAmountCents: Scalars['BigAmount'];
};

/** The connection type for FinancingRequest. */
export type FinancingRequestConnection = {
  __typename?: 'FinancingRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FinancingRequestEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FinancingRequest>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FinancingRequestEdge = {
  __typename?: 'FinancingRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FinancingRequest>;
};

export type FinancingRequestInvoice = {
  __typename?: 'FinancingRequestInvoice';
  amountCents: Scalars['Int'];
  bankingInfoId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentConcept?: Maybe<Scalars['String']>;
};

export type FinancingRequestInvoiceInputType = {
  amountCents: Scalars['Int'];
  bankingInfoId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  paymentConcept?: InputMaybe<Scalars['String']>;
};

export enum FinancingRequestPaymentStatusEnum {
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
}

export type FinancingRequestRepayment = {
  __typename?: 'FinancingRequestRepayment';
  financingRequest: FinancingRequest;
  id: Scalars['ID'];
};

export type FinancingRequestRepaymentInput = {
  amountCents: Scalars['Int'];
  financingRequestId: Scalars['ID'];
  interestAmountCents?: InputMaybe<Scalars['Int']>;
};

export enum FinancingRequestsSortableFieldEnum {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
}

export type FinancingRequestsSortingParams = {
  direction?: InputMaybe<SortingDirectionEnum>;
  field?: InputMaybe<FinancingRequestsSortableFieldEnum>;
};

export type Group = {
  __typename?: 'Group';
  features: Array<Feature>;
  id: Scalars['ID'];
  name: GroupNameEnum;
};

export enum GroupNameEnum {
  INVOICES_BUYER = 'INVOICES_BUYER',
  INVOICES_PROVIDER = 'INVOICES_PROVIDER',
  PROCUREMENT = 'PROCUREMENT',
  SALES = 'SALES',
}

export type HigoPay = {
  __typename?: 'HigoPay';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  paymentConcept: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type HigoPaymentReceivedEntry = {
  __typename?: 'HigoPaymentReceivedEntry';
  financingRequestRepayment?: Maybe<FinancingRequestRepayment>;
  id: Scalars['ID'];
  invoicePayment?: Maybe<InvoicePayment>;
  paymentConcept?: Maybe<Scalars['String']>;
};

export type HigoPaymentSentEntry = {
  __typename?: 'HigoPaymentSentEntry';
  financingRequestRepayment?: Maybe<FinancingRequestRepayment>;
  id: Scalars['ID'];
  invoicePayment?: Maybe<InvoicePayment>;
  lastBusinessUserValidation?: Maybe<BusinessUserValidation>;
  paymentConcept?: Maybe<Scalars['String']>;
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IntegrationResponseType = {
  __typename?: 'IntegrationResponseType';
  buyerFormPostUrl?: Maybe<Scalars['String']>;
  cxml?: Maybe<Scalars['String']>;
  /** @deprecated No longer encoded, use cxml field */
  encodedCxml?: Maybe<Scalars['String']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['ID'];
  invitedFor?: Maybe<InvitationInvitedForEnum>;
  inviteeBusiness: Business;
  inviteeEmail: Scalars['String'];
  inviterBusiness: Business;
  message?: Maybe<Scalars['String']>;
  nextStep: Scalars['String'];
  order?: Maybe<Order>;
  status?: Maybe<InvitationStatusEnum>;
  token: Scalars['String'];
};

export enum InvitationInvitedForEnum {
  CREATE_PRICE_LIST = 'CREATE_PRICE_LIST',
  VIEW_ORDER = 'VIEW_ORDER',
  VIEW_PRICE_LIST = 'VIEW_PRICE_LIST',
  VIEW_SALES_ORDER = 'VIEW_SALES_ORDER',
}

export enum InvitationStatusEnum {
  CANCELED = 'CANCELED',
  NOT_USED = 'NOT_USED',
  USED = 'USED',
}

/** Attributes for creating a user upon sending an HigoPayment */
export type InvitedUserInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  accountPayable?: Maybe<AccountPayable>;
  appliedCredit: Scalars['BigAmount'];
  /** Applied credit to invoice from a credit note */
  appliedCreditByCreditNote: Scalars['BigAmount'];
  approvalStatus: InvoiceApprovalStatusEnum;
  archived: Scalars['Boolean'];
  bankingInfo: BankingInfo;
  businessEmitter?: Maybe<Business>;
  businessEmitterEmail?: Maybe<Scalars['String']>;
  businessReceiver: Business;
  businessRelationship?: Maybe<BusinessRelationship>;
  cancelationReason?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenter>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creditNotes?: Maybe<Array<Invoice>>;
  currency: Scalars['String'];
  emissionStatus: InvoiceEmissionStatusEnum;
  emitterTaxpayerIdentifier?: Maybe<Scalars['String']>;
  emitterTaxpayerName?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  factoringOffer?: Maybe<FactoringOffer>;
  financed: Scalars['Boolean'];
  financingRequest?: Maybe<FinancingRequest>;
  fiscalIdentifier?: Maybe<Scalars['String']>;
  foreignTotal?: Maybe<Scalars['Int']>;
  globalStatus: InvoiceGlobalStatusEnum;
  hasXmlFile: Scalars['Boolean'];
  id: Scalars['ID'];
  iepsAmount: Scalars['BigAmount'];
  iepsRetainedAmount: Scalars['BigAmount'];
  invoiceLineItems: Array<InvoiceLineItem>;
  invoiceUrl?: Maybe<Scalars['String']>;
  invoiceables?: Maybe<Array<Invoiceable>>;
  invoices?: Maybe<Array<Invoice>>;
  invoicingDate?: Maybe<Scalars['DateTime']>;
  isComplete: Scalars['Boolean'];
  isrAmount: Scalars['BigAmount'];
  mailboxStatus?: Maybe<InvoiceMailboxStatusEnum>;
  matchedAt?: Maybe<Scalars['DateTime']>;
  outstandingBalance?: Maybe<Scalars['BigAmount']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentComplements: Array<PaymentComplement>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentStatus: InvoicePaymentStatusEnum;
  paymentTerms?: Maybe<Scalars['Int']>;
  pendingCredit: Scalars['BigAmount'];
  receiverCountryTaxPayerInfo?: Maybe<CountryTaxPayerInfo>;
  receiverTaxpayerAddress?: Maybe<Address>;
  receiverTaxpayerIdentifier?: Maybe<Scalars['String']>;
  receiverTaxpayerName?: Maybe<Scalars['String']>;
  satVerified: Scalars['Boolean'];
  serialNumber: Scalars['String'];
  subtotal: Scalars['BigAmount'];
  total: Scalars['BigAmount'];
  valueAddedRetainedTaxAmount: Scalars['BigAmount'];
  valueAddedTaxAmount: Scalars['BigAmount'];
  voucherType: InvoiceVoucherTypeEnum;
};

export type InvoiceAppliedCreditByCreditNoteArgs = {
  creditNoteId: Scalars['ID'];
};

export enum InvoiceApprovalStatusEnum {
  APPROVED = 'APPROVED',
  EDITED = 'EDITED',
  FINANCE_REQUESTED = 'FINANCE_REQUESTED',
  PENDING = 'PENDING',
  PENDING_REVISION = 'PENDING_REVISION',
  REJECTED = 'REJECTED',
}

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  pendingApproval: Scalars['String'];
  pendingExpiredTotal: Scalars['String'];
  pendingTotal: Scalars['String'];
  pendingTotalCredit: Scalars['String'];
  totalCount: Scalars['Int'];
};

export type InvoiceCreditNotePaymentEvent = {
  __typename?: 'InvoiceCreditNotePaymentEvent';
  amount?: Maybe<Scalars['BigAmount']>;
  id: Scalars['ID'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

export enum InvoiceEmissionStatusEnum {
  CANCELED = 'CANCELED',
  EMISSION_REQUESTED = 'EMISSION_REQUESTED',
  EMITTABLE = 'EMITTABLE',
  EMITTED = 'EMITTED',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
}

export enum InvoiceGlobalStatusEnum {
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
  FINANCING_REQUESTED = 'FINANCING_REQUESTED',
  PAID = 'PAID',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  REJECTED = 'REJECTED',
}

/** Attributes for adding an invoice Higo payment */
export type InvoiceHigoPaymentInput = {
  amount: Scalars['Int'];
  invoiceId: Scalars['ID'];
  paymentConcept?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InvitedUserInput>>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  iepsAmount: Scalars['Int'];
  iepsBase: Scalars['Int'];
  iepsRetainedAmount: Scalars['Int'];
  iepsRetainedBase: Scalars['Int'];
  iepsRetainedTaxPercent: Scalars['Float'];
  iepsTaxPercent: Scalars['Float'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pricePerUnit: Scalars['String'];
  quantity: Scalars['String'];
  total: Scalars['BigAmount'];
  unit?: Maybe<Scalars['String']>;
  valueAddedRetainedTaxAmount: Scalars['Int'];
  valueAddedRetainedTaxBase: Scalars['Int'];
  valueAddedRetainedTaxPercent: Scalars['Float'];
  valueAddedTaxAmount: Scalars['Int'];
  valueAddedTaxBase: Scalars['Int'];
  valueAddedTaxPercent: Scalars['Float'];
  valueDiscountAmount: Scalars['Float'];
};

export enum InvoiceMailboxStatusEnum {
  PENDING_REVISION = 'PENDING_REVISION',
  RECEIVED = 'RECEIVED',
}

export type InvoiceManualPaymentEvent = {
  __typename?: 'InvoiceManualPaymentEvent';
  amount?: Maybe<Scalars['BigAmount']>;
  id: Scalars['ID'];
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  amount: Scalars['Int'];
  amountCents: Scalars['BigAmount'];
  bankingTransactionId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  creditNoteId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  invoice: Invoice;
  invoiceId: Scalars['ID'];
  paymentProof?: Maybe<Scalars['String']>;
  paymentType: InvoicePaymentTypeEnum;
  receiverWalletEntryHashId?: Maybe<Scalars['ID']>;
  receiverWalletEntryId?: Maybe<Scalars['ID']>;
  status: PaymentStatusEnum;
  walletEntryHashId?: Maybe<Scalars['ID']>;
  walletEntryId?: Maybe<Scalars['ID']>;
};

export type InvoicePaymentEntry = {
  __typename?: 'InvoicePaymentEntry';
  id: Scalars['ID'];
  invoicePayment: InvoicePayment;
  stpPayment: StpPayment;
  trackingKey?: Maybe<Scalars['String']>;
};

/** Attributes for adding an invoice payment */
export type InvoicePaymentInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['BigAmount']>;
  creditNoteId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  paymentProof?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<InvoicePaymentTypeEnum>;
};

export enum InvoicePaymentStatusEnum {
  CLOSED = 'CLOSED',
  PAID = 'PAID',
  PARTIAL = 'PARTIAL',
  PENDING = 'PENDING',
}

export enum InvoicePaymentTypeEnum {
  BANKING_TRANSACTION = 'BANKING_TRANSACTION',
  CREDIT_NOTE = 'CREDIT_NOTE',
  HIGO_PAYMENT = 'HIGO_PAYMENT',
  MANUAL = 'MANUAL',
  PAYMENT_COMPLEMENT = 'PAYMENT_COMPLEMENT',
  STP_PAYMENT = 'STP_PAYMENT',
}

/** Attributes for a invoice payment with banking info request */
export type InvoicePaymentsWithBankingInfoRequestInput = {
  amount: Scalars['Int'];
  emails?: InputMaybe<Array<Scalars['String']>>;
  invoiceId: Scalars['ID'];
  paymentConcept?: InputMaybe<Scalars['String']>;
};

export type InvoiceReceivedEvent = {
  __typename?: 'InvoiceReceivedEvent';
  id: Scalars['ID'];
  supplierEmail: Scalars['String'];
};

export type InvoiceStpPaymentEvent = {
  __typename?: 'InvoiceSTPPaymentEvent';
  id: Scalars['ID'];
  walletEntry: WalletEntry;
};

export type InvoiceStatusCount = {
  __typename?: 'InvoiceStatusCount';
  duePaymentInvoicesCount: Scalars['Int'];
  paidInvoicesCount: Scalars['Int'];
};

export type InvoiceStatusEvent = {
  __typename?: 'InvoiceStatusEvent';
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paymentTerms?: Maybe<Scalars['Int']>;
  status: InvoiceApprovalStatusEnum;
};

/** Attributes for adding an invoice STP payment */
export type InvoiceStpPaymentInput = {
  amount: Scalars['Int'];
  invoiceId: Scalars['ID'];
  paymentConcept?: InputMaybe<Scalars['String']>;
  providerContacts?: InputMaybe<Array<ProviderContactInput>>;
};

export enum InvoiceVoucherTypeEnum {
  CREDIT_NOTE = 'CREDIT_NOTE',
  INVOICE = 'INVOICE',
  PAYMENT_COMPLEMENT = 'PAYMENT_COMPLEMENT',
  PAYROLL = 'PAYROLL',
  TRANSLATION = 'TRANSLATION',
}

export type Invoiceable = Order | SubscriptionStatement;

export enum InvoicesSortableFieldEnum {
  CREATED_AT = 'CREATED_AT',
  EXPIRY_DATE = 'EXPIRY_DATE',
  ID = 'ID',
  INVOICING_DATE = 'INVOICING_DATE',
  PAYMENT_DATE = 'PAYMENT_DATE',
}

export type InvoicesSortingParams = {
  direction?: InputMaybe<SortingDirectionEnum>;
  field?: InputMaybe<InvoicesSortableFieldEnum>;
};

export type Itemable = PriceListItem | SupplierProduct;

export type JsonWebToken = {
  __typename?: 'JsonWebToken';
  token: Scalars['String'];
};

export type LineItem = {
  __typename?: 'LineItem';
  availableQuantity: Scalars['Int'];
  buyerSku?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  orderableType?: Maybe<LineItemOrderableTypeEnum>;
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  pricePerUnitWithoutVat: Scalars['Int'];
  quantity: Scalars['Int'];
  requestedQuantity: Scalars['Int'];
  sellerSku?: Maybe<Scalars['String']>;
  status: LineItemStatusEnum;
  title?: Maybe<Scalars['String']>;
  unit: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  valueAddedTaxPercent: Scalars['Float'];
  versions?: Maybe<Array<LineItem>>;
};

export enum LineItemOrderableTypeEnum {
  PRODUCT = 'PRODUCT',
  PRODUCT_SIMPLE = 'PRODUCT_SIMPLE',
}

export enum LineItemStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  WITH_ISSUES = 'WITH_ISSUES',
}

/** Supported locales */
export enum LocalesEnum {
  AF = 'af',
  AF_ZA = 'af_ZA',
  AR = 'ar',
  AR_AE = 'ar_AE',
  AR_BH = 'ar_BH',
  AR_DZ = 'ar_DZ',
  AR_EG = 'ar_EG',
  AR_IQ = 'ar_IQ',
  AR_JO = 'ar_JO',
  AR_KW = 'ar_KW',
  AR_LB = 'ar_LB',
  AR_LY = 'ar_LY',
  AR_MA = 'ar_MA',
  AR_OM = 'ar_OM',
  AR_QA = 'ar_QA',
  AR_SA = 'ar_SA',
  AR_SY = 'ar_SY',
  AR_TN = 'ar_TN',
  AR_YE = 'ar_YE',
  AZ = 'az',
  AZ_AZ_CYRL = 'az_AZ_Cyrl',
  AZ_AZ_LATN = 'az_AZ_Latn',
  BE = 'be',
  BE_BY = 'be_BY',
  BG = 'bg',
  BG_BG = 'bg_BG',
  CA = 'ca',
  CA_ES = 'ca_ES',
  CS = 'cs',
  CS_CZ = 'cs_CZ',
  DA = 'da',
  DA_DK = 'da_DK',
  DE = 'de',
  DE_AT = 'de_AT',
  DE_CH = 'de_CH',
  DE_DE = 'de_DE',
  DE_LI = 'de_LI',
  DE_LU = 'de_LU',
  DIV = 'div',
  DIV_MV = 'div_MV',
  EL = 'el',
  EL_GR = 'el_GR',
  EN = 'en',
  EN_AU = 'en_AU',
  EN_BZ = 'en_BZ',
  EN_CA = 'en_CA',
  EN_CB = 'en_CB',
  EN_GB = 'en_GB',
  EN_IE = 'en_IE',
  EN_JM = 'en_JM',
  EN_NZ = 'en_NZ',
  EN_PH = 'en_PH',
  EN_TT = 'en_TT',
  EN_US = 'en_US',
  EN_ZA = 'en_ZA',
  EN_ZW = 'en_ZW',
  ES = 'es',
  ES_AR = 'es_AR',
  ES_BO = 'es_BO',
  ES_CL = 'es_CL',
  ES_CO = 'es_CO',
  ES_CR = 'es_CR',
  ES_DO = 'es_DO',
  ES_EC = 'es_EC',
  ES_ES = 'es_ES',
  ES_GT = 'es_GT',
  ES_HN = 'es_HN',
  ES_MX = 'es_MX',
  ES_NI = 'es_NI',
  ES_PA = 'es_PA',
  ES_PE = 'es_PE',
  ES_PR = 'es_PR',
  ES_PY = 'es_PY',
  ES_SV = 'es_SV',
  ES_UY = 'es_UY',
  ES_VE = 'es_VE',
  ET = 'et',
  ET_EE = 'et_EE',
  EU = 'eu',
  EU_ES = 'eu_ES',
  FA = 'fa',
  FA_IR = 'fa_IR',
  FI = 'fi',
  FI_FI = 'fi_FI',
  FO = 'fo',
  FO_FO = 'fo_FO',
  FR = 'fr',
  FR_BE = 'fr_BE',
  FR_CA = 'fr_CA',
  FR_CH = 'fr_CH',
  FR_FR = 'fr_FR',
  FR_LU = 'fr_LU',
  FR_MC = 'fr_MC',
  GL = 'gl',
  GL_ES = 'gl_ES',
  GU = 'gu',
  GU_IN = 'gu_IN',
  HE = 'he',
  HE_IL = 'he_IL',
  HI = 'hi',
  HI_IN = 'hi_IN',
  HR = 'hr',
  HR_HR = 'hr_HR',
  HU = 'hu',
  HU_HU = 'hu_HU',
  HY = 'hy',
  HY_AM = 'hy_AM',
  ID = 'id',
  ID_ID = 'id_ID',
  IS = 'is',
  IS_IS = 'is_IS',
  IT = 'it',
  IT_CH = 'it_CH',
  IT_IT = 'it_IT',
  JA = 'ja',
  JA_JP = 'ja_JP',
  KA = 'ka',
  KA_GE = 'ka_GE',
  KK = 'kk',
  KK_KZ = 'kk_KZ',
  KN = 'kn',
  KN_IN = 'kn_IN',
  KO = 'ko',
  KO_KR = 'ko_KR',
  KOK = 'kok',
  KOK_IN = 'kok_IN',
  KY = 'ky',
  KY_KG = 'ky_KG',
  LT = 'lt',
  LT_LT = 'lt_LT',
  LV = 'lv',
  LV_LV = 'lv_LV',
  MK = 'mk',
  MK_MK = 'mk_MK',
  MN = 'mn',
  MN_MN = 'mn_MN',
  MR = 'mr',
  MR_IN = 'mr_IN',
  MS = 'ms',
  MS_BN = 'ms_BN',
  MS_MY = 'ms_MY',
  NB_NO = 'nb_NO',
  NL = 'nl',
  NL_BE = 'nl_BE',
  NL_NL = 'nl_NL',
  NN_NO = 'nn_NO',
  NO = 'no',
  PA = 'pa',
  PA_IN = 'pa_IN',
  PL = 'pl',
  PL_PL = 'pl_PL',
  PT = 'pt',
  PT_BR = 'pt_BR',
  PT_PT = 'pt_PT',
  RO = 'ro',
  RO_RO = 'ro_RO',
  RU = 'ru',
  RU_RU = 'ru_RU',
  SA = 'sa',
  SA_IN = 'sa_IN',
  SK = 'sk',
  SK_SK = 'sk_SK',
  SL = 'sl',
  SL_SI = 'sl_SI',
  SQ = 'sq',
  SQ_AL = 'sq_AL',
  SR_SP_CYRL = 'sr_SP_Cyrl',
  SR_SP_LATN = 'sr_SP_Latn',
  SV = 'sv',
  SV_FI = 'sv_FI',
  SV_SE = 'sv_SE',
  SW = 'sw',
  SW_KE = 'sw_KE',
  SYR = 'syr',
  SYR_SY = 'syr_SY',
  TA = 'ta',
  TA_IN = 'ta_IN',
  TE = 'te',
  TE_IN = 'te_IN',
  TH = 'th',
  TH_TH = 'th_TH',
  TR = 'tr',
  TR_TR = 'tr_TR',
  TT = 'tt',
  TT_RU = 'tt_RU',
  UK = 'uk',
  UK_UA = 'uk_UA',
  UR = 'ur',
  UR_PK = 'ur_PK',
  UZ = 'uz',
  UZ_UZ_CYRL = 'uz_UZ_Cyrl',
  UZ_UZ_LATN = 'uz_UZ_Latn',
  VI = 'vi',
  ZH_CHS = 'zh_CHS',
  ZH_CHT = 'zh_CHT',
  ZH_CN = 'zh_CN',
  ZH_HK = 'zh_HK',
  ZH_MO = 'zh_MO',
  ZH_SG = 'zh_SG',
  ZH_TW = 'zh_TW',
}

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: LocationStatusEnum;
};

/** The connection type for Location. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Location>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Location>;
};

/** Attributes for creating or updating a location */
export type LocationInput = {
  address: AddressInput;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export enum LocationStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type MexicanTaxPayerInfo = {
  __typename?: 'MexicanTaxPayerInfo';
  cfdiDigitalStamp?: Maybe<Scalars['String']>;
  cfdiOriginalChain?: Maybe<Scalars['String']>;
  cfdiUsage: SatcfdiUsageEnum;
  fiscalUuid?: Maybe<Scalars['String']>;
  folio?: Maybe<Scalars['String']>;
  paymentForm: SatPaymentFormEnum;
  paymentMethod: SatPaymentMethodEnum;
  regime?: Maybe<Scalars['String']>;
  satStamp?: Maybe<Scalars['String']>;
  serie?: Maybe<Scalars['String']>;
  voucherType: SatVoucherTypeEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptBusinessUserInvitation: Business;
  acceptFactoringOffers: Array<FactoringOffer>;
  acceptRelationship: Invitation;
  activateBusinessRelationships: Array<BusinessRelationship>;
  addPurchaseListItems: PurchaseList;
  approveInvoicesFromActiveProviders: Business;
  archiveInvoices: Array<Invoice>;
  checkout: Order;
  createAccount: JsonWebToken;
  createBankingInfoValidationWithBankingInfoRequest: BankingInfo;
  createBankingTransactionInvoicePayments: CreateBankingTransactionInvoicePaymentsMutationPayload;
  createBusiness: CreateBusinessMutationPayload;
  createBusinessClient: BusinessRelationship;
  createBusinessProvider: BusinessRelationship;
  createBusinessTaxPayerInfo: TaxPayerInfo;
  createBusinessUser: BusinessUser;
  createBusinessUserAccount: JsonWebToken;
  createCategory: Category;
  createCodatCompany: CodatCompany;
  createCodatDataSource: CodatDataSource;
  createCostCenter: CostCenter;
  createFinancingRequest: HigoPay;
  createFinancingRequestRepayment: CreateFinancingRequestRepaymentMutationPayload;
  createInvoiceComment: AccountPayableEvent;
  createInvoicePaymentsWithBankingInfoRequest: Array<Invoice>;
  createInvoicesHigoPayments: Array<Invoice>;
  createInvoicesStpPayments: Array<Invoice>;
  createLocation: Location;
  createMailboxBusinessSupplier: BusinessRelationship;
  createPartnerLocation: Location;
  createPartnerTaxPayerInfo: TaxPayerInfo;
  createPasswordReset: User;
  createPaymentInstruction: CreatePaymentInstructionMutationPayload;
  createPaymentInstructionDraft: CreatePaymentInstructionDraftMutationPayload;
  createPayments: Array<BankingTransaction>;
  createPriceList: PriceList;
  createProviderContact: ProviderContact;
  createPurchaseList: PurchaseList;
  createPurchaseOrder: Order;
  createSalesOrder: Order;
  createTaxPayerInfo: TaxPayerInfo;
  createUser: JsonWebToken;
  createWithdrawalWalletEntry: WalletEntry;
  deactivateBusinessRelationship: BusinessRelationship;
  deleteBusinessRelationship: BusinessRelationship;
  deleteCodatDataSource: CodatDataSource;
  deletePurchaseList: PurchaseList;
  deletePurchaseListItems: PurchaseList;
  exportInvoices: Array<Invoice>;
  exportPurchaseOrders: Array<Order>;
  exportSalesOrders: Array<Order>;
  exportTransactions: Array<WalletEntry>;
  exportTransactionsPdf: Array<WalletEntry>;
  fundFinancingRequest: HigoPay;
  initializeInvoicesSync: TaxPayerInfo;
  /** @deprecated Avoid using this mutation,use inviteVerifiedBusinessSupplier instead */
  inviteVerifiedBusinessProvider: BusinessProfile;
  inviteVerifiedBusinessSupplier: BusinessRelationship;
  invoiceOrder: Invoice;
  payInvoices: Array<Invoice>;
  rejectPaymentInstruction: RejectPaymentInstructionMutationPayload;
  remindInvitation: Invitation;
  removeBusinessUser: BusinessUser;
  requestInvoiceFinancing: Array<FinancingRequestInvoice>;
  resendHigoPaymentNotification: ResendHigoPaymentNotificationMutationPayload;
  startBankingInfoValidation: BankingInfo;
  startBusinessUserValidation: Business;
  syncInvoicesWithSat: Business;
  updateBulkInvoiceStatus: Array<Invoice>;
  updateBusiness: Business;
  updateBusinessAsTaxPayer: Business;
  updateBusinessFeatureGroups: BusinessUser;
  updateBusinessRelationshipPriceList: PriceList;
  updateBusinessUser: BusinessUser;
  updateClientBusinessRelationship: BusinessRelationship;
  updateInvoiceCostCenter: Invoice;
  updateInvoiceCreditNotes: Array<InvoicePayment>;
  updateInvoiceEmail: Business;
  updateInvoiceMailboxStatus: Invoice;
  updateInvoicePaymentStatus: Invoice;
  updateInvoicePayments: Array<InvoicePayment>;
  updateInvoiceStatus: Invoice;
  updateLineItemsStatus: Order;
  updateLocation: Location;
  updateLocationStatus: Location;
  updateOrder: Order;
  updateOrderStatus: Order;
  updatePassword: User;
  updatePaymentInstruction: PaymentInstruction;
  updatePriceList: PriceList;
  updatePriceListItems: PriceList;
  updateProviderBusinessRelationship: BusinessRelationship;
  updateProviderContact: ProviderContact;
  updatePurchaseList: PurchaseList;
  updatePurchaseListItem: PurchaseList;
  updatePurchaseOrder: Order;
  updateSalesOrder: Order;
  updateShoppingCartItems: ShoppingCart;
  updateSupplier: Business;
  updateSupplierBankingInfo: BankingInfo;
  updateSupplierProducts: Array<SupplierProduct>;
  updateTaxPayerInfo: TaxPayerInfo;
  updateTaxPayerInfoStatus: TaxPayerInfo;
  updateUserPassword: User;
  updateUserProfile: User;
  uploadInvoice: Invoice;
  useBusinessUserValidationToken: User;
};

export type MutationAcceptBusinessUserInvitationArgs = {
  invitationToken: Scalars['String'];
};

export type MutationAcceptFactoringOffersArgs = {
  businessId: Scalars['ID'];
  factoringOffersIds: Array<Scalars['ID']>;
};

export type MutationAcceptRelationshipArgs = {
  businessId: Scalars['ID'];
  invitationToken: Scalars['String'];
};

export type MutationActivateBusinessRelationshipsArgs = {
  businessId: Scalars['ID'];
  businessRelationships: Array<BusinessRelationshipInput>;
};

export type MutationAddPurchaseListItemsArgs = {
  businessId: Scalars['ID'];
  purchaseListId: Scalars['ID'];
  purchaseListItems: Array<PurchaseListItemInput>;
};

export type MutationApproveInvoicesFromActiveProvidersArgs = {
  businessId: Scalars['ID'];
};

export type MutationArchiveInvoicesArgs = {
  archived: Scalars['Boolean'];
  businessId: Scalars['ID'];
  invoiceIds: Array<Scalars['ID']>;
};

export type MutationCheckoutArgs = {
  businessId: Scalars['ID'];
  checkoutOrderInput: CheckoutOrderInput;
};

export type MutationCreateAccountArgs = {
  account: AccountInput;
  invitationToken: Scalars['String'];
};

export type MutationCreateBankingInfoValidationWithBankingInfoRequestArgs = {
  bankingInfo: BankingInfoInput;
  token: Scalars['String'];
};

export type MutationCreateBankingTransactionInvoicePaymentsArgs = {
  businessId: Scalars['ID'];
  invoicePayments: Array<BankingTransactionInvoicePaymentInput>;
};

export type MutationCreateBusinessArgs = {
  business: BusinessInput;
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationCreateBusinessClientArgs = {
  businessId: Scalars['ID'];
  businessRelationshipInput?: InputMaybe<CreateBusinessRelationshipInput>;
  email?: InputMaybe<Scalars['String']>;
  invitedFor?: InputMaybe<InvitationInvitedForEnum>;
  name: Scalars['String'];
  priceListId?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateBusinessProviderArgs = {
  businessId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitedFor?: InputMaybe<InvitationInvitedForEnum>;
  lastName: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
};

export type MutationCreateBusinessTaxPayerInfoArgs = {
  businessId: Scalars['ID'];
  businessSrcImageUrl?: InputMaybe<Scalars['String']>;
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationCreateBusinessUserArgs = {
  businessId: Scalars['ID'];
  businessUser: BusinessUserInput;
};

export type MutationCreateBusinessUserAccountArgs = {
  businessUserAccount: BusinessUserAccountInput;
  invitationToken: Scalars['String'];
};

export type MutationCreateCategoryArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationCreateCodatCompanyArgs = {
  businessId: Scalars['ID'];
};

export type MutationCreateCodatDataSourceArgs = {
  businessId: Scalars['ID'];
  codatConnectionId: Scalars['String'];
  codatId: Scalars['String'];
  platformName: Scalars['String'];
};

export type MutationCreateCostCenterArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationCreateFinancingRequestArgs = {
  amountCents: Scalars['Int'];
  businessId: Scalars['ID'];
  creditDays: Scalars['Int'];
};

export type MutationCreateFinancingRequestRepaymentArgs = {
  businessId: Scalars['ID'];
  financingRequestRepayment: FinancingRequestRepaymentInput;
};

export type MutationCreateInvoiceCommentArgs = {
  businessId: Scalars['ID'];
  comment: Scalars['String'];
  invoiceId: Scalars['ID'];
  taggedUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationCreateInvoicePaymentsWithBankingInfoRequestArgs = {
  businessId: Scalars['ID'];
  invoicePayments: Array<InvoicePaymentsWithBankingInfoRequestInput>;
};

export type MutationCreateInvoicesHigoPaymentsArgs = {
  businessId: Scalars['ID'];
  invoiceHigoPayments: Array<InvoiceHigoPaymentInput>;
};

export type MutationCreateInvoicesStpPaymentsArgs = {
  businessId: Scalars['ID'];
  invoiceStpPayments: Array<InvoiceStpPaymentInput>;
};

export type MutationCreateLocationArgs = {
  businessId: Scalars['ID'];
  location: LocationInput;
};

export type MutationCreateMailboxBusinessSupplierArgs = {
  bankingInfo?: InputMaybe<BankingInfoInput>;
  businessId: Scalars['ID'];
  email: Scalars['String'];
  invoiceId: Scalars['ID'];
  paymentTerms?: InputMaybe<Scalars['Int']>;
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationCreatePartnerLocationArgs = {
  businessId: Scalars['ID'];
  location: LocationInput;
  partnerId: Scalars['ID'];
};

export type MutationCreatePartnerTaxPayerInfoArgs = {
  businessId: Scalars['ID'];
  partnerId: Scalars['ID'];
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationCreatePasswordResetArgs = {
  email: Scalars['String'];
};

export type MutationCreatePaymentInstructionArgs = {
  businessId: Scalars['ID'];
  paymentInstructionInvoices: Array<PaymentInstructionInvoicesInput>;
};

export type MutationCreatePaymentInstructionDraftArgs = {
  businessId: Scalars['ID'];
  paymentInstructionInvoices: Array<PaymentInstructionInvoicesInput>;
};

export type MutationCreatePaymentsArgs = {
  businessId: Scalars['ID'];
  payments: Array<PaymentsWithBusinessRelationshipInput>;
};

export type MutationCreatePriceListArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
  taxPayerInfoId: Scalars['ID'];
};

export type MutationCreateProviderContactArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
  providerContact: ProviderContactInput;
};

export type MutationCreatePurchaseListArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationCreatePurchaseOrderArgs = {
  businessId: Scalars['ID'];
  purchaseOrderInput: CreatePurchaseOrderInput;
};

export type MutationCreateSalesOrderArgs = {
  businessId: Scalars['ID'];
  salesOrderInput: CreateSalesOrderInput;
};

export type MutationCreateTaxPayerInfoArgs = {
  businessId: Scalars['ID'];
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationCreateUserArgs = {
  fromHigoPay?: InputMaybe<Scalars['Boolean']>;
  user: UserInput;
};

export type MutationCreateWithdrawalWalletEntryArgs = {
  amount: Scalars['Int'];
  businessId: Scalars['ID'];
  paymentConcept?: InputMaybe<Scalars['String']>;
};

export type MutationDeactivateBusinessRelationshipArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
};

export type MutationDeleteBusinessRelationshipArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
};

export type MutationDeleteCodatDataSourceArgs = {
  businessId: Scalars['ID'];
  codatDataSourceId: Scalars['ID'];
};

export type MutationDeletePurchaseListArgs = {
  businessId: Scalars['ID'];
  purchaseListId: Scalars['ID'];
};

export type MutationDeletePurchaseListItemsArgs = {
  businessId: Scalars['ID'];
  purchaseListId: Scalars['ID'];
  purchaseListItems: Array<PurchaseListItemInput>;
};

export type MutationExportInvoicesArgs = {
  approvalStatus?: InputMaybe<Array<InvoiceApprovalStatusEnum>>;
  archived?: InputMaybe<Scalars['Boolean']>;
  businessEmitterId?: InputMaybe<Array<Scalars['ID']>>;
  businessId: Scalars['ID'];
  emitterTaxpayerIdentifier?: InputMaybe<Array<Scalars['String']>>;
  expiryDateFrom?: InputMaybe<Scalars['DateTime']>;
  expiryDateTo?: InputMaybe<Scalars['DateTime']>;
  fiscalIdentifier?: InputMaybe<Scalars['String']>;
  globalStatus?: InputMaybe<Array<InvoiceGlobalStatusEnum>>;
  invoiceId?: InputMaybe<Array<Scalars['ID']>>;
  invoiceIds?: InputMaybe<Array<Scalars['ID']>>;
  invoicingDateFrom?: InputMaybe<Scalars['DateTime']>;
  invoicingDateTo?: InputMaybe<Scalars['DateTime']>;
  mailboxStatus?: InputMaybe<Array<InvoiceMailboxStatusEnum>>;
  matchedAtFrom?: InputMaybe<Scalars['DateTime']>;
  matchedAtTo?: InputMaybe<Scalars['DateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  minAmount?: InputMaybe<Scalars['Int']>;
  paymentDateFrom?: InputMaybe<Scalars['DateTime']>;
  paymentDateTo?: InputMaybe<Scalars['DateTime']>;
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatusEnum>>;
  receiverTaxpayerIdentifier?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InvoicesSortingParams>;
  voucherType?: InputMaybe<Array<InvoiceVoucherTypeEnum>>;
};

export type MutationExportPurchaseOrdersArgs = {
  businessId: Scalars['ID'];
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  orderIds?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  sellerBusinessId?: InputMaybe<Array<Scalars['ID']>>;
  shippingDateFrom?: InputMaybe<Scalars['DateTime']>;
  shippingDateTo?: InputMaybe<Scalars['DateTime']>;
  shippingLocationId?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<OrdersSortingParams>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
  taxPayerInfoId?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationExportSalesOrdersArgs = {
  businessId: Scalars['ID'];
  buyerBusinessId?: InputMaybe<Array<Scalars['ID']>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  orderIds?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  sellerTaxPayerInfoId?: InputMaybe<Array<Scalars['ID']>>;
  shippingDateFrom?: InputMaybe<Scalars['DateTime']>;
  shippingDateTo?: InputMaybe<Scalars['DateTime']>;
  sortBy?: InputMaybe<OrdersSortingParams>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type MutationExportTransactionsArgs = {
  businessId: Scalars['ID'];
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<WalletEntrySortingParams>;
  status?: InputMaybe<Array<WalletEntryStatusEnum>>;
  walletEntriesIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationExportTransactionsPdfArgs = {
  businessId: Scalars['ID'];
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<WalletEntrySortingParams>;
  status?: InputMaybe<Array<WalletEntryStatusEnum>>;
  walletEntriesIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationFundFinancingRequestArgs = {
  amountInCents: Scalars['Int'];
  taxpayerIdentifier: Scalars['String'];
};

export type MutationInitializeInvoicesSyncArgs = {
  businessId: Scalars['ID'];
  taxPayerInfoId: Scalars['ID'];
  taxPayerInfoPassword: Scalars['String'];
};

export type MutationInviteVerifiedBusinessProviderArgs = {
  businessId: Scalars['ID'];
  providerBusinessId: Scalars['ID'];
};

export type MutationInviteVerifiedBusinessSupplierArgs = {
  businessId: Scalars['ID'];
  verifiedSupplierId: Scalars['ID'];
};

export type MutationInvoiceOrderArgs = {
  businessId: Scalars['ID'];
  hashId: Scalars['String'];
};

export type MutationPayInvoicesArgs = {
  businessId: Scalars['ID'];
  invoiceIds: Array<Scalars['ID']>;
};

export type MutationRejectPaymentInstructionArgs = {
  businessId: Scalars['ID'];
  paymentInstructionId: Scalars['ID'];
};

export type MutationRemindInvitationArgs = {
  businessId: Scalars['ID'];
  invitationToken: Scalars['String'];
};

export type MutationRemoveBusinessUserArgs = {
  businessId: Scalars['ID'];
  businessUserId: Scalars['ID'];
  recepientUserId: Scalars['ID'];
};

export type MutationRequestInvoiceFinancingArgs = {
  businessId: Scalars['ID'];
  creditDays: Scalars['Int'];
  invoicesPaymentsDetails: Array<FinancingRequestInvoiceInputType>;
};

export type MutationResendHigoPaymentNotificationArgs = {
  businessId: Scalars['ID'];
  higoPaymentSentEntryId: Scalars['ID'];
  user: InvitedUserInput;
};

export type MutationStartBankingInfoValidationArgs = {
  bankingInfo: BankingInfoInput;
  businessId: Scalars['ID'];
};

export type MutationStartBusinessUserValidationArgs = {
  bankingInfo: BankingInfoInput;
};

export type MutationSyncInvoicesWithSatArgs = {
  businessId: Scalars['ID'];
  taxPayerInfoId: Scalars['ID'];
};

export type MutationUpdateBulkInvoiceStatusArgs = {
  businessId: Scalars['ID'];
  invoices: Array<BulkInvoiceInput>;
};

export type MutationUpdateBusinessArgs = {
  business: UpdateBusinessInput;
  businessId: Scalars['ID'];
};

export type MutationUpdateBusinessAsTaxPayerArgs = {
  businessId: Scalars['ID'];
  businessSrcImageUrl?: InputMaybe<Scalars['String']>;
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationUpdateBusinessFeatureGroupsArgs = {
  businessGroups: Array<BusinessGroupInput>;
  businessId: Scalars['ID'];
};

export type MutationUpdateBusinessRelationshipPriceListArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
  editable?: InputMaybe<Scalars['Boolean']>;
  priceListItems: Array<UpdateBusinessRelationshipPriceListItemInput>;
};

export type MutationUpdateBusinessUserArgs = {
  businessId: Scalars['ID'];
  businessUser: UpdateBusinessUserInput;
};

export type MutationUpdateClientBusinessRelationshipArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
  businessRelationshipInput?: InputMaybe<UpdateBusinessRelationshipInput>;
  invitedFor?: InputMaybe<InvitationInvitedForEnum>;
  name?: InputMaybe<Scalars['String']>;
  priceListId?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateInvoiceCostCenterArgs = {
  businessId: Scalars['ID'];
  costCenterId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type MutationUpdateInvoiceCreditNotesArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  invoicePayments: Array<InvoicePaymentInput>;
};

export type MutationUpdateInvoiceEmailArgs = {
  businessId: Scalars['ID'];
  mailboxEmail: Scalars['String'];
};

export type MutationUpdateInvoiceMailboxStatusArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  mailboxStatus: InvoiceMailboxStatusEnum;
};

export type MutationUpdateInvoicePaymentStatusArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  paymentDate?: InputMaybe<Scalars['DateTime']>;
  status: InvoicePaymentStatusEnum;
};

export type MutationUpdateInvoicePaymentsArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
  invoicePayments: Array<InvoicePaymentInput>;
  providerContacts?: InputMaybe<Array<ProviderContactInput>>;
};

export type MutationUpdateInvoiceStatusArgs = {
  businessId: Scalars['ID'];
  cancelationReason?: InputMaybe<Scalars['String']>;
  costCenterId?: InputMaybe<Scalars['ID']>;
  edited?: InputMaybe<Scalars['Boolean']>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  invoiceId: Scalars['ID'];
  paymentTerms?: InputMaybe<Scalars['Int']>;
  providerContacts?: InputMaybe<Array<ProviderContactInput>>;
  status: InvoiceApprovalStatusEnum;
  supplierEmail?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateLineItemsStatusArgs = {
  businessId: Scalars['ID'];
  hashId: Scalars['ID'];
  lineItemsIds: Array<Scalars['ID']>;
  status: LineItemStatusEnum;
};

export type MutationUpdateLocationArgs = {
  businessId: Scalars['ID'];
  location: LocationInput;
};

export type MutationUpdateLocationStatusArgs = {
  businessId: Scalars['ID'];
  locationId: Scalars['ID'];
  status: LocationStatusEnum;
};

export type MutationUpdateOrderArgs = {
  hashId: Scalars['String'];
  status: OrderStatusEnum;
};

export type MutationUpdateOrderStatusArgs = {
  businessId: Scalars['ID'];
  hashId: Scalars['ID'];
  status: OrderStatusEnum;
};

export type MutationUpdatePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationUpdatePaymentInstructionArgs = {
  businessId: Scalars['ID'];
  paymentInstructionId: Scalars['ID'];
  paymentInstructionInvoices: Array<UpdatePaymentInstructionInvoicesInput>;
  request?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdatePriceListArgs = {
  businessId: Scalars['ID'];
  priceListId: Scalars['ID'];
  priceListInput: UpdatePriceListInput;
};

export type MutationUpdatePriceListItemsArgs = {
  businessId: Scalars['ID'];
  priceListId: Scalars['ID'];
  priceListItems: Array<UpdatePriceListItemInput>;
};

export type MutationUpdateProviderBusinessRelationshipArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
  providerBusinessName?: InputMaybe<Scalars['String']>;
  providerContactInput?: InputMaybe<UpdateProviderContactInput>;
};

export type MutationUpdateProviderContactArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
  providerContact: UpdateProviderContactInput;
  providerContactId: Scalars['ID'];
};

export type MutationUpdatePurchaseListArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
  purchaseListId: Scalars['ID'];
};

export type MutationUpdatePurchaseListItemArgs = {
  businessId: Scalars['ID'];
  purchaseListItem: PurchaseListItemInput;
};

export type MutationUpdatePurchaseOrderArgs = {
  businessId: Scalars['ID'];
  purchaseOrderInput: UpdatePurchaseOrderInput;
};

export type MutationUpdateSalesOrderArgs = {
  businessId: Scalars['ID'];
  salesOrderInput: UpdateSalesOrderInput;
};

export type MutationUpdateShoppingCartItemsArgs = {
  businessId: Scalars['ID'];
  shoppingCartItems: Array<ShoppingCartItemInput>;
};

export type MutationUpdateSupplierArgs = {
  bankingInfo?: InputMaybe<BankingInfoInput>;
  businessId: Scalars['ID'];
  businessRelationship?: InputMaybe<UpdateBusinessRelationshipInput>;
  businessRelationshipId: Scalars['ID'];
  businessSrcImageUrl?: InputMaybe<Scalars['String']>;
  providerContact?: InputMaybe<UpdateProviderContactInput>;
  supplierBusinessName?: InputMaybe<Scalars['String']>;
  taxPayerInfo?: InputMaybe<TaxPayerInfoInput>;
};

export type MutationUpdateSupplierBankingInfoArgs = {
  bankingInfo: BankingInfoInput;
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
};

export type MutationUpdateSupplierProductsArgs = {
  businessId: Scalars['ID'];
  supplierProducts: Array<SupplierProductInput>;
};

export type MutationUpdateTaxPayerInfoArgs = {
  businessId: Scalars['ID'];
  taxPayerInfo: TaxPayerInfoInput;
};

export type MutationUpdateTaxPayerInfoStatusArgs = {
  businessId: Scalars['ID'];
  status: TaxPayerInfoStatusEnum;
  taxPayerInfoId: Scalars['ID'];
};

export type MutationUpdateUserPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type MutationUpdateUserProfileArgs = {
  user: UpdateUserInput;
};

export type MutationUploadInvoiceArgs = {
  businessId: Scalars['ID'];
  orderHashId: Scalars['ID'];
  pdfFileUrl: Scalars['String'];
  xmlFileUrl: Scalars['String'];
};

export type MutationUseBusinessUserValidationTokenArgs = {
  token: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  buyerBusiness: Business;
  buyerContact?: Maybe<BusinessContact>;
  buyerEmail?: Maybe<Scalars['String']>;
  canBeAccepted: Scalars['Boolean'];
  closedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  creatorBusiness: Business;
  currency: Scalars['String'];
  deliveryDate?: Maybe<Scalars['DateTime']>;
  hashId: Scalars['ID'];
  integrationResponse?: Maybe<IntegrationResponseType>;
  invitation?: Maybe<Invitation>;
  invoices?: Maybe<Array<Invoice>>;
  lineItems: Array<LineItem>;
  note?: Maybe<Scalars['String']>;
  paymentDueDate?: Maybe<Scalars['DateTime']>;
  paymentTerms?: Maybe<Scalars['String']>;
  publicAccessToken: Scalars['String'];
  sellerBusiness: Business;
  sellerContact?: Maybe<BusinessContact>;
  sellerEmail?: Maybe<Scalars['String']>;
  sellerTaxPayerInfo?: Maybe<TaxPayerInfo>;
  shippingCost: Scalars['Int'];
  shippingCostType?: Maybe<ShippingCostTypeEnum>;
  shippingCostValue?: Maybe<Scalars['Float']>;
  shippingDate?: Maybe<Scalars['DateTime']>;
  shippingLocation: Location;
  status: OrderStatusEnum;
  subtotal: Scalars['Int'];
  taxPayerInfo?: Maybe<TaxPayerInfo>;
  totalPrice: Scalars['Int'];
  /** @deprecated We are renaming all vat uses to to tax */
  totalVat: Scalars['Int'];
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Order>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  pendingAcceptanceCount: Scalars['Int'];
  pendingApprovalCount: Scalars['Int'];
  pendingDeliveryCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalValues: Array<CurrencyTotal>;
};

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Order>;
};

export enum OrderStatusEnum {
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
}

export enum OrdersSortableFieldEnum {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  SHIPPING_DATE = 'SHIPPING_DATE',
}

export type OrdersSortingParams = {
  direction?: InputMaybe<SortingDirectionEnum>;
  field?: InputMaybe<OrdersSortableFieldEnum>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentComplement = {
  __typename?: 'PaymentComplement';
  amount: Scalars['Int'];
  fiscalUuid: Scalars['String'];
  id: Scalars['ID'];
};

export type PaymentInstruction = {
  __typename?: 'PaymentInstruction';
  draftedBy?: Maybe<User>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paidBy?: Maybe<User>;
  paymentInstructionInvoices: Array<PaymentInstructionInvoice>;
  rejected?: Maybe<User>;
  requestedBy?: Maybe<User>;
  status?: Maybe<Scalars['String']>;
  totalAmountCents?: Maybe<Scalars['BigAmount']>;
  totalInvoices?: Maybe<Scalars['Int']>;
};

export type PaymentInstructionInvoice = {
  __typename?: 'PaymentInstructionInvoice';
  amountCents: Scalars['BigAmount'];
  clabe?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Invoice;
  paymentConcept?: Maybe<Scalars['String']>;
  paymentMethod: PaymentInstructionInvoicePaymentMethodEnum;
  providerEmails?: Maybe<Array<Scalars['String']>>;
  status: PaymentInstructionInvoiceStatusEnum;
};

export enum PaymentInstructionInvoicePaymentMethodEnum {
  HIGO_PAYMENT = 'HIGO_PAYMENT',
  REQUEST_BANKING_INFO = 'REQUEST_BANKING_INFO',
  STP_PAYMENT = 'STP_PAYMENT',
}

export enum PaymentInstructionInvoiceStatusEnum {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type PaymentInstructionInvoicesInput = {
  amountCents?: InputMaybe<Scalars['BigAmount']>;
  clabe?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  paymentConcept?: InputMaybe<Scalars['String']>;
  paymentMethod: PaymentInstructionInvoicePaymentMethodEnum;
  providerEmails?: InputMaybe<Array<Scalars['String']>>;
};

export enum PaymentStatusEnum {
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
}

/** Attributes for a payment with a business relationship request */
export type PaymentsWithBusinessRelationshipInput = {
  amountCents: Scalars['Int'];
  businessRelationshipId: Scalars['ID'];
  emails?: InputMaybe<Array<Scalars['String']>>;
  higoPay: Scalars['Boolean'];
  paymentConcept?: InputMaybe<Scalars['String']>;
};

export type PennyValidationEntry = {
  __typename?: 'PennyValidationEntry';
  id: Scalars['ID'];
  stpPayment: StpPayment;
  trackingKey?: Maybe<Scalars['String']>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: PermissionNamesEnum;
  taxPayerInfo?: Maybe<TaxPayerInfo>;
};

/** Attributes to update business user permissions */
export type PermissionInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  name: PermissionNamesEnum;
  subjectId?: InputMaybe<Scalars['ID']>;
};

export enum PermissionNamesEnum {
  MANAGE_ADDRESSES = 'MANAGE_ADDRESSES',
  MANAGE_CLIENTS = 'MANAGE_CLIENTS',
  MANAGE_INVOICES_EMITTED = 'MANAGE_INVOICES_EMITTED',
  MANAGE_INVOICES_RECEIVED = 'MANAGE_INVOICES_RECEIVED',
  MANAGE_PROVIDERS = 'MANAGE_PROVIDERS',
  MANAGE_PURCHASE_ORDERS = 'MANAGE_PURCHASE_ORDERS',
  MANAGE_SALES_ORDERS = 'MANAGE_SALES_ORDERS',
  READ_PURCHASE_ORDERS = 'READ_PURCHASE_ORDERS',
  READ_SALES_ORDERS = 'READ_SALES_ORDERS',
  READ_TAX_PAYER_INFO = 'READ_TAX_PAYER_INFO',
}

export type PriceList = {
  __typename?: 'PriceList';
  /** @deprecated Use sellerBusiness instead */
  business: Business;
  createdBy: PriceListCreatedByEnum;
  editable: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priceListItems: Array<PriceListItem>;
  sellerBusiness: Business;
  taxPayerInfo?: Maybe<TaxPayerInfo>;
  totalClients: Scalars['Int'];
  totalItems: Scalars['Int'];
};

/** The connection type for PriceList. */
export type PriceListConnection = {
  __typename?: 'PriceListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PriceListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PriceList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum PriceListCreatedByEnum {
  CLIENT = 'CLIENT',
  PROVIDER = 'PROVIDER',
}

/** An edge in a connection. */
export type PriceListEdge = {
  __typename?: 'PriceListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PriceList>;
};

export type PriceListItem = {
  __typename?: 'PriceListItem';
  /** @deprecated Use sellerBusiness instead */
  business: Business;
  currency: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  productSimple: ProductSimple;
  sellerBusiness: Business;
  taxPercent: Scalars['Float'];
  taxTotal: Scalars['Int'];
};

export enum PriceListItemSortableField {
  BUSINESS_NAME = 'BUSINESS_NAME',
  PRICE_PER_UNIT = 'PRICE_PER_UNIT',
}

export type PriceListItemSortingParams = {
  direction: SortingDirectionEnum;
  field: PriceListItemSortableField;
};

export type ProductSimple = {
  __typename?: 'ProductSimple';
  active: Scalars['Boolean'];
  business: Business;
  category?: Maybe<Category>;
  ean?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  srcImageUrl?: Maybe<Scalars['String']>;
  unit: Scalars['String'];
  unitOfMeasureCode?: Maybe<Scalars['String']>;
  unspscCode?: Maybe<Scalars['String']>;
};

/** Attributes for creating a ProductSimple */
export type ProductSimpleInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  sku?: InputMaybe<Scalars['String']>;
  srcImageUrl?: InputMaybe<Scalars['String']>;
  unit: Scalars['String'];
};

export type ProviderContact = {
  __typename?: 'ProviderContact';
  businessRelationship: BusinessRelationship;
  contactable: BusinessContact;
  defaultContact: Scalars['Boolean'];
  id: Scalars['ID'];
};

/** Attributes for creating a provider contact */
export type ProviderContactInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type PublicLineItem = {
  __typename?: 'PublicLineItem';
  currency: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  pricePerUnitWithoutVat: Scalars['Int'];
  quantity: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  unit: Scalars['String'];
  valueAddedTaxPercent: Scalars['Float'];
};

export type PublicTaxPayerInfoType = {
  __typename?: 'PublicTaxPayerInfoType';
  id: Scalars['ID'];
  taxpayerIdentifier: Scalars['String'];
  taxpayerName: Scalars['String'];
};

/** Attributes for creating a line item input type */
export type PurchaseLineItemInput = {
  buyerSku?: InputMaybe<Scalars['String']>;
  pricePerUnitWithoutVat?: InputMaybe<Scalars['Int']>;
  requestedQuantity: Scalars['Int'];
  sellerSku?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  valueAddedTaxPercent?: InputMaybe<Scalars['Float']>;
};

export type PurchaseList = {
  __typename?: 'PurchaseList';
  business: Business;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  purchaseListItems: Array<PurchaseListItem>;
  totalItems: Scalars['Int'];
  totalSellers: Scalars['Int'];
  totalValues: Array<CurrencyTotal>;
  user: User;
};

/** The connection type for PurchaseList. */
export type PurchaseListConnection = {
  __typename?: 'PurchaseListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PurchaseListEdge = {
  __typename?: 'PurchaseListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseList>;
};

export type PurchaseListItem = {
  __typename?: 'PurchaseListItem';
  currency: Scalars['String'];
  id: Scalars['ID'];
  itemable: Itemable;
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  quantity: Scalars['Int'];
  taxPercent: Scalars['Float'];
  taxTotal: Scalars['Int'];
  totalPrice: Scalars['Int'];
};

/** Attributes for creating/updating a purchase list item */
export type PurchaseListItemInput = {
  id?: InputMaybe<Scalars['ID']>;
  itemableId?: InputMaybe<Scalars['ID']>;
  itemableType?: InputMaybe<ShoppingCartItemableTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  accountPayableEvents: Array<AccountPayableEvent>;
  bankingInfoRequest: Banking_Info_Request;
  banks: BankConnection;
  businessRelationship: BusinessRelationship;
  businessRelationshipsAsClient: BusinessRelationshipConnection;
  businessUser: BusinessUser;
  businessUserInvitation: BusinessUserInvitation;
  businessUsers: BusinessUserConnection;
  categories: CategoryConnection;
  checkout: Array<CheckoutOrder>;
  clientPriceListItems: ClientPriceListItemsConnection;
  clientRelationships: BusinessRelationshipConnection;
  clients: BusinessConnection;
  codatCompany: CodatCompany;
  codatDataSource: CodatDataSource;
  costCenters: CostCenterConnection;
  countries: CountryConnection;
  creditAccount: CreditAccount;
  currentUser: User;
  factoringOffers: Array<FactoringOffer>;
  financingRequests: FinancingRequestConnection;
  groups: Array<Group>;
  higoPayTokenStatus: Higo_Pay_Token_Status;
  invitation: Invitation;
  invoice: Invoice;
  invoicePayments: Array<InvoicePayment>;
  invoiceStatusCount: InvoiceStatusCount;
  invoicesEmitted: InvoiceConnection;
  invoicesReceived: InvoiceConnection;
  locations: LocationConnection;
  order: Order;
  partnerContacts: Array<BusinessContact>;
  partnerLocations: LocationConnection;
  partnerTaxPayerInfos: TaxPayerInfoConnection;
  partnerUsers: BusinessUserConnection;
  passwordToken: User;
  paymentInstructions: Array<PaymentInstruction>;
  priceList: PriceList;
  priceLists: PriceListConnection;
  providerRelationships: BusinessRelationshipConnection;
  providers: BusinessConnection;
  purchaseList: PurchaseList;
  purchaseLists: PurchaseListConnection;
  purchaseOrder: Order;
  purchaseOrders: OrderConnection;
  receivedCreditNotes: InvoiceConnection;
  salesOrders: OrderConnection;
  shoppingCart: ShoppingCart;
  supplierProducts: SupplierProductConnection;
  supplierRelationship: BusinessRelationship;
  taxPayerInfos: TaxPayerInfoConnection;
  verifiedProviders: BusinessProfileConnection;
  verifiedSupplier: BusinessProfile;
  wallet: Wallet;
  walletEntries: WalletEntryConnection;
  walletEntry: WalletEntry;
};

export type QueryAccountPayableEventsArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type QueryBankingInfoRequestArgs = {
  token: Scalars['String'];
};

export type QueryBanksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessRelationshipArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
};

export type QueryBusinessRelationshipsAsClientArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<BusinessRelationshipsSortingParams>;
};

export type QueryBusinessUserArgs = {
  businessId: Scalars['ID'];
  businessUserId: Scalars['ID'];
};

export type QueryBusinessUserInvitationArgs = {
  token: Scalars['String'];
};

export type QueryBusinessUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<BusinessUserStatusEnum>>;
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCheckoutArgs = {
  businessId: Scalars['ID'];
};

export type QueryClientPriceListItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sellerBusinessId?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<PriceListItemSortingParams>;
};

export type QueryClientRelationshipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<BusinessRelationshipsSortingParams>;
  status?: InputMaybe<BusinessRelationshipStatusEnum>;
};

export type QueryClientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCodatCompanyArgs = {
  businessId: Scalars['ID'];
};

export type QueryCodatDataSourceArgs = {
  businessId: Scalars['ID'];
  codatConnectionId: Scalars['ID'];
};

export type QueryCostCentersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryCreditAccountArgs = {
  businessId: Scalars['ID'];
};

export type QueryFactoringOffersArgs = {
  businessId: Scalars['ID'];
  factoringOffersIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<FactoringOfferStatusEnum>;
};

export type QueryFinancingRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  creditAccountId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  repaid?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<FinancingRequestsSortingParams>;
};

export type QueryHigoPayTokenStatusArgs = {
  token: Scalars['String'];
};

export type QueryInvitationArgs = {
  token: Scalars['String'];
};

export type QueryInvoiceArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type QueryInvoicePaymentsArgs = {
  businessId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type QueryInvoiceStatusCountArgs = {
  businessId: Scalars['ID'];
};

export type QueryInvoicesEmittedArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalStatus?: InputMaybe<Array<InvoiceApprovalStatusEnum>>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  businessReceiverId?: InputMaybe<Array<Scalars['ID']>>;
  emissionStatus?: InputMaybe<Array<InvoiceEmissionStatusEnum>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  matchedAtFrom?: InputMaybe<Scalars['DateTime']>;
  matchedAtTo?: InputMaybe<Scalars['DateTime']>;
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatusEnum>>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InvoicesSortingParams>;
};

export type QueryInvoicesReceivedArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalStatus?: InputMaybe<Array<InvoiceApprovalStatusEnum>>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  businessEmitterId?: InputMaybe<Array<Scalars['ID']>>;
  businessId: Scalars['ID'];
  emitterTaxpayerIdentifier?: InputMaybe<Array<Scalars['String']>>;
  expiryDateFrom?: InputMaybe<Scalars['DateTime']>;
  expiryDateTo?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  fiscalIdentifier?: InputMaybe<Scalars['String']>;
  fromActiveProvidersOnly?: InputMaybe<Scalars['Boolean']>;
  globalStatus?: InputMaybe<Array<InvoiceGlobalStatusEnum>>;
  invoiceId?: InputMaybe<Array<Scalars['ID']>>;
  invoicingDateFrom?: InputMaybe<Scalars['DateTime']>;
  invoicingDateTo?: InputMaybe<Scalars['DateTime']>;
  last?: InputMaybe<Scalars['Int']>;
  mailboxStatus?: InputMaybe<Array<InvoiceMailboxStatusEnum>>;
  matchedAtFrom?: InputMaybe<Scalars['DateTime']>;
  matchedAtTo?: InputMaybe<Scalars['DateTime']>;
  maxAmount?: InputMaybe<Scalars['Int']>;
  minAmount?: InputMaybe<Scalars['Int']>;
  paymentDateFrom?: InputMaybe<Scalars['DateTime']>;
  paymentDateTo?: InputMaybe<Scalars['DateTime']>;
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatusEnum>>;
  receiverTaxpayerIdentifier?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<InvoicesSortingParams>;
  voucherType?: InputMaybe<Array<InvoiceVoucherTypeEnum>>;
};

export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryOrderArgs = {
  businessId: Scalars['ID'];
  orderHashId: Scalars['ID'];
};

export type QueryPartnerContactsArgs = {
  businessId: Scalars['ID'];
  businessRelationshipId: Scalars['ID'];
};

export type QueryPartnerLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['ID'];
};

export type QueryPartnerTaxPayerInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['ID'];
};

export type QueryPartnerUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['ID'];
};

export type QueryPasswordTokenArgs = {
  token: Scalars['String'];
};

export type QueryPaymentInstructionsArgs = {
  businessId: Scalars['ID'];
  status: Array<Scalars['String']>;
};

export type QueryPriceListArgs = {
  businessId: Scalars['ID'];
  priceListId: Scalars['ID'];
};

export type QueryPriceListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryProviderRelationshipsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<BusinessRelationshipsSortingParams>;
  status?: InputMaybe<BusinessRelationshipStatusEnum>;
};

export type QueryProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryPurchaseListArgs = {
  businessId: Scalars['ID'];
  purchaseListId: Scalars['ID'];
};

export type QueryPurchaseListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryPurchaseOrderArgs = {
  publicAccessToken: Scalars['String'];
};

export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sellerBusinessId?: InputMaybe<Array<Scalars['ID']>>;
  shippingDateFrom?: InputMaybe<Scalars['DateTime']>;
  shippingDateTo?: InputMaybe<Scalars['DateTime']>;
  shippingLocationId?: InputMaybe<Array<Scalars['ID']>>;
  sortBy?: InputMaybe<OrdersSortingParams>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
  taxPayerInfoId?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryReceivedCreditNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  approvalStatus?: InputMaybe<Array<InvoiceApprovalStatusEnum>>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  supplierHashId: Scalars['ID'];
  uncredited?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySalesOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  buyerBusinessId?: InputMaybe<Array<Scalars['ID']>>;
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sellerTaxPayerInfoId?: InputMaybe<Array<Scalars['ID']>>;
  shippingDateFrom?: InputMaybe<Scalars['DateTime']>;
  shippingDateTo?: InputMaybe<Scalars['DateTime']>;
  sortBy?: InputMaybe<OrdersSortingParams>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type QueryShoppingCartArgs = {
  businessId: Scalars['ID'];
};

export type QuerySupplierProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SupplierProductSortingParams>;
  supplierBusinessId?: InputMaybe<Array<Scalars['ID']>>;
  supplierHashId?: InputMaybe<Array<Scalars['ID']>>;
};

export type QuerySupplierRelationshipArgs = {
  businessId: Scalars['ID'];
  supplierHashId: Scalars['ID'];
};

export type QueryTaxPayerInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<TaxPayerInfosSortingParams>;
};

export type QueryVerifiedProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryVerifiedSupplierArgs = {
  businessId: Scalars['ID'];
  verifiedBusinessHashId: Scalars['ID'];
};

export type QueryWalletArgs = {
  businessId: Scalars['ID'];
};

export type QueryWalletEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessId: Scalars['ID'];
  createdAtFrom?: InputMaybe<Scalars['DateTime']>;
  createdAtTo?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  providerId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<WalletEntrySortingParams>;
  status?: InputMaybe<Array<WalletEntryStatusEnum>>;
};

export type QueryWalletEntryArgs = {
  businessId: Scalars['ID'];
  walletEntryHashId: Scalars['ID'];
};

export type RefundEntry = {
  __typename?: 'RefundEntry';
  id: Scalars['ID'];
  refundable: Refundable;
};

export type Refundable = BankingTransaction | InvoicePayment | StpPayment;

/** Autogenerated return type of RejectPaymentInstructionMutation */
export type RejectPaymentInstructionMutationPayload = {
  __typename?: 'RejectPaymentInstructionMutationPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResendHigoPaymentNotificationMutation */
export type ResendHigoPaymentNotificationMutationPayload = {
  __typename?: 'ResendHigoPaymentNotificationMutationPayload';
  token: Scalars['String'];
};

export enum SatcfdiUsageEnum {
  /** Adquisición de mercancías */
  ACQUISITION_OF_MERCHANDISE = 'acquisition_of_merchandise',
  /** Equipo de computo y accesorios */
  COMPUTATIONAL_EQUIPMENT = 'computational_equipment',
  /** Construcciones */
  CONSTRUCTIONS = 'constructions',
  /** Donativos. */
  DONATIONS = 'donations',
  /** Pagos por servicios educativos (colegiaturas) */
  EDUCATIONAL_PAYMENTS = 'educational_payments',
  /** Gastos en general */
  EXPENSES_IN_GENERAL = 'expenses_in_general',
  /** Gastos funerales. */
  FUNERAL_EXPENSES = 'funeral_expenses',
  /** Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación). */
  HIPOTECARY_INTERESTS = 'hipotecary_interests',
  /** Gastos de transportación escolar obligatoria. */
  MANDATORY_TRANSPORTATION_EXPENSES = 'mandatory_transportation_expenses',
  /** Gastos médicos por incapacidad o discapacidad */
  MEDICAL_EXPENSES = 'medical_expenses',
  /** Mobilario y equipo de oficina por inversiones */
  OFFICE_FURNITURE = 'office_furniture',
  /** Otra maquinaria y equipo */
  OTHER_MACHINERY = 'other_machinery',
  /** Comunicaciones telefónicas */
  PHONE_COMUNICATIONS = 'phone_comunications',
  /** Primas por seguros de gastos médicos. */
  PRIMALS = 'primals',
  /** Devoluciones, descuentos o bonificaciones */
  REIMBURSEMENTS_DISCOUNTS_BONIFICATIONS = 'reimbursements_discounts_bonifications',
  /** Aportaciones voluntarias al SAR. */
  SAR_GIVEAWAYS = 'sar_giveaways',
  /** Comunicaciones satelitales */
  SATELITE_COMUNICATIONS = 'satelite_comunications',
  /** Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones. */
  SAVING_DEPOSITS = 'saving_deposits',
  /** Por definir */
  TO_BE_DEFINED = 'to_be_defined',
  /** Dados, troqueles, moldes, matrices y herramental */
  TOOLS_MOLDS_ETC = 'tools_molds_etc',
  /** Equipo de transporte */
  TRANSPORTATION_EQUIPMENT = 'transportation_equipment',
}

export enum SatPaymentFormEnum {
  /** Pago inicial y parcialidades */
  INITIAL_OF_MULTIPLE_PAYMENTS = 'initial_of_multiple_payments',
  /** Pago en parcialidades o diferido */
  MULTIPLE_PAYMENTS = 'multiple_payments',
  /** Pago en una sola exhibición */
  SINGLE_PAYMENT = 'single_payment',
}

export enum SatPaymentMethodEnum {
  /** 30 | Aplicación de anticipos */
  ADVANCEMENT_APLICATION = 'advancement_aplication',
  /** 03 | Transferencia electrónica de fondos */
  BANK_TRANSFER = 'bank_transfer',
  /** 01 | Efectivo */
  CASH = 'cash',
  /** 17 | Compoensación */
  COMPENSATION = 'compensation',
  /** 15 | Condonación */
  CONDONATION = 'condonation',
  /** 24 | Confusión */
  CONFUSION = 'confusion',
  /** 04 | Tarjeta de crédito */
  CREDIT_CARD = 'credit_card',
  /** 28 | Tarjeta de débito */
  DEBIT_CARD = 'debit_card',
  /** 25 | Remisión de deuda */
  DEBT_REMISION = 'debt_remision',
  /** 06 | Dinero electrónico */
  ELECTRONIC_MONEY = 'electronic_money',
  /** 05 | Monedero electrónico */
  ELECTRONIC_WALLET = 'electronic_wallet',
  /** 31 | Intermediarios */
  INTERMEDIARIES = 'intermediaries',
  /** 02 | Cheque nominativo */
  NOMINATIVE_CHECK = 'nominative_check',
  /** 23 | Novación */
  NOVATION = 'novation',
  /** 99 | Otros */
  OTHER = 'other',
  /** 08 | Vales de despensa */
  PANTRY_VOUCHERS = 'pantry_vouchers',
  /** 14 | Pago por consignación */
  PAYMENT_BY_CONSIGNATION = 'payment_by_consignation',
  /** 13 | Pago por subrogación */
  PAYMENT_BY_SUBROGATION = 'payment_by_subrogation',
  /** 26 | Prescripción o caducidad */
  PRESCRIPTION_OR_EXPIRATION = 'prescription_or_expiration',
  /** 29 | Tarjeta de servicios */
  SERVICES_CARD = 'services_card',
  /** 12 | Dación en pago */
  SETTLEMENT = 'settlement',
  /** 27 | A satisfacción del acreedor */
  TO_CREDITOR_SATISFACTION = 'to_creditor_satisfaction',
}

export enum SatVoucherTypeEnum {
  /** Egreso */
  EXPENDITURE = 'expenditure',
  /** Ingreso */
  INCOME = 'income',
  /** Pago */
  PAYMENT = 'payment',
  /** Nómina */
  PAYROLL = 'payroll',
  /** Traslado */
  TRANSLATION = 'translation',
}

export type StpPayment = {
  __typename?: 'STPPayment';
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentConcept?: Maybe<Scalars['String']>;
};

/** Attributes for creating or updating a line item input type */
export type SalesLineItemInput = {
  availableQuantity: Scalars['Int'];
  pricePerUnitWithoutVat?: InputMaybe<Scalars['Int']>;
  sellerSku?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
  valueAddedTaxPercent?: InputMaybe<Scalars['Float']>;
};

export enum ShippingCostTypeEnum {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  FREE = 'FREE',
  TOTAL_PERCENT = 'TOTAL_PERCENT',
}

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  id: Scalars['ID'];
  shoppingCartItems?: Maybe<ShoppingCartItemConnection>;
  totalValues: Array<CurrencyTotal>;
};

export type ShoppingCartShoppingCartItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ShoppingCartItem = {
  __typename?: 'ShoppingCartItem';
  active?: Maybe<Scalars['Boolean']>;
  currency: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  itemable: Itemable;
  name?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  pricePerUnit: Scalars['Int'];
  quantity: Scalars['Int'];
  sellerBusiness?: Maybe<Business>;
  taxPercent: Scalars['Float'];
  taxTotal: Scalars['Int'];
  totalPrice: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
};

/** The connection type for ShoppingCartItem. */
export type ShoppingCartItemConnection = {
  __typename?: 'ShoppingCartItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShoppingCartItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShoppingCartItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ShoppingCartItemEdge = {
  __typename?: 'ShoppingCartItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShoppingCartItem>;
};

/** Attributes for adding a shopping cart item */
export type ShoppingCartItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  itemableId?: InputMaybe<Scalars['ID']>;
  itemableType?: InputMaybe<ShoppingCartItemableTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export enum ShoppingCartItemableTypeEnum {
  PRICE_LIST_ITEM = 'PRICE_LIST_ITEM',
  SUPPLIER_PRODUCT = 'SUPPLIER_PRODUCT',
}

export enum SortingDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SubscriptionStatement = {
  __typename?: 'SubscriptionStatement';
  id: Scalars['ID'];
};

export type SupplierProduct = {
  __typename?: 'SupplierProduct';
  business: Business;
  currency: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Int'];
  priceListItem?: Maybe<PriceListItem>;
  pricePerUnit: Scalars['Int'];
  productSimple: ProductSimple;
  sellerBusiness: Business;
  supplier: Business;
  taxPercent: Scalars['Float'];
};

/** The connection type for SupplierProduct. */
export type SupplierProductConnection = {
  __typename?: 'SupplierProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SupplierProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SupplierProduct>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SupplierProductEdge = {
  __typename?: 'SupplierProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SupplierProduct>;
};

/** Attributes for creating or updating a supplier product */
export type SupplierProductInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  active?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  priceListItemId?: InputMaybe<Scalars['ID']>;
  pricePerUnit?: InputMaybe<Scalars['Int']>;
  productSimple?: InputMaybe<ProductSimpleInput>;
  supplierId: Scalars['ID'];
  taxPercent?: InputMaybe<Scalars['Float']>;
};

export enum SupplierProductSortableField {
  BUSINESS_NAME = 'BUSINESS_NAME',
  PRICE_PER_UNIT = 'PRICE_PER_UNIT',
}

export type SupplierProductSortingParams = {
  direction: SortingDirectionEnum;
  field: SupplierProductSortableField;
};

export type TaxPayerInfo = {
  __typename?: 'TaxPayerInfo';
  address?: Maybe<Address>;
  business?: Maybe<Business>;
  countryTaxPayerInfo?: Maybe<CountryTaxPayerInfo>;
  hasSatPassword: Scalars['Boolean'];
  id: Scalars['ID'];
  satSyncStatus: TaxPayerInfoSatSyncStatusEnum;
  satUpdatedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<TaxPayerInfoStatusEnum>;
  taxpayerIdentifier: Scalars['String'];
  taxpayerName: Scalars['String'];
  wallet?: Maybe<Wallet>;
};

/** The connection type for TaxPayerInfo. */
export type TaxPayerInfoConnection = {
  __typename?: 'TaxPayerInfoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaxPayerInfoEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TaxPayerInfo>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TaxPayerInfoEdge = {
  __typename?: 'TaxPayerInfoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TaxPayerInfo>;
};

/** Attributes for creating or updating a tax payer info */
export type TaxPayerInfoInput = {
  address?: InputMaybe<AddressInput>;
  id?: InputMaybe<Scalars['String']>;
  satPassword?: InputMaybe<Scalars['String']>;
  taxpayerIdentifier: Scalars['String'];
  taxpayerName?: InputMaybe<Scalars['String']>;
};

export enum TaxPayerInfoSatSyncStatusEnum {
  NOT_SYNCED = 'NOT_SYNCED',
  SAT_LOADING_ERROR = 'SAT_LOADING_ERROR',
  SYNCED = 'SYNCED',
  SYNCING = 'SYNCING',
  SYNCING_HISTORICAL = 'SYNCING_HISTORICAL',
  VALIDATING_CREDENTIALS = 'VALIDATING_CREDENTIALS',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
}

export enum TaxPayerInfoStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TaxPayerInfosSortableField {
  CREATED_AT = 'CREATED_AT',
}

export type TaxPayerInfosSortingParams = {
  direction: SortingDirectionEnum;
  field: TaxPayerInfosSortableField;
};

/** Attributes used for updating the business */
export type UpdateBusinessInput = {
  currencyCode?: InputMaybe<CurrencyCodesEnum>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** Attributes for updating a business relationship */
export type UpdateBusinessRelationshipInput = {
  deliveryLeadTimeInDays?: InputMaybe<Scalars['Int']>;
  deliveryOnSaturday?: InputMaybe<Scalars['Boolean']>;
  deliveryOnSunday?: InputMaybe<Scalars['Boolean']>;
  paymentTerms?: InputMaybe<Scalars['Int']>;
  shippingCostType?: InputMaybe<ShippingCostTypeEnum>;
  shippingCostValue?: InputMaybe<Scalars['Float']>;
};

/** Attrs for updating BusinessRelationship#price_list_items */
export type UpdateBusinessRelationshipPriceListItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  currency: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  pricePerUnit: Scalars['Int'];
  productSimpleId?: InputMaybe<Scalars['ID']>;
  srcImageUrl?: InputMaybe<Scalars['String']>;
  taxPercent: Scalars['Float'];
  unit: Scalars['String'];
};

/** Attributes for updating sales order line item */
export type UpdateBusinessUserInput = {
  id?: InputMaybe<Scalars['ID']>;
  jobPosition?: InputMaybe<Scalars['String']>;
  permissions: Array<PermissionInput>;
  role?: InputMaybe<UserRoleEnum>;
};

export type UpdatePaymentInstructionInvoicesInput = {
  amountCents?: InputMaybe<Scalars['BigAmount']>;
  clabe?: InputMaybe<Scalars['String']>;
  paymentConcept?: InputMaybe<Scalars['String']>;
  paymentInstructionInvoiceId: Scalars['ID'];
  paymentMethod?: InputMaybe<PaymentInstructionInvoicePaymentMethodEnum>;
  providerEmails?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<PaymentInstructionInvoiceStatusEnum>;
};

/** Attributes used for updating a price list */
export type UpdatePriceListInput = {
  name?: InputMaybe<Scalars['String']>;
  taxPayerInfoId?: InputMaybe<Scalars['ID']>;
};

/** Attributes for updating price list items */
export type UpdatePriceListItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  currency: Scalars['String'];
  ean?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  pricePerUnit: Scalars['Int'];
  productSimpleId?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['String']>;
  srcImageUrl?: InputMaybe<Scalars['String']>;
  taxPercent: Scalars['Float'];
  unit: Scalars['String'];
  unitOfMeasureCode?: InputMaybe<Scalars['String']>;
  unspscCode?: InputMaybe<Scalars['String']>;
};

/** Attributes for updating a provider contact */
export type UpdateProviderContactInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  default?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

/** Attributes for updating purchase order line item */
export type UpdatePurchaseLineItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  buyerSku?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  pricePerUnitWithoutVat?: InputMaybe<Scalars['Int']>;
  requestedQuantity?: InputMaybe<Scalars['Int']>;
  sellerSku?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  valueAddedTaxPercent?: InputMaybe<Scalars['Float']>;
};

/** Attributes for updating a purchase order */
export type UpdatePurchaseOrderInput = {
  hashId?: InputMaybe<Scalars['String']>;
  lineItems: Array<UpdatePurchaseLineItemInput>;
  note?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['String']>;
  sellerContactId?: InputMaybe<Scalars['ID']>;
  sellerEmail?: InputMaybe<Scalars['String']>;
  shippingDate?: InputMaybe<Scalars['DateTime']>;
  shippingLocationId: Scalars['ID'];
  taxPayerInfoId?: InputMaybe<Scalars['ID']>;
};

/** Attributes for updating sales order line item */
export type UpdateSalesLineItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  availableQuantity?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  pricePerUnitWithoutVat?: InputMaybe<Scalars['Int']>;
  sellerSku?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
  valueAddedTaxPercent?: InputMaybe<Scalars['Float']>;
};

/** Attributes for creating or updating a purchase order */
export type UpdateSalesOrderInput = {
  buyerContactId?: InputMaybe<Scalars['ID']>;
  buyerEmail: Scalars['String'];
  hashId?: InputMaybe<Scalars['String']>;
  lineItems: Array<UpdateSalesLineItemInput>;
  note?: InputMaybe<Scalars['String']>;
  paymentTerms: Scalars['String'];
  sellerTaxPayerInfoId?: InputMaybe<Scalars['ID']>;
  shippingCostType: ShippingCostTypeEnum;
  shippingCostValue: Scalars['Float'];
  shippingDate: Scalars['DateTime'];
  shippingLocationId: Scalars['ID'];
  taxPayerInfoId?: InputMaybe<Scalars['ID']>;
};

/** Attributes used for updating a user */
export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type User = BusinessContact & {
  __typename?: 'User';
  businessUserValidation?: Maybe<BusinessUserValidation>;
  businessUsers?: Maybe<Array<BusinessUser>>;
  businesses?: Maybe<Array<Business>>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  /** @deprecated We migrated features into BusinessUser */
  features: Array<Feature>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  initials: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  locations: Array<Location>;
  phone?: Maybe<Scalars['String']>;
  profileImage: Scalars['String'];
  timeZone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Attributes used for creating a user when creating an account */
export type UserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale?: InputMaybe<LocalesEnum>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  COLABORATOR = 'COLABORATOR',
}

export type Wallet = {
  __typename?: 'Wallet';
  balance: Scalars['BigAmount'];
  business: Business;
  id: Scalars['ID'];
  stpClabe: Scalars['String'];
  taxPayerInfo: TaxPayerInfo;
  walletEntries: WalletEntryConnection;
};

export type WalletWalletEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type WalletEntry = {
  __typename?: 'WalletEntry';
  amount: Scalars['BigAmount'];
  claimed?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  entryable: Entryable;
  hashId: Scalars['ID'];
  id: Scalars['ID'];
  providerContacts?: Maybe<Array<ProviderContact>>;
  receiptUrl?: Maybe<Scalars['String']>;
  recipient?: Maybe<BankingInfo>;
  status: WalletEntryStatusEnum;
  user: User;
  wallet: Wallet;
};

/** The connection type for WalletEntry. */
export type WalletEntryConnection = {
  __typename?: 'WalletEntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WalletEntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WalletEntry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WalletEntryEdge = {
  __typename?: 'WalletEntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WalletEntry>;
};

export enum WalletEntrySortableField {
  CREATED_AT = 'CREATED_AT',
}

export type WalletEntrySortingParams = {
  direction: SortingDirectionEnum;
  field: WalletEntrySortableField;
};

export enum WalletEntryStatusEnum {
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
}

export type WithdrawalEntry = {
  __typename?: 'WithdrawalEntry';
  id: Scalars['ID'];
  trackingKey: Scalars['String'];
};

export type Banking_Info_Request = {
  __typename?: 'banking_info_request';
  amount: Scalars['BigAmount'];
  bankingInfoValidation?: Maybe<BankingInfoValidation>;
  email?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  invoicePayments?: Maybe<Array<Invoice_Payment>>;
  invoicesCount: Scalars['Int'];
  payeeTaxpayerInfo: PublicTaxPayerInfoType;
  payerTaxpayerInfo: PublicTaxPayerInfoType;
};

export type Higo_Pay_Token_Status = {
  __typename?: 'higo_pay_token_status';
  valid: Scalars['Boolean'];
};

export type Invoice_Payment = {
  __typename?: 'invoice_payment';
  amount: Scalars['BigAmount'];
  id: Scalars['ID'];
  status: PaymentStatusEnum;
};
