import { gql } from '@apollo/client';
import { PaymentInstruction } from '@src/types/models';

const PAYMENT_INSTRUCTIONS_QUERY = gql`
  query PaymentInstructions($businessId: ID!, $status: [String!]!) {
    paymentInstructions(businessId: $businessId, status: $status) {
      id
      requestedBy {
        firstName
        email
      }
      draftedBy {
        id
        firstName
        email
      }
      status
      expiryDate
      paymentInstructionInvoices {
        id
        status
        clabe
        amountCents
        paymentMethod
        providerEmails
        paymentConcept
        invoice {
          id
          outstandingBalance
        }
      }
      totalAmountCents
      totalInvoices
    }
  }
`;

export type PaymentInstructionsQueryVariables = {
  businessId?: string;
  status: string[];
};

export type PaymentInstructionResponse = Pick<
  PaymentInstruction,
  | 'id'
  | 'requestedBy'
  | 'draftedBy'
  | 'status'
  | 'expiryDate'
  | 'paymentInstructionInvoices'
> & {
  totalAmountCents: number;
  totalInvoices: number;
};

export type PaymentInstructionsQueryReturn = {
  paymentInstructions: PaymentInstructionResponse[];
};

export default PAYMENT_INSTRUCTIONS_QUERY;
