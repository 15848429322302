import styled from 'styled-components';

export const FullWidthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 80vh;
`;

export const Image = styled.img`
  width: 2.5rem;
`;
