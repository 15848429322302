import { useMemo } from 'react';

import useQueryParams from '@src/hooks/useQueryParams';
import useSortFilters from '@src/hooks/useSortFilters';

import {
  InvoiceGlobalStatus,
  InvoiceMailboxStatus,
  InvoicePaymentStatus,
  InvoiceApprovalStatus,
  InvoiceVoucherType,
} from '@src/types/enums';
import type { Sort, Date } from '@src/types/utils';
import useSettings from './useSettings';

import type { Invoice } from '@src/types/models';

export type InvoiceFilterOptions = {
  approvalStatus?: Array<keyof typeof InvoiceApprovalStatus>;
  archived?: boolean;
  businessEmitterId?: string[];
  businessId?: string;
  mailboxStatus?: Array<keyof typeof InvoiceMailboxStatus>;
  paymentStatus?: Array<keyof typeof InvoicePaymentStatus>;
  voucherType?:
    | Array<keyof typeof InvoiceVoucherType>
    | keyof typeof InvoiceVoucherType;
  limit?: number;
  fromActiveProvidersOnly?: boolean;
  emitterTaxpayerIdentifier?: string;
};

export type InvoiceFilters = InvoiceFilterOptions & {
  expiryDateFrom?: string;
  expiryDateTo?: string;
  globalStatus?: keyof typeof InvoiceGlobalStatus[];
  invoicingDateFrom?: string;
  invoicingDateTo?: string;
  paymentDateFrom?: string;
  paymentDateTo?: string;
  search?: string;
  voucherType:
    | Array<keyof typeof InvoiceVoucherType>
    | keyof typeof InvoiceVoucherType;
  sortBy?: Sort;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  tabIndex?: string;
  page?: number;
  invoiceId?: [number];
};

export type InvoiceQueryParams = {
  businessEmitterId?: string[];
  expiryDate?: Date;
  globalStatus?: string;
  invoicingDate?: Date;
  paymentDate?: Date;
  search?: string;
  voucherType?: Array<keyof typeof InvoiceVoucherType>;
  tabIndex?: number;
  sort?: keyof Invoice;
  sortDirection?: 'ascending' | 'descending';
};

function useInvoiceFilters(
  options?: InvoiceFilterOptions
): [InvoiceFilters, (query: InvoiceQueryParams) => void] {
  const { business } = useSettings();
  const [query, setQuery] = useQueryParams();
  const [sortBy] = useSortFilters();
  const {
    businessId,
    businessEmitterId,
    mailboxStatus,
    archived,
    voucherType,
    paymentStatus,
    approvalStatus,
    fromActiveProvidersOnly,
  } = options || {};

  const filters = useMemo(
    () => ({
      search: query?.search,
      expiryDateFrom: query?.expiryDate?.start,
      expiryDateTo: query?.expiryDate?.end,
      invoicingDateFrom: query?.invoicingDate?.start,
      invoicingDateTo: query?.invoicingDate?.end,
      paymentDateFrom: query?.paymentDate?.start,
      paymentDateTo: query?.paymentDate?.end,
      voucherType: query?.voucherType ||
        voucherType || [InvoiceVoucherType.INVOICE],
      businessEmitterId: businessEmitterId || query?.businessEmitterId,
      globalStatus: query?.globalStatus,
      tabIndex: query?.tabIndex,
      businessId: businessId || business?.id,
      first: query?.first,
      last: query?.last,
      before: query?.before,
      after: query?.after,
      page: query?.page,
      fromActiveProvidersOnly: fromActiveProvidersOnly,
      approvalStatus,
      mailboxStatus,
      paymentStatus,
      archived,
      sortBy,
    }),
    [options, query, location]
  );

  return [filters, setQuery];
}

export default useInvoiceFilters;
