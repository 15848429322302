import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_USER_PASSWORD as UPDATE_USER_PASSWORD_EVENT } from '@src/constants/events';
import {
  UpdateUserPasswordReturn,
  UpdateUserPasswordVariables,
  UPDATE_USER_PASSWORD,
} from '@src/graphql/mutations';
import UpdatePasswordForm from '@src/components/shared/form/UpdatePasswordForm';

export const eventData = {
  creator: 'admin',
  view: 'SettingsProfile',
};

export function ProfilePasswordForm() {
  const intl = useIntl();
  const { toast } = useToast();
  const [updateUserPassword, { loading }] = useMutation<
    UpdateUserPasswordReturn,
    UpdateUserPasswordVariables
  >(UPDATE_USER_PASSWORD);

  const onSubmit = async (data) => {
    try {
      if (data?.currentPassword && data?.newPassword) {
        await updateUserPassword({
          variables: {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
          },
        });
      }

      trackEvent(UPDATE_USER_PASSWORD_EVENT, eventData);

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Contraseña actualizada',
          id: '8wR3lj',
        }),
        intl.formatMessage({
          defaultMessage: 'Se ha actualizado tu contraseña',
          id: 'A8uomT',
        })
      );
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Algo salió mal',
          id: '6Pks4S',
        }),
        e.message
      );
    }
  };

  return (
    <UpdatePasswordForm
      showCurrentPassword
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}

export default ProfilePasswordForm;
