import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import usePagination from '@src/hooks/usePagination';
import useSettings from '@src/hooks/useSettings';
import useCreditAccount from '@src/hooks/useCreditAccount';
import FINANCING_REQUESTS_QUERY, {
  FinancingRequestsReturn,
  FinancingRequestsVariables,
} from '@src/graphql/queries/financingRequests';

type UseFinancingRequestsQueryVariables = FinancingRequestsVariables & {
  page?: number;
};

function useFinancingRequestsQuery(
  variables: UseFinancingRequestsQueryVariables
) {
  const [creditAccount] = useCreditAccount();
  const { business } = useSettings();
  const { data, loading, error, refetch } = useQuery<
    FinancingRequestsReturn,
    FinancingRequestsVariables
  >(FINANCING_REQUESTS_QUERY, {
    variables: {
      businessId: business?.id,
      creditAccountId: creditAccount?.id,
      ...variables,
    },
    skip: !creditAccount?.id || !business?.id,
    fetchPolicy: 'cache-and-network',
  });

  const { count, hasNext, hasPrevious, next, previous, page } = usePagination(
    data?.financingRequests,
    refetch,
    variables?.first || variables?.last,
    variables?.page
  );

  const financingRequests = useMemo(
    () => data?.financingRequests?.edges?.map((edge) => edge.node) || [],
    [data]
  );

  return {
    data: financingRequests,
    loading,
    error,
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    refetch,
  };
}

export default useFinancingRequestsQuery;
