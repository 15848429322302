import styled from 'styled-components';

export const Actions = styled.div`
  margin-top: ${({ theme }) => theme.spacing[12]};
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;
