import styled from 'styled-components';
import { FormControlProps } from './types';

export const Container = styled.div<FormControlProps>`
  position: relative;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing[6]} 0;
  width: ${({ theme, fullWidth }) => (fullWidth ? '100%' : theme.spacing[64])};
`;

export const Label = styled.label<FormControlProps>`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  color: ${({ error, theme }) =>
    error ? theme.colors.danger[500] : theme.colors.neutral[500]};
  font-size: ${({ theme }) => theme.fontSize.xs};
  position: absolute;
  margin: 0;
  top: 0;
  visibility: ${({ hiddenLabel }) => (hiddenLabel ? 'hidden' : 'visible')};
`;

export const HelperText = styled.p<FormControlProps>`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing[1]};
  font-size: ${({ theme }) => theme.fontSize['2xs']};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme, error }) =>
    error ? theme.colors.danger[500] : theme.colors.neutral[500]};
  margin-bottom: 0;
`;
