export const ACCEPT_RELATIONSHIP = 'AcceptRelationship';
export const ACCEPT_USER_INVITATION = 'AcceptBusinessUserInvitation';
export const CREATE_ACCOUNT = 'CreateAccount';
export const CREATE_BUSINESS_USER_ACCOUNT = 'CreateBusinessUserAccount';
export const CREATE_SALES_ORDER = 'CreateSalesOrder';
export const CREATE_PURCHASE_ORDER = 'CreatePurchaseOrder';
export const CREATE_CATALOG_PURCHASE_ORDER = 'CreateCatalogPurchaseOrder';
export const CREATE_TAX_PAYER_INFO = 'CreateTaxPayerInfo';
export const CREATE_SHIPPING_LOCATION = 'CreateShippingLocation';
export const CREATE_PARTNER_TAX_PAYER_INFO = 'CreatePartnerTaxPayerInfo';
export const CREATE_BUSINESS_CLIENT = 'CreateBusinessClient';
export const UPDATE_BUSINESS_CLIENT = 'UpdateBusinessClient';
export const CREATE_BUSINESS_PROVIDER = 'CreateBusinessProvider';
export const UPDATE_BUSINESS_PROVIDER = 'UpdateBusinessProvider';
export const CREATE_PARTNER_SHIPPING_LOCATION = 'CreatePartnerShippingLocation';
export const UPDATE_SALES_ORDER = 'UpdateSalesOrder';
export const UPDATE_PURCHASE_ORDER = 'UpdatePurchaseOrder';
export const SEND_INVITATION_REMINDER = 'RemindInvitation';
export const REMOVE_LINE_ITEM = 'RemoveLineItem';
export const ADD_LINE_ITEM = 'AddLineItem';
export const HANDLE_LINEITEMS_CSV = 'HandleLineItemsUpload';
export const EXPORT_SALES_ORDERS = 'ExportSalesOrders';
export const EXPORT_PURCHASE_ORDERS = 'ExportPurchaseOrders';
export const RESOURCE_LIST_FILTERS = 'ResourceListFilters';
export const ORDERS_REPORTS_FILTERS = 'OrdersReportsFilters';
export const UPDATE_ORDER_STATUS = 'UpdateOrderStatus';
export const CREATE_PASSWORD_RESET = 'CreatePasswordReset';
export const RESET_PASSWORD = 'ResetPassword';
export const CREATE_SETTINGS_LOCATION = 'CreateSettingsLocation';
export const UPDATE_SETTINGS_LOCATION = 'UpdateSettingsLocation';
export const UPDATE_SETTINGS_LOCATION_STATUS = 'UpdateSettingsLocationStatus';
export const CREATE_BUSINESS_USER = 'CreateBusinessUser';
export const UPDATE_BUSINESS = 'UpdateBusiness';
export const UPDATE_SETTINGS_TAXPAYERINFO_STATUS =
  'UpdateSettingsTayPayerInfosStatus';
export const CREATE_SETTINGS_TAX_PAYER_INFO = 'CreateSettingsTaxPayerInfo';
export const UPDATE_SETTINGS_TAX_PAYER_INFO = 'UpdateSettingsTaxPayerInfo';
export const INITIALIZE_INVOICES_SYNC = 'InitializeInvoicesSync';
export const REMOVE_BUSINESS_USER = 'RemoveBusinessUser';
export const UPDATE_BUSINESS_USER = 'UpdateBusinessUser';
export const UPDATE_USER_PASSWORD = 'UpdateUserPassword';
export const UPDATE_USER_PROFILE = 'UpdateUserProfile';
export const CREATE_PURCHASE_LIST = 'CreatePurchaseList';
export const UPDATE_PURCHASE_LIST = 'UpdatePurchaseList';
export const DELETE_PURCHASE_LIST = 'DeletePurchaseList';
export const DELETE_PURCHASE_LIST_ITEMS = 'DeletePurchaseListItems';
export const ADD_ITEMS_TO_PURCHASE_LIST = 'AddItemsToPurchaseList';
export const UPDATE_PURCHASE_LIST_ITEM = 'UpdatePurchaseListItem';
export const INVOICE_ORDER = 'InvoiceOrder';
export const CREATE_PRICE_LIST = 'CreatePriceList';
export const UPDATE_PRICE_LIST = 'UpdatePriceList';
export const ADD_ITEMS_TO_SHOPPING_CART = 'UpdateShoppingCartItems';
export const UPDATE_QUANTITY_CART_ITEM = 'UpdateQuantityAtShoppingCartItems';
export const REMOVE_CART_ITEM = 'RemoveShoppingCartItem';
export const EMPTY_SHOPPING_CART = 'EmptyShoppingCart';
export const UPDATE_PRICE_LIST_ITEMS = 'UpdatePriceListItems';
export const REMOVE_PRICE_LIST_ITEMS = 'RemovePriceListItems';
export const UPDATE_STATUS_PUBLIC_ORDER = 'UpdateStatusPublicOrder';
export const UPDATE_PROVIDERS_PRICE_LIST =
  'UpdateBusinessRelationshipPriceList';
export const DELETE_PROVIDER = 'DeleteBusinessRelationship';
export const UPLOAD_INVOICE = 'UploadInvoice';
export const CREATE_COST_CENTER = 'CreateCostCenter';
export const UPDATE_INVOICE_EMAIL = 'UpdateInvoiceEmail';
export const UPDATE_INVOICE_COST_CENTER = 'UpdateInvoiceCostCenter';
export const UPDATE_INVOICE_STATUS = 'UpdateInvoiceStatus';
export const CREATE_INVOICE_COMMENT = 'CreateInvoiceComment';
export const UPDATE_INVOICE_MAILBOX_STATUS = 'UpdateInvoiceMailboxStatus';
export const UPDATE_INVOICE_PAYMENTS = 'UpdateInvoicePayments';
export const CREATE_MAILBOX_BUSINESS_SUPPLIER = 'CreateMailboxBusinessSupplier';
export const UPDATE_BUSINESS_FEATURE_GROUPS = 'UpdateBusinessFeatureGroups';
export const UPDATE_SUPPLIER = 'UpdateSupplier';
export const PAY_INVOICES = 'PayInvoices';
export const ARCHIVE_INVOICES = 'ArchiveInvoices';
export const CREATE_BUSINESS_AS_TAXPAYER = 'CreateBusinessTaxPayerInfo';
export const UPDATE_BUSINESS_AS_TAXPAYER = 'UpdateBusinessAsTaxPayer';
export const SYNC_INVOICES_WITH_SAT = 'SyncInvoicesWithSat';
export const UPDATE_PROVIDER_CONTACT = 'UpdateProviderContact';
export const CREATE_PROVIDER_CONTACT = 'CreateProviderContact';
export const DELETE_PROVIDER_CONTACT = 'DeleteProviderContact';
export const UPDATE_INVOICE_CREDIT_NOTE = 'UpdateInvoiceCreditNote';
export const UPDATE_SUPPLIER_BANKING_INFO = 'UpdateSupplierBankingInfo';
export const CREATE_INVOICES_STP_PAYMENTS_ACTION = 'createInvoicesStpPayments';
export const CREATE_INVOICES_HIGO_PAYMENTS_ACTION =
  'createInvoicesHigoPayments';
export const CREATE_INVOICE_PAYMENTS_WITH_BANKING_INFO_REQUEST_ACTION =
  'createInvoicePaymentsWithBankingInfoRequest';
export const CREATE_WITHDRAWAL_WALLET_ENTRY = 'createWithdrawalWalletEntry';
export const CREATE_USER_EVENT = 'createUser';
export const CREATE_BUSINESS_EVENT = 'createBusinessEvent';

export const SUPPLIER_BATCH_ENABLED = 'supplier_batch_enabled';
export const SUPPLIER_ACTIVATED = 'supplier_activated';
export const SUPPLIER_DEACTIVATED = 'supplier_deactivated';
export const ONBOARDING_FINISHED = 'onboarding_finished';
export const ONBOARDING_MOVED_TO_PAY = 'onboarding_moved_to_pay';
export const NOTIFICATION_VIEWED = 'notification_viewed';
export const INVOICE_MANUALLY_PAID = 'invoice_manually_paid';
export const FINANCING_REQUESTED = 'financing_requested';
export const FINANCING_REQUEST_REPAYMENT_EVENT =
  'create_financing_requested_repayment';
export const HIGO_PAY_PROOF_RESENT = 'higo_pay_proof_resent';
export const SEARCH_CLICKED = 'search_clicked';
export const SEARCH_TRIGGERED = 'search_triggered';
export const SEARCH_RESULT_CLICKED = 'search_result_clicked';
export const SEARCH_CTA_CLICKED = 'search_cta_clicked';
export const BANKING_INFO_FAILED_VALIDATION = 'banking_info_failed_validation';
export const FINANCING_REQUEST_UPGRADE_CAP_LIMIT =
  'financing_request_upgrade_cap_limit';
export const INVOICE_PAY_CLICKED = 'invoice_pay_clicked';
export const PAYMENT_INSUFFICIENT_FUNDS_REACHED =
  'payment_insufficient_funds_reached';
export const BANKING_INFO_MODAL_OPENED = 'banking_info_modal_opened';
export const PAYMENT_TERMS_ACCEPTED = 'payment_terms_accepted';
export const BANKING_INFO_CREATED = 'banking_info_created';
export const PAYMENT_METHOD_CHANGED = 'payment_method_changed';
export const PAYMENT_EMAIL_ADDED = 'payment_email_added';

// Payment Instructions
export const INVOICES_FOR_PAYMENT_INSTRUCTION_SELECTED =
  'invoices_for_payment_instruction_selected';
export const PAYMENT_INSTRUCTION_SAVE_CLICKED =
  'payment_instruction_save_clicked';
export const PAYMENT_INSTRUCTION_REQUEST_CLICKED =
  'payment_instruction_request_clicked';
export const PAYMENT_INSTRUCTION_CREATED = 'payment_instruction_created';
export const PAYMENT_INSTRUCTION_UPDATE_CLICKED =
  'payment_instruction_update_clicked';
export const PAYMENT_INSTRUCTION_DELETE_CLICKED =
  'payment_instruction_delete_clicked';
export const PAYMENT_INSTRUCTION_REJECT_CLICKED =
  'payment_instruction_reject_clicked';
export const PAYMENT_INSTRUCTION_PAY_CLICKED =
  'payment_instruction_pay_clicked';
export const CREATE_BANKING_INFO_REQUEST_VALIDATION_ACTION =
  'createBankingInfoRequestValidation';

// Withdraw from credit
export const WITHDRAW_FROM_CREDIT_STARTED = 'withdraw_from_credit_started';
export const WITHDRAW_FROM_CREDIT_REQUESTED = 'withdraw_from_credit_requested';
export const WITHDRAW_FROM_CREDIT_CLOSED = 'withdraw_from_credit_closed';

// Credit
export const PAY_INDIVIDUAL_CREDIT_CLICKED = 'pay_individual_credit_clicked';
export const ACTIVE_CREDIT_TAB_CLICKED = 'active_credit_tab_clicked';
export const PAID_CREDIT_TAB_CLICKED = 'paid_credit_tab_clicked';

export const DOWNLOAD_CREDIT_REQUEST_RECEIPT_CLICKED =
  'download_credit_receipt_clicked';
// Navbar
export const NAVBAR_ITEM_CLICKED = 'navbar_item_clicked';
//Payments without invoice
export const CREATE_PAYMENTS = 'create_payments';
export const CREATE_PAYMENTS_ERROR = 'create_payments_error';
export const CREATE_PAYMENTS_WITHOUT_INVOICE_CLICKED =
  'create_payments_without_invoice_clicked';
export const CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_CLICKED =
  'create_banking_transaction_invoice_payment_clicked';
export const CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT =
  'create_banking_transaction_invoice_payment';
export const CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_ERROR =
  'create_banking_transaction_invoice_payment_error';
