import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './WalletView.styles';
import Card from '@src/components/ui/Card';
import Currency from '@src/components/i18n/Currency';
import Button from '@src/components/ui/Button';
import { Plus, Minus } from '@src/components/ui/Icon/outline';
import Label from '@src/components/ui/Label';
import WithdrawalModal from './components/WithdrawalModal';
import useModal from '@src/hooks/useModal';
import useWallet from '@src/hooks/useWallet';
import DepositModal from '@src/components/shared/modals/DepositModal';
import WithdrawalUnavailableModal from './components/WithdrawalUnavailableModal';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';
import useCreditAccount from '@src/hooks/useCreditAccount';

const WalletCard = () => {
  const { formatMessage } = useIntl();
  const { balance } = useWallet();
  const [creditAccount] = useCreditAccount();
  const isAutomaticFinancingFundActive = useFeatureFlag(
    FeatureFlag.AUTOMATIC_FINANCING_FUND
  );

  const { open: openWithdrawal } = useModal(WithdrawalModal);
  const { open: openDeposit } = useModal(DepositModal);
  const { open: openWithdrawalUnavailable } = useModal(
    WithdrawalUnavailableModal
  );

  const active = creditAccount?.active;
  const owedAmount = creditAccount?.owedTotal || 0;
  const canWithdraw =
    active && isAutomaticFinancingFundActive ? owedAmount === 0 : true;

  const tryWithdrawal = () => {
    if (canWithdraw) {
      openWithdrawal();
    } else {
      openWithdrawalUnavailable();
    }
  };

  return (
    <Card>
      <S.WalletCardContent>
        <S.BalanceContainer>
          <Label strong size="md">
            {formatMessage({
              defaultMessage: 'Saldo disponible',
              id: '8wYd6w',
            })}
          </Label>
          <S.Balance>
            <Currency value={balance || 0} smallDecimals />
          </S.Balance>
        </S.BalanceContainer>
        <S.Actions>
          <Button
            block
            variant="ghosted"
            onClick={openDeposit}
            startIcon={<Plus />}
          >
            {formatMessage({
              defaultMessage: 'Depositar',
              id: 'e88/qj',
            })}
          </Button>
          <Button block onClick={tryWithdrawal} startIcon={<Minus />}>
            {formatMessage({
              defaultMessage: 'Retirar',
              id: '2OwuoE',
            })}
          </Button>
        </S.Actions>
      </S.WalletCardContent>
    </Card>
  );
};

export default WalletCard;
