import React from 'react';
import { useIntl } from 'react-intl';

import { Header, Tag } from '@src/components/ui';
import * as S from './DownloadSuccess.styles';

export enum AcceptedFormat {
  PDF = 'pdf',
  CSV = 'csv',
}

type DownloadSuccessProps = {
  email: string;
  acceptedFormat?: AcceptedFormat;
};

function DownloadSuccess({
  email,
  acceptedFormat = AcceptedFormat.CSV,
}: DownloadSuccessProps) {
  const intl = useIntl();
  return (
    <S.DownloadSuccess>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="36.75" cy="38.5" r="14" fill="#82FFD9" />
        <path
          d="M40.25 21.15V22.76C40.2479 26.5338 39.0259 30.2057 36.7663 33.2282C34.5068 36.2507 31.3307 38.4619 27.7119 39.5318C24.093 40.6018 20.2252 40.4733 16.6853 39.1655C13.1454 37.8577 10.1231 35.4407 8.06918 32.2749C6.01522 29.1091 5.03963 25.3641 5.28793 21.5986C5.53623 17.833 6.99511 14.2486 9.44699 11.3799C11.8989 8.5112 15.2124 6.51194 18.8933 5.68027C22.5743 4.84861 26.4255 5.22911 29.8725 6.76502"
          stroke="#050C57"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.25 8.75977L22.75 26.2773L17.5 21.0273"
          stroke="#050C57"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Header as="h4">
        {intl.formatMessage({
          defaultMessage: 'Descarga enviada',
          id: 'KnQwu2',
        })}
      </Header>
      {acceptedFormat === AcceptedFormat.PDF ? (
        <p>
          {intl.formatMessage({
            defaultMessage:
              'En unos minutos enviaremos un archivo ZIP con tus PDFs a tu email',
            id: 'PCbgEJ',
          })}
        </p>
      ) : (
        <p>
          {intl.formatMessage({
            defaultMessage:
              'En unos minutos enviaremos un un archivo CSV a tu email',
            id: 'gTjZu3',
          })}
        </p>
      )}
      <Tag label={email} color="gray" />
    </S.DownloadSuccess>
  );
}

DownloadSuccess.displayName = 'DownloadSuccess';

export default DownloadSuccess;
