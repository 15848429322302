import { graphql } from 'msw';

export const transactionsListHandler = graphql.query(
  'TransactionsList',
  (req, res, ctx) => {
    const { pending } = req.variables;

    if (pending) {
      return res(
        ctx.data({
          walletEntries: {
            edges: [
              {
                node: {
                  amount: -100,
                  claimed: true,
                  createdAt: '2022-04-03T18:00:16-05:00',
                  hashId: 'JXX3PLMY',
                  id: '743',
                  recipient: null,
                  status: 'PROCESSED',
                  entryable: {
                    financingRequestRepayment: null,
                    id: '138',
                    lastBusinessUserValidation: null,
                    __typename: 'HigoPaymentSentEntry',
                    invoicePayment: {
                      id: '915',
                      paymentType: 'HIGO_PAYMENT',
                      __typename: 'InvoicePayment',
                      invoice: {
                        emitterTaxpayerIdentifier: 'GOM0809114P5',
                        id: '232767',
                      },
                    },
                  },
                  user: {
                    firstName: 'Sebastian Michele',
                    id: '47401',
                    lastName: 'Ferreyros Arciniega',
                  },
                  wallet: {
                    balance: 726589,
                    id: '515',
                  },
                  __typename: 'WalletEntry',
                },
              },
            ],
            pageInfo: {
              startCursor: 'mw',
              endCursor: 'MQ',
              hasNextPage: false,
              hasPreviousPage: false,
            },
            totalCount: 1,
          },
        })
      );
    }

    return res(
      ctx.data({
        walletEntries: {
          edges: [
            {
              node: {
                amount: -1000,
                claimed: true,
                createdAt: '2022-04-03T18:00:16-05:00',
                hashId: 'JXX3PLMY',
                id: '743',
                recipient: null,
                status: 'PROCESSED',
                entryable: {
                  financingRequestRepayment: null,
                  id: '138',
                  lastBusinessUserValidation: null,
                  __typename: 'HigoPaymentSentEntry',
                  invoicePayment: {
                    id: '915',
                    paymentType: 'HIGO_PAYMENT',
                    __typename: 'InvoicePayment',
                    invoice: {
                      emitterTaxpayerIdentifier: 'GOM0809114P5',
                      id: '232767',
                    },
                  },
                },
                user: {
                  firstName: 'Sebastian Michele',
                  id: '47401',
                  lastName: 'Ferreyros Arciniega',
                },
                wallet: {
                  balance: 726589,
                  id: '515',
                },
                __typename: 'WalletEntry',
              },
            },
          ],
          pageInfo: {
            startCursor: 'mw',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          totalCount: 1,
        },
      })
    );
  }
);
