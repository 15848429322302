/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import posthog from 'posthog-js';
import { get as getToken } from '@src/lib/auth/index'

import * as serviceWorker from './ServiceWorker';
import App from './components/App';
import AppErrorBoundary from './AppErrorBoundary';

const history = createBrowserHistory();

const {
  NODE_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_VERCEL_ENV,
  REACT_APP_VERCEL_GIT_COMMIT_SHA,
  REACT_APP_POSTHOG_API_KEY,
} = process.env;

const isProduction = NODE_ENV === 'production';

if (isProduction) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: REACT_APP_VERCEL_ENV,
    release: REACT_APP_VERCEL_GIT_COMMIT_SHA,
  });
}

if (process.env.REACT_APP_USE_MOCKS === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

if (REACT_APP_POSTHOG_API_KEY) {
  posthog.init(REACT_APP_POSTHOG_API_KEY, {
    api_host: 'https://app.posthog.com',
  });

  posthog.onFeatureFlags((_, flags) => {
    if (flags['beta-app']) {
      const token = getToken() || ''
      const search = window.location.search
      const query = search ? `${search}&token=${token}`: `?token=${token}`
      window.location.href = process.env.REACT_APP_BETA_DOMAIN + window.location.pathname + query;
    }
  });
}

function render(Component) {
  ReactDOM.render(
    <AppErrorBoundary>
      <Component history={history} />
    </AppErrorBoundary>,
    document.getElementById('root')
  );
}

render(App);

if (isProduction) {
  serviceWorker.unregister();
}

// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}
