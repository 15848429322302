import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import messages from './messages';
import Tag from '@src/components/ui/Tag';

import { BusinessRelationshipStatus } from '@src/types/enums';

type RelationshipStatusBadgeProps = {
  status: keyof typeof BusinessRelationshipStatus;
};

export function optionsForStatus(
  intl?: IntlShape
): Array<{ text: string; value: string }> {
  if (!intl || !intl.formatMessage) {
    return Object.values(BusinessRelationshipStatus).map((opt) => ({
      text: opt,
      value: opt,
    }));
  }

  return Object.values(BusinessRelationshipStatus).map((opt) => ({
    text: messages[opt] ? intl.formatMessage(messages[opt]) : opt,
    value: opt,
  }));
}

export function RelationshipStatusBadge(props: RelationshipStatusBadgeProps) {
  const { status } = props;
  const intl = useIntl();
  let color;
  switch (status) {
    case BusinessRelationshipStatus.ACCEPTED:
      color = 'success';
      break;
    case BusinessRelationshipStatus.NOT_ACCEPTED:
      color = 'warning';
      break;
    default:
      color = 'gray';
      break;
  }
  return (
    <Tag compact color={color} label={intl.formatMessage(messages[status])} />
  );
}

export default RelationshipStatusBadge;
