import styled, { css } from 'styled-components';
import { Color, ColorStep } from 'styled-components';

interface DividerProps {
  direction?: 'row' | 'column';
  margin?: '0' | '2' | '3' | '4' | '8';
  borderColor?: Color;
  borderColorStep?: ColorStep;
}

const Divider = styled.div<DividerProps>`
  ${({
    theme,
    direction = 'column',
    margin = 4,
    borderColorStep = 100,
    borderColor = 'neutral',
  }) =>
    direction === 'row'
      ? css`
          border-right: 1px solid ${theme.colors[borderColor][borderColorStep]};
          margin: 0 ${theme.spacing[margin]};
          display: inline-block;
          height: 100%;
        `
      : css`
          border-bottom: 1px solid ${theme.colors[borderColor][borderColorStep]};
          margin: ${theme.spacing[margin]} 0;
        `};
`;

export default Divider;
