import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { loggedIn as isLoggedIn } from '@src/lib/auth';
import CreateBusinessUserAccountForm from '@src/components/pages/BusinessUserInvitation/components/CreateBusinessUserAccountForm';
import Loader from '@src/components/shared/Loader';
import BUSINESS_USER_INVITATION_QUERY, {
  BusinessUserInvitationReturn,
  BusinessUserInvitationVariables,
} from '@src/graphql/queries/businessUserInvitation';
import useToast from '@src/hooks/useToast';
import MinimalLayout from '@src/components/layouts/MinimalLayout';
import { Card } from '@src/components/ui';
import * as S from './BusinessUserInvitation.styles';

type Params = {
  invitationId: string;
};

function BusinessUserInvitation() {
  const intl = useIntl();
  const { toast } = useToast();
  const history = useHistory();
  const { invitationId } = useParams<Params>();
  const { data, loading } = useQuery<
    BusinessUserInvitationReturn,
    BusinessUserInvitationVariables
  >(BUSINESS_USER_INVITATION_QUERY, {
    variables: {
      token: invitationId,
    },
    skip: !invitationId,
    onCompleted: (data) => {
      if (isLoggedIn() && data.businessUserInvitation) {
        history.replace(`/accept-user-invitation/${invitationId}`);
      } else if (data?.businessUserInvitation?.nextStep === 'login') {
        history.replace(`/accept-user-invitation/${invitationId}`);
        setTimeout(() => {
          toast.info(
            intl.formatMessage({
              id: 'zjrwsw',
              defaultMessage:
                'Debes iniciar sesión en tu cuenta para aceptar la unirte a la organización',
            })
          );
        }, 1000);
      }
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          id: '7/ydiz',
          defaultMessage: 'Error al encontrar una invitación',
        }),
        e.message
      );
      history.replace('/');
    },
  });

  if (loading || !data?.businessUserInvitation) {
    return <Loader fullWidth />;
  }

  return (
    <MinimalLayout
      title={intl.formatMessage({
        id: 'HLMVEd',
        defaultMessage: 'Accept user invitation',
      })}
    >
      <S.Container>
        <Card padding="8">
          <CreateBusinessUserAccountForm
            businessUserInvitation={data?.businessUserInvitation}
          />
        </Card>
      </S.Container>
    </MinimalLayout>
  );
}

export default BusinessUserInvitation;
