import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './Bank.styles';

import { ReactComponent as DefaultBank } from '@src/assets/img/default-bank.svg';
interface BankProps {
  name?: string;
  logoUrl?: string;
}

const Bank = ({ name, logoUrl }: BankProps) => {
  const intl = useIntl();

  return (
    <S.Bank>
      {logoUrl ? <img src={logoUrl} alt="Bank logo" /> : <DefaultBank />}
      {name ? (
        <p className="bank-name">{name}</p>
      ) : (
        <p>
          {intl.formatMessage({
            defaultMessage: 'Institución financiera',
            id: 'iMrwOs',
          })}
        </p>
      )}
    </S.Bank>
  );
};

export default Bank;
