import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export const Mailbox = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  button {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;
