import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';

import { trackEvent } from '@src/lib/analytics';
import { UPDATE_INVOICE_CREDIT_NOTE } from '@src/constants/events';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import attributes from './attributes';
import Button from '@src/components/ui/Button';
import Currency from '@src/components/i18n/Currency';
import Loader from '@src/components/shared/Loader';
import EmptyResults from '@src/components/shared/EmptyResults';
import Table from '@src/components/shared/Table';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { Header, Alert } from '@src/components/ui';

import UPDATE_INVOICE_CREDIT_NOTES_MUTATION, {
  UpdateInvoiceCreditNotesReturn,
  UpdateInvoiceCreditNotesVariables,
} from '@src/graphql/mutations/updateInvoiceCreditNotes';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import RECEIVED_CREDIT_NOTES_QUERY, {
  ReceivedCreditNotesReturn,
  ReceivedCreditNotesVariables,
  CreditNote,
} from '@src/graphql/queries/receivedCreditNotes';
import { InvoiceApprovalStatus } from '@src/types/enums';

interface ApplyCreditNoteProps {
  supplierHashId: string;
  invoice: {
    id: string;
    outstandingBalance?: number;
    currency: string;
  };
  onClose?: () => void;
}

type CreditNoteField = CreditNote & {
  applied?: boolean;
};

function ApplyCreditNote({
  invoice,
  supplierHashId,
  onClose,
}: ApplyCreditNoteProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const { toast } = useToast();
  const [hasAllSelected, setHasAllSelected] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [receivedCreditNotes, setReceivedCreditNotes] = useState<
    CreditNoteField[]
  >([]);
  const [appliedNotes] = useState(new Map());
  const { handleSubmit } = useForm();
  const { loading } = useQuery<
    ReceivedCreditNotesReturn,
    ReceivedCreditNotesVariables
  >(RECEIVED_CREDIT_NOTES_QUERY, {
    variables: {
      businessId: business?.id,
      supplierHashId,
      uncredited: false,
      approvalStatus: [InvoiceApprovalStatus.APPROVED],
    },
    fetchPolicy: 'network-only',
    skip: !business?.id,
    onCompleted: (data) => {
      setReceivedCreditNotes(
        data?.receivedCreditNotes?.edges
          ?.map((edge) => edge.node)
          .filter((creditNote) => creditNote.pendingCredit !== 0) || []
      );
    },
  });

  const [updateInvoiceCreditNotes, { loading: submitting }] = useMutation<
    UpdateInvoiceCreditNotesReturn,
    UpdateInvoiceCreditNotesVariables
  >(UPDATE_INVOICE_CREDIT_NOTES_MUTATION, {
    refetchQueries: [INVOICE_QUERY],
    onCompleted: () => {
      onClose?.();

      trackEvent(UPDATE_INVOICE_CREDIT_NOTE, {
        creator: 'Buyer',
        view: 'ApplyCreditNoteModal',
      });

      toast.success(
        intl.formatMessage({
          defaultMessage: 'El saldo de tu factura se ha actualizado',
          id: 'omOhpN',
        }),
        intl.formatMessage({
          defaultMessage:
            'Hemos aplicado el saldo de tu nota de crédito a tu factura',
          id: 'xku7Gz',
        })
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage:
            'Ha ocurrido un error al aplicar tus notas de crédito',
          id: 'icgUnb',
        }),
        e.message
      );
    },
  });

  const onSubmit = () => {
    const invoicePayments = receivedCreditNotes
      .filter((creditNote) => appliedNotes.get(creditNote.id))
      .map((creditNote) => ({
        creditNoteId: creditNote.id,
        amount: creditNote.pendingCredit,
      }));

    if (invoice.id && business?.id) {
      updateInvoiceCreditNotes({
        variables: {
          businessId: business.id,
          invoiceId: invoice.id,
          invoicePayments,
        },
      });
    }
  };

  const pendingBalance = useMemo(
    () =>
      (invoice?.outstandingBalance ?? 0) -
      receivedCreditNotes.reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue.applied ? currentValue.pendingCredit : 0),
        0
      ),
    [invoice, receivedCreditNotes, setSelectedIds]
  );

  const applyCreditNote = (creditNote: CreditNoteField): CreditNoteField => ({
    ...creditNote,
    applied: appliedNotes.get(creditNote.id),
  });

  const onSelectedCreditNotesChange = (selectedId: string) => {
    appliedNotes.set(selectedId, !appliedNotes.get(selectedId));

    const noteIndex = receivedCreditNotes.findIndex(
      (note) => note.id === selectedId
    );
    const nextReceivedNotes = [...receivedCreditNotes];
    nextReceivedNotes[noteIndex] = applyCreditNote(
      nextReceivedNotes[noteIndex]
    );

    setReceivedCreditNotes(nextReceivedNotes);
    setHasAllSelected(
      nextReceivedNotes.every((note) => appliedNotes.get(note.id))
    );
    setSelectedIds(
      nextReceivedNotes
        .filter((note) => appliedNotes.get(note.id))
        .map((note) => note.id)
    );
  };

  const onSelectedAllCreditNotesChange = () => {
    receivedCreditNotes.forEach((note) => {
      appliedNotes.set(note.id, !hasAllSelected);
    });

    const nextReceivedNotes = receivedCreditNotes.map((creditNote) =>
      applyCreditNote(creditNote)
    );

    setReceivedCreditNotes(nextReceivedNotes);
    setSelectedIds(
      hasAllSelected ? [] : nextReceivedNotes.map((note) => note.id)
    );
    setHasAllSelected(!hasAllSelected);
  };

  if (loading) {
    return <Loader fullWidth />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Aplicar crédito',
          id: 'P6Dqct',
        })}
      </Header>

      <Spacer />
      <Alert color={pendingBalance < 0 ? 'danger' : 'info'}>
        <FlexContainer justifyContent="space-between">
          <p>
            {intl.formatMessage({
              defaultMessage: 'Saldo pendiente',
              id: 'h0TyH1',
            })}
          </p>
          <p>
            <Currency value={pendingBalance || 0} currency={invoice.currency} />
          </p>
        </FlexContainer>
      </Alert>

      <Spacer />
      <Table
        headers={['id', 'pendingCredit', 'appliedCredit']}
        attributes={attributes}
        data={receivedCreditNotes}
        keyExtractor={(item) => item?.id}
        selectable
        onSelect={onSelectedCreditNotesChange}
        onSelectAll={onSelectedAllCreditNotesChange}
        selectedIds={selectedIds}
        hasAllSelected={hasAllSelected}
        emptyElement={
          <EmptyResults
            compact
            label={intl.formatMessage({
              defaultMessage: 'No hay notas de crédito',
              id: 'xDRdjf',
            })}
          />
        }
        loading={loading}
      />

      <Spacer />
      <FlexContainer justifyContent="flex-end">
        <Button color="neutral" variant="outlined" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button
          type="submit"
          disabled={
            submitting ||
            pendingBalance < 0 ||
            pendingBalance === invoice?.outstandingBalance
          }
          loading={submitting}
        >
          {intl.formatMessage({
            defaultMessage: 'Aplicar',
            id: '4aS11Q',
          })}
        </Button>
      </FlexContainer>
    </form>
  );
}

ApplyCreditNote.displayName = 'ApplyCreditNote';

export default ApplyCreditNote;
