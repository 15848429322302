import React, { FunctionComponent, SyntheticEvent } from 'react';
import { FlexContainer } from '@src/components/shared/layouts';
import { Icon } from '@src/components/ui';
import CircledIcon from '@src/components/ui/CircledIcon';
import { IconName } from '@src/components/ui/Icon/types';
import Switch from '../../../../ui/Switch';
import * as S from './ToggleItem.styles';

type ToggleItemProps = {
  text?: string;
  label?: any;
  id?: string;
  name?: string;
  iconName?: IconName;
  onChange: (state: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
};

export const ToggleItem: FunctionComponent<ToggleItemProps> = ({
  iconName,
  label,
  text,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <FlexContainer
      style={{ width: '600px', paddingTop: '24px', paddingBottom: '24px' }}
    >
      {iconName && (
        <S.IconContainer>
          <CircledIcon backgroundColor="neutral" backgroundColorStep="100">
            <Icon
              variant="solid"
              name={iconName}
              size={20}
              color="neutral"
              colorStep="500"
            />
          </CircledIcon>
        </S.IconContainer>
      )}
      <S.TextContainer>
        <S.Label>{label}</S.Label>
        <S.Text>{text}</S.Text>
      </S.TextContainer>
      <S.SwitchContainer>
        <Switch onChange={onChange} checked={checked} disabled={disabled} />
      </S.SwitchContainer>
    </FlexContainer>
  );
};
