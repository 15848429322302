import styled, { css } from 'styled-components';

type FlexContainerProps = Pick<
  React.CSSProperties,
  | 'justifyContent'
  | 'alignItems'
  | 'flexDirection'
  | 'flexWrap'
  | 'flexFlow'
  | 'alignContent'
  | 'gap'
> & {
  /**
   * Occupy the full width of the container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Occupy the full height of the container
   * @default false
   */
  fullHeight?: boolean;
};

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? ''};
  align-items: ${({ alignItems }) => alignItems ?? ''};
  flex-direction: ${({ flexDirection }) => flexDirection ?? ''};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? ''};
  flex-flow: ${({ flexFlow }) => flexFlow ?? ''};
  align-content: ${({ alignContent }) => alignContent ?? ''};
  gap: ${({ gap }) => gap ?? ''};
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;

export default FlexContainer;
