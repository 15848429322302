import { gql } from '@apollo/client';
import USER_PROFILE_ATTRIBUTES, {
  UserProfileAttributes,
} from '@src/graphql/fragments/userProfileAttributes';

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    updateUserPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      ...UserProfile
    }
  }
  ${USER_PROFILE_ATTRIBUTES}
`;

export type UpdateUserPasswordVariables = {
  currentPassword: string;
  newPassword: string;
};

export type UpdateUserPasswordReturn = UserProfileAttributes;
