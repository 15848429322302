import * as Types from '../../../../../../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RecordNotificationInvoiceFragment = {
  __typename?: 'Invoice';
  id: string;
  expiryDate?: any | null;
  paymentTerms?: number | null;
  cancelationReason?: string | null;
  currency: string;
};

export const RecordNotificationInvoiceFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecordNotificationInvoice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Invoice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'paymentTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cancelationReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RecordNotificationInvoiceFragment, unknown>;
