import { useCallback, useEffect, useState } from 'react';

import useQueryParams from '@src/hooks/useQueryParams';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import type { PaginatedResponse } from '@src/types/utils';
import { INITIAL_PAGINATION_PARAMS } from '@src/utils/pagination';

function usePagination<Element extends { id?: any } = any>(
  response?: PaginatedResponse<Element>,
  refetch?: any,
  pageSize = RESULTS_PER_PAGE,
  urlPage?: number
): {
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  nodes: Element[];
  next: () => void;
  previous: () => void;
  resetPaginationParams: (otherParams?: Record<string, any>) => void;
} {
  const [nodes, setNodes] = useState<any>(new Map());
  const [page, setPage] = useState<number>(urlPage || 1);
  const [, setParams] = useQueryParams();

  const endCursor = response?.pageInfo?.endCursor || undefined;
  const startCursor = response?.pageInfo?.startCursor || undefined;
  const count = response?.totalCount || 0;
  const hasPrevious = response?.pageInfo?.hasPreviousPage || false;
  const hasNext = response?.pageInfo?.hasNextPage || false;

  useEffect(() => {
    const newNodes = new Map(nodes);
    if (response?.edges && response.edges.length) {
      response.edges.forEach((edge) => {
        if (edge?.node?.id) {
          newNodes.set(edge.node.id, edge.node);
        }
      });

      setNodes(newNodes);
    }
  }, [response?.edges]);

  const next = useCallback(() => {
    if (refetch && hasNext) {
      const newPage = page + 1;
      const params = {
        after: endCursor,
        first: pageSize,
        before: undefined,
        last: undefined,
        page: newPage,
      };
      setParams(params);
      setPage(newPage);
    }
  }, [refetch, endCursor, hasNext, setParams, page]);

  const previous = useCallback(() => {
    if (refetch && hasPrevious) {
      const newPage = page - 1;
      const params = {
        before: newPage === 1 ? undefined : startCursor,
        last: newPage === 1 ? undefined : pageSize,
        after: undefined,
        first: newPage === 1 ? pageSize : undefined,
        page: newPage,
      };
      setParams(params);
      setPage(newPage);
    }
  }, [refetch, startCursor, hasPrevious, setParams, page]);

  useEffect(() => {
    setPage(urlPage || 1);
  }, [urlPage]);

  const resetPaginationParams = (otherParams?: Record<string, any>) => {
    setParams({
      ...INITIAL_PAGINATION_PARAMS,
      ...otherParams,
    });
  };

  return {
    count,
    hasNext,
    hasPrevious,
    page,
    nodes: Array.from(nodes.values()),
    next,
    previous,
    resetPaginationParams,
  };
}

export default usePagination;
