import styled from 'styled-components';

export const ActionBarStyled = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  z-index: 10;

  width: 100%;
  height: ${({ theme }) => theme.spacing[16]};
  padding: 0 ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[8]};

  background-color: ${({ theme }) => theme.colors.neutral[100]};

  border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  border-top-left-radius: ${({ theme }) => theme.spacing[2]};
  border-top-right-radius: ${({ theme }) => theme.spacing[2]};

  .action-bar-left {
    flex-grow: 1;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-left: ${({ theme }) => theme.spacing[4]};
    }
  }

  .action-bar-right > * {
    margin-left: ${({ theme }) => theme.spacing[2]};
  }
`;
