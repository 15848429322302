import React from 'react';
import { IntlShape } from 'react-intl';

import { Currency, FormattedDate } from '@src/components/i18n';
import { AttributesObject } from '@src/types/resourceList';
import { FinancingRequest } from '@src/types/models';
import { DownloadReceiptButton } from '../index';

export const getTableAttributes = (
  intl: IntlShape
): AttributesObject<FinancingRequest> => ({
  id: {
    name: intl.formatMessage({
      defaultMessage: 'ID de crédito',
      id: 'lfy2a6',
    }),
    type: 'STRING',
    render: ({ id }) => id,
  },
  paid: {
    name: intl.formatMessage({
      defaultMessage: 'Fecha de disposición',
      id: 'pWfBL4',
    }),
    type: 'DATE_TIME',
    render: ({ paidAt }) => <FormattedDate value={paidAt} />,
  },
  payOn: {
    name: intl.formatMessage({
      defaultMessage: 'Fecha de pago',
      id: 'lnTg/E',
    }),
    type: 'DATE_TIME',
    render: ({ repaidAt }) => <FormattedDate value={repaidAt} />,
  },
  owedAmount: {
    name: intl.formatMessage({
      defaultMessage: 'Monto solicitado',
      id: 'eP9g2T',
    }),
    type: 'STRING',
    render: ({ amountCents }) => (
      <Currency maximumFractionDigits={2} value={amountCents ?? 0} />
    ),
  },
  totalInterests: {
    name: intl.formatMessage({
      defaultMessage: 'Interés acumulado',
      id: 'jStRaY',
    }),
    type: 'STRING',
    render: ({ totalInterestAmountCents }) => (
      <Currency
        maximumFractionDigits={2}
        value={totalInterestAmountCents ?? 0}
      />
    ),
  },

  total: {
    name: intl.formatMessage({
      defaultMessage: 'Total pagado',
      id: 'ri85iV',
    }),
    type: 'STRING',
    render: ({ repaidAmountCents, repaidInterestCents }) => (
      <Currency
        maximumFractionDigits={2}
        value={(repaidAmountCents ?? 0) + (repaidInterestCents ?? 0)}
      />
    ),
  },
  download: {
    name: '',
    type: 'CUSTOM',
    render: ({ repaymentWalletEntries }: FinancingRequest) => (
      <DownloadReceiptButton walletEntries={repaymentWalletEntries} />
    ),
  },
});
