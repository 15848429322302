import { useMemo } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import { BANKS_QUERY } from '@src/graphql/queries/banks';

import { Bank } from '@src/types/models';

function useBanksQuery(): {
  loading: boolean;
  error?: ApolloError;
  data: {
    banks: Bank[];
  };
} {
  const { loading, error, data } = useQuery(BANKS_QUERY);
  const banks: Bank[] = useMemo(() => {
    if (!data?.banks?.edges) {
      return [];
    }
    return data.banks.edges.map((edge) => edge.node);
  }, [data]);

  return {
    error,
    loading,
    data: {
      banks,
    },
  };
}

export default useBanksQuery;
