import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import * as S from './Transaction.styles';
import { Card, Divider, Header, Text } from '@src/components/ui';
import { FlexColumnContainer, Grid } from '@src/components/shared/layouts';
import { FormattedDate, Currency } from '@src/components/i18n';
import TransactionStatusBadge from '@src/components/shared/TransactionStatusBadge';
import TransactionsIdentifier from '@src/components/shared/TransactionsIdentifier';
import useTransaction from '@src/hooks/useTransaction';
import Clabe from '@src/components/shared/Clabe';
import Link from '@src/components/shared/Link';
import messages from '@src/translations/messages/transactions';

import type { WalletEntry } from '@src/types/models';
import useModal from '@src/hooks/useModal';
import PaymentsReconciliationModal from '@src/components/shared/modals/PaymentsReconciliationModal';

type TransactionProps = {
  walletEntry: WalletEntry;
};

const KeyValue = ({ label, children }) => (
  <S.Row key={label}>
    <S.Label>{label}</S.Label>
    {children}
  </S.Row>
);

function Transaction({ walletEntry }: TransactionProps) {
  const {
    createdAt,
    amount,
    paymentType,
    description,
    trackingKey,
    status,
    recipient,
    sender,
    invoice,
    invoices,
    receiver,
    positive,
    emails,
    claimed,
    user,
    type,
  } = useTransaction(walletEntry);
  const intl = useIntl();
  const [seeAll, setSeeAll] = useState(false);
  const { open: openPaymentsReconciliation } = useModal(
    PaymentsReconciliationModal
  );

  const canSeeInvoiceDetails = !!invoice || type === 'ADVANCE_PAYMENT';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={canSeeInvoiceDetails ? 8 : 12}>
        <Card padding="8">
          <S.CardHeader>
            <Header as="h5">
              {intl.formatMessage({
                defaultMessage: 'Comprobante de transacción',
                id: 'f8yVwK',
              })}
            </Header>
            <TransactionStatusBadge status={status} claimed={claimed} />
          </S.CardHeader>

          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <KeyValue
                label={intl.formatMessage({
                  defaultMessage: 'Fecha de procesamiento',
                  id: 'B1KUjk',
                })}
              >
                <p>
                  {' '}
                  <FormattedDate value={createdAt} />
                </p>
              </KeyValue>
            </Grid>

            <Grid item xs={12} lg={4}>
              <KeyValue
                label={intl.formatMessage({
                  defaultMessage: 'Tipo',
                  id: 'wt+XcA',
                })}
              >
                <TransactionsIdentifier walletEntry={walletEntry} withLabel />
              </KeyValue>
            </Grid>

            {amount && (
              <Grid item xs={12} lg={4}>
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Monto',
                    id: 'Axixya',
                  })}
                >
                  <strong>
                    <Currency value={amount} />
                  </strong>
                </KeyValue>
              </Grid>
            )}

            {paymentType && messages[paymentType] && (
              <Grid item xs={12} lg={4}>
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Método de Pago',
                    id: 'Efd39f',
                  })}
                >
                  <p>{intl.formatMessage(messages[paymentType])}</p>
                </KeyValue>
              </Grid>
            )}

            {trackingKey && (
              <Grid item xs={12} lg={4}>
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Clave de rastreo',
                    id: '3P07MN',
                  })}
                >
                  <p>{trackingKey}</p>
                </KeyValue>
              </Grid>
            )}

            {description && (
              <Grid item xs={12} lg={4}>
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Concepto de pago',
                    id: 'z8Gzqo',
                  })}
                >
                  <p>{description}</p>
                </KeyValue>
              </Grid>
            )}
          </Grid>

          {sender && (
            <>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <S.Header>
                    <Header as="h5">
                      {intl.formatMessage({
                        defaultMessage: 'De',
                        id: 'Po4Dhk',
                      })}
                    </Header>
                  </S.Header>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Titular',
                      id: 'C2qvs5',
                    })}
                  >
                    <p>{sender?.name}</p>
                  </KeyValue>
                </Grid>

                {sender.clabe && (
                  <Grid item xs={12} lg={4}>
                    <KeyValue
                      label={intl.formatMessage({
                        defaultMessage: 'CLABE',
                        id: '56FzEv',
                      })}
                    >
                      <p>
                        <Clabe account={sender.clabe} />
                      </p>
                    </KeyValue>
                  </Grid>
                )}

                {sender?.rfc && (
                  <Grid item xs={12} lg={4}>
                    <KeyValue label="RFC">
                      <p>{sender?.rfc}</p>
                    </KeyValue>
                  </Grid>
                )}

                {sender.bankName && (
                  <Grid item xs={12} lg={4}>
                    <KeyValue
                      label={intl.formatMessage({
                        defaultMessage: 'Banco',
                        id: '2MvH8U',
                      })}
                    >
                      <p>{sender?.bankName}</p>
                    </KeyValue>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          {recipient && (
            <>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <S.Header>
                    <Header as="h5">
                      {intl.formatMessage({
                        defaultMessage: 'Beneficiario',
                        id: 'dtkaPZ',
                      })}
                    </Header>
                  </S.Header>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Titular',
                      id: 'C2qvs5',
                    })}
                  >
                    <p>{recipient?.name}</p>
                  </KeyValue>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <KeyValue label="CLABE">
                    <p>
                      {recipient?.clabe && <Clabe account={recipient?.clabe} />}
                    </p>
                  </KeyValue>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Banco',
                      id: '2MvH8U',
                    })}
                  >
                    <p>{recipient?.bankName}</p>
                  </KeyValue>
                </Grid>
              </Grid>
            </>
          )}

          {receiver && (
            <>
              <Divider />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <S.Header>
                    <Header as="h5">
                      {intl.formatMessage({
                        defaultMessage: 'Para',
                        id: 'elNXJ8',
                      })}
                    </Header>
                  </S.Header>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Titular',
                      id: 'C2qvs5',
                    })}
                  >
                    <p>{receiver?.name}</p>
                  </KeyValue>
                </Grid>

                {receiver?.rfc && (
                  <Grid item xs={12} lg={4}>
                    <KeyValue label="RFC">
                      <p>{receiver?.rfc}</p>
                    </KeyValue>
                  </Grid>
                )}

                <Grid item xs={12} lg={4}>
                  {receiver?.bankName && (
                    <KeyValue
                      label={intl.formatMessage({
                        defaultMessage: 'Banco',
                        id: '2MvH8U',
                      })}
                    >
                      <p>{receiver?.bankName}</p>
                    </KeyValue>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Card>
      </Grid>

      {canSeeInvoiceDetails && (
        <Grid item xs={12} lg={4}>
          <Card padding="6">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <strong>
                  {intl.formatMessage({
                    defaultMessage: 'Información general',
                    id: '+sT7oB',
                  })}
                </strong>
              </Grid>
              <Grid item xs={12}>
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'ID factura',
                    id: 'im1vUe',
                  })}
                >
                  {type !== 'ADVANCE_PAYMENT' && invoice?.id && (
                    <Link to={`/invoices/${invoice.id}`}>{invoice.id}</Link>
                  )}
                  {type === 'ADVANCE_PAYMENT' && (
                    <>
                      {invoices && invoices.length > 0 ? (
                        <FlexColumnContainer>
                          {invoices.map((invoice) => (
                            <Link
                              key={invoice.id}
                              to={`/invoices/${invoice.id}`}
                            >
                              {invoice.id}
                            </Link>
                          ))}
                        </FlexColumnContainer>
                      ) : (
                        <Text color="neutral" colorStep="600">
                          {intl.formatMessage({
                            id: 'OoKfyG',
                            defaultMessage: 'Sin factura',
                          })}
                          {walletEntry.status === 'PROCESSED' && (
                            <>
                              {' - '}

                              <S.LinkInvoiceButton
                                onClick={() =>
                                  openPaymentsReconciliation({ walletEntry })
                                }
                              >
                                {intl.formatMessage({
                                  id: 'axze5Y',
                                  defaultMessage: 'Ligar factura',
                                })}
                              </S.LinkInvoiceButton>
                            </>
                          )}
                        </Text>
                      )}
                    </>
                  )}
                </KeyValue>
              </Grid>
              {invoice && (
                <Grid item xs={12}>
                  <KeyValue
                    label={intl.formatMessage({
                      id: 'E6pIHW',
                      defaultMessage: 'Folio fiscal de factura',
                    })}
                  >
                    {type !== 'ADVANCE_PAYMENT' ? (
                      <span>{invoice?.uuid}</span>
                    ) : (
                      <FlexColumnContainer>
                        {invoices?.map((invoice) => (
                          <span key={invoice.id}>{invoice?.uuid}</span>
                        ))}
                      </FlexColumnContainer>
                    )}
                  </KeyValue>
                </Grid>
              )}
              {!positive && invoice?.emitterId && (
                <Grid item xs={12}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Proveedor',
                      id: 'RMvOYP',
                    })}
                  >
                    <Link to={`/suppliers/${invoice.emitterId}`}>
                      {invoice?.emitterTaxpayerName}
                    </Link>
                  </KeyValue>
                </Grid>
              )}
              {positive && invoice?.receiverId && (
                <Grid item xs={12}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Cliente',
                      id: 'DshuY+',
                    })}
                  >
                    <Link to={`/suppliers/${invoice.receiverId}`}>
                      {invoice?.receiverTaxpayerName}
                    </Link>
                  </KeyValue>
                </Grid>
              )}
              <Grid item xs={12}>
                <KeyValue
                  label={intl.formatMessage({
                    defaultMessage: 'Usuario',
                    id: 'OxzawJ',
                  })}
                >
                  <span>{user}</span>
                </KeyValue>
              </Grid>
              {emails.length > 0 && (
                <Grid item xs={12}>
                  <KeyValue
                    label={intl.formatMessage({
                      defaultMessage: 'Email de notificación',
                      id: 'EXetOG',
                    })}
                  >
                    {!seeAll && emails.length > 1 ? (
                      <div>
                        <S.Email href={`mailto:${emails[0]}`}>
                          {emails[0]}
                        </S.Email>
                        <S.ExpandButton
                          onClick={() => {
                            setSeeAll(true);
                          }}
                        >
                          {intl.formatMessage({
                            defaultMessage: 'Ver todos',
                            id: 'e48qL2',
                          })}
                        </S.ExpandButton>
                      </div>
                    ) : (
                      <div>
                        {emails.map((email, index) => (
                          <S.Email key={index} href={`mailto:${email}`}>
                            {email}
                          </S.Email>
                        ))}
                      </div>
                    )}
                  </KeyValue>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

export default Transaction;
