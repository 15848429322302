import React from 'react';
import { FormattedMessage } from 'react-intl';

import Currency from '@src/components/i18n/Currency';
import PaymentStatusBadge from '@src/components/shared/PaymentStatusBadge';
import InvoiceGlobalStatusBadge from '@src/components/shared/InvoiceGlobalStatusBadge';
import FormattedDate, {
  defaultTimeFormat,
} from '@src/components/i18n/FormattedDate';

import { InvoiceGlobalStatus, InvoicePaymentStatus } from '@src/types/enums';
import { AttributesObject } from '@src/types/resourceList';

const attributes: AttributesObject<{
  id: string;
  currency: string;
  createdAt: string;
  businessReceiver: {
    name: string;
  };
  expiryDate: string;
  globalStatus: InvoiceGlobalStatus;
  paymentStatus: InvoicePaymentStatus;
  total: number;
}> = {
  id: {
    name: <FormattedMessage defaultMessage="ID Factura" id="q4MR1Z" />,
    render: (datum: { id: string }) => datum.id,
  },
  createdAt: {
    name: <FormattedMessage defaultMessage="Fecha de creación" id="lUmO3u" />,
    render: (datum: { createdAt: any }) => (
      <FormattedDate value={datum.createdAt} {...defaultTimeFormat} />
    ),
  },
  businessReceiver: {
    name: <FormattedMessage defaultMessage="Cliente" id="DshuY+" />,
    filterable: true,
    render: (datum: { businessReceiver: { name: React.ReactNode } }) =>
      datum.businessReceiver.name,
  },
  globalStatus: {
    name: <FormattedMessage defaultMessage="Estatus de factura" id="9Xgy6/" />,
    filterable: true,
    render: (datum: {
      expiryDate: string;
      globalStatus: keyof typeof InvoiceGlobalStatus;
    }) => {
      return (
        <InvoiceGlobalStatusBadge
          status={datum.globalStatus}
          expiryDate={datum.expiryDate}
          receivable
        />
      );
    },
  },
  paymentStatus: {
    name: <FormattedMessage defaultMessage="Estatus de pago" id="/aIlDx" />,
    filterable: true,
    render: (datum: {
      id: string;
      paymentStatus: keyof typeof InvoicePaymentStatus | 'CLOSED';
    }) => <PaymentStatusBadge id={datum.id} status={datum.paymentStatus} />,
  },
  total: {
    name: <FormattedMessage defaultMessage="Total a cobrar" id="2sCUno" />,
    render: (datum: { total: any; currency: any }) => (
      <Currency value={datum.total} currency={datum.currency} />
    ),
  },
};

export default attributes;
