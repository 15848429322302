import styled from 'styled-components';

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const Container = styled.div`
  width: 35rem;
  max-width: 90vw;
`;
