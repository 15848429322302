import * as Types from '../../../../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { RecordNotificationInvoiceFragmentDoc } from '../components/RecordNotification/__graphql__/recordNotificationInvoice.fragment';
import { RecordNotificationUserFragmentDoc } from '../components/RecordNotification/__graphql__/recordNotificationUser.fragment';
import { RecordNotificationEventableFragmentDoc } from '../components/RecordNotification/__graphql__/recordNotificationEventable.fragment';
export type HistoricalRecordAccountPayableEventFragment = {
  __typename?: 'AccountPayableEvent';
  id: string;
  createdAt: any;
  invoice: {
    __typename?: 'Invoice';
    id: string;
    expiryDate?: any | null;
    paymentTerms?: number | null;
    cancelationReason?: string | null;
    currency: string;
  };
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    profileImage: string;
  } | null;
  eventable:
    | {
        __typename: 'CommentEvent';
        id: string;
        comment?: string | null;
        businessUsers?: Array<{
          __typename?: 'BusinessUser';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        }> | null;
      }
    | {
        __typename: 'CostCenterEvent';
        id: string;
        costCenter: { __typename?: 'CostCenter'; id: string; name: string };
      }
    | { __typename: 'InvoiceCreditNotePaymentEvent' }
    | {
        __typename: 'InvoiceManualPaymentEvent';
        id: string;
        amount?: any | null;
      }
    | { __typename: 'InvoiceReceivedEvent' }
    | {
        __typename: 'InvoiceSTPPaymentEvent';
        id: string;
        walletEntry: {
          __typename?: 'WalletEntry';
          id: string;
          amount: any;
          recipient?: {
            __typename?: 'BankingInfo';
            id: string;
            clabe: string;
            bankName: string;
            recipient: string;
          } | null;
          user: {
            __typename?: 'User';
            id: string;
            firstName: string;
            lastName: string;
          };
        };
      }
    | {
        __typename: 'InvoiceStatusEvent';
        id: string;
        status: Types.InvoiceApprovalStatusEnum;
        paymentTerms?: number | null;
        expiryDate?: any | null;
      };
};

export const HistoricalRecordAccountPayableEventFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HistoricalRecordAccountPayableEvent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AccountPayableEvent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecordNotificationInvoice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecordNotificationUser' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'eventable' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecordNotificationEventable' },
                },
              ],
            },
          },
        ],
      },
    },
    ...RecordNotificationInvoiceFragmentDoc.definitions,
    ...RecordNotificationUserFragmentDoc.definitions,
    ...RecordNotificationEventableFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  HistoricalRecordAccountPayableEventFragment,
  unknown
>;
