import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  > div {
    /* Shows only the first notification. */
    & ~ div {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing[48]};

  .resume-content {
    span {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: ${({ theme }) => theme.fontSize.lg};
    }
  }
`;
