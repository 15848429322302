import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const Controls = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing[4]};
  > * {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;
`;
