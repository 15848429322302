import useSettings from '../useSettings';
import { hasFeature } from '@src/utils/features';

import { FeatureName } from '@src/types/enums';

function useUserFeatures(features: Array<keyof typeof FeatureName>) {
  const currentUser = useSettings('currentUser');
  return hasFeature(features, currentUser);
}

export default useUserFeatures;
