import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './WithdrawalUnavailableModal.styles';
import { Header, Icon, Text } from '@src/components/ui';
import { CenteredFlexContainer, Spacer } from '@src/components/shared/layouts';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';

function WithdrawalUnavailableModal() {
  const intl = useIntl();
  const openCustomerSupportChat = useCustomerSupportChat();

  return (
    <S.Container>
      <Spacer />
      <CenteredFlexContainer>
        <Icon
          name="AlertTriangle"
          variant="solid"
          color="warning"
          colorStep="400"
        />
      </CenteredFlexContainer>
      <Spacer />
      <CenteredFlexContainer>
        <Header as="h4">
          {intl.formatMessage({
            id: 'IduxLA',
            defaultMessage: 'No es posible retirar tu saldo',
          })}
        </Header>
      </CenteredFlexContainer>
      <Spacer margin="3" />
      <Text colorStep="600" textAlign="center">
        {intl.formatMessage(
          {
            id: 'wrS94k',
            defaultMessage:
              'Por el momento, no puedes retirar tu saldo de Higo por tener uno o más créditos activos. Por favor {link} si quieres realizar un retiro.',
          },
          {
            link: (
              <S.Link href="#" onClick={openCustomerSupportChat}>
                {intl.formatMessage({
                  id: 'D+AY1e',
                  defaultMessage: 'contáctate con soporte',
                })}
              </S.Link>
            ),
          }
        )}
      </Text>
      <Spacer />
    </S.Container>
  );
}

WithdrawalUnavailableModal.displayName = 'WithdrawalUnavailableModal';

export default WithdrawalUnavailableModal;
