import React from 'react';

import Integrations from './Integrations';

import Permission from '@src/components/shared/Permission';
import useSettings from '@src/hooks/useSettings';

import { UserRole } from '@src/types/enums';

function IntegrationsContainer() {
  const { business } = useSettings();

  return (
    <Permission minimumRoles={[UserRole.ADMIN]} redirect>
      <Integrations business={business} />
    </Permission>
  );
}

export default IntegrationsContainer;
