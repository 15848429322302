import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import EmptyResults from '@src/components/shared/EmptyResults';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
} from '@src/components/ui';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import { Currency } from '@src/components/i18n';
import useSettings from '@src/hooks/useSettings';
import { useQuery } from '@apollo/client';
import FACTORING_OFFERS_QUERY, {
  FactoringOffersQueryReturn,
  FactoringOffersQueryVariables,
  FactoringOfferStatusEnum,
} from '@src/graphql/queries/factoringOffers';
import { DATE_FORMAT } from '@src/constants/date';

const Container = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;

function AcceptedOffers() {
  const intl = useIntl();
  const history = useHistory();

  const { business } = useSettings();
  const { data } = useQuery<
    FactoringOffersQueryReturn,
    FactoringOffersQueryVariables
  >(FACTORING_OFFERS_QUERY, {
    variables: {
      businessId: business?.id,
      status: FactoringOfferStatusEnum.ACCEPTED,
    },
    skip: !business?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  if (data?.factoringOffers.length === 0) {
    return (
      <EmptyResults
        label={intl.formatMessage({
          defaultMessage: 'Aún no tienes facturas adelantadas',
          id: 'wUDgxs',
        })}
        description={intl.formatMessage({
          defaultMessage:
            'Aquí verás todas las facturas que has adelantado en Higo.',
          id: 'Mr2IRr',
        })}
      />
    );
  }

  return (
    <Container>
      <Table>
        <TableHeader>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'No Folio',
              id: 'gdN/OU',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Fecha de factoraje',
              id: '7TPPsh',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Cliente',
              id: 'DshuY+',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Anticipo',
              id: 'ztnRJg',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Al vencimiento',
              id: 'U/EeE6',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Costo financiamiento',
              id: 'MRGlAW',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Saldo pendiente',
              id: 'h0TyH1',
            })}
          </TableHeaderCell>
        </TableHeader>
        <tbody>
          {data?.factoringOffers.map((offer) => (
            <SelectableTableRow
              onClick={() => {
                history.push(`/invoices/${offer.invoice.id}`);
              }}
              key={offer.id}
            >
              <TableCell>{offer.invoice.fiscalIdentifier || '-'}</TableCell>
              <TableCell>
                {offer.acceptedAt
                  ? format(new Date(offer.acceptedAt), DATE_FORMAT)
                  : '-'}
              </TableCell>
              <TableCell>{offer.invoice.businessReceiver?.name}</TableCell>
              <TableCell>
                <Currency value={offer.invoice.total || 0} />
              </TableCell>
              <TableCell>
                <Currency value={offer.invoice.total || 0} />
              </TableCell>
              <TableCell>
                <Currency value={offer.discountAmountCents} />
              </TableCell>
              <TableCell>
                <Currency value={offer.invoice.total || 0} />
              </TableCell>
            </SelectableTableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default AcceptedOffers;
