import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Label,
  Header,
  Divider,
  Alert,
  Button,
  Card,
} from '@src/components/ui';
import { CenteredFlexContainer, Spacer } from '@src/components/shared/layouts';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import useBanksQuery from '@src/hooks/useBanksQuery';
import Bank from '@src/components/shared/Bank';
import CLABEField from '@src/components/shared/react-hook-fields/CLABEField';
import { Currency } from '@src/components/i18n/Currency';
import { ReactComponent as Company } from './img/company.svg';
import {
  CREATE_BANKING_INFO_REQUEST_VALIDATION,
  CreateBankingInfoRequestValidationParams,
  CreateBankingInfoRequestValidationReturn,
} from '@src/graphql/mutations';
import {
  BANKING_INFO_FAILED_VALIDATION,
  CREATE_BANKING_INFO_REQUEST_VALIDATION_ACTION,
} from '@src/constants/events';
import { BANKING_INFO_REQUEST } from '@src/graphql/queries';
import SecurePaymentsNotification from '@src/components/shared/form/InvoicePaymentForm/components/SecurePaymentsNotification';

import type { BankingInfo, TaxPayerInfo } from '@src/types/models';
import { BankingInfoValidationStatus } from '@src/types/enums';

type RequestBankingInfoViewFormProps = {
  payeeTaxPayerInfo?: Pick<
    TaxPayerInfo,
    'id' | 'taxpayerName' | 'taxpayerIdentifier'
  >;
  payerTaxPayerInfo?: Pick<
    TaxPayerInfo,
    'id' | 'taxpayerName' | 'taxpayerIdentifier'
  >;
  totalInvoices?: number;
  totalAmount?: number;
  bankingInfoValidation?: {
    bankingInfo?: BankingInfo;
    validationStatus?: keyof typeof BankingInfoValidationStatus;
  };
};

export const RequestBankingInfoViewForm = ({
  payeeTaxPayerInfo,
  payerTaxPayerInfo,
  totalInvoices = 0,
  totalAmount = 0,
  bankingInfoValidation,
}: RequestBankingInfoViewFormProps) => {
  const intl = useIntl();
  const { toast } = useToast();
  const { token } = useParams<{ token: string }>();
  const { data: bankData } = useBanksQuery();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const [createBankingInfoRequest, { loading }] = useMutation<
    CreateBankingInfoRequestValidationReturn,
    CreateBankingInfoRequestValidationParams
  >(CREATE_BANKING_INFO_REQUEST_VALIDATION, {
    refetchQueries: [BANKING_INFO_REQUEST],
    awaitRefetchQueries: true,
    onError: () => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Hubo un problema al validar información',
          id: 'mgGk1z',
        })
      );
    },
  });

  const bankId = watch('bankId');
  const bank = bankData?.banks.find((bank) => bank.id === bankId);

  const handleCLABEBlur = (event) => {
    const value = event?.target?.value?.replace(/(\s_)/g, '').trim();
    if (value && value.length === 18) {
      trackEvent(BANKING_INFO_FAILED_VALIDATION, {
        type: errors?.clabe?.type,
        value,
      });
    }
  };

  const onSubmit = async (formData: { clabe: string; bankId: string }) => {
    await createBankingInfoRequest({
      variables: {
        token: token,
        bankingInfo: {
          bankId: formData.bankId,
          clabe: formData.clabe?.replace(/\s/g, ''),
          recipient: payeeTaxPayerInfo?.taxpayerName,
        },
      },
    });
    trackEvent(CREATE_BANKING_INFO_REQUEST_VALIDATION_ACTION);
  };

  return (
    <Card padding="8" depth="sm">
      <CenteredFlexContainer direction="column">
        <Spacer direction="vertical" margin="6" />
        <Company />
        <Spacer direction="vertical" margin="6" />
        <Header as="h5">{payerTaxPayerInfo?.taxpayerName}</Header>
        <Label size="lg" color="neutral" colorStep="600">
          {intl.formatMessage(
            {
              defaultMessage:
                'te ha enviado un pago de {invoiceCount} factura(s) a través de Higo por:',
              id: '5YfZwU',
            },
            {
              invoiceCount: totalInvoices,
            }
          )}
        </Label>
        <Spacer direction="vertical" margin="8" />
        <Header as="h3">
          <Currency value={totalAmount} />
        </Header>
      </CenteredFlexContainer>

      <Divider margin="8" />

      <Label size="lg" strong>
        {intl.formatMessage({
          defaultMessage: 'Ingresa la cuenta donde recibir tu pago',
          id: 'DFiZgJ',
        })}
      </Label>

      <Spacer direction="vertical" margin="6" />

      {bankingInfoValidation?.validationStatus ===
      BankingInfoValidationStatus.REJECTED ? (
        <Alert color="danger">
          <p>
            <strong>
              {intl.formatMessage(
                {
                  defaultMessage:
                    'No hemos podido verificar la cuenta {clabe}.',
                  id: '90e8nk',
                },
                {
                  clabe: bankingInfoValidation.bankingInfo?.clabe || '',
                }
              )}
            </strong>
            <span>
              {intl.formatMessage({
                defaultMessage:
                  ' Asegúrate de que el titular de la CLABE tenga el mismo RFC de tu cuenta.',
                id: 'RfnJUD',
              })}
            </span>
          </p>
        </Alert>
      ) : (
        <Alert>
          <Label size="sm" color="info" colorStep="500">
            {intl.formatMessage({
              defaultMessage:
                'Debido a que es el primer pago que recibes a través de Higo, validaremos que la cuenta pertenezca al mismo',
              id: 'mNQGdP',
            })}
            <strong> RFC ({payeeTaxPayerInfo?.taxpayerIdentifier})</strong>
            {intl.formatMessage({
              defaultMessage: ' de la empresa emisora de la(s) factura(s).',
              id: 'PMN5Rw',
            })}
          </Label>
        </Alert>
      )}

      <Spacer direction="vertical" margin="4" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <CLABEField
          control={control}
          onBankChange={(id) => setValue('bankId', id)}
          onBlur={handleCLABEBlur}
        />

        <Bank name={bank?.name} logoUrl={bank?.logoUrl} />

        <Spacer direction="vertical" margin="8" />
        <Button block type="submit" loading={loading}>
          {intl.formatMessage({
            defaultMessage: 'Validar y recibir pago',
            id: '/yXOAU',
          })}
        </Button>
      </form>

      <Spacer margin="12" />
      <SecurePaymentsNotification />
    </Card>
  );
};

export default RequestBankingInfoViewForm;
