import React, { useCallback, useMemo } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

import useSettings from '@src/hooks/useSettings';
import { INVOICES_RECEIVED_QUERY } from '@src/graphql/queries/invoicesReceivedSearch';
import { InvoiceApprovalStatus, InvoicePaymentStatus } from '@src/types/enums';
import {
  Inbox,
  Smile,
  Archive,
  InvoiceToPay,
} from '@src/components/ui/Icon/outline';
import ResultsSection from '@src/components/shared/GlobalSearch/components/ResultSection';
import InvoiceResult from '@src/components/shared/GlobalSearch/components/InvoiceResult';
import { trackEvent } from '@src/lib/analytics';
import { SEARCH_RESULT_CLICKED, SEARCH_TRIGGERED } from '@src/constants/events';

import type { Invoice } from '@src/types/models';

type useInvoiceResultsReturn = [
  (value: string) => void,
  {
    loading: boolean;
    error: ApolloError | undefined;
    component: React.ReactNode | undefined;
  }
];

function useInvoiceResults(): useInvoiceResultsReturn {
  const intl = useIntl();
  const business = useSettings('business');
  const [fetchInvoices, { loading, error, data, variables }] = useLazyQuery(
    INVOICES_RECEIVED_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        trackEvent(SEARCH_TRIGGERED, {
          resource: 'invoices',
          results: !!data?.invoicesReceived?.edges?.length,
          query: variables?.fiscalIdentifier,
        });
      },
    }
  );

  const invoices: {
    archive: Invoice[];
    inbox: Invoice[];
    pending: Invoice[];
    paid: Invoice[];
    length: number;
  } = useMemo(() => {
    const flattenInvoices = data?.invoicesReceived?.edges
      ? data.invoicesReceived.edges.map((edge) => edge.node)
      : [];
    const archive = flattenInvoices.filter((invoice) => invoice.archived);
    const inbox = flattenInvoices.filter(
      (invoice) =>
        invoice.approvalStatus === InvoiceApprovalStatus.PENDING &&
        !invoice.archived
    );
    const pending = flattenInvoices.filter(
      (invoice) =>
        invoice.approvalStatus === InvoiceApprovalStatus.APPROVED &&
        [InvoicePaymentStatus.PENDING, InvoicePaymentStatus.PARTIAL].includes(
          invoice.paymentStatus
        ) &&
        !invoice.archived
    );
    const paid = flattenInvoices.filter(
      (invoice) =>
        invoice.paymentStatus === InvoicePaymentStatus.PAID && !invoice.archived
    );
    return {
      archive,
      inbox,
      pending,
      paid,
      length: flattenInvoices.length,
    };
  }, [data]);

  const getInvoices = useCallback(
    debounce(
      (val) => {
        if (business?.id && val) {
          fetchInvoices({
            variables: {
              first: 100,
              fiscalIdentifier: val,
              businessId: business.id,
            },
          });
        }
      },
      500,
      {
        leading: true,
        trailing: false,
      }
    ),
    [business]
  );

  const handleResultClick = (location) => {
    trackEvent(SEARCH_RESULT_CLICKED, {
      resource: 'invoices',
      location,
    });
  };

  const component = useMemo(
    () =>
      !!invoices.length && (
        <>
          {!!invoices.inbox.length && (
            <ResultsSection section="Inbox" icon={<Inbox size={16} />}>
              {invoices.inbox.map((invoice) => (
                <InvoiceResult
                  onClick={() => {
                    handleResultClick('inbox');
                  }}
                  href={`/invoices/${invoice.id}`}
                  key={invoice.createdAt}
                  supplierName={invoice.emitterTaxpayerName}
                  total={invoice.total}
                  globalStatus={invoice.globalStatus}
                  paymentStatus={invoice.paymentStatus}
                  approvalStatus={invoice.approvalStatus}
                  expiryDate={invoice.expiryDate}
                />
              ))}
            </ResultsSection>
          )}
          {!!invoices.pending.length && (
            <ResultsSection
              section={intl.formatMessage({
                defaultMessage: 'Cuentas por pagar',
                id: 'y0s+Vr',
              })}
              subSection="Por pagar"
              icon={<InvoiceToPay size={16} />}
            >
              {invoices.pending.map((invoice) => (
                <InvoiceResult
                  onClick={() => {
                    handleResultClick('payable');
                  }}
                  href={`/invoices/${invoice.id}`}
                  key={invoice.createdAt}
                  supplierName={invoice.emitterTaxpayerName}
                  total={invoice.total}
                  globalStatus={invoice.globalStatus}
                  paymentStatus={invoice.paymentStatus}
                  approvalStatus={invoice.approvalStatus}
                  expiryDate={invoice.expiryDate}
                />
              ))}
            </ResultsSection>
          )}

          {!!invoices.paid.length && (
            <ResultsSection
              section={intl.formatMessage({
                defaultMessage: 'Cuentas por pagar',
                id: 'y0s+Vr',
              })}
              subSection={intl.formatMessage({
                defaultMessage: 'Pagadas',
                id: 'FFpbUV',
              })}
              icon={<Smile size={16} />}
            >
              {invoices.paid.map((invoice) => (
                <InvoiceResult
                  onClick={() => {
                    handleResultClick('paid');
                  }}
                  href={`/invoices/${invoice.id}`}
                  key={invoice.createdAt}
                  supplierName={invoice.emitterTaxpayerName}
                  total={invoice.total}
                  globalStatus={invoice.globalStatus}
                  paymentStatus={invoice.paymentStatus}
                  approvalStatus={invoice.approvalStatus}
                  expiryDate={invoice.expiryDate}
                />
              ))}
            </ResultsSection>
          )}
          {!!invoices.archive.length && (
            <ResultsSection
              section={intl.formatMessage({
                defaultMessage: 'Cuentas por pagar',
                id: 'y0s+Vr',
              })}
              subSection={intl.formatMessage({
                defaultMessage: 'Archivo',
                id: 'E4vK5X',
              })}
              icon={<Archive size={16} />}
            >
              {invoices.archive.map((invoice) => (
                <InvoiceResult
                  onClick={() => {
                    handleResultClick('archive');
                  }}
                  href={`/invoices/${invoice.id}`}
                  key={invoice.createdAt}
                  supplierName={invoice.emitterTaxpayerName}
                  total={invoice.total}
                  globalStatus={invoice.globalStatus}
                  paymentStatus={invoice.paymentStatus}
                  approvalStatus={invoice.approvalStatus}
                  expiryDate={invoice.expiryDate}
                />
              ))}
            </ResultsSection>
          )}
        </>
      ),
    [invoices, loading, data]
  );

  return [
    getInvoices,
    {
      loading,
      error,
      component,
    },
  ];
}

export default useInvoiceResults;
