import styled from 'styled-components';

export const Notification = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const EmptyState = styled.div`
  padding: ${({ theme }) => theme.spacing[20]} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
