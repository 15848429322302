import React from 'react';
import { useIntl, IntlShape } from 'react-intl';
import { differenceInDays } from 'date-fns';

import messages from './messages';
import receivableMessages from './receivableMessages';
import Tag from '@src/components/ui/Tag';

import { InvoiceGlobalStatus } from '@src/types/enums';

type InvoiceGlobalStatusBadgeProps = {
  expiryDate?: string;
  receivable?: boolean;
  status: keyof typeof InvoiceGlobalStatus;
};

export function optionsForInvoiceGlobalStatus(
  intl?: IntlShape
): Array<{ text: string; value: string }> {
  if (!intl || !intl.formatMessage) {
    return Object.values(InvoiceGlobalStatus).map((opt) => ({
      text: opt,
      value: opt,
    }));
  }

  return Object.values(InvoiceGlobalStatus).map((opt) => ({
    text: messages[opt] ? intl.formatMessage(messages[opt]) : opt,
    value: opt,
  }));
}

export function InvoiceGlobalStatusBadge(props: InvoiceGlobalStatusBadgeProps) {
  const { status, expiryDate, receivable } = props;
  const intl = useIntl();

  let color;
  let messageDate = '';

  const dueDate = expiryDate ? new Date(expiryDate) : new Date();
  const today = new Date();

  const difference = expiryDate ? differenceInDays(dueDate, today) : undefined;
  const finalDays = difference && difference < 0 ? difference * -1 : difference;

  switch (status) {
    case InvoiceGlobalStatus.PENDING_APPROVAL:
      color = 'warning';
      break;
    case InvoiceGlobalStatus.PENDING_PAYMENT:
      color = 'info';

      if (!expiryDate) {
        messageDate = '';
      } else {
        messageDate =
          difference === 0
            ? intl.formatMessage(messages.today)
            : intl.formatMessage(messages.inDays, {
                finalDays: finalDays,
              });
      }

      break;
    case InvoiceGlobalStatus.PAID:
      color = 'success';
      break;
    case InvoiceGlobalStatus.CLOSED:
      color = 'info';
      break;
    case InvoiceGlobalStatus.EXPIRED:
      color = 'danger';

      if (!expiryDate) {
        messageDate = '';
      } else {
        messageDate =
          difference === 0
            ? intl.formatMessage(messages.today)
            : intl.formatMessage(messages.days, {
                finalDays: finalDays,
              });
      }

      break;
    case InvoiceGlobalStatus.REJECTED:
      color = 'dark';
      break;
    case InvoiceGlobalStatus.FINANCING_REQUESTED:
      color = 'light';
      break;
    default:
      color = 'gray';
      break;
  }

  if (!expiryDate) {
    return (
      <Tag compact label={intl.formatMessage(messages[status])} color={color} />
    );
  }

  return (
    <Tag
      compact
      label={
        receivable
          ? `${intl.formatMessage(receivableMessages[status])} ${messageDate}`
          : `${intl.formatMessage(messages[status])} ${messageDate}`
      }
      color={color}
    />
  );
}

export default InvoiceGlobalStatusBadge;
