import React from 'react';
import { useIntl } from 'react-intl';
import { Currency } from '@src/components/i18n/Currency';
import {
  CenteredFlexContainer,
  FlexContainer,
  FlexRowContainer,
  Spacer,
} from '@src/components/shared/layouts';
import {
  Skeleton,
  Tooltip,
  Card,
  Header,
  Label,
  ProgressBar,
  Text,
} from '@src/components/ui';
import { Bar } from '@src/components/ui/ProgressBar/ProgressBar';

import useSettings from '@src/hooks/useSettings';
import CardHeader from './components/CardHeader';
import * as S from './CreditLineOverviewCard.styles';
import useCreditAccount from '@src/hooks/useCreditAccount';

interface CreditLineOverviewCardProps {
  theme?: 'primary' | 'neutral';
  cardTitle?: string;
  showCreditButton?: boolean;
}

function CreditLineOverviewCard({
  theme = 'primary',
  showCreditButton = true,
  cardTitle,
}: CreditLineOverviewCardProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const [creditAccount, { loading, error }] = useCreditAccount();
  const owedAmount = creditAccount?.owedAmountCents || 0;
  const creditLimit = creditAccount?.creditLimitCents || 0;
  const overdueAmount = creditAccount?.overdueAmountCents || 0;
  const owedPercentage = (owedAmount * 100) / creditLimit || 0;
  const overduePercentage = (overdueAmount * 100) / creditLimit;
  const owedInterestAmount = creditAccount?.owedInterestCents || 0;
  const owedTotal = creditAccount?.owedTotal || 0;
  const selectedTheme = S.cardThemes[theme];

  if (error) {
    return (
      <Card
        backgroundColor={selectedTheme.card.backgroundColor}
        backgroundColorStep={selectedTheme.card.backgroundColorStep}
        fullHeight
      >
        <CardHeader
          isOverdue={!!overdueAmount}
          theme={theme}
          cardTitle={cardTitle}
          showCreditButton={showCreditButton}
        />
        <S.Body>
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Label
              size="lg"
              strong
              color={selectedTheme.errorTitle.color}
              colorStep={selectedTheme.errorTitle.colorStep}
            >
              {intl.formatMessage({
                defaultMessage: 'La información no está disponible',
                id: 'ev3mC6',
              })}
            </Label>
            <Label
              size="md"
              color={selectedTheme.errorMessage.color}
              colorStep={selectedTheme.errorMessage.colorStep}
            >
              {intl.formatMessage({
                defaultMessage:
                  'Por favor, prueba volviendo a cargar la página',
                id: 'YgaISp',
              })}
            </Label>
          </FlexContainer>
        </S.Body>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card
        backgroundColor={selectedTheme.card.backgroundColor}
        backgroundColorStep={selectedTheme.card.backgroundColorStep}
        fullHeight
        data-testid="pay-financing-credit-loading"
      >
        <CardHeader
          isOverdue={!!overdueAmount}
          theme={theme}
          cardTitle={cardTitle}
          showCreditButton={showCreditButton}
        />
        <S.Body>
          <S.SkeletonContainer>
            <Skeleton
              backgroundColor={selectedTheme.skeleton.backgroundColor}
              backgroundColorStep={selectedTheme.skeleton.backgroundColorStep}
              backgroundOpacity={selectedTheme.skeleton.backgroundOpacity}
            />
            <Spacer margin="1" direction="vertical" />
            <Skeleton
              backgroundColor={selectedTheme.skeleton.backgroundColor}
              backgroundColorStep={selectedTheme.skeleton.backgroundColorStep}
              backgroundOpacity={selectedTheme.skeleton.backgroundOpacity}
            />
          </S.SkeletonContainer>
          <Spacer margin="3" direction="vertical" />
          <Skeleton
            backgroundColor={selectedTheme.skeleton.backgroundColor}
            backgroundColorStep={selectedTheme.skeleton.backgroundColorStep}
            backgroundOpacity={selectedTheme.skeleton.backgroundOpacity}
          />
          <Spacer margin="3" direction="vertical" />
          <Skeleton
            backgroundColor={selectedTheme.skeleton.backgroundColor}
            backgroundColorStep={selectedTheme.skeleton.backgroundColorStep}
            backgroundOpacity={selectedTheme.skeleton.backgroundOpacity}
          />
        </S.Body>
      </Card>
    );
  }

  return (
    <Card
      backgroundColor={selectedTheme.card.backgroundColor}
      backgroundColorStep={selectedTheme.card.backgroundColorStep}
      fullHeight
    >
      <CardHeader
        isOverdue={!!overdueAmount}
        theme={theme}
        cardTitle={cardTitle}
        showCreditButton={showCreditButton}
      />
      <S.Body>
        <FlexContainer justifyContent="space-between" alignItems="flex-end">
          <Header
            as="h3"
            color={selectedTheme.owedAmount.color}
            colorStep={selectedTheme.owedAmount.colorStep}
          >
            <Currency business={business} value={owedAmount} />
          </Header>
          <Label
            color={selectedTheme.availableAmount.color}
            colorStep={selectedTheme.availableAmount.colorStep}
          >
            <Text
              color={selectedTheme.creditDetails.color}
              colorStep={selectedTheme.creditDetails.colorStep}
            >
              {intl.formatMessage({
                defaultMessage: 'Disponible',
                id: '7Ba81g',
              })}{' '}
              <Currency business={business} value={creditLimit - owedAmount} />
            </Text>
          </Label>
        </FlexContainer>
        <Spacer margin="4" />
        <ProgressBar
          backgroundColor={selectedTheme.progressBar.backgroundColor}
          backgroundColorStep={selectedTheme.progressBar.backgroundColorStep}
        >
          <Bar
            percentage={owedPercentage > 100 ? 100 : owedPercentage}
            backgroundColor={selectedTheme.bar.backgroundColor}
            backgroundColorStep={selectedTheme.bar.backgroundColorStep}
          />
          {!!overdueAmount && (
            <Tooltip
              id="overdue"
              titleElement={
                <span>
                  <span>
                    {intl.formatMessage({
                      defaultMessage: 'Vencido: ',
                      id: 'Wy3IKC',
                    })}
                  </span>
                  <Currency business={business} value={overdueAmount} />
                </span>
              }
              place="bottom"
              offset={{ top: 10 }}
            >
              <Bar
                percentage={overduePercentage > 100 ? 100 : overduePercentage}
                backgroundColor="danger"
                backgroundColorStep="400"
                delay={50}
              />
            </Tooltip>
          )}
        </ProgressBar>
        <Spacer margin="3" />
        <FlexRowContainer>
          <CenteredFlexContainer>
            <Text
              color={selectedTheme.creditDetails.color}
              colorStep={selectedTheme.creditDetails.colorStep}
              small
            >
              {intl.formatMessage({
                defaultMessage: 'Interés acum.:',
                id: 'T9052M',
              })}
            </Text>
            <Spacer margin="1" direction="horizontal" />
            <Text
              color={selectedTheme.creditDetails.color}
              colorStep={selectedTheme.creditDetails.colorStep}
            >
              <Currency business={business} value={owedInterestAmount} />
            </Text>
            <Spacer margin="8" direction="horizontal" />
            <Text
              color={selectedTheme.creditDetails.color}
              colorStep={selectedTheme.creditDetails.colorStep}
              small
            >
              {intl.formatMessage({
                defaultMessage: 'Deuda total:',
                id: 'Zhj+7V',
              })}
            </Text>
            <Spacer margin="1" direction="horizontal" />
            <Text
              color={selectedTheme.creditDetails.color}
              colorStep={selectedTheme.creditDetails.colorStep}
            >
              <Currency business={business} value={owedTotal} />
            </Text>
          </CenteredFlexContainer>
        </FlexRowContainer>
      </S.Body>
    </Card>
  );
}

export default CreditLineOverviewCard;
