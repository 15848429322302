import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import usePagination from './usePagination';
import PROVIDER_RELATIONSHIPS_QUERY, {
  ProviderRelationshipsQueryReturn,
  ProviderRelationshipsQueryVariables,
} from '@src/graphql/queries/providerRelationships';

function useSupplierRelationshipsQuery({
  variables,
  skip,
  fetchPolicy,
}: QueryHookOptions<ProviderRelationshipsQueryVariables>) {
  const { data, loading, error, refetch } = useQuery<
    ProviderRelationshipsQueryReturn,
    ProviderRelationshipsQueryVariables
  >(PROVIDER_RELATIONSHIPS_QUERY, {
    variables: {
      businessId: variables?.businessId,
      sortBy: { field: 'CREATED_AT', direction: 'DESC' },
      ...variables,
    },
    skip: skip || !variables?.businessId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: fetchPolicy || 'cache-and-network',
  });

  const { count, hasNext, hasPrevious, next, previous, page } = usePagination(
    data?.providerRelationships,
    refetch,
    variables?.first || variables?.last,
    variables?.page
  );

  const supplierRelationships = useMemo(
    () => data?.providerRelationships?.edges?.map((edge) => edge.node) || [],
    [data]
  );

  return {
    data: supplierRelationships,
    loading: !data && loading,
    error,
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    refetch,
  };
}

export default useSupplierRelationshipsQuery;
