import styled from 'styled-components';

import Card from '@src/components/ui/Card';
import { CardProps } from '@src/components/ui/Card/types';

export const Container = styled.div`
  min-width: 40vw;
  h5 {
    text-align: left;
  }
  button {
    width: 100%;
  }
`;

export const Subtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.neutral[500]};
`;
export const StyledCard = styled(Card)<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing[2]};

  > * {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }

  .action-title {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .instructions {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }
`;

export const Notification = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;
