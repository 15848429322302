import React from 'react';
import { useIntl } from 'react-intl';

import PaymentsReconciliationModal from '@src/components/shared/modals/PaymentsReconciliationModal';
import { Button } from '@src/components/ui';
import {
  ENTRY_INVOICE_PAYMENT_ENTRY,
  ENTRY_HIGO_PAYMENT_SENT_ENTRY,
  ENTRY_HIGO_PAYMENT_RECEIVED_ENTRY,
  ENTRY_REFUND_ENTRY,
  ENTRY_BANKING_TRANSACTION_ENTRY,
} from '@src/constants/transactions';
import useModal from '@src/hooks/useModal';
import type { WalletEntry } from '@src/types/models';

interface TransactionInvoiceProps {
  walletEntry: WalletEntry;
}

function TransactionInvoice({ walletEntry }: TransactionInvoiceProps) {
  const intl = useIntl();
  const { open: openPaymentsReconciliationModal } = useModal(
    PaymentsReconciliationModal
  );
  if (
    walletEntry.entryable.__typename === ENTRY_INVOICE_PAYMENT_ENTRY ||
    walletEntry.entryable.__typename === ENTRY_HIGO_PAYMENT_SENT_ENTRY ||
    (walletEntry.entryable.__typename === ENTRY_HIGO_PAYMENT_RECEIVED_ENTRY &&
      walletEntry.entryable.invoicePayment)
  ) {
    return <span>{walletEntry.entryable?.invoicePayment?.invoice.id}</span>;
  }

  if (
    walletEntry.entryable.__typename === ENTRY_REFUND_ENTRY &&
    walletEntry?.entryable?.refundable?.invoice
  ) {
    return <span>{walletEntry.entryable.refundable.invoice.id}</span>;
  }

  if (
    walletEntry.entryable.__typename === ENTRY_BANKING_TRANSACTION_ENTRY &&
    walletEntry.entryable.invoicePayments
  ) {
    if (walletEntry.status === 'PROCESSED') {
      if (walletEntry.entryable.invoicePayments.length === 0) {
        return (
          <Button
            compact={true}
            variant="outlined"
            color="neutral"
            onClick={(e) => {
              openPaymentsReconciliationModal({ walletEntry });
              e.stopPropagation();
            }}
          >
            {intl.formatMessage({
              id: 'axze5Y',
              defaultMessage: 'Ligar factura',
            })}
          </Button>
        );
      }
      if (walletEntry.entryable.invoicePayments.length > 1) {
        return (
          <span>
            {intl.formatMessage(
              {
                id: 'ZEiAiU',
                defaultMessage: '{invoices} factura(s) ligadas',
              },
              { invoices: walletEntry.entryable.invoicePayments.length }
            )}
          </span>
        );
      }
      if (walletEntry.entryable.invoicePayments.length === 1) {
        return (
          <span>{walletEntry.entryable.invoicePayments[0].invoiceId}</span>
        );
      }
    }
  }

  return <span>-</span>;
}

export default TransactionInvoice;
