import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import useSettings from '@src/hooks/useSettings';
import useQueryParams from '@src/hooks/useQueryParams';
import NotFound from '@src/components/pages/Error404';
import HigoSuite from '@src/components/pages/HigoSuite';
import CreateBusiness from '@src/components/pages/CreateBusiness';
import Inbox from '@src/components/pages/Inbox';
import Contacts from '@src/components/pages/Contacts';
import BankingInformation from '@src/components/pages/BankingInformation';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';

// Resource routes
import AccountsPayableList from '@src/components/pages/AccountsPayable';
import AccountsReceivableList from '@src/components/pages/AccountsReceivable';
import CreditNoteView from '@src/components/pages/CreditNoteView';
import InvoiceView from '@src/components/pages/InvoiceView';
import FactoringOffers from '@src/components/pages/FactoringOffers';
import RequestFactoring from '@src/components/pages/RequestFactoring';
import PaymentRequests from '@src/components/pages/PaymentRequests';
import SupplierView from '@src/components/pages/SupplierView';
import TransactionsList from '@src/components/pages/TransactionList';
import TransactionsView from '@src/components/pages/TransactionView';
import WalletView from '@src/components/pages/WalletView';
// Public routes
import BusinessUserInvitation from '@src/components/pages/BusinessUserInvitation';
import CreateUser from '@src/components/pages/CreateUser/CreateUser';
import Error403 from '@src/components/pages/Error403';
import Error500 from '@src/components/pages/Error500/Error500';
import PasswordReset from '@src/components/pages/PasswordReset';
import UpdatePassword from '@src/components/pages/UpdatePassword';
import InvalidToken from '@src/components/pages/InvalidToken';
// Payment routes
import InvoicePayment from '@src/components/pages/InvoicePayment';
import PaymentWithoutInvoice from '@src/components/pages/PaymentWithoutInvoice';
import Login from '@src/components/pages/Login';
import RequestBankingInfo from '@src/components/pages/RequestBankingInfoView';
import RequestFinancing from '@src/components/pages/RequestFinancing';
import SelectProviders from '@src/components/pages/SelectProviders';
import AuthedRoute from '@src/components/router/AuthedRoute';
import UnauthedRoute from '@src/components/router/UnauthedRoute';
// Setting routes
import BusinessSettings from '@src/components/pages/BusinessSettings';
import Home from '@src/components/pages/Home';
import Profile from '@src/components/pages/Profile';
import Integrations from '@src/components/pages/Integrations';
import IntegrationsMenu from '@src/components/pages/Integrations/IntegrationsMenu';
import Users from '@src/components/pages/Users';
import UsersEdit from '@src/components/pages/UsersEdit';
import useErrorReporter from '@src/hooks/useErrorReporter';
import { BusinessUserValidationStatus } from '@src/types/enums';
import Credit from '@src/components/pages/Credit';
import AcceptBusinessUserInvitation from '@src/components/pages/AcceptBusinessUserInvitation';

export const PublicRoutes = () => (
  <Switch>
    <UnauthedRoute path="/p/login" exact component={Login} />
    <Route
      path="/p/user-invitation/:invitationId"
      exact
      component={BusinessUserInvitation}
    />
    <Route path="/p/sign-up" exact component={CreateUser} />
    <Route path="/p/403" exact component={Error403} />
    <Route path="/p/invalid_token" exact component={InvalidToken} />
    <Route
      path="/p/request_banking_info/:token"
      exact
      component={RequestBankingInfo}
    />
    <Route path="/p/500" exact component={Error500} />
    <Route path="/p/passwords/reset" exact component={PasswordReset} />
    <Route path="/p/passwords/:token" exact component={UpdatePassword} />
    <Route component={NotFound} />
  </Switch>
);

export const GeneralRoutes = () => {
  const { business, currentUser } = useSettings();
  const { search } = useLocation();
  const [query] = useQueryParams();
  const errorReporter = useErrorReporter();
  const hasAutomaticFinancingFunds = useFeatureFlag(
    FeatureFlag.AUTOMATIC_FINANCING_FUND
  );

  let Onboarding;
  const shouldOnboard = currentUser && !business;
  const isPayeeFlow =
    (!currentUser?.businessUserValidation && !business && query?.token) ||
    currentUser?.businessUserValidation?.status ===
      BusinessUserValidationStatus.PROCESSING;

  if (isPayeeFlow) {
    Onboarding = BankingInformation;
  } else if (!business) {
    Onboarding = CreateBusiness;
  }

  if (business && !business.hasTaxPayerInfo) {
    errorReporter('User has business without taxpayerinfo', {
      businessId: business.id,
    });
  }

  return (
    <Switch>
      <Redirect from="/login" to="/p/login" />

      {/* No navbar */}
      {/* Payment routes */}
      <AuthedRoute path="/invoice-payments" exact component={InvoicePayment} />
      <AuthedRoute path="/payments" exact component={PaymentWithoutInvoice} />

      {/* Setting routes */}
      <AuthedRoute
        exact
        path="/settings/business"
        component={BusinessSettings}
        showNavbar
      />
      <AuthedRoute exact path="/settings/home" component={Home} showNavbar />
      <AuthedRoute
        exact
        path="/settings/profile"
        component={Profile}
        showNavbar
      />
      <AuthedRoute exact path="/settings/users" component={Users} showNavbar />
      <AuthedRoute
        exact
        path="/settings/integrations"
        component={Integrations}
        showNavbar
      />
      <AuthedRoute
        exact
        path="/settings/integrations/menu"
        component={IntegrationsMenu}
        showNavbar
      />
      <AuthedRoute
        exact
        path="/business_users/:businessUserId/edit"
        component={UsersEdit}
        showNavbar
      />

      {/* Onboarding */}
      {shouldOnboard && (
        <>
          <Redirect to={`/onboarding${search}`} />
          <AuthedRoute exact path="/onboarding" component={Onboarding} />
        </>
      )}
      <AuthedRoute path="/onboarding/providers" component={SelectProviders} />

      {/* Navbar */}
      <AuthedRoute path="/contacts" component={Contacts} showNavbar />
      <AuthedRoute path="/higo_suite" component={HigoSuite} showNavbar />
      <AuthedRoute path="/inbox" component={Inbox} showNavbar />
      {/* Resources */}
      <AuthedRoute
        exact
        showNavbar
        path="/accounts_payable"
        component={AccountsPayableList}
      />
      <AuthedRoute
        exact
        showNavbar
        path="/accounts_receivable"
        component={AccountsReceivableList}
      />
      <AuthedRoute
        exact
        showNavbar
        path="/credit_notes/:invoiceId"
        component={CreditNoteView}
      />
      <AuthedRoute
        exact
        showNavbar
        path="/invoices/:invoiceId"
        component={InvoiceView}
      />
      <AuthedRoute
        exact
        showNavbar
        path="/suppliers/:suppliers_id"
        component={SupplierView}
      />
      <AuthedRoute
        exact
        showNavbar
        path="/transactions"
        component={TransactionsList}
      />
      <AuthedRoute
        exact
        showNavbar
        path="/transactions/:transactions_id"
        component={TransactionsView}
      />
      {!hasAutomaticFinancingFunds && (
        <AuthedRoute
          exact
          path="/request-financing"
          component={RequestFinancing}
        />
      )}
      <AuthedRoute
        exact
        showNavbar
        path="/factoring_offers"
        component={FactoringOffers}
      />
      <AuthedRoute
        path="/request-factoring"
        exact
        component={RequestFactoring}
      />
      <AuthedRoute exact path="/payment_requests" component={PaymentRequests} />
      <AuthedRoute showNavbar path="/wallet" component={WalletView} />
      <AuthedRoute path="/credit" exact showNavbar component={Credit} />

      <AuthedRoute
        path="/accept-user-invitation/:invitationId"
        exact
        component={AcceptBusinessUserInvitation}
      />
      <Redirect from="/" to={`/wallet${search}`} />
      <Route component={NotFound} />
    </Switch>
  );
};
