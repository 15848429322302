import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    defaultMessage: 'Error al cargar los datos',
    id: 'yM0cd/',
  },
  deleteSupplierModalTitle: {
    defaultMessage: 'Eliminar proveedor',
    id: '9lwLJU',
  },
  deleteSupplierModalText: {
    defaultMessage:
      'Se eliminará de tu lista de proveedores y no podrás ver sus productos.',
    id: 'lR1KAu',
  },
  deleteSupplierModalConfirmText: {
    defaultMessage: 'Eliminar',
    id: 'zKGHNB',
  },
  deleteSupplierSuccessTitle: {
    defaultMessage: 'Proveedor eliminado',
    id: 'bne3Eo',
  },
  deleteSupplierSuccessMessage: {
    defaultMessage: 'Se ha eliminado un proveedor de tu lista.',
    id: '3zXdpY',
  },
  deleteSupplierErrorTitle: {
    defaultMessage: 'Error al eliminar proveedor',
    id: 'BBbdsg',
  },
  deleteProviderContactSuccessTitle: {
    defaultMessage: 'Contacto eliminado',
    id: '7Oj8VK',
  },
  deleteProviderContactMessage: {
    defaultMessage: 'Se ha eliminado este contacto',
    id: 'ZIR/w1',
  },
  deleteProviderContactError: {
    defaultMessage: 'Ha ocurrido un problema al eliminar este contacto',
    id: 'eso6L/',
  },
  updateMainContactProviderContactSuccessTitle: {
    defaultMessage: 'Contacto principal selecccionado',
    id: 'OJQUv0',
  },
  updateMainContactProviderContactMessage: {
    defaultMessage: 'Hemos actualizado tu contacto principal',
    id: 'NoOJq9',
  },
  updateMainContactProviderContactError: {
    defaultMessage: 'Ha ocurrido un error al actualizar tu contacto principal',
    id: 'LHKXJq',
  },
  noInfoContact: {
    defaultMessage: 'Sin información',
    id: 'ulWfWZ',
  },
});
