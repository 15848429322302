import React, { forwardRef } from 'react';
import styled, { Color, ColorStep } from 'styled-components';

interface SVGProps {
  $color?: Color;
  $colorStep?: ColorStep;
}

export const SVG = styled.svg<SVGProps>`
  fill: ${({ theme, $color, $colorStep }) =>
    $color ? theme.colors[$color || 'neutral'][$colorStep || 900] : 'inherit'};
`;

interface LogoProps {
  variant?: 'logotype' | 'isotype';
  color?: Color;
  colorStep?: ColorStep;
  size?: number;
  monochrome?: boolean;
}

function Logo(
  { variant = 'logotype', color, colorStep, size = 24, monochrome }: LogoProps,
  ref
) {
  if (variant === 'isotype') {
    return (
      <SVG
        ref={ref}
        width={size}
        height={size}
        $color={color}
        $colorStep={colorStep}
        viewBox="0 0 31 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={monochrome ? color : '#82FFD9'}
          d="M24.5763 21.2881C24.5763 20.4874 24.8642 19.7954 25.4402 19.212C26.0161 18.6286 26.7059 18.3369 27.5095 18.3369C28.3264 18.3369 29.0228 18.6286 29.5987 19.212C30.1746 19.782 30.4626 20.4807 30.4626 21.3081C30.4626 22.1356 30.1746 22.8418 29.5987 23.4251C29.036 24.0085 28.3462 24.3002 27.5293 24.3002C26.7125 24.3002 26.0153 24.0085 25.4394 23.4251C24.8642 22.8418 24.5763 22.1289 24.5763 21.2881Z"
        />
        <path d="M0.5 13.5906C0.5 10.6938 1.5086 8.22934 3.52579 6.19718C5.40562 4.30342 10.1871 0.96758 10.8277 0.522603C10.871 0.492839 10.9268 0.492095 10.9709 0.522603C11.6137 0.966836 16.4201 4.31086 18.3132 6.21727C20.3171 8.26357 21.3191 10.7757 21.3191 13.7551C21.3191 16.762 20.3105 19.2816 18.2933 21.3137C16.2629 23.3318 13.7719 24.3415 10.8196 24.3415C7.89443 24.3415 5.44309 23.3117 3.46629 21.252C1.48803 19.2198 0.5 16.666 0.5 13.5906ZM5.10811 13.6725C5.10811 15.6771 5.62894 17.2628 6.67059 18.4303C7.73943 19.6112 9.14838 20.2013 10.8982 20.2013C12.6612 20.2013 14.0709 19.6179 15.1258 18.4504C16.1814 17.2837 16.7088 15.7247 16.7088 13.7752C16.7088 11.8256 16.1814 10.2675 15.1258 9.09995C14.0569 7.91904 10.9085 5.69565 10.9085 5.69565C10.9085 5.69565 7.77909 7.91904 6.71099 9.09995C5.64289 10.2816 5.10811 11.8055 5.10811 13.6725Z" />
      </SVG>
    );
  }

  return (
    <SVG
      ref={ref}
      width={size * 2}
      height={size}
      $color={color}
      $colorStep={colorStep}
      viewBox="0 0 57 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.9199 3.90791C15.9199 3.41232 16.1042 2.98387 16.4749 2.62254C16.8456 2.26122 17.2892 2.08154 17.8057 2.08154C18.3303 2.08154 18.78 2.26122 19.1487 2.62254C19.5193 2.97597 19.7037 3.40838 19.7037 3.91976C19.7037 4.43114 19.5193 4.86947 19.1487 5.22882C18.7861 5.59015 18.3425 5.76982 17.8179 5.76982C17.3176 5.76982 16.8395 5.58027 16.483 5.23869C16.1083 4.87737 15.9199 4.43312 15.9199 3.90791ZM19.2661 9.00792V21.2752H16.3595V9.00792H19.2661ZM19.3026 9.00792V21.2752H16.325V9.00792H19.3026Z" />
      <path d="M13.4314 15.1381V21.2708H10.4559V15.1381C10.4518 13.9594 10.1115 13.0136 9.43502 12.3048C8.74633 11.5841 7.83686 11.2228 6.70862 11.2228C5.59862 11.2228 4.69725 11.5841 4.00856 12.3048C3.33203 13.0136 2.98769 13.9199 2.97553 15.0315C2.97351 15.0532 2.97351 15.0769 2.97351 15.1006C2.97351 15.1105 2.97553 15.1223 2.97553 15.1322V21.2708H0V0H2.97756V9.71826C4.06325 9.01535 5.30694 8.66587 6.71065 8.66587C8.58834 8.66587 10.1824 9.29178 11.4909 10.5436C12.7792 11.7914 13.4254 13.3236 13.4294 15.1401H13.4314V15.1381Z" />
      <path
        fill={monochrome ? color : '#82FFD9'}
        d="M53.2168 19.7609C53.2168 19.2654 53.4011 18.8369 53.7718 18.4756C54.1425 18.1142 54.5861 17.9346 55.1026 17.9346C55.6272 17.9346 56.0769 18.1142 56.4455 18.4756C56.8162 18.829 57.0005 19.2614 57.0005 19.7728C57.0005 20.2842 56.8162 20.7225 56.4455 21.0818C56.0829 21.4432 55.6393 21.6229 55.1147 21.6229C54.6144 21.6229 54.1364 21.4333 53.7799 21.0917C53.4052 20.7304 53.2168 20.2861 53.2168 19.7609Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6208 15.1363V8.66406H32.6472V9.72237C31.5575 9.01552 30.3097 8.66406 28.9 8.66406C27.0304 8.66406 25.4464 9.28602 24.144 10.5279C22.8436 11.7699 22.1934 13.2764 22.1934 15.0475C22.1934 16.9291 22.8314 18.4889 24.1055 19.7328C25.3796 20.9925 26.9615 21.6204 28.8473 21.6204C30.2834 21.6204 31.5494 21.2709 32.6472 20.568V23.3895C32.6472 24.5821 32.3069 25.5338 31.6284 26.2485C30.9478 26.9613 30.0383 27.3187 28.902 27.3187C27.7738 27.3187 26.8643 26.9574 26.1756 26.2347C25.5132 25.5298 25.179 24.5762 25.1689 23.3698H22.1994C22.2197 25.21 22.8557 26.7382 24.1095 27.9604C25.3836 29.2201 26.9656 29.8499 28.8514 29.8499C30.7554 29.8499 32.3616 29.2319 33.6701 27.9979C34.9705 26.756 35.6228 25.2159 35.6228 23.3757V23.3698H35.6268V15.1363H35.6208ZM32.6472 15.164C32.6472 16.3546 32.3069 17.3082 31.6284 18.021C30.9478 18.7357 30.0383 19.0931 28.902 19.0931C27.7738 19.0931 26.8643 18.7298 26.1756 18.0091C25.5031 17.2964 25.1689 16.3269 25.1689 15.1008C25.1689 13.9595 25.5112 13.0276 26.2019 12.305C26.8906 11.5843 27.792 11.223 28.902 11.223C30.0302 11.223 30.9397 11.5843 31.6284 12.305C32.3029 13.0138 32.6432 13.9576 32.6472 15.1383V15.164Z"
      />
      <path d="M37.7344 15.5888C37.7344 13.796 38.3825 12.2717 39.6789 11.014C40.7849 9.94188 43.4545 8.12143 44.2141 7.61005C44.3377 7.52712 44.5017 7.52712 44.6253 7.61005C45.3889 8.12143 48.0728 9.9478 49.1868 11.0278C50.4751 12.2934 51.1192 13.8493 51.1192 15.6915C51.1192 17.5514 50.471 19.1112 49.1727 20.369C47.8662 21.6188 46.264 22.2427 44.366 22.2427C42.4843 22.2427 40.9084 21.605 39.6364 20.3314C38.3684 19.0717 37.7344 17.4922 37.7344 15.5888ZM40.6978 15.6401C40.6978 16.8801 41.032 17.8614 41.7024 18.5841C42.3891 19.3146 43.2965 19.6799 44.4207 19.6799C45.555 19.6799 46.4604 19.3185 47.139 18.5959C47.8176 17.8733 48.1579 16.9097 48.1579 15.7033C48.1579 14.4969 47.8176 13.5334 47.139 12.8108C46.5698 12.2066 45.0851 11.1581 44.5909 10.8166C44.4936 10.7494 44.364 10.7494 44.2647 10.8166C43.7725 11.1601 42.2959 12.2066 41.7288 12.8108C41.0401 13.5413 40.6978 14.4831 40.6978 15.6401Z" />
    </SVG>
  );
}

export default forwardRef(Logo);
