import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';

import Button from '@src/components/ui/Button';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { trackEvent } from '@src/lib/analytics';
import { REMOVE_BUSINESS_USER } from '@src/constants/events';
import useToast from '@src/hooks/useToast';
import { Header, Alert, Text, TextField } from '@src/components/ui';
import useSettings from '@src/hooks/useSettings';
import BUSINESS_USERS_QUERY, {
  BusinessUsersReturn,
  BusinessUsersParams,
} from '@src/graphql/queries/businessUsers';
import REMOVE_BUSINESS_USER_MUTATION, {
  RemoveBusinessUserReturn,
  RemoveBusinessUserParams,
} from '@src/graphql/mutations/removeBusinessUser';

interface RemoveBusinessUserFormProps {
  businessUserId: string;
  onClose?: () => void;
}
type FormData = {
  recipientUser?: {
    value: string;
    label: string;
  };
};

function RemoveBusinessUserForm({
  businessUserId,
  onClose,
}: RemoveBusinessUserFormProps) {
  const intl = useIntl();
  const history = useHistory();
  const { business } = useSettings();
  const { toast } = useToast();
  const { data, loading } = useQuery<BusinessUsersReturn, BusinessUsersParams>(
    BUSINESS_USERS_QUERY,
    {
      variables: {
        businessId: business?.id,
        first: 99,
        status: ['ACTIVE'],
      },
      skip: !business?.id,
    }
  );
  const [removeBusinessUser, { loading: submitting }] = useMutation<
    RemoveBusinessUserReturn,
    RemoveBusinessUserParams
  >(REMOVE_BUSINESS_USER_MUTATION, {
    refetchQueries: [BUSINESS_USERS_QUERY],
    onCompleted: () => {
      onClose?.();
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Usuario removido',
          id: 'tHTXy7',
        }),
        intl.formatMessage({
          defaultMessage: 'Se ha removido con éxito el usuario',
          id: 'WLk7+5',
        })
      );

      trackEvent(REMOVE_BUSINESS_USER, {
        creator: 'admin',
        view: 'SettingsBusinessUser',
      });

      history.push('/settings/users');
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al remover usuario',
          id: 'IvRy7W',
        }),
        e.message
      );
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const businessUserOptions = useMemo(
    () =>
      data?.businessUsers?.edges?.map((edge) => ({
        label: `${edge.node.firstName} ${edge.node.lastName}`,
        value: edge.node.userId,
      })),
    [data]
  );

  const onSubmit = (data: FormData) => {
    if (business?.id && data?.recipientUser?.value) {
      removeBusinessUser({
        variables: {
          businessId: business.id,
          businessUserId: businessUserId,
          recepientUserId: data.recipientUser.value,
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Remover usuario de la organización ',
          id: 'w7IaM3',
        })}
      </Header>
      <Spacer />
      <Text color="neutral" colorStep="500">
        {intl.formatMessage({
          defaultMessage:
            'Transfiere los datos y responsabilidad del usuario que deseas remover a un nuevo usuario.',
          id: 'iEKVjQ',
        })}
      </Text>
      <Spacer />
      <Controller
        name="recipientUser"
        control={control}
        rules={{
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.businessUserId?.message}
            label={intl.formatMessage({
              defaultMessage: 'Transferir a',
              id: 'IaF5Qb',
            })}
            fullWidth
            type="select"
            options={businessUserOptions}
            placeholder={intl.formatMessage({
              defaultMessage: 'Selecciona un usuario',
              id: 'Brum/G',
            })}
            loading={loading}
            disabled={submitting}
          />
        )}
      />
      <Alert color="danger">
        <p>
          {intl.formatMessage({
            defaultMessage: 'Esta acción no podrá deshacerse.',
            id: 'Ckkz36',
          })}
        </p>
      </Alert>
      <Spacer />
      <FlexContainer justifyContent="flex-end">
        <Button variant="ghosted" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button type="submit" disabled={submitting} loading={submitting}>
          {intl.formatMessage({
            defaultMessage: 'Remover Usuario',
            id: 'Ddq3Cx',
          })}
        </Button>
      </FlexContainer>
    </form>
  );
}

RemoveBusinessUserForm.displayName = 'RemoveBusinessUserForm';

export default RemoveBusinessUserForm;
