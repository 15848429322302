import { useMemo } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import { TRANSACTIONS_LIST_QUERY } from '@src/graphql/queries';
import usePagination from '@src/hooks/usePagination';

import type { WalletEntry } from '@src/types/models';
import type { Sort } from '@src/types/utils';

function useTransactionsListQuery(variables?: {
  businessId?: string;
  search?: string;
  first?: number;
  last?: number;
  status?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  sortBy?: Sort;
  pending?: boolean;
  providerId?: string;
  after?: string;
  before?: string;
  page?: number;
}): {
  loading: boolean;
  error?: ApolloError;
  data: WalletEntry[];
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  page: number;
  next: () => void;
  previous: () => void;
  nodes: WalletEntry[];
  resetPaginationParams: (otherParams?: Record<string, any>) => void;
} {
  const { loading, error, data, refetch } = useQuery(TRANSACTIONS_LIST_QUERY, {
    variables: variables || {},
    skip: !variables?.businessId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    nodes,
    resetPaginationParams,
  } = usePagination(
    data?.walletEntries,
    refetch,
    variables?.first,
    variables?.page
  );

  const transactions: WalletEntry[] = useMemo(() => {
    if (!data?.walletEntries?.edges) {
      return [];
    }
    return data.walletEntries.edges.map((edge) => edge.node);
  }, [data]);

  return {
    error,
    loading,
    data: transactions,
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    nodes,
    resetPaginationParams,
  };
}

export default useTransactionsListQuery;
