import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';

import Card from '@src/components/ui/Card';
import {
  Star as StarOutline,
  Book as BookOutline,
} from '@src/components/ui/Icon/outline';
import {
  Star as StarSolid,
  Book as BookSolid,
} from '@src/components/ui/Icon/solid';
import * as S from './SuppliersList.styles';
import useSettings from '@src/hooks/useSettings';
import useQueryParams from '@src/hooks/useQueryParams';
import usePageTrack from '@src/hooks/usePageTrack';
import { Tab, TabList } from '@src/components/shared/Tabs';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import useSupplierRelationshipsQuery from '@src/hooks/useSupplierRelationshipsQuery';
import SupplierResults from './SupplierResults';
import EmptyResults from '@src/components/shared/EmptyResults';
import messages from './messages';
import { trackPageView } from '@src/lib/analytics';
import Search from '@src/components/shared/Search';

import { ReactComponent as FeatureSupplierIcon } from '@src/assets/img/feature-supplier.svg';
import {
  getPaginationValuesForQuery,
  INITIAL_PAGINATION_PARAMS,
} from '@src/utils/pagination';

export function SuppliersList() {
  usePageTrack('suppliers_featured', 'core');
  const intl = useIntl();
  const { business } = useSettings();
  const history = useHistory();
  const [query, setParams] = useQueryParams();
  const limit = RESULTS_PER_PAGE;
  const {
    data: dataActive,
    loading: loadingActive,
    error: errorActive,
    count: countActive,
    hasNext: hasNextActive,
    next: nextActive,
    hasPrevious: hasPreviousActive,
    previous: previousActive,
    page: pageActive,
  } = useSupplierRelationshipsQuery({
    variables: {
      active: true,
      businessId: business?.id,
      search: query?.search,
      ...getPaginationValuesForQuery(query, limit),
    },
  });
  const {
    data: dataAll,
    loading: loadingAll,
    error: errorAll,
    count: countAll,
    hasNext: hasNextAll,
    next: nextAll,
    hasPrevious: hasPreviousAll,
    previous: previousAll,
    page: pageAll,
  } = useSupplierRelationshipsQuery({
    variables: {
      businessId: business?.id,
      search: query?.search,
      ...getPaginationValuesForQuery(query, limit),
    },
  });

  const onChange = () => {
    setParams({ search: undefined });
  };

  const handleTabSelect = (index) => {
    setParams({
      ...INITIAL_PAGINATION_PARAMS,
      search: undefined,
      tabIndex: index,
    });
  };

  const selectedTabIndex = parseInt(query.tabIndex) || 0;

  return (
    <Card>
      <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
        <TabList>
          <Tab
            defaultIcon={<StarOutline />}
            selectedIcon={<StarSolid />}
            onClick={() => {
              trackPageView('suppliers_featured', 'core');
            }}
          >
            <FormattedMessage
              defaultMessage="Destacados ({count})"
              id="Z9kvas"
              values={{ count: countActive }}
            />
          </Tab>
          <Tab
            defaultIcon={<BookOutline />}
            selectedIcon={<BookSolid />}
            onClick={() => {
              trackPageView('suppliers_all', 'core');
            }}
          >
            <FormattedMessage
              defaultMessage="Todo ({count})"
              id="7aMMVs"
              values={{ count: countAll }}
            />
          </Tab>
        </TabList>
        <S.Content>
          <S.Actions>
            <Search
              fullWidth
              placeholder={intl.formatMessage(
                messages.supplierSearchPlaceholder
              )}
            />
          </S.Actions>
          <TabPanel>
            <SupplierResults
              data={dataActive}
              loading={loadingActive}
              error={errorActive}
              count={countActive}
              hasNext={hasNextActive}
              next={nextActive}
              hasPrevious={hasPreviousActive}
              previous={previousActive}
              page={pageActive}
              limit={limit}
              emptyElement={
                <EmptyResults
                  icon={<FeatureSupplierIcon />}
                  label={intl.formatMessage(
                    messages.suppliersEmptyFeaturedTitle
                  )}
                  description={intl.formatMessage(
                    messages.suppliersEmptyFeaturedSubtitle
                  )}
                />
              }
              onDelete={onChange}
              onCreate={onChange}
              onClick={(supplier) => {
                history.push(`/suppliers/${supplier.supplier.hashId}`);
              }}
            />
          </TabPanel>
          <TabPanel>
            <SupplierResults
              data={dataAll}
              loading={loadingAll}
              error={errorAll}
              count={countAll}
              hasNext={hasNextAll}
              next={nextAll}
              hasPrevious={hasPreviousAll}
              previous={previousAll}
              page={pageAll}
              limit={limit}
              onDelete={onChange}
              onCreate={onChange}
              onClick={(supplier) => {
                history.push(`/suppliers/${supplier.supplier.hashId}`);
              }}
            />
          </TabPanel>
        </S.Content>
      </Tabs>
    </Card>
  );
}

export default SuppliersList;
