import { gql } from '@apollo/client';
import SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES from '@src/graphql/fragments/supplierBusinessRelationshipAttributes';
import SUPPLIER_PROFILE_ATTRIBUTES from '@src/graphql/fragments/supplierProfileAttributes';

export const SUPPLIER_RELATIONSHIP_QUERY_NAME = 'SupplierRelationshipView';

const SUPPLIER_RELATIONSHIP_QUERY = gql`
  query ${SUPPLIER_RELATIONSHIP_QUERY_NAME}($businessId: ID!, $supplierHashId: ID!) {
    supplierRelationship(
      businessId: $businessId
      supplierHashId: $supplierHashId
    ) {
      ...SupplierBusinessRelationshipAttributes
      supplier {
        ...SupplierProfileAttributes
      }
    } 
  }
  ${SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES}
  ${SUPPLIER_PROFILE_ATTRIBUTES}
`;

export default SUPPLIER_RELATIONSHIP_QUERY;
