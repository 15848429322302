import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ChevronLeft } from '@src/components/ui/Icon/outline';
import * as S from './GoBackButton.styles';

interface GoBackButtonProps {
  text?: string;
}

const GoBackButton = ({ text }: GoBackButtonProps) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <S.Button type="button" onClick={history.goBack}>
      <ChevronLeft />
      <span>
        {text ??
          intl.formatMessage({
            defaultMessage: 'Regresar',
            id: 'q400kG',
          })}
      </span>
    </S.Button>
  );
};

export default GoBackButton;
