import { useMutation } from '@apollo/client';
import { EXPORT_TRANSACTIONS } from '@src/graphql/mutations';
import type { WalletEntryStatus } from '@src/types/enums';

export type ExportTransactionsCSVParameters = {
  status?: Array<keyof typeof WalletEntryStatus>;
  search?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  providerId?: string;
  businessId: string;
  walletEntriesIds?: string[];
  pending?: boolean;
};

export type ExportTransactionsCSVResponse = {
  exportTransactions: { id: string }[];
};

function useExportTransactionsCSVMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    ExportTransactionsCSVResponse,
    ExportTransactionsCSVParameters
  >(EXPORT_TRANSACTIONS);
  return {
    mutate,
    data,
    loading,
    error,
  };
}

export default useExportTransactionsCSVMutation;
