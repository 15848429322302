import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';

import attributes from './attributes';
import Page from '@src/components/shared/Page';
import { Alert, Button, Card } from '@src/components/ui';
import Table from '@src/components/shared/Table';
import PageTitle from '@src/components/shared/PageTitle';
import Breadcrumb from '@src/components/shared/Breadcrumb';
import Loader from '@src/components/shared/Loader';
import CreateBusinessUserForm from '@src/components/shared/form/CreateBusinessUserForm';
import EmptyResults from '@src/components/shared/EmptyResults';
import BUSINESS_USERS_QUERY, {
  BusinessUsersParams,
  BusinessUsersReturn,
} from '@src/graphql/queries/businessUsers';
import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import { Spacer } from '@src/components/shared/layouts';

export function Users() {
  const intl = useIntl();
  const { business } = useSettings();
  const { open: openCreateBusinessUser } = useModal(CreateBusinessUserForm);
  const { data, loading, error } = useQuery<
    BusinessUsersReturn,
    BusinessUsersParams
  >(BUSINESS_USERS_QUERY, {
    variables: {
      businessId: business?.id,
      first: 99,
      status: ['ACTIVE', 'PENDING'],
    },
    fetchPolicy: 'cache-and-network',
    skip: !business?.id,
  });

  const businessUsers = useMemo(
    () => data?.businessUsers?.edges?.map((edge) => edge.node) || [],
    [data]
  );

  if (loading) {
    return <Loader fullWidth />;
  }

  if (error) {
    return (
      <Alert color="danger" hasBorder>
        {intl.formatMessage({
          defaultMessage: 'Error al cargar los datos',
          id: 'yM0cd/',
        })}{' '}
        {error.message}
      </Alert>
    );
  }

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Usuarios',
        id: 'Zw94jw',
      })}
    >
      <Breadcrumb
        links={[
          {
            url: '/settings/home',
            name: intl.formatMessage({
              defaultMessage: 'Configuración',
              id: 'lmFbMS',
            }),
          },
        ]}
      />
      <Spacer />
      <PageTitle
        actions={{
          render: () => (
            <Button onClick={() => openCreateBusinessUser({})}>
              {intl.formatMessage({
                defaultMessage: 'Agregar usuario',
                id: 'FIYZhs',
              })}
            </Button>
          ),
        }}
      >
        {intl.formatMessage({
          defaultMessage: 'Usuarios',
          id: 'Zw94jw',
        })}
      </PageTitle>
      <Card padding="8">
        <Table
          headers={['name', 'email', 'status', 'actions']}
          attributes={attributes}
          data={businessUsers}
          keyExtractor={(item) => item?.id}
          emptyElement={
            <EmptyResults
              label={intl.formatMessage({
                defaultMessage: 'No has agregado usuarios',
                id: 'zpC0FR',
              })}
              description={intl.formatMessage({
                defaultMessage: 'Puedes agregar usuarios a tu organización.',
                id: 'J2tByt',
              })}
              action={
                <Button onClick={() => openCreateBusinessUser({})}>
                  {intl.formatMessage({
                    defaultMessage: 'Agregar usuario',
                    id: 'FIYZhs',
                  })}
                </Button>
              }
            />
          }
          loading={loading}
        />
      </Card>
    </Page>
  );
}

export default Users;
