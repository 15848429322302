import styled from 'styled-components';

import Card from '@src/components/ui/Card';

export const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing[8]};
`;

export const TableCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing[8]};
`;
