import styled from 'styled-components';

export const ToggleItemContainer = styled.div`
  width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const TextContainer = styled.div`
  display: 'flex';
  flex-direction: 'column';
  width: 500px;
  margin-right: 100px;
`;

export const SwitchContainer = styled.div`
  margin-left: 'auto';
`;

export const Label = styled.div`
  width: full;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const Text = styled.text`
  width: full;
  margin-top: 4px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const IconContainer = styled.div`
  margin-right: 24px;
`;
