import React from 'react';
import { useIntl } from 'react-intl';
import { useLazyQuery, useMutation } from '@apollo/client';
import { APPROVE_INVOICES_FROM_ACTIVE_PROVIDERS } from '@src/graphql/mutations';
import { useHistory } from 'react-router-dom';

import * as S from './MoveInvoicesToPayModal.styles';
import { Header, Button, Badge } from '@src/components/ui';
import { APP_DATA_QUERY } from '@src/graphql/queries';
import useQueryParams from '@src/hooks/useQueryParams';
import { trackEvent } from '@src/lib/analytics';
import { ONBOARDING_MOVED_TO_PAY } from '@src/constants/events';
import useToast from '@src/hooks/useToast';

import { ReactComponent as CheckCircle } from './img/check-circle.svg';
import { Spacer } from '@src/components/shared/layouts';
export interface MoveInvoicesToPayModalProps {
  totalInvoices: number;
  businessId?: string;
  onClose?: () => void;
}

export function MoveInvoicesToPayModal({
  totalInvoices,
  businessId,
  onClose,
}: MoveInvoicesToPayModalProps) {
  const intl = useIntl();
  const history = useHistory();
  const { toast } = useToast();
  const [{ redirectTo }] = useQueryParams();
  const [getAppData, { loading: cancelLoading }] = useLazyQuery(
    APP_DATA_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        onClose && onClose();
        history.replace(redirectTo || '/inbox');
      },
    }
  );
  const [approveInvoices, { loading }] = useMutation(
    APPROVE_INVOICES_FROM_ACTIVE_PROVIDERS,
    {
      variables: { businessId },
      refetchQueries: [APP_DATA_QUERY],
      awaitRefetchQueries: true,
      onCompleted: () => {
        onClose && onClose();
        history.replace(redirectTo || '/accounts_payable');
        trackEvent(ONBOARDING_MOVED_TO_PAY, {
          answer: true,
        });
      },
      onError: (e) => {
        toast.error(e);
      },
    }
  );

  const handleAcceptClick = () => {
    if (businessId) {
      approveInvoices();
    }
  };

  const handleCancelClick = () => {
    getAppData();
    trackEvent(ONBOARDING_MOVED_TO_PAY, {
      answer: false,
    });
  };

  return (
    <S.Container>
      <CheckCircle />
      <Spacer />
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Agregar a tus Cuentas por pagar',
          id: 'TTAmeL',
        })}
      </Header>
      <S.Description>
        {intl.formatMessage({
          defaultMessage: 'Hemos encontrado',
          id: 'ESeu0+',
        })}{' '}
        <Badge
          color="gray"
          label={intl.formatMessage(
            {
              defaultMessage: '{totalInvoices} facturas',
              id: 'nN3Li5',
            },
            {
              totalInvoices,
            }
          )}
        />{' '}
        {intl.formatMessage({
          defaultMessage:
            'que te emitieron tus proveedores en el último mes. ¿Deseas agregarlas a tus cuentas por pagar?',
          id: 'uy/ixp',
        })}
      </S.Description>
      <Button
        block
        onClick={handleAcceptClick}
        loading={loading}
        disabled={cancelLoading}
      >
        {intl.formatMessage({
          defaultMessage: 'Si, agregar a mis Cuentas Por Pagar',
          id: 'gPvEdH',
        })}
      </Button>
      <Spacer margin="2" />
      <Button
        variant="transparent"
        block
        onClick={handleCancelClick}
        loading={cancelLoading}
        disabled={loading}
      >
        {intl.formatMessage({
          defaultMessage: 'No, prefiero revisarlas antes',
          id: 'rWAebC',
        })}
      </Button>
    </S.Container>
  );
}

MoveInvoicesToPayModal.displayName = 'MoveInvoicesToPayModal';

export default MoveInvoicesToPayModal;
