import React from 'react';
import Alert from '@src/components/ui/Alert';
import { useIntl } from 'react-intl';

function IncompleteInvoiceNotification() {
  const intl = useIntl();
  return (
    <Alert hasBorder>
      {intl.formatMessage({
        id: 'W6X4iB',
        defaultMessage:
          'La información de esta factura puede estar incompleta debido a que se ha llegado al límite de 2,000 descargas diarias del SAT para tu negocio. Por favor revisa nuevamente en 24h y cualquier duda ponte en contacto con',
      })}{' '}
      <a href="mailto:ayuda@higo.io">ayuda@higo.io</a>
    </Alert>
  );
}

export default IncompleteInvoiceNotification;
