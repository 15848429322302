import { gql } from '@apollo/client';

export const UPDATE_PASSWORD_MUTATION_NAME = 'passwordToken';

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation passwordToken($token: String!, $password: String!) {
    updatePassword(token: $token, password: $password) {
      id
    }
  }
`;
