import React from 'react';
import { useIntl } from 'react-intl';

import Currency from '@src/components/i18n/Currency';
import useWallet from '@src/hooks/useWallet';
import { Label } from '@src/components/ui';
import { FlexContainer } from '../layouts';

export function WalletBalance() {
  const { balance } = useWallet();
  const { formatMessage } = useIntl();

  return (
    <FlexContainer flexDirection="column" alignItems="flex-end">
      <Label strong colorStep="500">
        {formatMessage({
          defaultMessage: 'Saldo disponible',
          id: '8wYd6w',
        })}
      </Label>
      <Label strong size="lg">
        <Currency value={balance} />
      </Label>
    </FlexContainer>
  );
}

export default WalletBalance;
