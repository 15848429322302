import * as Sentry from '@sentry/react';

function useErrorReporter() {
  return (error: string | Error, metadata?: { [key: string]: any }) => {
    if (typeof error === 'string') {
      Sentry.captureMessage(error, {
        tags: metadata || {},
      });
    } else {
      Sentry.captureException(error, {
        tags: metadata || {},
      });
    }
  };
}

export default useErrorReporter;
