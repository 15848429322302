import React from 'react';

import { WalletEntry } from '@src/types/models';
import TransactionsIdentifier from '@src/components/shared/TransactionsIdentifier';
import Currency from '@src/components/i18n/Currency';
import FormattedDate, {
  defaultDateFormat,
} from '@src/components/i18n/FormattedDate';
import * as S from './WalletTransactionCard.styles';
import LinkToTransactionDetails from '../LinkToTransactionDetails';
import TransactionBusinessEmitter from '../TransactionBusinessEmitter';
import Card from '@src/components/ui/Card';
import Header from '@src/components/ui/Header';
import useTransaction from '@src/hooks/useTransaction';

interface WalletTransactionCardProps {
  walletEntry: WalletEntry;
  onClick: (entry: WalletEntry) => void;
}

export const WalletTransactionCard = ({
  walletEntry,
  onClick,
}: WalletTransactionCardProps) => {
  const {
    receiver,
    recipient,
    sender,
    positive,
    createdAt,
    amount,
  } = useTransaction(walletEntry);

  const handleClick = () => {
    onClick && onClick(walletEntry);
  };

  return (
    <Card
      backgroundColor="neutral"
      padding="6"
      backgroundColorStep="100"
      onClick={handleClick}
    >
      <S.FlexSpaceBetween>
        <TransactionsIdentifier walletEntry={walletEntry} withLabel={true} />
        <S.DateContainer>
          <FormattedDate value={createdAt} {...defaultDateFormat} />
        </S.DateContainer>
      </S.FlexSpaceBetween>
      <S.MarginLeft>
        <TransactionBusinessEmitter
          positive={positive}
          senderName={sender?.name}
          recipientName={receiver?.name || recipient?.name}
        />
        <S.FlexSpaceBetween>
          <LinkToTransactionDetails walletEntry={walletEntry} />
          <Header as="h5">
            <Currency value={amount} />
          </Header>
        </S.FlexSpaceBetween>
      </S.MarginLeft>
    </Card>
  );
};

export default WalletTransactionCard;
