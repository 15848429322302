import BigNumber from 'bignumber.js';

import { percentToFloat } from '@src/utils/percent';
import { stringToCents } from '@src/utils/currency';
import { floatToPercent } from '@src/utils/percent';
import { centsToString } from '@src/utils/currency';
import {
  STATUS_PENDING_ACCEPTANCE,
  STATUS_ACCEPTED,
  SHIPPING_COST_TYPE_FIXED_AMOUNT,
  SHIPPING_COST_TYPE_TOTAL_PERCENT,
} from '@src/constants/orders';

import { hasWeWorkIntegration } from '@src/utils/integrations';
import type {
  LineItemStatus,
  LineItemOrderable,
  OrderStatus,
  ShippingCostType,
  Quantity,
  InvoiceApprovalStatus,
  InvoiceEmissionStatus,
  InvoicePaymentStatus,
} from '@src/types/enums';
import type {
  Location,
  Business,
  Invitation,
  TaxPayerInfo,
} from '@src/types/models';

export type LineItemInputs = {
  id?: string;
  title: string;
  buyerSku?: string;
  sellerSku?: string;
  unit: string;
  quantity?: string;
  requestedQuantity?: string;
  availableQuantity?: string;
  pricePerUnit?: string | number;
  taxPercent?: string;
};

export type LineItem = {
  id?: string;
  title: string;
  buyerSku?: string;
  sellerSku?: string;
  imageUrl?: string;
  unit: string;
  quantity: number;
  pricePerUnit?: number;
  requestedQuantity?: number;
  availableQuantity?: number;
  pricePerUnitWithoutVat: number;
  valueAddedTaxPercent: number;
  price: number;
  currency?: string;
  status?: keyof typeof LineItemStatus;
  orderableType?: keyof typeof LineItemOrderable;
  _destroy?: boolean;
  versions?: Array<{
    updatedAt: string;
    quantity: number;
  }>;
  valueAddedRetainedTaxAmount?: number;
  valueAddedTaxAmount?: number;
  valueDiscountAmount?: number;
  iepsRetainedAmount?: number;
  iepsAmount?: number;
};

export type Invoice = {
  id: string;
  approvalStatus: keyof typeof InvoiceApprovalStatus;
  emissionStatus: keyof typeof InvoiceEmissionStatus;
  businessReceiver: Business;
  businessEmitter?: Business;
  matchedAt: string;
  paymentStatus: keyof typeof InvoicePaymentStatus;
  total: number;
  currency: string;
};

export type Order = {
  hashId: string;
  buyerBusiness: Business;
  buyerEmail: string;
  buyerContact?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  sellerBusiness: {
    id: string;
    name: string;
  };
  sellerEmail: string;
  sellerContact?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  creatorBusiness: {
    id: string;
  };
  shippingLocation: Location;
  taxPayerInfo?: TaxPayerInfo;
  sellerTaxPayerInfo?: TaxPayerInfo;
  lineItems: Array<LineItem>;
  note?: string;
  paymentTerms: string;
  paymentDueDate?: string;
  shippingDate?: string;
  currency: string;
  shippingCost: number;
  shippingCostType: keyof typeof ShippingCostType;
  shippingCostValue: number;
  totalVat: number;
  totalPrice: number;
  createdAt: string;
  canBeAccepted: boolean;
  status: keyof typeof OrderStatus;
  invitation?: Invitation;
  invoices: Array<Invoice>;
};

export const ALLOWED_EDIT_STATUS = [STATUS_PENDING_ACCEPTANCE, STATUS_ACCEPTED];

export function subtotalFromLineItems(lineItems: Array<LineItem>): number {
  return lineItems
    .map((lineItem) => {
      if (lineItem.pricePerUnitWithoutVat && lineItem.quantity) {
        const sub = lineItem.pricePerUnitWithoutVat * lineItem.quantity;
        return sub;
      }
      return 0;
    })
    .reduce((sum, current) => sum + current, 0);
}

export function quantityFromLineItems(
  lineItems: Array<LineItem>,
  quantity: keyof typeof Quantity = 'quantity'
): number {
  return lineItems
    .map((lineItem: LineItem) => {
      if (lineItem[quantity]) {
        //@ts-ignore
        return new BigNumber(lineItem[quantity]).toNumber();
      }
      return 0;
    })
    .reduce((sum, current) => sum + new BigNumber(current).toNumber(), 0);
}

export function taxesFromLineItems(lineItems: Array<LineItem>): number {
  return lineItems
    .map((lineItem) => {
      if (
        lineItem.pricePerUnitWithoutVat &&
        lineItem.quantity &&
        lineItem.valueAddedTaxPercent
      ) {
        const {
          quantity,
          pricePerUnitWithoutVat,
          valueAddedTaxPercent,
        } = lineItem;

        return quantity * pricePerUnitWithoutVat * valueAddedTaxPercent;
      }
      return 0;
    })
    .reduce((sum, current) => sum + current, 0);
}

export function totalPriceFromLineItems(lineItems: Array<LineItem>): number {
  return subtotalFromLineItems(lineItems) + taxesFromLineItems(lineItems);
}

export function lineItemSerializer(
  lineItem: LineItemInputs,
  value: keyof typeof Quantity = 'quantity'
): LineItem {
  const quantity = lineItem[value] ? lineItem[value] : 0;

  const requestedQuantity = lineItem.requestedQuantity
    ? parseInt(lineItem.requestedQuantity, 10)
    : 0;

  const availableQuantity = lineItem.availableQuantity
    ? parseInt(lineItem.availableQuantity, 10)
    : 0;

  const pricePerUnitWithoutVat = lineItem.pricePerUnit
    ? stringToCents(lineItem.pricePerUnit)
    : 0;
  const valueAddedTaxPercent = lineItem.taxPercent
    ? percentToFloat(lineItem.taxPercent)
    : 0;

  //@ts-ignore
  const price = new BigNumber(quantity)
    .times(pricePerUnitWithoutVat)
    .times(1 + valueAddedTaxPercent)
    .toFixed();

  const formattedLineItem = {
    id: lineItem.id,
    title: lineItem.title,
    buyerSku: lineItem.buyerSku,
    sellerSku: lineItem.sellerSku,
    unit: lineItem.unit,
    quantity,
    requestedQuantity,
    availableQuantity,
    pricePerUnitWithoutVat,
    valueAddedTaxPercent,
    price,
  };

  //@ts-ignore
  return formattedLineItem;
}

export function lineItemAdapter(lineItem: LineItem): LineItemInputs {
  const {
    quantity,
    requestedQuantity,
    availableQuantity,
    pricePerUnitWithoutVat,
    valueAddedTaxPercent,
    ...rest
  } = lineItem;

  const pricePerUnit = pricePerUnitWithoutVat
    ? centsToString(pricePerUnitWithoutVat)
    : '0';
  const taxPercent = valueAddedTaxPercent
    ? floatToPercent(valueAddedTaxPercent)
    : '0';

  return {
    ...rest,
    quantity: `${quantity}`,
    requestedQuantity: `${requestedQuantity || ''}`,
    availableQuantity: `${availableQuantity || ''}`,
    pricePerUnit: pricePerUnit,
    taxPercent: taxPercent,
  };
}

export function shippingCostValueInCents(
  shippingCostType?: keyof typeof ShippingCostType,
  shippingCostValue?: string
) {
  let shippingCost = 0;

  if (
    shippingCostType === SHIPPING_COST_TYPE_FIXED_AMOUNT &&
    shippingCostValue
  ) {
    shippingCost = stringToCents(shippingCostValue);
  }

  if (
    shippingCostType === SHIPPING_COST_TYPE_TOTAL_PERCENT &&
    shippingCostValue
  ) {
    shippingCost = percentToFloat(shippingCostValue);
  }

  return shippingCost;
}

export function canEditOrder(order: Order) {
  return (
    !hasWeWorkIntegration(order.buyerBusiness) &&
    ALLOWED_EDIT_STATUS.includes(order.status)
  );
}
