import React from 'react';

import Users from './Users';
import Permission from '@src/components/shared/Permission';
import Modal from '@src/components/shared/Modal';
import CreateBusinessUserForm from '@src/components/shared/form/CreateBusinessUserForm';

import { UserRole } from '@src/types/enums';

function UsersContainer() {
  return (
    <Permission minimumRoles={[UserRole.ADMIN]} redirect>
      <>
        <Users />
        <Modal component={CreateBusinessUserForm} />
      </>
    </Permission>
  );
}

export default UsersContainer;
