import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Page from '@src/components/shared/Page';
import Breadcrumb from '@src/components/shared/Breadcrumb';
import Loader from '@src/components/shared/Loader';
import UsersEditForm from '@src/components/pages/UsersEdit/components/UsersEditForm';
import { Alert, Button, Header, Card } from '@src/components/ui';
import useModal from '@src/hooks/useModal';
import RemoveBusinessUserForm from '@src/components/shared/form/RemoveBusinessUserForm';
import useSettings from '@src/hooks/useSettings';

import BUSINESS_USER_QUERY, {
  BusinessUserReturn,
  BusinessUserParams,
} from '@src/graphql/queries/businessUser';
import { Grid, Spacer } from '@src/components/shared/layouts';

function UsersEdit() {
  const intl = useIntl();
  const { businessUserId } = useParams<{ businessUserId: string }>();
  const { currentUser, business } = useSettings();
  const { open: openRemoveUserModal } = useModal(RemoveBusinessUserForm);
  const { data, loading, error } = useQuery<
    BusinessUserReturn,
    BusinessUserParams
  >(BUSINESS_USER_QUERY, {
    variables: {
      businessId: business?.id,
      businessUserId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !business?.id || !businessUserId,
  });

  const businessUser = data?.businessUser;

  if (loading) {
    return <Loader fullWidth />;
  }

  if (error) {
    return (
      <Alert color="danger" hasBorder>
        {intl.formatMessage({
          defaultMessage: 'Error al cargar los datos',
          id: 'yM0cd/',
        })}{' '}
        {error.message}
      </Alert>
    );
  }

  if (!businessUser) {
    return null;
  }

  const userName = `${businessUser.firstName} ${businessUser.lastName}`;

  return (
    <Page
      title={intl.formatMessage(
        {
          defaultMessage: '{userName}',
          id: 'hHhHqD',
        },
        { userName: userName }
      )}
    >
      <Breadcrumb
        links={[
          {
            url: '/settings/home',
            name: intl.formatMessage({
              defaultMessage: 'Configuración',
              id: 'lmFbMS',
            }),
          },
          {
            url: '/settings/users',
            name: intl.formatMessage({
              defaultMessage: 'Usuarios',
              id: 'Zw94jw',
            }),
          },
        ]}
      />
      <Spacer />
      <Grid container justifyContent="center">
        {currentUser && currentUser.id !== businessUser.userId && (
          <Grid item xs={12} md={6}>
            <Card padding="8">
              <Header as="h5">{userName}</Header>
              <p>{businessUser.email}</p>
              <UsersEditForm businessUser={businessUser} />
            </Card>
            <Spacer />
            <Card padding="8">
              <Header as="h6">
                {intl.formatMessage({
                  defaultMessage: 'Zona de peligro',
                  id: 'wnvSmJ',
                })}
              </Header>
              <Spacer />
              <Button
                block
                color="danger"
                variant="ghosted"
                onClick={() =>
                  openRemoveUserModal({ businessUserId: businessUser.id })
                }
              >
                {intl.formatMessage({
                  defaultMessage: 'Remover de la organización',
                  id: 'btAka0',
                })}
              </Button>
            </Card>
          </Grid>
        )}
      </Grid>
    </Page>
  );
}

export default UsersEdit;
