import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Control, useWatch } from 'react-hook-form';

import { Alert, Button } from '@src/components/ui';
import useBalance from '@src/hooks/useBalance';
import { centsToFloat, floatToCents } from '@src/utils/currency';
import useModal from '@src/hooks/useModal';
import DepositModal from '@src/components/shared/modals/DepositModal';
import { trackEvent } from '@src/lib/analytics';
import { PAYMENT_INSUFFICIENT_FUNDS_REACHED } from '@src/constants/events';
import {
  CenteredFlexContainer,
  FlexContainer,
  Spacer,
} from '@src/components/shared/layouts';
import useSettings from '@src/hooks/useSettings';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';
import WithdrawFromCreditModal from '@src/components/shared/modals/WithdrawFromCreditModal';
import userIsAdmin from '@src/utils/roles/userIsAdmin';

interface InsufficientFundsNotificationProps {
  amountCents: number;
}

interface InsufficientFundsNotificationContainerProps {
  control: Control<any>;
  fieldNameToSum: 'invoices' | 'payments';
}

export function InsufficientFundsNotification({
  amountCents,
}: InsufficientFundsNotificationProps) {
  const intl = useIntl();
  const { open: openDeposit } = useModal(DepositModal);
  const { open: openWithdrawFromCredit } = useModal(WithdrawFromCreditModal);
  const { business, currentUser } = useSettings();
  const { walletCents } = useBalance();
  const isAutomaticFinancingFundActive = useFeatureFlag(
    FeatureFlag.AUTOMATIC_FINANCING_FUND
  );
  const isAdmin = userIsAdmin(currentUser);

  const active = business?.creditAccount?.active;
  const creditLimitCents = business?.creditAccount?.creditLimitCents || 0;
  const owedAmount = business?.creditAccount?.owedAmountCents || 0;
  const remainingCredit = creditLimitCents - owedAmount;
  const showWithdrawalButton =
    isAdmin && isAutomaticFinancingFundActive && active && remainingCredit > 0;

  useEffect(() => {
    if (walletCents && walletCents < amountCents) {
      trackEvent(PAYMENT_INSUFFICIENT_FUNDS_REACHED, {
        total: amountCents / 100,
        currentBalance: walletCents / 100,
      });
    }
  }, [walletCents, amountCents]);

  if (typeof walletCents === 'undefined' || walletCents >= amountCents) {
    return null;
  }

  return (
    <Alert hasBorder color="danger">
      <FlexContainer justifyContent="space-between">
        <CenteredFlexContainer>
          <strong>
            {intl.formatMessage({
              id: '50q2Sw',
              defaultMessage: 'Saldo insuficiente.',
            })}
          </strong>
          <Spacer direction="horizontal" margin="1" />
          <span>
            {intl.formatMessage(
              {
                id: '6loifD',
                defaultMessage:
                  'Recarga ${amount} para continuar con la operación.',
              },
              {
                amount: intl.formatNumber(
                  centsToFloat(amountCents - walletCents)
                ),
              }
            )}
          </span>
        </CenteredFlexContainer>
        <CenteredFlexContainer>
          <Button
            compact
            variant="outlined"
            color="neutral"
            onClick={openDeposit}
          >
            {intl.formatMessage({
              id: 'e88/qj',
              defaultMessage: 'Depositar',
            })}
          </Button>
          {showWithdrawalButton && (
            <>
              <Spacer direction="horizontal" margin="2" />
              <Button
                compact
                variant="solid"
                color="danger"
                onClick={() =>
                  openWithdrawFromCredit({
                    amountCents: amountCents - walletCents,
                  })
                }
              >
                {intl.formatMessage({
                  id: 'mMiuqm',
                  defaultMessage: 'Transferir desde mi crédito',
                })}
              </Button>
            </>
          )}
        </CenteredFlexContainer>
      </FlexContainer>
    </Alert>
  );
}

function InsufficientFundsNotificationContainer({
  control,
  fieldNameToSum,
}: InsufficientFundsNotificationContainerProps) {
  const sumValues = useWatch({
    control,
    name: fieldNameToSum,
  });

  const paymentAmount = sumValues.reduce(
    (previousValue, value) => (value.amount ? previousValue + value.amount : 0),
    0
  );
  const paymentAmountCents = floatToCents(paymentAmount);

  return <InsufficientFundsNotification amountCents={paymentAmountCents} />;
}

export default InsufficientFundsNotificationContainer;
