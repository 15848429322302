import * as Types from '../../../../../../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RecordNotificationUserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  profileImage: string;
};

export const RecordNotificationUserFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecordNotificationUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RecordNotificationUserFragment, unknown>;
