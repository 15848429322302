import L from 'react-loadable';

import LoadingBar from '@src/components/shared/LoadingBar';

const Loadable: any = (opts: any) =>
  L({
    loading: LoadingBar,
    delay: 200,
    ...opts,
  });

export default Loadable;
