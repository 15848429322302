import { gql } from '@apollo/client';
import { INVOICE_LIST_ATTRIBUTES } from '../fragments/invoiceListAttributes';

export const UPDATE_INVOICE_MAILBOX_STATUS = gql`
  mutation UpdateInvoiceMailboxStatus(
    $businessId: ID!
    $invoiceId: ID!
    $mailboxStatus: InvoiceMailboxStatusEnum!
  ) {
    updateInvoiceMailboxStatus(
      businessId: $businessId
      invoiceId: $invoiceId
      mailboxStatus: $mailboxStatus
    ) {
      ...InvoicesListAttributes
    }
  }
  ${INVOICE_LIST_ATTRIBUTES}
`;
