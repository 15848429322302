import React from 'react';
import { useIntl } from 'react-intl';

import { Tag } from '@src/components/ui';
import { FinancingRequestPaymentStatus } from '@src/types/enums';

interface PaymentStatusTagProps {
  status?: FinancingRequestPaymentStatus;
}

function PaymentStatusTag({ status }: PaymentStatusTagProps) {
  const intl = useIntl();

  if (status === FinancingRequestPaymentStatus.OVERDUE) {
    return (
      <Tag
        label={intl.formatMessage({
          defaultMessage: 'Vencido',
          id: 'mTJVjt',
        })}
        compact
        color="danger"
      />
    );
  }

  if (status === FinancingRequestPaymentStatus.PARTIALLY_PAID) {
    return (
      <Tag
        label={intl.formatMessage({
          defaultMessage: 'Pago parcial',
          id: 'hW6P/+',
        })}
        compact
        color="warning"
      />
    );
  }

  return (
    <Tag
      label={intl.formatMessage({
        defaultMessage: 'Por pagar',
        id: 'YkWZGw',
      })}
      compact
      color="info"
    />
  );
}

export default PaymentStatusTag;
