import { gql } from '@apollo/client';
import PROVIDER_OPTION_ATTRIBUTES, {
  ProviderOptionAttributes,
} from '../fragments/providerOptionAttributes';
import { PaginatedResponse, Sort } from '@src/types/utils';

export const PROVIDER_OPTIONS_QUERY_NAME = 'SupplierRelationships';

const PROVIDER_OPTIONS_QUERY = gql`
  query ${PROVIDER_OPTIONS_QUERY_NAME}(
    $businessId: ID!
    $search: String
    $active: Boolean
    $first: Int
    $last: Int
    $before: String
    $after: String
    $sortBy: BusinessRelationshipsSortingParams
  ) {
    providerRelationships(
      businessId: $businessId
      search: $search
      active: $active
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...ProviderOptionAttributes
        }
      }
    }
  }
  ${PROVIDER_OPTION_ATTRIBUTES}
`;

export type ProviderOptionsQueryReturn = {
  providerRelationships: PaginatedResponse<ProviderOptionAttributes>;
};

export type ProviderOptionsQueryVariables = {
  businessId: string;
  search?: string;
  active?: boolean;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  sortBy?: Sort<'CREATED_AT' | 'INVOICES_COUNT'>;
};

export default PROVIDER_OPTIONS_QUERY;
