import React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import { Button, TextField } from '@src/components/ui';

export type UpdatePasswordFormData = {
  currentPassword?: string;
  newPassword?: string;
};

export interface UpdatePasswordFormProps {
  showCurrentPassword?: boolean;
  loading?: boolean;
  onSubmit: (data: UpdatePasswordFormData) => void;
}

export function UpdatePasswordForm({
  onSubmit,
  showCurrentPassword,
  loading,
}: UpdatePasswordFormProps) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {showCurrentPassword && (
        <TextField
          {...register('currentPassword', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          fullWidth
          required
          disabled={loading}
          type="password"
          label={intl.formatMessage({
            defaultMessage: 'Contraseña actual',
            id: 'uJGHGN',
          })}
          placeholder="**********"
          helperText={errors?.currentPassword?.message}
          error={!!errors?.currentPassword?.message}
        />
      )}
      <TextField
        {...register('newPassword', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
          minLength: {
            value: 8,
            message: intl.formatMessage(
              {
                defaultMessage: 'Debe contener mínimo {length} caracteres.',
                id: 'Isn6i2',
              },
              {
                length: 8,
              }
            ),
          },
          validate: {
            isStrong: (value) =>
              (/[A-Z]/.test(value) &&
                /\d/.test(value) &&
                /[a-z]/.test(value)) ||
              intl.formatMessage({
                defaultMessage:
                  'Tu contraseña debe tener mínimo 8 caracteres, un número y una mayúscula',
                id: 'PQBnTZ',
              }),
          },
        })}
        fullWidth
        required
        disabled={loading}
        type="password"
        placeholder="**********"
        label={intl.formatMessage({
          defaultMessage: 'Nueva contraseña',
          id: 'Or996K',
        })}
        helperText={errors?.newPassword?.message}
        error={!!errors?.newPassword?.message}
      />
      <TextField
        {...register('confirmPassword', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
          validate: (value: string) =>
            getValues('newPassword') === value ||
            intl.formatMessage({
              defaultMessage: 'La contraseña no coincide',
              id: '0F2MIV',
            }),
        })}
        fullWidth
        required
        disabled={loading}
        type="password"
        placeholder="**********"
        label={intl.formatMessage({
          defaultMessage: 'Confirmar contraseña',
          id: 'Zxo3iN',
        })}
        helperText={errors?.confirmPassword?.message}
        error={!!errors?.confirmPassword?.message}
      />

      <Button block type="submit" loading={loading}>
        {intl.formatMessage({
          defaultMessage: 'Actualizar contraseña',
          id: 'Vle9L4',
        })}
      </Button>
    </form>
  );
}

export default UpdatePasswordForm;
