import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './OrderDetails.styles';
import Table from '@src/components/ui/Table';
import Currency, { DEFAULT_CURRENCY } from '@src/components/i18n/Currency';
import ShippingCost from '@src/components/shared/ShippingCost';
import {
  quantityFromLineItems,
  taxesFromLineItems,
  totalPriceFromLineItems,
} from '@src/utils/orders';
import {
  SHIPPING_COST_TYPE_FIXED_AMOUNT,
  SHIPPING_COST_TYPE_TOTAL_PERCENT,
} from '@src/constants/orders';
import type {
  ShippingCostType,
  Quantity,
  LineItemStatus,
  LineItemOrderable,
} from '@src/types/enums';
import { Grid } from '@src/components/shared/layouts';
import { Text } from '@src/components/ui';

export type InvoiceLineItem = {
  id?: string;
  title: string;
  buyerSku?: string;
  sellerSku?: string;
  imageUrl?: string;
  unit: string;
  quantity: number;
  pricePerUnit?: number;
  requestedQuantity?: number;
  availableQuantity?: number;
  pricePerUnitWithoutVat: number;
  valueAddedTaxPercent: number;
  price: number;
  currency?: string;
  status?: keyof typeof LineItemStatus;
  orderableType?: keyof typeof LineItemOrderable;
  _destroy?: boolean;
  versions?: Array<{
    updatedAt: string;
    quantity: number;
  }>;
  valueAddedRetainedTaxAmount?: number;
  valueAddedTaxAmount?: number;
  valueDiscountAmount?: number;
  iepsRetainedAmount?: number;
  iepsAmount?: number;
};

type OrderDetailsProps = {
  currency?: string;
  lineItems?: Array<InvoiceLineItem>;
  shippingCost?: number;
  shippingCostValue?: number;
  shippingCostCurrency?: string;
  shippingCostType?: keyof typeof ShippingCostType;
  totals?: {
    taxes: number;
    totalPrice: number;
    quantity?: number;
  };
  note?: string;
  showNote?: boolean;
  quantityType?: keyof typeof Quantity;
  invoiceDetail?: boolean;
  foreignTotal?: number;
  valueAddedTaxAmount?: number;
  valueAddedRetainedTaxAmount?: number;
  iepsRetainedAmount?: number;
  iepsAmount?: number;
  isrAmount?: number;
  subtotal?: number;
  total: number;
  isInvoice?: boolean;
};

export function OrderDetails({
  lineItems,
  shippingCost = 0,
  shippingCostValue = 0,
  shippingCostCurrency,
  shippingCostType,
  totals,
  note,
  showNote = true,
  currency,
  quantityType,
  invoiceDetail,
  foreignTotal,
  valueAddedTaxAmount,
  valueAddedRetainedTaxAmount,
  iepsRetainedAmount,
  iepsAmount,
  isrAmount,
  subtotal,
  total,
  isInvoice,
}: OrderDetailsProps) {
  const intl = useIntl();
  let totalItems = 0;
  let displayedShippingCost = 0;
  let calculatedSubTotal = 0;
  let taxes = 0;
  let totalPrice = 0;
  let totalIepsAmount = 0;
  let totalIepsRetainedAmount = 0;
  let totalValueAddedRetainedTaxAmount = 0;
  let totalValueAddedTaxAmount = 0;
  let totalDiscountAmount = 0;

  if (!totals && !lineItems) {
    return null;
  }

  if (lineItems) {
    taxes = taxesFromLineItems(lineItems);
    totalPrice = totalPriceFromLineItems(lineItems);
    lineItems.forEach((lineItem) => {
      totalIepsAmount += lineItem.iepsAmount ? lineItem.iepsAmount : 0;
      totalIepsRetainedAmount += lineItem.iepsRetainedAmount
        ? lineItem.iepsRetainedAmount
        : 0;
      totalValueAddedTaxAmount += lineItem.valueAddedTaxAmount
        ? lineItem.valueAddedTaxAmount
        : 0;
      totalValueAddedRetainedTaxAmount += lineItem.valueAddedRetainedTaxAmount
        ? lineItem.valueAddedRetainedTaxAmount
        : 0;
      totalDiscountAmount += lineItem.valueDiscountAmount
        ? lineItem.valueDiscountAmount
        : 0;
      if (lineItem.pricePerUnit)
        calculatedSubTotal += lineItem.pricePerUnit * lineItem.quantity;
    });
  }

  if (totals) {
    taxes = totals.taxes > 0 ? totals.taxes : 0;
    totalPrice = totals.totalPrice;
  }

  totalItems =
    (totals && totals.quantity) ||
    quantityFromLineItems(lineItems || [], quantityType);

  if (shippingCost > 0) {
    displayedShippingCost = shippingCost;
  } else {
    if (
      shippingCostType === SHIPPING_COST_TYPE_FIXED_AMOUNT &&
      shippingCostValue
    ) {
      displayedShippingCost = shippingCostValue;
    }

    if (
      shippingCostType === SHIPPING_COST_TYPE_TOTAL_PERCENT &&
      shippingCostValue
    ) {
      displayedShippingCost = shippingCostValue * (totalPrice - taxes);
    }

    const calculatedTaxAmount =
      totalValueAddedTaxAmount - totalValueAddedRetainedTaxAmount;
    const calculatedIepsAmount = totalIepsAmount - totalIepsRetainedAmount;

    totalPrice = totalPrice + displayedShippingCost;

    calculatedSubTotal =
      totalPrice > 0
        ? totalPrice -
          calculatedTaxAmount -
          displayedShippingCost -
          calculatedIepsAmount +
          totalDiscountAmount
        : 0;
  }

  return (
    <Grid container>
      {showNote && (
        <Grid item xs={12}>
          <label>
            {intl.formatMessage({
              id: 'FD0Qfi',
              defaultMessage: 'Nota',
            })}
          </label>
          <div>
            {note ? (
              <p>{note}</p>
            ) : (
              intl.formatMessage({
                id: 'Y8ERFM',
                defaultMessage: 'No hay notas sobre la orden',
              })
            )}
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <Table>
          <tbody>
            <S.TableRow>
              <td>
                <Text textAlign="right">
                  {intl.formatMessage({
                    id: 'OoXue7',
                    defaultMessage: 'Items totales',
                  })}
                </Text>
              </td>
              <td data-testid="totalItems">
                <Text textAlign="right">{totalItems}</Text>
              </td>
            </S.TableRow>
            <S.TableRow>
              <td>
                <Text textAlign="right">
                  {intl.formatMessage({
                    id: 'L8seEc',
                    defaultMessage: 'Subtotal',
                  })}
                </Text>
              </td>
              <td>
                <Text textAlign="right">
                  <Currency
                    data-testid="subtotal"
                    value={isInvoice ? subtotal : calculatedSubTotal}
                    currency={currency}
                    showMissingPriceLabel
                  />
                </Text>
              </td>
            </S.TableRow>
            {totalDiscountAmount > 0 && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'xqbqgV',
                      defaultMessage: 'Descuento',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="subtotal"
                      value={totalDiscountAmount}
                      currency={currency}
                      showMissingPriceLabel
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {!!shippingCostType && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'v7U9pn',
                      defaultMessage: 'Envío',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <ShippingCost
                      data-testid="shippingCost"
                      shippingCostType={shippingCostType}
                      shippingCostValue={shippingCostValue}
                      shippingCostCurrency={
                        shippingCostCurrency || currency || DEFAULT_CURRENCY
                      }
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {invoiceDetail && totalIepsAmount > 0 && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'qnfaSi',
                      defaultMessage: 'IEPS',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="taxes"
                      value={iepsAmount}
                      currency={currency}
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {invoiceDetail && totalIepsRetainedAmount > 0 && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: '+z3ZIW',
                      defaultMessage: 'IEPS retenido',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="taxesRetainedIeps"
                      value={iepsRetainedAmount}
                      currency={currency}
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {invoiceDetail && totalValueAddedTaxAmount > 0 && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'd7UUo2',
                      defaultMessage: 'IVA Trasladado',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="taxesIva"
                      value={valueAddedTaxAmount}
                      currency={currency}
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {invoiceDetail && totalValueAddedRetainedTaxAmount > 0 && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'mhOn8/',
                      defaultMessage: 'IVA retenido',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="taxesRetainedIva"
                      value={valueAddedRetainedTaxAmount}
                      currency={currency}
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {!invoiceDetail && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'jF1QVk',
                      defaultMessage: 'Impuestos',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="taxes"
                      value={isInvoice ? valueAddedTaxAmount : taxes}
                      currency={currency}
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            {!!isrAmount && isrAmount > 0 && (
              <S.TableRow>
                <td>
                  <Text textAlign="right">
                    {intl.formatMessage({
                      id: 'lPa929',
                      defaultMessage: 'ISR',
                    })}
                  </Text>
                </td>
                <td>
                  <Text textAlign="right">
                    <Currency
                      data-testid="taxes"
                      value={isrAmount}
                      currency={currency}
                    />
                  </Text>
                </td>
              </S.TableRow>
            )}
            <S.TableRow>
              <td>
                <Text strong textAlign="right">
                  {intl.formatMessage({
                    id: 'MJ2jZQ',
                    defaultMessage: 'Total',
                  })}
                </Text>
              </td>
              <td>
                <Text strong textAlign="right">
                  <Currency
                    data-testid="total"
                    value={
                      isInvoice
                        ? foreignTotal
                          ? foreignTotal
                          : total
                        : totalPrice
                    }
                    currency={foreignTotal ? currency : DEFAULT_CURRENCY}
                    showMissingPriceLabel
                  />
                </Text>
              </td>
            </S.TableRow>
          </tbody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default OrderDetails;
