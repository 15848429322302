import { gql } from '@apollo/client';

export const INVOICES_RECEIVED_QUERY_NAME = 'InvoicesReceivedSearch';

export const INVOICES_RECEIVED_QUERY = gql`
  query ${INVOICES_RECEIVED_QUERY_NAME}(
    $businessId: ID!
    $fiscalIdentifier: String
    $first: Int
  ) {
    invoicesReceived(
      businessId: $businessId
      fiscalIdentifier: $fiscalIdentifier
      first: $first
    ) {
      edges {
        node {
          id
          archived
          approvalStatus
          fiscalIdentifier
          expiryDate
          globalStatus
          paymentStatus
          total
          emitterTaxpayerName
        }
      }
    }
  }
`;
