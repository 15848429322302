import React from 'react';
import useSettings from '@src/hooks/useSettings';
import TaxpayerDropdown from './components/TaxpayerDropdown';
import AvatarMenu from './components/AvatarMenu';
import * as S from './Navbar.styles';
import { loggedIn } from '@src/lib/auth';
import WalletBalance from '@src/components/shared/WalletBalance';
import GlobalSearch from '@src/components/shared/GlobalSearch';

function Navbar() {
  const { business, currentUser } = useSettings();
  if (!loggedIn()) {
    return null;
  }

  return currentUser && business ? (
    <S.StickyBar id="nav">
      <S.NavbarActions>
        <TaxpayerDropdown />
        <S.NavbarRight>
          <GlobalSearch />
          <WalletBalance />
          <AvatarMenu currentUser={currentUser} />
        </S.NavbarRight>
      </S.NavbarActions>
    </S.StickyBar>
  ) : null;
}

export default Navbar;
