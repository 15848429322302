import { defineMessages } from 'react-intl';

export default defineMessages({
  FAILED: {
    defaultMessage: 'Fallida',
    id: 'JPIWkz',
  },
  PROCESSED: {
    defaultMessage: 'Procesada',
    id: 'SaRjGi',
  },
  PROCESSING: {
    defaultMessage: 'Procesando',
    id: '4ujRn4',
  },
  status: {
    defaultMessage: 'Estatus',
    id: 'dWihL7',
  },
  createdAtFrom: {
    defaultMessage: 'Desde',
    id: 'EcSZ4Q',
  },
  createdAtTo: {
    defaultMessage: 'Hasta',
    id: 'wc9qXA',
  },
  providerId: {
    defaultMessage: 'Proveedor',
    id: 'RMvOYP',
  },
});
