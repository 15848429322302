import React, { forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Control, useWatch } from 'react-hook-form';

import useSettings from '@src/hooks/useSettings';
import MultiEmailSelect from '@src/components/shared/select/MultiEmailSelect';
import { BUSINESS_RELATIONSHIP_QUERY } from '@src/graphql/queries';

import { FormData } from '../../PaymentWithoutInvoice';

interface SupplierContactsSelect {
  control: Control<FormData>;
  businessRelationshipId?: string;
  error?: boolean;
  disabled?: boolean;
  watchName: string;
  onChange: (method: SupplierContactsSelect) => void;
}

const SupplierContactsSelect = ({
  control,
  disabled,
  error,
  watchName,
  ...props
}) => {
  const intl = useIntl();
  const { business } = useSettings();

  const businessRelationshipId = useWatch({ control, name: watchName });

  const { data } = useQuery(BUSINESS_RELATIONSHIP_QUERY, {
    variables: {
      businessId: business?.id,
      businessRelationshipId,
    },
    skip: !businessRelationshipId || !business,
  });

  const businessRelationship = data?.businessRelationship;

  return (
    <MultiEmailSelect
      options={
        businessRelationship?.providerContacts
          ?.map((contact) => contact?.contactable?.email || '')
          ?.filter(Boolean) || []
      }
      fullWidth
      isDisabled={disabled || !businessRelationshipId}
      error={!!error?.message}
      helperText={error?.message}
      hiddenLabel="email"
      placeholder={intl.formatMessage({
        id: 'fC1RGL',
        defaultMessage: 'Selecciona los correos a notificar',
      })}
      {...props}
    />
  );
};

export default forwardRef(SupplierContactsSelect);
