import React, { forwardRef } from 'react';

import * as S from './Radio.styles';

import type { RadioProps } from './types';

function Radio(props: RadioProps, ref: any) {
  const { id, label, checked, disabled, ...otherProps } = props;

  return (
    <S.Wrapper htmlFor={id} disabled={disabled}>
      <input
        type="radio"
        id={id}
        ref={ref}
        checked={checked}
        disabled={disabled}
        {...otherProps}
      />
      <span className="pseudo-radio"></span>
      {typeof label === 'string' ? <span>{label}</span> : label}
    </S.Wrapper>
  );
}

export default forwardRef(Radio);
