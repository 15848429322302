import { gql } from '@apollo/client';
import { InvoicePaymentType } from '@src/types/enums';

const UPDATE_INVOICE_CREDIT_NOTES_MUTATION = gql`
  mutation UpdateInvoiceCreditNotes(
    $businessId: ID!
    $invoiceId: ID!
    $invoicePayments: [InvoicePaymentInput!]!
  ) {
    updateInvoiceCreditNotes(
      businessId: $businessId
      invoiceId: $invoiceId
      invoicePayments: $invoicePayments
    ) {
      id
    }
  }
`;

export type InvoicePaymentInput = {
  id?: string;
  amount?: number;
  payment_proof?: string;
  _destroy?: boolean;
  credit_note_id?: string;
  payment_type?: InvoicePaymentType;
};

export type UpdateInvoiceCreditNotesVariables = {
  businessId: string;
  invoiceId: string;
  invoicePayments: Array<InvoicePaymentInput>;
};

export type UpdateInvoiceCreditNotesReturn = {
  updateInvoiceCreditNotes: {
    id: string;
  };
};

export default UPDATE_INVOICE_CREDIT_NOTES_MUTATION;
