import React, { useEffect } from 'react';
import FullScreenModal from '@src/components/ui/FullScreenModal';
import ModalUI from '@src/components/ui/Modal';
import useModal from '@src/hooks/useModal';

interface ModalProps {
  component: React.FC<any>;
  padding?: '2' | '4' | '6' | '8';
  type?: 'dialog' | 'fullscreen';
  zIndex?: 40 | 50;
}

export interface ModalChildrenProps {
  onClose?: () => void;
}

function Modal({
  component: Component,
  type = 'dialog',
  zIndex = 40,
  padding = '8',
}: ModalProps) {
  const { props, isOpen, close } = useModal(Component, zIndex);

  // Closes modal when unmounted. Works for unmounting when navigation.
  useEffect(() => close, []);

  if (type === 'fullscreen') {
    return (
      <FullScreenModal open={isOpen} onClose={close}>
        <Component {...props} onClose={close} />
      </FullScreenModal>
    );
  }

  return (
    <ModalUI
      open={isOpen}
      onClose={close}
      CardProps={{
        padding,
      }}
    >
      <Component {...props} onClose={close} />
    </ModalUI>
  );
}

export default Modal;
