import BigNumber from 'bignumber.js';

import { CENTS_CONVERSION } from '@src/constants/currency';

export function stringToCents(value: string | number) {
  const num = new BigNumber(value);

  if (num.isNaN()) {
    throw Error('Invalid number');
  }

  return parseInt(num.times(CENTS_CONVERSION).toFixed(), 10);
}

export function centsToString(value: number): string {
  const num = new BigNumber(value);

  if (num.isNaN()) {
    throw Error('Invalid number');
  }

  return num.dividedBy(CENTS_CONVERSION).toFixed().toString();
}

export function centsToFloat(value: number): number {
  const num = new BigNumber(value);

  if (num.isNaN()) {
    throw Error('Invalid number');
  }

  return num.dividedBy(CENTS_CONVERSION).toNumber();
}

export function floatToCents(value: number): number {
  const num = new BigNumber(value);

  if (num.isNaN()) {
    throw Error('Invalid number');
  }

  return num.multipliedBy(CENTS_CONVERSION).toNumber();
}

export function moneyToNumber(value: string) {
  return value.replace(/[^\d.-]/g, '');
}
