import React from 'react';
import { useIntl } from 'react-intl';

import Button from '@src/components/ui/Button';
import Permission from '@src/components/shared/Permission';
import {
  InvoiceApprovalStatus,
  InvoicePaymentStatus,
  PermissionName,
  UserRole,
} from '@src/types/enums';
import LinkButton from '@src/components/shared/buttons/LinkButton';
import useSettings from '@src/hooks/useSettings';
import userIsAdmin from '@src/utils/roles/userIsAdmin';
import useCreditAccount from '@src/hooks/useCreditAccount';
import { INVOICE_PAY_CLICKED } from '@src/constants/events';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '@src/lib/analytics';
import InvoiceActionDropdown from '../InvoiceActionDropdown';
import { Invoice } from '@src/types/models';

interface InvoiceActionsProps {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'approvalStatus'
    | 'paymentStatus'
    | 'businessReceiver'
    | 'businessEmitter'
    | 'businessRelationship'
    | 'paymentStatus'
    | 'total'
    | 'outstandingBalance'
    | 'currency'
    | 'invoiceUrl'
  >;
  onEdit: (value: boolean) => void;
}

function InvoiceActions({ invoice, onEdit }: InvoiceActionsProps) {
  const intl = useIntl();
  const { currentUser } = useSettings();
  const history = useHistory();
  const [creditAccount] = useCreditAccount();
  const isAdmin = userIsAdmin(currentUser);
  const isCollaborator = currentUser?.role === UserRole.COLABORATOR;
  const isUnpaid = invoice.paymentStatus !== InvoicePaymentStatus.PAID;
  const isPendingApproval =
    invoice.approvalStatus === InvoiceApprovalStatus.PENDING;
  const isRejected = invoice.approvalStatus === InvoiceApprovalStatus.REJECTED;
  const isApproved = invoice.approvalStatus === InvoiceApprovalStatus.APPROVED;
  const canRequestFinancing = isUnpaid && isAdmin && creditAccount?.active;
  const canPay = isUnpaid && isAdmin && isApproved;
  const canRequestPayment = isUnpaid && isCollaborator;

  const handleReviewClick = () => {
    if (invoice.id) {
      history.push(`/invoice-payments?invoiceIds=${invoice.id}`);
      trackEvent(INVOICE_PAY_CLICKED, {
        count: 1,
        approval_status: invoice.approvalStatus,
      });
    }
  };

  if (isRejected) {
    return null;
  }

  return (
    <>
      <InvoiceActionDropdown onEdit={onEdit} invoice={invoice} />

      {canRequestFinancing && !isPendingApproval && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
            PermissionName.MANAGE_PAYMENTS,
          ]}
        >
          <LinkButton
            href={`/request-financing?invoiceIds=${invoice.id}`}
            variant="outlined"
            data-testid="request-financing-submit"
          >
            {intl.formatMessage({
              id: 'vnyh9H',
              defaultMessage: 'Solicitar financiamiento',
            })}
          </LinkButton>
        </Permission>
      )}

      {isPendingApproval && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
          ]}
        >
          <Button type="submit">
            {intl.formatMessage({
              id: 'W86SDb',
              defaultMessage: 'Agregar a Cuentas por pagar',
            })}
          </Button>
        </Permission>
      )}

      {canRequestPayment && (
        <Button onClick={handleReviewClick}>
          {intl.formatMessage({
            id: 'S3KdQW',
            defaultMessage: 'Solicitar aprobación de pago',
          })}
        </Button>
      )}

      {canPay && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
            PermissionName.MANAGE_PAYMENTS,
          ]}
        >
          <Button onClick={handleReviewClick}>
            {intl.formatMessage({
              id: 'cUuhwH',
              defaultMessage: 'Revisar y pagar',
            })}
          </Button>
        </Permission>
      )}
    </>
  );
}

export default InvoiceActions;
