import { defineMessages } from 'react-intl';

export default defineMessages({
  single_payment: {
    defaultMessage: 'Pago en una sola exhibición',
    id: 'pt6n0O',
  },
  multiple_payments: {
    defaultMessage: 'Pago en parcialidades o diferido',
    id: 'SHdKtv',
  },
  initial_of_multiple_payments: {
    defaultMessage: 'Pago inicial y parcialidades',
    id: 'vP8629',
  },
  acquisition_of_merchandise: {
    defaultMessage: 'Adquisición de mercancías',
    id: 'ZoklDf',
  },
  reimbursements_discounts_bonifications: {
    defaultMessage: 'Devoluciones, descuentos o bonificaciones',
    id: 'fCDxPZ',
  },
  constructions: {
    defaultMessage: 'Construcciones',
    id: 'davp7N',
  },
  office_furniture: {
    defaultMessage: 'Mobilario y equipo de oficina por inversiones',
    id: 'Uh1nHD',
  },
  transportation_equipment: {
    defaultMessage: 'Equipo de transporte',
    id: '9UR0rO',
  },
  computational_equipment: {
    defaultMessage: 'Equipo de computo y accesorios',
    id: 'cm2wGH',
  },
  tools_molds_etc: {
    defaultMessage: 'Equipo de computo y accesorios',
    id: 'cm2wGH',
  },
  phone_comunications: {
    defaultMessage: 'Comunicaciones telefónicas',
    id: 'm6WtWj',
  },
  satelite_comunications: {
    defaultMessage: 'Comunicaciones satelitales',
    id: '9vApvu',
  },
  other_machinery: {
    defaultMessage: 'Otra maquinaria y equipo',
    id: '7rJRcC',
  },
  medical_expenses: {
    defaultMessage: 'Gastos médicos por incapacidad o discapacidad',
    id: 'x7fhu9',
  },
  funeral_expenses: {
    defaultMessage: 'Gastos funerales',
    id: 'LBGnC/',
  },
  donations: {
    defaultMessage: 'Donativos',
    id: 'Rs03Ck',
  },
  hipotecary_interests: {
    defaultMessage:
      'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',
    id: '1StIXb',
  },
  sar_giveaways: {
    defaultMessage: 'Aportaciones voluntarias al SAR',
    id: 'iVt76O',
  },
  primals: {
    defaultMessage: 'Primas por seguros de gastos médicos',
    id: 'Xxq05a',
  },
  mandatory_transportation_expenses: {
    defaultMessage: 'Gastos de transportación escolar obligatoria',
    id: 'wgxi1H',
  },
  saving_deposits: {
    defaultMessage:
      'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',
    id: '8BfwOl',
  },
  educational_payments: {
    defaultMessage: 'Pagos por servicios educativos (colegiaturas)',
    id: 'hrW8Y+',
  },
  expenses_in_general: {
    defaultMessage: 'Gastos en general',
    id: 'dkg0O+',
  },
  to_be_defined: {
    defaultMessage: 'Por definir',
    id: 'NjVvR2',
  },
  cash: {
    defaultMessage: 'Efectivo (01)',
    id: 'e1lWiu',
  },
  bank_transfer: {
    defaultMessage: 'Transferencia electrónica de fondos (03)',
    id: 'EPk1OQ',
  },
  credit_card: {
    defaultMessage: 'Tarjeta de crédito (04)',
    id: '2tGpDt',
  },
  debit_card: {
    defaultMessage: 'Tarjeta de débito (28)',
    id: 'gCFW/m',
  },
  other: {
    defaultMessage: 'Otros (99)',
    id: 'B89Iel',
  },
  income: {
    defaultMessage: 'Ingreso',
    id: '7iSkZH',
  },
  expenditure: {
    defaultMessage: 'Egreso',
    id: 'zkfG5X',
  },
  translation: {
    defaultMessage: 'Traslado',
    id: 'J7eWj0',
  },
  payroll: {
    defaultMessage: 'Nómina',
    id: 'qJVsT2',
  },
  payment: {
    defaultMessage: 'Pago',
    id: 'Lb3pmu',
  },
  settlement: {
    defaultMessage: 'Dación en pago',
    id: '0Uhyvd',
  },
  services_card: {
    defaultMessage: 'Tarjeta de servicios',
    id: 'XGRxiM',
  },
  prescription_or_expiration: {
    defaultMessage: 'Prescripción o caducidad',
    id: 'TkYpvs',
  },
  payment_by_subrogation: {
    defaultMessage: 'Pago por subrogación',
    id: 'Hvx4ME',
  },
  payment_by_consignation: {
    defaultMessage: 'Pago por consignación',
    id: 'g0AWdY',
  },
  pantry_vouchers: {
    defaultMessage: 'Vales de despensa',
    id: 'HhPm4y',
  },
  novation: {
    defaultMessage: 'Novación',
    id: 'lcpYY9',
  },
  nominative_check: {
    defaultMessage: 'Cheque nominativo',
    id: 'toGbkb',
  },
  intermediaries: {
    defaultMessage: 'Intermediarios',
    id: 'eZjgL6',
  },
  electronic_wallet: {
    defaultMessage: 'Monedero electrónico',
    id: 'PBgBPJ',
  },
  electronic_money: {
    defaultMessage: 'Dinero electrónico',
    id: 'Ts6cyA',
  },
  debt_remision: {
    defaultMessage: 'Remisión de deuda',
    id: 'sZnIGa',
  },
  confusion: {
    defaultMessage: 'Confusión',
    id: 'rqPU4/',
  },
  condonation: {
    defaultMessage: 'Condonación',
    id: 'puZf+A',
  },
  compensation: {
    defaultMessage: 'Compensación',
    id: 'nVv+jl',
  },
  to_creditor_satisfaction: {
    defaultMessage: 'A satisfacción del acreedor',
    id: 'LD1YjE',
  },
  advancement_aplication: {
    defaultMessage: 'Aplicación de anticipos',
    id: 'nW4EzM',
  },
});
