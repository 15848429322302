import React from 'react';
import { useIntl } from 'react-intl';

import Page from '@src/components/shared/Page';
import useSettings from '@src/hooks/useSettings';
import ProfileSettingsForm from '@src/components/pages/Profile/components/ProfileSettingsForm';
import ProfilePasswordForm from '@src/components/pages/Profile/components/ProfilePasswordForm';
import { Grid, Spacer } from '@src/components/shared/layouts';
import { Card, Header } from '@src/components/ui';
export function Profile() {
  const intl = useIntl();
  const { currentUser } = useSettings();

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Mi perfil',
        id: 'tdx+EV',
      })}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6}>
          <Header as="h3">
            {intl.formatMessage({
              defaultMessage: 'Mi Perfil',
              id: 'Rfh4ih',
            })}
          </Header>
          <Card padding="8">
            <ProfileSettingsForm
              defaultValues={{
                email: currentUser?.email,
                firstName: currentUser?.firstName,
                lastName: currentUser?.lastName,
                phone: currentUser?.phone,
              }}
            />
          </Card>
          <Spacer />
          <Card padding="8">
            <ProfilePasswordForm />
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Profile;
