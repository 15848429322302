import styled from 'styled-components';

import UICard from '@src/components/ui/Card';

type CardProps = {
  selected?: boolean;
  hasBorder?: boolean;
  compact?: boolean;
};

type ContainerProps = {
  compact?: boolean;
};

type ClabeProps = {
  compact?: boolean;
};

export const Card = styled(UICard)<CardProps>`
  width: 100%;
  display: inline-flex;
  border-radius: ${({ theme }) => theme.spacing[2]};
  box-shadow: ${({ theme, selected, hasBorder }) =>
    hasBorder &&
    `inset 0 0 0 1.5px ${
      selected ? theme.colors.primary[500] : theme.colors.neutral[200]
    }}`};
  align-items: center;
  padding: ${({ theme, compact }) =>
    compact ? theme.spacing[2] : theme.spacing[4]};
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: ${({ theme, compact }) =>
    compact ? theme.spacing[6] : theme.spacing[8]};
  /* min-width: ${({ theme }) => theme.spacing[96]}; */
  display: flex;
  position: relative;
  align-items: center;
`;

export const BankLogo = styled.div`
  flex-basis: ${({ theme }) => theme.spacing[12]};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.xl};
  color: ${({ theme }) => theme.colors.neutral[400]};
  margin-left: ${({ theme }) => theme.spacing[2]};
  max-width: ${({ theme }) => theme.spacing[10]};
  max-height: ${({ theme }) => theme.spacing[10]};
`;

export const BankingInfo = styled.div`
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.neutral[900]};
`;

export const Clabe = styled.div<ClabeProps>`
  margin: 0;
  display: flex;
  align-items: center;
  p {
    font-weight: ${({ theme, compact }) =>
      compact ? theme.fontWeight.medium : theme.fontWeight.bold};
    font-size: ${({ theme, compact }) =>
      compact ? theme.fontSize.xs : theme.fontSize.sm};
  }
`;

export const Status = styled.div`
  margin-left: ${({ theme }) => theme.spacing[4]};
`;

export const BankName = styled.p`
  margin: 0;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize['2xs']};
  color: ${({ theme }) => theme.colors.neutral[400]};
`;

export const More = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const Empty = styled.p`
  color: ${({ theme }) => theme.colors.primary[500]};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
