import React from 'react';
import { useIntl } from 'react-intl';

import Page from '@src/components/shared/Page';
import Breadcrumb from '@src/components/shared/Breadcrumb';
import UpdateBusinessForm from '@src/components/pages/BusinessSettings/components/UpdateBusinessForm';
import { Grid, Spacer } from '@src/components/shared/layouts';
import { Card } from '@src/components/ui';

function BusinessSettings() {
  const intl = useIntl();
  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Perfil de la organización',
        id: 'uz8OLr',
      })}
    >
      <Breadcrumb
        links={[
          {
            url: '/settings/home',
            name: intl.formatMessage({
              defaultMessage: 'Configuración',
              id: 'lmFbMS',
            }),
          },
        ]}
      />
      <Spacer />
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6}>
          <Card padding="8">
            <UpdateBusinessForm />
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default BusinessSettings;
