import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as S from './BankingInfoValidation.styles';
import Header from '@src/components/ui/Header';
import Button from '@src/components/ui/Button';
import Alert from '@src/components/ui/Alert';
import BankingInfo from '@src/components/shared/BankingInfo';

import { BankingInfo as BankingInfoType } from '@src/types/models';

import { ReactComponent as CoinsIllustration } from '@src/assets/img/coins.svg';

type BankingInfoValidationProps = {
  isValidating: boolean;
  isVerified: boolean;
  isRejected: boolean;
  bankingInfo?: BankingInfoType;
  rejectedAccount?: string;
  onAddClick: () => void;
};
const BankingInfoValidation = (props: BankingInfoValidationProps) => {
  const {
    isValidating,
    isVerified,
    isRejected,
    bankingInfo,
    rejectedAccount,
    onAddClick,
  } = props;

  return !!bankingInfo && !isRejected ? (
    <>
      {isValidating && (
        <S.Notification>
          <Alert>
            <FormattedMessage
              defaultMessage="Tu nueva CLABE está en revisión y será verificada dentro de los próximos minutos. Te enviaremos un email cuando el proceso termine."
              id="R33M8S"
            />
          </Alert>
        </S.Notification>
      )}
      <BankingInfo
        showStatus
        hasBorder
        verified={isVerified}
        selected={isVerified}
        id={bankingInfo.id}
        bankName={bankingInfo.bankName}
        clabe={bankingInfo.clabe}
      />
    </>
  ) : (
    <>
      {isRejected && (
        <S.Notification>
          <Alert color="danger">
            <p>
              <strong>
                <FormattedMessage
                  defaultMessage="No hemos podido verificar la cuenta {clabe}."
                  id="90e8nk"
                  values={{
                    clabe: rejectedAccount || '',
                  }}
                />
              </strong>
              <span>
                <FormattedMessage
                  defaultMessage=" Asegúrate de que el titular de la CLABE tenga el mismo RFC de tu cuenta."
                  id="RfnJUD"
                />
              </span>
            </p>
          </Alert>
        </S.Notification>
      )}
      <S.EmptyState>
        <CoinsIllustration />
        <Header as="h5">
          <FormattedMessage
            defaultMessage="Agregar una cuenta de retiro"
            id="PWF6M6"
          />
        </Header>
        <S.Body>
          <FormattedMessage
            defaultMessage="Ingresa tu CLABE para poder retirar fondos de tu cuenta de Higo."
            id="0i9BAO"
          />
        </S.Body>
        <Button onClick={onAddClick}>
          <FormattedMessage defaultMessage="Agregar cuenta" id="NMM9Mz" />
        </Button>
      </S.EmptyState>
    </>
  );
};

export default BankingInfoValidation;
