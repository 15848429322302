import { gql } from '@apollo/client';

const CREATE_BUSINESS_USER_MUTATION = gql`
  mutation CreateBusinessUser(
    $businessId: ID!
    $businessUser: BusinessUserInput!
  ) {
    createBusinessUser(businessId: $businessId, businessUser: $businessUser) {
      id
      userId
      firstName
      lastName
      status
      email
      profileImage
      role
      jobPosition
    }
  }
`;

export type BusinessUserInput = {
  firstName?: string;
  lastName?: string;
  email: string;
  jobPosition?: string;
};

export type CreateBusinessUserParams = {
  businessId: string;
  businessUser: BusinessUserInput;
};

export type CreateBusinessUserReturn = {
  data: {
    createBusinessUser: {
      id;
      userId;
      firstName;
      lastName;
      status;
      email;
      profileImage;
      role;
      jobPosition;
    };
  };
};

export default CREATE_BUSINESS_USER_MUTATION;
