import React from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { Grid } from '@src/components/shared/layouts';
import FailedPayment from './components/FailedPayment';
import ValidatedPayment from './components/ValidatedPayment';
import ProcessingPayment from './components/ProcessingPayment';
import RequestBankingInfoViewForm from './components/RequestBankingInfoForm';
import usePageTrack from '@src/hooks/usePageTrack';
import { BANKING_INFO_REQUEST } from '@src/graphql/queries';
import TrustedPartnerLayout from '@src/components/layouts/TrustedPartnerLayout';

import type {
  BankingInfoRequestReturn,
  BankingInfoRequestParams,
} from '@src/graphql/queries';
import {
  WalletEntryStatus,
  BankingInfoValidationStatus,
} from '@src/types/enums';
import { trackEvent } from '@src/lib/analytics';

export function RequestBankingInfoView() {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { data, loading, error } = useQuery<
    BankingInfoRequestReturn,
    BankingInfoRequestParams
  >(BANKING_INFO_REQUEST, {
    skip: !token,
    pollInterval: 10000,
    variables: {
      token,
    },
  });

  usePageTrack('request_banking_info_view', 'core');

  if (!loading && error) {
    history.push('/p/invalid_token');
  }

  if (loading || !data || !data.bankingInfoRequest) {
    return null;
  }

  const { bankingInfoRequest } = data;

  const paymentsFailed =
    bankingInfoRequest.bankingInfoValidation?.validationStatus ===
      BankingInfoValidationStatus.VALIDATED &&
    bankingInfoRequest.invoicePayments.filter(
      (invoicePayment) => invoicePayment.status === WalletEntryStatus.FAILED
    ).length > 0;

  if (paymentsFailed) {
    trackEvent('request_banking_info_view', {
      bankingInfovalidation: BankingInfoValidationStatus.VALIDATED,
      paymentsFailed: true,
    });
    return (
      <TrustedPartnerLayout>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={5}>
            <FailedPayment
              bankName={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo?.bankName
              }
              bankLogoUrl={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo
                  ?.bankLogoUrl
              }
              clabe={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo?.clabe
              }
              taxPayerName={bankingInfoRequest.payeeTaxpayerInfo.taxpayerName}
              totalAmount={bankingInfoRequest.amount}
            />
          </Grid>
        </Grid>
      </TrustedPartnerLayout>
    );
  }

  if (
    bankingInfoRequest.bankingInfoValidation?.validationStatus ===
    BankingInfoValidationStatus.PROCESSING
  ) {
    trackEvent('request_banking_info_view', {
      bankingInfovalidation: BankingInfoValidationStatus.PROCESSING,
      paymentsFailed: false,
    });
    return (
      <TrustedPartnerLayout>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={5}>
            <ProcessingPayment
              bankName={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo?.bankName
              }
              bankLogoUrl={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo
                  ?.bankLogoUrl
              }
              clabe={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo?.clabe
              }
              taxPayerName={bankingInfoRequest.payeeTaxpayerInfo.taxpayerName}
              taxpayerIdentifier={
                bankingInfoRequest.payeeTaxpayerInfo.taxpayerIdentifier
              }
              email={bankingInfoRequest.email}
              totalAmount={bankingInfoRequest.amount}
            />
          </Grid>
        </Grid>
      </TrustedPartnerLayout>
    );
  }

  if (
    bankingInfoRequest.bankingInfoValidation?.validationStatus ===
    BankingInfoValidationStatus.VALIDATED
  ) {
    trackEvent('request_banking_info_view', {
      bankingInfovalidation: BankingInfoValidationStatus.VALIDATED,
      paymentsFailed: false,
    });
    return (
      <TrustedPartnerLayout>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={5}>
            <ValidatedPayment
              bankName={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo?.bankName
              }
              bankLogoUrl={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo
                  ?.bankLogoUrl
              }
              clabe={
                bankingInfoRequest.bankingInfoValidation?.bankingInfo?.clabe
              }
              taxPayerName={bankingInfoRequest.payeeTaxpayerInfo.taxpayerName}
              taxpayerIdentifier={
                bankingInfoRequest.payeeTaxpayerInfo.taxpayerIdentifier
              }
              email={bankingInfoRequest.email}
              totalAmount={bankingInfoRequest.amount}
            />
          </Grid>
        </Grid>
      </TrustedPartnerLayout>
    );
  }

  return (
    <TrustedPartnerLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={5}>
          <RequestBankingInfoViewForm
            totalInvoices={bankingInfoRequest.invoicesCount}
            totalAmount={bankingInfoRequest.amount}
            payeeTaxPayerInfo={bankingInfoRequest.payeeTaxpayerInfo}
            payerTaxPayerInfo={bankingInfoRequest.payerTaxpayerInfo}
            bankingInfoValidation={bankingInfoRequest.bankingInfoValidation}
          />
        </Grid>
      </Grid>
    </TrustedPartnerLayout>
  );
}

export default RequestBankingInfoView;
