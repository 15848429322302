import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './ProcessingPayment.styles';
import { Header, Text, Card } from '@src/components/ui';
import LinkButton from '@src/components/shared/buttons/LinkButton';
import Clabe from '@src/components/shared/Clabe';
import Currency from '@src/components/i18n/Currency';
import { ArrowRight as ArrowRightIcon } from '@src/components/ui/Icon/outline';
import {
  CenteredFlexContainer,
  FlexContainer,
  Grid,
  Spacer,
} from '@src/components/shared/layouts';
import DataContent from '../DataContent';
import { Clock } from '@src/components/ui/Icon/solid';

type ProcessingPaymentProps = {
  totalAmount: number;
  taxPayerName?: string;
  taxpayerIdentifier?: string;
  email?: string;
  clabe?: string;
  bankName?: string;
  bankLogoUrl?: string;
};
export const ProcessingPayment = ({
  totalAmount,
  bankName,
  taxPayerName,
  taxpayerIdentifier,
  email,
  bankLogoUrl,
  clabe = '',
}: ProcessingPaymentProps) => {
  const intl = useIntl();
  return (
    <Card padding="8" depth="sm">
      <CenteredFlexContainer direction="column">
        <S.GrayCircle>
          <S.BlueCircle>
            <ArrowRightIcon color="white" />
          </S.BlueCircle>
        </S.GrayCircle>
        <Spacer direction="vertical" margin="6" />
        <Header as="h5">
          {intl.formatMessage({
            defaultMessage: 'Tu pago se está procesando',
            id: 'v9aGBR',
          })}
        </Header>
        <Spacer direction="vertical" margin="2" />
        <Text color="neutral" colorStep="600" light textAlign="center">
          {intl.formatMessage({
            defaultMessage:
              'Estamos procesando tu pago. Te enviaremos un email notificándote cuando el proceso termine, puede tomar unos minutos.',
            id: 'Xi+P01',
          })}
        </Text>
        <Spacer direction="vertical" margin="12" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="User"
              label={intl.formatMessage({
                defaultMessage: 'Cliente',
                id: 'DshuY+',
              })}
              value={taxPayerName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="Money"
              label={intl.formatMessage({
                defaultMessage: 'Monto',
                id: 'Axixya',
              })}
              value={<Currency value={totalAmount} />}
            />
          </Grid>
        </Grid>
        <Spacer direction="vertical" margin="8" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="Send"
              label={intl.formatMessage({
                defaultMessage: 'CLABE destino',
                id: 'ZDHRsL',
              })}
              value={
                <FlexContainer alignItems="center">
                  <Clabe account={clabe} as="strong" />
                  <Spacer direction="horizontal" margin="2" />
                  <Clock size={14} />
                </FlexContainer>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DataContent
              imgSrc={bankLogoUrl}
              value={bankName}
              label={intl.formatMessage({
                defaultMessage: 'Institucion Bancaria',
                id: 'F8in/6',
              })}
            />
          </Grid>
        </Grid>
        <Spacer direction="vertical" margin="16" />
        <LinkButton
          target="_blank"
          href={`/p/sign-up?email=${email}&rfc=${taxpayerIdentifier}`}
          block
          variant="solid"
          color="primary"
        >
          {intl.formatMessage({
            defaultMessage: 'Prueba Higo',
            id: 'zj0FQO',
          })}
        </LinkButton>
      </CenteredFlexContainer>
    </Card>
  );
};

export default ProcessingPayment;
