import { gql } from '@apollo/client';

const CREATE_BUSINESS_USER_ACCOUNT_MUTATION = gql`
  mutation CreateBusinessUserAccount(
    $invitationToken: String!
    $businessUserAccount: BusinessUserAccountInput!
  ) {
    createBusinessUserAccount(
      invitationToken: $invitationToken
      businessUserAccount: $businessUserAccount
    ) {
      token
    }
  }
`;

export type BusinessUserAccountInput = {
  firstName: string;
  lastName: string;
  password: string;
  locale: string;
  phone?: string;
};

export type CreateBusinessUserAccountVariables = {
  invitationToken: string;
  businessUserAccount: BusinessUserAccountInput;
};

export type CreateBusinessUserAccountReturn = {
  createBusinessUserAccount: {
    token: string;
  };
};

export default CREATE_BUSINESS_USER_ACCOUNT_MUTATION;
