/* eslint-disable no-var */
import React from 'react';
import * as Sentry from '@sentry/react';

import RuntimeError from '@src/components/pages/RuntimeError';

function AppErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={<RuntimeError />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default AppErrorBoundary;
