import React from 'react';
import { useIntl } from 'react-intl';

import BankingInformationForm from '@src/components/shared/form/BankingInformationForm';
import * as S from './BankingInfo.styles';
import { BankingInfo } from '@src/types/models';
import { Header } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';

interface BankingInfoModalProps {
  taxpayerName: string;
  onSubmit: (bankingInfo: BankingInfo) => void;
  onClose?: () => void;
}

function BankingInfoModal({
  taxpayerName,
  onSubmit,
  onClose,
}: BankingInfoModalProps) {
  const intl = useIntl();

  const handleSubmit = (bankingInfo: BankingInfo) => {
    onSubmit(bankingInfo);
    onClose?.();
  };

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage(
          {
            defaultMessage: 'Agrega una CLABE para {taxpayerName}',
            id: 'NpB1cs',
          },
          {
            taxpayerName,
          }
        )}
      </Header>
      <Spacer />
      <BankingInformationForm
        onSubmit={handleSubmit}
        defaultValues={{
          recipient: taxpayerName,
        }}
      />
    </S.Container>
  );
}

BankingInfoModal.displayName = 'BankingInfoModal';

export default BankingInfoModal;
