import React from 'react';

import * as S from './EmptyResults.styled';
import { Meh } from '@src/components/ui/Icon/outline';

interface EmptyResultsProps {
  label: string;
  description?: string;
  icon?: React.ReactNode;
  compact?: boolean;
  action?: React.ReactNode;
}

function EmptyResults({
  label,
  icon,
  description,
  compact,
  action,
}: EmptyResultsProps) {
  return (
    <S.EmptyState compact={compact}>
      {!compact && (
        <S.Icon>
          {icon || (
            <S.IconCircle>
              <Meh />
            </S.IconCircle>
          )}
        </S.Icon>
      )}
      <h2>{label}</h2>
      {description && <p>{description}</p>}
      {action && <S.Action>{action}</S.Action>}
    </S.EmptyState>
  );
}

export default EmptyResults;
