import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export const Chain = styled.p`
  word-break: break-all;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;
