import { BankingInfoValidationStatus } from '@src/types/enums';

import { Business } from '@src/types/models';

function useBusinessBankingInfoValidation(business: Business) {
  const { bankingInfoValidation, defaultBankingInfo } = business;
  const validatingBankingInfo = bankingInfoValidation?.bankingInfo;

  return {
    validatingBankingInfo,
    isVerified:
      defaultBankingInfo?.validationStatus ===
      BankingInfoValidationStatus.VALIDATED,
    isRejected:
      bankingInfoValidation?.validationStatus ===
        BankingInfoValidationStatus.REJECTED ||
      defaultBankingInfo?.validationStatus ===
        BankingInfoValidationStatus.REJECTED,
    isValidating:
      bankingInfoValidation?.validationStatus ===
      BankingInfoValidationStatus.PROCESSING,
    bankingInfo: defaultBankingInfo || validatingBankingInfo,
    rejectedAccount: bankingInfoValidation?.clabe || '',
  };
}

export default useBusinessBankingInfoValidation;
