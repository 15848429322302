import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './PasswordReset.styles';
import MinimalLayout from '@src/components/layouts/MinimalLayout';
import PasswordResetForm from '@src/components/pages/PasswordReset/components/PasswordResetForm';
import Header from '@src/components/ui/Header';
import LinkButton from '@src/components/shared/buttons/LinkButton';

export function PasswordReset() {
  const { formatMessage } = useIntl();
  return (
    <MinimalLayout
      title={formatMessage({
        defaultMessage: 'Restablecer contraseña',
        id: 'FBUX0U',
      })}
    >
      <S.Container>
        <S.FormContainer>
          <Header as="h2">
            {formatMessage({
              defaultMessage: 'Restablece tu contraseña',
              id: 'h1YK1v',
            })}
            <span className="green-dot">.</span>
          </Header>
          <S.Legend>
            {formatMessage({
              defaultMessage:
                'Escribe tu correo y te enviaremos un link para que puedas restablecer tu contraseña.',
              id: '0X3zKi',
            })}
          </S.Legend>
          <PasswordResetForm />
          <LinkButton
            href="/p/login"
            block
            variant="transparent"
            color="neutral"
          >
            {formatMessage({
              defaultMessage: 'Iniciar sesión',
              id: 'Mwm1HV',
            })}
          </LinkButton>
        </S.FormContainer>
      </S.Container>
    </MinimalLayout>
  );
}

export default PasswordReset;
