import styled from 'styled-components';

const CenteredFlexContainer = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: center;
  align-items: center;
`;

export default CenteredFlexContainer;
