import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import Button from '@src/components/ui/Button';
import { Header, TextField } from '@src/components/ui';
import * as S from './CreateBusinessUserForm.styles';

import CREATE_BUSINESS_USER_MUTATION, {
  CreateBusinessUserReturn,
  CreateBusinessUserParams,
} from '@src/graphql/mutations/createBusinessUser';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { CREATE_BUSINESS_USER } from '@src/constants/events';
import useSettings from '@src/hooks/useSettings';
import BUSINESS_USERS_QUERY from '@src/graphql/queries/businessUsers';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';

interface CreateBusinessUserFormProps {
  onClose?: () => void;
}

type FormData = {
  email: string;
  jobPosition: string;
  firstName: string;
  lastName: string;
};

function CreateBusinessUserForm({ onClose }: CreateBusinessUserFormProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const { toast } = useToast();
  const [createBusinessUser, { loading }] = useMutation<
    CreateBusinessUserReturn,
    CreateBusinessUserParams
  >(CREATE_BUSINESS_USER_MUTATION, {
    refetchQueries: [BUSINESS_USERS_QUERY],
    onCompleted: () => {
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Usuario agregado',
          id: 'RHKuWP',
        }),
        intl.formatMessage({
          defaultMessage:
            'Se ha agregado con éxito el usuario a la organización',
          id: 'eCP1pX',
        })
      );
      trackEvent(CREATE_BUSINESS_USER, {
        creator: 'admin',
        view: 'SettingsUser',
      });
      onClose?.();
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al crear un usuario',
          id: 'g/FWRw',
        }),
        e.message
      );
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FormData) => {
    if (business?.id) {
      createBusinessUser({
        variables: {
          businessId: business.id,
          businessUser: {
            email: data.email,
            jobPosition: data.jobPosition,
            firstName: data.firstName,
            lastName: data.lastName,
          },
        },
      });
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Agregar usuario',
          id: 'FIYZhs',
        })}
      </Header>
      <Spacer />
      <TextField
        fullWidth
        type="text"
        {...register('firstName', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        error={!!errors.firstName?.message}
        helperText={errors.firstName?.message}
        label={intl.formatMessage({
          defaultMessage: 'Nombre',
          id: 'hCOqfl',
        })}
      />

      <TextField
        fullWidth
        type="text"
        {...register('lastName', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        error={!!errors.lastName?.message}
        helperText={errors.lastName?.message}
        label={intl.formatMessage({
          defaultMessage: 'Apellidos',
          id: 'nVSFBU',
        })}
      />

      <TextField
        fullWidth
        type="text"
        {...register('jobPosition', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        error={!!errors.jobPosition?.message}
        helperText={errors.jobPosition?.message}
        label={intl.formatMessage({
          defaultMessage: 'Puesto de trabajo',
          id: 'JfDMD6',
        })}
      />
      <TextField
        fullWidth
        type="email"
        {...register('email', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        error={!!errors.email?.message}
        helperText={
          errors.email?.message ||
          intl.formatMessage({
            defaultMessage:
              'Se le enviará un correo para unirse a la organización',
            id: 'XV8rS3',
          })
        }
        label={intl.formatMessage({
          defaultMessage: 'Correo',
          id: 'otXL2t',
        })}
      />
      <Spacer />
      <FlexContainer justifyContent="flex-end">
        <Button variant="ghosted" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button type="submit" loading={loading}>
          {intl.formatMessage({
            defaultMessage: 'Agregar usuario',
            id: 'FIYZhs',
          })}
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

CreateBusinessUserForm.displayName = 'CreateBusinessUserForm';

export default CreateBusinessUserForm;
