import { gql } from '@apollo/client';

const SUPPLIER_PROFILE_ATTRIBUTES = gql`
  fragment SupplierProfileAttributes on BusinessProfile {
    id
    name
    phone
    about
    hashId
    imageUrl
    verified
    createdAt
    websiteUrl
    businessId
    description
    activeClients
    imageColorCode
    imageShortName
    completedOrders
    defaultTaxPayerInfo {
      id
      taxpayerIdentifier
      taxpayerName
    }
  }
`;

export default SUPPLIER_PROFILE_ATTRIBUTES;
