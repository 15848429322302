import React from 'react';
import { useIntl } from 'react-intl';

import Dropdown, { DropdownItem } from '@src/components/ui/Dropdown';
import Permission from '@src/components/shared/Permission';
import {
  InvoiceApprovalStatus,
  InvoicePaymentStatus,
  PermissionName,
} from '@src/types/enums';
import useModal from '@src/hooks/useModal';
import RejectInvoiceForm from '@src/components/shared/form/RejectInvoiceForm';
import InvoicePayments from '../InvoicePayments';
import { Invoice } from '@src/types/models';

interface InvoiceActionDropdownProps {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'approvalStatus'
    | 'paymentStatus'
    | 'businessReceiver'
    | 'businessEmitter'
    | 'businessRelationship'
    | 'paymentStatus'
    | 'total'
    | 'outstandingBalance'
    | 'currency'
    | 'invoiceUrl'
  >;
  onEdit: (value: boolean) => void;
}

function InvoiceActionDropdown({
  invoice,
  onEdit,
}: InvoiceActionDropdownProps) {
  const intl = useIntl();
  const { open: openRejectionModal } = useModal(RejectInvoiceForm);
  const { open: openInvoicePayments } = useModal(InvoicePayments);

  const isApproved = invoice.approvalStatus === InvoiceApprovalStatus.APPROVED;
  const isUnpaid = invoice.paymentStatus !== InvoicePaymentStatus.PAID;
  const canManuallyPay = isApproved && isUnpaid;
  const isUrl = invoice.invoiceUrl;
  const isRejected = invoice.approvalStatus === InvoiceApprovalStatus.REJECTED;

  const updateInvoiceAsPaid = () => {
    openInvoicePayments({
      invoice: invoice,
    });
  };

  if (isRejected) {
    return null;
  }

  return (
    <Dropdown
      label={intl.formatMessage({
        id: 'GUG1Jz',
        defaultMessage: 'Más acciones',
      })}
    >
      {isApproved && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
          ]}
        >
          <DropdownItem onClick={() => onEdit(true)}>
            {intl.formatMessage({
              id: 'VGHwB5',
              defaultMessage: 'Editar',
            })}
          </DropdownItem>
        </Permission>
      )}

      {canManuallyPay && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
          ]}
        >
          {
            <DropdownItem onClick={updateInvoiceAsPaid}>
              {intl.formatMessage({
                id: 'ix67EA',
                defaultMessage: 'Marcar pago manual',
              })}
            </DropdownItem>
          }
        </Permission>
      )}

      {isUrl && (
        <DropdownItem>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={invoice.invoiceUrl}
          >
            {intl.formatMessage({
              id: 'xs3X3F',
              defaultMessage: 'Descargar a XML',
            })}
          </a>
        </DropdownItem>
      )}

      {isUnpaid && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
          ]}
        >
          <DropdownItem
            onClick={() =>
              openRejectionModal({
                invoiceId: invoice.id,
              })
            }
          >
            {intl.formatMessage({
              id: 'YRNntu',
              defaultMessage: 'Rechazar',
            })}
          </DropdownItem>
        </Permission>
      )}
    </Dropdown>
  );
}

export default InvoiceActionDropdown;
