import * as Sentry from '@sentry/react';

export function log(error: Error) {
  if (process.env.NODE_ENV === 'production') {
    try {
      Sentry.captureException(error);
    } catch (e) {
      console.log('Report Error:', e);
    }
  } else {
    console.log('Report Error:', error);
  }
}

export function resetUser() {
  if (process.env.NODE_ENV === 'production') {
    try {
      Sentry.configureScope((scope) => scope.setUser(null));
    } catch (e) {
      console.log('Report Error:', e);
    }
  }
}
