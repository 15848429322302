import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import useSettings from '@src/hooks/useSettings';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import Filters from '@src/components/shared/Filters';
import InvoicesTable, {
  InvoiceActionDropdown,
} from '@src/components/shared/InvoicesTable';
import Card from '@src/components/ui/Card';
import EmptyResults from '@src/components/shared/EmptyResults';
import { getPaginationValuesForQuery } from '@src/utils/pagination';

import { InvoicePaymentStatus, InvoiceVoucherType } from '@src/types/enums';

const TableCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export function PaidInvoicesTable() {
  const limit = 50;
  const intl = useIntl();
  const { business, currentUser } = useSettings();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filters] = useInvoiceFilters({
    paymentStatus: [InvoicePaymentStatus.PAID],
    voucherType: [InvoiceVoucherType.INVOICE],
    archived: false,
  });
  const {
    loading,
    data: { invoices, totalAmount },
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
    nodes,
  } = useInvoicesListQuery({
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  const clearSelection = () => {
    setSelectedIds([]);
  };

  return (
    <TableCard>
      <Filters fields={['invoicingDate', 'paymentDate', 'businessEmitterId']} />
      <InvoicesTable
        columns={{
          id: 100,
          receiverCountryTaxPayerInfo: 100,
          invoicingDate: 200,
          paymentDate: 200,
          businessEmitter: 300,
          total: 150,
          paymentStatus: 100,
          costCenter: 200,
        }}
        nodes={nodes}
        invoices={invoices}
        loading={loading}
        showActionBar
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        limit={limit}
        page={page}
        totalAmount={totalAmount}
        actions={
          <InvoiceActionDropdown
            invoiceIds={selectedIds}
            count={count}
            businessId={business?.id}
            userEmail={currentUser?.email}
            archivable
            exportable
            onArchive={clearSelection}
            onExport={clearSelection}
          />
        }
        emptyElement={
          <EmptyResults
            label={intl.formatMessage({
              defaultMessage: 'Aún no tienes cuentas pagadas',
              id: 'bUMVjz',
            })}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas las cuentas pagadas que tengas en Higo.',
              id: 'ZVH+eY',
            })}
          />
        }
        count={count}
        onNext={next}
        hasNext={hasNext}
        onPrevious={previous}
        hasPrevious={hasPrevious}
        selectable
      />
    </TableCard>
  );
}

export default PaidInvoicesTable;
