import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  PaginationContainer,
  Controls,
  PageInfo,
} from './PaginationControls.styles';
import IconButton from '@src/components/ui/IconButton';
import { ChevronRight, ChevronLeft } from '@src/components/ui/Icon/outline';

type PaginationControlsProps = {
  loading?: boolean;
  limit?: number;
  count?: number;
  itemCount?: number;
  page?: number;
  hasNext?: boolean;
  endMessage?: string | React.ReactNode;
  hasPrevious?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onLimitChange?: (limit: number) => void;
};

const PaginationControls = ({
  loading,
  page = 1,
  itemCount = 0,
  count,
  limit = 0,
  hasNext,
  onNext,
  hasPrevious,
  onPrevious,
  endMessage,
}: PaginationControlsProps) => {
  if (count === 0 && !loading) {
    return null;
  }

  return (
    <PaginationContainer>
      <PageInfo>
        <Controls>
          <IconButton
            color="neutral"
            variant="outlined"
            onClick={onPrevious}
            disabled={!hasPrevious || loading || page === 1}
          >
            <ChevronLeft size={18} />
          </IconButton>
          <IconButton
            color="neutral"
            variant="outlined"
            onClick={onNext}
            disabled={!hasNext || loading}
          >
            <ChevronRight size={18} />
          </IconButton>
        </Controls>
        {!!count && count > 0 && (
          <FormattedMessage
            defaultMessage="{start}-{end} de {count}"
            id="PCZF8e"
            values={{
              start: (page - 1) * limit + 1,
              end: (page - 1) * limit + itemCount,
              count: count,
            }}
          />
        )}
      </PageInfo>
      <div>{endMessage}</div>
    </PaginationContainer>
  );
};

export default PaginationControls;
