import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Backdrop, Card, Search, Alert, Button } from '@src/components/ui';
import * as S from './GlobalSearch.styles';
import useInvoiceResults from '@src/components/shared/GlobalSearch/hooks/useInvoiceResults';
import EmptyResults from '@src/components/shared/EmptyResults';
import { Mail } from '@src/components/ui/Icon/outline';
import useSettings from '@src/hooks/useSettings';
import { trackEvent } from '@src/lib/analytics';
import { SEARCH_CLICKED, SEARCH_CTA_CLICKED } from '@src/constants/events';

function GlobalSearch() {
  const intl = useIntl();
  const [value, setValue] = useState('');
  const business = useSettings('business');
  const [backdrop, setBackdrop] = useState(false);
  const [
    fetchInvoices,
    {
      loading: invoicesLoading,
      error: invoicesError,
      component: invoiceResults,
    },
  ] = useInvoiceResults();
  const loading = invoicesLoading;
  const error = invoicesError;

  const handleClear = () => {
    setValue('');
  };

  const handleOnChange = (event) => {
    fetchInvoices(event.target.value);
    setValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchInvoices(event.target.value);
    }
    if (event.key === 'Escape') {
      setValue('');
      setBackdrop(false);
      event.target.blur();
    }
  };

  const handleInputFocus = () => {
    setBackdrop(true);
    trackEvent(SEARCH_CLICKED);
  };

  const handleBackdropClick = () => setBackdrop(false);

  const handleCtaClicked = () => {
    trackEvent(SEARCH_CTA_CLICKED);
  };

  return (
    <div>
      <S.SearchContainer
        zIndex={backdrop ? 60 : 0}
        width={backdrop ? 400 : 200}
      >
        <Search
          value={value}
          loading={loading}
          onKeyDown={handleInputKeyDown}
          onClear={handleClear}
          fullWidth
          onFocus={handleInputFocus}
          onChange={handleOnChange}
          placeholder={intl.formatMessage({
            defaultMessage: 'Buscar Folio Fiscal',
            id: '5vfG9J',
          })}
        />
        {backdrop && value && !loading && (
          <Card padding="8" className="results-card">
            {error && (
              <Alert color="danger">
                {intl.formatMessage({
                  defaultMessage: 'Tuvimos un problema al buscar tus facturas',
                  id: 't+R2gG',
                })}
              </Alert>
            )}

            {invoiceResults}

            {!invoiceResults && !error && (
              <EmptyResults
                compact
                label={intl.formatMessage({
                  defaultMessage: 'No se encontraron resultados',
                  id: 'QMFjdU',
                })}
                description={intl.formatMessage({
                  defaultMessage:
                    'Si no encontraste tus facturas, no te preocupes. ',
                  id: 'nqlbnK',
                })}
                action={
                  <a href={`mailto:${business.mailboxEmail}`}>
                    <Button
                      compact
                      color="primary"
                      variant="ghosted"
                      startIcon={<Mail />}
                      onClick={handleCtaClicked}
                    >
                      {intl.formatMessage({
                        defaultMessage: 'Envía el XML a tu correo',
                        id: 'F3Xsm1',
                      })}
                    </Button>
                  </a>
                }
              />
            )}
          </Card>
        )}
      </S.SearchContainer>
      <Backdrop open={backdrop} onClick={handleBackdropClick} />
    </div>
  );
}

export default GlobalSearch;
