import * as Types from '../../../../../../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RecordNotificationEventable_CommentEvent_Fragment = {
  __typename: 'CommentEvent';
  id: string;
  comment?: string | null;
  businessUsers?: Array<{
    __typename?: 'BusinessUser';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
  }> | null;
};

export type RecordNotificationEventable_CostCenterEvent_Fragment = {
  __typename: 'CostCenterEvent';
  id: string;
  costCenter: { __typename?: 'CostCenter'; id: string; name: string };
};

export type RecordNotificationEventable_InvoiceCreditNotePaymentEvent_Fragment = {
  __typename: 'InvoiceCreditNotePaymentEvent';
};

export type RecordNotificationEventable_InvoiceManualPaymentEvent_Fragment = {
  __typename: 'InvoiceManualPaymentEvent';
  id: string;
  amount?: any | null;
};

export type RecordNotificationEventable_InvoiceReceivedEvent_Fragment = {
  __typename: 'InvoiceReceivedEvent';
};

export type RecordNotificationEventable_InvoiceStpPaymentEvent_Fragment = {
  __typename: 'InvoiceSTPPaymentEvent';
  id: string;
  walletEntry: {
    __typename?: 'WalletEntry';
    id: string;
    amount: any;
    recipient?: {
      __typename?: 'BankingInfo';
      id: string;
      clabe: string;
      bankName: string;
      recipient: string;
    } | null;
    user: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
    };
  };
};

export type RecordNotificationEventable_InvoiceStatusEvent_Fragment = {
  __typename: 'InvoiceStatusEvent';
  id: string;
  status: Types.InvoiceApprovalStatusEnum;
  paymentTerms?: number | null;
  expiryDate?: any | null;
};

export type RecordNotificationEventableFragment =
  | RecordNotificationEventable_CommentEvent_Fragment
  | RecordNotificationEventable_CostCenterEvent_Fragment
  | RecordNotificationEventable_InvoiceCreditNotePaymentEvent_Fragment
  | RecordNotificationEventable_InvoiceManualPaymentEvent_Fragment
  | RecordNotificationEventable_InvoiceReceivedEvent_Fragment
  | RecordNotificationEventable_InvoiceStpPaymentEvent_Fragment
  | RecordNotificationEventable_InvoiceStatusEvent_Fragment;

export const RecordNotificationEventableFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecordNotificationEventable' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Eventable' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CommentEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businessUsers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CostCenterEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'costCenter' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InvoiceStatusEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentTerms' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InvoiceManualPaymentEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'InvoiceSTPPaymentEvent' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'walletEntry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'amount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recipient' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'clabe' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bankName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'recipient' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RecordNotificationEventableFragment, unknown>;
