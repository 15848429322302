import { LOCATION_ADDRESS_KEYS } from '@src/constants/locations';

import { Address } from '@src/types/models';

type Config = {
  showCountry?: boolean;
};

const defaultConfig = {
  showCountry: true,
};

export function addressString(
  address?: string | Address,
  config: Config = defaultConfig
) {
  if (!address) {
    return '';
  }

  if (typeof address === 'string') {
    return address;
  }

  const keys = config.showCountry
    ? LOCATION_ADDRESS_KEYS
    : LOCATION_ADDRESS_KEYS.filter((k) => k !== 'country');

  return keys.reduce((addressString, key, index) => {
    const separator = index === 0 ? ' ' : index + 1 < keys.length ? ', ' : '';
    return address[key]
      ? addressString + `${address[key] || ''}${separator}`
      : addressString;
  }, '');
}
