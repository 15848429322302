import styled from 'styled-components';
import { BackdropProps } from './types';

export const Wrapper = styled.div<BackdropProps>`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -1;

  &.backdrop-enter {
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      visibility 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &.backdrop-enter-done {
    opacity: 1;
    z-index: ${({ theme }) => theme.zIndex[50]};
  }
  &.backdrop-enter-active {
  }
  &.backdrop-exit {
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      visibility 225ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    opacity: 0;
  }
  &.backdrop-exit-done {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  &.backdrop-exit-active {
    z-index: ${({ theme }) => theme.zIndex[50]};
  }
`;

export const Overlay = styled.div<BackdropProps>`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  background-color: ${({ invisible, theme }) =>
    invisible
      ? 'transparent'
      : theme.colors.neutral[900] + theme.opacityHex[40]};
`;
