import React from 'react';
import { Link } from 'react-router-dom';

import { StyledSidebar } from './Sidebar.styles';
import Logo from '@src/components/shared/Logo';

export interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  footer?: React.ReactNode;
}

export default function Sidebar(props: SidebarProps) {
  const { children, footer } = props;

  return (
    <StyledSidebar>
      <div className="sidebar-header">
        <Link to="/">
          <div className="logo-wrapper">
            <Logo variant="isotype" size={36} />
          </div>
        </Link>
      </div>
      <div className="sidebar-body">
        <div className="sidebar-links">{children}</div>
      </div>
      <div className="sidebar-footer">{footer}</div>
    </StyledSidebar>
  );
}
