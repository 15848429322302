import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Star: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
        stroke="none"
        strokeWidth="0"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6726 0.668032C11.5462 0.412054 11.2855 0.25 11 0.25C10.7146 0.25 10.4538 0.412054 10.3275 0.668032L7.41183 6.57485L0.891551 7.52789C0.609131 7.56917 0.374621 7.76717 0.286587 8.03868C0.198554 8.31018 0.272259 8.60812 0.476722 8.80727L5.19413 13.402L4.08082 19.8932C4.03255 20.1746 4.14824 20.459 4.37922 20.6268C4.61021 20.7946 4.91642 20.8167 5.16911 20.6838L11 17.6174L16.8309 20.6838C17.0836 20.8167 17.3898 20.7946 17.6208 20.6268C17.8518 20.459 17.9675 20.1746 17.9192 19.8932L16.8059 13.402L21.5233 8.80727C21.7278 8.60812 21.8015 8.31018 21.7135 8.03868C21.6254 7.76717 21.3909 7.56917 21.1085 7.52789L14.5882 6.57485L11.6726 0.668032Z"
        />
      </svg>
    );
  }
);

Star.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Star.displayName = 'Star';

export default Star;
