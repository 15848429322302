import styled from 'styled-components';

import {
  FlexColumnContainer,
  FlexRowContainer,
} from '@src/components/shared/layouts';

export const Recipient = styled.p`
  max-width: 5rem;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[4]};
  > div {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
`;

export const Balance = styled.p`
  margin-bottom: 0;
  line-height: 1;
  small {
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: 0;
  }
  span {
    font-size: ${({ theme }) => theme.fontSize['4xl']};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    small {
      font-size: ${({ theme }) => theme.fontSize['xl']};
    }
    span {
      font-size: ${({ theme }) => theme.fontSize['3xl']};
    }
  }
`;

export const WalletCardContent = styled(FlexRowContainer)`
  padding: ${({ theme }) => theme.spacing[8]};
  box-sizing: border-box;
  justify-content: space-between;

  button:first-child {
    margin-bottom: ${({ theme }) => theme.spacing[4]};

    @media (max-width: 768px) {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.spacing[2]};
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Actions = styled(FlexColumnContainer)`
  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

export const BalanceContainer = styled(FlexColumnContainer)`
  justify-content: space-between;
`;
