import { gql } from '@apollo/client';
import { InvoicePayment } from '@src/types/models';

const INVOICE_PAYMENTS_QUERY = gql`
  query Invoice($businessId: ID!, $invoiceId: ID!) {
    invoicePayments(businessId: $businessId, invoiceId: $invoiceId) {
      amount
      id
      walletEntryId
      walletEntryHashId
      receiverWalletEntryId
      receiverWalletEntryHashId
      creditNoteId
      paymentProof
      paymentType
      createdAt
      status
    }
  }
`;

export type InvoicePaymentsReturn = {
  invoicePayments: Array<Required<InvoicePayment>>;
};

export type InvoicePaymentsVariables = {
  businessId?: string;
  invoiceId: string;
};

export default INVOICE_PAYMENTS_QUERY;
