import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    flex-grow: 1;
    margin-right: ${({ theme }) => theme.spacing[4]};
  }
`;
export const InputHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[4]};

  .mail-icon {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    height: ${({ theme }) => theme.spacing[10]};
    width: ${({ theme }) => theme.spacing[10]};
    border-radius: 50%;
    margin-right: ${({ theme }) => theme.spacing[4]};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.neutral[600]};
  }
`;

export const Container = styled.div`
  width: 32rem;

  .resend-subtitle {
    margin-top: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[8]};
    color: ${({ theme }) => theme.colors.neutral[600]};
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }
`;
