import * as Types from '../../../../../../generated/graphql';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { HistoricalRecordAccountPayableEventFragmentDoc } from './historicalRecordAccountPayableEvent.fragment';
export type HistoricalRecordAccountPayableEventsQueryVariables = Types.Exact<{
  businessId: Types.Scalars['ID'];
  invoiceId: Types.Scalars['ID'];
}>;

export type HistoricalRecordAccountPayableEventsQuery = {
  __typename?: 'Query';
  accountPayableEvents: Array<{
    __typename?: 'AccountPayableEvent';
    id: string;
    createdAt: any;
    invoice: {
      __typename?: 'Invoice';
      id: string;
      expiryDate?: any | null;
      paymentTerms?: number | null;
      cancelationReason?: string | null;
      currency: string;
    };
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      profileImage: string;
    } | null;
    eventable:
      | {
          __typename: 'CommentEvent';
          id: string;
          comment?: string | null;
          businessUsers?: Array<{
            __typename?: 'BusinessUser';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
          }> | null;
        }
      | {
          __typename: 'CostCenterEvent';
          id: string;
          costCenter: { __typename?: 'CostCenter'; id: string; name: string };
        }
      | { __typename: 'InvoiceCreditNotePaymentEvent' }
      | {
          __typename: 'InvoiceManualPaymentEvent';
          id: string;
          amount?: any | null;
        }
      | { __typename: 'InvoiceReceivedEvent' }
      | {
          __typename: 'InvoiceSTPPaymentEvent';
          id: string;
          walletEntry: {
            __typename?: 'WalletEntry';
            id: string;
            amount: any;
            recipient?: {
              __typename?: 'BankingInfo';
              id: string;
              clabe: string;
              bankName: string;
              recipient: string;
            } | null;
            user: {
              __typename?: 'User';
              id: string;
              firstName: string;
              lastName: string;
            };
          };
        }
      | {
          __typename: 'InvoiceStatusEvent';
          id: string;
          status: Types.InvoiceApprovalStatusEnum;
          paymentTerms?: number | null;
          expiryDate?: any | null;
        };
  }>;
};

export const HistoricalRecordAccountPayableEventsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HistoricalRecordAccountPayableEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invoiceId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountPayableEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'invoiceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'HistoricalRecordAccountPayableEvent',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HistoricalRecordAccountPayableEventFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  HistoricalRecordAccountPayableEventsQuery,
  HistoricalRecordAccountPayableEventsQueryVariables
>;
