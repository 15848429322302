import { gql } from '@apollo/client';

export const RESEND_HIGO_PAYMENT_NOTIFICATION = gql`
  mutation ResendHigoPaymentNotification(
    $businessId: ID!
    $higoPaymentSentEntryId: ID!
    $user: InvitedUserInput!
  ) {
    resendHigoPaymentNotification(
      businessId: $businessId
      higoPaymentSentEntryId: $higoPaymentSentEntryId
      user: $user
    ) {
      token
    }
  }
`;

export default RESEND_HIGO_PAYMENT_NOTIFICATION;
