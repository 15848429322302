import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Puzzle: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M10.0596 5.04382C10.6119 5.04382 11.0596 5.49154 11.0596 6.04382V17.9555C11.0596 18.5078 10.6119 18.9555 10.0596 18.9555C9.50729 18.9555 9.05957 18.5078 9.05957 17.9555V6.04382C9.05957 5.49154 9.50729 5.04382 10.0596 5.04382Z" />
        <path d="M5.7541 10.1024C5.7541 8.49258 7.07542 7.21143 8.68122 7.21143H12.8168C13.3691 7.21143 13.8168 7.65914 13.8168 8.21143C13.8168 8.76371 13.3691 9.21143 12.8168 9.21143H8.68122C8.14891 9.21143 7.7541 9.62799 7.7541 10.1024C7.7541 10.5744 8.15354 10.9933 8.68122 10.9933H11.4383C13.0441 10.9933 14.3654 12.2745 14.3654 13.8843C14.3654 15.5057 13.0322 16.7753 11.4383 16.7753H6.74707C6.19479 16.7753 5.74707 16.3275 5.74707 15.7753C5.74707 15.223 6.19479 14.7753 6.74707 14.7753H11.4383C11.9684 14.7753 12.3654 14.3609 12.3654 13.8843C12.3654 13.4099 11.9706 12.9933 11.4383 12.9933H8.68122C7.08486 12.9933 5.7541 11.7145 5.7541 10.1024Z" />
        <path d="M5.29158 3C4.01133 3 3 4.01933 3 5.22981V18.7702C3 19.9807 4.01133 21 5.29158 21H14.8287C16.109 21 17.1203 19.9807 17.1203 18.7702C17.1203 18.2179 17.568 17.7702 18.1203 17.7702C18.6726 17.7702 19.1203 18.2179 19.1203 18.7702C19.1203 21.1208 17.1777 23 14.8287 23H5.29158C2.94265 23 1 21.1208 1 18.7702V5.22981C1 2.87922 2.94265 1 5.29158 1H14.8287C17.1777 1 19.1203 2.87922 19.1203 5.22981C19.1203 5.7821 18.6726 6.22981 18.1203 6.22981C17.568 6.22981 17.1203 5.7821 17.1203 5.22981C17.1203 4.01933 16.109 3 14.8287 3H5.29158Z" />
        <path d="M15.7041 12.4275C15.7041 11.8752 16.1518 11.4275 16.7041 11.4275L22.0002 11.4275C22.5525 11.4275 23.0002 11.8752 23.0002 12.4275C23.0002 12.9798 22.5525 13.4275 22.0002 13.4275L16.7041 13.4275C16.1518 13.4275 15.7041 12.9798 15.7041 12.4275Z" />
        <path d="M20.0704 9.13394C20.4566 9.52866 20.4498 10.1618 20.0551 10.5481L18.1329 12.4292L20.056 14.3163C20.4502 14.7031 20.4562 15.3362 20.0694 15.7304C19.6826 16.1246 19.0495 16.1306 18.6553 15.7438L16.0037 13.142C15.8119 12.9538 15.7039 12.6963 15.7041 12.4276C15.7043 12.1589 15.8126 11.9015 16.0047 11.7136L18.6562 9.11867C19.0509 8.73239 19.6841 8.73923 20.0704 9.13394Z" />
      </svg>
    );
  }
);

Puzzle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Puzzle.displayName = 'Puzzle';

export default Puzzle;
