import React from 'react';
import { useIntl } from 'react-intl';

import { Header, Text, Card, Icon, Button } from '@src/components/ui';
import Clabe from '@src/components/shared/Clabe';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';
import { FlexContainer, Grid } from '@src/components/shared/layouts';
import Currency from '@src/components/i18n/Currency';
import { CenteredFlexContainer, Spacer } from '@src/components/shared/layouts';
import DataContent from '../DataContent';
import { Verified } from '@src/components/ui/Icon/solid';

type FailedPaymentProps = {
  totalAmount: number;
  taxPayerName?: string;
  clabe?: string;
  bankName?: string;
  bankLogoUrl?: string;
};

const FailedPayment = ({
  totalAmount,
  bankName,
  taxPayerName,
  bankLogoUrl,
  clabe = '',
}: FailedPaymentProps) => {
  const intl = useIntl();
  const openCustomerSupportChat = useCustomerSupportChat();
  return (
    <Card padding="8" depth="sm">
      <CenteredFlexContainer direction="column">
        <Icon
          variant="solid"
          name="Info"
          color="danger"
          colorStep="500"
          size={60}
        />
        <Spacer direction="vertical" margin="6" />
        <Header as="h5">
          {intl.formatMessage({
            defaultMessage: '¡Hubo un error con el pago!',
            id: 'z7iaZG',
          })}
        </Header>
        <Spacer direction="vertical" margin="2" />
        <Text color="neutral" colorStep="600" light textAlign="center">
          {intl.formatMessage(
            {
              defaultMessage:
                'Hola {client}. No pudimos procesar tu pago. A continuación podrás encontrar los detalles básicos de la transacción:',
              id: 'oYZ3Zm',
            },
            {
              client: taxPayerName,
            }
          )}
        </Text>
        <Spacer direction="vertical" margin="12" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="User"
              label={intl.formatMessage({
                defaultMessage: 'Cliente',
                id: 'DshuY+',
              })}
              value={taxPayerName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="Money"
              label={intl.formatMessage({
                defaultMessage: 'Monto',
                id: 'Axixya',
              })}
              value={<Currency value={totalAmount} />}
            />
          </Grid>
        </Grid>
        <Spacer direction="vertical" margin="8" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="Send"
              label={intl.formatMessage({
                defaultMessage: 'CLABE destino',
                id: 'ZDHRsL',
              })}
              value={
                <FlexContainer alignItems="center">
                  <Clabe account={clabe} as="strong" />
                  <Spacer direction="horizontal" />
                  <Verified color="#42BBFF" size={14} />
                </FlexContainer>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DataContent
              imgSrc={bankLogoUrl}
              value={bankName}
              label={intl.formatMessage({
                defaultMessage: 'Institucion Bancaria',
                id: 'F8in/6',
              })}
            />
          </Grid>
        </Grid>
        <Spacer direction="vertical" margin="16" />
        <Button block onClick={openCustomerSupportChat}>
          {intl.formatMessage({
            defaultMessage: 'Contactar con soporte',
            id: '9xjFzn',
          })}
        </Button>
      </CenteredFlexContainer>
    </Card>
  );
};

export default FailedPayment;
