import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useForm, useFieldArray } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import * as S from './ActivateBusinessRelationships.styles';
import Button from '@src/components/ui/Button';
import useSettings from '@src/hooks/useSettings';
import ModalHeader from '@src/components/ui/ModalHeader';
import useModal from '@src/hooks/useModal';
import useToast from '@src/hooks/useToast';
import Avatar from '@src/components/ui/Avatar';
import TableUI from '@src/components/ui/Table';
import TextField from '@src/components/ui/TextField';
import TableCell from '@src/components/ui/TableCell';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import TableHeaderCell from '@src/components/ui/TableHeaderCell';
import { EMAIL_PATTERN } from '@src/constants/patterns';
import MoveInvoicesToPayModal from '../MoveInvoicesToPayModal/MoveInvoicesToPayModal';
import { trackEvent } from '@src/lib/analytics';
import ACTIVATE_BUSINESS_RELATIONSHIPS_MUTATION, {
  ActivateBusinessRelationshipsReturn,
  ActivateBusinessRelationshipsVariables,
} from '@src/graphql/mutations/activateBusinessRelationships';
import {
  ONBOARDING_FINISHED,
  SUPPLIER_BATCH_ENABLED,
} from '@src/constants/events';

import type { BankingInfo } from '@src/types/models';
import BankingInfoField from '../BankingInfoField';

export type BusinessRelationshipsFormInputs = {
  id: string;
  taxPayerName: string;
  taxPayerIdentifier: string;
  paymentTerms: string;
  email?: string;
  bankingInfo?: BankingInfo;
  invoicesCount?: number;
};

interface ActivateBusinessRelationshipsProps {
  businessRelationships: BusinessRelationshipsFormInputs[];
  businessRelationshipsTotalCount: number;
  onClose?: () => void;
}

const ActivateBusinessRelationships = ({
  businessRelationships,
  businessRelationshipsTotalCount,
  onClose,
}: ActivateBusinessRelationshipsProps) => {
  const intl = useIntl();
  const { business } = useSettings();
  const { toast } = useToast();
  const { open: openInboxConfirmation } = useModal(MoveInvoicesToPayModal, 50);
  const {
    control,
    formState: { errors },
    register,
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      businessRelationships,
    },
  });
  const { fields, remove } = useFieldArray({
    control,
    name: 'businessRelationships',
  });
  const totalInvoices = useMemo(
    () => fields.reduce((acc, item) => acc + (item?.invoicesCount || 0), 0),
    [fields]
  );
  const [activateBusinessRelationships, { loading }] = useMutation<
    ActivateBusinessRelationshipsReturn,
    ActivateBusinessRelationshipsVariables
  >(ACTIVATE_BUSINESS_RELATIONSHIPS_MUTATION, {
    onCompleted: () => {
      trackEvent(ONBOARDING_FINISHED);
      trackEvent(SUPPLIER_BATCH_ENABLED, {
        total: businessRelationshipsTotalCount,
        enabled: businessRelationships.length,
      });
      openInboxConfirmation({
        businessId: business?.id,
        totalInvoices: totalInvoices,
        onClose,
      });
    },
    onError: (error) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar',
          id: 'mtahgR',
        }),
        error.message
      );
      openInboxConfirmation({
        businessId: business?.id,
        totalInvoices: totalInvoices,
        onClose,
      });
    },
  });

  const onSubmit = async (data: {
    businessRelationships: BusinessRelationshipsFormInputs[];
  }) => {
    const businessRelationships = data.businessRelationships.map(
      ({
        email,
        bankingInfo,
        paymentTerms,
        taxPayerName,
        taxPayerIdentifier,
        invoicesCount,
        ...data
      }) => ({
        ...data,
        paymentTerms: parseInt(paymentTerms),
        providerContact: (email && { email }) || undefined,
        bankingInfo: bankingInfo && {
          clabe: bankingInfo?.clabe.replace(/ /g, '') || '',
          bankId: bankingInfo?.bankId || '',
          recipient: bankingInfo?.recipient || '',
        },
      })
    );
    if (business?.id) {
      activateBusinessRelationships({
        variables: { businessId: business?.id, businessRelationships },
      });
    }
  };

  const handleCancel = (index: number) => {
    if (fields.length === 1 && onClose) onClose();
    remove(index);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader
        sticky
        title={intl.formatMessage({
          defaultMessage: 'Agregar Proveedores',
          id: 'iYh7M4',
        })}
        onClose={onClose}
        actions={
          <Button type="submit" loading={loading}>
            {intl.formatMessage({
              defaultMessage: 'Terminar',
              id: '31kdnk',
            })}
          </Button>
        }
      />
      <S.FormCard padding="8">
        <TableUI
          stickyHeader
          columnsWidth={[50, 0, 100, 80, 130, 180, 240, 80]}
        >
          <SelectableTableHeader cancelable>
            <TableHeaderCell />
            <th />
            <TableHeaderCell>
              {intl.formatMessage({
                defaultMessage: 'Proveedor',
                id: 'RMvOYP',
              })}
            </TableHeaderCell>

            <TableHeaderCell>RFC</TableHeaderCell>

            <TableHeaderCell>
              {intl.formatMessage({
                defaultMessage: 'Términos de pago',
                id: 'cFxjey',
              })}
            </TableHeaderCell>

            <TableHeaderCell>
              {intl.formatMessage({
                defaultMessage: 'Email (Opcional)',
                id: 'W2ng8W',
              })}
            </TableHeaderCell>

            <TableHeaderCell>
              {intl.formatMessage({
                defaultMessage: 'CLABE Interbancaria (Opcional)',
                id: 'zSrCoG',
              })}
            </TableHeaderCell>
          </SelectableTableHeader>
          <tbody>
            {fields?.map((item, index) => (
              <SelectableTableRow
                key={`invoice-${item}-row`}
                cancelable
                onCancel={() => handleCancel(index)}
              >
                <TableCell>
                  <Avatar name={item.taxPayerName} />
                </TableCell>
                <TableCell>
                  <TextField
                    defaultValue={item.id}
                    {...register(`businessRelationships.${index}.id`, {
                      value: item.id,
                    })}
                  />
                </TableCell>

                <TableCell>{item.taxPayerName}</TableCell>

                <TableCell>{item.taxPayerIdentifier}</TableCell>

                <TableCell>
                  <TextField
                    type="number"
                    error={
                      !!errors?.businessRelationships?.[index]?.paymentTerms
                        ?.message
                    }
                    helperText={
                      errors?.businessRelationships?.[index]?.paymentTerms
                        ?.message
                    }
                    fullWidth
                    defaultValue={getValues(
                      `businessRelationships.${index}.paymentTerms`
                    )}
                    {...register(
                      `businessRelationships.${index}.paymentTerms`,
                      {
                        required: intl.formatMessage({
                          defaultMessage: 'Campo requerido',
                          id: '7Vvfe3',
                        }),
                      }
                    )}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    type="text"
                    fullWidth
                    error={
                      !!errors?.businessRelationships?.[index]?.email?.message
                    }
                    helperText={
                      errors?.businessRelationships?.[index]?.email?.message
                    }
                    placeholder="company@email.com"
                    defaultValue={getValues(
                      `businessRelationships.${index}.email`
                    )}
                    {...register(`businessRelationships.${index}.email`, {
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: intl.formatMessage({
                          defaultMessage: 'Email inválido',
                          id: 'yWNLzv',
                        }),
                      },
                    })}
                  />
                </TableCell>
                <TableCell>
                  <BankingInfoField
                    control={control}
                    taxpayerName={item?.taxPayerName}
                    name={`businessRelationships.${index}.bankingInfo`}
                    onSubmit={(data) => {
                      setValue(
                        `businessRelationships.${index}.bankingInfo`,
                        data
                      );
                    }}
                  />
                </TableCell>
              </SelectableTableRow>
            ))}
          </tbody>
        </TableUI>
      </S.FormCard>
    </form>
  );
};

ActivateBusinessRelationships.displayName = 'ActivateBusinessRelationships';

export default ActivateBusinessRelationships;
