import { gql } from '@apollo/client';

const REJECT_PAYMENT_INSTRUCTION = gql`
  mutation RejectPaymentInstruction(
    $businessId: ID!
    $paymentInstructionId: ID!
  ) {
    rejectPaymentInstruction(
      businessId: $businessId
      paymentInstructionId: $paymentInstructionId
    ) {
      success
    }
  }
`;

export type RejectPaymentInstructionMutationReturn = {
  success: boolean;
};

export type RejectPaymentInstructionMutationVariables = {
  businessId: string;
  paymentInstructionId: number;
};

export default REJECT_PAYMENT_INSTRUCTION;
