import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import {
  Tooltip,
  Button,
  TextField,
  Radio,
  Checkbox,
} from '@src/components/ui';
import { Info } from '@src/components/ui/Icon/outline';
import useToast from '@src/hooks/useToast';

import UPDATE_BUSINESS_USER_MUTATION, {
  UpdateBusinessUserReturn,
  UpdateBusinessUserParams,
  PermissionInput,
} from '@src/graphql/mutations/updateBusinessUser';
import { UPDATE_BUSINESS_USER } from '@src/constants/events';
import { trackEvent } from '@src/lib/analytics';

import { PermissionName, UserRole } from '@src/types/enums';
import { BusinessUser, Permission } from '@src/types/models';
import { Spacer } from '@src/components/shared/layouts';
import { useForm } from 'react-hook-form';
import useSettings from '@src/hooks/useSettings';

interface UsersEditFormProps {
  businessUser: Omit<BusinessUser, 'business' | 'features'>;
}

type FormData = {
  jobPosition: string;
  role: UserRole;
  permissions: Array<PermissionName>;
};

function UsersEditForm({ businessUser }: UsersEditFormProps) {
  const { toast } = useToast();
  const intl = useIntl();
  const { business } = useSettings();
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      jobPosition: businessUser.jobPosition,
      role: businessUser.role,
      permissions: businessUser.permissions.map(
        (permission) => permission.name
      ),
    },
  });
  const [updateBusinessUser, { loading: submitting }] = useMutation<
    UpdateBusinessUserReturn,
    UpdateBusinessUserParams
  >(UPDATE_BUSINESS_USER_MUTATION, {
    onCompleted: () => {
      trackEvent(UPDATE_BUSINESS_USER, {
        creator: 'admin',
        view: 'SettingsBusinessUser',
      });

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Usuario actualizado',
          id: 'V2feEu',
        }),
        intl.formatMessage({
          defaultMessage: 'Se ha actualizado con éxito el usuario',
          id: '/BAmQk',
        })
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar usuario',
          id: '1lZL3z',
        }),
        e.message
      );
    },
  });
  const isAdmin = watch('role') === 'ADMIN';

  const updateExistingPermissions = (
    existingPermissions: Array<Permission>,
    newPermissions: Array<PermissionName>
  ): Array<PermissionInput> =>
    existingPermissions.map((permission) => ({
      id: permission.id,
      name: permission.name,
      _destroy: !newPermissions.includes(permission.name),
    }));

  const appendNewPermissions = (
    permissions: Array<PermissionInput>,
    newPermissions: Array<PermissionName>
  ) => {
    const permissionsCopy = [...permissions];

    newPermissions.forEach((name) => {
      if (!permissions.find((permission) => permission.name === name)) {
        permissionsCopy.push({ name });
      }
    });
    return permissionsCopy;
  };

  const onSubmit = (data: FormData) => {
    let permissions = updateExistingPermissions(
      businessUser.permissions,
      data.permissions
    );

    permissions = appendNewPermissions(permissions, data.permissions);

    updateBusinessUser({
      variables: {
        businessId: business?.id,
        businessUser: {
          id: businessUser.id,
          jobPosition: data.jobPosition,
          role: data.role,
          permissions,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        {...register('jobPosition', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Puesto de trabajo',
          id: 'JfDMD6',
        })}
      />
      <div>
        <strong>
          {intl.formatMessage({
            defaultMessage: 'Asignar roles',
            id: 'lDc0ni',
          })}
        </strong>
        <Spacer margin="2" />
        <Radio
          {...register('role', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            onChange: () => {
              setValue('permissions', []);
            },
          })}
          label={
            <span>
              {intl.formatMessage({
                defaultMessage: 'Administrador',
                id: 'dQ+Sd7',
              })}{' '}
              <Tooltip
                id="administratorOption"
                title={intl.formatMessage({
                  defaultMessage:
                    'Tiene acceso a gestionar la organización, empresas, usuarios, direcciones, compras y ventas.',
                  id: 'Qx345c',
                })}
                effect="solid"
                place="top"
              >
                <Info size={16} />
              </Tooltip>
            </span>
          }
          value={UserRole.ADMIN}
        />
        <Spacer margin="2" />
        <Radio
          {...register('role', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            onChange: () => {
              setValue('permissions', [
                PermissionName.MANAGE_INVOICES_EMITTED,
                PermissionName.MANAGE_INVOICES_RECEIVED,
              ]);
            },
          })}
          label={
            <span>
              {intl.formatMessage({
                defaultMessage: 'Colaborador',
                id: 'j5KmgV',
              })}{' '}
              <Tooltip
                id="colaboratorOption"
                title={intl.formatMessage({
                  defaultMessage:
                    'Puede tener acceso a distintas acciones de compras y ventas dentro de la organización.',
                  id: '9aKNJk',
                })}
                effect="solid"
                place="top"
              >
                <Info size={16} />
              </Tooltip>
            </span>
          }
          value={UserRole.COLABORATOR}
        />
      </div>
      <Spacer />
      {!isAdmin && (
        <>
          <div>
            <strong>
              {intl.formatMessage({
                defaultMessage: 'Permisos',
                id: '6KNuIo',
              })}
            </strong>
            <Spacer margin="2" />
            <Checkbox
              {...register('permissions')}
              label={intl.formatMessage({
                id: 'y0s+Vr',
                defaultMessage: 'Cuentas por pagar',
              })}
              value={PermissionName.MANAGE_INVOICES_RECEIVED}
            />
            <Spacer margin="2" />
            <Checkbox
              {...register('permissions')}
              label={intl.formatMessage({
                id: 'qiuiRz',
                defaultMessage: 'Cuentas por cobrar',
              })}
              value={PermissionName.MANAGE_INVOICES_EMITTED}
            />
          </div>
          <Spacer />
        </>
      )}
      <Spacer />
      <Button block type="submit" disabled={submitting} loading={submitting}>
        {intl.formatMessage({
          defaultMessage: 'Guardar',
          id: 'oN4aGu',
        })}
      </Button>
    </form>
  );
}

export default UsersEditForm;
