import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { addDays, format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';

import { Button, Divider, Header, TextField, Text } from '@src/components/ui';
import * as S from './CreditTermsForm.styles';
import { Currency } from '@src/components/i18n';
import CreditInterestTag from '@src/components/shared/tag/CreditInterestTag';
import { FlexContainer } from '@src/components/shared/layouts';
import { centsToFloat } from '@src/utils/currency';
import BigNumber from 'bignumber.js';
import useCreditAccount from '@src/hooks/useCreditAccount';

interface CreditOption {
  value: number;
  days: number;
  interestRate: number;
  annualInterestRate: number;
  label: string;
}
interface FormData {
  creditTerms: CreditOption;
}
interface CreditTermsFormProps {
  financingAmountCents: number;
  loading?: boolean;
  onSubmit?: (creditTerms: number) => void;
}

const noInterestRate = {
  days: 0,
  interestRate: 0,
  annualInterestRate: 0,
};

function CreditTermsForm({
  onSubmit,
  loading,
  financingAmountCents = 0,
}: CreditTermsFormProps) {
  const intl = useIntl();
  const [creditAccount] = useCreditAccount();
  const availableCreditTerms = creditAccount?.creditTermsJson || [];
  const creditOptions: Array<CreditOption> = useMemo(
    () =>
      availableCreditTerms
        .filter(({ days }) => [10, 30, 60, 90].includes(days))
        .map((option) => ({
          value: option.days,
          days: option.days,
          interestRate: option.interestRate,
          annualInterestRate: +new BigNumber(option.interestRate)
            .dividedBy(option.days)
            .multipliedBy(365)
            .toNumber()
            .toFixed(2),
          label: intl.formatMessage(
            {
              defaultMessage: '{days} días',
              id: 'aNRWjR',
            },
            {
              days: option.days,
            }
          ),
        })),
    [availableCreditTerms]
  );
  const { control, handleSubmit, watch } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      creditTerms: creditOptions?.[1],
    },
  });
  const selectedOption = watch('creditTerms');
  const { days, interestRate, annualInterestRate } =
    selectedOption || noInterestRate;
  const interestRateAmount = centsToFloat(
    new BigNumber(financingAmountCents).multipliedBy(interestRate).toNumber()
  );
  const interestRateAmountWithTaxes = new BigNumber(interestRateAmount)
    .multipliedBy(1.16)
    .toNumber();
  const dueDate = format(addDays(new Date(), days), 'd/MM/yyyy');

  const handleFormSubmit = (data: FormData) => {
    onSubmit && onSubmit(data?.creditTerms?.value);
  };

  return (
    <S.Form onSubmit={handleSubmit(handleFormSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Términos del crédito',
          id: 'USPtju',
        })}
      </Header>
      <S.Description>
        {intl.formatMessage({
          defaultMessage:
            'Pagaremos a tu proveedor el valor de la factura y tú eliges cuando pagarnos de vuelta',
          id: 'tgWeL9',
        })}
      </S.Description>

      <Controller
        control={control}
        name="creditTerms"
        rules={{
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        }}
        render={({ field: { onChange, ...otherFieldProps } }) => {
          const handleChange = (option) => {
            onChange({
              target: {
                value: option,
              },
            });
          };
          return (
            <TextField
              label={intl.formatMessage({
                defaultMessage: 'Términos del crédito',
                id: 'USPtju',
              })}
              type="select"
              fullWidth
              required
              onChange={handleChange}
              options={creditOptions}
              {...otherFieldProps}
            />
          );
        }}
      />

      <Divider />
      <FlexContainer justifyContent="space-between" alignItems="center">
        <Text colorStep="400">
          {intl.formatMessage({
            defaultMessage: 'Fecha de pago',
            id: 'lnTg/E',
          })}
        </Text>
        <strong>{dueDate}</strong>
      </FlexContainer>
      <Divider />
      <FlexContainer justifyContent="space-between" alignItems="center">
        <Text colorStep="400">
          {intl.formatMessage({
            defaultMessage: 'Monto a financiar',
            id: '+MADWp',
          })}
        </Text>
        <Currency
          maximumFractionDigits={2}
          textComponent="strong"
          value={financingAmountCents}
        />
      </FlexContainer>
      <Divider />
      <FlexContainer justifyContent="space-between" alignItems="center">
        <S.RateLabel>
          <Text colorStep="400">
            {intl.formatMessage({
              defaultMessage: 'Tasa de interés',
              id: 'SyXZeV',
            })}
          </Text>
          <CreditInterestTag
            interestRateAmount={interestRateAmount}
            interestRateAmountWithTaxes={interestRateAmountWithTaxes}
            annualInterestRate={annualInterestRate}
          />
        </S.RateLabel>
        <Currency
          maximumFractionDigits={2}
          textComponent="strong"
          value={interestRateAmountWithTaxes}
        />
      </FlexContainer>

      <S.ButtonWrapper>
        <Button block type="submit" loading={loading}>
          {intl.formatMessage({
            defaultMessage: 'Solicitar',
            id: 'qK4ogN',
          })}{' '}
          <Currency value={financingAmountCents} maximumFractionDigits={2} />
        </Button>
      </S.ButtonWrapper>
    </S.Form>
  );
}

CreditTermsForm.displayName = 'CreditTermsForm';

export default CreditTermsForm;
