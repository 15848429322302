import { gql } from '@apollo/client';

const ACCEPT_USER_INVITATION_MUTATION = gql`
  mutation AcceptBusinessUserInvitation($invitationToken: String!) {
    acceptBusinessUserInvitation(invitationToken: $invitationToken) {
      id
      name
    }
  }
`;

export type AcceptBusinessUserInvitationVariables = {
  invitationToken: string;
};

export type AcceptBusinessUserInvitationReturn = {
  id: string;
  name: string;
};

export default ACCEPT_USER_INVITATION_MUTATION;
