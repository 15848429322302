import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import Card from '@src/components/ui/Card';
import { Actions, SearchContainer } from './SuppliersList.styles';
import useClientRelationshipsQuery from '@src/hooks/useClientRelationshipsQuery';
import useQueryParams from '@src/hooks/useQueryParams';
import useSettings from '@src/hooks/useSettings';
import ClientsTable from '@src/components/pages/Contacts/components/ClientsTable';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import Search from '@src/components/shared/Search';
import usePageTrack from '@src/hooks/usePageTrack';

function ClientsList() {
  usePageTrack('clients', 'core');
  const intl = useIntl();
  const { business } = useSettings();
  const [query] = useQueryParams();
  const {
    data,
    error,
    loading,
    count,
    refetch,
    hasNext,
    hasPrevious,
    previous,
    next,
    page,
  } = useClientRelationshipsQuery({
    businessId: business?.id,
    search: query?.search,
  });

  return (
    <Card padding="8">
      <Actions>
        <SearchContainer>
          <Search
            fullWidth
            placeholder={intl.formatMessage(messages.clientSearchPlaceholder)}
          />
        </SearchContainer>
      </Actions>
      <ClientsTable
        data={data}
        loading={loading}
        count={count}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        onPrevious={previous}
        onNext={next}
        columns={{
          clientName: 200,
          clientContact: 200,
          priceList: 100,
          paymentTerms: 100,
          deliveryLeadTimeInDays: 150,
          shippingCostValue: 120,
          status: 120,
        }}
        limit={RESULTS_PER_PAGE}
        page={page}
      />
    </Card>
  );
}

export default ClientsList;
