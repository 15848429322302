import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Wrapper, Overlay } from './Backdrop.styles';
import { BackdropProps } from './types';

export default function Backdrop(props: BackdropProps) {
  const { children, open, invisible, ...otherProps } = props;

  return (
    <CSSTransition
      appear
      mountOnEnter
      unmountOnExit
      in={open}
      timeout={{
        appear: 300,
        enter: 100,
        exit: 300,
      }}
      classNames="backdrop"
    >
      <Wrapper {...otherProps}>
        <Overlay invisible={invisible} />
        {children}
      </Wrapper>
    </CSSTransition>
  );
}
