import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Page from '@src/components/shared/Page';
import Switcher from '@src/components/ui/Switcher';
import SwitcherItem from '@src/components/ui/SwitcherItem';
import messages from './messages';
import SupplierList from './components/SuppliersList';
import ClientsList from './components/ClientsList';
import useQueryParams from '@src/hooks/useQueryParams';
import Header from '@src/components/ui/Header';
import useUserFeatures from '@src/hooks/feature-flags/useUserFeatures';
import { FeatureName } from '@src/types/enums';
import { Spacer } from '@src/components/shared/layouts';

function Contacts() {
  const intl = useIntl();
  const history = useHistory();
  const hasSupplierPermission = useUserFeatures([FeatureName.SUPPLIERS_LIST]);
  const hasClientPermission = useUserFeatures([FeatureName.CLIENT_LIST]);
  const [query, setQuery] = useQueryParams();
  const handleSwitchChange = (value) => {
    setQuery({
      tab: value,
      search: undefined,
      tabIndex: undefined,
    });
  };

  const tab = query?.tab || 'suppliers';

  if (!hasSupplierPermission && !hasClientPermission) {
    history.replace('/p/403');
  }

  if (hasSupplierPermission && hasClientPermission) {
    return (
      <Page title={'Inbox'}>
        <div>
          <Header as="h4">
            <FormattedMessage defaultMessage="Contactos" id="nBwDGK" />
          </Header>
          <Switcher value={tab} onChange={handleSwitchChange}>
            <SwitcherItem
              value="suppliers"
              label={intl.formatMessage(messages.suppliers)}
            />
            <SwitcherItem
              value="clients"
              label={intl.formatMessage(messages.clients)}
            />
          </Switcher>
        </div>
        <Spacer />
        {tab === 'suppliers' ? <SupplierList /> : <ClientsList />}
      </Page>
    );
  }

  if (hasClientPermission) {
    return (
      <Page title={'Inbox'}>
        <Header as="h4">
          <FormattedMessage defaultMessage="Clientes" id="zBnTsv" />
        </Header>
        <ClientsList />
      </Page>
    );
  }

  if (hasSupplierPermission) {
    return (
      <Page title={'Inbox'}>
        <Header as="h4">
          <FormattedMessage defaultMessage="Proveedores" id="ZOFug8" />
        </Header>
        <SupplierList />
      </Page>
    );
  }

  return null;
}

export default Contacts;
