import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './ConfirmationModal.styles';
import { ReactComponent as Error } from './img/error.svg';
import { Button, Text } from '@src/components/ui';
import { FlexColumnContainer, Spacer } from '@src/components/shared/layouts';
import useModal from '@src/hooks/useModal';

type ConfirmationModalProps = {
  title: string;
  text: string;
  onConfirm: (payload?: any) => void;
  icon?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onCancel?: (payload?: any) => void;
};

export function ConfirmationModal({
  title,
  text,
  confirmText,
  cancelText,
  icon,
  onConfirm,
  onCancel,
}: ConfirmationModalProps) {
  const intl = useIntl();
  const { close } = useModal(ConfirmationModal);

  const _onClose = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };

  const _onConfirm = () => {
    close();
    onConfirm();
  };

  return (
    <S.Container>
      {icon || <Error />}
      <Spacer margin="5" />
      <S.Header as="h4">{title}</S.Header>
      <Spacer margin="3" />
      <Text color="neutral" colorStep="600">
        {text}
      </Text>
      <Spacer margin="6" />
      <FlexColumnContainer>
        <Button onClick={_onConfirm}>
          {confirmText ||
            intl.formatMessage({
              defaultMessage: 'Sí',
              id: '0HwljF',
            })}
        </Button>
        <Spacer margin="2" />
        <Button variant="transparent" onClick={_onClose}>
          {cancelText ||
            intl.formatMessage({
              defaultMessage: 'No',
              id: 'oUWADl',
            })}
        </Button>
      </FlexColumnContainer>
    </S.Container>
  );
}

ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
