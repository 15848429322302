import Loadable from '@src/components/shared/Loadable';

const RuntimeErrorLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "404" */
      './RuntimeError'
    ),
});

export default RuntimeErrorLoader;
