import React, { createContext, useContext, useReducer } from 'react';

type ModalData = {
  name?: string;
  isModalOpen?: boolean;
  [key: string]: any;
};

type ModalContextProps = {
  children: React.ReactNode;
};

enum ModalAction {
  OPEN,
  CLOSE,
}

type Action =
  | { type: ModalAction.OPEN; payload: Payload }
  | { type: ModalAction.CLOSE; payload: Payload };

type Dispatch = (action: Action) => void;

type State = { [key: number]: ModalData };

type Payload = {
  zIndex: number;
  name?: string;
  [key: string]: any;
};

const ModalContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);
ModalContext.displayName = 'ModalContext';

function modalReducer(state: State, action: Action) {
  switch (action.type) {
    case ModalAction.OPEN: {
      const { zIndex, ...data } = action.payload;
      const newState = { ...state };
      newState[zIndex] = {
        isModalOpen: true,
        ...data,
      };
      return newState;
    }

    // When closing the modal the props stay to avoid layout shifting. The new props replace the old ones when opening a new one.
    case ModalAction.CLOSE: {
      const { zIndex } = action.payload;
      const newState = { ...state };
      if (newState[zIndex]) {
        newState[zIndex] = {
          ...newState[zIndex],
          isModalOpen: false,
        };
      }
      return newState;
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function ModalProvider({ children }: ModalContextProps) {
  const [state, dispatch] = useReducer(modalReducer, {});

  const value = { state, dispatch };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

function useModalContext() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}

const open = (dispatch: Dispatch, payload: Payload) =>
  dispatch({ type: ModalAction.OPEN, payload });

const close = (dispatch: Dispatch, payload: Payload) =>
  dispatch({ type: ModalAction.CLOSE, payload });

export { ModalProvider, useModalContext, open, close };
