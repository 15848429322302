import React from 'react';
import compose from 'lodash/flowRight';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ACCOUNTS_RECEIVABLE_TABLE_DATA } from './graphql';
import { HeaderCard } from './AccountsReceivableTable.styles';
import Currency from '@src/components/i18n/Currency';
import { withSettings } from '@src/components/SettingsProvider';
import InvoiceGlobalStatusBadge from '@src/components/shared/InvoiceGlobalStatusBadge';
import Card from '@src/components/ui/Card';
import Button from '@src/components/ui/Button';
import Label from '@src/components/ui/Label';
import Table from '@src/components/ui/Table';
import TableRow from '@src/components/ui/TableRow';
import TableHeader from '@src/components/ui/TableHeader';
import TableCell from '@src/components/ui/TableCell';
import TableHeaderCell from '@src/components/ui/TableHeaderCell';

import type { Business } from '@src/types/models';
import type { Invoice, InvoicesEmitted, InvoicesEmittedVars } from './types';
import EmptyResults from '@src/components/shared/EmptyResults';

type AccountsReceivableTableProps = RouteComponentProps<any, any, any> & {
  business?: Business;
};

export const AccountsReceivableTable = (
  props: AccountsReceivableTableProps
) => {
  const { business, history } = props;
  const intl = useIntl();
  const { loading, data } = useQuery<InvoicesEmitted, InvoicesEmittedVars>(
    ACCOUNTS_RECEIVABLE_TABLE_DATA,
    {
      variables: {
        businessId: business && business.id,
        limit: 5,
        sortBy: { field: 'EXPIRY_DATE', direction: 'ASC' },
      },
      skip: !business,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  );

  const handleButtonClick = () => {
    history.push('/accounts_receivable');
  };

  const invoices =
    data && data.invoicesEmitted
      ? data.invoicesEmitted.edges.map((edge) => edge.node)
      : [];

  return (
    <Card padding="8">
      <HeaderCard>
        <Label strong size="md">
          <FormattedMessage defaultMessage="Cuentas por cobrar" id="qiuiRz" />
        </Label>

        <Button compact variant="ghosted" onClick={handleButtonClick}>
          <FormattedMessage defaultMessage="Ver todo" id="6q+3v5" />
        </Button>
      </HeaderCard>
      <Table columnsWidth={[130, 120, 120, 200]}>
        <TableHeader compact>
          <TableHeaderCell>
            <FormattedMessage defaultMessage="No. Factura" id="OWCm2x" />
          </TableHeaderCell>
          <TableHeaderCell>
            <FormattedMessage defaultMessage="Cliente" id="DshuY+" />
          </TableHeaderCell>
          <TableHeaderCell>
            <FormattedMessage defaultMessage="Monto" id="Axixya" />
          </TableHeaderCell>
          <TableHeaderCell>
            <FormattedMessage defaultMessage="Estatus" id="dWihL7" />
          </TableHeaderCell>
        </TableHeader>
        <tbody>
          {loading
            ? [...Array(5).keys()].map((i) => (
                <TableRow compact key={i}>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
              ))
            : invoices?.map((invoice: Invoice, index: number) => (
                <TableRow
                  key={index}
                  compact
                  onClick={() => {
                    history.push(`/invoices/${invoice.id}`);
                  }}
                >
                  <TableCell>{invoice.id}</TableCell>
                  <TableCell>{invoice.businessReceiver.name}</TableCell>
                  <TableCell>
                    <strong>
                      <Currency
                        value={invoice?.total || invoice?.foreignTotal}
                        currency={invoice.currency}
                      />
                    </strong>
                  </TableCell>
                  <TableCell>
                    <InvoiceGlobalStatusBadge
                      expiryDate={invoice.expiryDate}
                      status={invoice.globalStatus}
                    />
                  </TableCell>
                </TableRow>
              ))}
        </tbody>
      </Table>
      {!loading && invoices.length === 0 && (
        <EmptyResults
          compact
          label={intl.formatMessage({
            defaultMessage: 'Aún no tienes cuentas por cobrar',
            id: 'tWDznM',
          })}
          description={intl.formatMessage({
            defaultMessage:
              'Aquí verás todas las cuentas por cobrar que tengas en Higo.',
            id: 'z/79TB',
          })}
        />
      )}
    </Card>
  );
};

export default compose(withSettings, withRouter)(AccountsReceivableTable);
