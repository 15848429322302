import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg';
  contrast?: boolean;
};

const Spinner = styled.div<SpinnerProps>`
  animation: ${rotate} 0.75s linear infinite;
  height: ${({ theme }) => theme.spacing[4]};
  width: ${({ theme }) => theme.spacing[4]};
  border-width: ${({ theme }) => theme.spacing[0.5]};
  border-style: solid;
  border-color: inherit;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;

  ${({ size }) =>
    size === 'md' &&
    css`
      height: ${({ theme }) => theme.spacing[6]};
      width: ${({ theme }) => theme.spacing[6]};
      border-width: ${({ theme }) => theme.spacing[1]};
    `}

  ${({ size }) =>
    size === 'lg' &&
    css`
      height: ${({ theme }) => theme.spacing[8]};
      width: ${({ theme }) => theme.spacing[8]};
      border-width: ${({ theme }) => theme.spacing[1]};
    `}
`;

export default Spinner;
