import React from 'react';
import * as S from './GroupValueFields.styles';

interface GroupValueFieldProps {
  label?: string;
  value?: React.ReactNode;
}

export const GroupValueField = ({ value, label }: GroupValueFieldProps) => {
  return (
    <div>
      {!!label && <S.Label>{label}</S.Label>}
      {value}
    </div>
  );
};

export default GroupValueField;
