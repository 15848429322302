import React, { memo } from 'react';

import TableUI from '@src/components/ui/Table';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import TableCell from '@src/components/ui/TableCell';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import TableHeaderCell from '@src/components/ui/TableHeaderCell';
import TableFilter from '@src/components/shared/TableFilter';
import { ChevronDown } from '@src/components/ui/Icon/outline';

import type { SortParams } from '@src/types/utils';
import type { AttributesObject } from '@src/types/resourceList';

interface TableProps {
  headers: string[];
  attributes: AttributesObject<any>;
  data: Array<any>;
  keyExtractor: (item: any) => string;

  selectedIds?: string[];
  hasAllSelected?: boolean;
  cancelable?: boolean;
  selectable?: boolean;
  emptyElement?: React.ReactNode;
  loading?: boolean;
  limit?: number;
  sort?: SortParams;
  columnsWidth?: Array<number | string>;
  onSelectAll?: () => void;
  onSelect?: (id: string) => void;
  onCancel?: (id: string) => void;
  onRowClick?: (id: string) => void;
  onSort?: (id: string) => void;
  strongFilter?: (item: any) => boolean;
}

const Table = (props: TableProps) => {
  const {
    headers,
    attributes,
    data,
    selectedIds,
    hasAllSelected,
    selectable,
    cancelable,
    emptyElement,
    loading,
    sort,
    columnsWidth,
    limit = 25,
    onSort,
    onSelectAll,
    onSelect,
    onRowClick,
    onCancel,
    strongFilter,
    keyExtractor,
  } = props;

  const handleInvoiceSelect = (event, id) => {
    event.stopPropagation();
    if (onSelect) {
      onSelect(id);
    }
  };

  const handleInvoiceCancel = (event, id) => {
    event.stopPropagation();
    if (onCancel) {
      onCancel(id);
    }
  };

  const handleHeaderClick = (id) => {
    if (onSort && attributes[id] && attributes[id].sortable) {
      onSort(id);
    }
  };

  const normalizedColumnsWidth = columnsWidth;
  if (selectable) {
    normalizedColumnsWidth?.unshift(100);
  }
  if (cancelable) {
    normalizedColumnsWidth?.push(100);
  }

  return (
    <>
      <TableUI stickyHeader columnsWidth={normalizedColumnsWidth}>
        <SelectableTableHeader
          selectable={selectable}
          selected={hasAllSelected}
          onSelect={onSelectAll}
          cancelable={cancelable}
        >
          {headers.map(
            (header) =>
              header in attributes && (
                <TableHeaderCell
                  key={`${header}-header`}
                  onClick={() => {
                    handleHeaderClick(header);
                  }}
                >
                  {attributes[header]?.name}

                  {attributes[header].sortable && (
                    <TableFilter
                      active={sort?.sort === header}
                      rotate={sort?.sortDirection === 'ascending'}
                    >
                      <ChevronDown size={18} />
                    </TableFilter>
                  )}
                </TableHeaderCell>
              )
          )}
        </SelectableTableHeader>
        <tbody>
          {loading &&
            [...Array(limit).keys()].map((i) => (
              <SelectableTableRow
                disabled
                key={`item-${i}-row`}
                selectable={selectable}
                cancelable={cancelable}
              >
                {headers.map(
                  (header) =>
                    header in attributes && (
                      <TableCell key={`item-${i}-${header}`} loading />
                    )
                )}
              </SelectableTableRow>
            ))}

          {!loading &&
            data.length > 0 &&
            data.map((item) => {
              const id = keyExtractor(item);
              return (
                <SelectableTableRow
                  key={`item-${id}-row`}
                  strong={strongFilter ? strongFilter(item) : false}
                  active={selectable && selectedIds && selectedIds.includes(id)}
                  onClick={onRowClick ? () => onRowClick(id) : undefined}
                  selectable={selectable}
                  selected={
                    selectable && selectedIds && selectedIds.includes(id)
                  }
                  onSelect={(event) => handleInvoiceSelect(event, id)}
                  cancelable={cancelable}
                  onCancel={(event) => handleInvoiceCancel(event, id)}
                >
                  {headers.map((header) => {
                    const { render } = attributes[header];
                    return (
                      header in attributes && (
                        <TableCell key={`item-${id}-${header}`}>
                          {render && render(item, props)}
                        </TableCell>
                      )
                    );
                  })}
                </SelectableTableRow>
              );
            })}
        </tbody>
      </TableUI>

      {!loading && data.length === 0 && emptyElement}
    </>
  );
};

export default memo(Table);
