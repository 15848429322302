import styled from 'styled-components';

export const Form = styled.form`
  width: 32rem;
  max-width: 90vw;
  button {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  p {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }
  svg {
    margin: ${({ theme }) => theme.spacing[6]} 0;
  }
`;

export const TooltipSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.primary[500]};
  font-size: ${({ theme }) => theme.fontSize.xs};
  cursor: pointer;
`;
