import React from 'react';

function Background(props) {
  return (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 217.75 93.49"
    >
      <path
        className="blob"
        d="M104.76,89.94a27.6,27.6,0,0,0,6.81-31.38C102.85,39,121.81,17.44,142.3,23.66A27.51,27.51,0,0,0,172.55,13C185.38-5.09,212.75.14,217,21.21a27,27,0,0,0,.76,2.84V16.59c-7.19-19.32-34-22.87-46.83-4.77h0a25.51,25.51,0,0,1-28,9.93C120.75,15,100.32,38.22,109.74,59.37a25.6,25.6,0,0,1-6.33,29.1,29.42,29.42,0,0,0-4.35,5h2.48A27.33,27.33,0,0,1,104.76,89.94Z"
      />
      <path
        className="star"
        d="M48.48,54.94l-5.73-1.2,5.73-1.19a7.59,7.59,0,0,0,5.87-5.87L55.54,41l1.2,5.73a7.59,7.59,0,0,0,5.87,5.87l5.73,1.19-5.73,1.2a7.59,7.59,0,0,0-5.87,5.87l-1.2,5.73-1.19-5.73A7.59,7.59,0,0,0,48.48,54.94Z"
      />
      <path
        className="star"
        d="M67.35,41.65,64,41l3.35-.69a4.44,4.44,0,0,0,3.42-3.43l.7-3.34.7,3.34a4.44,4.44,0,0,0,3.42,3.43l3.35.69-3.35.7a4.41,4.41,0,0,0-3.42,3.43l-.7,3.34-.7-3.34A4.43,4.43,0,0,0,67.35,41.65Z"
      />
      <path
        className="star"
        d="M.22,36a.75.75,0,0,1,1.06,0l2.19,2.19a.75.75,0,0,1-1.06,1.06L.22,37A.75.75,0,0,1,.22,36Z"
      />
      <path
        className="star"
        d="M5.21,41a.75.75,0,0,1,1.06,0l2.19,2.19a.75.75,0,0,1,0,1.06.77.77,0,0,1-1.07,0L5.21,42A.75.75,0,0,1,5.21,41Z"
      />
      <path
        className="star"
        d="M3.47,41a.75.75,0,0,1,0,1.06L1.28,44.2A.75.75,0,0,1,.22,43.14L2.41,41A.75.75,0,0,1,3.47,41Z"
      />
      <path
        className="star"
        d="M8.47,36a.75.75,0,0,1,0,1.06L6.28,39.21a.77.77,0,0,1-1.07,0,.75.75,0,0,1,0-1.06L7.4,36A.75.75,0,0,1,8.47,36Z"
      />
      <path
        className="star"
        d="M15.22,79.83a.75.75,0,0,1,1.06,0L18.47,82a.75.75,0,0,1-1.06,1.06l-2.19-2.19A.75.75,0,0,1,15.22,79.83Z"
      />
      <path
        className="star"
        d="M20.21,84.82a.75.75,0,0,1,1.06,0L23.46,87a.75.75,0,0,1,0,1.06.77.77,0,0,1-1.07,0l-2.19-2.19A.75.75,0,0,1,20.21,84.82Z"
      />
      <path
        className="star"
        d="M18.47,84.82a.75.75,0,0,1,0,1.06l-2.19,2.19A.75.75,0,0,1,15.22,87l2.19-2.19A.75.75,0,0,1,18.47,84.82Z"
      />
      <path
        className="star"
        d="M23.47,79.83a.75.75,0,0,1,0,1.06l-2.19,2.19a.77.77,0,0,1-1.07,0,.75.75,0,0,1,0-1.06l2.19-2.19A.75.75,0,0,1,23.47,79.83Z"
      />
    </svg>
  );
}

export default Background;
