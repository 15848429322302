import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import {
  INVOICES_RECEIVED_QUERY,
  InvoicesReceivedQueryReturn,
  InvoicesReceivedQueryVariables,
} from '@src/graphql/queries/invoicesReceived';
import useSettings from '@src/hooks/useSettings';
import { InvoiceListAttributes } from '@src/graphql/fragments/invoiceListAttributes';

type UseInvoicesReturn = {
  data: Array<InvoiceListAttributes>;
  loading: boolean;
};
type SortType = {
  key: string;
  order: 'asc' | 'desc';
};
type UseInvoiceOptions = {
  sort?: SortType;
};

const useInvoices = (
  invoiceIds: string[],
  options?: UseInvoiceOptions
): UseInvoicesReturn => {
  const { business } = useSettings();
  const { data, loading } = useQuery<
    InvoicesReceivedQueryReturn,
    InvoicesReceivedQueryVariables
  >(INVOICES_RECEIVED_QUERY, {
    variables: {
      businessId: business?.id,
      invoiceId: invoiceIds,
    },
    skip: !business?.id || !invoiceIds || invoiceIds.length === 0,
  });

  const invoices = useMemo(() => {
    if (!data?.invoicesReceived?.edges) {
      return [];
    }
    const flatten = data.invoicesReceived.edges.map((edge) => edge.node);

    if (options?.sort?.key) {
      const { key, order } = options.sort;
      return flatten.sort((a, b) =>
        order === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key])
      );
    }

    return flatten;
  }, [data]);

  return {
    data: invoices,
    loading,
  };
};

export default useInvoices;
