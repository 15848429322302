import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { ModalCard } from './Modal.styles';
import Backdrop from '@src/components/ui/Backdrop';
import IconButton from '@src/components/ui/IconButton';
import { X as Close } from '@src/components/ui/Icon/outline';

import type { ModalProps } from './types';

export default function Modal(props: ModalProps) {
  const {
    children,
    onClose,
    open,
    container,
    CardProps,
    ...otherProps
  } = props;
  const containerElement = container && document.querySelector(container);
  const portalContainer = containerElement || document.body;

  const blockScroll = () => {
    if (document.body.scrollHeight > window.innerHeight) {
      document.body.style.overflowY = 'scroll';
    }
    document.body.style.position = 'fixed';
  };
  const enableScroll = () => {
    document.body.style.overflowY = 'auto';
    document.body.style.position = 'static';
  };

  useEffect(() => {
    const handleEscapeKeydown = (event) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeydown);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeydown);
      enableScroll();
    };
  }, []);

  const handleClose = (event) => {
    event.stopPropagation();
    if (onClose) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <Backdrop open={open}>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modal"
        appear
        mountOnEnter
        unmountOnExit
        onEnter={blockScroll}
        onExit={enableScroll}
      >
        <ModalCard {...otherProps} {...CardProps}>
          {children}
          {onClose && (
            <div className="close-button">
              <IconButton
                onClick={handleClose}
                variant="transparent"
                color="neutral"
                rounded
              >
                <Close />
              </IconButton>
            </div>
          )}
        </ModalCard>
      </CSSTransition>
    </Backdrop>,
    portalContainer
  );
}
