import { gql } from '@apollo/client';

const fragments = {
  invoices: gql`
    fragment AccountsPayableTableAttributes on Invoice {
      id
      globalStatus
      expiryDate
      outstandingBalance
      total
      currency
      foreignTotal
      createdAt
      businessEmitter {
        id
        name
        hashId
      }
    }
  `,
};

export const AccountsPayableTableQueryName = 'AccountsPayableTable';

export const ACCOUNTS_PAYABLE_TABLE_DATA = gql`
  query ${AccountsPayableTableQueryName}(
      $businessId: ID!
      $approvalStatus: [InvoiceApprovalStatusEnum!]
      $paymentStatus: [InvoicePaymentStatusEnum!]
      $limit: Int
      $sortBy: InvoicesSortingParams
    ) {
    invoicesReceived(
        businessId: $businessId
        approvalStatus: $approvalStatus
        paymentStatus: $paymentStatus
        first: $limit
        sortBy: $sortBy
    ) {
      edges {
        node {
          ...AccountsPayableTableAttributes
        }
      }
    }
  }
  ${fragments.invoices}
`;
