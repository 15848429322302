import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/flowRight';
import { gql } from '@apollo/client';

import CURRENT_USER_QUERY from '@src/graphql/queries/currentUser';

const HigoSuiteData = graphql(gql`
  query HigoSuiteData {
    groups {
      features {
        active
        id
        name
      }
      id
      name
    }
  }
`);

const UpdateBusinessFeatureGroups = graphql(
  gql`
    mutation UpdateBusinessFeatureGroups(
      $businessId: ID!
      $businessGroups: [BusinessGroupInput!]!
    ) {
      updateBusinessFeatureGroups(
        businessId: $businessId
        businessGroups: $businessGroups
      ) {
        id
      }
    }
  `,
  {
    name: 'updateBusinessFeatureGroups',
    // $FlowIgnore
    props: ({ updateBusinessFeatureGroups }: any) => ({
      updateBusinessFeatureGroups: (
        businessId: string,
        businessGroups: any
      ) => {
        return updateBusinessFeatureGroups({
          variables: {
            businessId,
            businessGroups,
          },
          refetchQueries: [CURRENT_USER_QUERY],
        });
      },
    }),
  }
);

//$FlowIgnore
export default compose(HigoSuiteData, UpdateBusinessFeatureGroups);
