import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const InvoiceToCharge: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 5.99999 1H14C14.2652 1 14.5195 1.10536 14.7071 1.29289L20.7071 7.29288C20.8946 7.48041 21 7.73477 21 7.99998V19.9999C21 20.7956 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7956 22.9999 18 22.9999H5.99999C5.20435 22.9999 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7956 3 19.9999V3.99999C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM5.99999 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 3.99999V19.9999C5 20.2652 5.10536 20.5195 5.29289 20.707C5.48043 20.8946 5.73478 20.9999 5.99999 20.9999H18C18.2652 20.9999 18.5195 20.8946 18.7071 20.707C18.8946 20.5195 19 20.2652 19 19.9999V8.4142L13.5858 3H5.99999Z" />
        <path d="M8.5 12.9998C8.5 12.4475 8.94772 11.9998 9.5 11.9998H20C20.5523 11.9998 21 12.4475 21 12.9998C21 13.552 20.5523 13.9998 20 13.9998H9.5C8.94772 13.9998 8.5 13.552 8.5 12.9998Z" />
        <path d="M8.29304 13.7071C7.90252 13.3166 7.90252 12.6834 8.29304 12.2929L12.293 8.29289C12.6836 7.90237 13.3167 7.90237 13.7073 8.29289C14.0978 8.68342 14.0978 9.31658 13.7073 9.70711L9.70726 13.7071C9.31673 14.0976 8.68357 14.0976 8.29304 13.7071Z" />
        <path d="M8.29304 12.2929C7.90252 12.6834 7.90252 13.3166 8.29304 13.7071L12.293 17.7071C12.6836 18.0976 13.3167 18.0976 13.7073 17.7071C14.0978 17.3166 14.0978 16.6834 13.7073 16.2929L9.70726 12.2929C9.31673 11.9024 8.68357 11.9024 8.29304 12.2929Z" />
      </svg>
    );
  }
);

InvoiceToCharge.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InvoiceToCharge.displayName = 'InvoiceToCharge';

export default InvoiceToCharge;
