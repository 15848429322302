import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Page from '@src/components/shared/Page';
import PayFinancingModal from '@src/components/pages/WalletView/components/PayFinancingModal';
import Modal from '@src/components/shared/Modal';
import { Spacer } from '@src/components/shared/layouts';
import Header from '@src/components/ui/Header';
import usePageTrack from '@src/hooks/usePageTrack';
import useSettings from '@src/hooks/useSettings';
import userIsAdmin from '@src/utils/roles/userIsAdmin';
import { FinancingRequests } from './components';
import Widgets from './components/Widgets';
import DownloadSuccess from '@src/components/shared/DownloadSuccess';

function Credit() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { currentUser, business } = useSettings();
  const hasCreditActive = business?.creditAccount?.active;
  const isAdmin = userIsAdmin(currentUser);
  usePageTrack('credit_actives', 'core');

  if (!isAdmin || !hasCreditActive) {
    history.replace('/p/403');
  }

  return (
    <Page
      title={formatMessage({
        defaultMessage: 'Mi crédito',
        id: 'QCRWQM',
      })}
    >
      <Header as="h4">
        {formatMessage({
          defaultMessage: 'Mi crédito',
          id: 'QCRWQM',
        })}
      </Header>
      <Widgets />
      <Spacer margin="12" />
      <FinancingRequests />
      <Modal component={PayFinancingModal} type="fullscreen" />
      <Modal component={DownloadSuccess} />
    </Page>
  );
}

export default Credit;
