import styled from 'styled-components';

export const InvoicesTableStyled = styled.div`
  position: relative;
`;

export const CellCentered = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: ${({ theme }) => theme.spacing[1]};
  }
`;
