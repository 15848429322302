import { gql } from '@apollo/client';

export const DELETE_CODAT_DATASOURCE = gql`
  mutation DeleteCodatDataSource($businessId: ID!, $codatDataSourceId: ID!) {
    deleteCodatDataSource(
      businessId: $businessId
      codatDataSourceId: $codatDataSourceId
    ) {
      id
    }
  }
`;
