import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const BankTransfer: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M12.25 15.9998C12.25 15.5855 12.5858 15.2498 13 15.2498L22.0004 15.2498C22.4146 15.2498 22.7504 15.5855 22.7504 15.9998C22.7504 16.414 22.4146 16.7498 22.0004 16.7498L13 16.7498C12.5858 16.7498 12.25 16.414 12.25 15.9998Z" />
        <path d="M19.1256 18.4902C19.3969 18.8032 19.8706 18.8371 20.1836 18.5658L22.4913 16.5658C22.6557 16.4233 22.7501 16.2165 22.7501 15.999C22.7501 15.7815 22.6557 15.5747 22.4913 15.4322L20.1836 13.4323C19.8706 13.161 19.3969 13.1948 19.1256 13.5078C18.8543 13.8209 18.8882 14.2945 19.2012 14.5658L20.855 15.999L19.2012 17.4322C18.8882 17.7035 18.8543 18.1772 19.1256 18.4902Z" />
        <path d="M4.74902 6.99927C5.16324 6.99927 5.49902 7.33505 5.49902 7.74927L5.49902 18.2492C5.49902 18.6634 5.16324 18.9992 4.74902 18.9992C4.33481 18.9992 3.99902 18.6634 3.99902 18.2492L3.99902 7.74927C3.99902 7.33505 4.33481 6.99927 4.74902 6.99927Z" />
        <path d="M9.75 6.99927C10.1642 6.99927 10.5 7.33505 10.5 7.74927L10.5 18.2492C10.5 18.6634 10.1642 18.9992 9.75 18.9992C9.33579 18.9992 9 18.6634 9 18.2492L9 7.74927C9 7.33505 9.33579 6.99927 9.75 6.99927Z" />
        <path d="M14.75 6.99927C15.1642 6.99927 15.5 7.33505 15.5 7.74927L15.5 11.2492C15.5 11.6635 15.1642 11.9992 14.75 11.9992C14.3358 11.9992 14 11.6635 14 11.2492L14 7.74927C14 7.33505 14.3358 6.99927 14.75 6.99927Z" />
        <path d="M19.75 6.99927C20.1642 6.99927 20.5 7.33505 20.5 7.74927L20.5 11.2492C20.5 11.6635 20.1642 11.9992 19.75 11.9992C19.3358 11.9992 19 11.6635 19 11.2492L19 7.74927C19 7.33505 19.3358 6.99927 19.75 6.99927Z" />
        <path d="M11.308 0.668111C11.7416 0.442824 12.2577 0.442824 12.6912 0.668111L21.3518 5.16829C21.9653 5.48704 22.2814 6.18375 22.1174 6.85531C21.9533 7.52687 21.3515 7.99933 20.6602 7.99933H3.33901C2.64771 7.99933 2.04593 7.52687 1.88187 6.85531C1.7178 6.18375 2.03395 5.48704 2.64739 5.16829L11.308 0.668111ZM11.9996 1.99915L20.6602 6.49933H3.33901L11.9996 1.99915Z" />
        <path d="M1.99902 21.2489C1.99902 20.8347 2.33481 20.4989 2.74902 20.4989L21.2498 20.4989C21.664 20.4989 21.9998 20.8347 21.9998 21.2489C21.9998 21.6631 21.664 21.9989 21.2498 21.9989L2.74902 21.9989C2.33481 21.9989 1.99902 21.6631 1.99902 21.2489Z" />
      </svg>
    );
  }
);

BankTransfer.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BankTransfer.displayName = 'BankTransfer';

export default BankTransfer;
