import styled from 'styled-components';

export const Body = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const Actions = styled.div`
  margin-top: ${({ theme }) => theme.spacing[24]};
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;

export const Container = styled.div`
  width: 35rem;
  max-width: 90vw;
`;
