import { gql } from '@apollo/client';
import { InvoicePayment } from '@src/types/models';

export const CREATE_BANKING_TRANSACTION_INVOICE_PAYMENTS_MUTATION = gql`
  mutation CreateBankingTransactionInvoicePayments(
    $businessId: ID!
    $invoicePayments: [BankingTransactionInvoicePaymentInput!]!
  ) {
    createBankingTransactionInvoicePayments(
      businessId: $businessId
      invoicePayments: $invoicePayments
    ) {
      invoicePayments {
        id
      }
    }
  }
`;

export type InvoicePaymentInput = {
  amountCents: number;
  bankingTransactionId: string;
  invoiceId: string;
};

export type CreateBankingTransactionInvoicePaymentsParams = {
  businessId: string;
  invoicePayments: InvoicePaymentInput[];
};

export type CreateBankingTransactionInvoicePaymentsReturn = {
  invoicePayments: Array<Pick<InvoicePayment, 'id'>>;
};
