import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Smile: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0C8.81846 0.00344108 5.76821 1.26883 3.51852 3.51852C1.26883 5.76821 0.00344108 8.81846 0 12C0.6 27.9 23.4 27.893 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM15.5 8C15.8978 8 16.2794 8.15804 16.5607 8.43934C16.842 8.72064 17 9.10218 17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11C15.1022 11 14.7206 10.842 14.4393 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10218 14.158 8.72064 14.4393 8.43934C14.7206 8.15804 15.1022 8 15.5 8ZM8.5 8C8.89782 8 9.27936 8.15804 9.56066 8.43934C9.84196 8.72064 10 9.10218 10 9.5C10 9.89782 9.84196 10.2794 9.56066 10.5607C9.27936 10.842 8.89782 11 8.5 11C8.10218 11 7.72064 10.842 7.43934 10.5607C7.15804 10.2794 7 9.89782 7 9.5C7 9.10218 7.15804 8.72064 7.43934 8.43934C7.72064 8.15804 8.10218 8 8.5 8ZM17.842 15.542C17.2544 16.5779 16.4061 17.442 15.3813 18.0486C14.3565 18.6552 13.1908 18.9832 12 19C10.8084 18.9834 9.64182 18.6555 8.61601 18.0489C7.59019 17.4423 6.74078 16.5781 6.152 15.542C6.04568 15.376 5.99077 15.1824 5.99417 14.9853C5.99757 14.7883 6.05912 14.5966 6.17109 14.4344C6.28306 14.2722 6.44046 14.1467 6.62352 14.0737C6.80658 14.0007 7.00714 13.9834 7.2 14.024C8.73768 14.6033 10.3582 14.9328 12 15C13.6391 14.932 15.2568 14.6025 16.792 14.024C16.985 13.9829 17.1859 13.9998 17.3693 14.0726C17.5527 14.1454 17.7105 14.2709 17.8227 14.4332C17.935 14.5955 17.9967 14.7874 18.0001 14.9847C18.0036 15.182 17.9485 15.3759 17.842 15.542Z"
          fill={color}
        />
      </svg>
    );
  }
);

Smile.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Smile.displayName = 'Smile';

export default Smile;
