import React from 'react';
import { useIntl } from 'react-intl';
import { useController, Control } from 'react-hook-form';

import TextField from '@src/components/ui/TextField';
import { CLABE_PATTERN } from '@src/constants/patterns';
import { groupNumberMask } from '@src/utils/masks';
import isValidClabe from '@src/utils/validations/isValidClabe';
import useBanksQuery from '@src/hooks/useBanksQuery';

interface CLABEFieldProps {
  control: Control;
  defaultValue?: string;
  onBankChange?: (id?: string) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
  onChange?: (event: React.SyntheticEvent) => void;
}

function CLABEField({
  control,
  defaultValue,
  onBankChange,
  onChange,
  onBlur,
}: CLABEFieldProps) {
  const intl = useIntl();
  const { data: bankData } = useBanksQuery();

  const {
    field: { onBlur: hookOnBlur, onChange: hookOnChange, value, ref },
    formState: { errors },
  } = useController({
    name: 'clabe',
    control,
    rules: {
      validate: {
        isSupportedBank: (v) => {
          const inferredBank = bankData?.banks.find(
            (bank) => bank?.code && new RegExp(`\^${bank.code}`).test(v)
          );

          onBankChange && onBankChange(inferredBank?.id);
          return (
            inferredBank?.supported ||
            intl.formatMessage({
              defaultMessage:
                'Todavía no tenemos soporte para tu banco. Porfavor contacta a soporte.',
              id: 'LSEoSr',
            })
          );
        },
        isValidPattern: (v) =>
          CLABE_PATTERN.test(v) ||
          intl.formatMessage({
            defaultMessage: 'La CLABE debe contener 18 dígitos numéricos',
            id: 'SGBInO',
          }),
        isValid: (v) =>
          isValidClabe(v) ||
          intl.formatMessage({
            defaultMessage: 'La CLABE es incorrecta',
            id: 'v20T5+',
          }),
      },
      required: intl.formatMessage({
        defaultMessage: 'Introduce la CLABE',
        id: 'SlYKTz',
      }),
    },
    defaultValue: defaultValue || '',
  });

  const handleChange = (event) => {
    onChange && onChange(event);
    hookOnChange(event);
  };

  const handleBlur = (event) => {
    onBlur && onBlur(event);
    hookOnBlur();
  };

  return (
    <TextField
      label="CLABE"
      type="text"
      fullWidth
      required
      placeholder="5001 1501 6003 2694 11"
      mask={groupNumberMask(18, 4)}
      error={!!errors?.clabe?.message}
      helperText={errors?.clabe?.message}
      autoFocus
      value={value}
      ref={ref}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export default CLABEField;
