import React from 'react';
import { useIntl } from 'react-intl';

import SatSyncCard from '../SatSyncCard';
import Switcher from '@src/components/ui/Switcher';
import SwitcherItem from '@src/components/ui/SwitcherItem';
import useSettings from '@src/hooks/useSettings';
import * as S from './InboxHeader.styles';
import Header from '@src/components/ui/Header';

import { InvoiceVoucherType } from '@src/types/enums';
function InboxHeader({
  activeTab,
  onSwitchChange,
}: {
  activeTab: string;
  onSwitchChange?: (value: string) => void;
}) {
  const intl = useIntl();
  const { business } = useSettings();
  const hasSatSyncCard = business?.taxPayerInfos?.length === 1;

  return (
    <S.PageHeader>
      <div>
        <Header as="h4">
          {intl.formatMessage({
            defaultMessage: 'Buzón de entrada',
            id: 'y09zFl',
          })}
        </Header>
        <S.SwitchContainer>
          <Switcher value={activeTab} onChange={onSwitchChange}>
            <SwitcherItem
              value={InvoiceVoucherType.INVOICE}
              label={intl.formatMessage({
                defaultMessage: 'Facturas agregadas',
                id: 'osldg7',
              })}
            />
            <SwitcherItem
              value={InvoiceVoucherType.CREDIT_NOTE}
              label={intl.formatMessage({
                defaultMessage: 'Notas de crédito',
                id: 'mdwqAr',
              })}
            />
          </Switcher>
        </S.SwitchContainer>
      </div>
      {hasSatSyncCard && <SatSyncCard />}
    </S.PageHeader>
  );
}

export default InboxHeader;
