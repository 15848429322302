import styled from 'styled-components';
import UITag from '@src/components/ui/Tag';

export const Option = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  span {
    margin-right: ${({ theme }) => theme.spacing[1]};
    margin-bottom: 0;
  }
`;

export const Tag = styled(UITag)`
  margin-left: ${({ theme }) => theme.spacing[1]};
`;
