import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabPanel, Tabs } from 'react-tabs';

import usePageTrack from '@src/hooks/usePageTrack';
import useSettings from '@src/hooks/useSettings';
import FACTORING_OFFERS_QUERY, {
  FactoringOffersQueryReturn,
  FactoringOffersQueryVariables,
  FactoringOfferStatusEnum,
} from '@src/graphql/queries/factoringOffers';
import { useQuery } from '@apollo/client';
import useQueryParams from '@src/hooks/useQueryParams';
import { trackPageView } from '@src/lib/analytics';
import Page from '@src/components/shared/Page';
import PageTitle from '@src/components/shared/PageTitle';
import { Card } from '@src/components/ui';
import { TabList, Tab } from '@src/components/shared/Tabs';
import {
  Smile as SmileOutline,
  Transaction as TransactionOutline,
} from '@src/components/ui/Icon/outline';
import {
  Saldo as SaldoSolid,
  Smile as SmileSolid,
} from '@src/components/ui/Icon/solid';
import AvailableOffers from './components/AvailableOffers';
import AcceptedOffers from './components/AcceptedOffers/AcceptedOffers';
import CompletedOffers from './components/CompletedOffers';

function FactoringOffers() {
  usePageTrack('availables', 'factoring_offers');
  const [query, setQuery] = useQueryParams();
  const intl = useIntl();
  const { business } = useSettings();

  const { data, loading } = useQuery<
    FactoringOffersQueryReturn,
    FactoringOffersQueryVariables
  >(FACTORING_OFFERS_QUERY, {
    variables: {
      businessId: business?.id,
      status: FactoringOfferStatusEnum.AVAILABLE,
    },
    skip: !business?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const selectedTabIndex = parseInt(query?.tabIndex) || 0;

  const handleTabSelect = (index) => {
    trackPageView(
      ['available', 'accepted', 'completed'][index],
      'factoring_offers'
    );
    setQuery({
      tabIndex: index,
    });
  };

  const availableInvoicesCount = data?.factoringOffers?.length;

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Factoraje',
        id: 'i9cV3r',
      })}
    >
      <PageTitle>
        <FormattedMessage defaultMessage="Factoraje" id="i9cV3r" />
      </PageTitle>
      <Card>
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          <TabList>
            <Tab
              count={availableInvoicesCount}
              defaultIcon={<SaldoSolid />}
              selectedIcon={<SaldoSolid />}
            >
              <FormattedMessage defaultMessage="Disponibles" id="2EwRku" />
            </Tab>
            <Tab
              defaultIcon={<TransactionOutline />}
              selectedIcon={<TransactionOutline />}
            >
              <FormattedMessage defaultMessage="Adelantadas" id="vm4j4m" />
            </Tab>
            <Tab defaultIcon={<SmileOutline />} selectedIcon={<SmileSolid />}>
              <FormattedMessage defaultMessage="Completadas" id="JV+lDz" />
            </Tab>
          </TabList>
          <TabPanel>
            <AvailableOffers
              factoringOffers={data?.factoringOffers || []}
              loading={loading}
            />
          </TabPanel>
          <TabPanel>
            <AcceptedOffers />
          </TabPanel>
          <TabPanel>
            <CompletedOffers />
          </TabPanel>
        </Tabs>
      </Card>
    </Page>
  );
}

export default FactoringOffers;
