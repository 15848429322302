import { gql } from '@apollo/client';

import { BULK_INVOICE_APPROVAL_ATTRIBUTES } from '../fragments/bulkInvoiceApprovalAttributes';

import { InvoiceApprovalStatus } from '@src/types/enums';
import { Invoice } from '@src/types/models';

// We could use ListAttributes. Don't see why they request different data.
export const UPDATE_BULK_INVOICE_STATUS = gql`
  mutation UpdateBulkInvoiceStatus(
    $businessId: ID!
    $invoices: [BulkInvoiceInput!]!
  ) {
    updateBulkInvoiceStatus(businessId: $businessId, invoices: $invoices) {
      ...BulkInvoiceApprovalAttributes
    }
  }
  ${BULK_INVOICE_APPROVAL_ATTRIBUTES}
`;

export type UpdateInvoiceStatusParameters = {
  businessId?: string;
  invoices: {
    id: string;
    status: keyof typeof InvoiceApprovalStatus;
    expiryDate: string;
    paymentTerms: number;
    costCenterId?: string;
  }[];
};

export type UpdateInvoiceStatusResponse = {
  updateBulkInvoiceStatus: Invoice[];
};
