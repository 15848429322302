import React from 'react';

import DateRangePicker from '@src/components/ui/DateRangePicker';

type DatePickerFiltersProps = {
  onChange?: (...args: any) => void;
  value?: {
    start?: string;
    end?: string;
  };
};

const DatePickerFilter = ({ onChange, value }: DatePickerFiltersProps) => {
  const { start, end } = value || {};

  const handleChange = (nextValue) => {
    onChange?.({
      createdAtFrom: nextValue.start,
      createdAtTo: nextValue.end,
    });
  };

  return (
    <DateRangePicker
      fullWidth
      name="createdAt"
      onChange={handleChange}
      value={{ start, end }}
    />
  );
};

export default DatePickerFilter;
