import snack from '@src/lib/snack';
import toast from '@src/lib/toastr';

const notifications = {
  toast,
  snack,
};

const useToast = () => notifications;

export default useToast;
