import { gql } from '@apollo/client';

export const TAXPAYER_INFO_ATTRIBUTES = gql`
  fragment TaxPayerInfoDataAttributes on TaxPayerInfo {
    id
    taxpayerIdentifier
    taxpayerName
    hasSatPassword
    satSyncStatus
    satUpdatedAt
  }
`;
