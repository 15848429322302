import styled from 'styled-components';
import Card from '../Card';

type MenuCardProps = {
  open?: boolean;
  rows?: number;
  width?: number | string;
};

export const MenuCard = styled(Card)<MenuCardProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: ${({ theme, width }) => width || theme.spacing[52]};
  min-width: ${({ theme }) => theme.spacing[52]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  overflow-y: auto;
  max-height: ${({ theme, rows }) =>
    rows ? `calc(${theme.spacing[15]} * ${rows})` : 'auto'};
`;

export const MenuItem = styled.div<{ selected?: boolean }>`
  outline: none;
  border: none;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing[15]};
  font-weight: ${({ theme, selected }) =>
    selected ? theme.fontWeight.medium : theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.md};
  padding: 0 ${({ theme }) => theme.spacing[2.5]};
  transition: background-color 200ms ease-in;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${({ theme }) => theme.spacing[2.5]};
    right: ${({ theme }) => theme.spacing[2.5]};
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }
`;
