import React, { useEffect } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router';
import useSettings from '@src/hooks/useSettings';
import Card from '@src/components/ui/Card';
import { useIntl } from 'react-intl';
import { Tab, TabList } from '@src/components/shared/Tabs';
import {
  Star as StarOutline,
  Inbox as InboxOutline,
} from '@src/components/ui/Icon/outline';
import {
  Star as StarSolid,
  Inbox as InboxSolid,
} from '@src/components/ui/Icon/solid';
import EmptyResults from '@src/components/shared/EmptyResults';
import PendingInvoicesTable from '../PendingInvoicesTable';
import Button from '@src/components/ui/Button';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import Filters from '@src/components/shared/Filters';
import { ReactComponent as FeatureSupplierIcon } from '@src/assets/img/feature-supplier.svg';
import * as S from './PendingInvoices.styles';
import { trackPageView } from '@src/lib/analytics';
import CopyButton from '@src/components/shared/buttons/CopyButton';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { InvoiceApprovalStatus, InvoicePaymentStatus } from '@src/types/enums';

export function PendingInvoices() {
  const intl = useIntl();
  const limit = 50;
  const { business } = useSettings();
  const history = useHistory();
  const [filters, setFilters] = useInvoiceFilters({
    businessId: business?.id,
    approvalStatus: [InvoiceApprovalStatus.PENDING],
    paymentStatus: [InvoicePaymentStatus.PENDING],
    archived: false,
  });

  const {
    loading: featuredLoading,
    nodes: featuredNodes,
    data: { invoices: featuredInvoices, totalAmount: featuredTotalAmount },
    count: featuredCount,
    next: featuredNext,
    hasNext: featuredHasNext,
    previous: featuredPrevious,
    hasPrevious: featuredHasPrevious,
    page: featuredPage,
    resetPaginationParams,
  } = useInvoicesListQuery({
    ...filters,
    fromActiveProvidersOnly: true,
    ...getPaginationValuesForQuery(filters, limit),
  });

  const {
    loading: allLoading,
    nodes: allNodes,
    data: { invoices: allInvoices, totalAmount: allTotalAmount },
    count: allCount,
    next: allNext,
    hasNext: allHasNext,
    previous: allPrevious,
    hasPrevious: allHasPrevious,
    page: allPage,
  } = useInvoicesListQuery({
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  useEffect(() => {
    setFilters({ sort: 'invoicingDate', sortDirection: 'descending' });
  }, []);

  const handleEmptyClick = () => {
    history.push('/contacts');
  };

  const selectedTabIndex = filters?.tabIndex ? parseInt(filters.tabIndex) : 0;

  const handleTabSelect = (index) => {
    resetPaginationParams({
      invoicingDate: undefined,
      businessEmitterId: undefined,
      tabIndex: index,
    });
  };

  return (
    <Card>
      <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
        <TabList>
          <Tab
            defaultIcon={<StarOutline />}
            selectedIcon={<StarSolid />}
            count={featuredCount}
            onClick={() => {
              trackPageView('inbox_featured', 'payables');
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Destacado',
              id: 'UfONij',
            })}
          </Tab>
          <Tab
            defaultIcon={<InboxOutline />}
            selectedIcon={<InboxSolid />}
            count={allCount}
            isAlert
            onClick={() => {
              trackPageView('inbox_all', 'payables');
            }}
          >
            {intl.formatMessage({
              defaultMessage: 'Todo',
              id: '8X5WO5',
            })}
          </Tab>
          {business?.mailboxEmail && (
            <S.Mailbox>
              <span>{business.mailboxEmail}</span>
              <CopyButton text={business.mailboxEmail} />
            </S.Mailbox>
          )}
        </TabList>
        <TabPanel>
          <S.Container>
            <Filters fields={['invoicingDate', 'businessEmitterId']} />
            <PendingInvoicesTable
              key="featured"
              nodes={featuredNodes}
              invoices={featuredInvoices}
              loading={featuredLoading}
              limit={limit}
              page={featuredPage}
              count={featuredCount}
              next={featuredNext}
              previous={featuredPrevious}
              hasNext={featuredHasNext}
              hasPrevious={featuredHasPrevious}
              totalAmount={featuredTotalAmount}
              emptyElement={
                <EmptyResults
                  icon={<FeatureSupplierIcon />}
                  label={intl.formatMessage({
                    defaultMessage:
                      'No hay cuentas por pagar de tus proveedores destacados',
                    id: 'vDxTb4',
                  })}
                  description={intl.formatMessage({
                    defaultMessage:
                      'Aquí verás todas las cuentas por pagar de los proveedores que marques como destacado.',
                    id: '/+IGw8',
                  })}
                  action={
                    <Button variant="ghosted" onClick={handleEmptyClick}>
                      {intl.formatMessage({
                        defaultMessage: 'Ir a mis Proveedores',
                        id: 'FZWloQ',
                      })}
                    </Button>
                  }
                />
              }
            />
          </S.Container>
        </TabPanel>
        <TabPanel>
          <S.Container>
            <Filters fields={['invoicingDate', 'businessEmitterId']} />
            <PendingInvoicesTable
              key="all"
              nodes={allNodes}
              invoices={allInvoices}
              loading={allLoading}
              limit={limit}
              page={allPage}
              count={allCount}
              next={allNext}
              previous={allPrevious}
              hasNext={allHasNext}
              hasPrevious={allHasPrevious}
              totalAmount={allTotalAmount}
            />
          </S.Container>
        </TabPanel>
      </Tabs>
    </Card>
  );
}

export default PendingInvoices;
