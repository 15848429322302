import styled from 'styled-components';

type WrapperProps = {
  disabled?: boolean;
};

export const Wrapper = styled.label<WrapperProps>`
  display: block;
  input[type='radio'] {
    appearance: none;
  }

  input[type='radio'] + .pseudo-radio::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
    width: ${({ theme }) => theme.spacing[5]};
    height: ${({ theme }) => theme.spacing[5]};
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.spacing[0.5]} ${theme.colors.neutral[300]}`};
    transition: 100ms;
  }

  input[type='radio']:checked + .pseudo-radio::before {
    background-color: ${({ theme }) => theme.colors.primary[500]};
    border-color: ${({ theme }) => theme.colors.neutral[50]};
    border-width: ${({ theme }) => theme.spacing[1]};
    border-style: solid;
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.spacing[0.5]} ${theme.colors.primary[500]}`};
  }

  input[type='radio']:disabled + .pseudo-radio::before {
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.spacing[0.5]} ${theme.colors.neutral[300]}`};
  }

  .pseudo-radio + * {
    margin-left: ${({ theme }) => theme.spacing[3]};
    cursor: ${({ disabled }) => !disabled && 'pointer'};
    p,
    span {
      font-size: ${({ theme }) => theme.fontSize.sm};
      color: ${({ theme, disabled }) =>
        disabled ? theme.colors.neutral[400] : theme.colors.neutral[900]};
    }
  }
`;
