import styled from 'styled-components';

export const DepositModalContainer = styled.div`
  width: 32rem;
  max-width: 90vw;
`;

export const BankingInfoContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[6]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const TransferInfo = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.neutral[500]};
`;
