import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ACCOUNTS_PAYABLE_TABLE_DATA } from './graphql';
import { HeaderCard } from './AccountsPayableTable.styles';
import Currency from '@src/components/i18n/Currency';
import InvoiceGlobalStatusBadge from '@src/components/shared/InvoiceGlobalStatusBadge';
import Card from '@src/components/ui/Card';
import Button from '@src/components/ui/Button';
import Label from '@src/components/ui/Label';
import Table from '@src/components/ui/Table';
import TableRow from '@src/components/ui/TableRow';
import TableHeader from '@src/components/ui/TableHeader';
import TableCell from '@src/components/ui/TableCell';
import TableHeaderCell from '@src/components/ui/TableHeaderCell';
import { InvoiceApprovalStatus, InvoicePaymentStatus } from '@src/types/enums';
import { Invoice, InvoicesReceived, InvoicesReceivedVars } from './types';
import EmptyResults from '@src/components/shared/EmptyResults';
import useSettings from '@src/hooks/useSettings';

export const AccountsPayableTable = () => {
  const intl = useIntl();
  const { business } = useSettings();
  const history = useHistory();

  const { loading, data } = useQuery<InvoicesReceived, InvoicesReceivedVars>(
    ACCOUNTS_PAYABLE_TABLE_DATA,
    {
      variables: {
        businessId: business && business.id,
        approvalStatus: [InvoiceApprovalStatus.APPROVED],
        paymentStatus: [
          InvoicePaymentStatus.PARTIAL,
          InvoicePaymentStatus.PENDING,
        ],
        limit: 5,
        sortBy: { field: 'EXPIRY_DATE', direction: 'ASC' },
      },
      skip: !business,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    }
  );

  const handleButtonClick = () => {
    history.push('/accounts_payable?tabIndex=0');
  };

  const invoices = useMemo(
    () => data?.invoicesReceived?.edges?.map((edge) => edge.node) || [],
    [data]
  );

  return (
    <Card padding="8">
      <HeaderCard>
        <Label strong size="md">
          {intl.formatMessage({
            defaultMessage: 'Cuentas por pagar',
            id: 'y0s+Vr',
          })}
        </Label>

        <Button compact variant="ghosted" onClick={handleButtonClick}>
          {intl.formatMessage({
            defaultMessage: 'Ver todo',
            id: '6q+3v5',
          })}
        </Button>
      </HeaderCard>
      <Table columnsWidth={[130, 120, 120, 200]}>
        <TableHeader compact>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'No. Factura',
              id: 'OWCm2x',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Proveedor',
              id: 'RMvOYP',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Monto',
              id: 'Axixya',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Estatus',
              id: 'dWihL7',
            })}
          </TableHeaderCell>
        </TableHeader>
        <tbody>
          {loading
            ? [...Array(5).keys()].map((i) => (
                <TableRow compact key={i}>
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                  <TableCell loading />
                </TableRow>
              ))
            : invoices?.map((invoice: Invoice, index: number) => (
                <TableRow
                  compact
                  key={index}
                  onClick={() => {
                    history.push(`/invoices/${invoice.id}`);
                  }}
                >
                  <TableCell>{invoice.id}</TableCell>
                  <TableCell>{invoice?.businessEmitter?.name || '-'}</TableCell>
                  <TableCell>
                    <strong>
                      <Currency
                        value={invoice?.total || invoice?.foreignTotal}
                        currency={invoice.currency}
                      />
                    </strong>
                  </TableCell>
                  <TableCell>
                    <InvoiceGlobalStatusBadge
                      expiryDate={invoice.expiryDate}
                      status={invoice.globalStatus}
                    />
                  </TableCell>
                </TableRow>
              ))}
        </tbody>
      </Table>
      {!loading && invoices.length === 0 && (
        <EmptyResults
          compact
          label={intl.formatMessage({
            defaultMessage: 'Aún no tienes cuentas por pagar',
            id: 'DDj4bg',
          })}
          description={intl.formatMessage({
            defaultMessage:
              'Aquí verás todas las cuentas por pagar que tengas en Higo.',
            id: 'BH7PdI',
          })}
        />
      )}
    </Card>
  );
};

export default AccountsPayableTable;
