import { gql } from '@apollo/client';

export const UPDATE_PROVIDER_CONTACT = gql`
  mutation UpdateProviderContact(
    $businessId: ID!
    $businessRelationshipId: ID!
    $providerContactId: ID!
    $providerContact: UpdateProviderContactInput!
  ) {
    updateProviderContact(
      businessId: $businessId
      businessRelationshipId: $businessRelationshipId
      providerContactId: $providerContactId
      providerContact: $providerContact
    ) {
      id
    }
  }
`;
