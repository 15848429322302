import { useEffect, useState } from 'react';

import { centsToFloat } from '@src/utils/currency';
import { FinancingRequest } from '@src/types/models';

interface useFinancingRepaymentAmountsParams {
  inputAmount?: any;
  selectedFinancingRequest?: FinancingRequest & { interestDays: number };
}

const useFinancingRepaymentAmounts = ({
  inputAmount,
  selectedFinancingRequest,
}: useFinancingRepaymentAmountsParams) => {
  const [includeInterest, setIncludeInterest] = useState(false);

  useEffect(() => {
    if (inputAmount && selectedFinancingRequest) {
      const owedTotal = selectedFinancingRequest?.owedAmountCents;
      const inputAmountIsMoreOrEqualToOwedTotal =
        inputAmount >= centsToFloat(owedTotal);

      if (
        inputAmountIsMoreOrEqualToOwedTotal &&
        (selectedFinancingRequest?.totalInterestAmountCents || 0) > 0
      ) {
        setIncludeInterest(true);
      } else {
        setIncludeInterest(false);
      }
    }
  }, [inputAmount, selectedFinancingRequest]);

  const initialAmount =
    (selectedFinancingRequest?.owedAmountCents || 0) +
    (selectedFinancingRequest?.totalInterestAmountCents || 0);

  return { includeInterest, initialAmount };
};

export default useFinancingRepaymentAmounts;
