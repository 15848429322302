import { useQuery } from '@apollo/client';

import WALLET_QUERY, {
  WalletQueryReturn,
  WalletQueryVariables,
} from '@src/graphql/queries/wallet';
import useSettings from '@src/hooks/useSettings';

const defaultValue = {
  id: undefined,
  balance: undefined,
  stpClabe: undefined,
};

function useWallet() {
  const { business } = useSettings();
  const { data } = useQuery<WalletQueryReturn, WalletQueryVariables>(
    WALLET_QUERY,
    {
      variables: {
        businessId: business?.id,
      },
      skip: !business?.hasTaxPayerInfo,
    }
  );

  return data?.wallet || defaultValue;
}

export default useWallet;
