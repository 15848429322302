import React from 'react';
import { useIntl } from 'react-intl';

import Table from '@src/components/shared/Table';
import PaginationControls from '@src/components/shared/PaginationControls';
import EmptyResults from '@src/components/shared/EmptyResults';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import useSettings from '@src/hooks/useSettings';
import useQueryParams from '@src/hooks/useQueryParams';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { ReactComponent as EmptyPaidCreditIcon } from './assets/empty-paid-credit.svg';
import { getTableAttributes } from './utils';
import useFinancingRequestsQuery from '../../hooks/useFinancingRequestsQuery';

function PaidFinancingRequestsTable() {
  const intl = useIntl();

  const { business } = useSettings();

  const attributes = getTableAttributes(intl);

  const limit = RESULTS_PER_PAGE;
  const [query] = useQueryParams();

  const {
    data: paidFinancingRequests,
    loading,
    count,
    hasNext,
    next,
    hasPrevious,
    previous,
    page,
  } = useFinancingRequestsQuery({
    businessId: business?.id,
    repaid: true,
    sortBy: {
      field: 'CREATED_AT',
      direction: 'DESC',
    },
    ...getPaginationValuesForQuery(query, limit),
  });

  if (!loading && paidFinancingRequests.length <= 0) {
    return (
      <EmptyResults
        label={intl.formatMessage({
          defaultMessage: 'Aún no has pagado ninguna disposición',
          id: 'nJ8IMR',
        })}
        description={intl.formatMessage({
          defaultMessage:
            'Aquí verás todas tus disposiciones de crédito que ya hayan sido pagadas.',
          id: 'MRTo6X',
        })}
        icon={<EmptyPaidCreditIcon />}
      />
    );
  }

  return (
    <>
      <Table
        loading={loading}
        keyExtractor={(financingRequest) => financingRequest.id}
        attributes={attributes}
        headers={Object.keys(attributes)}
        data={paidFinancingRequests}
        limit={limit}
        columnsWidth={[140, 160, 160, 165, 165, 165, 140]}
      />
      <PaginationControls
        limit={limit}
        loading={loading}
        page={page}
        itemCount={paidFinancingRequests.length}
        count={count}
        hasNext={hasNext}
        onNext={next}
        hasPrevious={hasPrevious}
        onPrevious={previous}
      />
    </>
  );
}

export default PaidFinancingRequestsTable;
