import React from 'react';
import { FormattedMessage } from 'react-intl';

import ShippingCost from '@src/components/shared/ShippingCost';
import RelationshipStatusBadge from '@src/components/shared/RelationshipStatusBadge';
import { Email } from './ClientsTable.styles';

import type { ClientRelationship } from '@src/types/models';
import type { Attributes } from '@src/types/resourceList';

const attributes: Attributes<ClientRelationship> = {
  clientName: {
    name: <FormattedMessage defaultMessage="Cliente" id="DshuY+" />,
    type: 'STRING',
    render: (datum) => datum.clientName,
  },
  clientContact: {
    name: <FormattedMessage defaultMessage="Email de contacto" id="3sxgTG" />,
    type: 'STRING',
    render: (datum) =>
      datum?.clientContact?.email ? (
        <Email href={`mailto:${datum.clientContact.email}`}>
          {datum.clientContact.email}
        </Email>
      ) : null,
  },
  priceList: {
    name: <FormattedMessage defaultMessage="Lista de precios" id="47LnNu" />,
    type: 'STRING',
    render: (datum) => datum.priceList && datum.priceList.name,
  },
  paymentTerms: {
    name: <FormattedMessage defaultMessage="Crédito" id="kbZ9Ki" />,
    type: 'STRING',
    render: (datum) => (
      <FormattedMessage
        defaultMessage="{days} días"
        id="aNRWjR"
        values={{ days: datum.paymentTerms }}
      />
    ),
  },
  deliveryLeadTimeInDays: {
    name: <FormattedMessage defaultMessage="Tiempo de entrega" id="H7jlFR" />,
    type: 'STRING',
    render: (datum) => (
      <>
        <FormattedMessage
          defaultMessage="{days} días"
          id="aNRWjR"
          values={{ days: datum.deliveryLeadTimeInDays }}
        />{' '}
        {datum.deliveryOnSunday && datum.deliveryOnSaturday ? (
          <FormattedMessage defaultMessage="naturales" id="KBZXgV" />
        ) : !datum.deliveryOnSunday && !datum.deliveryOnSaturday ? (
          <FormattedMessage defaultMessage="hábiles" id="qL6aeA" />
        ) : (
          (!datum.deliveryOnSaturday && (
            <FormattedMessage
              defaultMessage="sin entrega en sábado"
              id="0a+spy"
            />
          )) ||
          (!datum.deliveryOnSunday && (
            <FormattedMessage
              defaultMessage="sin entrega en domingo"
              id="jg3ab6"
            />
          ))
        )}
      </>
    ),
  },
  shippingCostValue: {
    name: <FormattedMessage defaultMessage="Costo de envío" id="ZAlxod" />,
    type: 'STRING',
    render: (datum) => (
      <ShippingCost
        shippingCostType={datum.shippingCostType}
        shippingCostValue={datum.shippingCostValue}
        shippingCostCurrency={datum.shippingCostCurrency}
      />
    ),
  },
  status: {
    name: <FormattedMessage defaultMessage="Estatus" id="dWihL7" />,
    type: 'STRING',
    render: (datum) => <RelationshipStatusBadge status={datum.status} />,
  },
};

export default attributes;
