import Loadable from '@src/components/shared/Loadable';

const LoginLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Login" */
      './Login'
    ),
});

export default LoginLoader;
