import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
`;

export const FormContainer = styled.div`
  position: relative;
  h2 {
    position: absolute;
    top: -${({ theme }) => theme.spacing[14]};
    width: 100%;
    text-align: center;
  }

  form {
    width: 90vw;
    margin-top: ${({ theme }) => theme.spacing[4]};
    @media (min-width: 600px) {
      width: 100%;
    }
  }

  .green-dot {
    color: ${({ theme }) => theme.colors.secondary[200]};
    font-size: inherit;
  }
  button {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;

export const Legend = styled.p`
  text-align: center;
`;
