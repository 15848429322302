import React from 'react';

import { Icon, Text } from '@src/components/ui';
import CircledIcon from '@src/components/ui/CircledIcon';
import { IconName } from '@src/components/ui/Icon/types';
import { FlexColumnContainer } from '@src/components/shared/layouts';
import * as S from './InfoBlock.styles';

interface InfoBlockProps {
  title: string;
  data: string | React.ReactNode;
  iconName: IconName;
}

function InfoBlock({ title, data, iconName }: InfoBlockProps) {
  return (
    <S.Container gap="12px" alignItems="center">
      <CircledIcon backgroundColor="neutral" size="10">
        <Icon size={16} name={iconName} />
      </CircledIcon>
      <FlexColumnContainer>
        <S.Title>{title}</S.Title>
        {typeof data === 'string' ? <Text strong>{data}</Text> : data}
      </FlexColumnContainer>
    </S.Container>
  );
}

export default InfoBlock;
