import { onError } from '@apollo/client/link/error';
import { UNAUTHORIZED, FORBIDDEN, INTERNAL_SERVER_ERROR } from 'http-status';

import { remove as logOut } from '@src/lib/auth';
import { log as logError } from '@src/lib/errorReporter';
import * as qs from '@src/utils/querystring';

const errorLink = onError(({ networkError }) => {
  if (networkError && 'response' in networkError) {
    switch (networkError?.response.status) {
      case UNAUTHORIZED:
        logOut();
        window.location.replace('/login');
        break;
      case FORBIDDEN:
        logError(networkError);
        window.location.assign(`/p/403`);
        break;
      case INTERNAL_SERVER_ERROR:
        logError(networkError);
        const querystring = qs.stringify({
          error: networkError.message,
        });
        window.location.assign(`/p/500/${querystring}`);
        break;
      default:
        break;
    }
  }
});

export default errorLink;
