import React from 'react';

import * as S from './ResultSection.styles';

interface ResultSectionProps {
  section: string;
  subSection?: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
}

const ResultSection = ({
  children,
  section,
  subSection,
  icon,
}: ResultSectionProps) => {
  return (
    <div>
      <S.SectionTitle>
        {icon}
        {section}
        {subSection && ` > ${subSection}`}
      </S.SectionTitle>
      {children}
    </div>
  );
};

export default ResultSection;
