import React, { memo } from 'react';

import PaginationControls from '@src/components/shared/PaginationControls';
import Table from './components/Table';
import { ClientsTableStyled } from './ClientsTable.styles';
import attributes from './attributes';

import type { ClientRelationship } from '@src/types/models';

type ClientProperty = keyof ClientRelationship;

interface ClientsTableProps {
  data: ClientRelationship[];
  loading?: boolean;
  count?: number;
  page?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  actions?: React.ReactNode;
  emptyElement?: React.ReactNode;
  columns?:
    | { [Property in ClientProperty]?: string | number }
    | Array<ClientProperty>;
  limit?: number;
  onNext?: () => void;
  onLimitChange?: (limit: number) => void;
  onPrevious?: () => void;
  onEdit?: (id: string) => void;
}

const ClientsTable = (props: ClientsTableProps) => {
  const {
    data,
    loading,
    count,
    hasNext,
    onNext,
    hasPrevious,
    onPrevious,
    emptyElement,
    columns,
    limit,
    onLimitChange,
    onEdit,
    page,
  } = props;

  const fields = Array.isArray(columns)
    ? columns
    : Object.keys(columns || attributes);
  const columnsWidth =
    Array.isArray(columns) || !columns ? undefined : Object.values(columns);

  if (onEdit) {
    columnsWidth?.push(150);
  }
  return (
    <ClientsTableStyled>
      <Table
        emptyElement={emptyElement}
        loading={loading}
        attributes={attributes}
        fields={fields}
        data={data}
        limit={limit}
        columnsWidth={columnsWidth}
        onEdit={onEdit}
      />

      <PaginationControls
        limit={limit}
        loading={loading}
        page={page}
        itemCount={data.length}
        count={count}
        hasNext={hasNext}
        onNext={onNext}
        hasPrevious={hasPrevious}
        onPrevious={onPrevious}
        onLimitChange={onLimitChange}
      />
    </ClientsTableStyled>
  );
};

export default memo(ClientsTable);
