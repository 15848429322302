import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const ThumbsUp: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path d="M11.7954 1C11.4164 1 11.0729 1.23273 10.919 1.59386L7.08266 10.5939C7.02816 10.7217 7 10.8601 7 11V22C7 22.5523 7.42939 23 7.95908 23H18.7725C19.4643 23.0067 20.1354 22.7532 20.6626 22.2859C21.1912 21.8175 21.5397 21.165 21.6439 20.4499L22.9672 11.4516L22.9674 11.4501C23.03 11.0201 23.0022 10.5811 22.8859 10.1634C22.7696 9.7458 22.5676 9.35951 22.2939 9.03134C22.0203 8.70317 21.6814 8.44096 21.3009 8.26289C20.9222 8.08567 20.5112 7.996 20.0961 8H15.6317V5C15.6317 3.93913 15.2275 2.92172 14.5081 2.17157C13.7886 1.42143 12.8129 1 11.7954 1Z" />
        <path d="M1.87868 10.8787C2.44129 10.3161 3.20435 10 4 10C4.55228 10 5 10.4477 5 11V22C5 22.5523 4.55228 23 4 23C3.20435 23 2.44129 22.6839 1.87868 22.1213C1.31607 21.5587 1 20.7956 1 20V13C1 12.2044 1.31607 11.4413 1.87868 10.8787Z" />
      </svg>
    );
  }
);

ThumbsUp.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ThumbsUp.displayName = 'ThumbsUp';

export default ThumbsUp;
