import styled from 'styled-components';

type LinkProps = {
  disabled?: boolean;
};

export const Link = styled.p<LinkProps>`
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral[300] : theme.colors.primary[500]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 0;
  display: flex;
  align-items: center;
  svg {
    margin: 0;
    margin-right: ${({ theme }) => theme.spacing[1]};
  }
`;

export const LinkInvoiceButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.primary[500]};
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
