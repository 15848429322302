import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import * as S from './TrustedPartnerLayout.styles';
import {
  FlexColumnContainer,
  FlexContainer,
  Spacer,
  Grid,
} from '@src/components/shared/layouts';
import { Text } from '@src/components/ui';
import Logo from '@src/components/shared/Logo';
import LinkButton from '@src/components/shared/buttons/LinkButton';

import { ReactComponent as Trustpilot } from './img/trustpilot.svg';
import joker from './img/jokr.png';
import diri from './img/diri.png';
import benebox from './img/benebox.png';
import vinoschidos from './img/vinoschidos.png';
import siclo from './img/siclo.png';

type TrustedPartnerLayoutProps = {
  children: ReactNode;
};
export const TrustedPartnerLayout = ({
  children,
}: TrustedPartnerLayoutProps) => {
  const { formatMessage } = useIntl();
  return (
    <FlexColumnContainer>
      <S.TopContent>
        <a href="https://higo.io">
          <Logo size={30} monochrome />
        </a>
        <div>
          <LinkButton href="/p/login" variant="transparent">
            {formatMessage({
              defaultMessage: 'Iniciar Sesión',
              id: 'QordSC',
            })}
          </LinkButton>
          <Spacer direction="horizontal" margin="4" />
          <LinkButton href="/p/sign-up">
            {formatMessage({
              defaultMessage: 'Crear cuenta',
              id: '4sjoNr',
            })}
          </LinkButton>
        </div>
      </S.TopContent>

      <FlexContainer flexDirection="column" alignItems="center">
        {children}
        <Spacer direction="vertical" margin="12" />
        <Trustpilot />
        <Spacer direction="vertical" margin="6" />
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={4}>
            <FlexContainer flexDirection="column" alignItems="center">
              <Text color="neutral" colorStep="500" small>
                {formatMessage({
                  defaultMessage: 'EMPRESAS QUE CONFIAN EN HIGO',
                  id: 'VZ0kGH',
                })}
              </Text>
              <Spacer direction="vertical" margin="2" />
              <Grid
                container
                item
                xs={12}
                direction="row"
                justifyContent="space-between"
              >
                <div>
                  <S.Logo src={joker} />
                </div>
                <div>
                  <S.Logo src={diri} />
                </div>
                <div>
                  <S.Logo src={benebox} />
                </div>
                <div>
                  <S.Logo src={vinoschidos} />
                </div>
                <div>
                  <S.Logo src={siclo} />
                </div>
              </Grid>
              <Spacer direction="vertical" margin="8" />
              <Logo
                monochrome
                variant="isotype"
                color="neutral"
                colorStep="500"
              />
              <Text color="neutral" colorStep="500" light>
                {formatMessage({
                  defaultMessage: 'Enviado desde mi higo.io',
                  id: 'WseA0J',
                })}
              </Text>
              <Text color="neutral" colorStep="500" light>
                {formatMessage({
                  defaultMessage:
                    'Higo, la plataforma para pagar, cobrar y financiar las facturas de tu empresa.',
                  id: 'rhzPpS',
                })}
              </Text>
              <Spacer direction="vertical" margin="4" />
              <Text className="copyright" color="neutral" colorStep="500" light>
                Copyright © 2022 Higo Holdings, Inc. All rights reserved
              </Text>
              <Spacer direction="vertical" margin="12" />
            </FlexContainer>
          </Grid>
        </Grid>
      </FlexContainer>
    </FlexColumnContainer>
  );
};

export default TrustedPartnerLayout;
