import React from 'react';
import { useIntl } from 'react-intl';

import DownloadSuccess, {
  AcceptedFormat,
} from '@src/components/shared/DownloadSuccess';
import { Icon, IconButton, Tooltip } from '@src/components/ui';
import { FlexContainer } from '@src/components/shared/layouts';
import useExportTransactionMutationPDF from '@src/hooks/useExportTransactionMutationPDF';
import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import { trackEvent } from '@src/lib/analytics';
import { DOWNLOAD_CREDIT_REQUEST_RECEIPT_CLICKED } from '@src/constants/events';
import { WalletEntry } from '@src/types/models';

interface DownloadReceiptButtonProps {
  walletEntries: WalletEntry[] | undefined;
}

function DownloadReceiptButton({ walletEntries }: DownloadReceiptButtonProps) {
  const intl = useIntl();
  const { open } = useModal(DownloadSuccess);
  const { currentUser, business } = useSettings();
  const receiptUrls =
    walletEntries?.map((entry) => entry.receiptUrl)?.filter(Boolean) || [];
  const { mutate: exportTransactions } = useExportTransactionMutationPDF({
    onCompleted() {
      if (currentUser?.email) {
        open({
          email: currentUser.email,
          acceptedFormat: AcceptedFormat.PDF,
        });
      }
    },
  });

  function handleOnClick() {
    const entriesCount = walletEntries?.length;
    trackEvent(DOWNLOAD_CREDIT_REQUEST_RECEIPT_CLICKED, {
      entriesCount,
    });
    /*
      If the transaction only has one entry, we can download it directly on the 
      browser. Otherwise, if the transaction has more than one entry, we need to
      send them via email.
    */
    if (receiptUrls.length === 1) {
      window.open(walletEntries?.[0].receiptUrl, '_blank');
    } else if (receiptUrls.length > 0) {
      const walletEntriesId = walletEntries?.map(
        (walletEntry) => walletEntry.id
      );
      if (business?.id) {
        exportTransactions({
          variables: {
            businessId: business.id,
            walletEntriesIds: walletEntriesId,
          },
        });
      }
    }
  }

  if (receiptUrls.length === 0) {
    return null;
  }

  return (
    <FlexContainer justifyContent="flex-end">
      <Tooltip
        id="download-receipt-tooltip"
        title={intl.formatMessage({
          id: '4Xcl3f',
          defaultMessage: 'Descargar comprobante',
        })}
        place="left"
        effect="solid"
      >
        <IconButton color="neutral" variant="ghosted" onClick={handleOnClick}>
          <Icon name="Download" size={20} />
        </IconButton>
      </Tooltip>
    </FlexContainer>
  );
}

export default DownloadReceiptButton;
