import { gql } from '@apollo/client';

export const BULK_INVOICE_APPROVAL_ATTRIBUTES = gql`
  fragment BulkInvoiceApprovalAttributes on Invoice {
    id
    paymentDate
    approvalStatus
    paymentStatus
    mailboxStatus
    globalStatus
    emitterTaxpayerName
    emitterTaxpayerIdentifier
    satVerified
    outstandingBalance
    total
    currency
    invoicingDate
    foreignTotal
    expiryDate
    createdAt
    codatSyncedAt
    voucherType
    receiverCountryTaxPayerInfo {
      __typename
      ... on MexicanTaxPayerInfo {
        folio
      }
    }
    businessEmitter {
      id
      name
      hashId
      bankingInfos {
        id
        bankId
        recipient
        bankName
        clabe
      }
    }
    businessRelationship {
      id
      paymentTerms
      supplier {
        id
        name
        hashId
        defaultBankingInfo {
          id
          clabe
          bankName
          recipient
        }
      }
    }
    costCenter {
      id
      name
    }
  }
`;
