import styled from 'styled-components';

export const TransactionSection = styled.div`
  > div {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
  &:last-child {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[600]};

  > svg {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }

  p {
    margin: 0;
  }
`;
