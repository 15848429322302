import styled from 'styled-components';

export const FormContainer = styled.div`
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.spacing[7]};
    button {
      margin-left: ${({ theme }) => theme.spacing[4]};
    }
  }
`;

export const TooltipSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.primary[500]};
  font-size: ${({ theme }) => theme.fontSize.xs};
  cursor: pointer;
`;
