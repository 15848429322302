import { gql } from '@apollo/client';

export const CREATE_FINANCING_REQUEST = gql`
  mutation CreateFinancingRequest(
    $businessId: ID!
    $creditDays: Int!
    $amountCents: Int!
  ) {
    createFinancingRequest(
      businessId: $businessId
      creditDays: $creditDays
      amountCents: $amountCents
    ) {
      id
      amount
    }
  }
`;

export type CreateFinancingRequestMutationReturn = {
  createFinancingRequest: {
    id: number;
    amount: number;
  };
};

export type CreateFinancingRequestMutationVariables = {
  businessId: string;
  creditDays: number;
  amountCents: number;
};
