import styled from 'styled-components';

import { Card, Header as UiHeader } from '@src/components/ui';

export const Container = styled(Card)`
  text-align: center;
  max-width: 527px;
`;

export const Header = styled(UiHeader)`
  margin: 0 auto;
  max-width: 400px;
`;
