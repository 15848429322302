import React from 'react';
import DatePicker from '@src/components/ui/DatePicker';
import { useIntl } from 'react-intl';

interface DateRangePickerProps {
  onChange?: (dateRange: { start?: string; end?: string }) => void;
  value?: {
    start?: string;
    end?: string;
  };
  name: string;
  fullWidth?: boolean;
}

function DateRangePicker({
  onChange,
  value,
  name,
  fullWidth,
}: DateRangePickerProps) {
  const intl = useIntl();
  const { start, end } = value || {};

  const handleChange = (nextStart, nextEnd) => {
    if (onChange) {
      onChange({
        start: nextStart,
        end: nextEnd,
      });
    }
  };

  const handleClear = () => {
    if (onChange) {
      onChange({
        start: undefined,
        end: undefined,
      });
    }
  };

  return (
    <DatePicker
      id={name}
      minDate={start}
      selectsEnd
      placeholder={intl.formatMessage({
        id: 'FoFHSz',
        defaultMessage: 'Desde - Hasta',
      })}
      startDate={start}
      endDate={end}
      value={end}
      onChange={handleChange}
      onClear={handleClear}
      fullWidth={fullWidth}
      selectsRange
    />
  );
}

export default DateRangePicker;
