import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const PlayRound: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path d="M7.5 2C6.84851 2 6.22317 2.14375 5.6346 2.42696L5.61395 2.43689L5.59378 2.44776C4.84105 2.85341 4.22485 3.49727 3.88002 4.28162L3.87395 4.29543L3.8683 4.30941C3.75516 4.58938 3.63672 5.06031 3.55827 5.39094C3.51533 5.57188 3.47763 5.74046 3.45048 5.86611C3.43693 5.92878 3.42565 5.98244 3.41746 6.02274C3.41347 6.04238 3.4096 6.06182 3.40642 6.07874C3.40501 6.08627 3.40288 6.0978 3.40074 6.11059C3.13492 7.49677 3 9.66258 3 11.9891C3 14.2312 3.13771 16.2963 3.35284 17.6765L3.40473 18.0094L3.43056 18.0343C3.46033 18.1768 3.49959 18.3626 3.54537 18.5615C3.59737 18.7874 3.66027 19.0412 3.73095 19.2783C3.79686 19.4994 3.88729 19.7681 4.00725 19.9894L4.00805 19.9908C4.68689 21.238 6.00526 22 7.40909 22H7.51604L7.53206 21.9995C8.03747 21.9833 8.69501 21.787 9.12854 21.6425C9.36713 21.5631 9.5836 21.4834 9.74385 21.4206C9.82304 21.3896 9.89457 21.3604 9.9511 21.3357C9.97684 21.3244 10.0118 21.3088 10.0466 21.2913C11.3356 20.7673 13.1568 19.7527 14.8787 18.6381C16.6153 17.5138 18.3509 16.2235 19.3892 15.1457L19.5116 15.028C19.7981 14.7523 20.1434 14.3495 20.2403 14.2261C20.7517 13.5791 21 12.789 21 12.0131C21 11.1563 20.7223 10.3432 20.1888 9.67887L20.1487 9.62889L20.1025 9.58444C20.1025 9.58444 20.1002 9.5822 20.0928 9.57473C20.0895 9.57139 20.0853 9.56711 20.0801 9.56173C20.0646 9.54581 20.045 9.52545 20.0207 9.50008L19.9606 9.43708C19.9241 9.39891 19.8824 9.35522 19.841 9.31202C19.7058 9.17106 19.535 8.99512 19.3652 8.83115C18.3517 7.78846 16.6009 6.477 14.8233 5.32187C13.0386 4.16214 11.118 3.08945 9.74502 2.56955C9.52442 2.48361 9.15809 2.3548 8.77372 2.24296C8.41079 2.13735 7.93449 2.0173 7.5418 2.00087L7.52091 2H7.5Z" />
      </svg>
    );
  }
);

PlayRound.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PlayRound.displayName = 'PlayRound';

export default PlayRound;
