import useSettings from '../useSettings';
import { hasBusinessFeature } from '@src/utils/features';

import { FeatureName } from '@src/types/enums';

function useBusinessFeatures(features: Array<keyof typeof FeatureName>) {
  const business = useSettings('business');
  return hasBusinessFeature(features, business);
}

export default useBusinessFeatures;
