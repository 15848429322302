import { createGlobalStyle } from 'styled-components';

import ApercuBold from './fonts/Apercu-Bold.otf';
import ApercuBoldItalic from './fonts/Apercu-BoldItalic.otf';
import ApercuItalic from './fonts/Apercu-Italic.otf';
import ApercuLight from './fonts/Apercu-Light.otf';
import ApercuLightItalic from './fonts/Apercu-LightItalic.otf';
import ApercuMedium from './fonts/Apercu-Medium.otf';
import ApercuMediumItalic from './fonts/Apercu-MediumItalic.otf';
import Apercu from './fonts/Apercu.otf';

const FontStyles = createGlobalStyle`
      @font-face {
      font-family: Apercu;
      src: url(${ApercuLight});
      font-style: normal;
      font-weight: 300;
      }

      @font-face {
      font-family: Apercu;
      src: url(${Apercu});
      font-style: normal;
      font-weight: 400;
      }

      @font-face {
      font-family: Apercu;
      src: url(${ApercuMedium});
      font-style: normal;
      font-weight: 500;
      }

      @font-face {
      font-family: Apercu;
      src: url(${ApercuBold});
      font-style: normal;
      font-weight: 700;
      }

      @font-face {
      font-family: Apercu;
      src: url(${ApercuLightItalic});
      font-style: italic;
      font-weight: 300;
      }

      @font-face {
      font-family: Apercu;
      src: url(${ApercuItalic});
      font-style: italic;
      font-weight: 400;
      }

      @font-face {
      font-family: Apercu;
      src: url(${ApercuMediumItalic});
      font-style: italic;
      font-weight: 500;
      }

      @font-face {
      font-family: Apercu;
      src: url(${ApercuBoldItalic});
      font-style: italic;
      font-weight: 700;
      }
`;

export default FontStyles;
