import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

import { InputProps } from './types';
import { StyledInputContainer, StyledInput } from './Input.styles';

const unsupportedTypes = ['email', 'number'];

function Input(props: InputProps, ref) {
  const {
    mask,
    type,
    error,
    showMask,
    fullWidth,
    startIcon,
    endIcon,
    ...otherProps
  } = props;

  if (mask && type && unsupportedTypes.includes(type)) {
    console.error(`Type "${type}" is unsupported when using a mask`);
  }

  return (
    <StyledInputContainer fullWidth={fullWidth} error={error}>
      {mask && type && !unsupportedTypes.includes(type) ? (
        <MaskedInput
          autoComplete="off"
          {...otherProps}
          mask={mask}
          type={type}
          showMask={showMask}
          render={(rf, inputProps) => {
            const setRef = (element: HTMLElement) => {
              rf(element);
              ref && ref(element);
            };
            return (
              <StyledInput
                startIcon={!!startIcon}
                endIcon={!!endIcon}
                ref={setRef}
                {...inputProps}
                error={error}
              />
            );
          }}
        />
      ) : (
        <StyledInput
          ref={ref}
          startIcon={!!startIcon}
          endIcon={!!endIcon}
          error={error}
          type={type}
          {...otherProps}
        />
      )}
      {startIcon && <div className="input-start-icon">{startIcon}</div>}
      {endIcon && <div className="input-end-icon">{endIcon}</div>}
    </StyledInputContainer>
  );
}

export default forwardRef(Input);
