import React from 'react';
import { useIntl } from 'react-intl';

import {
  STATUS_TRANSACTION_PROCESSING,
  STATUS_TRANSACTION_PROCESSED,
  STATUS_TRANSACTION_FAILED,
} from '@src/constants/transactions';
import Select from '@src/components/ui/Select';

type StatusFilterProps = {
  onChange?: (...args: any) => void;
  value?: string;
};

const StatusFilter = ({ onChange, value }: StatusFilterProps) => {
  const intl = useIntl();

  const handleChange = (option) => {
    if (onChange) {
      onChange({
        status: option?.value,
      });
    }
  };

  const options = [
    {
      label: intl.formatMessage({
        defaultMessage: 'Procesando',
        id: '4ujRn4',
      }),
      value: STATUS_TRANSACTION_PROCESSING,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Procesado',
        id: 'dvMSHB',
      }),
      value: STATUS_TRANSACTION_PROCESSED,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Fallo',
        id: 'L1wml9',
      }),
      value: STATUS_TRANSACTION_FAILED,
    },
  ];

  const selected = options.find((option) => option.value === value);

  return (
    <Select
      isClearable
      value={selected}
      placeholder={intl.formatMessage({
        defaultMessage: 'Status',
        id: 'tzMNF3',
      })}
      onChange={handleChange}
      options={options}
    />
  );
};

export default StatusFilter;
