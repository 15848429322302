import styled from 'styled-components';
import { SelectableTableRow as TableRow } from '@src/components/ui/TableRow';

export const Container = styled.div`
  margin: ${({ theme }) => theme.spacing[8]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  position: relative;
`;

export const SelectableTableRow = styled(TableRow)`
  button {
    opacity: 0;
    transition: opacity 0.15s ease;
  }
  &:hover button {
    opacity: 1;
  }
`;
