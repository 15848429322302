import { gql } from '@apollo/client';
import type { BusinessUser } from '@src/types/models';

const BUSINESS_USER_QUERY = gql`
  query BusinessUser($businessUserId: ID!, $businessId: ID!) {
    businessUser(businessUserId: $businessUserId, businessId: $businessId) {
      id
      userId
      firstName
      lastName
      status
      email
      profileImage
      role
      jobPosition
      permissions {
        id
        name
        taxPayerInfo {
          id
        }
      }
    }
  }
`;

export type BusinessUserParams = {
  businessId?: string;
  businessUserId?: string;
};

export type BusinessUserReturn = {
  businessUser: Pick<
    BusinessUser,
    | 'id'
    | 'userId'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'profileImage'
    | 'role'
    | 'status'
    | 'jobPosition'
    | 'permissions'
  >;
};

export default BUSINESS_USER_QUERY;
