import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Filters from '@src/components/shared/Filters';
import useSettings from '@src/hooks/useSettings';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import InvoicesTable, {
  InvoiceActionDropdown,
} from '@src/components/shared/InvoicesTable';

import Button from '@src/components/ui/Button';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import EmptyResults from '@src/components/shared/EmptyResults';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import {
  InvoiceVoucherType,
  InvoiceApprovalStatus,
  InvoicePaymentStatus,
} from '@src/types/enums';

interface SupplierInvoicesTableProps {
  businessEmitterId: string;
  businessEmitterName: string;
}

const SupplierInvoicesTable = ({
  businessEmitterId,
  businessEmitterName,
}: SupplierInvoicesTableProps) => {
  const history = useHistory();
  const intl = useIntl();
  const limit = RESULTS_PER_PAGE;
  const { business, currentUser } = useSettings();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filters] = useInvoiceFilters({
    approvalStatus: [
      InvoiceApprovalStatus.APPROVED,
      InvoiceApprovalStatus.PENDING,
    ],
    paymentStatus: [
      InvoicePaymentStatus.PENDING,
      InvoicePaymentStatus.PARTIAL,
      InvoicePaymentStatus.PAID,
    ],
    businessEmitterId: [businessEmitterId],
    voucherType: [InvoiceVoucherType.INVOICE],
    archived: false,
  });
  const {
    loading,
    data: { invoices, totalAmount },
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
    nodes,
  } = useInvoicesListQuery({
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  const anyApprovalPending = useMemo(
    () =>
      invoices.some(
        (invoice) =>
          selectedIds.includes(invoice.id) &&
          invoice.approvalStatus === InvoiceApprovalStatus.PENDING
      ),
    [selectedIds, invoices]
  );

  const handleNextClick = () => {
    history.push(`/invoice-payments?invoiceIds=${selectedIds.join(',')}`);
  };

  const clearSelection = () => {
    setSelectedIds([]);
  };

  return (
    <div>
      <Filters
        fields={['invoicingDate', 'expiryDate', 'globalStatus']}
        globalStatusOptions={[
          'pendingApproval',
          'pendingPayment',
          'expired',
          'financingRequested',
          'paid',
        ]}
      />
      <InvoicesTable
        columns={{
          id: 100,
          receiverCountryTaxPayerInfo: 100,
          invoicingDate: 200,
          globalStatus: 200,
          outstandingBalance: 120,
          expiryDate: 200,
          paymentStatus: 100,
        }}
        emptyElement={
          <EmptyResults
            label={intl.formatMessage(
              {
                defaultMessage: 'Aún no tienes facturas con {supplierName}',
                id: '50bFn0',
              },
              {
                supplierName: businessEmitterName,
              }
            )}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas las facturas que tengan en Higo.',
              id: 'bqD93o',
            })}
          />
        }
        nodes={nodes}
        invoices={invoices}
        loading={loading}
        showActionBar
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        onNext={next}
        hasNext={hasNext}
        onPrevious={previous}
        hasPrevious={hasPrevious}
        selectable
        limit={limit}
        count={count}
        page={page}
        totalAmount={totalAmount}
        actions={
          <>
            <InvoiceActionDropdown
              invoiceIds={selectedIds}
              count={count}
              businessId={business?.id}
              userEmail={currentUser?.email}
              archivable
              payable
              onArchive={clearSelection}
              onMarkAsPaid={clearSelection}
            />{' '}
            <Button onClick={handleNextClick}>
              {anyApprovalPending
                ? intl.formatMessage({
                    defaultMessage: 'Aprobar y pagar',
                    id: 'PtAMp/',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Revisar y pagar',
                    id: 'cUuhwH',
                  })}
            </Button>
          </>
        }
      />
    </div>
  );
};

export default SupplierInvoicesTable;
