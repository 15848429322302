import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const ArrowTopLine: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M12 2C12.5523 2 13 2.44772 13 3V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V3C11 2.44772 11.4477 2 12 2Z" />
        <path d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071C19.3166 11.0976 18.6834 11.0976 18.2929 10.7071L12 4.41421L5.70711 10.7071C5.31658 11.0976 4.68342 11.0976 4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289L11.2929 2.29289Z" />
        <path d="M20 22C20 22.5523 19.5523 23 19 23L5 23C4.44771 23 4 22.5523 4 22C4 21.4477 4.44771 21 5 21L19 21C19.5523 21 20 21.4477 20 22Z" />
      </svg>
    );
  }
);

ArrowTopLine.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowTopLine.displayName = 'ArrowTopLine';

export default ArrowTopLine;
