import React from 'react';
import { useIntl } from 'react-intl';

import Page from '@src/components/shared/Page';
import PageTitle from '@src/components/shared/PageTitle';
import DownloadSuccess from '@src/components/shared/DownloadSuccess';
import TransactionsTable from '@src/components/pages/TransactionList/components/TransactionsTable';
import ResendEmailModal from '@src/components/shared/modals/ResendEmailModal';
import usePageTrack from '@src/hooks/usePageTrack';
import PaymentsReconciliationModal from '@src/components/shared/modals/PaymentsReconciliationModal';
import Modal from '@src/components/shared/Modal';
import DownloadTransactionsCSV from './components/DownloadTransactionsCSV';
import DownloadTransactionsPDF from './components/DownloadTransactionsPDF';

function TransactionsList() {
  usePageTrack('transactions_pending', 'core');
  const intl = useIntl();

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Transacciones',
        id: 'Aqopvj',
      })}
    >
      <PageTitle>
        {intl.formatMessage({
          defaultMessage: 'Transacciones',
          id: 'Aqopvj',
        })}
      </PageTitle>
      <TransactionsTable />
      <Modal component={ResendEmailModal} />
      <Modal component={DownloadTransactionsCSV} />
      <Modal component={DownloadTransactionsPDF} />
      <Modal component={DownloadSuccess} />
      <Modal component={PaymentsReconciliationModal} />
    </Page>
  );
}

export default TransactionsList;
