import styled from 'styled-components';
import Card from '@src/components/ui/Card';

export const StyledCard = styled(Card)`
  position: relative;
  color: ${({ theme }) => theme.colors.neutral[50]};
  h5 {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }

  .bottom-art {
    position: absolute;
    max-width: ${({ theme }) => theme.spacing[50]};
    max-height: ${({ theme }) => theme.spacing[24]};
    right: 0;
    bottom: 0;
  }

  .blob {
    fill: ${({ theme }) => theme.colors.neutral[700]};
  }

  .star {
    fill: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

export const PreApproved = styled.div`
  display: flex;
  justify-content: space-between;
`;
