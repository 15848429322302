import styled, { keyframes } from 'styled-components';

type ButtonProps = {
  color: 'warning' | 'neutral';
};

export const heartBeat = keyframes`
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
`;

export const Button = styled.button<ButtonProps>`
  border: none;
  color: ${({ theme, color }) =>
    color === 'neutral' ? theme.colors.neutral[600] : '#FFC90B'};
  background: transparent;
  transition: 300ms;
  height: ${({ theme }) => theme.spacing[8]};
  width: ${({ theme }) => theme.spacing[8]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: ${({ theme }) => theme.fontSize.lg};

  &.featured-heartbeat {
    animation-name: ${heartBeat};
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
  }

  &:hover {
    background-color: ${({ theme, color }) =>
      color === 'neutral' ? theme.colors[color][200] : theme.colors[color][50]};
  }
`;
