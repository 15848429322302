import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Card, CurrencyInput, Label } from '@src/components/ui';
import { ArrowDown } from '@src/components/ui/Icon/solid';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import CircledIcon from '@src/components/ui/CircledIcon';
import * as S from './RequestCreditWidget.styles';
import useModal from '@src/hooks/useModal';
import WithdrawFromCreditModal from '@src/components/shared/modals/WithdrawFromCreditModal';
import { floatToCents } from '@src/utils/currency';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';

function RequestCreditWidget() {
  const intl = useIntl();
  const [amount, setAmount] = useState<string>();
  const { open: openWithdrawFromCredit } = useModal(WithdrawFromCreditModal);

  const onClick = () => {
    if (amount) {
      const amountFloat = parseCurrency(amount);
      if (amountFloat && amountFloat > 0) {
        const amountCents = floatToCents(amountFloat);
        openWithdrawFromCredit({
          amountCents,
        });
      }
    }
  };

  return (
    <Card fullHeight fullWidth horizontalPadding="6">
      <FlexContainer alignItems="center" fullHeight>
        <CircledIcon backgroundColor="primary" backgroundColorStep="50">
          <ArrowDown size={20} />
        </CircledIcon>
        <Spacer margin="4" direction="horizontal" />
        <Label
          style={{ flex: 1 }}
          size="lg"
          color="neutral"
          colorStep="900"
          strong
        >
          {intl.formatMessage({
            defaultMessage: 'Disponer crédito',
            id: '9IOi+O',
          })}
        </Label>
        <Spacer margin="4" direction="horizontal" />
        <S.InputContainer>
          <CurrencyInput
            fullWidth
            placeholder={intl.formatMessage({
              defaultMessage: 'Ingresa un monto',
              id: '2aojbW',
            })}
            onChange={(e) => setAmount(e.target.value)}
          />
        </S.InputContainer>
        <Spacer margin="2" direction="horizontal" />
        <Button
          onClick={onClick}
          disabled={!amount || parseCurrency(amount) === 0}
        >
          {intl.formatMessage({
            defaultMessage: 'Disponer',
            id: 'P8nFFA',
          })}
        </Button>
      </FlexContainer>
    </Card>
  );
}

export default RequestCreditWidget;
