import { gql } from '@apollo/client';

const REMOVE_BUSINESS_USER_MUTATION = gql`
  mutation RemoveBusinessUser(
    $businessId: ID!
    $businessUserId: ID!
    $recepientUserId: ID!
  ) {
    removeBusinessUser(
      businessId: $businessId
      businessUserId: $businessUserId
      recepientUserId: $recepientUserId
    ) {
      id
    }
  }
`;

export type RemoveBusinessUserParams = {
  businessId: string;
  businessUserId: string;
  recepientUserId: string;
};

export type RemoveBusinessUserReturn = {
  removeBusinessUser: {
    id: string;
  };
};

export default REMOVE_BUSINESS_USER_MUTATION;
