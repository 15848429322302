import { useQuery } from '@apollo/client';
import PAYMENT_INSTRUCTIONS_QUERY, {
  PaymentInstructionsQueryReturn,
  PaymentInstructionsQueryVariables,
} from '@src/graphql/queries/paymentInstructions';
import useSettings from '@src/hooks/useSettings';
import { PaymentInstructionStatus } from '@src/types/enums';
import filterPaymentInstructions from '../utils/filterPaymentInstructions';

type UsePaymentRequests = Partial<
  Pick<PaymentInstructionsQueryVariables, 'status'>
>;

function usePaymentRequests({ status }: UsePaymentRequests) {
  const { business } = useSettings();

  const { data, loading } = useQuery<
    PaymentInstructionsQueryReturn,
    PaymentInstructionsQueryVariables
  >(PAYMENT_INSTRUCTIONS_QUERY, {
    variables: {
      businessId: business?.id,
      status: status || [
        PaymentInstructionStatus.REQUESTED,
        PaymentInstructionStatus.DRAFTED,
      ],
    },
    skip: !business?.id,
    fetchPolicy: 'cache-and-network',
  });

  if (data?.paymentInstructions) {
    const paymentInstructions = data?.paymentInstructions.map(
      (instruction) => ({
        ...instruction,
        paymentInstructionInvoices: filterPaymentInstructions(
          instruction.paymentInstructionInvoices
        ),
      })
    );
    const noEmptyPaymentInstructions = paymentInstructions.filter(
      (instruction) => instruction.paymentInstructionInvoices.length
    );

    return {
      paymentInstructions: noEmptyPaymentInstructions,
      loading,
    };
  }

  return {
    paymentInstructions: data?.paymentInstructions,
    loading,
  };
}

export default usePaymentRequests;
