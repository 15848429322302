import { gql } from '@apollo/client';
import {
  InvoiceApprovalStatus,
  InvoiceEmissionStatus,
  InvoicePaymentStatus,
} from '@src/types/enums';
import { Invoice } from '@src/types/models';
import { PaginatedResponse, Sort } from '@src/types/utils';

const INVOICES_EMITTED_QUERY = gql`
  query AccountsReceivableItems(
    $businessId: ID!
    $approvalStatus: [InvoiceApprovalStatusEnum!]
    $paymentStatus: [InvoicePaymentStatusEnum!]
    $emissionStatus: [InvoiceEmissionStatusEnum!]
    $businessReceiverId: [ID!]
    $search: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: InvoicesSortingParams
  ) {
    invoicesEmitted(
      businessId: $businessId
      approvalStatus: $approvalStatus
      paymentStatus: $paymentStatus
      emissionStatus: $emissionStatus
      businessReceiverId: $businessReceiverId
      search: $search
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          id
          invoiceables {
            __typename
            ... on SubscriptionStatement {
              id
            }
            ... on Order {
              hashId
            }
          }
          approvalStatus
          emissionStatus
          globalStatus
          businessReceiver {
            id
            name
          }
          expiryDate
          currency
          createdAt
          paymentStatus
          total
        }
      }
    }
  }
`;

export type PartialInvoice = Pick<
  Invoice,
  | 'id'
  | 'expiryDate'
  | 'currency'
  | 'createdAt'
  | 'paymentStatus'
  | 'total'
  | 'invoiceables'
  | 'approvalStatus'
  | 'emissionStatus'
  | 'globalStatus'
  | 'businessReceiver'
>;

export type InvoicesEmittedReturn = {
  invoicesEmitted: PaginatedResponse<PartialInvoice>;
};

export type InvoicesEmittedVariables = {
  businessId?: string;
  approvalStatus?: Array<InvoiceApprovalStatus>;
  paymentStatus?: Array<InvoicePaymentStatus>;
  emissionStatus?: Array<InvoiceEmissionStatus>;
  businessReceiverId?: Array<string>;
  search?: string;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  sortBy?: Sort<
    'ID' | 'CREATED_AT' | 'INVOICING_DATE' | 'EXPIRY_DATE' | 'PAYMENT_DATE'
  >;
};

export default INVOICES_EMITTED_QUERY;
