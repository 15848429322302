import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import * as S from './HigoSuite.styles';
import { Switch } from '@src/components/ui';

type HigoSuiteCardProps = {
  name: string;
  features: any;
  checked: boolean;
  onChange: (...args: Array<any>) => any;
};

function HigoSuiteCard(props: HigoSuiteCardProps) {
  const { name, features, onChange, checked } = props;
  const intl = useIntl();
  return (
    <S.Card padding="8">
      <S.Header>
        <p>{intl.formatMessage(messages[name])}</p>
        <Switch onChange={() => onChange(name)} checked={checked} />
      </S.Header>
      <div>
        <p>{intl.formatMessage(messages[`${name}_DESCRIPTION`])}</p>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>{intl.formatMessage(messages[feature.name])}</li>
          ))}
        </ul>
      </div>
    </S.Card>
  );
}

export default HigoSuiteCard;
