import { gql } from '@apollo/client';
import { BankingTransaction } from '@src/types/models';

export const CREATE_PAYMENTS = gql`
  mutation CreatePayments(
    $businessId: ID!
    $payments: [PaymentsWithBusinessRelationshipInput!]!
  ) {
    createPayments(businessId: $businessId, payments: $payments) {
      id
      status
      paymentConcept
      amountCents
      receiverAccount {
        __typename
        id
        clabe
        taxId
        bank {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

export type CreatePaymentsMutationReturn = {
  createPayments: BankingTransaction[];
};

export type CreatePaymentsMutationVariables = {
  businessId: string;
  payments: {
    businessRelationshipId: string;
    amountCents: number;
    higoPay: boolean;
    paymentConcept?: string;
  }[];
};
