import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useQuery, useMutation, QueryResult } from '@apollo/client';
import useQueryParams from '@src/hooks/useQueryParams';
import messages, { descriptions } from './messages';

import Tag from '@src/components/ui/Tag';
import Breadcrumb from '@src/components/shared/Breadcrumb';
import { Button, Card, Header } from '@src/components/ui';
import { FlexContainer, Grid, Spacer } from '@src/components/shared/layouts';
import Page from '@src/components/shared/Page';
import { IntegrationData } from './types';
import { CODAT_COMPANY_QUERY } from '@src/graphql/queries';
import { CREATE_CODAT_DATA_SOURCE } from '@src/graphql/mutations';
import { ConnectButton } from './components/ConnectButton';
import { Settings } from '@src/components/ui/Icon/outline';

import { IntegrationsName } from '@src/types/enums';
import { Business, CodatDataSource } from '@src/types/models';

import quickBooksOnlineImg from './img/qbo.png';
import netsuiteImg from './img/netsuite.png';
import sapImg from './img/sap.png';
import useToast from '@src/hooks/useToast';

interface IntegrationsProps {
  business?: Business;
}

interface IntegrationsDataType {
  [key: string]: IntegrationData;
}

const IntegrationsData: IntegrationsDataType = {
  quickBooksSandbox: {
    code: 'quickBooksSandbox',
    displayName: 'QBO Sandbox',
    codatPlatformKey: 'quickbooksonlinesandbox',
    img: quickBooksOnlineImg,
    soon: false,
  },
  quickBooks: {
    code: 'quickBooks',
    displayName: 'QuickBooks Online',
    codatPlatformKey: 'qhyg',
    img: quickBooksOnlineImg,
    soon: false,
  },
  netsuite: {
    code: 'netsuite',
    displayName: 'NetSuite',
    codatPlatformKey: 'akxx',
    img: netsuiteImg,
    soon: false,
  },
  sap: {
    code: 'sap',
    displayName: 'SAP Business One',
    codatPlatformKey: 'sap',
    img: sapImg,
    soon: true,
  },
};

export function Integrations({ business }: IntegrationsProps) {
  const history = useHistory();
  const { toast } = useToast();
  const intl = useIntl();
  const [
    { connectionId, companyId, platform, platformName, errorMessage },
  ] = useQueryParams();

  const codatCompanyQuery: QueryResult = useQuery(CODAT_COMPANY_QUERY, {
    variables: { businessId: business?.id },
    skip: !business?.id,
    fetchPolicy: 'no-cache',
  });
  const [createCodatDataSource] = useMutation(CREATE_CODAT_DATA_SOURCE);

  const platformMapping = {
    quickBooks: 'quickbooksonline',
    quickBooksSandbox: 'sandbox',
    netsuite: 'netsuite',
  };

  useEffect(() => {
    codatLinkCallback();
  }, []);

  /**
   *  Used when this page is loaded after Codat Link process
   */
  const codatLinkCallback = async () => {
    if (!!errorMessage) {
      toast.error(errorMessage);
      return;
    }

    if (!!connectionId && !!platform) {
      const { data, errors } = await createCodatDataSource({
        variables: {
          businessId: business?.id,
          codatId: companyId,
          codatConnectionId: connectionId,
          platformName: platform,
        },
      });

      if (data) {
        // Refetch CodatCompany data
        codatCompanyQuery.refetch();
        toast.success(`Connection to ${platformName} successful`);
      }

      if (errors) {
        errors.map((error) => {
          toast.error(error.message);
        });
      }

      // Clean current URL from Codat params
      // (prevent a new CodatDataSource to be created on refresh)
      window.history.replaceState(
        {},
        document.title,
        window.location.href.split('?')[0]
      );
    }
  };

  /**
   * Render an Integration card
   * @param integrationName
   * @returns a <Grid/> component or null
   */
  const renderIntegrationCard = (integrationName: IntegrationsName) => {
    if (!IntegrationsData.hasOwnProperty(integrationName)) {
      return null;
    }
    const codatDataSource = codatCompanyQuery.data?.codatCompany.codatDataSources?.find(
      (dataSource: CodatDataSource) =>
        dataSource.platformName === platformMapping[integrationName]
    );
    const integrationData: IntegrationData = IntegrationsData[integrationName];
    return (
      <Grid item xs={6} lg={3}>
        <Card padding="8">
          <FlexContainer justifyContent="space-between" alignItems="center">
            <Spacer />
            <img style={{ height: '1em' }} src={integrationData.img} />
            {integrationData.soon ? (
              <Tag
                compact
                label={intl.formatMessage({
                  defaultMessage: 'Próximamente',
                  id: '5XY+bf',
                })}
              />
            ) : null}
          </FlexContainer>
          <Header as="h5">{integrationData.displayName}</Header>
          <p>
            {descriptions[integrationName] &&
              intl.formatMessage(descriptions[integrationName])}
          </p>

          {codatDataSource ? (
            <FlexContainer flexDirection="row" justifyContent="space-between">
              <Button
                startIcon={<Settings />}
                onClick={() =>
                  history.push({
                    pathname: '/settings/integrations/menu',
                    state: {
                      platformName: integrationName,
                      connectionId: codatDataSource.codatConnectionId,
                      autoSyncBillsInit: codatDataSource.autoSyncBills,
                      codatDataSourceId: codatDataSource.id,
                    },
                  })
                }
                color="primary"
              >
                {intl.formatMessage({
                  id: 'oAmSXP',
                  defaultMessage: 'Administrar conexión',
                })}
              </Button>
              {/* To be implemented later */}
              {/* <IconButton onClick={close} variant="ghosted" color="neutral">
                <RefreshCw />
              </IconButton> */}
            </FlexContainer>
          ) : (
            <ConnectButton
              intl={intl}
              business={business}
              integration={integrationData}
              codatCompanyQuery={codatCompanyQuery}
            />
          )}
        </Card>
      </Grid>
    );
  };

  return (
    <Page title={intl.formatMessage(messages.pageTitle)}>
      <Breadcrumb
        links={[
          {
            url: '/settings/home',
            name: intl.formatMessage({
              defaultMessage: 'Configuración',
              id: 'lmFbMS',
            }),
          },
        ]}
      />
      <Spacer />
      <Grid container spacing={2}>
        {/* For testing purpose:
        {renderIntegrationCard(IntegrationsName.QUICKBOOKS_SANDBOX)} */}
        {renderIntegrationCard(IntegrationsName.QUICKBOOKS)}
        {renderIntegrationCard(IntegrationsName.NETSUITE)}
        {renderIntegrationCard(IntegrationsName.SAP)}
      </Grid>
    </Page>
  );
}

export default Integrations;
