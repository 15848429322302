import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';

import Button from '@src/components/ui/Button';
import useToast from '@src/hooks/useToast';
import * as S from './CreateHistoricalComment.styles';
import useSettings from '@src/hooks/useSettings';
import { trackEvent } from '@src/lib/analytics';
import { CREATE_INVOICE_COMMENT } from '@src/constants/events';
import { Header, TextField } from '@src/components/ui';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import BUSINESS_USERS_QUERY, {
  BusinessUsersReturn,
  BusinessUsersParams,
} from '@src/graphql/queries/businessUsers';
import CREATE_INVOICE_COMMENT_MUTATION, {
  CreateInvoiceCommentReturn,
  CreateInvoiceCommentVariables,
} from '@src/graphql/mutations/createInvoiceComment';
import { HistoricalRecordAccountPayableEventsDocument } from '../HistoricalRecord/__graphql__/historicalRecordAccountPayableEvents.query';

type Option = {
  value: string;
  label: string;
};

export type CreateInvoiceCommentFormData = {
  users: Array<Option>;
  comment: string;
};

interface CreateHistoricalCommentProps {
  invoiceId: string;
  onClose?: () => void;
}

function CreateHistoricalComment({
  invoiceId,
  onClose,
}: CreateHistoricalCommentProps) {
  const { business } = useSettings();
  const [businessUserOptions, setBusinessUserOptions] = useState<Option[]>([]);
  const { toast } = useToast();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { loading } = useQuery<BusinessUsersReturn, BusinessUsersParams>(
    BUSINESS_USERS_QUERY,
    {
      variables: {
        businessId: business?.id,
        first: 100,
        status: ['ACTIVE'],
      },
      onCompleted: (data) => {
        setBusinessUserOptions(
          data.businessUsers?.edges?.map((edge) => ({
            label: `${edge.node.firstName} ${edge.node.lastName}`,
            value: edge.node.id,
          })) || []
        );
      },
    }
  );

  const [createInvoiceComment, { loading: submitting }] = useMutation<
    CreateInvoiceCommentReturn,
    CreateInvoiceCommentVariables
  >(CREATE_INVOICE_COMMENT_MUTATION, {
    refetchQueries: [HistoricalRecordAccountPayableEventsDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      trackEvent(CREATE_INVOICE_COMMENT, {
        creator: 'supplier',
        view: 'HistoricalRecord',
      });

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Se ha enviado la nota con éxito',
          id: 'Eeur05',
        }),
        intl.formatMessage({
          defaultMessage: 'Se ha enviado la nota al usuario seleccionado.',
          id: 'ZuEDbu',
        })
      );
      onClose?.();
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Ha ocurrido un error',
          id: 'DVO6hk',
        }),
        e.message
      );
    },
  });

  const onSubmit = ({ comment, users }: CreateInvoiceCommentFormData) => {
    if (business?.id && invoiceId) {
      createInvoiceComment({
        variables: {
          businessId: business.id,
          invoiceId,
          comment,
          taggedUserIds: users.map((userOption) => userOption.value),
        },
      });
    }
  };
  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Agregar nota',
          id: '91wNjx',
        })}
      </Header>
      <Spacer />
      <Controller
        control={control}
        name="users"
        rules={{
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        }}
        render={({ field }) => (
          <TextField
            fullWidth
            type="select"
            label={intl.formatMessage({
              defaultMessage: 'Para',
              id: 'elNXJ8',
            })}
            options={businessUserOptions}
            placeholder={intl.formatMessage({
              defaultMessage: 'Selecciona un usuario',
              id: 'Brum/G',
            })}
            isMulti
            error={!!errors.users?.message}
            helperText={errors.users?.message}
            isLoading={loading}
            {...field}
          />
        )}
      />
      <TextField
        fullWidth
        type="textarea"
        {...register('comment', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Nota',
          id: 'FD0Qfi',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'Escribe tu nota aquí...',
          id: 'XI0ykt',
        })}
        error={!!errors.comment?.message}
        helperText={errors.comment?.message}
      />
      <FlexContainer justifyContent="flex-end">
        <Button variant="ghosted" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button type="submit" loading={submitting}>
          {intl.formatMessage({
            defaultMessage: 'Enviar',
            id: 'hKJZJR',
          })}
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

CreateHistoricalComment.displayName = 'CreateHistoricalComment';

export default CreateHistoricalComment;
