import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './PageTitle.styles';
import Breadcrumb from '@src/components/shared/Breadcrumb';
import Header from '@src/components/ui/Header';
import GoBackButton from '../GoBackButton';

interface PageTitleProps {
  className?: string;
  children: ReactNode;
  goBackLink?: boolean;
  backLinks?: Array<{ name: ReactNode; url: string }>;
  actions?: {
    render: (props?: any) => ReactNode;
  };
  actionProps?: any;
}

export function PageTitle(props: PageTitleProps) {
  const {
    className,
    children,
    actions,
    goBackLink = false,
    actionProps,
    backLinks = [],
  } = props;
  const history = useHistory();
  return (
    <S.PageTitle className={className}>
      {goBackLink && <GoBackButton />}
      {backLinks.length > 0 && (
        <S.Breadcrumb>
          <Breadcrumb links={backLinks} />
        </S.Breadcrumb>
      )}
      <S.Container>
        <Header as="h4">{children}</Header>
        <div className="d-print-none">
          {actions && actions.render(actionProps)}
        </div>
      </S.Container>
    </S.PageTitle>
  );
}

export default PageTitle;
