import Loadable from '@src/components/shared/Loadable';

const NotFoundLoader = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "404" */
      './Error404'
    ),
});

export default NotFoundLoader;
