import React from 'react';
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormStateReturn,
} from 'react-hook-form';
import BigNumber from 'bignumber.js';
import { useIntl } from 'react-intl';
import { addDays, format, formatISO } from 'date-fns';

import useModal from '@src/hooks/useModal';
import { DEFAULT_CURRENCY } from '@src/components/i18n';
import { Info, Plus } from '@src/components/ui/Icon/outline';
import Button from '@src/components/ui/Button';
import Tooltip from '@src/components/ui/Tooltip';
import Currency from '@src/components/i18n/Currency';
import PaymentStatusBadge from '@src/components/shared/PaymentStatusBadge';
import CostCentersSelect from '@src/components/shared/select/CostCentersSelect';
import Link from '@src/components/shared/Link';
import {
  DatePicker,
  FormControl,
  Label,
  Text,
  TextField,
} from '@src/components/ui';
import MultiEmailSelect from '@src/components/shared/select/MultiEmailSelect';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import CreateCostCenterForm from '@src/components/pages/InvoiceView/components/CreateCostCenterForm';
import ApplyCreditNote from '@src/components/pages/InvoiceView/components/ApplyCreditNote';
import InvoicePayments from '../InvoicePayments';
import { getSupplierContacts } from '@src/utils/supplierContacts';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';

import { InvoiceApprovalStatus } from '@src/types/enums';
import { CostCenter, Invoice } from '@src/types/models';

interface FormData {
  providerContacts: string[];
  paymentTerms: number;
  expiryDate: string;
  costCenter?: CostCenter;
  exchangeRate: number;
}

interface EditableInvoiceInformationProps {
  invoice: Pick<
    Invoice,
    | 'businessRelationship'
    | 'businessEmitter'
    | 'outstandingBalance'
    | 'currency'
    | 'invoicingDate'
    | 'approvalStatus'
    | 'paymentStatus'
    | 'id'
    | 'foreignTotal'
    | 'invoiceables'
  >;
  disabled?: boolean;
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  setValue: UseFormSetValue<FormData>;
  formState: UseFormStateReturn<FormData>;
}

function EditableInvoiceInformation({
  invoice,
  disabled,
  register,
  control,
  setValue,
  formState,
}: EditableInvoiceInformationProps) {
  const intl = useIntl();
  const { open: openCreateCreateCostCenter } = useModal(CreateCostCenterForm);
  const { open: openApplyCreditNoteModal } = useModal(ApplyCreditNote);
  const { open: openInvoicePayments } = useModal(InvoicePayments);
  const providerContacts = getSupplierContacts(
    invoice?.businessRelationship?.providerContacts
  );
  const handleApplyCreditNote = () => {
    if (invoice.businessEmitter?.hashId) {
      openApplyCreditNoteModal({
        supplierHashId: invoice.businessEmitter?.hashId,
        invoice: {
          id: invoice.id,
          outstandingBalance: invoice.outstandingBalance,
          currency: invoice.currency,
        },
      });
    }
  };

  const handlePaymentView = () =>
    openInvoicePayments({
      invoice: invoice as any,
    });

  return (
    <div>
      <Label strong size="lg">
        {intl.formatMessage({
          id: 'r8VS2J',
          defaultMessage: 'Información de factura',
        })}
      </Label>
      <Spacer />
      <TextField
        type="number"
        {...register('paymentTerms', {
          required: intl.formatMessage({
            id: '7Vvfe3',
            defaultMessage: 'Campo requerido',
          }),
          onChange: (event) => {
            setValue(
              `expiryDate`,
              formatISO(
                addDays(new Date(invoice.invoicingDate), event.target.value)
              )
            );
          },
        })}
        required
        disabled={disabled}
        fullWidth
        label={intl.formatMessage({
          id: 'k44iuX',
          defaultMessage: 'Dias de crédito',
        })}
        placeholder="30"
        autoFocus
        error={!!formState.errors?.paymentTerms?.message}
        helperText={formState.errors?.paymentTerms?.message}
      />

      <Controller
        control={control}
        rules={{
          required: intl.formatMessage({
            id: '7Vvfe3',
            defaultMessage: 'Campo requerido',
          }),
        }}
        name="expiryDate"
        render={({ field }) => (
          <FormControl
            fullWidth
            required
            label={intl.formatMessage({
              id: 'XF6Ipl',
              defaultMessage: 'Fecha de vencimiento',
            })}
            error={!!formState.errors?.expiryDate?.message}
            helperText={formState.errors?.expiryDate?.message}
          >
            <DatePicker
              fullWidth
              minDate={
                invoice.invoicingDate
                  ? formatISO(new Date(invoice.invoicingDate))
                  : formatISO(new Date())
              }
              placeholder={format(new Date(), 'dd/MM/yyyy')}
              onClear={() => {
                setValue(`expiryDate`, '');
              }}
              disabled={disabled}
              error={!!formState.errors?.expiryDate?.message}
              {...field}
            />
          </FormControl>
        )}
      />

      <Controller
        name="costCenter"
        control={control}
        render={({ field }) => (
          <FormControl
            fullWidth
            label={intl.formatMessage({
              id: 'fWNdF2',
              defaultMessage: 'Centro de costos',
            })}
            error={!!formState.errors?.paymentTerms?.message}
            helperText={formState.errors?.paymentTerms?.message}
          >
            <CostCentersSelect disabled={disabled} {...field} />
          </FormControl>
        )}
      />
      {!disabled && (
        <Button
          compact
          disabled={disabled}
          variant="ghosted"
          startIcon={<Plus />}
          onClick={() => openCreateCreateCostCenter({})}
        >
          {intl.formatMessage({
            id: 'RGUeJL',
            defaultMessage: 'Agregar centro de costo',
          })}
        </Button>
      )}
      <Spacer />
      {invoice.approvalStatus === InvoiceApprovalStatus.APPROVED && (
        <>
          <div>
            <Label strong>
              {intl.formatMessage({
                id: 'UI+WgZ',
                defaultMessage: 'Estado de pago',
              })}
            </Label>
            <FlexContainer justifyContent="space-between" alignItems="center">
              <PaymentStatusBadge
                id={invoice.id}
                status={invoice.paymentStatus}
              />

              <Button compact variant="transparent" onClick={handlePaymentView}>
                {intl.formatMessage({
                  id: 'UAE9F7',
                  defaultMessage: 'Ver',
                })}
              </Button>
            </FlexContainer>
          </div>
          <Spacer />
          <div>
            <Label strong>
              {intl.formatMessage({
                id: 'h0TyH1',
                defaultMessage: 'Saldo pendiente',
              })}
            </Label>
            <Text>
              <Currency
                value={invoice.outstandingBalance}
                currency={
                  invoice.foreignTotal ? DEFAULT_CURRENCY : invoice.currency
                }
              />
            </Text>
          </div>
          <Spacer />
          <div>
            <Label strong>
              {intl.formatMessage({
                id: 'h/f4b+',
                defaultMessage: 'Crédito disponible',
              })}
            </Label>
            <FlexContainer justifyContent="space-between" alignItems="center">
              <Text>
                <Currency
                  value={
                    invoice.businessRelationship?.pendingTotalCredit
                      ? new BigNumber(
                          invoice.businessRelationship.pendingTotalCredit
                        ).toNumber()
                      : 0
                  }
                  currency={
                    invoice.foreignTotal ? DEFAULT_CURRENCY : invoice.currency
                  }
                />
              </Text>

              <Button
                compact
                variant="transparent"
                onClick={handleApplyCreditNote}
              >
                {intl.formatMessage({
                  id: '4aS11Q',
                  defaultMessage: 'Aplicar',
                })}
              </Button>
            </FlexContainer>
          </div>
          <Spacer margin="8" />
        </>
      )}
      {invoice.currency !== DEFAULT_CURRENCY && (
        <div>
          <FlexContainer alignItems="center">
            <Label strong size="lg">
              {intl.formatMessage({
                id: 'CcRVOT',
                defaultMessage: 'Tipo de cambio',
              })}
            </Label>
            <Spacer direction="horizontal" margin="2" />
            <Tooltip
              id={`currency-${invoice.id}`}
              title={intl.formatMessage({
                id: 'Faeazt',
                defaultMessage:
                  'Ingresa la relación de valor en pesos mexicanos ($MXN) para esta factura.',
              })}
              place="top"
              effect="solid"
            >
              <Info size={18} />
            </Tooltip>
          </FlexContainer>
          <Spacer />
          <TextField
            type="currency"
            currency="MXN"
            decimalLimit={4}
            {...register('exchangeRate', {
              validate: {
                positive: (value: number) =>
                  value > 0 ||
                  intl.formatMessage({
                    id: 'htbxyi',
                    defaultMessage: 'Debe ser mayor a $0',
                  }),
              },
              setValueAs: (value: string) => parseCurrency(value),
              required: intl.formatMessage({
                id: '7Vvfe3',
                defaultMessage: 'Campo requerido',
              }),
            })}
            required
            error={!!formState.errors?.exchangeRate?.message}
            helperText={formState.errors?.exchangeRate?.message}
            label={intl.formatMessage({
              id: 'CcRVOT',
              defaultMessage: 'Tipo de cambio',
            })}
            placeholder={intl.formatMessage({
              id: '4zuaVn',
              defaultMessage: 'MXN $20.00',
            })}
            disabled={disabled}
          />
        </div>
      )}
      <Spacer />
      {invoice.businessEmitter && (
        <div>
          <Label strong size="lg">
            {intl.formatMessage({
              id: 'gz/voC',
              defaultMessage: 'Información del proveedor',
            })}
          </Label>
          <Spacer />
          <div>
            <Label strong>
              {intl.formatMessage({
                id: 'hCOqfl',
                defaultMessage: 'Nombre',
              })}
            </Label>
            {invoice?.businessEmitter?.name && (
              <Link to={`/suppliers/${invoice.businessEmitter.hashId}`}>
                {invoice?.businessEmitter?.name}
              </Link>
            )}
          </div>
          <Spacer />
          <div>
            <Controller
              control={control}
              name="providerContacts"
              render={({ field, fieldState: { error } }) => (
                <MultiEmailSelect
                  options={providerContacts}
                  isDisabled={disabled}
                  fullWidth
                  label={intl.formatMessage({
                    id: 'sy+pv5',
                    defaultMessage: 'Email',
                  })}
                  error={!!error?.message}
                  helperText={error?.message}
                  {...field}
                />
              )}
            />
          </div>
        </div>
      )}

      {invoice.invoiceables.length > 0 && (
        <div>
          <Label strong>
            {intl.formatMessage({
              id: 'Npq2EO',
              defaultMessage: 'Orden de compra',
            })}
          </Label>
          <div>
            {invoice.invoiceables.map((invoiceable, index) =>
              invoiceable.__typename === 'Order' ? (
                <div key={index}>
                  <Link to={`/orders/${invoiceable.hashId}`}>
                    {invoiceable.hashId}
                  </Link>
                  {index + 1 !== invoice.invoiceables.length ? ',' : ''}
                </div>
              ) : null
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default EditableInvoiceInformation;
