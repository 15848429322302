import { gql } from '@apollo/client';
import { SupplierRelationship } from '@src/types/models';

const SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES = gql`
  fragment SupplierBusinessRelationshipAttributes on BusinessRelationship {
    id
    active
    bankingInfo {
      recipient
      bankName
      bankId
      clabe
      id
    }
    status
    lastOrderDate
    providerId
    providerName
    providerContact {
      id
      email
    }
    last30DaysOrdersTotals {
      value
      currency
    }
    priceList {
      id
      totalItems
    }
    supplier {
      id
      name
      hashId
      defaultTaxPayerInfo {
        taxpayerIdentifier
      }
    }
    providerContacts {
      id
      defaultContact
      contactable {
        email
        firstName
        lastName
        id
      }
    }
    deliveryOnSaturday
    deliveryOnSunday
    shippingCostType
    shippingCostValue
    shippingCostCurrency
    deliveryLeadTimeInDays
    paymentTerms
    providerImageUrl
    providerImageShortName
    providerImageColorCode
    providerHashId
    pendingTotal
    pendingExpiredTotal
  }
`;

export type SupplierBusinessRelationshipAttributes = Pick<
  SupplierRelationship,
  | 'id'
  | 'active'
  | 'bankingInfo'
  | 'status'
  | 'lastOrderDate'
  | 'providerId'
  | 'providerName'
  | 'providerContact'
  | 'last30DaysOrdersTotals'
  | 'priceList'
  | 'supplier'
  | 'providerContacts'
  | 'deliveryOnSaturday'
  | 'deliveryOnSunday'
  | 'shippingCostType'
  | 'shippingCostValue'
  | 'shippingCostCurrency'
  | 'deliveryLeadTimeInDays'
  | 'paymentTerms'
  | 'providerImageUrl'
  | 'providerImageShortName'
  | 'providerImageColorCode'
  | 'providerHashId'
  | 'pendingTotal'
  | 'pendingExpiredTotal'
>;

export default SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES;
