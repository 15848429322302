import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { hasFeature } from '@src/utils/features';

import { FeatureName } from '@src/types/enums';
import useSettings from '@src/hooks/useSettings';

type FeatureProps = {
  children: JSX.Element;
  redirect?: boolean;
  features: Array<keyof typeof FeatureName>;
};

function Feature({ children, features, redirect = false }: FeatureProps) {
  const history = useHistory();
  const { currentUser } = useSettings();
  const shouldRender = hasFeature(features, currentUser);

  if (currentUser && !shouldRender && redirect) {
    history.replace('/p/403');
  }

  return shouldRender ? children : null;
}

export default Feature;
