import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';

import { Header, Button, Text, Label } from '@src/components/ui';
import { FlexContainer, Grid, Spacer } from '@src/components/shared/layouts';
import { Currency } from '@src/components/i18n/Currency';
import PaymentStatusBadge from '@src/components/shared/PaymentStatusBadge/PaymentStatusBadge';
import INVOICE_PAYMENTS_QUERY, {
  InvoicePaymentsReturn,
  InvoicePaymentsVariables,
} from '@src/graphql/queries/invoicePayments';
import useSettings from '@src/hooks/useSettings';
import useModal from '@src/hooks/useModal';
import MarkAsPaid from '../MarkAsPaid';
import EnterManualPayment from '../EnterManualPayment';
import PaymentsTable from '../PaymentsTable/PaymentsTable';
import UPDATE_INVOICES_PAYMENTS_MUTATION, {
  UpdateInvoicePaymentsReturn,
  UpdateInvoicePaymentsVariables,
} from '@src/graphql/mutations/updateInvoicePayment';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import {
  INVOICE_MANUALLY_PAID,
  UPDATE_INVOICE_PAYMENTS,
} from '@src/constants/events';

import { Invoice } from '@src/types/models';
import { HistoricalRecordAccountPayableEventsDocument } from '../HistoricalRecord/__graphql__/historicalRecordAccountPayableEvents.query';

interface InvoicePaymentsProps {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'businessReceiver'
    | 'businessEmitter'
    | 'businessRelationship'
    | 'paymentStatus'
    | 'total'
    | 'outstandingBalance'
    | 'currency'
  >;
  onClose?: () => void;
}

function InvoicePayments({ invoice, onClose }: InvoicePaymentsProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const { open: openMarkAsPaid } = useModal(MarkAsPaid, 50);
  const { open: openManualPayment } = useModal(EnterManualPayment, 50);

  const { data: payments, loading } = useQuery<
    InvoicePaymentsReturn,
    InvoicePaymentsVariables
  >(INVOICE_PAYMENTS_QUERY, {
    variables: {
      invoiceId: invoice?.id,
      businessId: business?.id,
    },
    fetchPolicy: 'network-only',
    skip: !business?.id || !invoice?.id,
  });
  const { toast } = useToast();
  const [updateInvoicePayments] = useMutation<
    UpdateInvoicePaymentsReturn,
    UpdateInvoicePaymentsVariables
  >(UPDATE_INVOICES_PAYMENTS_MUTATION, {
    refetchQueries: [
      HistoricalRecordAccountPayableEventsDocument,
      INVOICE_QUERY,
      INVOICE_PAYMENTS_QUERY,
      SUPPLIER_RELATIONSHIP_QUERY_NAME,
    ],
    onCompleted: () => {
      trackEvent(UPDATE_INVOICE_PAYMENTS, {
        creator: 'emitter',
        view: 'InvoiceView',
      });
      trackEvent(INVOICE_MANUALLY_PAID);
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Factura actualizada',
          id: 'FZAoOc',
        }),
        intl.formatMessage(
          {
            defaultMessage:
              'El estatus de pago de la factura {invoiceId} ha sido actualizado.',
            id: 'mkY+va',
          },
          {
            invoiceId: invoice.id,
          }
        )
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar el estado de la factura',
          id: 'VcpAgx',
        }),
        e.message
      );
    },
  });

  const handleManualPaymentClick = () => {
    onClose?.();
    openManualPayment({
      invoice,
    });
  };
  const handleMarkAsPaidClick = () => {
    onClose?.();
    openMarkAsPaid({
      invoice,
    });
  };

  const handleDelete = (
    invoicePayments: Array<{ id: string; _destroy: boolean }>
  ) => {
    if (business?.id && invoice.id) {
      updateInvoicePayments({
        variables: {
          invoiceId: invoice.id,
          businessId: business?.id,
          invoicePayments,
        },
      });
    }
  };

  return (
    <div>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Adjuntar pago',
          id: '+CUxIV',
        })}
      </Header>
      <Spacer />
      <Text colorStep="600">
        {intl.formatMessage({
          defaultMessage:
            'Adjunta los pagos que haces a tus facturas y lleva un control de tu contabilidad.',
          id: 'xwQBPY',
        })}
      </Text>
      <Spacer />
      <Grid container>
        <Grid item md={6}>
          <Label>
            {intl.formatMessage({
              defaultMessage: 'Saldo pendiente',
              id: 'h0TyH1',
            })}
          </Label>
          <Text>
            <Currency value={invoice.outstandingBalance} />
          </Text>
        </Grid>
        <Grid item md={6}>
          <Label>
            {intl.formatMessage({
              defaultMessage: 'Estado del pago',
              id: 'uohz7v',
            })}
          </Label>
          <PaymentStatusBadge id={invoice.id} status={invoice.paymentStatus} />
        </Grid>
      </Grid>
      <Spacer />
      <PaymentsTable
        payments={payments?.invoicePayments}
        currency={invoice.currency}
        onDelete={handleDelete}
        isPaymentReceiver={
          !!invoice && business?.id == invoice?.businessEmitter?.id
        }
      />
      <Spacer margin="8" />
      <FlexContainer justifyContent="flex-end">
        <Button
          variant="ghosted"
          disabled={invoice.outstandingBalance === 0}
          onClick={handleManualPaymentClick}
        >
          {intl.formatMessage({
            defaultMessage: 'Registrar pago parcial',
            id: 'yjlWwQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button
          disabled={invoice.outstandingBalance === 0}
          onClick={handleMarkAsPaidClick}
        >
          {intl.formatMessage({
            defaultMessage: 'Marcar como pagada',
            id: 'CnH3hH',
          })}
        </Button>
      </FlexContainer>
    </div>
  );
}

InvoicePayments.displayName = 'InvoicePayments';

export default InvoicePayments;
