import React from 'react';
import { FormattedMessage } from 'react-intl';
import BigNumber from 'bignumber.js';

import Currency from '@src/components/i18n/Currency';
import * as S from './SuppliersList.styles';
import Skeleton from '@src/components/ui/Skeleton';
import { SupplierRelationship } from '@src/types/models';
import FeatureSupplierButton from '@src/components/shared/buttons/FeatureSupplierButton/FeatureSupplierButton';

interface SupplierProps {
  businessId?: string;
  datum?: SupplierRelationship;
  loading?: boolean;
  onDelete?: () => void;
  onCreate?: () => void;
  onClick?: (supplier: SupplierRelationship) => void;
}

export function Supplier(props: SupplierProps) {
  const { businessId, datum, loading, onDelete, onCreate, onClick } = props;

  const handleSupplierClick = () => {
    if (onClick && datum) {
      onClick(datum);
    }
  };

  if (loading) {
    return (
      <S.SupplierContainer loading>
        <Skeleton fullHeight />
      </S.SupplierContainer>
    );
  }

  return datum ? (
    <S.SupplierContainer onClick={handleSupplierClick} clickable={!!onClick}>
      <S.SupplierContent>
        <S.SupplierAvatar
          squared
          size="lg"
          src={datum.providerImageUrl}
          backgroundColor={`#${datum.providerImageColorCode}`}
          initials={datum.providerImageShortName}
        />

        <S.SupplierInfo>
          <S.SupplierName>{datum.providerName}</S.SupplierName>
          <S.SupplierEmail>
            {datum?.supplier?.defaultTaxPayerInfo?.taxpayerIdentifier}
          </S.SupplierEmail>
        </S.SupplierInfo>

        <S.SupplierCredit>
          <S.InformationLabel>
            <FormattedMessage
              defaultMessage="Saldo total pendiente"
              id="LaFO3F"
            />
          </S.InformationLabel>

          <S.PendingValue>
            <Currency value={new BigNumber(datum.pendingTotal).toNumber()} />
          </S.PendingValue>
        </S.SupplierCredit>

        <S.SupplierCredit>
          <S.InformationLabel>
            <FormattedMessage defaultMessage="Saldo vencido" id="nw3RnX" />
          </S.InformationLabel>

          <S.PendingValue expiredTotal>
            <Currency
              value={new BigNumber(datum.pendingExpiredTotal).toNumber()}
            />
          </S.PendingValue>
        </S.SupplierCredit>

        <S.Actionable>
          <FeatureSupplierButton
            businessId={businessId}
            active={datum.active}
            businessRelationshipId={datum.id}
            onDeactivate={onDelete}
            onActivate={onCreate}
          />
        </S.Actionable>
      </S.SupplierContent>
    </S.SupplierContainer>
  ) : null;
}

export default Supplier;
