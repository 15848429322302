import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';

import useSettings from '@src/hooks/useSettings';
import Button from '@src/components/ui/Button';
import InvoicesTable, {
  InvoiceActionDropdown,
} from '@src/components/shared/InvoicesTable';
import UpdateTermsModal from '../UpdateTermsModal';
import attributes from '../attributes';

import { InvoiceListAttributes } from '@src/graphql/fragments';
import useModal from '@src/hooks/useModal';

interface PendingInvoicesTableProps {
  invoices: InvoiceListAttributes[];
  nodes: InvoiceListAttributes[];
  loading?: boolean;
  limit?: number;
  page?: number;
  count?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  totalAmount?: number;
  emptyElement?: React.ReactNode;
  next?: () => void;
  previous?: () => void;
}

export function PendingInvoicesTable(props: PendingInvoicesTableProps) {
  const {
    emptyElement,
    invoices,
    nodes,
    loading,
    limit,
    page,
    count,
    next,
    previous,
    hasNext,
    hasPrevious,
    totalAmount,
  } = props;
  const intl = useIntl();
  const { business, currentUser } = useSettings();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { open: openTerms } = useModal(UpdateTermsModal);

  const handleNextClick = () => {
    openTerms({
      selectedIds,
      invoices: nodes,
      onSuccess: () => setSelectedIds([]),
    });
  };

  const clearSelection = () => {
    setSelectedIds([]);
  };

  return (
    <>
      <InvoicesTable
        columns={{
          id: 100,
          receiverCountryTaxPayerInfo: 100,
          invoicingDate: 200,
          businessEmitter: 300,
          total: 150,
        }}
        customAttributes={attributes}
        invoices={invoices}
        loading={loading}
        limit={limit}
        showActionBar
        nodes={nodes}
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        page={page}
        emptyElement={emptyElement}
        actions={
          <>
            <InvoiceActionDropdown
              invoiceIds={selectedIds}
              count={count}
              businessId={business?.id}
              userEmail={currentUser?.email}
              payable
              archivable
              onArchive={clearSelection}
              onMarkAsPaid={clearSelection}
            />
            <Button onClick={handleNextClick}>
              {intl.formatMessage({
                defaultMessage: 'Agregar a cuentas por pagar',
                id: 'TO7qak',
              })}
            </Button>
          </>
        }
        count={count}
        onNext={next}
        hasNext={hasNext}
        onPrevious={previous}
        hasPrevious={hasPrevious}
        selectable
        totalAmount={totalAmount}
      />
    </>
  );
}

export default memo(PendingInvoicesTable);
