import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';

import errorLink from './errorLink';
import { PUBLIC_API_URL, GRAPHQL_NAMESPACE } from '../api/utils';

const httpLink = new HttpLink({
  uri: PUBLIC_API_URL + GRAPHQL_NAMESPACE,
});

const publicClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache({ possibleTypes: {} }),
});

export default publicClient;
