import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import RecordNotification from './components/RecordNotification/RecordNotification';
import * as S from './HistoricalRecord.styles';
import { Spacer } from '@src/components/shared/layouts';
import { Alert, Button } from '@src/components/ui';
import Loader from '@src/components/shared/Loader';
import { Edit3 as Edit } from '@src/components/ui/Icon/outline';
import CreateHistoricalComment from '../CreateHistoricalComment';
import useModal from '@src/hooks/useModal';
import { useQuery } from '@apollo/client';
import useSettings from '@src/hooks/useSettings';
import { HistoricalRecordAccountPayableEventFragment } from './__graphql__/historicalRecordAccountPayableEvent.fragment';
import { HistoricalRecordAccountPayableEventsDocument } from './__graphql__/historicalRecordAccountPayableEvents.query';

type HistoricalRecordProps = {
  invoiceId: string;
};

export function HistoricalRecord({ invoiceId }: HistoricalRecordProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const [accountPayableEvents, setAccountPayableEvents] = useState<
    HistoricalRecordAccountPayableEventFragment[]
  >([]);
  const { open: openCreateComment } = useModal(CreateHistoricalComment);
  const { loading, error } = useQuery(
    HistoricalRecordAccountPayableEventsDocument,
    {
      variables: {
        businessId: business?.id,
        invoiceId,
      },
      skip: !business?.id || !invoiceId,
      onCompleted: (data) => {
        setAccountPayableEvents(data.accountPayableEvents);
      },
    }
  );

  const openCreateHistoricalCommentModal = () => {
    openCreateComment({
      invoiceId,
    });
  };

  if (loading) {
    return (
      <S.Container>
        <Loader fullWidth />
      </S.Container>
    );
  }

  if (error) {
    return (
      <S.Container>
        <Alert color="danger" hasBorder>
          {intl.formatMessage({
            id: 'yM0cd/',
            defaultMessage: 'Error al cargar los datos',
          })}{' '}
          {error.message}
        </Alert>
      </S.Container>
    );
  }

  if (!accountPayableEvents) {
    return null;
  }

  return (
    <S.Container>
      {accountPayableEvents.map((event) => (
        <RecordNotification
          key={event.id}
          user={event.user}
          eventable={event.eventable}
          createdAt={event.createdAt}
          invoice={event.invoice}
        />
      ))}
      <Spacer />
      <Button
        onClick={openCreateHistoricalCommentModal}
        variant="ghosted"
        startIcon={<Edit />}
      >
        {intl.formatMessage({
          id: '91wNjx',
          defaultMessage: 'Agregar nota',
        })}
      </Button>
    </S.Container>
  );
}

export default HistoricalRecord;
