import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import messages from './messages';
import {
  STATUS_OPTIONS,
  STATUS_TRANSACTION_FAILED,
  STATUS_TRANSACTION_PROCESSED,
  STATUS_TRANSACTION_PROCESSING,
} from '@src/constants/transactions';

import { WalletEntryStatus } from '@src/types/enums';
import Tag from '../../ui/Tag';

type TransactionStatusBadgeProps = {
  status: keyof typeof WalletEntryStatus;
  claimed?: boolean;
};

export function optionsForStatus(
  intl?: IntlShape
): Array<{ text: string; value: string }> {
  if (!intl || !intl.formatMessage) {
    return STATUS_OPTIONS;
  }

  return STATUS_OPTIONS.map((opt) => ({
    text: (intl && intl.formatMessage(messages[opt.value])) || opt.text,
    value: opt.value,
  }));
}

export function TransactionStatusBadge(props: TransactionStatusBadgeProps) {
  const { status, claimed = true } = props;
  const intl = useIntl();

  let color: 'success' | 'light' | 'warning' | 'danger';

  switch (status) {
    case STATUS_TRANSACTION_PROCESSED:
      color = 'success';
      break;
    case STATUS_TRANSACTION_PROCESSING:
      color = 'warning';
      break;
    case STATUS_TRANSACTION_FAILED:
      color = 'danger';
      break;
    default:
      color = 'light';
      break;
  }

  if (!status) {
    return null;
  }

  if (!claimed) {
    return (
      <Tag
        compact
        label={intl.formatMessage(messages.unclaimed)}
        color={'info'}
      />
    );
  }

  return (
    <Tag compact label={intl.formatMessage(messages[status])} color={color} />
  );
}

export default TransactionStatusBadge;
