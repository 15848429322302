import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { SatStatus } from '@src/types/enums';
import useSatBot from '@src/hooks/useSatBot';
import { Alert } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';

export function SatBotAlert() {
  const { formatMessage } = useIntl();
  const { satSyncStatus } = useSatBot();
  const [activeAlert, setActiveAlert] = useState(false);

  useEffect(() => {
    if (
      satSyncStatus !== SatStatus.SYNCED &&
      satSyncStatus !== SatStatus.NOT_SYNCED &&
      satSyncStatus !== SatStatus.SYNCING_HISTORICAL
    ) {
      setActiveAlert(true);
    } else {
      setActiveAlert(false);
    }
  }, [satSyncStatus]);

  if (!satSyncStatus || !activeAlert) {
    return null;
  }

  let color: 'warning' | 'danger' = 'warning';
  let message = formatMessage({
    defaultMessage:
      'Iniciando sincronización... Este proceso puede tardar hasta 15 minutos. Puedes seguir usando normalmente Higo mientras el proceso termina.',
    id: '6w4uW2',
  });

  switch (satSyncStatus) {
    case SatStatus.WRONG_CREDENTIALS:
      color = 'danger';
      message = formatMessage({
        defaultMessage: 'Contraseña SAT incorrecta.',
        id: 'JCa0V6',
      });
      break;
    case SatStatus.SAT_LOADING_ERROR:
      color = 'danger';
      message = formatMessage({
        defaultMessage:
          'Hemos detectado un error con la sincronización con el SAT. Intenta más tarde.',
        id: 'B5/hA+',
      });
      break;
    case SatStatus.VALIDATING_CREDENTIALS:
      message = formatMessage({
        defaultMessage: 'Validando contraseña...',
        id: 'YCFNxP',
      });
      break;
    default:
      break;
  }

  return (
    <>
      <Alert hasBorder onClose={() => setActiveAlert(false)} color={color}>
        {message}
      </Alert>
      <Spacer margin="4" />
    </>
  );
}

export default SatBotAlert;
