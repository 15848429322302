import React, { useEffect, useState, memo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import {
  ACTIVATE_BUSINESS_RELATIONSHIPS_MUTATION,
  DELETE_BUSINESS_RELATIONSHIP_MUTATION,
} from '@src/graphql/mutations';
import { PROVIDER_RELATIONSHIPS_QUERY_NAME } from '@src/graphql/queries/providerRelationships';
import { INVOICES_RECEIVED_QUERY_NAME } from '@src/graphql/queries/invoicesReceived';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import {
  SUPPLIER_ACTIVATED,
  SUPPLIER_DEACTIVATED,
} from '@src/constants/events';
import StarButton from '../StarButton';

interface FeatureSupplierButtonProps {
  businessId?: string;
  businessRelationshipId?: string;
  active?: boolean;
  onActivate?: () => void;
  onDeactivate?: () => void;
}

function FeatureSupplierButton({
  businessRelationshipId,
  active,
  onActivate,
  onDeactivate,
}: FeatureSupplierButtonProps) {
  const intl = useIntl();
  const { toast } = useToast();
  const business = useSettings('business');
  const [featured, setFeatured] = useState(active);
  const location = useLocation();
  const [
    activateBusinessRelationships,
    { loading: activateLoading, error: activateError },
  ] = useMutation(ACTIVATE_BUSINESS_RELATIONSHIPS_MUTATION, {
    refetchQueries: [
      PROVIDER_RELATIONSHIPS_QUERY_NAME,
      INVOICES_RECEIVED_QUERY_NAME,
    ],
    onCompleted() {
      if (onActivate) onActivate();
    },
  });
  const [
    deactivateBusinessRelationships,
    { loading: deactivateLoading, error: deactivateError },
  ] = useMutation(DELETE_BUSINESS_RELATIONSHIP_MUTATION, {
    refetchQueries: [
      PROVIDER_RELATIONSHIPS_QUERY_NAME,
      INVOICES_RECEIVED_QUERY_NAME,
    ],
    onCompleted() {
      if (onDeactivate) onDeactivate();
    },
  });

  useEffect(() => {
    setFeatured(active);
  }, [active]);

  const genericError = intl.formatMessage({
    defaultMessage: 'Hubo un error.',
    id: 'M0y+A/',
  });

  if (!activateLoading && activateError) {
    toast.error(genericError, activateError.message);
  }

  if (!deactivateLoading && deactivateError) {
    toast.error(genericError, deactivateError.message);
  }

  const handleActivate = (event) => {
    event.stopPropagation();
    if (business.id) {
      try {
        setFeatured(true);
        activateBusinessRelationships({
          variables: {
            businessId: business.id,
            businessRelationships: [
              {
                id: businessRelationshipId,
              },
            ],
          },
        });
        trackEvent(SUPPLIER_ACTIVATED, {
          page: location.pathname,
        });
      } catch (e) {
        setFeatured(false);
        toast.error(genericError, e.message);
      }
    }
  };
  const handleDeactivate = (event) => {
    event.stopPropagation();
    if (business.id) {
      try {
        deactivateBusinessRelationships({
          variables: {
            businessId: business.id,
            businessRelationshipId,
          },
        });
        setFeatured(false);
        trackEvent(SUPPLIER_DEACTIVATED, {
          page: location.pathname,
        });
      } catch (e) {
        setFeatured(true);
        toast.error(genericError, e.message);
      }
    }
  };

  return (
    <StarButton
      active={featured}
      onActivate={handleActivate}
      onDeactivate={handleDeactivate}
      TooltipProps={{
        id: 'feature-supplier-tooltip',
        title: intl.formatMessage({
          defaultMessage: 'Destacar proveedor',
          id: 'ci8sKA',
        }),
      }}
    />
  );
}

export default memo(FeatureSupplierButton);
