import React from 'react';

import UsersEdit from './UsersEdit';
import Permission from '@src/components/shared/Permission';
import RemoveBusinessUserForm from '@src/components/shared/form/RemoveBusinessUserForm';
import Modal from '@src/components/shared/Modal';
import Page from '@src/components/shared/Page';

import { UserRole } from '@src/types/enums';

function UsersEditContainer() {
  return (
    <Permission minimumRoles={[UserRole.ADMIN]} redirect>
      <Page>
        <UsersEdit />
        <Modal component={RemoveBusinessUserForm} />
      </Page>
    </Permission>
  );
}

export default UsersEditContainer;
