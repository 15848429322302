import styled from 'styled-components';

import { Card } from '@src/components/ui';
import { FlexRowContainer } from '@src/components/shared/layouts';
import { Label } from '@src/components/ui';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing[7]};
`;

export const InnerCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing[7]};
`;

export const AmountContainer = styled(FlexRowContainer)`
  position: relative;
`;

export const ResultLabel = styled(Label)`
  position: absolute;
  right: ${({ theme }) => theme.spacing[12]};
  font-weight: 500 !important;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 0;
`;
