import styled, { css } from 'styled-components';
import { CardProps } from './types';

export const cardStyles = css<CardProps>`
  background-color: ${({ backgroundColor, theme, backgroundColorStep }) =>
    backgroundColor
      ? theme.colors[backgroundColor][backgroundColorStep || 500]
      : theme.colors.neutral[50]};
  box-shadow: ${({ theme, depth }) =>
    depth ? theme.boxShadow[depth] : 'none'};
  border-radius: ${({ theme }) => theme.spacing[4]};
  border: ${({ theme, borderColor, borderColorStep }) =>
    borderColor
      ? `1px solid ${theme.colors[borderColor][borderColorStep || 500]}`
      : 'none'};
  min-height: ${({ theme }) => theme.spacing[10]};
  position: relative;
  padding: 0;
  cursor: ${({ onClick }) => onClick && 'pointer'};
`;

export const Surface = styled.div<CardProps>`
  ${cardStyles}

  ${({ padding, theme }) =>
    padding &&
    css`
      padding: ${theme.spacing[padding]};
    `};

  ${({ verticalPadding, theme }) =>
    verticalPadding &&
    css`
      padding-top: ${theme.spacing[verticalPadding]};
      padding-bottom: ${theme.spacing[verticalPadding]};
    `};

  ${({ horizontalPadding, theme }) =>
    horizontalPadding &&
    css`
      padding-left: ${theme.spacing[horizontalPadding]};
      padding-right: ${theme.spacing[horizontalPadding]};
    `};
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;

export const FeaturedCard = styled(Surface)<CardProps>`
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 0;
    width: ${({ theme }) => theme.spacing['1.5']};
    background-color: ${({ theme }) => theme.colors.primary[300]};
    mask-clip: content-box;
    border-bottom-left-radius: 100% ${({ theme }) => theme.spacing[3]};
    border-top-left-radius: 100% ${({ theme }) => theme.spacing[3]};
  }
`;
