import React, { forwardRef } from 'react';
import { Surface } from './Card.styles';
import { CardProps } from './types';

function Card(props: CardProps, ref: any) {
  const { children, ...otherProps } = props;

  return (
    <Surface ref={ref} {...otherProps}>
      {children}
    </Surface>
  );
}

export default forwardRef(Card);
