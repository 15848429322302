import { gql } from '@apollo/client';
import type { BusinessUser } from '@src/types/models';

import { PaginatedResponse } from '@src/types/utils';

const BUSINESS_USERS_QUERY = gql`
  query BusinessUsersData(
    $businessId: ID!
    $first: Int
    $status: [BusinessUserStatusEnum!]
  ) {
    businessUsers(businessId: $businessId, first: $first, status: $status) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          userId
          firstName
          lastName
          status
          email
          profileImage
          role
          jobPosition
        }
      }
    }
  }
`;

type Status = 'ACTIVE' | 'PENDING';

export type BusinessUsersParams = {
  businessId?: string;
  first?: number;
  status: Status[];
};

export type BusinessUsersReturn = {
  businessUsers: PaginatedResponse<
    Pick<
      BusinessUser,
      | 'id'
      | 'userId'
      | 'firstName'
      | 'lastName'
      | 'email'
      | 'profileImage'
      | 'role'
      | 'status'
      | 'jobPosition'
    >
  >;
};

export default BUSINESS_USERS_QUERY;
