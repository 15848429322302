import React, { forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import validator from 'validator';

import { TextField } from '@src/components/ui';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { PAYMENT_EMAIL_ADDED } from '@src/constants/events';

interface MultiEmailSelectProps {
  options: string[];
  label?: string;
  value?: any;
  id?: string;
  hiddenLabel?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  isDisabled?: boolean;
  maximumSelectionLength?: number;
  onChange?: (value?: string[]) => void;
  onNewOption?: (value: string) => void;
}

function MultiEmailSelect(
  {
    options,
    value,
    placeholder,
    label,
    maximumSelectionLength,
    onChange,
    onNewOption,
    ...otherProps
  }: MultiEmailSelectProps,
  ref
) {
  const intl = useIntl();
  const { toast } = useToast();
  const isMaxLength = !!maximumSelectionLength;
  const isMaxLengthReached =
    isMaxLength && value?.length >= (maximumSelectionLength || 0);

  const handleCreateOption = useCallback(
    (option) => {
      if (validator.isEmail(option)) {
        const nextOptions = !value ? [option] : [...value, option];
        if (onNewOption) onNewOption(option);
        if (onChange) onChange(nextOptions);
        trackEvent(PAYMENT_EMAIL_ADDED);
      } else {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Correo inválido',
            id: 'IYZgPk',
          }),
          intl.formatMessage({
            defaultMessage: 'Revisa el formato del correo electrónico',
            id: 'VP6G4M',
          })
        );
      }
    },
    [value]
  );

  return (
    <TextField
      label={label}
      options={options}
      type="select"
      ref={ref}
      value={value}
      placeholder={
        placeholder ||
        intl.formatMessage({
          defaultMessage: 'Selecciona los correos',
          id: 'bZTgMV',
        })
      }
      noOptionsMessage={() =>
        intl.formatMessage({
          defaultMessage: 'Escribe un correo nuevo',
          id: 'yMtM3t',
        })
      }
      isMulti
      isOptionDisabled={() => isMaxLengthReached}
      isCreateable={!!onChange && !isMaxLengthReached}
      formatCreateLabel={(inputValue: string) =>
        intl.formatMessage({
          defaultMessage: 'Enviar a ',
          id: 'Sv3+NZ',
        }) + inputValue
      }
      getOptionLabel={(option) => option}
      getOptionValue={(option) => option}
      getNewOptionData={(inputValue, optionLabel) => optionLabel}
      onCreateOption={handleCreateOption}
      onChange={onChange}
      {...otherProps}
    />
  );
}

export default forwardRef(MultiEmailSelect);
