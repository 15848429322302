import React from 'react';
import { FormattedMessage } from 'react-intl';

import Currency from '@src/components/i18n/Currency';
import Percent from '@src/components/i18n/Percent';

import {
  SHIPPING_COST_TYPE_FIXED_AMOUNT,
  SHIPPING_COST_TYPE_TOTAL_PERCENT,
  SHIPPING_COST_TYPE_FREE,
} from '@src/constants/orders';

import { ShippingCostType } from '@src/types/enums';

type ShippingCostProps = {
  className?: string;
  shippingCostType: keyof typeof ShippingCostType;
  shippingCostValue: number;
  shippingCostCurrency: string;
};

function ShippingCost(props: ShippingCostProps) {
  const {
    className,
    shippingCostType,
    shippingCostValue,
    shippingCostCurrency,
  } = props;

  return (
    <div className={className}>
      {shippingCostType === SHIPPING_COST_TYPE_FREE && (
        <FormattedMessage defaultMessage="Incluido" id="tDLC2N" />
      )}
      {shippingCostType === SHIPPING_COST_TYPE_FIXED_AMOUNT && (
        <Currency value={shippingCostValue} currency={shippingCostCurrency} />
      )}
      {shippingCostType === SHIPPING_COST_TYPE_TOTAL_PERCENT && (
        <Percent value={shippingCostValue} />
      )}
    </div>
  );
}

export default ShippingCost;
