import { gql } from '@apollo/client';

export const PROVIDER_LIST_QUERY = gql`
  query ProvidersList($businessId: ID!) {
    providers(businessId: $businessId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
