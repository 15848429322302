import styled from 'styled-components';

export const InvoiceResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .result-invoice-data {
    margin-right: ${({ theme }) => theme.spacing[4]};
    flex-shrink: 1;
  }
  .result-invoice-title {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin: 0;
  }
  .result-invoice-tag {
    flex-shrink: 0;
  }
  .result-invoice-description {
    font-weight: ${({ theme }) => theme.fontWeight.light};
    color: ${({ theme }) => theme.colors.neutral[600]};
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin: 0;
    height: ${({ theme }) => theme.spacing[4]};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
