import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 30vw;
  margin: ${({ theme }) => theme.spacing[4]} auto;

  form > * {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
`;
