import { gql } from '@apollo/client';

const CREATE_COST_CENTER_MUTATION = gql`
  mutation CreateCostCenter($name: String!, $businessId: ID!) {
    createCostCenter(name: $name, businessId: $businessId) {
      id
      name
    }
  }
`;

export type CreateCostCenterParams = {
  businessId: string;
  name: string;
};

export type CreateCostCenterReturn = {
  createCostCenter: {
    id: string;
    name: string;
  };
};

export default CREATE_COST_CENTER_MUTATION;
