import React from 'react';
import { IntlShape, useIntl } from 'react-intl';

import messages from './messages';
import Tag from '@src/components/ui/Tag';

import { InvoiceApprovalStatus } from '@src/types/enums';

type InvoiceStatusBadgeProps = {
  status: keyof typeof InvoiceApprovalStatus;
};

export function optionsForInvoiceStatus(
  intl?: IntlShape
): Array<{ text: string; value: string }> {
  if (!intl || !intl.formatMessage) {
    return Object.values(InvoiceStatusBadge).map((opt) => ({
      text: opt,
      value: opt,
    }));
  }

  return Object.values(InvoiceStatusBadge).map((opt) => ({
    text: messages[opt] ? intl.formatMessage(messages[opt]) : opt,
    value: opt,
  }));
}

export function InvoiceStatusBadge(props: InvoiceStatusBadgeProps) {
  const { status } = props;
  const intl = useIntl();

  let color: 'success' | 'warning' | 'danger' | 'gray';
  switch (status) {
    case InvoiceApprovalStatus.APPROVED:
      color = 'success';
      break;
    case InvoiceApprovalStatus.PENDING:
      color = 'warning';
      break;
    case InvoiceApprovalStatus.REJECTED:
      color = 'danger';
      break;
    default:
      color = 'gray';
      break;
  }
  return (
    <Tag compact label={intl.formatMessage(messages[status])} color={color} />
  );
}

export default InvoiceStatusBadge;
