import React, { ReactNode } from 'react';
import { Button } from '@src/components/ui';
import { ButtonProps } from '@src/components/ui/Button/types';
import * as S from './LinkButton.styles';

interface LinkButtonProps extends ButtonProps {
  href: string;
  children: ReactNode;
  block?: boolean;
  target?: string;
}

const LinkButton = ({
  href,
  children,
  block,
  target,
  ...buttonProps
}: LinkButtonProps) => {
  return (
    <S.Link target={target} to={href} $block={block}>
      <Button block={block} {...buttonProps}>
        {children}
      </Button>
    </S.Link>
  );
};

export default LinkButton;
