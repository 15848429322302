import React from 'react';
import { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { loggedIn } from '@src/lib/auth';
import SidebarLayout from '@src/components/layouts/SidebarLayout';
import * as qs from '@src/utils/querystring';
import { RouteComponentProps, RouteProps } from 'react-router-dom';

type AuthedRouteProps = RouteProps & {
  path?: string;
  showNavbar?: boolean;
  component: ComponentType<any>;
};

export function AuthedRoute(props: AuthedRouteProps) {
  const { component: Component, showNavbar, ...rest } = props;

  return (
    <Route
      exact
      {...rest}
      render={(props: RouteComponentProps) => {
        if (loggedIn()) {
          return showNavbar ? (
            <SidebarLayout>
              <Component {...props} />
            </SidebarLayout>
          ) : (
            <Component {...props} />
          );
        } else {
          const url = '/p/login';

          const encodedSearch = props.location.search
            ? encodeURIComponent(props.location.search)
            : '';
          const redirectTo = `${props.location.pathname}${encodedSearch}`;
          const querystring = qs.stringify({
            redirectTo,
          });

          return <Redirect to={`${url}${querystring}`} />;
        }
      }}
    />
  );
}

export default AuthedRoute;
