import React, { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import union from 'lodash/union';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';

import PaginationControls from '@src/components/shared/PaginationControls';
import HeaderActionBar from '@src/components/shared/HeaderActionBar';
import Table from '@src/components/shared/Table';
import {
  InvoiceVoucherType,
  InvoiceMailboxStatus,
  InvoicePaymentStatus,
} from '@src/types/enums';
import useUpdateInvoiceMailboxStatusMutation from '@src/hooks/useUpdateInvoiceMailboxStatusMutation';
import useSettings from '@src/hooks/useSettings';
import useSortFilters from '@src/hooks/useSortFilters';
import { UPDATE_INVOICE_MAILBOX_STATUS } from '@src/constants/events';
import { trackEvent } from '@src/lib/analytics';
import { InvoicesTableStyled } from './InvoicesTable.styles';
import useToast from '@src/hooks/useToast';
import defaultAttributes from './attributes';

import type { Invoice } from '@src/types/models';
import type { Attributes } from '@src/types/resourceList';

type CustomInvoice = Partial<Invoice> & { id: string };

type InvoiceProperty = keyof CustomInvoice;

type InvoicesTableProps = {
  invoices: CustomInvoice[];
  loading?: boolean;
  customAttributes?: Attributes<CustomInvoice>;
  count?: number;
  page?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  showActionBar?: boolean;
  selectable?: boolean;
  selectedIds?: string[];
  actions?: React.ReactNode;
  emptyElement?: React.ReactNode;
  columns?:
    | { [Property in keyof Invoice]?: string | number }
    | Array<InvoiceProperty>;
  limit?: number;
  selectionLimit?: number;
  totalAmount?: number;
  nodes: CustomInvoice[];
  onLimitChange?: (limit: number) => void;
  onPrevious?: () => void;
  onNext?: () => void;
  onSelect?: (id: string[]) => void;
};

const InvoicesTable = ({
  invoices,
  customAttributes,
  loading,
  count,
  hasNext,
  hasPrevious,
  showActionBar,
  actions,
  selectable,
  emptyElement,
  columns,
  limit,
  selectionLimit,
  page,
  nodes,
  onLimitChange,
  onSelect,
  onPrevious,
  onNext,
  totalAmount = 0,
  selectedIds = [],
}: InvoicesTableProps) => {
  const intl = useIntl();
  const { business } = useSettings();
  const { toast } = useToast();
  const attributes = customAttributes || defaultAttributes;
  const history = useHistory();
  const [, handleSort, sortParams] = useSortFilters();
  const { mutate: markAsRead } = useUpdateInvoiceMailboxStatusMutation();
  const selectedTotal = useMemo(() => {
    const totalInCents = nodes
      .filter((invoice) => selectedIds.includes(invoice.id))
      .reduce(
        (accumulator, invoice) =>
          invoice.paymentStatus === InvoicePaymentStatus.PAID
            ? accumulator + (invoice?.total || 0)
            : accumulator + (invoice?.outstandingBalance || 0),
        0
      );
    return new Intl.NumberFormat(intl.locale).format(totalInCents / 100);
  }, [nodes, selectedIds]);
  const total =
    totalAmount && new Intl.NumberFormat(intl.locale).format(totalAmount / 100);

  const fields = Array.isArray(columns)
    ? columns
    : Object.keys(columns || attributes);
  const columnsWidth =
    Array.isArray(columns) || !columns ? undefined : Object.values(columns);
  const hasAllSelected =
    selectedIds.length > 0 && selectedIds.length === nodes.length;
  const hasAnySelected = !!selectedIds.length;

  const showLimitError = () => {
    toast.error(
      intl.formatMessage(
        {
          defaultMessage: 'No puedes seleccionar mas de {limit} facturas',
          id: 'pVswnD',
        },
        {
          limit: selectionLimit,
        }
      )
    );
  };

  const handleSelect = (targetId) => {
    if (selectionLimit && selectedIds.length >= selectionLimit) {
      showLimitError();
    } else if (selectedIds.includes(targetId) && onSelect) {
      onSelect(selectedIds.filter((id) => id !== targetId));
    } else if (onSelect) {
      onSelect([targetId, ...selectedIds]);
    }
  };

  const handleSelectAll = () => {
    const pageInvoiceIds = invoices.map(({ id }) => id);
    const hasAllPageSelected =
      intersection(pageInvoiceIds, selectedIds).length ===
      pageInvoiceIds.length;
    const mergedIds = union(pageInvoiceIds, selectedIds);

    if (hasAllPageSelected && onSelect) {
      const afterRemove = difference(selectedIds, pageInvoiceIds);
      onSelect(afterRemove);
    } else if (selectionLimit && mergedIds.length > selectionLimit) {
      showLimitError();
    } else if (onSelect) {
      onSelect(mergedIds);
    }
  };

  const handleRowClick = async (targetId) => {
    const invoice = invoices.find((i) => i.id === targetId);
    if (business && invoice?.mailboxStatus === InvoiceMailboxStatus.RECEIVED) {
      try {
        await markAsRead({
          variables: {
            businessId: business.id,
            invoiceId: targetId,
            mailboxStatus: InvoiceMailboxStatus.PENDING_REVISION,
          },
        });

        trackEvent(UPDATE_INVOICE_MAILBOX_STATUS, {
          creator: 'Buyer',
          view: 'InvoicesListComponent',
        });
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al actualizar el status',
            id: 'UBbjGS',
          }),
          e.message
        );
      }
    }

    if (invoice) {
      const link =
        invoice.voucherType === InvoiceVoucherType.CREDIT_NOTE
          ? `/credit_notes/${invoice.id}`
          : `/invoices/${invoice.id}`;
      history.push(link);
    }
  };

  return (
    <InvoicesTableStyled>
      {showActionBar && hasAnySelected && (
        <HeaderActionBar
          indeterminate={selectedIds.length !== nodes.length}
          title={intl.formatMessage(
            {
              defaultMessage: '{count} elementos seleccionados por ${total}',
              id: 'oDhAKA',
            },
            {
              count: selectedIds.length,
              total: selectedTotal,
            }
          )}
          checked={hasAllSelected}
          onChange={handleSelectAll}
          actions={actions}
        />
      )}

      <Table
        emptyElement={emptyElement}
        loading={loading}
        keyExtractor={(invoice) => invoice.id}
        strongFilter={(invoice) =>
          invoice.mailboxStatus === InvoiceMailboxStatus.RECEIVED
        }
        attributes={attributes}
        headers={fields}
        data={invoices}
        selectedIds={selectedIds}
        hasAllSelected={hasAllSelected}
        onSelectAll={handleSelectAll}
        onSelect={handleSelect}
        onRowClick={handleRowClick}
        selectable={selectable}
        onSort={handleSort}
        sort={sortParams}
        limit={limit}
        columnsWidth={columnsWidth}
      />

      <PaginationControls
        limit={limit}
        loading={loading}
        page={page}
        itemCount={invoices.length}
        count={count}
        hasNext={hasNext}
        onNext={onNext}
        hasPrevious={hasPrevious}
        onPrevious={onPrevious}
        onLimitChange={onLimitChange}
        endMessage={
          !!total && (
            <p>
              <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />{' '}
              <strong> ${total}</strong>
            </p>
          )
        }
      />
    </InvoicesTableStyled>
  );
};

export default memo(InvoicesTable);
