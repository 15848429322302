import React from 'react';

import SignUpStepBar from '@src/components/shared/SignUpStepBar';
import * as S from './OnboardingLayout.styles';

function OnboardingLayout({ children, step, title, isPayeeFlow = false }) {
  return (
    <S.Page title={title}>
      <S.LeftContainer>
        <SignUpStepBar step={step} isPayeeFlow={isPayeeFlow} />
      </S.LeftContainer>
      <S.RightContainer>{children}</S.RightContainer>
    </S.Page>
  );
}

export default OnboardingLayout;
