import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableHeader,
  TableHeaderCell,
  Tooltip,
  IconButton,
} from '@src/components/ui';
import messages from '../messages';
import { Edit3 } from '@src/components/ui/Icon/outline';
import useModal from '@src/hooks/useModal';
import CreateSupplierContactModal from '@src/components/pages/SupplierView/components/CreateSupplierContactModal';
import StarButton from '@src/components/shared/buttons/StarButton';
import {
  DELETE_PROVIDER_CONTACT,
  UPDATE_PROVIDER_CONTACT as UPDATE_PROVIDER_CONTACT_EVENT,
} from '@src/constants/events';
import { trackEvent } from '@src/lib/analytics';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import { UPDATE_PROVIDER_CONTACT } from '@src/graphql/mutations';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';
import EmptyResults from '@src/components/shared/EmptyResults';

type SupplierContactsTableProps = {
  contacts: Array<{
    id: string;
    defaultContact: boolean;
    contactable: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
    };
  }>;
  businessEmitterName?: string;
  businessRelationshipId: string;
};

export const eventData = {
  creator: 'buyer',
  view: 'SupplierView',
};

export function SupplierContactsTable({
  contacts,
  businessRelationshipId,
  businessEmitterName,
}: SupplierContactsTableProps) {
  const intl = useIntl();
  const business = useSettings('business');
  const { toast } = useToast();
  const { open } = useModal(CreateSupplierContactModal);
  const [updateProviderContact, { loading, error }] = useMutation(
    UPDATE_PROVIDER_CONTACT,
    {
      refetchQueries: [SUPPLIER_RELATIONSHIP_QUERY_NAME],
      awaitRefetchQueries: true,
    }
  );

  const handleButtonClick = () => {
    open({ businessRelationshipId });
  };

  const handleFavoriteButton = async (providerId: string, update: boolean) => {
    if (businessRelationshipId && business?.id) {
      try {
        await updateProviderContact({
          variables: {
            businessId: business.id,
            businessRelationshipId,
            providerContactId: providerId,
            providerContact: {
              default: update,
            },
          },
        });

        if (update) {
          trackEvent(UPDATE_PROVIDER_CONTACT_EVENT, eventData);
          toast.success(
            intl.formatMessage(
              messages.updateMainContactProviderContactSuccessTitle
            ),
            intl.formatMessage(messages.updateMainContactProviderContactMessage)
          );
        } else {
          trackEvent(DELETE_PROVIDER_CONTACT, eventData);
          toast.success(
            intl.formatMessage(messages.deleteProviderContactSuccessTitle),
            intl.formatMessage(messages.deleteProviderContactMessage)
          );
        }
      } catch (e) {
        if (update) {
          toast.error(
            intl.formatMessage(messages.updateMainContactProviderContactError),
            e.message
          );
        } else {
          toast.error(
            intl.formatMessage(messages.deleteProviderContactError),
            e.message
          );
        }
      }
    }
  };

  return (
    <>
      <Table columnsWidth={[50, 200, 200, 200]}>
        <TableHeader>
          <TableHeaderCell />
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Nombre',
              id: 'hCOqfl',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Email',
              id: 'sy+pv5',
            })}
          </TableHeaderCell>
          <TableHeaderCell justifyContent="flex-end">
            <Button compact onClick={handleButtonClick}>
              {intl.formatMessage({
                defaultMessage: 'Agregar contacto',
                id: 'NWpbar',
              })}
            </Button>
          </TableHeaderCell>
        </TableHeader>
        <tbody>
          {contacts.map((contact, index) => {
            const { email, firstName, lastName } = contact.contactable;
            const { id, defaultContact } = contact;
            const initialValues = {
              id,
              defaultContact,
              email,
              firstName,
              lastName,
              businessRelationshipId,
            };
            return (
              <TableRow key={index}>
                <TableCell>
                  <StarButton
                    active={defaultContact}
                    onActivate={() => {
                      handleFavoriteButton(id, true);
                    }}
                    onDeactivate={() => {
                      handleFavoriteButton(id, false);
                    }}
                    TooltipProps={{
                      id: 'contact-favorite-tooltip',
                      title: intl.formatMessage({
                        defaultMessage: 'Marcar como contacto principal',
                        id: '2GOEb8',
                      }),
                    }}
                  />
                </TableCell>
                <TableCell>
                  {firstName
                    ? lastName
                      ? `${firstName} ${lastName}`
                      : firstName
                    : lastName
                    ? lastName
                    : intl.formatMessage(messages.noInfoContact)}
                </TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip
                      id="edit-contact"
                      place="top"
                      effect="solid"
                      title={intl.formatMessage({
                        defaultMessage: 'Editar',
                        id: 'VGHwB5',
                      })}
                    >
                      <IconButton
                        onClick={() => {
                          open({ businessRelationshipId, initialValues });
                        }}
                        color="neutral"
                        rounded
                        variant="transparent"
                      >
                        <Edit3 size={18} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      {!loading && !contacts.length && (
        <EmptyResults
          label={intl.formatMessage({
            defaultMessage: 'Aún no tienes contactos',
            id: 'NTMjtn',
          })}
          description={intl.formatMessage(
            {
              defaultMessage:
                'Aquí verás todos los contactos que tengas con {name}.',
              id: 'ctSk0Y',
            },
            {
              name: businessEmitterName,
            }
          )}
        />
      )}
    </>
  );
}

export default SupplierContactsTable;
