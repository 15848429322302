import { useMemo } from 'react';

import { centsToFloat } from '@src/utils/currency';

import { Invoice, PaymentMethod } from '@src/types/models';
import useInvoicePaymentMethods from './useInvoicePaymentMethods';
import useUserPaymentMethods from './useUserPaymentMethods';

type PaymentField = {
  email: string[];
  paymentConcept: string;
  paymentMethod?: PaymentMethod;
  amount: number;
  invoiceId?: string;
};

type InvoiceField<Invoice> = Invoice & PaymentField;

const getSupplierEmails = (invoice) =>
  invoice?.businessRelationship?.providerContacts
    ?.map((invoice) => invoice?.contactable?.email)
    .filter((mail) => Boolean(mail)) || [];

function usePaymentDefaults<
  PartialInvoice extends Pick<
    Invoice,
    | 'id'
    | 'businessRelationship'
    | 'outstandingBalance'
    | 'receiverCountryTaxPayerInfo'
    | 'factoringOffer'
  >
>(
  invoices: Array<PartialInvoice>,
  overrides?: Partial<PaymentField> | Partial<PaymentField>[]
): Array<InvoiceField<PartialInvoice>> {
  const userPaymentMethods = useUserPaymentMethods();
  const getInvoicePaymentMethods = useInvoicePaymentMethods();
  const defaults = useMemo(
    () =>
      invoices.map((invoice) => {
        const override = Array.isArray(overrides)
          ? overrides.find((o) => o.invoiceId === invoice.id)
          : overrides;
        const { email, paymentConcept, paymentMethod, amount } = override || {};

        const { defaultPaymentMethod } = getInvoicePaymentMethods(
          invoice,
          userPaymentMethods
        );

        return {
          ...invoice,
          email: email || getSupplierEmails(invoice),
          paymentMethod: paymentMethod || defaultPaymentMethod,
          amount: amount || centsToFloat(invoice.outstandingBalance),
          paymentConcept:
            paymentConcept ||
            `Pago Factura ${
              invoice?.receiverCountryTaxPayerInfo?.folio || ''
            }`.trim(),
        };
      }),
    [invoices, overrides]
  );
  return defaults;
}

export default usePaymentDefaults;
