import { gql } from '@apollo/client';
import CLIENT_RELATIONSHIPS_ATTRIBUTES from '@src/graphql/fragments/clientRelationshipAttributes';

export const DELETE_BUSINESS_RELATIONSHIP_MUTATION = gql`
  mutation DeactivateBusinessRelationship(
    $businessId: ID!
    $businessRelationshipId: ID!
  ) {
    deactivateBusinessRelationship(
      businessId: $businessId
      businessRelationshipId: $businessRelationshipId
    ) {
      ...ClientRelationshipsAttributes
    }
  }
  ${CLIENT_RELATIONSHIPS_ATTRIBUTES}
`;

export default DELETE_BUSINESS_RELATIONSHIP_MUTATION;
