import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[4]};
  width: 463px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary[500]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: underline;
`;
