import styled from 'styled-components';

export const ReconciliationModalContainer = styled.div`
  width: 59rem;
  max-width: 100vw;
`;

export const InvoicesTableContainer = styled.div`
  div:first-of-type {
    max-height: 20rem;
  }
  thead th {
    z-index: ${({ theme }) => theme.zIndex[10]};
  }
`;
