import styled from 'styled-components';

export const DateContainer = styled.p`
  color: ${({ theme }) => theme.colors.neutral[500]};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

export const CurrencyContainer = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;
`;

export const MarginLeft = styled.div`
  margin-left: ${({ theme }) => theme.spacing[7]};
  margin-top: ${({ theme }) => theme.spacing[2]};
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }

  h5 {
    margin: 0;
  }
`;
