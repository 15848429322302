import { gql } from '@apollo/client';

export const UPDATE_CODAT_DATA_SOURCE_MUTATION = gql`
  mutation UpdateCodatDataSource(
    $businessId: ID!
    $codatConnectionId: String!
    $autoSyncBills: Boolean!
  ) {
    updateCodatDataSource(
      businessId: $businessId
      codatConnectionId: $codatConnectionId
      autoSyncBills: $autoSyncBills
    ) {
      id
    }
  }
`;

export type updateCodatDataSourceMutationReturn = {
  id: number;
};

export type updateCodatDataSourceMutationVariables = {
  businessId: string;
  codatConnectionId: string;
  autoSyncBills: boolean;
};
