import React from 'react';
import { useIntl } from 'react-intl';

import Icon from '@src/components/ui/Icon';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { Label } from '@src/components/ui';

import pciLogo from './img/pci.png';

const SecurePaymentsNotification = () => {
  const intl = useIntl();
  return (
    <FlexContainer alignItems="center">
      <Icon name="Shield" color="neutral" colorStep="500" size={48} />
      <Spacer direction="horizontal" />
      <Label size="sm" colorStep="500">
        {intl.formatMessage({
          defaultMessage:
            'Toda tu información está protegida y se mantendrá oculta para cualquier usuario dentro de Higo.',
          id: 'Nm1Bre',
        })}
      </Label>
      <Spacer direction="horizontal" margin="7" />
      <img src={pciLogo} style={{ height: 24 }} />
    </FlexContainer>
  );
};

export default SecurePaymentsNotification;
