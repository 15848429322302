import React from 'react';
import { FormattedMessage } from 'react-intl';

import Avatar from '@src/components/ui/Avatar';

import { BusinessRelationship } from '@src/types/models';
import { AttributesObject } from '@src/types/resourceList';

const attributes: AttributesObject<BusinessRelationship> = {
  avatar: {
    name: '',
    type: 'CUSTOM',
    render: (datum) => (
      <Avatar
        name={datum.provider?.defaultTaxPayerInfo?.taxpayerName}
        backgroundColor={`#${datum?.providerImageColorCode}`}
      />
    ),
  },
  provider: {
    name: <FormattedMessage defaultMessage="Proveedor" id="RMvOYP" />,
    type: 'CUSTOM',
    render: (datum) => datum.provider?.defaultTaxPayerInfo?.taxpayerName,
  },
  provider2: {
    name: <FormattedMessage defaultMessage="RFC" id="wvCzn7" />,
    type: 'STRING',
    render: (datum) => datum.provider?.defaultTaxPayerInfo?.taxpayerIdentifier,
  },
  invoicesCount: {
    name: <FormattedMessage defaultMessage="No. Facturas" id="dk9b+x" />,
    sortable: true,
    type: 'STRING',
    render: (datum) => datum.invoicesCount,
  },
};

export default attributes;
