import { gql } from '@apollo/client';

export const CODAT_COMPANY_QUERY = gql`
  query CodatCompany($businessId: ID!) {
    codatCompany(businessId: $businessId) {
      codatId
      codatLinkUrl
      codatDataSources {
        codatConnectionId
        id
        platformName
        createdAt
        autoSyncBills
      }
    }
  }
`;
