import styled from 'styled-components';

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.neutral[500]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  span {
    margin-left: ${({ theme }) => theme.spacing[1]};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }
`;
