import styled from 'styled-components';
import { TabBarProps } from './types';

export const Wrapper = styled.div<TabBarProps>`
  width: 100%;
  display: flex;
  overflow-x: auto;

  > * {
    flex-shrink: 0;
  }
`;
