import styled from 'styled-components';
import type { Color, ColorStep } from 'styled-components';

export interface ProgressBarProps {
  backgroundColor: Color;
  backgroundColorStep?: ColorStep;
}
export interface BarProps {
  percentage: number;
  backgroundColor: Color;
  backgroundColorStep?: ColorStep;
  transition?: number;
  delay?: number;
}

const ProgressBar = styled.div<ProgressBarProps>`
  background-color: ${({ theme, backgroundColor, backgroundColorStep }) =>
    theme.colors[backgroundColor][backgroundColorStep || 500]};
  height: ${({ theme }) => theme.spacing[2]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  width: 100%;
  position: relative;
`;

export const Bar = styled.div<BarProps>`
  position: absolute;
  content: '';
  left: 0;
  transition: width ${({ transition }) => transition || 500}ms ease-out
    ${({ delay }) => `${delay || 0}ms`};
  background-color: ${({ theme, backgroundColor, backgroundColorStep }) =>
    theme.colors[backgroundColor][backgroundColorStep || 50]};
  height: ${({ theme }) => theme.spacing[2]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  width: ${({ percentage }) => percentage}%;
`;

export default ProgressBar;
