import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { FlexContainer } from '@src/components/shared/layouts';
import { FileText } from '@src/components/ui/Icon/outline';
import PaymentsReconciliationModal from '@src/components/shared/modals/PaymentsReconciliationModal';

import useModal from '@src/hooks/useModal';
import { TransactionType } from '@src/types/enums';
import { WalletEntry } from '@src/types/models';

import * as S from './LinkToTransactionDetails.styles';
import useTransaction from '@src/hooks/useTransaction';

interface LinkToTransactionDetailsProps {
  walletEntry: WalletEntry;
}

export const LinkToTransactionDetails = ({
  walletEntry,
}: LinkToTransactionDetailsProps) => {
  const { type, invoices, hashId, invoice } = useTransaction(walletEntry);
  const intl = useIntl();
  const { open: openPaymentsReconciliation } = useModal(
    PaymentsReconciliationModal
  );

  if (type === TransactionType.ADVANCE_PAYMENT) {
    return invoices && invoices.length > 0 ? (
      <Link to={`/transactions/${hashId}`} onClick={(e) => e.stopPropagation()}>
        <S.Link>
          <FileText size={16} />
          {intl.formatMessage(
            {
              id: 'ZEiAiU',
              defaultMessage: '{invoices} factura(s) ligadas',
            },
            { invoices: invoices.length }
          )}
        </S.Link>
      </Link>
    ) : (
      <FlexContainer gap="1rem">
        <S.Link disabled>
          <FileText size={16} />
          {intl.formatMessage({
            id: 'OoKfyG',
            defaultMessage: 'Sin factura',
          })}
        </S.Link>
        {walletEntry.status === 'PROCESSED' && (
          <S.LinkInvoiceButton
            onClick={(e) => {
              openPaymentsReconciliation({ walletEntry });
              e.stopPropagation();
            }}
          >
            {intl.formatMessage({
              id: 'axze5Y',
              defaultMessage: 'Ligar factura',
            })}
          </S.LinkInvoiceButton>
        )}
      </FlexContainer>
    );
  }

  return invoice?.id ? (
    <Link to={`/invoices/${invoice.id}`} onClick={(e) => e.stopPropagation()}>
      <S.Link>
        <FileText size={16} />
        {intl.formatMessage({
          id: '/TgHRS',
          defaultMessage: 'Ver factura',
        })}
      </S.Link>
    </Link>
  ) : (
    <FlexContainer gap="1rem">
      <S.Link disabled>
        <FileText size={16} />
        {intl.formatMessage({
          id: 'OoKfyG',
          defaultMessage: 'Sin factura',
        })}
      </S.Link>
    </FlexContainer>
  );
};

export default LinkToTransactionDetails;
