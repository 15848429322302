import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { hasPermission } from '@src/utils/permissions';
import { UserRole, PermissionName } from '@src/types/enums';
import useSettings from '@src/hooks/useSettings';

type PermissionProps = {
  children: JSX.Element;
  redirect?: boolean;
  permissions?: Array<keyof typeof PermissionName>;
  minimumRoles?: Array<keyof typeof UserRole>;
};

export function Permission({
  permissions = [],
  redirect,
  children,
  minimumRoles,
}: PermissionProps) {
  const history = useHistory();
  const { currentUser } = useSettings();
  const shouldRender = hasPermission(permissions, minimumRoles, currentUser);

  useEffect(() => {
    if (currentUser && !shouldRender && redirect) {
      history.replace('/p/403');
    }
  }, [history, currentUser, shouldRender, redirect]);

  return shouldRender ? children : null;
}

export default Permission;
