import React, { useState } from 'react';
import { IntlShape } from 'react-intl';
import { formatDistanceToNow } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { useMutation, QueryResult } from '@apollo/client';
import toastr from '@src/lib/toastr';

import { I18N } from '@src/lib/i18n';

import { IntegrationData } from '../types';
import { CodatDataSource } from '@src/types/models';
import { Business } from '@src/types/models';

import useErrorReporter from '@src/hooks/useErrorReporter';
import Button from '@src/components/ui/Button';
import {
  CREATE_CODAT_COMPANY,
  DELETE_CODAT_DATASOURCE,
} from '@src/graphql/mutations';
import { buttons } from '../messages';

interface ConnectButtonProps {
  intl: IntlShape;
  business?: Business;
  integration: IntegrationData;
  codatCompanyQuery: QueryResult;
}

export function ConnectButton(props: ConnectButtonProps) {
  const { intl, business, integration, codatCompanyQuery } = props;

  const [loadingCodatLink, setLoadingCodatLink] = useState(false);
  const [loadingCodatUnlink, setLoadingCodatUnlink] = useState(false);
  const [createCodatCompany] = useMutation(CREATE_CODAT_COMPANY, {
    variables: { businessId: business?.id },
  });
  const [deleteCodatDataSource] = useMutation(DELETE_CODAT_DATASOURCE);
  const errorReporter = useErrorReporter();

  if (!!codatCompanyQuery?.loading) {
    return null;
  }

  /**
   *  Redirects the user to Codat Link URL
   *  Triggered when user clicks on Connectar
   *  If CodatCompany already does not exist yet, it creates it
   */
  const triggerCodatLink = async () => {
    setLoadingCodatLink(true);

    let codatLinkUrl = '';
    if (!codatCompanyQuery?.data?.codatCompany.codatLinkUrl) {
      const { data } = await createCodatCompany();
      codatLinkUrl = data?.createCodatCompany?.codatLinkUrl;
    } else {
      codatLinkUrl = codatCompanyQuery?.data?.codatCompany.codatLinkUrl;
    }
    if (codatLinkUrl) {
      const currentPath = window.location.href.split('?')[0];
      window.location.replace(`${codatLinkUrl}?dynamicURL=${currentPath}`);
    } else {
      errorReporter('codatLinkUrl is null', {
        businessId: business?.id,
      });
    }
  };

  const triggerUnlink = async () => {
    if (!existingDataSource || loadingCodatUnlink) {
      return;
    }

    setLoadingCodatUnlink(true);
    try {
      await deleteCodatDataSource({
        variables: {
          businessId: business?.id,
          codatDataSourceId: existingDataSource?.id,
        },
      });
      await codatCompanyQuery.refetch();
    } catch (e: any) {
      toastr.error('Error', e.message);
    }
    setLoadingCodatUnlink(false);
  };

  const existingDataSource:
    | CodatDataSource
    | undefined = codatCompanyQuery?.data?.codatCompany.codatDataSources?.find(
    (source: CodatDataSource) =>
      source.platformName === integration.codatPlatformKey
  );

  if (!!existingDataSource) {
    return (
      <div>
        <p>
          {intl.formatMessage({
            defaultMessage: 'Activado',
            id: 'zKfimU',
          })}{' '}
          {formatDistanceToNow(new Date(existingDataSource.createdAt), {
            addSuffix: true,
            locale: I18N.getLocale() === 'es' ? es : enUS,
          })}
          .
        </p>
        <Button
          onClick={triggerUnlink}
          variant="outlined"
          color="danger"
          loading={loadingCodatUnlink}
        >
          {intl.formatMessage({
            defaultMessage: 'Desconectar',
            id: 'w8vWqr',
          })}
        </Button>
      </div>
    );
  }

  return (
    <Button
      onClick={triggerCodatLink}
      disabled={integration.soon || loadingCodatLink}
    >
      {buttons[integration.code] &&
        intl.formatMessage(buttons[integration.code])}
    </Button>
  );
}
