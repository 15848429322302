import styled, { keyframes } from 'styled-components';
import { cssTransition, ToastContainer as Container } from 'react-toastify';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
`;

export const Appear = cssTransition({
  enter: 'fadeIn',
  exit: 'fadeOut',
  appendPosition: false,
  collapse: true,
  collapseDuration: 300,
});

export const Button = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary[300]};
  border-radius: ${({ theme }) => theme.spacing[2]};
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[3]}`};
  margin-left: ${({ theme }) => theme.spacing[2]};
  font-family: ${({ theme }) => theme.fontFamily.sans};

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[800]};
    transition: 200ms;
  }
`;

export const StyledContainer = styled(Container)`
  --toastify-color-info: ${({ theme }) => theme.colors.neutral[900]};
  --toastify-text-color-info: ${({ theme }) => theme.colors.neutral[50]};
  --toastify-color-progress-info: ${({ theme }) => theme.colors.neutral[900]};
  --toastify-font-family: ${({ theme }) => theme.fontFamily.sans};
  --toastify-toast-background: ${({ theme }) => theme.colors.neutral[900]};
  --toastify-toast-min-height: ${({ theme }) => theme.spacing[16]};
  --toastify-z-index: 9999;

  width: auto;

  /* Notifications */
  .Toastify__toast {
    height: ${({ theme }) => theme.spacing[16]};
    box-shadow: ${({ theme }) => theme.boxShadow.lg};
    font-size: ${({ theme }) => theme.fontSize.sm};
    border-radius: ${({ theme }) => theme.spacing[2]};
    padding: 0 ${({ theme }) => theme.spacing[4]};
    display: flex;
    align-items: center;
  }

  .Toastify__toast-body {
    align-items: flex-start;
    white-space: nowrap;
  }
  .Toastify__close-button {
    color: ${({ theme }) => theme.colors.neutral[50]};
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
    margin-top: ${({ theme }) => theme.spacing.px};
    opacity: 0.6;
    align-self: auto;
  }
  .Toastify__close-button > svg {
    width: ${({ theme }) => theme.spacing[4]};
    height: ${({ theme }) => theme.spacing[4]};
  }

  p,
  span,
  a {
    margin: 0;
  }

  /* Custom animations */
  .fadeIn {
    animation-name: ${fadeIn};
    animation-duration: 250ms;
    animation-fill-mode: both;
  }
  .fadeOut {
    animation-name: ${fadeOut};
    animation-duration: 250ms;
    animation-fill-mode: both;
  }
`;
