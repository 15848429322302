import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    defaultMessage: 'Cargando...',
    id: 'ARhEHP',
  },
  error: {
    defaultMessage: 'Error al cargar los datos',
    id: 'yM0cd/',
  },
  title: {
    defaultMessage: 'No. {walletEntryId}',
    id: 'je2P+3',
  },
  errorTitle: {
    defaultMessage: 'Hemos detectado un error.',
    id: '+9Bngw',
  },
  unclaimedAlertMessage: {
    defaultMessage:
      'El pago se ha procesado correctamente pero tu proveedor aún no ha confirmado la recepción del mismo.',
    id: 'mxCf5f',
  },
});
