import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as S from './PaymentResults.styles';
import {
  Table,
  Button,
  TableRow,
  TableHeader,
  TableCell,
  TableHeaderCell,
  Header,
  Card,
  Label,
} from '@src/components/ui';
import Currency from '@src/components/i18n/Currency';
import STPPaymentTimeAlert from '@src/components/shared/alerts/STPPaymentTimeAlert';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import {
  STPAccount,
  HigoPayAccount,
} from '@src/components/shared/AccountInformation';

import { InvoicePaymentType } from '@src/types/enums';

import { ReactComponent as ConfirmPayment } from './img/confirmPayment.svg';
import { BankingInfo, BankingAccount, PaymentMethod } from '@src/types/models';
import usePageTrack from '@src/hooks/usePageTrack';

type PaymentType = {
  receiverAccount?: BankingAccount;
  amountCents: number;
  bankingInfo?: BankingInfo;
  paymentMethod: PaymentMethod;
};

interface PaymentResultsProps {
  payments: PaymentType[];
  onClose?: () => void;
}

function PaymentResults({ payments, onClose }: PaymentResultsProps) {
  usePageTrack('payment_results', 'core');
  const intl = useIntl();
  const history = useHistory();
  const totalPaid = payments.reduce((acc, item) => acc + item.amountCents, 0);

  const handleClose = () => {
    history.push(`/transactions`);
    onClose?.();
  };

  return (
    <S.ConfirmationContainer>
      <Card padding="8" depth="sm">
        <S.Content>
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <ConfirmPayment />
            <Spacer margin="4" />
            <Header as="h5">
              {intl.formatMessage({
                id: 'XfvBqr',
                defaultMessage: 'Tu pago ha sido enviado',
              })}
            </Header>
          </FlexContainer>
          <Spacer margin="2" />
          <FlexContainer justifyContent="center">
            <FlexContainer flexDirection="column" alignItems="center">
              <Label>
                {intl.formatMessage({
                  id: 'COq66n',
                  defaultMessage: 'Pagos',
                })}
              </Label>
              <Label strong size="lg">
                {payments.length}
              </Label>
            </FlexContainer>
            <Spacer margin="8" direction="horizontal" />
            <FlexContainer flexDirection="column" alignItems="center">
              <Label>
                {intl.formatMessage({
                  id: 'paxGZi',
                  defaultMessage: 'Monto Total',
                })}
              </Label>
              <Label strong size="lg">
                <Currency value={totalPaid} />
              </Label>
            </FlexContainer>
          </FlexContainer>
          <Spacer margin="8" />
          <STPPaymentTimeAlert />
          <Spacer margin="8" />
          <Table className="table-payments">
            <TableHeader>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'dtkaPZ',
                  defaultMessage: 'Beneficiario',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'RMvOYP',
                  defaultMessage: 'Proveedor',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'F3Fk3M',
                  defaultMessage: 'Monto pagado',
                })}
              </TableHeaderCell>
            </TableHeader>
            <tbody>
              {payments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {false ? (
                      <HigoPayAccount name={payment.receiverAccount?.taxId} />
                    ) : null}
                    {payment.paymentMethod?.paymentType ===
                      InvoicePaymentType.STP_PAYMENT && payment.bankingInfo ? (
                      <STPAccount
                        logoUrl={payment.receiverAccount?.bank?.logoUrl}
                        bankName={payment.receiverAccount?.bank?.name}
                        clabe={payment.receiverAccount?.clabe || ''}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>{payment.receiverAccount?.taxId}</TableCell>
                  <TableCell>
                    <Currency value={payment.amountCents} />
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <Spacer margin="8" />
          <div>
            <Button onClick={handleClose}>
              {intl.formatMessage({
                id: 'arPhpH',
                defaultMessage: 'Ir a transacciones',
              })}
            </Button>
          </div>
        </S.Content>
      </Card>
    </S.ConfirmationContainer>
  );
}

PaymentResults.displayName = 'PaymentResults';

export default PaymentResults;
