import React from 'react';
import { FormattedDate as OriginalFormattedDate } from 'react-intl';

import { IntlProviderContext } from '@src/components/i18n/IntlProvider';

export const defaultTimeFormat = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

export const defaultDateFormat = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  localeMatcher: 'lookup',
};

export const dateDigitsFormat = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  localeMatcher: 'lookup',
};

export default function FormattedDate(props: any) {
  return (
    <IntlProviderContext.Consumer>
      {(intl) => <OriginalFormattedDate timeZone={intl.timeZone} {...props} />}
    </IntlProviderContext.Consumer>
  );
}
