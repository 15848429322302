import AWS from 'aws-sdk';

const tmpStorageBucket = process.env.REACT_APP_S3_BUCKET_NAME;
const bucketRegion = process.env.REACT_APP_S3_BUCKET_REGION;
const IdentityPoolId = process.env.REACT_APP_S3_POOL_ID;

export function uploadFile(file: any) {
  if (!IdentityPoolId || !tmpStorageBucket) return null;

  AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: IdentityPoolId,
    }),
  });

  const fileName = file.name;
  const key = encodeURI(`${Math.random()}-${fileName}`);

  const upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: tmpStorageBucket,
      Key: key,
      Body: file,
      ACL: 'public-read',
    },
  });

  return upload.promise();
}
