import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './TransactionSection.styles';
import WalletTransactionCard from '../WalletTransactionCard';
import EmptyResults from '@src/components/shared/EmptyResults';

import { WalletEntry } from '@src/types/models';
interface TransactionSectionProps {
  walletEntries: WalletEntry[];
  onClick: (entry: WalletEntry) => void;
  icon: React.ReactNode;
  title: string;
  loading?: boolean;
  hideOnEmpty?: boolean;
}

function TransactionSection({
  walletEntries,
  onClick,
  icon,
  title,
  loading,
  hideOnEmpty,
}: TransactionSectionProps) {
  const intl = useIntl();

  if (hideOnEmpty && walletEntries.length === 0) {
    return null;
  }

  return (
    <S.TransactionSection>
      <S.Header>
        {icon}
        <p>{title}</p>
      </S.Header>
      {walletEntries.length > 0 ? (
        walletEntries.map((walletEntry, index) => (
          <WalletTransactionCard
            key={index}
            onClick={onClick}
            walletEntry={walletEntry}
          />
        ))
      ) : (
        <EmptyResults
          label={intl.formatMessage({
            defaultMessage: 'Aún no tienes transacciones',
            id: 'd+4RJp',
          })}
          description={intl.formatMessage({
            defaultMessage:
              'Aquí verás todas las entradas y salidas de dinero de tu cuenta de Higo.',
            id: 'lpBhgp',
          })}
        />
      )}
    </S.TransactionSection>
  );
}

export default TransactionSection;
