import { gql } from '@apollo/client';
import { PaymentInstructionInvoice } from '@src/types/models';

const UPDATE_PAYMENT_INSTRUCTION = gql`
  mutation UpdatePaymentInstruction(
    $businessId: ID!
    $paymentInstructionId: ID!
    $paymentInstructionInvoices: [UpdatePaymentInstructionInvoicesInput!]!
    $request: Boolean
  ) {
    updatePaymentInstruction(
      businessId: $businessId
      paymentInstructionId: $paymentInstructionId
      paymentInstructionInvoices: $paymentInstructionInvoices
      request: $request
    ) {
      id
    }
  }
`;

export type UpdatePaymentInstructionMutationReturn = {
  id: number;
};

export type UpdatePaymentInstructionMutationVariables = {
  businessId: string;
  paymentInstructionId: number;
  paymentInstructionInvoices: Array<Partial<PaymentInstructionInvoice>>;
  request?: boolean;
};

export default UPDATE_PAYMENT_INSTRUCTION;
