export const STATUS_PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE';
export const STATUS_PENDING_APPROVAL = 'PENDING_APPROVAL';
export const STATUS_ACCEPTED = 'ACCEPTED';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_CANCELED = 'CANCELED';

export const LINE_ITEM_STATUS_PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE';
export const LINE_ITEM_STATUS_ACCEPTED = 'ACCEPTED';
export const LINE_ITEM_STATUS_CLOSED = 'CLOSED';
export const LINE_ITEM_STATUS_CANCELED = 'CANCELED';
export const LINE_ITEM_STATUS_WITH_ISSUES = 'WITH_ISSUES';

export const LINE_ITEM_ORDERABLE_PRODUCT = 'PRODUCT';
export const LINE_ITEM_ORDERABLE_PRODUCT_SIMPLE = 'PRODUCT_SIMPLE';

export const STATUS_OPTIONS = [
  {
    text: 'Por Aprobar',
    value: STATUS_PENDING_APPROVAL,
  },
  {
    text: 'Por Aceptar',
    value: STATUS_PENDING_ACCEPTANCE,
  },
  {
    text: 'Aceptada',
    value: STATUS_ACCEPTED,
  },
  {
    text: 'Completada',
    value: STATUS_CLOSED,
  },
  {
    text: 'Cancelada',
    value: STATUS_CANCELED,
  },
];

export const SHIPPING_COST_TYPE_FREE = 'FREE';
export const SHIPPING_COST_TYPE_FIXED_AMOUNT = 'FIXED_AMOUNT';
export const SHIPPING_COST_TYPE_TOTAL_PERCENT = 'TOTAL_PERCENT';
