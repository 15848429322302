import { useState } from 'react';
import union from 'lodash/union';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';

interface UseTableSelectionParams {
  items: any[];
  keyName?: string;
  defaultIds?: string[];
}

const useTableSelection = ({
  items,
  keyName = 'id',
  defaultIds = [],
}: UseTableSelectionParams) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(defaultIds);

  const clearSelection = () => {
    setSelectedIds([]);
  };

  const handleSelect = (targetId) => {
    if (selectedIds.includes(targetId)) {
      setSelectedIds(selectedIds.filter((id) => id !== targetId));
    } else {
      setSelectedIds([targetId, ...selectedIds]);
    }
  };

  const hasItemsSelected = !!selectedIds.length;

  const hasAllSelected =
    selectedIds.length > 0 && selectedIds.length === items.length;

  const handleSelectAll = () => {
    const itemsPerPage = items.map((item) => item[keyName]);
    const hasAllPageSelected =
      intersection(itemsPerPage, selectedIds).length === itemsPerPage.length;

    if (hasAllPageSelected) {
      const afterRemove = difference(selectedIds, itemsPerPage);
      setSelectedIds(afterRemove);
    } else {
      setSelectedIds(union(itemsPerPage, selectedIds));
    }
  };

  return {
    hasAllSelected,
    selectedIds,
    hasItemsSelected,
    handleSelect,
    clearSelection,
    handleSelectAll,
  };
};

export default useTableSelection;
