import React from 'react';
import { useIntl } from 'react-intl';

import Table from '@src/components/shared/Table';
import { Button } from '@src/components/ui';
import PaginationControls from '@src/components/shared/PaginationControls';
import EmptyResults from '@src/components/shared/EmptyResults';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';
import useSettings from '@src/hooks/useSettings';
import useQueryParams from '@src/hooks/useQueryParams';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { ReactComponent as EmptyCreditIcon } from './assets/empty-credit.svg';
import { getTableAttributes } from './utils';
import useFinancingRequestsQuery from '../../hooks/useFinancingRequestsQuery';

function ActiveFinancingRequestsTable() {
  const intl = useIntl();

  const { business } = useSettings();

  const attributes = getTableAttributes(intl);
  const [query] = useQueryParams();

  const limit = RESULTS_PER_PAGE;

  const {
    data: activeFinancingRequests,
    loading,
    count,
    hasNext,
    next,
    hasPrevious,
    previous,
    page,
  } = useFinancingRequestsQuery({
    businessId: business?.id,
    repaid: false,
    sortBy: {
      field: 'CREATED_AT',
      direction: 'DESC',
    },
    ...getPaginationValuesForQuery(query, limit),
  });

  const hasAutomaticFinancingFunds = useFeatureFlag(
    FeatureFlag.AUTOMATIC_FINANCING_FUND
  );

  if (!loading && activeFinancingRequests.length <= 0) {
    return (
      <EmptyResults
        label={intl.formatMessage({
          defaultMessage: 'No hay ninguna disposición activa',
          id: 'zC8/8A',
        })}
        description={intl.formatMessage({
          defaultMessage:
            'Aquí verás todas tus disposiciones de crédito realizadas que se encuentren por pagar o vencidas.',
          id: 't4YegC',
        })}
        // To be uncommented when the feature of credit is enabled
        // action={
        //   hasAutomaticFinancingFunds ? (
        //     <Button variant="ghosted">
        //       {intl.formatMessage({
        //         id: 'Credit.ActiveFinancingRequestsTable.creditRequest',
        //         defaultMessage: 'Disponer crédito',
        //       })}
        //     </Button>
        //   ) : null
        // }
        icon={<EmptyCreditIcon />}
      />
    );
  }

  return (
    <>
      <Table
        loading={loading}
        keyExtractor={(financingRequest) => financingRequest.id}
        attributes={attributes}
        headers={Object.keys(attributes)}
        data={activeFinancingRequests}
        limit={limit}
        columnsWidth={[135, 150, 160, 160, 160, 160, 200, 110]}
      />
      <PaginationControls
        limit={limit}
        loading={loading}
        page={page}
        itemCount={activeFinancingRequests.length}
        count={count}
        hasNext={hasNext}
        onNext={next}
        hasPrevious={hasPrevious}
        onPrevious={previous}
      />
    </>
  );
}

export default ActiveFinancingRequestsTable;
