import React from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import InvoiceView from './InvoiceView';
import INVOICE_QUERY, {
  InvoiceReturn,
  InvoiceVariables,
} from '@src/graphql/queries/invoice';
import useSettings from '@src/hooks/useSettings';
import Page from '@src/components/shared/Page';
import Loader from '@src/components/shared/Loader';
import PageTitle from '@src/components/shared/PageTitle';
import { Alert } from '@src/components/ui';
import CreateCostCenterForm from '@src/components/pages/InvoiceView/components/CreateCostCenterForm';
import ApplyCreditNote from '@src/components/pages/InvoiceView/components/ApplyCreditNote';
import RejectInvoiceForm from '@src/components/shared/form/RejectInvoiceForm';
import Modal from '@src/components/shared/Modal';
import CreateHistoricalComment from '@src/components/pages/InvoiceView/components/CreateHistoricalComment';
import MarkAsPaid from './components/MarkAsPaid';
import EnterManualPayment from './components/EnterManualPayment';
import InvoicePayments from './components/InvoicePayments';

function InvoiceViewContainer() {
  const intl = useIntl();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { business } = useSettings();
  const { data, loading, error } = useQuery<InvoiceReturn, InvoiceVariables>(
    INVOICE_QUERY,
    {
      variables: {
        businessId: business?.id,
        invoiceId: invoiceId,
      },
      skip: !invoiceId || !business?.id,
    }
  );

  if (loading) {
    return (
      <Page
        title={intl.formatMessage({
          id: 'ARhEHP',
          defaultMessage: 'Cargando...',
        })}
      >
        <PageTitle>
          {intl.formatMessage({
            id: 'ARhEHP',
            defaultMessage: 'Cargando...',
          })}
        </PageTitle>
        <div>
          <Loader fullWidth />
        </div>
      </Page>
    );
  }
  if (error) {
    return (
      <Alert color="danger" hasBorder>
        {intl.formatMessage({
          id: 'yM0cd/',
          defaultMessage: 'Error al cargar los datos',
        })}{' '}
        {error.message}
      </Alert>
    );
  }

  if (!data?.invoice) {
    return null;
  }

  return (
    <Page
      title={intl.formatMessage(
        {
          id: '5RO01m',
          defaultMessage: 'Factura {invoiceId}',
        },
        { invoiceId: invoiceId }
      )}
    >
      <InvoiceView invoice={data.invoice} />

      <Modal component={RejectInvoiceForm} />
      <Modal component={CreateCostCenterForm} />
      <Modal component={ApplyCreditNote} />
      <Modal component={CreateHistoricalComment} />
      <Modal component={InvoicePayments} />
      <Modal component={MarkAsPaid} zIndex={50} />
      <Modal component={EnterManualPayment} zIndex={50} />
    </Page>
  );
}

export default InvoiceViewContainer;
