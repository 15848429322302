import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './WalletView.styles';
import Card from '@src/components/ui/Card';
import Label from '@src/components/ui/Label';
import { Clock, Transaction } from '@src/components/ui/Icon/outline';

import type { WalletEntry } from '@src/types/models';
import TransactionSection from './components/TransactionSection';
import useTransactionsListQuery from '@src/hooks/useTransactionsListQuery';
import useSettings from '@src/hooks/useSettings';
import useModal from '@src/hooks/useModal';
import WalletTransactionModal from '@src/components/pages/WalletView/components/WalletTransactionModal/WalletTransactionModal';
import LinkButton from '@src/components/shared/buttons/LinkButton';

const WalletTransactions = () => {
  const intl = useIntl();
  const business = useSettings('business');
  const { open } = useModal(WalletTransactionModal);
  const {
    loading: pendingLoading,
    data: pendingData,
  } = useTransactionsListQuery({
    first: 5,
    businessId: business?.id,
    pending: true,
  });
  const { loading: allLoading, data: allData } = useTransactionsListQuery({
    first: 5,
    businessId: business?.id,
    pending: false,
  });

  const handleTransactionClick = (walletEntry: WalletEntry) => {
    open({
      walletEntry: walletEntry,
    });
  };

  return (
    <>
      <Card padding="8">
        <S.RowContainer>
          <Label strong size="md">
            {intl.formatMessage({
              defaultMessage: 'Transacciones',
              id: 'Aqopvj',
            })}
          </Label>
          <LinkButton compact variant="ghosted" href="/transactions">
            {intl.formatMessage({
              defaultMessage: 'Ver todo',
              id: '6q+3v5',
            })}
          </LinkButton>
        </S.RowContainer>
        <S.CardContainer>
          <TransactionSection
            hideOnEmpty
            loading={pendingLoading}
            walletEntries={pendingData}
            onClick={handleTransactionClick}
            icon={<Clock size={16} />}
            title={intl.formatMessage({
              defaultMessage: 'Pendientes',
              id: '5anEzm',
            })}
          />
          <TransactionSection
            loading={allLoading}
            walletEntries={allData}
            onClick={handleTransactionClick}
            icon={<Transaction size={16} />}
            title={intl.formatMessage({
              defaultMessage: 'Historial',
              id: 'zYkReX',
            })}
          />
        </S.CardContainer>
      </Card>
    </>
  );
};

export default WalletTransactions;
