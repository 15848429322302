import styled from 'styled-components';
import Logo from '@src/components/shared/Logo';

export const StyledLogo = styled(Logo)`
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing[20]};
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing[6]};
  }
`;

export const StepContainer = styled.div<{ stepActive?: boolean }>`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: ${({ theme }) => theme.spacing[4]};
  color: ${({ stepActive, theme }) =>
    stepActive ? theme.colors.neutral[900] : theme.colors.neutral[500]};

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
  }
`;

export const StepTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.sm};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  @media (max-width: 767px) {
    text-align: center;
    margin-bottom: 0;
    padding-bottom: ${({ theme }) => theme.spacing[5]};
  }
`;

export const StepText = styled.p`
  font-weight: normal;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  white-space: break-spaces;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const StepDescription = styled.div`
  padding: 0 ${({ theme }) => theme.spacing[5]};
  max-width: ${({ theme }) => theme.spacing[72]};
`;

export const StepIconContainer = styled.div`
  span {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: 0;
  transform: translateX(50%);
  @media (max-width: 767px) {
    position: relative;
    position: absolute;
    left: -${({ theme }) => theme.spacing[3]};
    top: -${({ theme }) => theme.spacing[3]};
  }
`;
