import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './ConfirmationContractModal.styles';
// import { Download } from '@src/components/ui/Icon/outline';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { Button, Header, Text } from '@src/components/ui';

interface ConfirmationContractProps {
  onSubmit: () => void;
  onClose: () => void;
}

function ConfirmationContract({
  onSubmit,
  onClose,
}: ConfirmationContractProps) {
  const intl = useIntl();

  const handleSubmit = () => {
    onClose();
    onSubmit();
  };

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Autorización y confirmación',
          id: 'Pn38a9',
        })}
      </Header>
      <Spacer margin="3" />
      <Text colorStep="600">
        {intl.formatMessage({
          defaultMessage:
            'Una vez que acepte la transacción, autorizará y confirmará los términos del contrato de factoraje generado para avanzar sus facturas.',
          id: 'nnfmJ8',
        })}
      </Text>
      {/* <Spacer margin="4" /> */}
      {/* <Button variant="transparent" endIcon={<Download />}>
        {intl.formatMessage({
          id: 'ConfirmationContract.downloadContract',
          defaultMessage: 'Descargar contrato',
        })}
      </Button> */}
      <Spacer margin="12" />
      <FlexContainer justifyContent="flex-end">
        <Button color="neutral" variant="outlined" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" margin="5" />
        <Button onClick={handleSubmit}>
          {intl.formatMessage({
            defaultMessage: 'Aceptar y adelantar',
            id: 'qd1vnX',
          })}
        </Button>
      </FlexContainer>
    </S.Container>
  );
}

ConfirmationContract.displayName = 'ConfirmationContract';

export default ConfirmationContract;
