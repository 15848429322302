import React, { forwardRef, useState } from 'react';

import Input from '@src/components/ui/Input';
import Icon from '@src/components/ui/Icon';
import type { PasswordInputProps } from './types';

function PasswordInput(props: PasswordInputProps, ref) {
  const [type, setType] = useState<string>('password');

  return (
    <Input
      {...props}
      ref={ref}
      type={type}
      endIcon={
        <Icon
          size={16}
          name={type === 'password' ? 'Eye' : 'EyeOff'}
          onClick={() => {
            setType(type === 'password' ? 'text' : 'password');
          }}
        />
      }
    />
  );
}

export default forwardRef(PasswordInput);
