import React from 'react';

import * as S from './ProductImage.styles';
import { Package } from '@src/components/ui/Icon/outline';

interface ProductImageProps {
  url?: string;
  smaller?: boolean;
}

function ProductImage({ url, smaller = false }: ProductImageProps) {
  return (
    <S.ImageContainer small={smaller}>
      {url ? (
        <S.Image src={url} alt="img" />
      ) : (
        <Package size={smaller ? 18 : 32} />
      )}
    </S.ImageContainer>
  );
}

export default ProductImage;
