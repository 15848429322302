import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import * as S from './CreateSupplierContactModal.styles';
import messages from './messages';
import { Header, TextField, Button } from '@src/components/ui';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import {
  UPDATE_PROVIDER_CONTACT as UPDATE_PROVIDER_CONTACT_EVENT,
  CREATE_PROVIDER_CONTACT as CREATE_PROVIDER_CONTACT_EVENT,
} from '@src/constants/events';
import { useMutation } from '@apollo/client';
import {
  CREATE_PROVIDER_CONTACT,
  UPDATE_PROVIDER_CONTACT,
} from '@src/graphql/mutations';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';

export const eventData = {
  creator: 'buyer',
  view: 'CreateSupplierContactModal',
};

type CreateSupplierContactModalProps = {
  initialValues?: {
    id: string;
    defaultContact: boolean;
    email: string;
    firstName: string;
    lastName: string;
  };
  businessRelationshipId: string;
  onClose?: () => void;
};

export function CreateSupplierContactModal(
  props: CreateSupplierContactModalProps
) {
  const { onClose, initialValues, businessRelationshipId } = props;
  const intl = useIntl();
  const { toast } = useToast();
  const [defaultContact, setDefaultContact] = useState(
    initialValues?.defaultContact || false
  );
  const business = useSettings('business');
  const contactId = initialValues?.id;
  const [createProviderContact, { loading: createLoading }] = useMutation(
    CREATE_PROVIDER_CONTACT,
    {
      refetchQueries: [SUPPLIER_RELATIONSHIP_QUERY_NAME],
      awaitRefetchQueries: true,
      onCompleted: () => {
        trackEvent(CREATE_PROVIDER_CONTACT_EVENT, eventData);
        onClose && onClose();
        toast.success(
          intl.formatMessage(messages.createProviderContactSuccessTitle),
          intl.formatMessage(messages.createProviderContactMessage)
        );
      },
      onError: (error) => {
        toast.error(
          intl.formatMessage(messages.createProviderContactError),
          error.message
        );
      },
    }
  );

  const [updateProviderContact, { loading: updateLoading }] = useMutation(
    UPDATE_PROVIDER_CONTACT,
    {
      refetchQueries: [SUPPLIER_RELATIONSHIP_QUERY_NAME],
      awaitRefetchQueries: true,
      onCompleted: () => {
        trackEvent(UPDATE_PROVIDER_CONTACT_EVENT, eventData);
        onClose && onClose();
        toast.success(
          intl.formatMessage(messages.updateProviderContactSuccessTitle),
          intl.formatMessage(messages.updateProviderContactMessage)
        );
      },
      onError: (error) => {
        toast.error(
          intl.formatMessage(messages.updateProviderContactError),
          error.message
        );
      },
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: initialValues?.firstName,
      lastName: initialValues?.lastName,
      email: initialValues?.email,
    },
  });

  const handleChange = () => {
    if (!defaultContact) {
      setDefaultContact(true);
    }
  };

  const onSubmit = (data: {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string;
  }) => {
    const hasBusinessIds = business?.id && businessRelationshipId;

    if (hasBusinessIds && initialValues?.id) {
      updateProviderContact({
        variables: {
          businessId: business?.id,
          businessRelationshipId,
          providerContactId: initialValues.id,
          providerContact: {
            ...data,
            default: defaultContact,
          },
        },
      });
    } else if (hasBusinessIds) {
      createProviderContact({
        variables: {
          businessId: business?.id,
          businessRelationshipId,
          providerContact: {
            ...data,
          },
        },
      });
    }
  };

  return (
    <S.Wrapper>
      <Header as="h5">
        {contactId
          ? intl.formatMessage({
              defaultMessage: 'Editar contacto',
              id: 'X4EJZe',
            })
          : intl.formatMessage({
              defaultMessage: 'Agregar contacto',
              id: 'NWpbar',
            })}
      </Header>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          type="text"
          {...register('firstName')}
          label={intl.formatMessage(messages.contactNamelabel)}
          placeholder={intl.formatMessage(messages.contactNamelabel)}
          error={!!errors?.firstName?.message}
          helperText={errors?.firstName?.message}
        />
        <TextField
          fullWidth
          type="text"
          {...register('lastName')}
          label={intl.formatMessage(messages.contactLastNamelabel)}
          placeholder={intl.formatMessage(messages.contactLastnamePlaceholder)}
          error={!!errors?.lastName?.message}
          helperText={errors?.lastName?.message}
        />
        <TextField
          fullWidth
          type="email"
          required
          {...register('email', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={'Email'}
          placeholder={intl.formatMessage(messages.contactEmailPlaceholder)}
          error={!!errors?.email?.message}
          helperText={errors?.email?.message}
        />

        <S.Actions>
          <Button variant="outlined" color="neutral" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: 'Cancelar',
              id: 'nZLeaQ',
            })}
          </Button>
          <Button type="submit" loading={createLoading || updateLoading}>
            {intl.formatMessage({
              defaultMessage: 'Guardar',
              id: 'oN4aGu',
            })}
          </Button>
        </S.Actions>
      </S.Form>
    </S.Wrapper>
  );
}

CreateSupplierContactModal.displayName = 'CreateSupplierContactModal';

export default CreateSupplierContactModal;
