import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import * as S from './SupplierBankingInformationModal.styles';
import Header from '@src/components/ui/Header';
import usePageTrack from '@src/hooks/usePageTrack';
import useSettings from '@src/hooks/useSettings';
import BankingInformationForm from '@src/components/shared/form/BankingInformationForm';
import { UPDATE_SUPPLIER_BANKING_INFO } from '@src/graphql/mutations';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import {
  UpdateSupplierBankingInfoVariables,
  UpdateSupplierBankingInfoReturn,
} from '@src/graphql/mutations/updateSupplierBankingInfo';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';
import useToast from '@src/hooks/useToast';
import type { ModalChildrenProps } from '@src/components/shared/Modal';

import { BankingInfo } from '@src/types/models';

type SupplierBankingInformationModalProps = ModalChildrenProps & {
  bankingInfoId?: string;
  bankId?: string;
  clabe?: string;
  recipient?: string;
  businessRelationshipId: string;
  onSuccess?: (
    values?: Pick<
      BankingInfo,
      'id' | 'bankId' | 'bankName' | 'clabe' | 'recipient'
    >
  ) => void;
};
function SupplierBankingInformationModal({
  businessRelationshipId,
  recipient,
  clabe,
  bankId,
  onClose,
  onSuccess,
}: SupplierBankingInformationModalProps) {
  usePageTrack('supplier_banking_information_modal', 'core');
  const intl = useIntl();
  const business = useSettings('business');
  const { toast } = useToast();

  const [mutate, { loading }] = useMutation<
    UpdateSupplierBankingInfoReturn,
    UpdateSupplierBankingInfoVariables
  >(UPDATE_SUPPLIER_BANKING_INFO, {
    refetchQueries: [INVOICE_QUERY, SUPPLIER_RELATIONSHIP_QUERY_NAME],
    awaitRefetchQueries: true,
  });

  const handleUpdateSupplierBankingInfo = async ({
    bankId,
    clabe,
    recipient: taxpayerName,
  }: {
    bankId: string;
    clabe: string;
    recipient?: string;
  }) => {
    if (business?.id) {
      try {
        const bankingInfo = {
          bankId,
          clabe: clabe?.replace(/\s/g, ''),
          recipient: taxpayerName || recipient,
        };

        const response = await mutate({
          variables: {
            businessId: business.id,
            businessRelationshipId,
            bankingInfo,
          },
        });

        onSuccess && onSuccess(response?.data?.updateSupplierBankingInfo);
        onClose && onClose();
      } catch (e: any) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Hubo un problema al agregar la cuenta',
            id: 'SjTg2l',
          }),
          e.message
        );
      }
    }
  };

  const defaultValues = {
    clabe,
    bankId,
    recipient,
  };

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Información Bancaria',
          id: 'UN2Mj9',
        })}
      </Header>

      <S.Body>
        {intl.formatMessage({
          defaultMessage:
            'Agrega la información bancaria de tu proveedor para realizar pagos.',
          id: 'uv9gNI',
        })}
      </S.Body>

      <BankingInformationForm
        onCancel={onClose}
        onSubmit={handleUpdateSupplierBankingInfo}
        loading={loading}
        defaultValues={defaultValues}
      />
    </S.Container>
  );
}

SupplierBankingInformationModal.displayName = 'SupplierBankingInformationModal';

export default SupplierBankingInformationModal;
