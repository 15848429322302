import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import BigNumber from 'bignumber.js';

import InvoiceLineItemsTable from './InvoiceLineItemsTable';
import * as S from './Invoice.styles';
import mexicanMessages from './mexicanInvoiceMessages';
import { addressString } from '@src/utils/address';
import OrderDetails from '@src/components/shared/Invoice/components/OrderDetails';
import { hasListoIntegration } from '@src/utils/integrations';
import GroupValueField from '@src/components/shared/Invoice/components/GroupValueField';
import InvoiceStatusBadge from '@src/components/shared/InvoiceStatusBadge';
import InvoiceGlobalStatusBadge from '@src/components/shared/InvoiceGlobalStatusBadge';
import EmissionStatusBadge from '@src/components/shared/EmissionStatusBadge';
import FormattedDate, {
  defaultTimeFormat,
} from '@src/components/i18n/FormattedDate';
import useSettings from '@src/hooks/useSettings';
import { Grid, Spacer } from '../layouts';
import { Divider, Text } from '../../ui';

import { Invoice as InvoiceType } from '@src/types/models';
import {
  InvoiceApprovalStatus,
  InvoiceVoucherType,
  InvoiceGlobalStatus,
} from '@src/types/enums';

interface InvoiceProps {
  invoice: Pick<
    InvoiceType,
    | 'currency'
    | 'invoicingDate'
    | 'approvalStatus'
    | 'emissionStatus'
    | 'invoiceLineItems'
    | 'globalStatus'
    | 'expiryDate'
    | 'foreignTotal'
    | 'voucherType'
    | 'businessEmitter'
    | 'fiscalIdentifier'
    | 'emitterTaxpayerName'
    | 'emitterTaxpayerIdentifier'
    | 'receiverTaxpayerName'
    | 'receiverTaxpayerAddress'
    | 'receiverTaxpayerIdentifier'
    | 'receiverCountryTaxPayerInfo'
    | 'valueAddedTaxAmount'
    | 'valueAddedRetainedTaxAmount'
    | 'iepsRetainedAmount'
    | 'iepsAmount'
    | 'isrAmount'
    | 'subtotal'
    | 'total'
  >;
}

export function Invoice({ invoice }: InvoiceProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const {
    currency,
    invoicingDate,
    approvalStatus,
    emissionStatus,
    invoiceLineItems,
    globalStatus,
    expiryDate,
    foreignTotal,
    voucherType,
    businessEmitter,
    fiscalIdentifier,
    emitterTaxpayerName,
    emitterTaxpayerIdentifier,
    receiverTaxpayerName,
    receiverTaxpayerAddress,
    receiverTaxpayerIdentifier,
    receiverCountryTaxPayerInfo,
    valueAddedTaxAmount,
    valueAddedRetainedTaxAmount,
    iepsRetainedAmount,
    iepsAmount,
    isrAmount,
    subtotal,
    total,
  } = invoice;

  const totals = useMemo(() => {
    if (!invoice) {
      return { totalPrice: 0, taxes: 0, quantity: 0 };
    }

    const { invoiceLineItems } = invoice;
    const totals = invoiceLineItems
      ? invoiceLineItems.map((lineItem) => {
          const {
            quantity = 0,
            pricePerUnit = 0,
            valueAddedTaxPercent = 0,
            total = 0,
          } = lineItem;
          return {
            totalPrice: total,
            taxes: quantity * pricePerUnit * valueAddedTaxPercent,
            quantity: quantity,
          };
        })
      : [];

    const totalPrice = totals.reduce((acc, total) => acc + total.totalPrice, 0);
    const taxes = totals.reduce((acc, total) => acc + total.taxes, 0);
    const quantity = totals.reduce(
      (acc, total) => acc + new BigNumber(total.quantity).toNumber(),
      0
    );

    return {
      totalPrice,
      taxes,
      quantity,
    };
  }, [invoice]);

  let receiverBillingAddress = '';

  if (receiverTaxpayerAddress) {
    receiverBillingAddress =
      typeof receiverTaxpayerAddress === 'object'
        ? addressString(receiverTaxpayerAddress)
        : receiverTaxpayerAddress;
  }

  const mexicanTaxPayerInfo =
    receiverCountryTaxPayerInfo?.__typename === 'MexicanTaxPayerInfo';

  return (
    <S.Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GroupValueField
            label={intl.formatMessage({
              id: '8BXj7L',
              defaultMessage: 'Emisor',
            })}
            value={
              <div>
                {emitterTaxpayerName ? (
                  <Text>{emitterTaxpayerName}</Text>
                ) : (
                  <Text>
                    {intl.formatMessage({
                      id: 'lYRhl0',
                      defaultMessage: 'Dato no confirmado',
                    })}
                  </Text>
                )}
              </div>
            }
          />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent="flex-end">
          {approvalStatus === InvoiceApprovalStatus.PENDING ||
          (voucherType === InvoiceVoucherType.CREDIT_NOTE &&
            globalStatus !== InvoiceGlobalStatus.CLOSED) ? (
            <InvoiceStatusBadge status={approvalStatus} />
          ) : (
            <InvoiceGlobalStatusBadge
              expiryDate={expiryDate}
              status={globalStatus}
            />
          )}
          {emissionStatus &&
            businessEmitter &&
            business &&
            hasListoIntegration(business) &&
            business?.id === businessEmitter?.id && (
              <EmissionStatusBadge status={emissionStatus} />
            )}
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GroupValueField
            label={intl.formatMessage({
              id: 'QFfq5c',
              defaultMessage: 'RFC/Tax ID',
            })}
            value={
              <p>
                {emitterTaxpayerIdentifier ||
                  intl.formatMessage({
                    id: 'lYRhl0',
                    defaultMessage: 'Dato no confirmado',
                  })}
              </p>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GroupValueField
            label={intl.formatMessage({
              id: 'f4nC1Y',
              defaultMessage: 'Fecha de emisión',
            })}
            value={
              <p>
                <FormattedDate value={invoicingDate} {...defaultTimeFormat} />
              </p>
            }
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GroupValueField
            label={intl.formatMessage({
              id: 'SBf4tJ',
              defaultMessage: 'Receptor',
            })}
            value={
              <Text>
                {receiverTaxpayerName ||
                  intl.formatMessage({
                    id: 'lYRhl0',
                    defaultMessage: 'Dato no confirmado',
                  })}
              </Text>
            }
          />
        </Grid>
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GroupValueField
            label={intl.formatMessage({
              id: 'QFfq5c',
              defaultMessage: 'RFC/Tax ID',
            })}
            value={<p>{receiverTaxpayerIdentifier}</p>}
          />
        </Grid>

        {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.fiscalUuid ? (
          <Grid item xs={12} md={6}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'eGPpeT',
                defaultMessage: 'Folio fiscal',
              })}
              value={<p>{receiverCountryTaxPayerInfo.fiscalUuid}</p>}
            />
          </Grid>
        ) : fiscalIdentifier ? (
          <Grid item xs={12} md={6}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'eGPpeT',
                defaultMessage: 'Folio fiscal',
              })}
              value={<p>{fiscalIdentifier}</p>}
            />
          </Grid>
        ) : null}
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.cfdiUsage && (
          <Grid item xs={12} md={6}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'sbLOKH',
                defaultMessage: 'Uso CFDI',
              })}
              value={
                <p>
                  {receiverCountryTaxPayerInfo?.cfdiUsage &&
                    intl.formatMessage(
                      mexicanMessages[receiverCountryTaxPayerInfo.cfdiUsage]
                    )}
                </p>
              }
            />
          </Grid>
        )}
        {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.folio && (
          <Grid item xs={12} md={6}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'x+TaH9',
                defaultMessage: 'Folio',
              })}
              value={<p>{receiverCountryTaxPayerInfo.folio}</p>}
            />
          </Grid>
        )}
      </Grid>

      <Spacer />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <GroupValueField
            label={intl.formatMessage({
              id: 'ZC1yHf',
              defaultMessage: 'Dirección de facturación',
            })}
            value={
              <p>
                {receiverBillingAddress ||
                  intl.formatMessage({
                    id: 'q0dKJ6',
                    defaultMessage: 'Aún no hay datos disponibles',
                  })}
              </p>
            }
          />
        </Grid>

        {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.voucherType && (
          <Grid item xs={12} md={6} lg={4}>
            <GroupValueField
              label={intl.formatMessage({
                id: '3RraGS',
                defaultMessage: 'Efecto de comprobante',
              })}
              value={
                <p>
                  {receiverCountryTaxPayerInfo?.voucherType &&
                    intl.formatMessage(
                      mexicanMessages[receiverCountryTaxPayerInfo.voucherType]
                    )}
                </p>
              }
            />
            <Spacer />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.regime && (
          <Grid item xs={12} md={6}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'WB0/JJ',
                defaultMessage: 'Régimen fiscal',
              })}
              value={
                <p>
                  {receiverCountryTaxPayerInfo?.regime &&
                    mexicanMessages[
                      mexicanMessages[receiverCountryTaxPayerInfo.regime]
                    ]}
                </p>
              }
            />
          </Grid>
        )}
      </Grid>
      <Spacer />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InvoiceLineItemsTable
            invoiceLineItems={invoiceLineItems || []}
            currency={currency}
          />
        </Grid>
      </Grid>

      <Spacer margin="16" />

      <Grid container spacing={2}>
        <Spacer />
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <GroupValueField
                label={intl.formatMessage({
                  id: 'xOX686',
                  defaultMessage: 'Moneda',
                })}
                value={<p>{currency}</p>}
              />
            </Grid>
            {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.paymentMethod && (
              <Grid item xs={12} md={6}>
                <GroupValueField
                  label={intl.formatMessage({
                    id: '6yAF7Y',
                    defaultMessage: 'Método de pago',
                  })}
                  value={
                    <p>
                      {receiverCountryTaxPayerInfo.paymentMethod &&
                        intl.formatMessage(
                          mexicanMessages[
                            receiverCountryTaxPayerInfo.paymentMethod
                          ]
                        )}
                    </p>
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {mexicanTaxPayerInfo && receiverCountryTaxPayerInfo?.paymentForm && (
              <Grid item xs={12} md={6}>
                <GroupValueField
                  label={intl.formatMessage({
                    id: 'GPQRPG',
                    defaultMessage: 'Forma de pago',
                  })}
                  value={
                    <p>
                      {receiverCountryTaxPayerInfo.paymentForm &&
                        intl.formatMessage(
                          mexicanMessages[
                            receiverCountryTaxPayerInfo.paymentForm
                          ]
                        )}
                    </p>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Spacer />
        <Grid item xs={12} md={6}>
          <OrderDetails
            showNote={false}
            totals={totals}
            valueAddedTaxAmount={valueAddedTaxAmount}
            valueAddedRetainedTaxAmount={valueAddedRetainedTaxAmount}
            iepsRetainedAmount={iepsRetainedAmount}
            iepsAmount={iepsAmount}
            isrAmount={isrAmount}
            currency={currency}
            foreignTotal={foreignTotal}
            subtotal={subtotal}
            total={total}
            isInvoice
            quantityType={'requestedQuantity'}
            invoiceDetail
            lineItems={invoiceLineItems as any}
          />
        </Grid>
      </Grid>

      <Spacer margin="8" />
      <Divider />

      {mexicanTaxPayerInfo && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'gOfgAM',
                defaultMessage: 'Sello digital del CFDI',
              })}
              value={
                <S.Chain>
                  {receiverCountryTaxPayerInfo?.cfdiDigitalStamp ||
                    intl.formatMessage({
                      id: 'q0dKJ6',
                      defaultMessage: 'Aún no hay datos disponibles',
                    })}
                </S.Chain>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'WO/04M',
                defaultMessage: 'Sello digital del SAT',
              })}
              value={
                <S.Chain>
                  {receiverCountryTaxPayerInfo?.satStamp ||
                    intl.formatMessage({
                      id: 'q0dKJ6',
                      defaultMessage: 'Aún no hay datos disponibles',
                    })}
                </S.Chain>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <GroupValueField
              label={intl.formatMessage({
                id: 'aZ8xA6',
                defaultMessage:
                  'Cadena original del complemento de certificación digital del SAT',
              })}
              value={
                <S.Chain>
                  {receiverCountryTaxPayerInfo?.cfdiOriginalChain ||
                    intl.formatMessage({
                      id: 'q0dKJ6',
                      defaultMessage: 'Aún no hay datos disponibles',
                    })}
                </S.Chain>
              }
            />
          </Grid>
        </Grid>
      )}
    </S.Container>
  );
}

export default Invoice;
