import { gql } from '@apollo/client';
import type { BankingInfo } from '@src/types/models';

export const UPDATE_SUPPLIER_BANKING_INFO = gql`
  mutation UpdateSupplierBankingInfo(
    $businessId: ID!
    $businessRelationshipId: ID!
    $bankingInfo: BankingInfoInput!
  ) {
    updateSupplierBankingInfo(
      businessId: $businessId
      businessRelationshipId: $businessRelationshipId
      bankingInfo: $bankingInfo
    ) {
      id
      bankId
      bankName
      clabe
      recipient
      bankLogoUrl
    }
  }
`;

export type BankingInfoInput = {
  id?: string;
  clabe?: string;
  recipient?: string;
  bankId?: string;
};

export type UpdateSupplierBankingInfoVariables = {
  businessId: string;
  businessRelationshipId: string;
  bankingInfo: BankingInfoInput;
};

export type UpdateSupplierBankingInfoReturn = {
  updateSupplierBankingInfo: Pick<
    BankingInfo,
    'id' | 'bankId' | 'bankName' | 'clabe' | 'recipient'
  >;
};
