import React from 'react';
import { useIntl } from 'react-intl';

import { Button, Text, Card, Header } from '@src/components/ui';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';
import TrustedPartnerLayout from '@src/components/layouts/TrustedPartnerLayout';
import { FlexContainer, Grid, Spacer } from '@src/components/shared/layouts';

import { ReactComponent as Graphic } from './img/invalid_token.svg';

const InvalidToken = () => {
  const intl = useIntl();
  const openCustomerSupportChat = useCustomerSupportChat();
  return (
    <TrustedPartnerLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={5}>
          <Card padding="8" depth="sm">
            <FlexContainer
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Graphic />
              <Spacer margin="6" />
              <Header as="h5">
                {intl.formatMessage({
                  defaultMessage: 'Este link de pago ha expirado.',
                  id: 'O8Pofw',
                })}
              </Header>
              <Text color="neutral" colorStep="600">
                {intl.formatMessage({
                  defaultMessage:
                    'Si necesitas ayuda contáctanos haciendo click en el botón de abajo',
                  id: 'U4pJLD',
                })}
              </Text>
            </FlexContainer>
            <Spacer margin="6" />
            <Button block onClick={openCustomerSupportChat}>
              {intl.formatMessage({
                defaultMessage: 'Contactar soporte',
                id: '6tofF3',
              })}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </TrustedPartnerLayout>
  );
};

export default InvalidToken;
