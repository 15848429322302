import styled from 'styled-components';

import PageUI from '@src/components/shared/Page';

export const Page = styled(PageUI)`
  padding: 0;
  margin: 0;
  display: flex;

  @media (max-width: 767px) {
    margin: 0;
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing[8]} 0 ${theme.spacing[16]} ${theme.spacing[8]}`};
  background: ${({ theme }) => theme.colors.neutral[50]};
  width: 25rem;
  height: 100vh;
  flex-shrink: 0;

  @media (max-width: 767px) {
    height: auto;
    width: 100vw;
    padding: ${({ theme }) => theme.spacing[6]} 0 0 0;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[8]};

  @media (max-width: 767px) {
    padding-top: ${({ theme }) => theme.spacing[12]};
  }
`;
