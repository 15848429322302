import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Modal from '@src/components/shared/Modal';
import ProcessingLoader from '@src/components/shared/ProcessingLoaderPage';
import Page from '@src/components/shared/Page';
import InvoicePaymentForm from './components/InvoicePaymentForm';
import RequestInstructionForm from './components/RequestInstructionForm';
import SupplierBankingInformation from '@src/components/shared/modals/SupplierBankingInformationModal';
import useToast from '@src/hooks/useToast';
import useQueryParams from '@src/hooks/useQueryParams';
import InvoicePaymentResults from './components/InvoicePaymentResults';
import usePageTrack from '@src/hooks/usePageTrack';
import userIsAdmin from '@src/utils/roles/userIsAdmin';
import useSettings from '@src/hooks/useSettings';
import { PaymentInstruction } from '@src/types/models';
import PaymentInstructionReadOnly from './components/PaymentInstructionReadOnly';

function InvoicePayment() {
  const intl = useIntl();
  const [
    { invoiceIds, mode, payment_instruction_id: paymentInstructionId },
  ] = useQueryParams();
  const { currentUser } = useSettings();
  const history = useHistory();
  const { toast } = useToast();
  const isAdmin = userIsAdmin(currentUser);
  const paymentInstruction = (history.location.state as {
    paymentInstruction?: PaymentInstruction;
  })?.paymentInstruction;

  const isReadMode = mode === 'read';

  usePageTrack('invoice_payment', 'core');

  useEffect(() => {
    if (!invoiceIds || invoiceIds.length === 0) {
      history.push('/');
      toast.info(
        intl.formatMessage({
          defaultMessage: 'Necesitas seleccionar al menos una factura',
          id: 'cDCAg7',
        })
      );
    }
  }, [invoiceIds]);

  if (!invoiceIds || invoiceIds.length === 0) {
    return null;
  }

  if (paymentInstruction && isReadMode) {
    return (
      <Page
        title={intl.formatMessage({
          defaultMessage: 'Detalles de solicitud',
          id: 'Ug15nO',
        })}
      >
        <PaymentInstructionReadOnly
          invoiceIds={invoiceIds}
          paymentInstruction={paymentInstruction}
        />
      </Page>
    );
  }

  if (isAdmin) {
    return (
      <Page
        title={intl.formatMessage({
          defaultMessage: 'Revisar y pagar',
          id: 'cUuhwH',
        })}
      >
        <InvoicePaymentForm
          invoiceIds={invoiceIds}
          paymentInstructionId={paymentInstructionId}
          paymentInstruction={paymentInstruction}
        />

        <Modal component={SupplierBankingInformation} />
        <Modal type="fullscreen" component={ProcessingLoader} zIndex={40} />
        <Modal
          type="fullscreen"
          component={InvoicePaymentResults}
          zIndex={50}
        />
      </Page>
    );
  }

  return (
    <Page
      title={
        paymentInstructionId
          ? intl.formatMessage({
              defaultMessage: 'Detalles de solicitud',
              id: 'Ug15nO',
            })
          : intl.formatMessage({
              defaultMessage: 'Solicitar aprobación de pago',
              id: 'S3KdQW',
            })
      }
    >
      <RequestInstructionForm
        invoiceIds={invoiceIds}
        paymentInstructionId={paymentInstructionId}
        paymentInstruction={paymentInstruction}
      />

      <Modal component={SupplierBankingInformation} />
    </Page>
  );
}

export default InvoicePayment;
