import { defineMessages } from 'react-intl';

const messages = defineMessages({
  INVOICES_BUYER: {
    defaultMessage: 'Cuentas por pagar',
    id: 'y0s+Vr',
  },
  INVOICES_BUYER_DESCRIPTION: {
    defaultMessage:
      'Ten un solo lugar para recibir. aprobar y administrar todas las facturas que tus proveedores te han emitido de la manera más fácil y rápida.',
    id: 'R72YKf',
  },
  INVOICES_PROVIDER: {
    defaultMessage: 'Cuentas por cobrar',
    id: 'qiuiRz',
  },
  INVOICES_PROVIDER_DESCRIPTION: {
    defaultMessage:
      'Ten una visualización completa de tus cuentas por cobrar, mantén la información de cuándo y cuánto deben pagarte tus clientes.',
    id: 'DuE1n8',
  },
  PROCUREMENT: {
    defaultMessage: 'Compras',
    id: 'PGM262',
  },
  PROCUREMENT_DESCRIPTION: {
    defaultMessage:
      'Genera automáticamente los catálogos de tus proveedores. Crea requisiciones y sencillos flujos de aprobación para hacer órdenes a todos tus proveedores.',
    id: 'KmHV9F',
  },
  SALES: {
    defaultMessage: 'Ventas',
    id: 'FJ1JrW',
  },
  SALES_DESCRIPTION: {
    defaultMessage:
      'Aumenta tus ventas. Crea tu catálogo, administra tus listas de precios y recibe las órdenes de tus clientes.',
    id: 'VDW9Zg',
  },
  ACCOUNTS_PAYABLE: {
    defaultMessage: 'Cuentas por pagar',
    id: 'y0s+Vr',
  },
  ACCOUNTS_RECEIVABLE: {
    defaultMessage: 'Cuentas por cobrar',
    id: 'qiuiRz',
  },
  SHARED_PRODUCTS: {
    defaultMessage: 'Productos',
    id: 'nHcysl',
  },
  MANUAL_PURCHASE_ORDER: {
    defaultMessage: 'Compra manual',
    id: 'JDJGzr',
  },
  PURCHASE_LISTS: {
    defaultMessage: 'Lista de compra',
    id: 'vYMICO',
  },
  PURCHASE_ORDER: {
    defaultMessage: 'Órdenes de compra',
    id: '3WXpTI',
  },
  MANUAL_SALES_ORDER: {
    defaultMessage: 'Venta manual',
    id: 'XFDszc',
  },
  PRICE_LISTS: {
    defaultMessage: 'Lista de precios',
    id: '47LnNu',
  },
  SALES_ORDER: {
    defaultMessage: 'Órdenes de venta',
    id: 'rQ9Wa7',
  },
  updateBusinessFeatureGroupsSuccessTitle: {
    defaultMessage: 'Módulos configurados',
    id: 'PsmV1y',
  },
  updateBusinessFeatureGroupsSuccessMessages: {
    defaultMessage: 'Se han configurado los módulos de tu suite.',
    id: '4nGAGf',
  },
  updateBusinessFeatureGroupsError: {
    defaultMessage: 'Ha ocurrido un error',
    id: 'DVO6hk',
  },
  notModulesSelectedTitleError: {
    defaultMessage: 'Error al actualizar',
    id: 'mtahgR',
  },
  notModulesSelectedTitleMessage: {
    defaultMessage: 'Debes tener al menos un módulo seleccionado,',
    id: '1YnPRz',
  },
});

export default messages;
