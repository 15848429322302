import React from 'react';
import { useIntl } from 'react-intl';

import ModalHeader from '@src/components/ui/ModalHeader';
import WalletBalance from '@src/components/shared/WalletBalance';
import useSettings from '@src/hooks/useSettings';
import { FinancingRequest } from '@src/types/models';
import PayFinancingForm from '../PayFinancingForm';
import * as S from './PayFinancingModal.styles';

interface PayFinancingModalProps {
  onClose?: () => void;

  /**
   * Financing request to be preselected
   */
  defaultRequest?: FinancingRequest;
}

function PayFinancingModal({
  onClose,
  defaultRequest,
}: PayFinancingModalProps) {
  const intl = useIntl();
  const business = useSettings('business');

  return (
    <>
      <ModalHeader
        title={intl.formatMessage({
          defaultMessage: 'Pagar crédito',
          id: 'UKACVk',
        })}
        onClose={onClose}
        actions={<WalletBalance />}
        sticky
      />

      <S.ModalContainer>
        <PayFinancingForm
          defaultRequest={defaultRequest}
          onSubmit={onClose}
          business={business}
        />
      </S.ModalContainer>
    </>
  );
}

PayFinancingModal.displayName = 'PayFinancingModal';

export default PayFinancingModal;
