import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import useSettings from '@src/hooks/useSettings';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import Filters from '@src/components/shared/Filters';
import Card from '@src/components/ui/Card';
import InvoicesTable from '@src/components/shared/InvoicesTable';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import EmptyResults from '@src/components/shared/EmptyResults';
import { getPaginationValuesForQuery } from '@src/utils/pagination';

import { InvoicePaymentStatus, InvoiceApprovalStatus } from '@src/types/enums';

const TableCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export function PendingCreditNotesTable() {
  const intl = useIntl();
  const limit = RESULTS_PER_PAGE;
  const { business } = useSettings();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filters] = useInvoiceFilters({
    businessId: business?.id,
    approvalStatus: [InvoiceApprovalStatus.PENDING],
    paymentStatus: [InvoicePaymentStatus.PENDING],
    archived: false,
    fromActiveProvidersOnly: false,
  });
  const {
    loading,
    data: { invoices, totalAmount },
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
    nodes,
  } = useInvoicesListQuery({
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  return (
    <TableCard>
      <Filters fields={['invoicingDate', 'businessEmitterId']} />
      <InvoicesTable
        columns={{
          id: 100,
          receiverCountryTaxPayerInfo: 100,
          invoicingDate: 200,
          businessEmitter: 300,
          total: 150,
        }}
        nodes={nodes}
        invoices={invoices}
        loading={loading}
        limit={limit}
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        page={page}
        count={count}
        onNext={next}
        hasNext={hasNext}
        onPrevious={previous}
        hasPrevious={hasPrevious}
        totalAmount={totalAmount}
        emptyElement={
          <EmptyResults
            label={intl.formatMessage({
              defaultMessage:
                'No hay notas de crédito de tus proveedores destacados',
              id: 'Zo3xLw',
            })}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas las notas de crédito de los proveedores que marques como destacado.',
              id: 'RYNou+',
            })}
          />
        }
      />
    </TableCard>
  );
}

export default PendingCreditNotesTable;
