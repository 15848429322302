export const STATUS_TRANSACTION_PROCESSING = 'PROCESSING';
export const STATUS_TRANSACTION_PROCESSED = 'PROCESSED';
export const STATUS_TRANSACTION_FAILED = 'FAILED';

export const INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE';
export const SECURE_PAYMENT = 'SECURE_PAYMENT';

export const ENTRY_DEPOSIT_ENTRY = 'DepositEntry';
export const ENTRY_INVOICE_PAYMENT_ENTRY = 'InvoicePaymentEntry';
export const ENTRY_WITHDRAWAL_ENTRY = 'WithdrawalEntry';
export const ENTRY_REFUND_ENTRY = 'RefundEntry';
export const ENTRY_HIGO_PAYMENT_SENT_ENTRY = 'HigoPaymentSentEntry';
export const ENTRY_HIGO_PAYMENT_RECEIVED_ENTRY = 'HigoPaymentReceivedEntry';
export const ENTRY_BANKING_TRANSACTION_ENTRY = 'BankingTransaction';

export const REFUNDABLE_INVOICE_PAYMENT = 'InvoicePayment';
export const REFUNDABLE_STP_PAYMENT = 'STPPayment';

export const STATUS_OPTIONS = [
  {
    text: 'Procesando',
    value: STATUS_TRANSACTION_PROCESSING,
  },
  {
    text: 'Procesado',
    value: STATUS_TRANSACTION_PROCESSED,
  },
  {
    text: 'Fallo',
    value: STATUS_TRANSACTION_FAILED,
  },
];
