import { useMutation } from '@apollo/client';

import { CREATE_BUSINESS } from '@src/graphql/mutations';
import CURRENT_USER_QUERY from '@src/graphql/queries/currentUser';

import { Business } from '@src/types/models';

export type CreateBusinessParameters = {
  business: { name: string };
  taxPayerInfo: {
    taxpayerIdentifier: string;
    satPassword: string;
  };
};

export type CreateBusinessResponse = {
  createBusiness: {
    business: Business;
  };
};

function useCreateBusinessMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    CreateBusinessResponse,
    CreateBusinessParameters
  >(CREATE_BUSINESS, {
    refetchQueries: [CURRENT_USER_QUERY],
    awaitRefetchQueries: true,
  });

  return {
    mutate,
    data,
    loading,
    error,
  };
}

export default useCreateBusinessMutation;
