import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Invoice: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M12.0596 6.04382V17.9555" />
        <path d="M14.8168 8.21143H10.6812C9.61216 8.21143 8.7541 9.06029 8.7541 10.1024C8.7541 11.1445 9.6192 11.9933 10.6812 11.9933H13.4383C14.5073 11.9933 15.3654 12.8422 15.3654 13.8843C15.3654 14.9333 14.5003 15.7753 13.4383 15.7753H8.74707" />
        <path d="M20.1203 18.7702C20.1203 20.5507 18.6433 22 16.8287 22H7.29158C5.47699 22 4 20.5507 4 18.7702V5.22981C4 3.44928 5.47699 2 7.29158 2H16.8287C18.6433 2 20.1203 3.44928 20.1203 5.22981" />
      </svg>
    );
  }
);

Invoice.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Invoice.displayName = 'Invoice';

export default Invoice;
