import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const CreditCardAdd: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M3 3.5C2.44772 3.5 2 3.94772 2 4.5V16.5C2 17.0523 2.44772 17.5 3 17.5H13.5C14.0523 17.5 14.5 17.9477 14.5 18.5C14.5 19.0523 14.0523 19.5 13.5 19.5H3C1.34315 19.5 0 18.1569 0 16.5V4.5C0 2.84315 1.34315 1.5 3 1.5H21C22.6569 1.5 24 2.84315 24 4.5V14C24 14.5523 23.5523 15 23 15C22.4477 15 22 14.5523 22 14V4.5C22 3.94772 21.5523 3.5 21 3.5H3Z" />
        <path d="M0 8.5C0 7.94772 0.447715 7.5 1 7.5H23C23.5523 7.5 24 7.94772 24 8.5C24 9.05228 23.5523 9.5 23 9.5H1C0.447715 9.5 0 9.05228 0 8.5Z" />
        <path d="M19.6667 15C20.219 15 20.6667 15.4477 20.6667 16V21.3333C20.6667 21.8856 20.219 22.3333 19.6667 22.3333C19.1144 22.3333 18.6667 21.8856 18.6667 21.3333V16C18.6667 15.4477 19.1144 15 19.6667 15Z" />
        <path d="M16 18.5C16 17.9477 16.4477 17.5 17 17.5H22.3333C22.8856 17.5 23.3333 17.9477 23.3333 18.5C23.3333 19.0523 22.8856 19.5 22.3333 19.5H17C16.4477 19.5 16 19.0523 16 18.5Z" />
      </svg>
    );
  }
);

CreditCardAdd.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CreditCardAdd.displayName = 'CreditCardAdd';

export default CreditCardAdd;
