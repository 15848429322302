import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import useToast from '@src/hooks/useToast';
import useSettings from '@src/hooks/useSettings';
import Form from '@src/components/shared/form/InvoicePaymentForm';
import useQuerySilentChange from '@src/hooks/useQuerySilentChange';
import useInvoices from '@src/hooks/useInvoices';

import { Button } from '@src/components/ui';
import {
  CreatePaymentInstructionsMutationReturn,
  CreatePaymentInstructionsMutationVariables,
  CREATE_PAYMENT_INSTRUCTION,
} from '@src/graphql/mutations/createPaymentInstruction';
import { centsToFloat, stringToCents } from '@src/utils/currency';
import usePaymentDefaults from '@src/hooks/usePaymentDefaults';
import { InvoiceListAttributes } from '@src/graphql/fragments';
import {
  CreatePaymentInstructionsDraftQueryReturn,
  CreatePaymentInstructionsDraftQueryVariables,
  CREATE_PAYMENT_INSTRUCTION_DRAFT,
} from '@src/graphql/mutations/createPaymentInstructionDraft';
import UPDATE_PAYMENT_INSTRUCTION, {
  UpdatePaymentInstructionMutationReturn,
  UpdatePaymentInstructionMutationVariables,
} from '@src/graphql/mutations/updatePaymentInstruction';
import { trackEvent } from '@src/lib/analytics';
import {
  PAYMENT_INSTRUCTION_CREATED,
  PAYMENT_INSTRUCTION_REQUEST_CLICKED,
  PAYMENT_INSTRUCTION_UPDATE_CLICKED,
} from '@src/constants/events';

import {
  PaymentInstructionInvoiceStatus,
  PaymentInstructionStatus,
} from '@src/types/enums';
import { Invoice, PaymentInstruction, PaymentMethod } from '@src/types/models';

interface RequestInstructionFormProps {
  invoiceIds: string[];
  paymentInstruction?: PaymentInstruction;
  paymentInstructionId?: number;
}

interface FormData {
  invoices: Array<
    Pick<
      Invoice,
      'id' | 'emitterTaxpayerName' | 'businessEmitter' | 'businessRelationship'
    > & {
      amount: number;
      paymentConcept: string;
      paymentMethod: PaymentMethod;
      email: string[];
    }
  >;
}

function RequestInstructionForm({
  invoiceIds,
  paymentInstruction,
  paymentInstructionId,
}: RequestInstructionFormProps) {
  const intl = useIntl();
  const history = useHistory();
  const search = useQuerySilentChange();
  const { business } = useSettings();
  const { toast } = useToast();
  const { data, loading } = useInvoices(invoiceIds, {
    sort: {
      key: 'emitterTaxpayerName',
      order: 'asc',
    },
  });

  const invoices = usePaymentDefaults<InvoiceListAttributes>(
    data,
    paymentInstruction
      ? paymentInstruction.paymentInstructionInvoices.map((instruction) => ({
          invoiceId: instruction.invoice.id,
          email: instruction.providerEmails || [],
          paymentConcept: instruction.paymentConcept,
          paymentMethod: {
            value: instruction.clabe,
            paymentType: instruction.paymentMethod,
          },
          amount: centsToFloat(instruction.amountCents),
        }))
      : undefined
  );

  const [createPaymentInstruction, { loading: creatingRequest }] = useMutation<
    CreatePaymentInstructionsMutationReturn,
    CreatePaymentInstructionsMutationVariables
  >(CREATE_PAYMENT_INSTRUCTION);
  const [
    createPaymentInstructionDraft,
    { loading: creatingDraft },
  ] = useMutation<
    CreatePaymentInstructionsDraftQueryReturn,
    CreatePaymentInstructionsDraftQueryVariables
  >(CREATE_PAYMENT_INSTRUCTION_DRAFT);

  const [updatePaymentInstruction, { loading: updatingRequest }] = useMutation<
    UpdatePaymentInstructionMutationReturn,
    UpdatePaymentInstructionMutationVariables
  >(UPDATE_PAYMENT_INSTRUCTION);

  const createRequest = async (data: FormData) => {
    trackEvent(PAYMENT_INSTRUCTION_REQUEST_CLICKED);
    if (business) {
      try {
        await createPaymentInstruction({
          variables: {
            businessId: business.id,
            paymentInstructionInvoices: data.invoices.map((invoice) => ({
              invoiceId: invoice.id,
              providerEmails: invoice.email,
              clabe: invoice.paymentMethod.value,
              amountCents: stringToCents(invoice.amount),
              paymentMethod: invoice.paymentMethod.paymentType,
              paymentConcept: invoice.paymentConcept,
            })),
          },
        });

        trackEvent(PAYMENT_INSTRUCTION_CREATED);
        history.push('/accounts_payable');

        toast.success(
          intl.formatMessage({
            defaultMessage: 'Solicitud de aprobación de pago creada con éxito.',
            id: '71cqNX',
          })
        );
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al crear tu solicitud.',
            id: 'Q3PH5I',
          }),
          e.message
        );
      }
    }
  };

  const handleDraftSubmit = async (data: FormData) => {
    if (business) {
      try {
        await createPaymentInstructionDraft({
          variables: {
            businessId: business.id,
            paymentInstructionInvoices: data.invoices.map((i) => ({
              invoiceId: i.id,
              providerEmails: i.email,
              clabe: i.paymentMethod.value,
              amountCents: stringToCents(i.amount),
              paymentMethod: i.paymentMethod.paymentType,
              paymentConcept: i.paymentConcept,
            })),
          },
        });

        history.push('/accounts_payable');

        toast.success(
          intl.formatMessage({
            defaultMessage: 'Solicitud de pago guardada con éxito.',
            id: 'drqLdy',
          })
        );
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al guardar tu solicitud.',
            id: 'UZ7GxD',
          }),
          e.message
        );
      }
    }
  };

  const updateRequest = async (
    data: FormData,
    { request }: { request?: boolean } = {}
  ) => {
    trackEvent(PAYMENT_INSTRUCTION_UPDATE_CLICKED, {
      payment_instruction_id: paymentInstructionId,
    });
    if (business && paymentInstructionId && paymentInstruction) {
      const remainingInvoicesObj = data.invoices.reduce(
        (idsObj, invoice) => ({
          ...idsObj,
          [invoice.id]: invoice,
        }),
        {}
      );

      try {
        await updatePaymentInstruction({
          variables: {
            businessId: business.id,
            paymentInstructionId,
            paymentInstructionInvoices: paymentInstruction.paymentInstructionInvoices.map(
              (instruction) => {
                const invoice =
                  instruction.invoice.id &&
                  remainingInvoicesObj[instruction.invoice.id];

                if (!invoice) {
                  return {
                    paymentInstructionInvoiceId: instruction.id,
                    status: PaymentInstructionInvoiceStatus.REJECTED,
                  };
                }

                return {
                  paymentInstructionInvoiceId: instruction.id,
                  providerEmails: invoice.email,
                  amountCents: stringToCents(invoice.amount),
                  paymentMethod: invoice.paymentMethod.paymentType,
                  clabe: invoice.paymentMethod.value,
                  paymentConcept: invoice.paymentConcept?.trim(),
                  status: instruction.status,
                };
              }
            ),
            request,
          },
        });

        history.push('/payment_requests');
        toast.success(
          intl.formatMessage({
            defaultMessage: 'Solicitud actualizada.',
            id: 'LBsLZ6',
          })
        );
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al intentar actualizar la solicitud.',
            id: 'BikY5d',
          }),
          e.message
        );
      }
    }
  };

  const handleInvoiceRemoval = (id: string, callback?: () => void) => {
    search.pop('invoiceIds', id);
    callback?.();
  };

  if (paymentInstructionId) {
    return (
      <Form
        wallet="balance"
        title={intl.formatMessage({
          defaultMessage: 'Detalles de solicitud',
          id: 'Ug15nO',
        })}
        onSubmit={updateRequest}
        invoices={invoices}
        invoiceCount={invoiceIds.length}
        loading={loading}
        submitting={updatingRequest}
        onRemove={handleInvoiceRemoval}
        ctaCustomButton={(handleSubmit) => (
          <>
            {paymentInstruction?.status ===
              PaymentInstructionStatus.DRAFTED && (
              <Button
                variant="outlined"
                type="submit"
                loading={updatingRequest}
              >
                {intl.formatMessage({
                  defaultMessage: 'Guardar',
                  id: 'oN4aGu',
                })}
              </Button>
            )}
            <Button
              onClick={handleSubmit((values) =>
                updateRequest(values, { request: true })
              )}
              loading={updatingRequest}
            >
              {intl.formatMessage({
                defaultMessage: 'Guardar y enviar',
                id: 'mvEaLi',
              })}
            </Button>
          </>
        )}
        hiddenTerms
      />
    );
  }

  return (
    <Form
      wallet="balance"
      title={intl.formatMessage({
        defaultMessage: 'Solicitar aprobación de pago',
        id: 'S3KdQW',
      })}
      onSubmit={createRequest}
      invoices={invoices}
      invoiceCount={invoiceIds.length}
      loading={loading}
      submitting={creatingRequest}
      onRemove={handleInvoiceRemoval}
      ctaCustomButton={(handleSubmit) => (
        <>
          <Button
            onClick={handleSubmit(handleDraftSubmit)}
            variant="outlined"
            loading={creatingDraft}
          >
            {intl.formatMessage({
              defaultMessage: 'Guardar',
              id: 'oN4aGu',
            })}
          </Button>
          <Button type="submit" loading={creatingRequest}>
            {intl.formatMessage({
              defaultMessage: 'Solicitar aprobación de pago',
              id: 'S3KdQW',
            })}
          </Button>
        </>
      )}
      hiddenTerms
    />
  );
}

export default RequestInstructionForm;
