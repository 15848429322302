import React from 'react';

import { useIntl } from 'react-intl';
import DeclinedCreditCard from '@src/components/pages/WalletView/components/DeclinedCreditCard';
import CreditLineOverviewCard from '@src/components/shared/CreditLineOverviewCard';
import DisabledFinancingCard from '@src/components/pages/WalletView/components/DisabledFinancingCard';
import useSettings from '@src/hooks/useSettings';

function FinancingCard() {
  const intl = useIntl();
  const { business } = useSettings();
  const creditAccount = business?.creditAccount;

  if (creditAccount?.active && creditAccount?.creditLimitCents === 0) {
    return <DeclinedCreditCard />;
  }

  if (creditAccount?.active) {
    return (
      <CreditLineOverviewCard
        cardTitle={intl.formatMessage({
          defaultMessage: 'Mi crédito',
          id: 'QCRWQM',
        })}
      />
    );
  } else {
    return <DisabledFinancingCard />;
  }
}

export default FinancingCard;
