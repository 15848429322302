import styled from 'styled-components';

export const Container = styled.div`
  padding-top: ${({ theme }) => theme.spacing[5]};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 34rem;
  max-width: 90vw;
`;

export const Description = styled.span`
  max-width: 33rem;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize['sm']};
  padding-bottom: ${({ theme }) => theme.spacing[10]};
  color: ${({ theme }) => theme.colors.neutral[600]};
`;
