import styled from 'styled-components';

export const StyledSwitcherItem = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => theme.spacing[7]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing[5]};
  cursor: pointer;
  z-index: 2;
  font-size: ${({ theme }) => theme.spacing[3]};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.neutral[900] : theme.colors.neutral[300]};
`;
