import styled from 'styled-components';
import { StyledSidebarItem } from './SidebarItem.styles';

export const StyledSidebar = styled.div`
  overflow-y: auto;
  width: ${({ theme }) => theme.spacing[20]};
  position: sticky;
  top: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.neutral[50]};
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex[20]};
  flex-shrink: 0;

  .sidebar-header {
    width: 100%;
    height: ${({ theme }) => theme.spacing[20]};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .sidebar-body {
    width: 100%;
    flex-grow: 1;
    padding: ${({ theme }) => theme.spacing[4]} 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .sidebar-links {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 ${({ theme }) => theme.spacing[8]};
        align-items: center;
      }
    }

    @media (max-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .sidebar-footer {
    padding: ${({ theme }) => theme.spacing[4]} 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${StyledSidebarItem} {
      margin-top: ${({ theme }) => theme.spacing[2]};
      &:first-child {
        border: none;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .logo-wrapper {
    width: ${({ theme }) => theme.spacing[8]};
    display: flex;
    align-items: center;
  }

  ${StyledSidebarItem} {
    margin-bottom: ${({ theme }) => theme.spacing[4]};

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    height: ${({ theme }) => theme.spacing[20]};
    width: 100vw;
    flex-direction: row;
    position: static;
  }
`;
