import React from 'react';
import Checkbox from '@src/components/ui/Checkbox';

import { ActionBarStyled } from './HeaderActionBar.styles';

type HeaderActionBarProps = {
  title?: string;
  checked?: boolean;
  onChange?: () => void;
  actions?: React.ReactNode;
  indeterminate?: boolean;
};

const HeaderActionBar = ({
  title,
  checked,
  onChange,
  actions,
  indeterminate,
}: HeaderActionBarProps) => {
  return (
    <ActionBarStyled>
      <div className="action-bar-left">
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onChange={onChange}
          buffer
        />
        <p>{title}</p>
      </div>
      <div className="action-bar-right">{actions}</div>
    </ActionBarStyled>
  );
};

export default HeaderActionBar;
