import React from 'react';
import styled from 'styled-components';

import { formatClabe } from '@src/utils/bankingInfo';

interface ClabeProps {
  account: string;
  as?: 'span' | 'p' | 'strong';
}

const ClabeFormatted = styled.span`
  margin: 0;
  white-space: nowrap;
`;

function Clabe({ account, as = 'span' }: ClabeProps) {
  const formattedClabe = formatClabe(account);
  return <ClabeFormatted as={as}>{formattedClabe}</ClabeFormatted>;
}

export default Clabe;
