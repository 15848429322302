import React from 'react';
import { IntlShape } from 'react-intl';

import { AttributesObject } from '@src/types/resourceList';
import { Currency, FormattedDate } from '@src/components/i18n';
import { FinancingRequest } from '@src/types/models';
import { PayCreditButton } from '..';
import PaymentStatusTag from '../PaymentStatusTag';

export const getTableAttributes = (
  intl: IntlShape
): AttributesObject<FinancingRequest> => ({
  id: {
    name: intl.formatMessage({
      defaultMessage: 'ID de crédito',
      id: 'lfy2a6',
    }),
    type: 'STRING',
    render: ({ id }) => id,
  },
  paid: {
    name: intl.formatMessage({
      defaultMessage: 'Fecha de disposición',
      id: 'pWfBL4',
    }),
    type: 'DATE_TIME',
    render: ({ paidAt }) => (paidAt ? <FormattedDate value={paidAt} /> : '-'),
  },
  payOn: {
    name: intl.formatMessage({
      defaultMessage: 'Fecha de vencimiento',
      id: 'XF6Ipl',
    }),
    type: 'DATE_TIME',
    render: ({ dueDate }) =>
      dueDate ? <FormattedDate value={dueDate} /> : '-',
  },
  owedAmount: {
    name: intl.formatMessage({
      defaultMessage: 'Capital',
      id: 'IaE50n',
    }),
    type: 'STRING',
    render: ({ amountCents }) =>
      amountCents ? (
        <Currency maximumFractionDigits={2} value={-amountCents} />
      ) : (
        '-'
      ),
  },
  totalInterests: {
    name: intl.formatMessage({
      defaultMessage: 'Interés acumulado',
      id: 'jStRaY',
    }),
    type: 'STRING',
    render: ({ totalInterestAmountCents }) => (
      <Currency
        maximumFractionDigits={2}
        value={totalInterestAmountCents ?? 0}
      />
    ),
  },
  paidAmount: {
    name: intl.formatMessage({
      defaultMessage: 'Monto pagado',
      id: 'F3Fk3M',
    }),
    type: 'STRING',
    render: ({ repaidAmountCents }) =>
      repaidAmountCents && repaidAmountCents > 0 ? (
        <Currency maximumFractionDigits={2} value={repaidAmountCents} />
      ) : (
        '-'
      ),
  },

  status: {
    name: intl.formatMessage({
      defaultMessage: 'Estado del crédito',
      id: 'V/cRu7',
    }),
    type: 'CUSTOM',
    render: ({ paymentStatus }) => <PaymentStatusTag status={paymentStatus} />,
  },
  pay: {
    name: '',
    type: 'CUSTOM',
    render: (request: FinancingRequest) => (
      <PayCreditButton request={request} />
    ),
  },
});
