import { useQuery, ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import type { Provider } from '@src/types/models';
import { PROVIDER_LIST_QUERY } from '@src/graphql/queries';

function useProvidersListQuery(
  businessId?: string
): {
  loading: boolean;
  error?: ApolloError;
  data: Provider[];
} {
  const { loading, error, data } = useQuery(PROVIDER_LIST_QUERY, {
    variables: { businessId: businessId },
    skip: !businessId,
  });

  const providers: Provider[] = useMemo(() => {
    if (!data?.providers?.edges) {
      return [];
    }
    return data.providers.edges.map((edge) => ({
      name: edge.node.name,
      id: edge.node.id,
    }));
  }, [data]);

  return {
    error,
    loading,
    data: providers,
  };
}

export default useProvidersListQuery;
