import styled from 'styled-components';

export const Form = styled.form`
  width: 27.5rem;
  > * {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
  @media (max-width: 576px) {
    width: 100vw;
    padding: 0 1rem;
  }
`;

export const TooltipSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.primary[500]};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.xs};
`;
