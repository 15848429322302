import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    defaultMessage: 'Cargando...',
    id: 'ARhEHP',
  },
  error: {
    defaultMessage: 'Error al cargar los datos',
    id: 'yM0cd/',
  },
  pageTitleMorning: {
    defaultMessage: '¡Buenos días, {userName}!',
    id: 'Mp6kPI',
  },
  pageTitleAfternoon: {
    defaultMessage: '¡Buenas tardes, {userName}!',
    id: 'kWOmGw',
  },
  pageTitleNight: {
    defaultMessage: '¡Buenas noches, {userName}!',
    id: '9ECBAI',
  },
});
