import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  background-image: url('~@src/assets/img/bg_errors.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
`;

export const Error = styled.div`
  position: relative;

  label {
    font-size: ${({ theme }) => theme.spacing[40]};
    font-weight: bold;
  }

  span {
    position: absolute;
    font-size: ${({ theme }) => theme.spacing[12]};
    margin-left: -${({ theme }) => theme.spacing[10]};
    margin-top: ${({ theme }) => theme.spacing[6]};
  }
`;
