import React, { Fragment } from 'react';
import { IntlProvider as OriginalIntlProvider } from 'react-intl';

import { I18N } from '@src/lib/i18n';
import useSettings from '@src/hooks/useSettings';

interface IntlProviderProps {
  children: React.ReactNode;
  onError?: (error: Error) => void;
}

export const IntlProviderContext = React.createContext({
  locale: I18N.getLocale(),
  timeZone: I18N.getTimeZone(),
});

function IntlProvider({ children, onError }: IntlProviderProps) {
  const { currentUser } = useSettings();
  const { locale: userLocale, timeZone: userTimeZone } = currentUser || {};

  const locale = userLocale ? I18N.getLocale(userLocale) : I18N.getLocale();
  const timeZone = userTimeZone
    ? I18N.getTimeZone(userTimeZone)
    : I18N.getTimeZone();
  const messages = I18N.getMessages(locale);

  return (
    <IntlProviderContext.Provider value={{ locale, timeZone }}>
      <OriginalIntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        textComponent={Fragment}
        onError={onError}
      >
        {children}
      </OriginalIntlProvider>
    </IntlProviderContext.Provider>
  );
}

export default IntlProvider;
