import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';

import Button from '@src/components/ui/Button';
import { Header, TextField } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';
import { APP_DATA_QUERY_NAME } from '@src/graphql/queries/appData';
import useSettings from '@src/hooks/useSettings';
import UPDATE_BUSINESS_MUTATION, {
  UpdateBusinessReturn,
  UpdateBusinessVariables,
} from '@src/graphql/mutations/updateBusiness';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_BUSINESS } from '@src/constants/events';
import CurrencySelect from '@src/components/shared/select/CurrencySelect';

export type FormData = {
  name: string;
  websiteUrl?: string;
  phone: string;
  currencyCode: string;
};

export function UpdateBusinessForm() {
  const intl = useIntl();
  const { toast } = useToast();
  const { business } = useSettings();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: business?.name,
      websiteUrl: business?.websiteUrl,
      phone: business?.phone,
      currencyCode: business?.setting?.currencyCode,
    },
  });

  const [updateBusiness, { loading: submitting }] = useMutation<
    UpdateBusinessReturn,
    UpdateBusinessVariables
  >(UPDATE_BUSINESS_MUTATION, {
    refetchQueries: [APP_DATA_QUERY_NAME],
    awaitRefetchQueries: true,
    onCompleted: () => {
      trackEvent(UPDATE_BUSINESS, {
        creator: 'admin',
        view: 'BusinessSettings',
      });
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Perfil actualizado',
          id: 'n3SUeV',
        }),
        intl.formatMessage({
          defaultMessage: 'Se ha actualizado la información de perfil.',
          id: 'fXKzlK',
        })
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Algo salió mal',
          id: '6Pks4S',
        }),
        e.message
      );
    },
  });

  const onSubmit = (data: FormData) => {
    if (business?.id) {
      updateBusiness({
        variables: {
          businessId: business?.id,
          business: {
            name: data.name,
            websiteUrl: data?.websiteUrl,
            phone: data.phone,
            currencyCode: data.currencyCode,
          },
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Perfil de la organización',
          id: 'uz8OLr',
        })}
      </Header>
      <Spacer margin="4" />
      <TextField
        fullWidth
        type="text"
        {...register('name', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Nombre comercial',
          id: 'Anv9KA',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'Mi organización',
          id: '3dpH+3',
        })}
        error={!!errors.name?.message}
        helperText={
          errors.name?.message ||
          intl.formatMessage({
            defaultMessage: 'El nombre con el que tu organización es conocida.',
            id: '8oIFiU',
          })
        }
        required
      />
      <Spacer margin="2" />
      <TextField
        type="url"
        fullWidth
        {...register('websiteUrl')}
        label={intl.formatMessage({
          defaultMessage: 'Página web',
          id: 'zMB5Jt',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'https://wwww.negocio.com',
          id: 'cBUZrb',
        })}
        error={!!errors.websiteUrl?.message}
        helperText={
          errors.websiteUrl?.message ||
          intl.formatMessage({
            defaultMessage: 'Debe iniciar con http:// o https://',
            id: '1vgAI4',
          })
        }
      />
      <TextField
        type="tel"
        fullWidth
        {...register('phone', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
          pattern: {
            value: /^\+?[1-9]\d{1,14}$/,
            message: intl.formatMessage({
              defaultMessage: 'Teléfono inválido',
              id: 'L+Cza1',
            }),
          },
          setValueAs: (value) => value.replace(/(\s|\+)/g, ''),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Teléfono',
          id: 'S6W4PT',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: '+52 55 6633 7890',
          id: 'KfhaoM',
        })}
        defaultValue={business?.phone}
        error={!!errors.phone?.message}
        helperText={errors.phone?.message}
        required
      />
      <Controller
        control={control}
        name="currencyCode"
        rules={{
          required: true,
        }}
        render={({ field, fieldState: { error } }) => (
          <CurrencySelect
            fullWidth
            error={!!error?.message}
            helperText={error?.message}
            required
            {...field}
          />
        )}
      />
      <Spacer margin="4" />
      <Button block type="submit" loading={submitting}>
        {intl.formatMessage({
          defaultMessage: 'Guardar',
          id: 'oN4aGu',
        })}
      </Button>
    </form>
  );
}

export default UpdateBusinessForm;
