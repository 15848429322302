import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import union from 'lodash/union';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';

import * as S from './TransactionsTable.styles';
import { Dropdown } from '@src/components/ui';
import { DropdownItem } from '@src/components/ui/Dropdown';
import Table from '@src/components/shared/TransactionsTable';
import HeaderActionBar from '@src/components/shared/HeaderActionBar';
import { WalletEntry } from '@src/types/models';
import useModal from '@src/hooks/useModal';
import useSortFilters from '@src/hooks/useSortFilters';
import useQueryParams from '@src/hooks/useQueryParams';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import DownloadTransactionsCSV from './DownloadTransactionsCSV';
import DownloadTransactionsPDF from './DownloadTransactionsPDF';

type HistoricalTransactionsTableProps = {
  data: WalletEntry[];
  nodes: WalletEntry[];
  loading: boolean;
  count: number;
  next: () => void;
  hasNext: boolean;
  previous: () => void;
  hasPrevious: boolean;
  page: number;
};
const HistoricalTransactionsTable = (
  props: HistoricalTransactionsTableProps
) => {
  const {
    data,
    nodes,
    loading,
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
  } = props;

  const intl = useIntl();
  const [query] = useQueryParams();
  const { open: openDownloadTransactions } = useModal(DownloadTransactionsCSV);
  const { open: openDownloadTransactionsPDF } = useModal(
    DownloadTransactionsPDF
  );
  const limit = RESULTS_PER_PAGE;
  const [sortBy, handleSort, sortParams] = useSortFilters();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const handleSelect = (targetId) => {
    if (selectedIds.includes(targetId)) {
      setSelectedIds(selectedIds.filter((id) => id !== targetId));
    } else {
      setSelectedIds([targetId, ...selectedIds]);
    }
  };

  useEffect(() => setSelectedIds([]), [query]);

  const hasAnySelected = !!selectedIds.length;

  const handleSelectAll = () => {
    const invoicesPerPage = data.map(({ id }) => id);
    const hasAllPageSelected =
      intersection(invoicesPerPage, selectedIds).length ===
      invoicesPerPage.length;

    if (hasAllPageSelected) {
      const afterRemove = difference(selectedIds, invoicesPerPage);
      setSelectedIds(afterRemove);
    } else {
      setSelectedIds(union(invoicesPerPage, selectedIds));
    }
  };

  const handleDownloadCSV = () => {
    openDownloadTransactions({
      totalCount: count,
      filterCount: count,
      selectedCount: selectedIds.length,
      filters: query,
      hasAllSelected: selectedIds.length === count,
      walletEntriesId: selectedIds,
    });
  };

  const handleDownloadPDF = () => {
    openDownloadTransactionsPDF({
      totalCount: count,
      filterCount: count,
      selectedCount: selectedIds.length,
      filters: query,
      hasAllSelected: selectedIds.length === count,
      walletEntriesId: selectedIds,
    });
  };

  const hasAllSelected =
    selectedIds.length > 0 && selectedIds.length === nodes.length;

  return (
    <S.HistoricalTransactionsTable>
      {hasAnySelected && (
        <HeaderActionBar
          indeterminate={selectedIds?.length !== nodes.length}
          title={intl.formatMessage(
            {
              defaultMessage: '{count} elementos seleccionados',
              id: 'DYdTDh',
            },
            {
              count: selectedIds?.length,
            }
          )}
          checked={hasAllSelected}
          onChange={handleSelectAll}
          actions={
            <div>
              <Dropdown
                color="primary"
                variant="solid"
                label={
                  <FormattedMessage defaultMessage="Descargar" id="LcOlm6" />
                }
              >
                <DropdownItem onClick={handleDownloadPDF}>
                  <FormattedMessage
                    defaultMessage="PDF Comprobantes"
                    id="UpAFXt"
                  />
                </DropdownItem>
                <DropdownItem onClick={handleDownloadCSV}>
                  <FormattedMessage
                    defaultMessage="CSV Transacciones"
                    id="6W7r0Z"
                  />
                </DropdownItem>
              </Dropdown>
            </div>
          }
        />
      )}
      <Table
        columns={{
          transactionIdentifier: 50,
          hashId: 80,
          createdAt: 100,
          recipient: 100,
          invoice: 120,
          providerContact: 100,
          transactionType: 100,
          amount: 80,
          status: 120,
        }}
        limit={limit}
        page={page}
        data={data}
        loading={loading}
        count={count}
        hasNext={hasNext}
        onNext={next}
        sort={sortParams}
        hasPrevious={hasPrevious}
        onPrevious={previous}
        onSort={handleSort}
        selectable
        onSelect={handleSelect}
        selectedIds={selectedIds}
        onSelectAll={handleSelectAll}
        hasAllSelected={hasAllSelected}
      />
    </S.HistoricalTransactionsTable>
  );
};

export default HistoricalTransactionsTable;
