import { gql } from '@apollo/client';

const INVOICE_STATUS_COUNT = gql`
  query AccountsPayableItems($businessId: ID!) {
    invoiceStatusCount(businessId: $businessId) {
      duePaymentInvoicesCount
      paidInvoicesCount
    }
  }
`;

export default INVOICE_STATUS_COUNT;
