import { gql } from '@apollo/client';

import { WALLET_ENTRY_ATTRIBUTES } from '@src/graphql/fragments';

export const TRANSACTIONS_VIEW_QUERY_NAME = 'TransactionsView';

export const TRANSACTIONS_VIEW_QUERY = gql`
  query ${TRANSACTIONS_VIEW_QUERY_NAME}($businessId: ID!, $walletEntryHashId: ID!) {
    walletEntry(
      businessId: $businessId
      walletEntryHashId: $walletEntryHashId
    ) {
      ...WalletEntryViewAttributes
    }
  }
  ${WALLET_ENTRY_ATTRIBUTES}
`;
