import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ACCEPTED: {
    defaultMessage: 'Activo',
    id: '/61g0d',
  },
  NOT_ACCEPTED: {
    defaultMessage: 'Pendiente',
    id: 'uZ+xW0',
  },
});

export default messages;
