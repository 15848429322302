import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Receipt: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 18 20"
        fill={color}
        {...rest}
      >
        <path
          fillRule="evenodd"
          d="M0 1.26524V19.05C0 19.5576 0.576979 19.8585 1.00382 19.5735L3.34575 18.0097C3.60273 17.838 3.94763 17.8718 4.16528 18.0898L5.85722 19.7845C6.10916 20.0368 6.52242 20.0368 6.77436 19.7845L8.38353 18.1727C8.63547 17.9203 9.04874 17.9203 9.30068 18.1727L10.936 19.8107C11.1777 20.0527 11.5703 20.0641 11.8259 19.8365L13.8361 18.0467C14.0559 17.851 14.3831 17.8286 14.6284 17.9924L16.9962 19.5735C17.423 19.8585 18 19.5576 18 19.05V1.26524C18 0.566467 17.4244 0 16.7143 0H1.28571C0.575634 0 0 0.566467 0 1.26524ZM14.1429 7.59143H3.85714V5.06096H14.1429V7.59143ZM3.85714 12.6524H14.1429V10.1219H3.85714V12.6524Z"
        />
      </svg>
    );
  }
);

Receipt.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Receipt.displayName = 'Receipt';

export default Receipt;
