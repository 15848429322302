import React from 'react';

import { Spacer } from '@src/components/shared/layouts';
import { Label, Text, Icon } from '@src/components/ui';
import * as S from './DataContent.styles';
import { IconName } from '@src/components/ui/Icon/types';

interface DataContentProps {
  label: string;
  value: React.ReactNode;
  imgSrc?: string;
  iconName?: IconName;
}

function DataContent({ label, value, iconName, imgSrc }: DataContentProps) {
  return (
    <S.DataContent justifyContent="center" alignItems="center">
      <S.RoundIcon $src={imgSrc}>
        {!imgSrc && (
          <Icon
            name={iconName || 'Bank'}
            color="neutral"
            colorStep="500"
            variant="solid"
            size={24}
          />
        )}
      </S.RoundIcon>
      <Spacer direction="horizontal" margin="4" />
      <div>
        <Label color="neutral" colorStep="500">
          {label}
        </Label>
        <Text color="neutral" colorStep="600" strong>
          {value}
        </Text>
      </div>
    </S.DataContent>
  );
}

export default DataContent;
