import styled from 'styled-components';

export const DownloadSuccess = styled.div`
  width: 32rem;
  max-width: 90vw;
  text-align: center;
  margin: ${({ theme }) => theme.spacing[4]} 0;

  p {
    color: ${({ theme }) => theme.colors.neutral[600]};
  }

  svg {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
`;
