import React from 'react';
import { IconWrapper } from '../Button';
import { StyledIconButton } from './IconButton.styles';
import { IconButtonProps } from './types';

export default function IconButton(props: IconButtonProps) {
  const { children, ...otherProps } = props;

  return (
    <StyledIconButton {...otherProps}>
      <IconWrapper>{children}</IconWrapper>
    </StyledIconButton>
  );
}
