import React from 'react';
import { useIntl } from 'react-intl';

import WalletBalance from '@src/components/shared/WalletBalance';
import ModalHeader from '@src/components/ui/ModalHeader';
import WithdrawalForm from '@src/components/pages/WalletView/components/WithdrawalForm';
import useModal from '@src/hooks/useModal';

function WithdrawalFullscreenModal() {
  const intl = useIntl();
  const { close } = useModal(WithdrawalFullscreenModal);

  return (
    <>
      <ModalHeader
        title={intl.formatMessage({
          defaultMessage: 'Retirar',
          id: '2OwuoE',
        })}
        actions={<WalletBalance />}
        onClose={close}
      />
      <WithdrawalForm />
    </>
  );
}

WithdrawalFullscreenModal.displayName = 'WithdrawalFullscreenModal';

export default WithdrawalFullscreenModal;
