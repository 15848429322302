import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './AccountInformation.styles';
import Clabe from '@src/components/shared/Clabe';
import { Plus } from '@src/components/ui/Icon/outline';
import { Bank, Send } from '@src/components/ui/Icon/solid';
import Logo from '@src/components/shared/Logo';
import { Label, Text } from '@src/components/ui';
import { HIGO_CAPITAL_RFC } from '@src/constants/financingRepayments';

import { InvoicePaymentType } from '@src/types/enums';

interface STPAccountProps {
  logoUrl?: string;
  clabe: string;
  bankName?: string;
}
interface HigoPayAccountProps {
  name?: string;
  disabled?: boolean;
}

interface AccountInformationProps
  extends Partial<STPAccountProps>,
    Partial<HigoPayAccountProps> {
  paymentType?: InvoicePaymentType;
  helperText?: string;
  compact?: boolean;
  selected?: boolean;
  hasBorder?: boolean;
}

export function AccountPlaceholder() {
  const intl = useIntl();
  return (
    <S.Account>
      <Label size="md" strong color="neutral" colorStep="400">
        {intl.formatMessage({
          defaultMessage: 'Selecciona un método de pago',
          id: 'HGMuYp',
        })}
      </Label>
    </S.Account>
  );
}

export function NewSTPAccount() {
  const intl = useIntl();
  return (
    <S.Account>
      <S.Circle $color="primary">
        <Plus size={16} />
      </S.Circle>

      <Label size="md" strong color="primary" colorStep="500">
        {intl.formatMessage({
          defaultMessage: 'Agregar CLABE',
          id: 'LDKRfg',
        })}
      </Label>
    </S.Account>
  );
}

export function RequestAccount() {
  const intl = useIntl();
  return (
    <S.Account>
      <S.Circle>
        <Send size={16} />
      </S.Circle>

      <div>
        <Label size="md" strong>
          {intl.formatMessage({
            defaultMessage: 'Solicitar CLABE a tu proveedor',
            id: '56+vNO',
          })}
        </Label>
        <Text small colorStep="500">
          Via email
        </Text>
      </div>
    </S.Account>
  );
}

export function HigoPayAccount({ disabled, name }: HigoPayAccountProps) {
  const intl = useIntl();
  return (
    <S.Account>
      <S.HigoPayLogo disabled={disabled}>
        <Logo
          variant="isotype"
          size={16}
          color="neutral"
          colorStep="50"
          monochrome
        />
      </S.HigoPayLogo>
      <div>
        <Label size="md" strong>
          {name}
        </Label>
        <Text small colorStep="500">
          {intl.formatMessage({
            defaultMessage: 'Transferencia directa a cuenta de Higo',
            id: 'PmpYSh',
          })}
          {disabled &&
            ` - ${intl.formatMessage({
              defaultMessage: 'No disponible',
              id: 'ZgpGFg',
            })}`}
        </Text>
      </div>
    </S.Account>
  );
}

export function HigoConcentradoraAccount() {
  return (
    <S.Account>
      <S.HigoPayLogo>
        <Logo
          variant="isotype"
          size={16}
          color="neutral"
          colorStep="50"
          monochrome
        />
      </S.HigoPayLogo>
      <div>
        <Label size="md" strong>
          Higo Capital SA de CV
        </Label>
        <Text small colorStep="500">
          {HIGO_CAPITAL_RFC}
        </Text>
      </div>
    </S.Account>
  );
}

export function STPAccount({ logoUrl, clabe, bankName }: STPAccountProps) {
  return (
    <S.Account>
      {!!logoUrl ? (
        <S.BankImage src={logoUrl} alt="Bank logotype" />
      ) : (
        <S.Circle>
          <Bank size={16} />
        </S.Circle>
      )}
      <div>
        <Label size="md" strong>
          <Clabe account={clabe} as="strong" />
        </Label>
        <Text small colorStep="500">
          {bankName}
        </Text>
      </div>
    </S.Account>
  );
}

const AccountInformation = ({
  paymentType,
  logoUrl,
  clabe,
  bankName,
  disabled,
  name,
}: AccountInformationProps) => {
  if (paymentType === InvoicePaymentType.STP_PAYMENT && clabe) {
    return <STPAccount logoUrl={logoUrl} clabe={clabe} bankName={bankName} />;
  }

  if (paymentType === InvoicePaymentType.HIGO_PAYMENT) {
    return <HigoPayAccount disabled={disabled} name={name} />;
  }

  return <NewSTPAccount />;
};

export default AccountInformation;
