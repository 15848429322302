import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useIntl } from 'react-intl';

import { TERMS_URL, PRIVACY_POLICY_URL } from '@src/constants/publicUrls';
import Permission from '@src/components/shared/Permission';
import Menu, { MenuItem } from '@src/components/ui/Menu';
import Avatar from '@src/components/ui/Avatar';
import * as S from './AvatarMenu.styles';
import { remove as logOut } from '@src/lib/auth';
import { resetUser } from '@src/lib/errorReporter';

import { UserRole } from '@src/types/enums';
import type { User } from '@src/types/models';

type AvatarMenuProps = {
  currentUser?: User;
};

function AvatarMenu({ currentUser }: AvatarMenuProps) {
  const intl = useIntl();
  const client = useApolloClient();
  const history = useHistory();
  const [avatar, setAvatar] = useState<HTMLElement>();
  const [open, setOpen] = useState(false);
  const { firstName, lastName } = currentUser || {};

  const handleAvatarClick = () => {
    setOpen(true);
  };

  const handleMenuBlur = () => {
    setOpen(false);
  };

  const handleLogoutClick = async () => {
    try {
      await client.clearStore();
      logOut();
      resetUser();
      history.replace('/login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <S.MenuButton ref={setAvatar as any}>
        <Avatar
          name={`${firstName} ${lastName}`}
          onClick={handleAvatarClick}
          backgroundColor="#97A0F9"
        />
      </S.MenuButton>
      <Menu
        anchorEl={avatar}
        open={open}
        placement="auto"
        onBlur={handleMenuBlur}
      >
        <Link to="/settings/profile">
          <MenuItem>
            {intl.formatMessage({
              defaultMessage: 'Mi perfil',
              id: 'tdx+EV',
            })}
          </MenuItem>
        </Link>

        <Permission minimumRoles={[UserRole.ADMIN]}>
          <Link to="/settings/home">
            <MenuItem>
              {intl.formatMessage({
                defaultMessage: 'Configuración',
                id: 'lmFbMS',
              })}
            </MenuItem>
          </Link>
        </Permission>

        <a target="_blank" href={PRIVACY_POLICY_URL} rel="noreferrer">
          <MenuItem>
            {intl.formatMessage({
              defaultMessage: 'Aviso de Privacidad',
              id: 'BBA+aY',
            })}
          </MenuItem>
        </a>

        <a target="_blank" href={TERMS_URL} rel="noreferrer">
          <MenuItem>
            {intl.formatMessage({
              defaultMessage: 'Términos y Condiciones',
              id: 'VkHZxm',
            })}
          </MenuItem>
        </a>

        <MenuItem onClick={handleLogoutClick} data-testid="navbar-logout">
          {intl.formatMessage({
            defaultMessage: 'Cerrar Sesión',
            id: 'XEKx71',
          })}
        </MenuItem>
      </Menu>
    </>
  );
}

export default AvatarMenu;
