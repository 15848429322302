import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import SettingsProvider from '@src/components/SettingsProvider';
import { IntlProvider } from '@src/components/i18n';
import { GeneralRoutes } from '@src/routes';
import GlobalStyles from '@src/styles/globalStyles';
import theme from '@src/styles/theme';
import { Container as ToastContainer } from '@src/components/ui/Toast/Toast';
import { Container as SnackbarContainer } from '@src/components/ui/Snackbar/Snackbar';
import GlobalModals from '@src/components/shared/modals/GlobalModals';
import client from '@src/lib/apollo/client';
import optimizedClient from '@src/lib/apollo/optimizedClient';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';

import { ModalProvider } from '@src/context/modalContext';

function App() {
  const isCacheOptimizationEnabled = useFeatureFlag(
    FeatureFlag.INVOICES_CACHE_OPTIMIZATION
  );

  const appClient = React.useMemo(
    () => (isCacheOptimizationEnabled ? optimizedClient : client),
    [isCacheOptimizationEnabled]
  );
  return (
    <ModalProvider>
      <ApolloProvider client={appClient}>
        <SettingsProvider>
          <IntlProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <GeneralRoutes />
              <ToastContainer />
              <SnackbarContainer />
              <GlobalModals />
            </ThemeProvider>
          </IntlProvider>
        </SettingsProvider>
      </ApolloProvider>
    </ModalProvider>
  );
}

export default App;
