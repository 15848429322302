import React, { useCallback } from 'react';

import {
  useModalContext,
  open as openModal,
  close as closeModal,
} from '@src/context/modalContext';

type ModalElement<ModalProps> = React.FC<ModalProps>;
type ModalManager<ModalProps> = {
  isOpen: boolean;
  props: ModalProps;
  open: void extends ModalProps ? () => void : (props: ModalProps) => void;
  close: () => void;
};

function useModal<ModalProps = void>(
  modalElement: ModalElement<ModalProps>,
  zIndex?: 40 | 50
): ModalManager<ModalProps> {
  const { dispatch, state } = useModalContext();

  const modalIndex = zIndex || 40;
  const componentName = modalElement.displayName;
  const modalState = state;
  const props = Object.values(modalState).find(
    (modal) => modal.name === componentName
  );
  const open = useCallback<
    void extends ModalProps ? () => void : (props: ModalProps) => void
  >(
    (payload = {}) => {
      if (componentName) {
        openModal(dispatch, {
          zIndex: modalIndex,
          name: componentName,
          ...payload,
        });
      }
    },
    [componentName, modalIndex]
  );

  const close = useCallback<() => void>(() => {
    const closeIndex = Object.keys(modalState).find(
      (key) => modalState[key].name === componentName
    );
    if (zIndex) {
      closeModal(dispatch, { zIndex });
    } else if (closeIndex) {
      closeModal(dispatch, { zIndex: parseInt(closeIndex) });
    }
  }, [modalState, componentName]);

  if (!modalElement.displayName) {
    throw new Error(`${modalElement.name}: needs to implement displayName`);
  }

  return {
    isOpen: !!props?.isModalOpen,
    props: (props as unknown) as ModalProps,
    open,
    close,
  };
}

export default useModal;
