import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import * as S from './CreateBusinessForm.styles';
import Card from '@src/components/ui/Card';
import Button from '@src/components/ui/Button';
import Tooltip from '@src/components/ui/Tooltip';
import TextField from '@src/components/ui/TextField';
import useQueryParams from '@src/hooks/useQueryParams';
import useToast from '@src/hooks/useToast';
import useBusinessUserValidationToken from '@src/hooks/useBusinessUserValidationToken';
import useSettings from '@src/hooks/useSettings';
import { trackEvent } from '@src/lib/analytics';
import { CREATE_BUSINESS_EVENT } from '@src/constants/events';
import useCreateBusinessMutation from '@src/hooks/useCreateBusinessMutation';

const CreateBusinessForm = () => {
  const intl = useIntl();
  const { currentUser, setCurrentBusinessId } = useSettings();
  const location = useLocation();
  const [query] = useQueryParams();
  const { toast } = useToast();
  const history = useHistory();
  const { mutate: createBusiness, loading } = useCreateBusinessMutation();
  const defaultRfc = (
    query?.taxpayerIdentifier ||
    query?.rfc ||
    ''
  ).toUpperCase();
  const [rfc, setRfc] = useState(defaultRfc);
  const { mutate: validateUser } = useBusinessUserValidationToken();

  const callBusinessUserValidationToken = async () => {
    try {
      await validateUser({
        variables: {
          token: query?.token,
        },
      });
    } catch (e: any) {
      toast.error('Error', e.message);
    }
  };

  useEffect(() => {
    if (!currentUser?.businessUserValidation && query?.token) {
      callBusinessUserValidationToken();
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      taxpayerIdentifier: defaultRfc,
      businessName: query.businessName,
      satPassword: '',
    },
  });

  const onSubmit = async ({
    businessName,
    taxpayerIdentifier,
    satPassword,
  }) => {
    try {
      const newBusiness = await createBusiness({
        variables: {
          business: {
            name: businessName,
          },
          taxPayerInfo: {
            taxpayerIdentifier: taxpayerIdentifier.toUpperCase(),
            satPassword: satPassword.trim(),
          },
        },
      });
      const businessId = newBusiness.data?.createBusiness?.business?.id;
      if (businessId) {
        setCurrentBusinessId(businessId);
      }
      trackEvent(CREATE_BUSINESS_EVENT);
      history.replace(`/onboarding/providers${location.search || ''}`);
    } catch (e: any) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al crear cuenta',
          id: 'zDYQ2G',
        }),
        e.message
      );
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Card padding="8">
        <TextField
          {...register('taxpayerIdentifier', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            maxLength: {
              value: 13,
              message: intl.formatMessage({
                defaultMessage:
                  'Debe contener hasta 13 caracteres alfanuméricos.',
                id: 'g7O2+a',
              }),
            },
            minLength: {
              value: 12,
              message: intl.formatMessage({
                defaultMessage:
                  'Debe contener mínimo 12 caracteres alfanuméricos.',
                id: '2vKU/U',
              }),
            },
            pattern: {
              value: /[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}/,
              message: intl.formatMessage({
                defaultMessage: 'No cumple con el formato correcto',
                id: '1yqXVx',
              }),
            },
            setValueAs: (value) => {
              setRfc(value.toUpperCase());
              return value.toUpperCase();
            },
          })}
          value={rfc}
          type="text"
          label={intl.formatMessage({
            defaultMessage: 'RFC',
            id: 'wvCzn7',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ej. ABCDEF123456',
            id: 'PJ1cUH',
          })}
          required
          fullWidth
          autoFocus
          helperText={errors?.taxpayerIdentifier?.message}
          error={!!errors?.taxpayerIdentifier?.message}
          data-testid="taxpayer-identifier-input"
        />

        <TextField
          {...register('businessName', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Razón social',
            id: 'pmiu07',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ej. Acme Inc.',
            id: 'N3TkoY',
          })}
          required
          fullWidth
          helperText={errors?.businessName?.message}
          error={!!errors?.businessName?.message}
          data-testid="business-name-input"
        />
      </Card>

      <Card padding="8">
        <TextField
          {...register('satPassword', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Contraseña CIEC',
            id: 'bNmcp4',
          })}
          required
          type="password"
          fullWidth
          helperText={
            errors?.satPassword?.message || (
              <Tooltip
                id="signup_tooltip"
                place="top"
                width={400}
                title={intl.formatMessage({
                  defaultMessage:
                    'Tu contraseña CIEC la utilizamos únicamente para verificar que la información de tus facturas y proveedores es real y poder determinar tu capacidad de endeudamiento.',
                  id: 'H2TpmL',
                })}
              >
                <S.TooltipSpan>
                  {intl.formatMessage({
                    defaultMessage: '¿Para que te pedimos la contraseña CIEC?',
                    id: 'OtcRJr',
                  })}
                </S.TooltipSpan>
              </Tooltip>
            )
          }
          error={!!errors?.satPassword?.message}
          placeholder={'************'}
          data-testid="password-input"
        />
      </Card>

      <Button
        block
        type="submit"
        disabled={loading}
        data-testid="create-business-submit"
        loading={loading}
      >
        {intl.formatMessage({
          defaultMessage: 'Continuar',
          id: '1JuMPJ',
        })}
      </Button>
    </S.Form>
  );
};

export default CreateBusinessForm;
