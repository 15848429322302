const {
  NODE_ENV,
  REACT_APP_API_HOSTNAME,
  REACT_APP_API_PORT,
  REACT_APP_API_ROOT,
  REACT_APP_API_PUBLIC_ROOT,
  REACT_APP_GRAPHQL_ENDPOINT,
} = process.env;

const isDevelopment = NODE_ENV === 'development';
const isHost = !isDevelopment && REACT_APP_API_HOSTNAME;
const isPort = !isDevelopment && isHost && REACT_APP_API_PORT;

export const HOST_NAME = isHost ? REACT_APP_API_HOSTNAME : '';
export const PORT = isPort ? `:${REACT_APP_API_PORT}` : '';
export const ROOT = REACT_APP_API_ROOT || '/';
export const PUBLIC_ROOT = REACT_APP_API_PUBLIC_ROOT || '/';
export const API_URL = HOST_NAME + PORT + ROOT;
export const PUBLIC_API_URL = HOST_NAME + PORT + PUBLIC_ROOT;
export const GRAPHQL_NAMESPACE = REACT_APP_GRAPHQL_ENDPOINT || 'graphql';
