import { gql } from '@apollo/client';

export const CREATE_MAILBOX_BUSINESS_SUPPLIER = gql`
  mutation CreateMailboxBusinessSupplier(
    $businessId: ID!
    $invoiceId: ID!
    $email: String!
    $paymentTerms: Int
    $taxPayerInfo: TaxPayerInfoInput!
    $bankingInfo: BankingInfoInput
  ) {
    createMailboxBusinessSupplier(
      businessId: $businessId
      invoiceId: $invoiceId
      email: $email
      paymentTerms: $paymentTerms
      taxPayerInfo: $taxPayerInfo
      bankingInfo: $bankingInfo
    ) {
      id
      paymentTerms
    }
  }
`;
