import React, { useEffect, useState } from 'react';
import SearchField from '@src/components/ui/Search';
import useQueryParams from '@src/hooks/useQueryParams';
import { useIntl } from 'react-intl';
import { INITIAL_PAGINATION_PARAMS } from '@src/utils/pagination';

interface SearchProps {
  fullWidth?: boolean;
  placeholder?: string;
  paramName?: string;
  onClear?: () => void;
}

export default function Search(props: SearchProps) {
  const intl = useIntl();
  const [{ search }, setQuery] = useQueryParams();
  const [value, setValue] = useState<string>(search);
  const { fullWidth, placeholder, paramName = 'search' } = props;

  useEffect(() => {
    setValue(search || '');
  }, [search]);

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      const { value } = event.currentTarget;
      setQuery({
        ...INITIAL_PAGINATION_PARAMS,
        [paramName]: value,
      });
    }
    if (event.key === 'Escape') {
      setValue('');
      setQuery({
        ...INITIAL_PAGINATION_PARAMS,
        [paramName]: undefined,
      });
    }
  };

  const handleClear = () => {
    setQuery({
      ...INITIAL_PAGINATION_PARAMS,
      [paramName]: undefined,
    });
    setValue('');
  };

  const handleInputChange = (event) => {
    const { value } = event.currentTarget;
    setValue(value);

    if (!value) {
      handleClear();
    }
  };

  return (
    <SearchField
      fullWidth={fullWidth}
      placeholder={
        placeholder ||
        intl.formatMessage({
          defaultMessage: 'Search',
          id: 'xmcVZ0',
        })
      }
      value={value}
      onKeyDown={handleInputKeyDown}
      onChange={handleInputChange}
      onClear={handleClear}
    />
  );
}
