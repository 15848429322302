import React from 'react';
import { StyledSidebarItem, Alert } from './SidebarItem.styles';
import { NavLink } from 'react-router-dom';
import Tooltip from '@src/components/ui/Tooltip';
import { trackEvent } from '@src/lib/analytics';
import { NAVBAR_ITEM_CLICKED } from '@src/constants/events';

export interface SidebarItemProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  label: string;
  icon: React.ReactNode;
  alert?: boolean;
  href: string;
  isExternal?: boolean;
  exact?: boolean;
  isActive?: (match: any, location: any) => boolean;
}

export default function SidebarItem({
  icon,
  alert,
  href,
  isExternal,
  exact,
  label,
  isActive,
  ...otherProps
}: SidebarItemProps) {
  const handleItemClick = () => {
    trackEvent(NAVBAR_ITEM_CLICKED, {
      href,
    });
  };

  return (
    <StyledSidebarItem {...otherProps} onClick={handleItemClick}>
      <Tooltip title={label} id={label} place="right" effect="solid">
        {isExternal ? (
          <a href={href} target="_blank" rel="noreferrer">
            {icon ? (
              <div>
                {icon}
                {alert ? <Alert /> : null}
              </div>
            ) : null}
          </a>
        ) : (
          <NavLink
            isActive={isActive}
            exact={exact}
            to={href}
            activeClassName="sidebar-item-active"
          >
            {icon ? (
              <div>
                {icon}
                {alert ? <Alert /> : null}
              </div>
            ) : null}
          </NavLink>
        )}
      </Tooltip>
    </StyledSidebarItem>
  );
}
