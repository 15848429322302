import React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import * as S from './CreateBusinessForm.styles';
import { Button, Tooltip, Header, TextField } from '@src/components/ui';
import { trackEvent } from '@src/lib/analytics';
import { CREATE_BUSINESS_EVENT } from '@src/constants/events';
import useCreateBusinessMutation from '@src/hooks/useCreateBusinessMutation';
import useToast from '@src/hooks/useToast';
import useSettings from '@src/hooks/useSettings';

interface CreateBusinessModalProps {
  onClose?: () => void;
}

function CreateBusinessModal({ onClose }: CreateBusinessModalProps) {
  const intl = useIntl();
  const { setCurrentBusinessId } = useSettings();
  const { toast } = useToast();
  const { mutate: createBusiness, loading } = useCreateBusinessMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const onSubmit = async ({
    businessName,
    taxpayerIdentifier,
    satPassword,
  }) => {
    try {
      const newBusiness = await createBusiness({
        variables: {
          business: {
            name: businessName,
          },
          taxPayerInfo: {
            taxpayerIdentifier: taxpayerIdentifier.toUpperCase(),
            satPassword,
          },
        },
      });
      const businessId = newBusiness.data?.createBusiness?.business?.id;

      if (businessId) {
        setCurrentBusinessId(businessId);
      }
      onClose && onClose();
      trackEvent(CREATE_BUSINESS_EVENT);
    } catch (e: any) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al crear cuenta',
          id: 'zDYQ2G',
        })
      );
    }
  };

  return (
    <S.FormContainer>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Nueva razón social',
          id: 'OUTwCt',
        })}
      </Header>
      <p>
        {intl.formatMessage({
          defaultMessage:
            'Completa la información de tu nueva razón social para continuar.',
          id: 'I5IS8m',
        })}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...register('taxpayerIdentifier', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
            maxLength: {
              value: 13,
              message: intl.formatMessage({
                defaultMessage:
                  'Debe contener hasta 13 caracteres alfanuméricos.',
                id: 'g7O2+a',
              }),
            },
            minLength: {
              value: 12,
              message: intl.formatMessage({
                defaultMessage:
                  'Debe contener mínimo 12 caracteres alfanuméricos.',
                id: '2vKU/U',
              }),
            },
            pattern: {
              value: /[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}/,
              message: intl.formatMessage({
                defaultMessage: 'No cumple con el formato correcto',
                id: '1yqXVx',
              }),
            },
            setValueAs: (value) => value.toUpperCase(),
          })}
          type="text"
          label={intl.formatMessage({
            defaultMessage: 'RFC',
            id: 'wvCzn7',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ej. ABCDEF123456',
            id: 'PJ1cUH',
          })}
          required
          fullWidth
          autoFocus
          helperText={errors?.taxpayerIdentifier?.message}
          error={!!errors?.taxpayerIdentifier?.message}
          data-testid="taxpayer-identifier-input"
        />

        <TextField
          {...register('businessName', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Razón social',
            id: 'pmiu07',
          })}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ej. Acme Inc.',
            id: 'N3TkoY',
          })}
          required
          fullWidth
          helperText={errors?.businessName?.message}
          error={!!errors?.businessName?.message}
          data-testid="business-name-input"
        />

        <TextField
          {...register('satPassword', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Contraseña CIEC',
            id: 'bNmcp4',
          })}
          required
          type="password"
          fullWidth
          helperText={
            errors?.satPassword?.message || (
              <Tooltip
                id="signup_tooltip"
                place="top"
                width={400}
                title={intl.formatMessage({
                  defaultMessage:
                    'Tu contraseña CIEC la utilizamos únicamente para verificar que la información de tus facturas y proveedores es real y poder determinar tu capacidad de endeudamiento.',
                  id: 'H2TpmL',
                })}
              >
                <S.TooltipSpan>
                  {intl.formatMessage({
                    defaultMessage: '¿Para que te pedimos la contraseña CIEC?',
                    id: 'OtcRJr',
                  })}
                </S.TooltipSpan>
              </Tooltip>
            )
          }
          error={!!errors?.satPassword?.message}
          placeholder={'************'}
          data-testid="password-input"
        />

        <div className="buttons-container">
          <Button variant="ghosted" onClick={onClose} data-testid="cancel">
            {intl.formatMessage({
              defaultMessage: 'Cancelar',
              id: 'nZLeaQ',
            })}
          </Button>
          <Button
            type="submit"
            loading={loading}
            data-testid="create-business-submit"
          >
            {intl.formatMessage({
              defaultMessage: 'Continuar',
              id: '1JuMPJ',
            })}
          </Button>
        </div>
      </form>
    </S.FormContainer>
  );
}

CreateBusinessModal.displayName = 'CreateBusinessModal';

export default CreateBusinessModal;
