import { gql } from '@apollo/client';
import { SupplierRelationship } from '@src/types/models';

const PROVIDER_OPTION_ATTRIBUTES = gql`
  fragment ProviderOptionAttributes on BusinessRelationship {
    id
    supplier {
      name
    }
  }
`;

export type ProviderOptionAttributes = Pick<
  SupplierRelationship,
  'id' | 'supplier'
>;

export default PROVIDER_OPTION_ATTRIBUTES;
