import React from 'react';
import { useIntl } from 'react-intl';
import { UseFormRegister } from 'react-hook-form';

import { TextField } from '@src/components/ui';
import { TextFieldProps } from '@src/components/ui/TextField/types';

interface PaymentConceptFieldProps extends TextFieldProps {
  name: string;
  register: UseFormRegister<any>;
}

const PaymentConceptField = ({
  name,
  register,
  ...props
}: PaymentConceptFieldProps) => {
  const intl = useIntl();
  return (
    <TextField
      type="text"
      {...register(name, {
        maxLength: {
          value: 40,
          message: intl.formatMessage(
            {
              defaultMessage: 'Debe ser menor a {length} caracteres',
              id: 'KlZdvB',
            },
            {
              length: 40,
            }
          ),
        },
      })}
      {...props}
    />
  );
};

export default PaymentConceptField;
