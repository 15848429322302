import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 32vw;
  max-width: 90vw;
`;

export const Form = styled.form`
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const FavoriteButton = styled.div`
  display: flex;
  align-items: center;
  p {
    padding-top: ${({ theme }) => theme.spacing[1]};
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing[2]};
  }
`;

export const Actions = styled.div`
  margin-top: ${({ theme }) => theme.spacing[8]};
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;
