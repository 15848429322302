import React, { useMemo, Fragment } from 'react';
import { useIntl, IntlShape } from 'react-intl';

import messages from './messages';
import Tooltip from '@src/components/ui/Tooltip';
import * as S from './PaymentStatusBadge.styles';

import { InvoicePaymentStatus } from '@src/types/enums';

import { ReactComponent as Complete } from './img/complete-payment.svg';
import { ReactComponent as Partial } from './img/partial-payment.svg';
import { ReactComponent as Pending } from './img/pending-payment.svg';

interface PaymentStatusBadgeProps {
  status: keyof typeof InvoicePaymentStatus | 'CLOSED';
  id: string;
  tooltip?: boolean;
}

export function optionsForPaymentStatus(
  intl?: IntlShape
): Array<{ text: string; value: string }> {
  if (!intl || !intl.formatMessage) {
    return Object.values(InvoicePaymentStatus).map((opt) => ({
      text: opt,
      value: opt,
    }));
  }

  return Object.values(InvoicePaymentStatus).map((opt) => ({
    text: messages[opt] ? intl.formatMessage(messages[opt]) : opt,
    value: opt,
  }));
}

export function PaymentStatusBadge({
  status,
  id,
  tooltip = false,
}: PaymentStatusBadgeProps) {
  const intl = useIntl();
  const memoizedId = useMemo(() => `${id}-${Math.random()}`, [id]);

  let icon;

  switch (status) {
    case InvoicePaymentStatus.PAID:
      icon = <Complete />;
      break;
    case InvoicePaymentStatus.PENDING:
      icon = <Pending />;
      break;
    case InvoicePaymentStatus.PARTIAL:
      icon = <Partial />;
      break;
    case InvoicePaymentStatus.CLOSED:
      icon = <Complete />;
      break;
    default:
      icon = <Pending />;
      break;
  }

  return (
    <Fragment>
      {tooltip ? (
        <Tooltip
          id={memoizedId}
          title={intl.formatMessage(messages[status])}
          place="top"
          effect="solid"
        >
          {icon}
        </Tooltip>
      ) : (
        <S.PaymentStatusBadge>
          {icon}
          <p>{intl.formatMessage(messages[status])}</p>
        </S.PaymentStatusBadge>
      )}
    </Fragment>
  );
}

export default PaymentStatusBadge;
