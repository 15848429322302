import { gql } from '@apollo/client';

import { PaginatedResponse } from '@src/types/utils';

import { InvoiceApprovalStatus } from '@src/types/enums';

const RECEIVED_CREDIT_NOTES_QUERY = gql`
  query ReceivedCreditNotes(
    $businessId: ID!
    $supplierHashId: ID!
    $uncredited: Boolean
    $approvalStatus: [InvoiceApprovalStatusEnum!]
  ) {
    receivedCreditNotes(
      businessId: $businessId
      supplierHashId: $supplierHashId
      uncredited: $uncredited
      approvalStatus: $approvalStatus
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          currency
          total
          pendingCredit
          appliedCredit
        }
      }
      totalCount
    }
  }
`;

export type CreditNote = {
  id: string;
  currency: string;
  total: number;
  pendingCredit: number;
  appliedCredit: number;
};

export type ReceivedCreditNotesReturn = {
  receivedCreditNotes: PaginatedResponse<CreditNote>;
};

export type ReceivedCreditNotesVariables = {
  businessId?: string;
  supplierHashId: string;
  uncredited?: boolean;
  approvalStatus?: InvoiceApprovalStatus[];
};

export default RECEIVED_CREDIT_NOTES_QUERY;
