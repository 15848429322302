import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Inbox: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
        stroke="none"
        strokeWidth="0"
      >
        <path
          fillRule="evenodd"
          stroke="none"
          d="M16.76 3H7.24C6.68187 3.0003 6.13437 3.15628 5.66004 3.45042C5.18617 3.74427 4.80358 4.16436 4.5552 4.66354L4.55447 4.665L1.11484 11.5343C1.04151 11.6734 1 11.8318 1 12V18V19C1 20.1046 1.89543 21 3 21H4H20H21C22.1046 21 23 20.1046 23 19V18V12C23 11.9873 22.9998 11.9745 22.9993 11.9618C22.9935 11.8079 22.9529 11.6628 22.8852 11.5343L19.4455 4.665L19.4449 4.66368C19.1965 4.16444 18.8139 3.7443 18.34 3.45042C17.8656 3.15628 17.3181 3.0003 16.76 3ZM20.3809 11L17.6558 5.55773L17.6545 5.555C17.5717 5.38839 17.4441 5.24818 17.2859 5.15014C17.1279 5.05213 16.9456 5.00013 16.7597 5H7.24034C7.05436 5.00013 6.87211 5.05213 6.71405 5.15014C6.55594 5.24819 6.42832 5.38839 6.34553 5.555L6.37504 5.5L6.34417 5.55773L3.61908 11H8C8.33435 11 8.64658 11.1671 8.83205 11.4453L10.5352 14H13.4648L15.1679 11.4453C15.3534 11.1671 15.6656 11 16 11H20.3809Z"
        />
      </svg>
    );
  }
);

Inbox.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Inbox.displayName = 'Inbox';

export default Inbox;
