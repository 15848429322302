import React, { memo, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  useForm,
  useFieldArray,
  Controller,
  UseFormHandleSubmit,
} from 'react-hook-form';
import { useHistory } from 'react-router';

import {
  Card,
  Table,
  TableCell,
  TableHeaderCell,
  TextField,
  ModalHeader,
} from '@src/components/ui';
import * as S from './InvoicePaymentForm.styles';
import { Grid } from '@src/components/shared/layouts';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import { PaymentMethodDropdownProps } from '@src/components/shared/dropdown/PaymentMethodDropdown';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import InsufficientCreditNotification from './components/InsufficientCreditNotification';
import InsufficientFundsNotification from '@src/components/shared/alerts/InsufficientFundsNotification';
import {
  Container,
  Spacer,
  FlexContainer,
} from '@src/components/shared/layouts';
import InvoicePaymentFormHeader from './components/InvoicePaymentFormHeader/InvoicePaymentFormHeader';
import MultiEmailSelect from '@src/components/shared/select/MultiEmailSelect';
import InvoicePaymentMethodDropdown from './components/InvoicePaymentMethodDropdown';
import OutstandingAmount from './components/OutstandingAmount';
import SecurePaymentsNotification from './components/SecurePaymentsNotification';
import useUserPaymentMethods from '@src/hooks/useUserPaymentMethods';
import STPPaymentTimeAlert from '@src/components/shared/alerts/STPPaymentTimeAlert';
import PaymentConceptField from '@src/components/shared/react-hook-fields/PaymentConceptField';

import { BankingInfo, Invoice, PaymentMethod } from '@src/types/models';
import { InvoicePaymentType } from '@src/types/enums';
import { stringToCents } from '@src/utils/currency';

type PartialInvoice = Pick<
  Invoice,
  | 'id'
  | 'globalStatus'
  | 'expiryDate'
  | 'outstandingBalance'
  | 'emitterTaxpayerName'
  | 'businessEmitter'
  | 'businessRelationship'
  | 'receiverCountryTaxPayerInfo'
  | 'factoringOffer'
>;

type InvoiceField = {
  amount: number;
  paymentConcept: string;
  paymentMethod?: PaymentMethod;
  email: string[];
};

interface FormData {
  invoices: Array<PartialInvoice & Required<InvoiceField>>;
}

interface InvoicePaymentFormProps {
  title: string;
  loading?: boolean;
  submitting?: boolean;
  ctaCustomButton?: (handleSubmit: UseFormHandleSubmit<any>) => JSX.Element;
  ctaLabel?: string;
  wallet?: 'credit' | 'balance';
  invoices: Array<PartialInvoice & InvoiceField>;
  invoiceCount?: number;
  PaymentMethodDropdownProps?: PaymentMethodDropdownProps;
  hiddenTerms?: boolean;
  onSubmit: (data: FormData) => void;
  onReject?: () => void;
  onRemove?: (id: string, callback?: () => void) => void;
}

const buildNextList = (originalList, newValue) =>
  [...(originalList || []), newValue].filter(
    (value, index, self) => self.indexOf(value) === index
  );

function InvoicePaymentForm({
  invoices,
  invoiceCount,
  loading,
  submitting,
  title,
  ctaCustomButton,
  ctaLabel,
  wallet,
  PaymentMethodDropdownProps,
  hiddenTerms,
  onSubmit,
  onReject,
  onRemove,
}: InvoicePaymentFormProps) {
  const intl = useIntl();
  const history = useHistory();
  const defaultPaymentMethods = useUserPaymentMethods();
  const paymentMethods =
    PaymentMethodDropdownProps?.paymentMethods || defaultPaymentMethods;
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      termsAccepted: false,
      invoices,
    },
  });
  const { fields, remove } = useFieldArray({
    control,
    keyName: 'key',
    name: 'invoices',
  });

  useEffect(() => {
    if (invoices.length > 0 && fields.length === 0) {
      reset({ invoices });
    }
  }, [invoices, fields]);

  const updateSupplierField = useCallback(
    (businessRelationshipId: string, key: keyof InvoiceField, value: any) => {
      fields.forEach((invoice, index) => {
        if (invoice.businessRelationship?.id === businessRelationshipId) {
          setValue(`invoices.${index}.${key}`, value, {
            shouldValidate: true,
          });
        }
      });
    },
    [fields]
  );

  const handleBankingInfoCreate = useCallback(
    (businessRelationshipId: string, bankingInfo: BankingInfo) => {
      fields.forEach((invoice, index) => {
        if (invoice.businessRelationship?.id === businessRelationshipId) {
          setValue(
            `invoices.${index}.businessRelationship.bankingInfo`,
            bankingInfo
          );
        }
      });
    },
    [fields]
  );

  const handleEmailCreate = useCallback(
    (businessRelationshipId: string, email: string) => {
      fields.forEach((invoice, index) => {
        if (invoice.businessRelationship?.id === businessRelationshipId) {
          const contact = {
            id: '',
            defaultContact: false,
            contactable: { email, firstName: '', lastName: '', id: '' },
          };
          setValue(
            `invoices.${index}.businessRelationship.providerContacts`,
            buildNextList(
              invoice?.businessRelationship?.providerContacts,
              contact
            )
          );
        }
      });
    },
    [fields]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader
        onClose={history.goBack}
        title={title}
        actions={
          <InvoicePaymentFormHeader
            control={control}
            loading={submitting}
            ctaLabel={ctaLabel}
            wallet={wallet}
            fieldNameToSum="invoices"
            hiddenTerms={hiddenTerms}
            onReject={onReject}
            ctaCustomButton={ctaCustomButton}
            handleSubmit={handleSubmit}
          />
        }
        sticky
      />
      <Container maxWidth={false}>
        <Spacer margin="8" />
        <S.NotificationWrapper>
          <STPPaymentTimeAlert />
          {wallet === 'credit' ? (
            <InsufficientCreditNotification control={control} />
          ) : (
            <InsufficientFundsNotification
              control={control}
              fieldNameToSum="invoices"
            />
          )}
        </S.NotificationWrapper>
        <Card padding="8">
          <Table columnsWidth={[120, 180, 150, 180, 320, 300, 240, 100]}>
            <SelectableTableHeader cancelable>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Folio',
                  id: 'x+TaH9',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Proveedor',
                  id: 'RMvOYP',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Monto restante',
                  id: 'SZBLWN',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Monto a pagar',
                  id: 'ZzlLUg',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Beneficiario',
                  id: 'dtkaPZ',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Notificar al proveedor',
                  id: '7QMfwz',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Concepto de pago',
                  id: 'z8Gzqo',
                })}
              </TableHeaderCell>
            </SelectableTableHeader>
            <tbody>
              {fields.map((invoice, index) => (
                <SelectableTableRow
                  cancelable
                  key={invoice.id}
                  onCancel={() => {
                    const handleRemove = () => {
                      if (fields.length === 1) history.goBack();
                      remove(index);
                    };

                    if (onRemove) {
                      onRemove(invoice.id, handleRemove);
                    } else {
                      handleRemove();
                    }
                  }}
                >
                  <TableCell>
                    {invoice?.receiverCountryTaxPayerInfo?.folio || '-'}
                  </TableCell>
                  <TableCell>{invoice.emitterTaxpayerName}</TableCell>
                  <TableCell>
                    <OutstandingAmount
                      control={control}
                      totalCents={invoice.outstandingBalance}
                      name={`invoices.${index}.amount`}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      {...register(`invoices.${index}.amount`, {
                        validate: {
                          isLessEqualThanTotal: (v) =>
                            stringToCents(v || 0) <=
                              invoice.outstandingBalance ||
                            intl.formatMessage({
                              defaultMessage:
                                'No puede ser mayor al monto restante',
                              id: 'Jum/BY',
                            }),
                          positive: (v) =>
                            v > 0 ||
                            intl.formatMessage({
                              defaultMessage: 'Debe ser mayor a 0',
                              id: '1CA8ow',
                            }),
                        },
                        setValueAs: (value) => parseCurrency(`${value}`),
                      })}
                      fullWidth
                      id={`invoices.${index}.amount`}
                      type="currency"
                      hiddenLabel="Outstanding"
                      error={!!errors?.invoices?.[index]?.amount?.message}
                      helperText={errors?.invoices?.[index]?.amount?.message}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      rules={{
                        required: intl.formatMessage({
                          defaultMessage: 'Campo requerido',
                          id: '7Vvfe3',
                        }),
                      }}
                      name={`invoices.${index}.paymentMethod`}
                      render={({
                        field: { onChange, ...fieldProps },
                        fieldState: { error },
                        formState: { isSubmitting },
                      }) => (
                        <InvoicePaymentMethodDropdown
                          invoice={invoice}
                          paymentMethods={paymentMethods}
                          supplierName={invoice?.businessEmitter?.name}
                          businessRelationshipId={
                            invoice?.businessRelationship?.id
                          }
                          bankingInfo={
                            invoice?.businessRelationship?.bankingInfo
                          }
                          fullWidth
                          disabled={isSubmitting}
                          error={!!error?.message}
                          onNewBankingInfo={handleBankingInfoCreate}
                          onChange={(method: PaymentMethod) => {
                            onChange(method);
                            if (invoice?.businessRelationship?.id) {
                              updateSupplierField(
                                invoice.businessRelationship.id,
                                'paymentMethod',
                                method
                              );
                            }
                          }}
                          {...fieldProps}
                          {...PaymentMethodDropdownProps}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`invoices.${index}.email`}
                      rules={{
                        required:
                          ([
                            InvoicePaymentType.HIGO_PAYMENT,
                            InvoicePaymentType.REQUEST_BANKING_INFO,
                          ] as any[]).includes(
                            watch(`invoices.${index}.paymentMethod`)
                              ?.paymentType
                          ) &&
                          intl.formatMessage({
                            defaultMessage: 'Campo requerido',
                            id: '7Vvfe3',
                          }),
                      }}
                      render={({
                        field: { onChange, value, ...fieldProps },
                        fieldState: { error },
                      }) => {
                        const isRequestBankingInfo =
                          watch(`invoices.${index}.paymentMethod`)
                            ?.paymentType ===
                          InvoicePaymentType.REQUEST_BANKING_INFO;
                        return (
                          <MultiEmailSelect
                            options={
                              invoice?.businessRelationship?.providerContacts
                                ?.map(
                                  (contact) => contact?.contactable?.email || ''
                                )
                                ?.filter(Boolean) || []
                            }
                            fullWidth
                            maximumSelectionLength={
                              isRequestBankingInfo ? 1 : undefined
                            }
                            error={!!error?.message}
                            helperText={
                              error?.message ||
                              (isRequestBankingInfo
                                ? intl.formatMessage({
                                    defaultMessage:
                                      'Solo puedes solicitar a un correo electrónico',
                                    id: 'g1TVAY',
                                  })
                                : undefined)
                            }
                            value={value}
                            onNewOption={(email) => {
                              if (invoice?.businessRelationship?.id) {
                                handleEmailCreate(
                                  invoice.businessRelationship.id,
                                  email
                                );
                              }
                            }}
                            id={`invoices.${index}.email`}
                            hiddenLabel="email"
                            placeholder={intl.formatMessage({
                              defaultMessage:
                                'Selecciona los correos a notificar',
                              id: 'fC1RGL',
                            })}
                            onChange={(emails) => {
                              onChange(emails);
                              if (invoice?.businessRelationship?.id) {
                                updateSupplierField(
                                  invoice.businessRelationship.id,
                                  'email',
                                  emails
                                );
                              }
                            }}
                            {...fieldProps}
                          />
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <PaymentConceptField
                      fullWidth
                      hiddenLabel="Payment concept"
                      id={`invoices.${index}.paymentConcept`}
                      name={`invoices.${index}.paymentConcept`}
                      type="text"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Pago Factura',
                        id: 'RSOShv',
                      })}
                      error={
                        !!errors?.invoices?.[index]?.paymentConcept?.message
                      }
                      helperText={
                        errors?.invoices?.[index]?.paymentConcept?.message
                      }
                      register={register}
                    />
                  </TableCell>
                </SelectableTableRow>
              ))}

              {loading &&
                [...Array(invoiceCount || 10).keys()].map((i) => (
                  <SelectableTableRow key={i}>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </SelectableTableRow>
                ))}
            </tbody>
          </Table>
        </Card>
        <Spacer margin="8" />
        <FlexContainer justifyContent="center">
          <Grid container justifyContent="center">
            <Grid item xs={12} md={4}>
              <SecurePaymentsNotification />
            </Grid>
          </Grid>
        </FlexContainer>
      </Container>
    </form>
  );
}

export default memo(InvoicePaymentForm);
