import { Business, BusinessUser, CurrentUser, User } from '@src/types/models';

export const getCurrentBusiness = (
  user?: User,
  currentBusinessId?: string
): Business | undefined => {
  if (!user) return;

  const firstBusiness = user.businesses[0];
  if (!currentBusinessId) return firstBusiness;

  return (
    user.businesses.find((b) => b.id === currentBusinessId) || firstBusiness
  );
};

export const getCurrentBusinessUser = (
  user?: User,
  business?: Business
): BusinessUser | undefined =>
  business &&
  user?.businessUsers?.find(
    (businessUser) => businessUser.business.id === business?.id
  );

export const getCurrentUser = (
  user?: User,
  business?: Business
): CurrentUser | undefined => {
  if (!user) return;

  const businessUser = getCurrentBusinessUser(user, business);
  return {
    ...user,
    features: businessUser?.features || [],
    permissions: businessUser?.permissions || [],
    role: businessUser?.role,
  };
};
