import { gql } from '@apollo/client';

const SYNC_INVOICES_WITH_SAT_MUTATION = gql`
  mutation SyncInvoicesWithSat($businessId: ID!, $taxPayerInfoId: ID!) {
    syncInvoicesWithSat(
      businessId: $businessId
      taxPayerInfoId: $taxPayerInfoId
    ) {
      id
    }
  }
`;

export type SyncInvoicesWithSatParams = {
  businessId: string;
  taxPayerInfoId: string;
};

export type SyncInvoicesWithSatReturn = {
  syncInvoicesWithSat: {
    id: string;
  };
};

export default SYNC_INVOICES_WITH_SAT_MUTATION;
