import { defineMessages } from 'react-intl';

export default defineMessages({
  createProviderContactSuccessTitle: {
    defaultMessage: 'Contacto Agregado',
    id: '5bjD60',
  },
  createProviderContactMessage: {
    defaultMessage: 'La información de tu contacto se ha creado correctamente',
    id: 'If8lmi',
  },
  createProviderContactError: {
    defaultMessage: 'Error al crear el contacto',
    id: 'hVL5ot',
  },
  updateProviderContactSuccessTitle: {
    defaultMessage: 'Contacto actualizado',
    id: '1s8c5+',
  },
  updateProviderContactMessage: {
    defaultMessage:
      'La información de tu contacto se ha actualizado correctamente',
    id: 'R8A4/Y',
  },
  updateProviderContactError: {
    defaultMessage: 'Error al actualizar el contacto',
    id: 'oZm2Kw',
  },
  contactNamePlaceholder: {
    defaultMessage: 'Nombre del contacto',
    id: 'y79+TI',
  },
  contactLastnamePlaceholder: {
    defaultMessage: 'Apellido del contacto',
    id: 'KspKkg',
  },
  contactEmailPlaceholder: {
    defaultMessage: 'Email del contacto',
    id: 'NQ7hzK',
  },
  contactNamelabel: {
    defaultMessage: 'Nombre',
    id: 'hCOqfl',
  },
  contactLastNamelabel: {
    defaultMessage: 'Apellido',
    id: 'cEArc/',
  },
});
