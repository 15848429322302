import styled from 'styled-components';

export const SignInContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[4]};
  text-align: center;
  p {
    margin-bottom: 0;
  }
`;

export const SignInLink = styled.span`
  color: ${({ theme }) => theme.colors.primary[500]};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
