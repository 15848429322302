import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { TRANSACTIONS_VIEW_QUERY } from '@src/graphql/queries';
import useSettings from '@src/hooks/useSettings';
import messages from './messages';
import * as S from './TransactionView.styles';
import Page from '@src/components/shared/Page';
import Alert from '@src/components/ui/Alert';
import Loader from '@src/components/shared/Loader';
import PageTitle from '@src/components/shared/PageTitle';
import Button from '@src/components/ui/Button';
import Transaction from '@src/components/pages/TransactionView/components/Transaction';
import { Send } from '@src/components/ui/Icon/outline';
import ResendEmailModal from '@src/components/shared/modals/ResendEmailModal';
import PaymentsReconciliationModal from '@src/components/shared/modals/PaymentsReconciliationModal';
import useModal from '@src/hooks/useModal';
import Modal from '@src/components/shared/Modal';
import { ENTRY_HIGO_PAYMENT_SENT_ENTRY } from '@src/constants/transactions';

type TransactionsViewParams = {
  transactions_id?: string;
};

export function TransactionsView() {
  const intl = useIntl();
  const { transactions_id } = useParams<TransactionsViewParams>();
  const business = useSettings('business');
  const { open } = useModal(ResendEmailModal);

  const { data, loading, error } = useQuery(TRANSACTIONS_VIEW_QUERY, {
    variables: {
      businessId: business?.id,
      walletEntryHashId: transactions_id,
    },
    skip: !business?.id || !transactions_id,
    fetchPolicy: 'cache-and-network',
  });
  const { walletEntry } = data || {};

  if (loading) {
    return (
      <Page title={intl.formatMessage(messages.loading)}>
        <PageTitle>{intl.formatMessage(messages.loading)}</PageTitle>
        <Loader fullWidth />
      </Page>
    );
  }

  if (error) {
    return (
      <Alert color="danger" hasBorder>
        {intl.formatMessage(messages.error)} {error.message}
      </Alert>
    );
  }

  if (!walletEntry) {
    return null;
  }

  const { entryable } = walletEntry;
  const isHigoPayInvoicePayment =
    entryable.__typename === ENTRY_HIGO_PAYMENT_SENT_ENTRY &&
    entryable?.invoicePayment;

  const handleResendClick = () => {
    open({
      higoPaymentSentEntryId: entryable.id,
      defaultEmail: entryable.lastBusinessUserValidation.email,
    });
  };

  return (
    <Page
      title={intl.formatMessage(messages.title, {
        walletEntryId: walletEntry.hashId,
      })}
    >
      <PageTitle
        goBackLink
        actions={{
          render: () => (
            <div>
              {isHigoPayInvoicePayment && !walletEntry.claimed && (
                <Button
                  color="neutral"
                  variant="outlined"
                  startIcon={<Send />}
                  onClick={handleResendClick}
                >
                  {intl.formatMessage({
                    defaultMessage: 'Reenviar comprobante',
                    id: '2NMTCx',
                  })}
                </Button>
              )}
              {!!walletEntry?.receiptUrl && walletEntry?.claimed && (
                <a
                  href={walletEntry.receiptUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>
                    {intl.formatMessage({
                      defaultMessage: 'Descargar',
                      id: 'LcOlm6',
                    })}
                  </Button>
                </a>
              )}
            </div>
          ),
        }}
      >
        {intl.formatMessage(messages.title, {
          walletEntryId: walletEntry.hashId,
        })}
      </PageTitle>

      {entryable.stpPayment && entryable.stpPayment.errorMessage && (
        <S.NotificationWrapper>
          <Alert color="danger">{entryable.stpPayment.errorMessage}</Alert>
        </S.NotificationWrapper>
      )}

      {!walletEntry?.claimed && (
        <S.NotificationWrapper>
          <Alert color="info">
            {intl.formatMessage(messages.unclaimedAlertMessage)}
          </Alert>
        </S.NotificationWrapper>
      )}

      <Transaction walletEntry={walletEntry} />

      <Modal component={ResendEmailModal} />
      <Modal component={PaymentsReconciliationModal} />
    </Page>
  );
}

export default TransactionsView;
