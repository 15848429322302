import React from 'react';

import { StyledTag } from './Tag.styles';

import type { TagProps } from './types';

export default function Tag(props: TagProps) {
  const { label, color, startIcon, endIcon, ...otherProps } = props;

  return (
    <StyledTag color={color} {...otherProps}>
      {startIcon && <div className="tag-icon">{startIcon}</div>}
      <div className="button-label">{label}</div>
      {endIcon && <div className="tag-icon">{endIcon}</div>}
    </StyledTag>
  );
}
