import { gql } from '@apollo/client';
import { Invoice } from '@src/types/models';

const CREATE_INVOICES_STP_PAYMENTS_MUTATION = gql`
  mutation CreateInvoicesStpPayments(
    $businessId: ID!
    $invoiceStpPayments: [InvoiceStpPaymentInput!]!
  ) {
    createInvoicesStpPayments(
      businessId: $businessId
      invoiceStpPayments: $invoiceStpPayments
    ) {
      id
    }
  }
`;

export type ProviderContactInputType = {
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
};

export type InvoiceStpPaymentInputType = {
  invoiceId: string;
  amount: number;
  paymentConcept?: string;
  providerContacts?: Array<ProviderContactInputType>;
};

export type CreateInvoicesStpPaymentsParams = {
  businessId: string;
  invoiceStpPayments: Array<InvoiceStpPaymentInputType>;
};

export type CreateInvoicesStpPaymentsReturn = Array<Pick<Invoice, 'id'>>;

export default CREATE_INVOICES_STP_PAYMENTS_MUTATION;
