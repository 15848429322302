import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import useToast from '@src/hooks/useToast';
import useSettings from '@src/hooks/useSettings';
import Form from '@src/components/shared/form/InvoicePaymentForm';
import useQuerySilentChange from '@src/hooks/useQuerySilentChange';
import usePaymentsMutation from '@src/components/pages/InvoicePayment/hooks/usePaymentsMutation';
import useInvoices from '@src/hooks/useInvoices';
import useModal from '@src/hooks/useModal';
import ProcessingLoaderPage from '@src/components/shared/ProcessingLoaderPage';
import InvoicePaymentResults from '../InvoicePaymentResults';
import { centsToFloat, floatToCents } from '@src/utils/currency';
import usePaymentDefaults from '@src/hooks/usePaymentDefaults';
import { InvoiceListAttributes } from '@src/graphql/fragments';
import useRejectPaymentInstruction from '@src/hooks/useRejectPaymentInstruction';
import ConfirmationModal from '@src/components/shared/modals/ConfirmationModal';
import { useMutation } from '@apollo/client';
import UPDATE_PAYMENT_INSTRUCTION, {
  UpdatePaymentInstructionMutationReturn,
  UpdatePaymentInstructionMutationVariables,
} from '@src/graphql/mutations/updatePaymentInstruction';
import useErrorReporter from '@src/hooks/useErrorReporter';
import { trackEvent } from '@src/lib/analytics';
import {
  PAYMENT_INSTRUCTION_PAY_CLICKED,
  PAYMENT_INSTRUCTION_REJECT_CLICKED,
} from '@src/constants/events';

import { PaymentInstructionInvoiceStatus } from '@src/types/enums';
import { Invoice, PaymentInstruction, PaymentMethod } from '@src/types/models';

interface InvoicePaymentFormProps {
  invoiceIds: string[];
  paymentInstruction?: PaymentInstruction;
  paymentInstructionId?: number;
}

interface FormData {
  invoices: Array<
    Pick<
      Invoice,
      'id' | 'emitterTaxpayerName' | 'businessEmitter' | 'businessRelationship'
    > & {
      amount: number;
      paymentConcept: string;
      paymentMethod: PaymentMethod;
      email: string[];
    }
  >;
}

function InvoicePaymentForm({
  invoiceIds,
  paymentInstructionId,
  paymentInstruction,
}: InvoicePaymentFormProps) {
  const intl = useIntl();
  const {
    open: openProcessingPayment,
    close: closeProcessingPayment,
  } = useModal(ProcessingLoaderPage, 40);
  const { open: openResults } = useModal(InvoicePaymentResults, 50);
  const search = useQuerySilentChange();
  const errorReporter = useErrorReporter();
  const { business } = useSettings();
  const { toast } = useToast();
  const { data, loading } = useInvoices(invoiceIds, {
    sort: {
      key: 'emitterTaxpayerName',
      order: 'asc',
    },
  });
  const { open: openConfirmationModal } = useModal(ConfirmationModal);

  const invoices = usePaymentDefaults<InvoiceListAttributes>(
    data,
    paymentInstruction &&
      paymentInstruction.paymentInstructionInvoices.map((instruction) => ({
        invoiceId: instruction.invoice.id,
        email: instruction.providerEmails || [],
        paymentConcept: instruction.paymentConcept,
        paymentMethod: {
          value: instruction.clabe,
          paymentType: instruction.paymentMethod,
        },
        amount: centsToFloat(instruction.amountCents),
      }))
  );
  const [submitPayments, { loading: submitting }] = usePaymentsMutation({
    businessId: business?.id,
  });

  const [updatePaymentInstruction] = useMutation<
    UpdatePaymentInstructionMutationReturn,
    UpdatePaymentInstructionMutationVariables
  >(UPDATE_PAYMENT_INSTRUCTION);

  const {
    rejectPaymentInstruction: rejectPaymentInstructionMutation,
    loading: rejecting,
  } = useRejectPaymentInstruction();

  const handleSubmit = (data: FormData) => {
    if (paymentInstructionId) {
      trackEvent(PAYMENT_INSTRUCTION_PAY_CLICKED, {
        payment_instruction_id: paymentInstructionId,
      });
    }

    openProcessingPayment({});
    submitPayments(data.invoices, {
      onSuccess: () => {
        setTimeout(() => {
          closeProcessingPayment();
          openResults({
            invoices: data.invoices.map((i) => ({
              ...i,
              amountCents: floatToCents(i.amount),
              paymentMethod: i.paymentMethod,
              bankingInfo: i.businessRelationship?.bankingInfo,
            })),
          });
        }, 5000);
      },
      onError: () => {
        setTimeout(() => {
          closeProcessingPayment();
          toast.error(
            intl.formatMessage({
              defaultMessage: 'Hubo un problema al enviar los pagos',
              id: 'i9Y1SW',
            })
          );
        }, 5000);
      },
    });
  };

  const handleReject = useCallback(() => {
    if (paymentInstructionId) {
      openConfirmationModal({
        title: intl.formatMessage({
          defaultMessage: '¿Estás seguro que quieres rechazar esta solicitud?',
          id: 'jCdmRJ',
        }),
        text: intl.formatMessage({
          defaultMessage:
            'Esta acción no puede deshacerse y el solicitador ya no verá disponible la solicitud de pago. Moveremos tus facturas a Cuentas por pagar.',
          id: 'bA0Z0r',
        }),
        confirmText: intl.formatMessage({
          defaultMessage: 'Si, rechazar solicitud',
          id: '0gkYRP',
        }),
        cancelText: intl.formatMessage({
          defaultMessage: 'No, conservar solicitud',
          id: 'QIBXLA',
        }),
        onConfirm: () => {
          rejectPaymentInstructionMutation(paymentInstructionId);
          trackEvent(PAYMENT_INSTRUCTION_REJECT_CLICKED, {
            payment_instruction_id: paymentInstructionId,
          });
        },
      });
    }
  }, [
    paymentInstructionId,
    rejectPaymentInstructionMutation,
    openConfirmationModal,
  ]);

  const handleInvoiceRemoval = useCallback(
    (id: string, callback?: () => void) => {
      if (business && paymentInstructionId && paymentInstruction) {
        openConfirmationModal({
          title: intl.formatMessage({
            defaultMessage:
              '¿Estás seguro que quieres rechazar el pago de esta factura?',
            id: 'MWSg3C',
          }),
          text: intl.formatMessage({
            defaultMessage:
              'Esta acción no puede deshacerse y el solicitador ya no verá disponible la solicitud de pago de esta factura. Moveremos tu factura a Cuentas por pagar.',
            id: 'YEWU6l',
          }),
          confirmText: intl.formatMessage({
            defaultMessage: 'Si, rechazar factura',
            id: 'lQ21Ha',
          }),
          cancelText: intl.formatMessage({
            defaultMessage: 'No, conservar factura',
            id: 'QRNVV/',
          }),
          onConfirm: () => {
            try {
              search.pop('invoiceIds', id);
              callback?.();
              updatePaymentInstruction({
                variables: {
                  businessId: business.id,
                  paymentInstructionId,
                  paymentInstructionInvoices: paymentInstruction.paymentInstructionInvoices
                    .filter((instruction) => instruction.invoice.id === id)
                    .map((instruction) => ({
                      paymentInstructionInvoiceId: instruction.id,
                      status: PaymentInstructionInvoiceStatus.REJECTED,
                    })),
                },
              });
            } catch (e) {
              console.error(e);
              toast.error(
                intl.formatMessage({
                  defaultMessage: 'Error al rechazar factura',
                  id: '35Bcw9',
                })
              );
              errorReporter('Error removing payment instruction invoice', {
                e,
                paymentInstructionId: paymentInstruction.id,
              });
            }
          },
        });
      } else {
        search.pop('invoiceIds', id);
        callback?.();
      }
    },
    [
      business,
      paymentInstructionId,
      paymentInstruction,
      openConfirmationModal,
      updatePaymentInstruction,
    ]
  );

  return (
    <Form
      wallet="balance"
      title={intl.formatMessage({
        defaultMessage: 'Revisar y pagar',
        id: 'cUuhwH',
      })}
      onSubmit={handleSubmit}
      onReject={
        paymentInstructionId && paymentInstruction ? handleReject : undefined
      }
      invoices={invoices}
      invoiceCount={invoiceIds.length}
      loading={loading}
      submitting={submitting || rejecting}
      onRemove={handleInvoiceRemoval}
    />
  );
}

export default InvoicePaymentForm;
