import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Color, ColorStep } from 'styled-components';

interface DotProps {
  anchorEl?: HTMLElement;
  borderColor?: Color;
  borderColorStep?: ColorStep;
}

const StyledDot = styled.div<DotProps>`
  position: absolute;
  width: ${({ theme }) => theme.spacing[4]};
  height: ${({ theme }) => theme.spacing[4]};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.danger[500]};
  border: 3px solid
    ${({ theme, borderColorStep, borderColor }) =>
      borderColor && theme.colors[borderColor][borderColorStep || 500]};
`;

function Dot({ anchorEl, ...otherProps }: DotProps) {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [8, -8],
        },
      },
    ],
  });

  return (
    <StyledDot
      ref={setPopperElement as any}
      style={styles.popper}
      {...attributes.popper}
      {...otherProps}
    />
  );
}

export default Dot;
