import styled from 'styled-components';

export const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.neutral[500]};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

export const Header = styled.div`
  margin: ${({ theme }) => theme.spacing[4]} 0;
`;

export const Row = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const Email = styled.a`
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  display: block;
`;

export const ExpandButton = styled.button`
  color: ${({ theme }) => theme.colors.primary[500]};
  font-size: ${({ theme }) => theme.fontSize.sm};
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: ${({ theme }) => theme.spacing[2]} 0;
`;

export const LinkInvoiceButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.primary[500]};
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
