import React from 'react';
import { StyledSwitcherItem } from './SwitcherItem.styles';
import { SwitcherItemProps } from './types';

export default function SwitcherItem(props: SwitcherItemProps) {
  const { label, onChange, onClick, value, selected, ...otherProps } = props;

  const handleClick = (event) => {
    if (!selected && onChange) {
      onChange(value);
    }

    if (onClick) {
      onClick(event);
    }
  };
  return (
    <StyledSwitcherItem
      onClick={handleClick}
      selected={selected}
      {...otherProps}
    >
      {label}
    </StyledSwitcherItem>
  );
}
