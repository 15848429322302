import { useContext } from 'react';

import { SettingsProviderContext } from '@src/components/SettingsProvider';
import { SettingsContext } from '@src/components/SettingsProvider/SettingsProvider';

function useSettings(): SettingsContext;
function useSettings(key: keyof SettingsContext);
function useSettings(key?: string) {
  const settings = useContext(SettingsProviderContext);
  if (key && key in settings) {
    return settings[key];
  }

  return settings;
}

export default useSettings;
