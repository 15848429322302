import React, { useState } from 'react';

import Label from '@src/components/ui/Label';
import { Bank } from '@src/components/ui/Icon/solid';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import CircledIcon from '@src/components/ui/CircledIcon';
import * as S from '../../CreditLineOverviewCard.styles';
import Dot from '@src/components/ui/Dot';
import CardButton from '../CardButton';

interface CardHeaderProps {
  theme?: 'primary' | 'neutral';
  cardTitle?: string;
  showCreditButton?: boolean;
  isOverdue?: boolean;
}

function CardHeader({
  theme = 'primary',
  cardTitle,
  showCreditButton,
  isOverdue,
}: CardHeaderProps) {
  const selectedTheme = S.cardThemes[theme];

  return (
    <S.Head>
      <FlexContainer alignItems="center">
        <CircledIcon
          size="8"
          backgroundColor={selectedTheme.icon.backgroundColor}
          backgroundColorStep={selectedTheme.icon.backgroundColorStep}
          backgroundOpacity={selectedTheme.icon.backgroundOpacity}
        >
          <Bank size={16} color={selectedTheme.icon.color} />
        </CircledIcon>
        <Spacer margin="3" direction="horizontal" />
        <Label
          size="md"
          color={selectedTheme.cardTitle.color}
          colorStep={selectedTheme.cardTitle.colorStep}
          strong
        >
          {cardTitle}
        </Label>
      </FlexContainer>

      {showCreditButton && <CardButton isOverdue={isOverdue} />}
    </S.Head>
  );
}

export default CardHeader;
