import { gql } from '@apollo/client';

const fragments = {
  invoices: gql`
    fragment AccountsReceivableTableAttributes on Invoice {
      id
      globalStatus
      paymentStatus
      expiryDate
      outstandingBalance
      total
      currency
      foreignTotal
      createdAt
      businessEmitter {
        id
        name
        hashId
      }
      businessReceiver {
        id
        name
        hashId
      }
    }
  `,
};

export const AccountsReceivableTableQueryName = 'AccountsReceivableTable';

export const ACCOUNTS_RECEIVABLE_TABLE_DATA = gql`
  query ${AccountsReceivableTableQueryName}(
      $businessId: ID!
      $approvalStatus: [InvoiceApprovalStatusEnum!]
      $paymentStatus: [InvoicePaymentStatusEnum!]
      $limit: Int
      $sortBy: InvoicesSortingParams
    ) {
      invoicesEmitted(
        businessId: $businessId
        approvalStatus: $approvalStatus
        paymentStatus: $paymentStatus
        first: $limit
        sortBy: $sortBy
    ) {
      edges {
        node {
          ...AccountsReceivableTableAttributes
        }
      }
    }
  }
  ${fragments.invoices}
`;
