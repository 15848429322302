import React from 'react';
import { FormattedMessage } from 'react-intl';

import TransactionStatusBadge, {
  optionsForStatus,
} from '@src/components/shared/TransactionStatusBadge';
import FormattedDate, {
  defaultTimeFormat,
} from '@src/components/i18n/FormattedDate';
import TransactionsIdentifier from '@src/components/shared/TransactionsIdentifier';
import Currency from '@src/components/i18n/Currency';
import {
  ENTRY_DEPOSIT_ENTRY,
  ENTRY_REFUND_ENTRY,
} from '@src/constants/transactions';
import TransactionType from '@src/components/shared/TransactionType';
import TransactionRecipient from '@src/components/shared/TransactionRecipient';
import TransactionInvoice from '@src/components/shared/TransactionInvoice';
import EmailSendButton from '@src/components/shared/TransactionsTable/components/EmailSendButton';
import { ENTRY_HIGO_PAYMENT_SENT_ENTRY } from '@src/constants/transactions';

import type { WalletEntryStatus } from '@src/types/enums';
import type { AttributesObject } from '@src/types/resourceList';
import type { WalletEntry } from '@src/types/models';

const attributes: AttributesObject<WalletEntry> = {
  transactionIdentifier: {
    name: '',
    type: 'CUSTOM',
    render: (datum) => <TransactionsIdentifier walletEntry={datum} />,
  },
  hashId: {
    name: <FormattedMessage defaultMessage="ID" id="qlcuNQ" />,
    type: 'CUSTOM',
    render: (datum: { hashId: string }) => datum.hashId,
  },
  createdAt: {
    name: <FormattedMessage defaultMessage="Fecha" id="eF7+Pa" />,
    type: 'DATE_RANGE',
    render: (datum: { createdAt: any }) => (
      <FormattedDate value={datum.createdAt} {...defaultTimeFormat} />
    ),
    sortable: false,
    filterable: true,
    options: [],
  },
  transactionType: {
    name: <FormattedMessage defaultMessage="Tipo" id="wt+XcA" />,
    type: 'CUSTOM',
    render: (datum) => <TransactionType walletEntry={datum} />,
  },

  invoice: {
    name: <FormattedMessage defaultMessage="Factura" id="yZ36Da" />,
    type: 'CUSTOM',
    render: (datum) => <TransactionInvoice walletEntry={datum} />,
  },
  recipient: {
    name: <FormattedMessage defaultMessage="De/Para" id="pHfEVO" />,
    type: 'CUSTOM',
    render: (datum) => <TransactionRecipient walletEntry={datum} />,
  },
  providerContact: {
    name: <FormattedMessage defaultMessage="Usuario" id="OxzawJ" />,
    type: 'CUSTOM',
    render: (datum: {
      entryable: { __typename: string };
      user: { firstName: any; lastName: any };
    }) =>
      datum.entryable.__typename !== ENTRY_DEPOSIT_ENTRY &&
      datum.entryable.__typename !== ENTRY_REFUND_ENTRY
        ? `${datum.user.firstName} ${datum.user.lastName}`
        : '-',
  },
  amount: {
    name: <FormattedMessage defaultMessage="Monto" id="Axixya" />,
    type: 'STRING',
    render: (datum: { amount: any }) => <Currency value={datum.amount} />,
  },
  status: {
    name: '',
    type: 'MULTI_VALUE',
    render: (datum: { status: keyof typeof WalletEntryStatus }) => (
      <TransactionStatusBadge status={datum.status} />
    ),
    filterable: true,
    options: optionsForStatus(),
  },
  sendEmail: {
    name: '',
    type: 'MULTI_VALUE',
    render: (datum) => {
      const { entryable } = datum;
      const isHigoPayInvoicePayment =
        entryable.__typename === ENTRY_HIGO_PAYMENT_SENT_ENTRY &&
        !!entryable?.invoicePayment;
      const defaultEmail = entryable?.lastBusinessUserValidation?.email;
      return isHigoPayInvoicePayment && !datum.claimed ? (
        <EmailSendButton
          higoPaymentSentEntryId={entryable.id}
          defaultEmail={defaultEmail}
        />
      ) : null;
    },
    filterable: false,
    options: optionsForStatus(),
  },
};

export default attributes;
