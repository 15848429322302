import React from 'react';
import BusinessSettings from './BusinessSettings';

import Permission from '@src/components/shared/Permission';
import { UserRole } from '@src/types/enums';

function BusinessContainer() {
  return (
    <Permission minimumRoles={[UserRole.ADMIN]} redirect>
      <BusinessSettings />
    </Permission>
  );
}

export default BusinessContainer;
