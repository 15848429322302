import { gql } from '@apollo/client';
import { BankingInfo } from '@src/types/models';

export const CREATE_BANKING_INFO_REQUEST_VALIDATION = gql`
  mutation CreateBankingInfoValidationWithBankingInfoRequest(
    $token: String!
    $bankingInfo: BankingInfoInput!
  ) {
    createBankingInfoValidationWithBankingInfoRequest(
      token: $token
      bankingInfo: $bankingInfo
    ) {
      id
      clabe
      validationStatus
    }
  }
`;

export type CreateBankingInfoRequestValidationParams = {
  token: string;
  bankingInfo: {
    bankId: string;
    clabe: string;
    recipient?: string;
  };
};

export type CreateBankingInfoRequestValidationReturn = Pick<
  BankingInfo,
  'id' | 'clabe' | 'validationStatus'
>;
