import { format } from 'date-fns';

export const getGreetingTime = () => {
  const splitAfternoon = 12;
  const splitEvening = 19;
  const currentHour = parseFloat(format(new Date(), 'HH'));
  return currentHour >= splitAfternoon && currentHour <= splitEvening
    ? 'pageTitleAfternoon'
    : currentHour >= splitEvening
    ? 'pageTitleNight'
    : 'pageTitleMorning';
};
