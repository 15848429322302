import styled from 'styled-components';
import { ToastContainer as Container } from 'react-toastify';

export const StyledContainer = styled(Container)`
  --toastify-color-light: ${({ theme }) => theme.colors.neutral[500]};
  --toastify-color-dark: ${({ theme }) => theme.colors.neutral[800]};
  --toastify-color-info: ${({ theme }) => theme.colors.info[500]};
  --toastify-color-success: ${({ theme }) => theme.colors.success[500]};
  --toastify-color-warning: ${({ theme }) => theme.colors.warning[500]};
  --toastify-color-error: ${({ theme }) => theme.colors.danger[500]};
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-toast-width: 22.5rem;
  --toastify-toast-background: ${({ theme }) => theme.colors.neutral[50]};
  --toastify-toast-min-height: ${({ theme }) => theme.spacing[16]};
  --toastify-toast-max-height: 800px;
  --toastify-font-family: ${({ theme }) => theme.fontFamily.sans};
  --toastify-z-index: 9999;

  --toastify-text-color-light: ${({ theme }) => theme.colors.neutral[900]};
  --toastify-text-color-dark: ${({ theme }) => theme.colors.neutral[50]};

  --toastify-text-color-info: ${({ theme }) => theme.colors.neutral[50]};
  --toastify-text-color-success: ${({ theme }) => theme.colors.neutral[50]};
  --toastify-text-color-warning: ${({ theme }) => theme.colors.neutral[50]};
  --toastify-text-color-error: ${({ theme }) => theme.colors.neutral[50]};

  --toastify-spinner-color: ${({ theme }) => theme.colors.neutral[500]};
  --toastify-spinner-color-empty-area: ${({ theme }) =>
    theme.colors.neutral[100]};

  --toastify-color-progress-light: ${({ theme }) => theme.colors.neutral[200]};
  --toastify-color-progress-dark: ${({ theme }) => theme.colors.neutral[800]};
  --toastify-color-progress-info: ${({ theme }) => theme.colors.info[500]};
  --toastify-color-progress-success: ${({ theme }) =>
    theme.colors.success[500]};
  --toastify-color-progress-warning: ${({ theme }) =>
    theme.colors.warning[500]};
  --toastify-color-progress-error: ${({ theme }) => theme.colors.danger[500]};

  width: auto;

  /* Notifications */
  .Toastify__toast {
    box-shadow: ${({ theme }) => theme.boxShadow.lg};
    font-size: ${({ theme }) => theme.fontSize.sm};
    border-radius: ${({ theme }) => theme.spacing[2]};
    padding: ${({ theme }) => theme.spacing[4]};
    max-width: var(--toastify-toast-width);
  }

  .Toastify__toast-body {
    align-items: flex-start;
  }
  .Toastify__toast-body-subtitle {
    color: ${({ theme }) => theme.colors.neutral[50]};
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }

  .Toastify__toast-theme--dark {
    background: var(--toastify-color-progress-dark);
  }
  .Toastify__toast-theme--light {
    background: var(--toastify-color-progress-light);
  }
  .Toastify__toast-theme--colored {
    border-left-width: ${({ theme }) => theme.spacing[1]};
    border-left-style: solid;
  }
  .Toastify__toast-theme--colored.Toastify__toast--default {
  }
  .Toastify__toast-theme--colored.Toastify__toast--info {
    border-left-color: var(--toastify-color-progress-info);
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    border-left-color: var(--toastify-color-progress-success);
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    border-left-color: var(--toastify-color-progress-warning);
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    border-left-color: var(--toastify-color-progress-error);
  }
  .Toastify__toast-icon {
    margin-top: ${({ theme }) => theme.spacing[0.5]};
  }
  .Toastify__close-button {
    color: ${({ theme }) => theme.colors.neutral[50]};
    padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
    margin-top: ${({ theme }) => theme.spacing.px};
    opacity: 0.6;
  }
  .Toastify__close-button > svg {
    width: ${({ theme }) => theme.spacing[4]};
    height: ${({ theme }) => theme.spacing[4]};
  }

  p,
  span,
  a {
    margin: 0;
  }
`;
