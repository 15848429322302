import { gql } from '@apollo/client';
import { PaginatedResponse } from '@src/types/utils';

const COUNTRIES_QUERY = gql`
  query Countries {
    countries {
      edges {
        node {
          id
          name
          currencyCode
        }
      }
    }
  }
`;

export type Country = {
  id: string;
  name: string;
  currencyCode: string;
};

export type CountriesReturn = {
  countries: PaginatedResponse<Country>;
};

export default COUNTRIES_QUERY;
