import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { trackPageView } from '@src/lib/analytics';

import { RouteComponentProps } from 'react-router-dom';

type PageViewRecorderProps = RouteComponentProps;

export class PageViewRecorder extends Component<PageViewRecorderProps, any> {
  //@ts-ignore
  unlisten: void | (() => void);

  constructor(props: PageViewRecorderProps) {
    super(props);

    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(this.handleLocationChange);
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  handleLocationChange() {
    const path = this.props.history.location.pathname;

    trackPageView(path);
  }

  render() {
    return null;
  }
}

export default withRouter(PageViewRecorder);
