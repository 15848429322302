import React from 'react';
import MUIContainer from '@mui/material/Container';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

function Container(props) {
  return <MUIContainer theme={theme} {...props} />;
}

export default Container;
