import React from 'react';
import { useIntl } from 'react-intl';
import { Recipient } from './TransactionBusinessEmitter.styles';

interface TransactionBusinessEmitterProps {
  recipientName?: string;
  senderName?: string;
  positive?: boolean;
}

export const TransactionBusinessEmitter = ({
  positive,
  senderName,
  recipientName,
}: TransactionBusinessEmitterProps) => {
  const { formatMessage } = useIntl();

  if (positive && senderName) {
    return (
      <Recipient>
        {formatMessage(
          {
            defaultMessage: 'De: {business}',
            id: 'F/TiXd',
          },
          { business: senderName }
        )}
      </Recipient>
    );
  }

  if (recipientName) {
    return (
      <Recipient>
        {formatMessage(
          {
            defaultMessage: 'Para: {business}',
            id: 'JtKCC8',
          },
          { business: recipientName }
        )}
      </Recipient>
    );
  }

  return <Recipient />;
};

export default TransactionBusinessEmitter;
