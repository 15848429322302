import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import * as S from './WithdrawalValidationModal.styles';

import Header from '@src/components/ui/Header';
import usePageTrack from '@src/hooks/usePageTrack';
import useModal from '@src/hooks/useModal';
import Tag from '@src/components/ui/Tag';
import useSettings from '@src/hooks/useSettings';
import { START_BANKING_INFO_VALIDATION } from '@src/graphql/mutations';
import WithdrawalModal from '@src/components/pages/WalletView/components/WithdrawalModal';
import BankingInformationForm from '@src/components/shared/form/BankingInformationForm';
import { APP_DATA_QUERY_NAME } from '@src/graphql/queries';
import useToast from '@src/hooks/useToast';
import type { ModalChildrenProps } from '@src/components/shared/Modal';

type WithdrawalValidationModalProps = ModalChildrenProps & {
  taxpayerIdentifier?: string;
};
function WithdrawalValidationModal({
  taxpayerIdentifier,
  onClose,
}: WithdrawalValidationModalProps) {
  usePageTrack('withdraw_validation_modal', 'core');
  const intl = useIntl();
  const { toast } = useToast();
  const { open } = useModal(WithdrawalModal);
  const business = useSettings('business');
  const rfc =
    taxpayerIdentifier || business?.taxPayerInfos[0]?.taxpayerIdentifier;

  const [mutate, { loading }] = useMutation(START_BANKING_INFO_VALIDATION, {
    refetchQueries: [APP_DATA_QUERY_NAME],
    awaitRefetchQueries: true,
  });

  const handleBankingInfoValidation = async ({ bankId, clabe }) => {
    if (business?.id) {
      try {
        const bankingInfo = {
          bankId,
          clabe: clabe?.replace(/\s/g, ''),
          recipient: business?.taxPayerInfos[0]?.taxpayerName,
        };

        await mutate({
          variables: {
            businessId: business.id,
            bankingInfo,
          },
        });
        open();
      } catch (e: any) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Hubo un problema al agregar la cuenta',
            id: 'SjTg2l',
          }),
          e.message
        );
      }
    }
  };

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Agregar cuenta',
          id: 'NMM9Mz',
        })}
      </Header>

      <S.Body>
        {intl.formatMessage({
          defaultMessage:
            'Para verificar tu información bancaria, el titular de la CLABE debe tener el mismo RFC con la que diste de alta tu cuenta ',
          id: 'wVfYVe',
        })}
        {rfc && (
          <>
            {' '}
            <Tag compact color="gray" label={`(RFC: ${rfc})`} />
          </>
        )}
      </S.Body>

      <BankingInformationForm
        onCancel={onClose}
        onSubmit={handleBankingInfoValidation}
        loading={loading}
      />
    </S.Container>
  );
}

WithdrawalValidationModal.displayName = 'WithdrawalValidationModal';

export default WithdrawalValidationModal;
