import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import { FlexContainer, Spacer } from '../layouts';
import * as S from './FileDropZone.styles';
import { Label, Text } from '../../ui';
import { UploadCloud, Paperclip } from '@src/components/ui/Icon/outline';

import { ReactComponent as PdfIcon } from './assets/pdf.svg';
import { ReactComponent as XmlIcon } from './assets/xml.svg';

interface FileDropZoneProps {
  acceptedFileType?: string;
  multiple?: boolean;
  title: string;
  subtitle?: React.ReactNode;
  onDrop: (files: Array<any>) => void;
}

interface FileIconProps {
  type: string;
}

const FileIcon = ({ type }: FileIconProps) => {
  if (type === 'pdf') {
    return <PdfIcon />;
  }

  if (type === 'xml') {
    return <XmlIcon />;
  }

  return <Paperclip />;
};

function FileDropZone({
  title,
  subtitle,
  multiple = false,
  acceptedFileType = '.doc, .pdf',
  onDrop,
}: FileDropZoneProps) {
  const intl = useIntl();
  const {
    acceptedFiles,
    isDragActive,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: acceptedFileType,
    multiple,
  });

  const acceptedFilesItems = acceptedFiles.map((file, index) => {
    const { type, name, size } = file;

    const fileType = type.split('/');

    return (
      <FlexContainer alignItems="center" key={index}>
        <FileIcon type={fileType[1]} />
        <Spacer direction="horizontal" />
        <div>
          <Label size="lg" strong>
            {name}
          </Label>
          <Text colorStep="600">{(size / 1048).toFixed(2)} MB</Text>
        </div>
      </FlexContainer>
    );
  });

  return (
    <S.FileDropZone {...getRootProps()}>
      <input {...getInputProps()} />

      <FlexContainer>
        {acceptedFilesItems.length > 0 ? (
          <div>{acceptedFilesItems}</div>
        ) : isDragActive ? (
          <div>
            <Text textAlign="center" colorStep="500">
              {intl.formatMessage({
                defaultMessage: 'Suelta tu archivo aquí',
                id: 'GNdQr5',
              })}
            </Text>
          </div>
        ) : (
          <FlexContainer alignItems="center">
            <UploadCloud size={36} />
            <Spacer direction="horizontal" />
            <FlexContainer flexDirection="column" justifyContent="center">
              <Label size="lg" strong>
                {title}
              </Label>
              <Text colorStep="600">{subtitle}</Text>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    </S.FileDropZone>
  );
}

export default FileDropZone;
