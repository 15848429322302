import styled from 'styled-components';

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[500]};
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding: ${({ theme }) => theme.spacing[2]} 0;

  svg {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;
