import React from 'react';
import { useIntl } from 'react-intl';
import { TabPanel, Tabs } from 'react-tabs';

import { Card, Header, Icon } from '@src/components/ui';
import { Tab, TabList } from '@src/components/shared/Tabs';
import {
  ACTIVE_CREDIT_TAB_CLICKED,
  PAID_CREDIT_TAB_CLICKED,
} from '@src/constants/events';
import useQueryParams from '@src/hooks/useQueryParams';
import { trackEvent } from '@src/lib/analytics';
import { INITIAL_PAGINATION_PARAMS } from '@src/utils/pagination';
import * as S from './FinancingRequests.styles';
import ActiveFinancingRequestsTable from '../ActiveFinancingRequestsTable';
import PaidFinancingRequestsTable from '../PaidFinancingRequestsTable';
import { Spacer } from '@src/components/shared/layouts';

enum TabStatus {
  active = 0,
  paid = 1,
}

function FinancingRequests() {
  const { formatMessage } = useIntl();

  const [query, setQuery] = useQueryParams();
  const selectedTabIndex = parseInt(TabStatus[query?.tab]) || TabStatus.active;

  const onTabSelected = (tab: number) => {
    if (selectedTabIndex !== tab) {
      setQuery({ ...INITIAL_PAGINATION_PARAMS, tab: TabStatus[tab] });
    }
  };

  const onActiveTabClick = () => {
    const eventData = {
      view: 'Credit',
      tab: 'Active',
    };

    trackEvent(ACTIVE_CREDIT_TAB_CLICKED, eventData);
  };

  const onPaidTabClick = () => {
    const eventData = {
      view: 'Credit',
      tab: 'Paid',
    };

    trackEvent(PAID_CREDIT_TAB_CLICKED, eventData);
  };

  return (
    <>
      <Header as="h5">
        {formatMessage({
          defaultMessage: 'Disposiciones realizadas',
          id: '5PwfLx',
        })}
      </Header>
      <Spacer margin="3" />
      <Card>
        <Tabs selectedIndex={selectedTabIndex} onSelect={onTabSelected}>
          <TabList>
            <Tab
              defaultIcon={<Icon name="Dot" variant="solid" />}
              selectedIcon={<Icon name="Dot" variant="solid" />}
              onClick={onActiveTabClick}
            >
              {formatMessage({
                defaultMessage: 'Activas',
                id: 'opmAD5',
              })}
            </Tab>
            <Tab
              defaultIcon={<Icon name="Smile" />}
              selectedIcon={<Icon name="Smile" variant="solid" />}
              onClick={onPaidTabClick}
            >
              {formatMessage({
                defaultMessage: 'Pagadas',
                id: 'FFpbUV',
              })}
            </Tab>
          </TabList>
          <TabPanel>
            <S.Container>
              <ActiveFinancingRequestsTable />
            </S.Container>
          </TabPanel>
          <TabPanel>
            <S.Container>
              <PaidFinancingRequestsTable />
            </S.Container>
          </TabPanel>
        </Tabs>
      </Card>
    </>
  );
}

export default FinancingRequests;
