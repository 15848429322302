import styled from 'styled-components';

type IdentifierContainerProps = {
  withLabel?: boolean;
};

type IconColorProps = {
  name: string;
};

export const IdentifierContainer = styled.div<IdentifierContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ withLabel }) => !withLabel && 'center'};
  p {
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing[2]};
  }
  span {
    line-height: 1;
  }
`;

export const IconColor = styled.span<IconColorProps>`
  color: ${({ theme, name }) =>
    theme.colors[name === 'payment' ? 'danger' : 'secondary']['500']};
`;
