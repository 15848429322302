import { gql } from '@apollo/client';
import { FactoringOffer } from '@src/types/models';

export const FACTORING_OFFERS_QUERY = gql`
  query FactoringOffers(
    $businessId: ID!
    $status: FactoringOfferStatusEnum
    $factoringOffersIds: [ID!]
  ) {
    factoringOffers(
      businessId: $businessId
      status: $status
      factoringOffersIds: $factoringOffersIds
    ) {
      id
      acceptedAt
      expiresAt
      interestRate
      offeredAt
      percentCashable
      cashableAmountCents
      discountAmountCents
      reserveAmountCents
      paymentTerms
      invoice {
        id
        fiscalIdentifier
        businessReceiver {
          name
        }
        expiryDate
        total
      }
    }
  }
`;

export enum FactoringOfferStatusEnum {
  AVAILABLE = 'AVAILABLE',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
}

export type FactoringOffersQueryVariables = {
  businessId?: string;
  status?: FactoringOfferStatusEnum;
  factoringOffersIds?: [number];
};

export type FactoringOffersQueryReturn = {
  factoringOffers: FactoringOffer[];
};

export default FACTORING_OFFERS_QUERY;
