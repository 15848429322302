import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, TabPanel } from 'react-tabs';

import Page from '@src/components/shared/Page';
import LinkButton from '@src/components/shared/buttons/LinkButton';
import { Tab, TabList } from '@src/components/shared/Tabs';
import Card from '@src/components/ui/Card';
import PaymentsApprovalCta from './components/PaymentsApprovalButton';
import ToPayInvoicesTable from './components/ToPayInvoicesTable/ToPayInvoicesTable';
import PaidInvoicesTable from './components/PaidInvoicesTable/PaidInvoicesTable';
import ArchivedInvoicesTable from './components/ArchivedInvoicesTable/ArchivedInvoicesTable';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useQueryParams from '@src/hooks/useQueryParams';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';
import { trackEvent } from '@src/lib/analytics';
import INVOICE_STATUS_COUNT_QUERY from '@src/graphql/queries/invoiceStatusCount';
import {
  Smile as SmileOutline,
  Archive as ArchiveOutline,
  InvoiceToPay as InvoiceToPayOutline,
  ArrowRightLine,
} from '@src/components/ui/Icon/outline';
import {
  Smile as SmileSolid,
  Archive as ArchiveSolid,
  InvoiceToPay as InvoiceToPaySolid,
} from '@src/components/ui/Icon/solid';
import PageTitle from '@src/components/shared/PageTitle';
import useSettings from '@src/hooks/useSettings';
import usePageTrack from '@src/hooks/usePageTrack';
import { trackPageView } from '@src/lib/analytics';
import { INITIAL_PAGINATION_PARAMS } from '@src/utils/pagination';
import usePaymentRequests from '@src/components/pages/PaymentRequests/hooks/usePaymentRequests';
import { CREATE_PAYMENTS_WITHOUT_INVOICE_CLICKED } from '@src/constants/events';

const INITIAL_PAYABLE_FILTERS = {
  invoicingDate: undefined,
  expiryDate: undefined,
  paymentDate: undefined,
  globalStatus: undefined,
  businessEmitterId: undefined,
  voucherType: undefined,
};

function AccountsPayableList() {
  usePageTrack('to_pay', 'payables');
  const [, setFilters] = useInvoiceFilters();
  const [query] = useQueryParams();
  const intl = useIntl();
  const { business } = useSettings();
  const { data } = useQuery(INVOICE_STATUS_COUNT_QUERY, {
    variables: { businessId: business?.id },
    skip: !business?.id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const { paymentInstructions } = usePaymentRequests({});

  const selectedTabIndex = parseInt(query?.tabIndex) || 0;
  const isPaymentWithoutInvoiceEnabled = useFeatureFlag(
    FeatureFlag.PAYMENTS_WITHOUT_INVOICE
  );

  const handleTabSelect = (index) => {
    trackPageView(['to_pay', 'paid', 'archived'][index], 'payables');
    setFilters({
      ...INITIAL_PAGINATION_PARAMS,
      ...INITIAL_PAYABLE_FILTERS,
      tabIndex: index,
      sort: index === 0 ? 'expiryDate' : undefined,
      sortDirection: index === 0 ? 'ascending' : undefined,
    });
  };

  const duePaymentInvoicesCount =
    data?.invoiceStatusCount?.duePaymentInvoicesCount;
  const paidInvoicesCount = data?.invoiceStatusCount?.paidInvoicesCount;

  const paymentRequestsCount = paymentInstructions?.length || 0;

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Cuentas por pagar',
        id: 'y0s+Vr',
      })}
    >
      <PageTitle
        actions={{
          render: () =>
            isPaymentWithoutInvoiceEnabled && (
              <LinkButton
                href={`/payments`}
                startIcon={<ArrowRightLine />}
                onClick={() =>
                  trackEvent(CREATE_PAYMENTS_WITHOUT_INVOICE_CLICKED)
                }
              >
                {intl.formatMessage({
                  id: 's5dLk5',
                  defaultMessage: 'Pago sin factura',
                })}
              </LinkButton>
            ),
        }}
      >
        <FormattedMessage
          id="uQRd1S"
          defaultMessage="Cuentas por pagar"
          description="Accounts payable view page title"
        />
      </PageTitle>
      <Card>
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          <TabList>
            <Tab
              count={duePaymentInvoicesCount}
              defaultIcon={<InvoiceToPayOutline />}
              selectedIcon={<InvoiceToPaySolid />}
            >
              <FormattedMessage defaultMessage="Por pagar" id="YkWZGw" />
            </Tab>
            <Tab
              count={paidInvoicesCount}
              defaultIcon={<SmileOutline />}
              selectedIcon={<SmileSolid />}
            >
              <FormattedMessage defaultMessage="Pagadas" id="FFpbUV" />
            </Tab>
            <Tab
              defaultIcon={<ArchiveOutline />}
              selectedIcon={<ArchiveSolid />}
            >
              <FormattedMessage defaultMessage="Archivo" id="E4vK5X" />
            </Tab>
          </TabList>
          <TabPanel>
            <PaymentsApprovalCta paymentRequestsCount={paymentRequestsCount} />
            <ToPayInvoicesTable />
          </TabPanel>
          <TabPanel>
            <PaidInvoicesTable />
          </TabPanel>
          <TabPanel>
            <ArchivedInvoicesTable />
          </TabPanel>
        </Tabs>
      </Card>
    </Page>
  );
}

export default AccountsPayableList;
