import React from 'react';
import { FormattedMessage } from 'react-intl';

import attributes from '@src/components/shared/InvoicesTable/attributes';
import { CellCentered } from '@src/components/shared/InvoicesTable/InvoicesTable.styles';
import FeatureSupplierButton from '@src/components/shared/buttons/FeatureSupplierButton';
import { AttributesObject } from '@src/types/resourceList';

const customAttributes: AttributesObject<any> = {
  ...attributes,
  businessEmitter: {
    name: <FormattedMessage defaultMessage="Proveedor" id="RMvOYP" />,
    type: 'MULTI_VALUE',
    filterable: true,
    options: [],
    render: (datum) => (
      <CellCentered>
        <FeatureSupplierButton
          businessRelationshipId={datum.businessRelationship?.id}
          active={datum.businessRelationship?.active}
        />
        <span>
          {datum?.businessEmitter?.name || datum?.emitterTaxpayerName}
        </span>
      </CellCentered>
    ),
    shouldRender: (datum, props) => !props.shouldRenderProvider,
  },
};

export default customAttributes;
