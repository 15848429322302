import { gql } from '@apollo/client';

const UPDATE_BUSINESS_MUTATION = gql`
  mutation UpdateBusiness($businessId: ID!, $business: UpdateBusinessInput!) {
    updateBusiness(businessId: $businessId, business: $business) {
      id
      name
      setting {
        id
        currencyCode
      }
    }
  }
`;

export type UpdateBusinessInput = {
  name: string;
  phone?: string;
  websiteUrl?: string;
  currencyCode?: string;
};

export type UpdateBusinessVariables = {
  businessId: string;
  business: UpdateBusinessInput;
};

export type UpdateBusinessReturn = {
  updateSupplierBankingInfo: {
    id: string;
    name: string;
    setting: {
      id: string;
      currencyCode: string;
    };
  };
};

export default UPDATE_BUSINESS_MUTATION;
