import { useMemo } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import { BUSINESS_RELATIONSHIPS_AS_CLIENT_QUERY } from '@src/graphql/queries';
import usePagination from '@src/hooks/usePagination';

import type { BusinessRelationship } from '@src/types/models';
import type { Sort } from '@src/types/utils';

interface useBussinessRelationshipAsClientReturn {
  loading: boolean;
  error?: ApolloError;
  data: { businessRelationships: BusinessRelationship[]; totalAmount: number };
  page: number;
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  next: () => void;
  previous: () => void;
  refetch: () => void;
}

function useBusinessRelationshipsAsClient(variables?: {
  businessId?: string;
  limit?: number;
  sortBy?: Sort;
}): useBussinessRelationshipAsClientReturn {
  const { loading, error, data, refetch } = useQuery(
    BUSINESS_RELATIONSHIPS_AS_CLIENT_QUERY,
    {
      variables: variables || {},
      skip: !variables?.businessId,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { count, hasNext, hasPrevious, next, previous, page } = usePagination(
    data?.businessRelationshipsAsClient,
    refetch
  );

  const businessRelationships: BusinessRelationship[] = useMemo(() => {
    if (!data?.businessRelationshipsAsClient?.edges) {
      return [];
    }
    return data.businessRelationshipsAsClient.edges.map((edge) => edge.node);
  }, [data]);

  return {
    error,
    loading,
    data: {
      businessRelationships,
      totalAmount: data?.businessRelationshipsAsClient.totalCount,
    },
    count,
    page,
    hasNext,
    hasPrevious,
    next,
    previous,
    refetch,
  };
}

export default useBusinessRelationshipsAsClient;
