import styled, { DefaultTheme } from 'styled-components';
import Spinner from '../Spinner';
import { ButtonProps, ButtonColor } from './types';

type SolidButtonProps = ButtonProps & { customLoading?: boolean };

export const IconWrapper = styled.div`
  max-height: ${({ theme }) => theme.spacing[4]};
  max-width: ${({ theme }) => theme.spacing[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;

  > * {
    height: 100%;
    width: 100%;
  }
`;

const getSolidButtonColor = (theme: DefaultTheme, color: ButtonColor) =>
  ({
    primary: {
      backgroundColor: theme.colors.primary[500],
      hoverColor: theme.colors.primary[600],
      color: theme.colors.neutral[50],
    },
    secondary: {
      backgroundColor: theme.colors.secondary[400],
      hoverColor: theme.colors.secondary[300],
      color: theme.colors.neutral[50],
    },
    neutral: {
      backgroundColor: theme.colors.neutral[800],
      hoverColor: theme.colors.neutral[900],
      color: theme.colors.neutral[50],
    },
    danger: {
      backgroundColor: theme.colors.danger[500],
      hoverColor: theme.colors.danger[600],
      color: theme.colors.danger[50],
    },
  }[color]);

export const SolidButton = styled.button<SolidButtonProps>`
  border: none;
  background-color: ${({ theme, color = 'primary' }) =>
    getSolidButtonColor(theme, color).backgroundColor};
  color: ${({ theme, color = 'primary' }) =>
    getSolidButtonColor(theme, color).color};
  outline: none;
  padding: 0
    ${({ compact, theme }) => (compact ? theme.spacing[5] : theme.spacing[6])};
  border-radius: ${({ theme }) => theme.spacing[2]};
  transition: background-color 200ms;
  font-size: ${({ compact, theme }) =>
    compact ? theme.fontSize.xs : theme.fontSize.sm};
  font-weight: ${({ compact, theme }) =>
    compact ? theme.fontWeight.regular : theme.fontWeight.medium};
  font-family: ${({ theme }) => theme.fontFamily.sans};
  width: ${({ block }) => (block ? '100%' : 'auto')};
  height: ${({ compact, theme }) =>
    compact ? theme.spacing[8] : theme.spacing[11]};
  max-width: 100%;
  max-height: 100%;

  .button-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${IconWrapper} ~ .button-label, .button-label ~ ${IconWrapper} {
    margin-left: ${({ theme }) => theme.spacing['2.5']};
  }

  ${IconWrapper} {
    flex-shrink: 0;
    opacity: ${({ customLoading }) => customLoading && '0'};
  }

  .button-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: ${({ customLoading }) => customLoading && '0'};
  }

  ${Spinner} {
    position: absolute;
    display: ${({ customLoading }) => !customLoading && 'none'};
  }

  &:hover {
    background-color: ${({ theme, color = 'primary' }) =>
      getSolidButtonColor(theme, color).hoverColor};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral[200]};
    color: ${({ theme }) => theme.colors.neutral[300]};
  }

  &:active {
    transform: scale(0.99);
  }
`;

const getGhostedButtonColor = (theme: DefaultTheme, color: ButtonColor) =>
  ({
    primary: {
      backgroundColor: theme.colors.primary[50],
      hoverColor: theme.colors.primary[100],
      color: theme.colors.primary[500],
    },
    secondary: {
      backgroundColor: theme.colors.secondary[50],
      hoverColor: theme.colors.secondary[100],
      color: theme.colors.secondary[400],
    },
    neutral: {
      backgroundColor: theme.colors.neutral[100],
      hoverColor: theme.colors.neutral[200],
      color: theme.colors.neutral[900],
    },
    danger: {
      backgroundColor: theme.colors.danger[50],
      hoverColor: theme.colors.danger[200],
      color: theme.colors.danger[500],
    },
  }[color]);

export const GhostedButton = styled(SolidButton)<ButtonProps>`
  background-color: ${({ theme, color = 'primary' }) =>
    getGhostedButtonColor(theme, color).backgroundColor};
  color: ${({ theme, color = 'primary' }) =>
    getGhostedButtonColor(theme, color).color};

  &:hover {
    background-color: ${({ theme, color = 'primary' }) =>
      getGhostedButtonColor(theme, color).hoverColor};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

const getTransparentButtonColor = (theme: DefaultTheme, color: ButtonColor) =>
  ({
    primary: {
      hoverColor: theme.colors.primary[50],
      color: theme.colors.primary[500],
    },
    secondary: {
      hoverColor: theme.colors.secondary[50],
      color: theme.colors.secondary[400],
    },
    neutral: {
      hoverColor: theme.colors.neutral[100],
      color: theme.colors.neutral[900],
    },
    danger: {
      hoverColor: theme.colors.danger[100],
      color: theme.colors.danger[500],
    },
  }[color]);

export const TransparentButton = styled(SolidButton)<ButtonProps>`
  background-color: transparent;
  color: ${({ theme, color = 'primary' }) =>
    getTransparentButtonColor(theme, color).color};

  &:hover {
    background-color: ${({ theme, color = 'primary' }) =>
      getTransparentButtonColor(theme, color).hoverColor};
  }

  &:disabled {
    background-color: transparent;
  }
`;

const getOutlinedButtonColor = (theme: DefaultTheme, color: ButtonColor) =>
  ({
    primary: {
      hoverColor: theme.colors.primary[50],
      color: theme.colors.primary[500],
      borderColor: theme.colors.primary[500],
    },
    secondary: {
      hoverColor: theme.colors.secondary[50],
      color: theme.colors.secondary[400],
      borderColor: theme.colors.secondary[400],
    },
    neutral: {
      hoverColor: theme.colors.neutral[100],
      color: theme.colors.neutral[900],
      borderColor: theme.colors.neutral[300],
    },
    danger: {
      hoverColor: theme.colors.danger[100],
      color: theme.colors.danger[500],
      borderColor: theme.colors.danger[500],
    },
  }[color]);

export const OutlinedButton = styled(SolidButton)<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.neutral[50]};
  color: ${({ theme, color = 'primary' }) =>
    getOutlinedButtonColor(theme, color).color};
  box-shadow: inset 0 0 0 1px
    ${({ theme, color = 'primary' }) =>
      getOutlinedButtonColor(theme, color).borderColor};

  &:hover {
    background-color: ${({ theme, color = 'primary' }) =>
      getOutlinedButtonColor(theme, color).hoverColor};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.neutral[200]};
  }
`;
