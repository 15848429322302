import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import { QueryType } from '@src/hooks/useQueryParams';

const INITIAL_PAGINATION_PARAMS = {
  before: undefined,
  after: undefined,
  first: undefined,
  last: undefined,
  page: 1,
};

const getFirstValue = (
  limit: number,
  first?: string | number,
  last?: string | number
) => {
  if (!first && !last) {
    return limit;
  }

  if (first) {
    return Number(first);
  }
};

const getPaginationValuesForQuery = (
  urlQuery?: QueryType,
  limit: number = RESULTS_PER_PAGE
) => ({
  before: urlQuery?.before,
  after: urlQuery?.after,
  first: getFirstValue(limit, urlQuery?.first, urlQuery?.last),
  last: urlQuery?.last && Number(urlQuery?.last || 0),
  page: Number(urlQuery?.page || 0) || 1,
});

export {
  getFirstValue,
  getPaginationValuesForQuery,
  INITIAL_PAGINATION_PARAMS,
};
