import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { Header, Button } from '@src/components/ui';
import Table from '@src/components/shared/Table';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import {
  CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT as CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_EVENT,
  CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_CLICKED,
  CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_ERROR,
} from '@src/constants/events';
import {
  CreateBankingTransactionInvoicePaymentsParams,
  CreateBankingTransactionInvoicePaymentsReturn,
  CREATE_BANKING_TRANSACTION_INVOICE_PAYMENTS_MUTATION,
  InvoicePaymentInput,
} from '@src/graphql/mutations';
import {
  TRANSACTIONS_LIST_QUERY,
  TRANSACTIONS_VIEW_QUERY,
} from '@src/graphql/queries';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { InvoicePaymentStatus } from '@src/types/enums';
import { WalletEntry } from '@src/types/models';
import { floatToCents } from '@src/utils/currency';

import { AdvancePaymentInfo } from './components';
import * as S from './PaymentsReconciliationModal.styles';
import { getTableAttributes } from './utils';
import EmptyResults from '@src/components/shared/EmptyResults';

export type InvoiceField = {
  [key: string]: { amount: number };
};

export type FormData = {
  invoices: InvoiceField;
};

interface PaymentsReconciliationProps {
  walletEntry?: WalletEntry;
  onClose?: () => void;
}

function PaymentsReconciliation({
  walletEntry,
  onClose,
}: PaymentsReconciliationProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const amount = useState(walletEntry?.entryable?.amountCents ?? 0);
  const [currentAmount] = amount;
  const openCustomerSupportChat = useCustomerSupportChat();

  const { toast } = useToast();
  const emitterTaxpayerIdentifier =
    walletEntry?.entryable.receiverAccount?.taxId;

  const [createBankingTransactionInvoicePayment, { loading }] = useMutation<
    CreateBankingTransactionInvoicePaymentsReturn,
    CreateBankingTransactionInvoicePaymentsParams
  >(CREATE_BANKING_TRANSACTION_INVOICE_PAYMENTS_MUTATION, {
    refetchQueries: [TRANSACTIONS_VIEW_QUERY, TRANSACTIONS_LIST_QUERY],
    onCompleted: () => {
      trackEvent(CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_EVENT);
      toast.success(
        intl.formatMessage({
          id: '3eBrBW',
          defaultMessage:
            '¡Se han conciliado las facturas a este pago anticipado con éxito!',
        })
      );
      onClose?.();
    },
    onError: (error) => {
      trackEvent(CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_ERROR);
      toast.error(
        intl.formatMessage({
          id: 'JkclKW',
          defaultMessage: 'Error al conciliar las facturas',
        }),
        error.message
      );
    },
  });

  const {
    data: { invoices },
    loading: invoicesLoading,
  } = useInvoicesListQuery({
    businessId: business?.id,
    approvalStatus: ['APPROVED', 'PENDING'],
    voucherType: ['INVOICE'],
    emitterTaxpayerIdentifier,
    paymentStatus: [InvoicePaymentStatus.PENDING, InvoicePaymentStatus.PARTIAL],
    sortBy: {
      direction: 'ASC',
      field: 'INVOICING_DATE',
    },
    archived: false,
  });

  const form = useForm<FormData>({
    mode: 'onChange',
  });

  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  const attributes = getTableAttributes(intl, form, amount);

  const onSubmit = (data: FormData) => {
    const invoicePayments: InvoicePaymentInput[] = [];

    Object.entries(data.invoices).map(([key, value]) => {
      if (value.amount) {
        const invoicePayment: InvoicePaymentInput = {
          amountCents: floatToCents(value.amount),
          bankingTransactionId: walletEntry?.entryable.id ?? '-',
          //Get id from field key Ex: invoice-1234 => 1234
          invoiceId: key.split('-')[1],
        };
        invoicePayments.push(invoicePayment);
      }
    });

    trackEvent(CREATE_BANKING_TRANSACTION_INVOICE_PAYMENT_CLICKED);

    createBankingTransactionInvoicePayment({
      variables: { businessId: business?.id || '', invoicePayments },
    });
  };

  return (
    <S.ReconciliationModalContainer>
      <Header as="h5">
        {intl.formatMessage({
          id: 'G1ExpF',
          defaultMessage: 'Conciliar con facturas',
        })}
      </Header>
      <Spacer margin="6" />
      {walletEntry && (
        <AdvancePaymentInfo walletEntry={walletEntry} amount={currentAmount} />
      )}
      <Spacer margin="7" />
      <form onSubmit={handleSubmit(onSubmit)} id="conciliationForm">
        <S.InvoicesTableContainer>
          <Table
            loading={invoicesLoading}
            keyExtractor={(invoice) => invoice.id}
            attributes={attributes}
            headers={Object.keys(attributes)}
            data={invoices}
            columnsWidth={[200, 160, 130, 200, 200]}
            emptyElement={
              <EmptyResults
                label={intl.formatMessage({
                  id: 'ae/b46',
                  defaultMessage:
                    'No existen facturas por pagar de este proveedor',
                })}
              />
            }
          />
        </S.InvoicesTableContainer>
      </form>

      <Spacer margin="4" />
      <FlexContainer justifyContent="space-between">
        <Button onClick={openCustomerSupportChat} variant="transparent">
          {intl.formatMessage({
            id: 'YyqOru',
            defaultMessage: '¿Necesitas ayuda?',
          })}
        </Button>
        <div>
          <Button
            variant="outlined"
            color="neutral"
            onClick={onClose}
            loading={loading}
          >
            {intl.formatMessage({
              id: 'nZLeaQ',
              defaultMessage: 'Cancelar',
            })}
          </Button>
          <Spacer margin="4" direction="horizontal" />
          <Button
            disabled={currentAmount !== 0 || !isValid}
            type="submit"
            loading={loading}
            form="conciliationForm"
          >
            {intl.formatMessage({
              id: 'MaBkv1',
              defaultMessage: 'Conciliar factura(s)',
            })}
          </Button>
        </div>
      </FlexContainer>
    </S.ReconciliationModalContainer>
  );
}

PaymentsReconciliation.displayName = 'PaymentsReconciliation';
export default PaymentsReconciliation;
