export enum OrderStatus {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  ACCEPTED = 'ACCEPTED',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
}

export enum LineItemStatus {
  PENDING_ACCEPTANCE = 'PENDING_ACCEPTANCE',
  ACCEPTED = 'ACCEPTED',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  WITH_ISSUES = 'WITH_ISSUES',
}

export enum BusinessRelationshipStatus {
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
}

export enum ShippingCostType {
  FREE = 'FREE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  TOTAL_PERCENT = 'TOTAL_PERCENT',
}

export enum Quantity {
  quantity = 'quantity',
  availableQuantity = 'availableQuantity',
  requestedQuantity = 'requestedQuantity',
}

export enum PermissionName {
  MANAGE_CLIENTS = 'MANAGE_CLIENTS',
  MANAGE_PROVIDERS = 'MANAGE_PROVIDERS',
  MANAGE_ADDRESSES = 'MANAGE_ADDRESSES',
  MANAGE_PURCHASE_ORDERS = 'MANAGE_PURCHASE_ORDERS',
  MANAGE_SALES_ORDERS = 'MANAGE_SALES_ORDERS',
  MANAGE_INVOICES_RECEIVED = 'MANAGE_INVOICES_RECEIVED',
  MANAGE_INVOICES_EMITTED = 'MANAGE_INVOICES_EMITTED',
  READ_PURCHASE_ORDERS = 'READ_PURCHASE_ORDERS',
  READ_SALES_ORDERS = 'READ_SALES_ORDERS',
  READ_TAX_PAYER_INFO = 'READ_TAX_PAYER_INFO',
  READ_INVOICES = 'READ_INVOICES',
  MANAGE_PAYMENTS = 'MANAGE_PAYMENTS',
}

export enum FeatureName {
  ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  CLIENT_LIST = 'CLIENT_LIST',
  FINANCING_REPAYMENTS = 'FINANCING_REPAYMENTS',
  FACTORING = 'FACTORING',
  HIGO_PAYMENTS = 'HIGO_PAYMENTS',
  SUPPLIERS_LIST = 'SUPPLIERS_LIST',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  COLABORATOR = 'COLABORATOR',
}

export enum InvoiceApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EDITED = 'EDITED',
  FINANCE_REQUESTED = 'FINANCE_REQUESTED',
}

export enum InvoiceMailboxStatus {
  PENDING_REVISION = 'PENDING_REVISION',
  RECEIVED = 'RECEIVED',
}

export enum InvoiceEmissionStatus {
  EMITTED = 'EMITTED',
  EMISSION_REQUESTED = 'EMISSION_REQUESTED',
  EMITTABLE = 'EMITTABLE',
  CANCELED = 'CANCELED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export enum InvoicePaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  PARTIAL = 'PARTIAL',
  CLOSED = 'CLOSED',
}

export enum InvoiceGlobalStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PAID = 'PAID',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  FINANCING_REQUESTED = 'FINANCING_REQUESTED',
}

export enum InvitationInvitedFor {
  VIEW_ORDER = 'VIEW_ORDER',
  CREATE_PRICE_LIST = 'CREATE_PRICE_LIST',
  VIEW_PRICE_LIST = 'VIEW_PRICE_LIST',
}

export enum BusinessActsAs {
  CLIENT = 'CLIENT',
  PROVIDER = 'PROVIDER',
  BOTH = 'BOTH',
}

export enum LineItemOrderable {
  PRODUCT = 'PRODUCT',
  PRODUCT_SIMPLE = 'PRODUCT_SIMPLE',
}

export enum PriceListCreatedBy {
  CLIENT = 'CLIENT',
  PROVIDER = 'PROVIDER',
}

export enum BusinessModules {
  INVOICES_BUYER = 'INVOICES_BUYER',
  INVOICES_PROVIDER = 'INVOICES_PROVIDER',
  PROCUREMENT = 'PROCUREMENT',
  SALES = 'SALES',
}

export enum SatStatus {
  SYNCED = 'SYNCED',
  SYNCING = 'SYNCING',
  SYNCING_HISTORICAL = 'SYNCING_HISTORICAL',
  NOT_SYNCED = 'NOT_SYNCED',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  SAT_LOADING_ERROR = 'SAT_LOADING_ERROR',
  VALIDATING_CREDENTIALS = 'VALIDATING_CREDENTIALS',
}

export enum InvoiceVoucherType {
  CREDIT_NOTE = 'CREDIT_NOTE',
  INVOICE = 'INVOICE',
  PAYMENT_COMPLEMENT = 'PAYMENT_COMPLEMENT',
  PAYROLL = 'PAYROLL',
  TRANSLATION = 'TRANSLATION',
}

export enum InvoicePaymentType {
  MANUAL = 'MANUAL',
  CREDIT_NOTE = 'CREDIT_NOTE',
  STP_PAYMENT = 'STP_PAYMENT',
  HIGO_PAYMENT = 'HIGO_PAYMENT',
  PAYMENT_COMPLEMENT = 'PAYMENT_COMPLEMENT',
  REQUEST_BANKING_INFO = 'REQUEST_BANKING_INFO',
  BANKING_TRANSACTION = 'BANKING_TRANSACTION',
}

export enum WalletEntryStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export enum Entryable {
  DepositEntry = 'DepositEntry',
  InvoicePaymentEntry = 'InvoicePaymentEntry',
  WithdrawalEntry = 'WithdrawalEntry',
  RefundEntry = 'RefundEntry',
  HigoPaymentSentEntry = 'HigoPaymentSentEntry',
  HigoPaymentReceivedEntry = 'HigoPaymentReceivedEntry',
  BankingTransaction = 'BankingTransaction',
}

export enum BankingInfoValidationStatus {
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
}

export enum BusinessUserValidationStatus {
  VALIDATED = 'VALIDATED',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

export enum TransactionType {
  FINANCE_REPAYMENT = 'FINANCE_REPAYMENT',
  PAYMENT = 'PAYMENT',
  DEPOSIT = 'DEPOSIT',
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
  WITHDRAWAL = 'WITHDRAWAL',
  HIGO_FUNDING = 'HIGO_FUNDING',
  ADVANCE_PAYMENT = 'ADVANCE_PAYMENT',
}

export enum IntegrationsName {
  QUICKBOOKS = 'quickBooks',
  QUICKBOOKS_SANDBOX = 'quickBooksSandbox',
  NETSUITE = 'netsuite',
  SAP = 'sap',
}

export enum PaymentInstructionStatus {
  DRAFTED = 'drafted',
  REQUESTED = 'requested',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  PAID = 'paid',
}

export enum PaymentInstructionInvoiceStatus {
  UNCOMPLETED = 'UNCOMPLETED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export enum FinancingRequestPaymentStatus {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PENDING = 'PENDING',
  OVERDUE = 'OVERDUE',
}

export enum BankingAccountAccountType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum OrderableType {
  PRODUCT = 'PRODUCT',
  PRODUCT_SIMPLE = 'PRODUCT_SIMPLE',
}
