import React from 'react';
import { useIntl } from 'react-intl';

import type { WalletEntry } from '@src/types/models';
import useTransaction from '@src/hooks/useTransaction';
import messages from '@src/translations/messages/transactions';

interface TransactionTypeProps {
  walletEntry: WalletEntry;
}

function TransactionType({ walletEntry }: TransactionTypeProps) {
  const intl = useIntl();
  const { type } = useTransaction(walletEntry);
  if (!type) {
    return null;
  }

  return <span>{intl.formatMessage(messages[type])}</span>;
}

export default TransactionType;
