import React from 'react';
import { useIntl } from 'react-intl';

import CreditNoteActions from './components/CreditNoteActions/CreditNoteActions';
import Table from '@src/components/ui/Table';
import PageTitle from '@src/components/shared/PageTitle';
import Invoice from '@src/components/shared/Invoice';
import Currency from '@src/components/i18n/Currency';
import { Card, Label, Text } from '@src/components/ui';
import Link from '@src/components/shared/Link';

import { Invoice as InvoiceType } from '@src/types/models';
import { Grid, Spacer } from '@src/components/shared/layouts';

interface CreditNoteViewProps {
  invoice: Pick<
    InvoiceType,
    | 'id'
    | 'approvalStatus'
    | 'paymentStatus'
    | 'paymentTerms'
    | 'expiryDate'
    | 'costCenter'
    | 'businessRelationship'
    | 'invoicingDate'
    | 'exchangeRate'
    | 'invoiceLineItems'
    | 'businessEmitter'
    | 'hasXmlFile'
    | 'businessEmitter'
    | 'outstandingBalance'
    | 'currency'
    | 'foreignTotal'
    | 'total'
    | 'invoiceables'
    | 'voucherType'
    | 'emitterTaxpayerIdentifier'
    | 'emitterTaxpayerName'
    | 'globalStatus'
    | 'invoices'
    | 'pendingCredit'
    | 'appliedCredit'
    | 'emissionStatus'
    | 'mailboxStatus'
    | 'appliedCreditByCreditNote'
  >;
}

const CreditNoteView = ({ invoice }: CreditNoteViewProps) => {
  const intl = useIntl();
  return (
    <>
      <PageTitle
        goBackLink
        actions={{
          render: () => (
            <CreditNoteActions
              invoiceId={invoice.id}
              approvalStatus={invoice.approvalStatus}
            />
          ),
        }}
      >
        {intl.formatMessage(
          {
            id: 'upM9Gc',
            defaultMessage: 'Nota crédito No.{invoiceId}',
          },
          {
            invoiceId: invoice.id,
          }
        )}
      </PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card>
            <Invoice invoice={invoice} />
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card padding="8">
            <Label strong size="lg">
              {intl.formatMessage({
                id: '8pGoWG',
                defaultMessage: 'Información de Nota de crédito',
              })}
            </Label>
            <Spacer />
            <Label strong>
              {intl.formatMessage({
                id: '8/cFG7',
                defaultMessage: 'Saldo aplicado',
              })}
            </Label>
            <Currency
              value={invoice.appliedCredit}
              currency={invoice.currency}
            />
            <Spacer />
            <Label strong>
              {intl.formatMessage({
                id: 'FjoERF',
                defaultMessage: 'Saldo sin aplicar',
              })}
            </Label>
            <Currency
              value={invoice.pendingCredit}
              currency={invoice.currency}
            />
            <Spacer margin="8" />
            <Label strong size="lg">
              {intl.formatMessage({
                id: 'qcCPop',
                defaultMessage: 'Facturas asociadas',
              })}
            </Label>
            <Table>
              <thead>
                {invoice?.invoices?.length ? (
                  <tr>
                    <th>
                      <Label strong>
                        {intl.formatMessage({
                          id: 'Z344cR',
                          defaultMessage: 'ID. Factura',
                        })}
                      </Label>
                    </th>
                    <th>
                      <Label strong>
                        {intl.formatMessage({
                          id: '8/cFG7',
                          defaultMessage: 'Saldo aplicado',
                        })}
                      </Label>
                    </th>
                  </tr>
                ) : (
                  <Text colorStep="600">
                    {intl.formatMessage({
                      id: '0FNWZc',
                      defaultMessage: 'No hay facturas asociadas',
                    })}
                  </Text>
                )}
              </thead>
              <tbody>
                {invoice?.invoices?.map((relatedInvoice, index) => {
                  const { id, appliedCreditByCreditNote } = relatedInvoice;
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/invoices/${id}`}>{id}</Link>
                      </td>
                      <td>
                        <Currency
                          value={appliedCreditByCreditNote}
                          currency={invoice.currency}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CreditNoteView;
