import React, { forwardRef, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Select from '@src/components/ui/Select';
import { useQuery } from '@apollo/client';
import COST_CENTERS_QUERY, {
  CostCentersQueryVariables,
  CostCentersQueryReturn,
} from '@src/graphql/queries/costCenters';
import useSettings from '@src/hooks/useSettings';
import { CostCenter } from '@src/types/models';

type CostCentersSelectProps = {
  disabled?: boolean;
  value?: Pick<CostCenter, 'id' | 'name'>;
  onChange?: (value: Pick<CostCenter, 'id' | 'name'>) => void;
};

function CostCentersSelect(
  { disabled, value, onChange, ...otherProps }: CostCentersSelectProps,
  ref
) {
  const intl = useIntl();
  const { business } = useSettings();
  const { data, loading } = useQuery<
    CostCentersQueryReturn,
    CostCentersQueryVariables
  >(COST_CENTERS_QUERY, {
    variables: {
      businessId: business?.id,
    },
    skip: !business?.id,
  });

  const costCenterOptions = useMemo(
    () =>
      data?.costCenters?.edges?.map((edge) => ({
        label: edge.node.name,
        value: edge.node.id,
      })) || [],
    [data]
  );

  const handleChange = (option) => {
    const costCenter = option && {
      id: option.value,
      name: option.label,
    };
    onChange?.(costCenter);
  };

  return (
    <Select
      ref={ref}
      options={costCenterOptions}
      defaultText={intl.formatMessage({
        defaultMessage: 'Centro de costos',
        id: 'fWNdF2',
      })}
      value={value && { value: value.id, label: value.name }}
      onChange={handleChange}
      isDisabled={!business?.id || disabled}
      loading={loading}
      {...otherProps}
    />
  );
}

export default forwardRef(CostCentersSelect);
