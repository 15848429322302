import React from 'react';
import { useIntl } from 'react-intl';
import { Control, useWatch } from 'react-hook-form';

import { Alert, Button } from '@src/components/ui';
import useBalance from '@src/hooks/useBalance';
import useCustomerSupportChat from '@src/hooks/useCustomerSupportChat';
import * as S from './InsufficientCreditNotification.styles';
import { trackEvent } from '@src/lib/analytics';
import { floatToCents, centsToFloat } from '@src/utils/currency';
import { FINANCING_REQUEST_UPGRADE_CAP_LIMIT } from '@src/constants/events';

interface InsufficientCreditNotificationProps {
  control: Control<any>;
}

function InsufficientCreditNotification({
  control,
}: InsufficientCreditNotificationProps) {
  const intl = useIntl();
  const openCustomerSupporChat = useCustomerSupportChat();
  const { creditCents } = useBalance();
  const invoices = useWatch({
    control,
    name: 'invoices',
  });
  const financingAmount = invoices.reduce(
    (previousValue, invoice) => previousValue + invoice.amount,
    0
  );
  const financingAmountCents = floatToCents(financingAmount);

  if (
    typeof creditCents === 'undefined' ||
    creditCents >= financingAmountCents
  ) {
    return null;
  }

  const handleButtonClick = () => {
    openCustomerSupporChat();
    trackEvent(FINANCING_REQUEST_UPGRADE_CAP_LIMIT, {
      total: centsToFloat(financingAmountCents),
      credit: centsToFloat(creditCents || 0),
    });
  };

  return (
    <Alert hasBorder color="danger">
      {intl.formatMessage({
        defaultMessage:
          'Saldo insuficiente. Solicita una extensión de crédito.',
        id: '31SPGD',
      })}
      <S.NotificationButton>
        <Button
          compact
          variant="outlined"
          color="neutral"
          onClick={handleButtonClick}
        >
          {intl.formatMessage({
            defaultMessage: 'Solicitar',
            id: 'qK4ogN',
          })}
        </Button>
      </S.NotificationButton>
    </Alert>
  );
}

export default InsufficientCreditNotification;
