import React from 'react';
import { useIntl } from 'react-intl';

import CopyButton from '@src/components/shared/buttons/CopyButton';
import { Header, Text } from '@src/components/ui';
import * as S from './SuccessEmailUpdate.styles';
import { Spacer } from '@src/components/shared/layouts';

interface SuccessEmailUpdateProps {
  email: string;
  onClose?: () => void;
}

function SuccessEmailUpdate({ email }: SuccessEmailUpdateProps) {
  const intl = useIntl();
  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: '¡Felicidades!',
          id: '1YGzpj',
        })}
      </Header>
      <Text color="neutral" colorStep="600">
        {intl.formatMessage({
          defaultMessage: 'Esta es tu dirección para recibir facturas',
          id: 'jYmQ/P',
        })}{' '}
        <Text color="primary" colorStep="500" as="span">
          {email}
        </Text>
        {'. '}
      </Text>
      <Spacer />
      <Text color="neutral" colorStep="600">
        {intl.formatMessage({
          defaultMessage:
            'Todas las facturas enviadas a este correo serán visualizadas en Higo para un mejor seguimiento.',
          id: '7u4dX9',
        })}{' '}
        {intl.formatMessage({
          defaultMessage:
            'Compártela con tus proveedores o reenvía las facturas de tu correo actual.',
          id: 'yRUVR1',
        })}
      </Text>
      <Spacer />
      <CopyButton text={email} />
    </S.Container>
  );
}

SuccessEmailUpdate.displayName = 'SuccessEmailUpdate';

export default SuccessEmailUpdate;
