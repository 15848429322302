import { gql } from '@apollo/client';
import { InvoiceApprovalStatus } from '@src/types/enums';
import { Invoice } from '@src/types/models';

const UPDATE_INVOICES_STATUS_MUTATION = gql`
  mutation UpdateInvoiceStatus(
    $businessId: ID!
    $invoiceId: ID!
    $status: InvoiceApprovalStatusEnum!
    $cancelationReason: String
    $edited: Boolean
    $costCenterId: ID
    $expiryDate: DateTime
    $paymentTerms: Int
    $exchangeRate: Float
    $providerContacts: [ProviderContactInput!]
  ) {
    updateInvoiceStatus(
      businessId: $businessId
      invoiceId: $invoiceId
      status: $status
      cancelationReason: $cancelationReason
      edited: $edited
      costCenterId: $costCenterId
      expiryDate: $expiryDate
      paymentTerms: $paymentTerms
      exchangeRate: $exchangeRate
      providerContacts: $providerContacts
    ) {
      id
      approvalStatus
    }
  }
`;

export type UpdateInvoiceStatusParams = {
  businessId?: string;
  invoiceId?: string;
  status?: keyof typeof InvoiceApprovalStatus;
  cancelationReason?: string;
  edited?: boolean;
  costCenterId?: string;
  expiryDate?: string;
  paymentTerms?: number;
  exchangeRate?: number;
  providerContacts?: Array<{
    email: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
  }>;
};

export type UpdateInvoiceStatusReturn = {
  updateInvoiceStatus: Pick<Invoice, 'id' | 'approvalStatus'>;
};

export default UPDATE_INVOICES_STATUS_MUTATION;
