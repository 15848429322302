import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Saldo: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 17"
        fill="none"
        {...rest}
      >
        <g clipPath="url(#clip0_2536_8251)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 1.51219V15.74C1 16.1461 1.44876 16.3868 1.78075 16.1588L3.60225 14.9077C3.80212 14.7704 4.07038 14.7974 4.23967 14.9718L5.55561 16.3276C5.75157 16.5295 6.073 16.5295 6.26895 16.3276L7.52052 15.0381C7.71648 14.8363 8.03791 14.8363 8.23386 15.0381L9.50581 16.3486C9.69374 16.5422 9.99909 16.5513 10.1979 16.3692L11.7614 14.9374C11.9323 14.7808 12.1869 14.7629 12.3776 14.8939L14.2192 16.1588C14.5512 16.3868 15 16.1461 15 15.74V1.51219C15 0.953173 14.5523 0.5 14 0.5H2C1.44772 0.5 1 0.953173 1 1.51219ZM12 6.57315H4V4.54876H12V6.57315ZM4 10.6219H12V8.59753H4V10.6219Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2536_8251">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Saldo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Saldo.displayName = 'Saldo';

export default Saldo;
