import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as S from '../SupplierView.styles';
import { Label, Button } from '@src/components/ui';
import useModal from '@src/hooks/useModal';
import { Grid, Spacer, FlexContainer } from '@src/components/shared/layouts';
import { BusinessProfile } from '@src/types/models';
import UpdateSupplierModal from '@src/components/pages/SupplierView/components/UpdateSupplierModal';
import SupplierBankingInformationModal from '@src/components/shared/modals/SupplierBankingInformationModal';

import type { SupplierRelationship } from '@src/types/models';
import Clabe from '@src/components/shared/Clabe';

type SupplierInformationProps = {
  supplier: BusinessProfile;
  bankingInfo?: {
    id?: string;
    bankId?: string;
    bankName?: string;
    clabe?: string;
    recipient?: string;
  };
  providerContact?: {
    id: string;
    email: string;
  };
  paymentTerms?: number;
  status: string;
  supplierRelationship?: SupplierRelationship;
};

export function SupplierInformation({
  supplier,
  bankingInfo,
  paymentTerms,
  supplierRelationship,
}: SupplierInformationProps) {
  const { open } = useModal(UpdateSupplierModal);
  const { open: openEditBankingInformation } = useModal(
    SupplierBankingInformationModal
  );

  const openBankingInfoSupplierModal = () => {
    if (supplierRelationship && supplierRelationship.supplier) {
      const {
        supplier: { name, defaultTaxPayerInfo },
        bankingInfo,
      } = supplierRelationship;

      openEditBankingInformation({
        businessRelationshipId: supplierRelationship?.id,
        bankingInfoId: bankingInfo?.id,
        clabe: bankingInfo?.clabe,
        bankId: bankingInfo?.bankId,
        recipient:
          bankingInfo?.recipient ||
          defaultTaxPayerInfo?.taxpayerName ||
          name ||
          '',
      });
    }
  };

  const taxpayerInfo = supplier.defaultTaxPayerInfo;
  const openCreateSupplierFromInvoiceModal = ({ isGeneralInformation }) => {
    if (supplierRelationship && supplierRelationship.supplier) {
      const {
        providerContact,
        supplier: { name, defaultTaxPayerInfo },
        paymentTerms,
        bankingInfo,
      } = supplierRelationship;
      open({
        initialValues: {
          supplierBusinessName: name,
          taxPayerName: defaultTaxPayerInfo?.taxpayerName || '',
          taxPayerId: defaultTaxPayerInfo?.taxpayerIdentifier || '',
          contactEmail: providerContact?.email || '',
          bankingInfoId: bankingInfo?.id,
          bankId: bankingInfo?.bankId,
          bank: bankingInfo?.bankName || '',
          clabe: bankingInfo?.clabe || '',
          ownerName:
            bankingInfo?.recipient || defaultTaxPayerInfo?.taxpayerName || '',
          paymentTerms: paymentTerms,
        },
        isGeneralInformation,
        taxpayerId: defaultTaxPayerInfo?.id || '',
        businessRelationshipId: supplierRelationship?.id,
        taxPayerName: defaultTaxPayerInfo?.taxpayerName || '',
        taxPayerIdentifier: defaultTaxPayerInfo?.taxpayerIdentifier || '',
      });
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={4} xs={12} lg={12}>
        <Grid item lg={4} xs={12}>
          <FlexContainer justifyContent={'space-between'}>
            <Label strong size="lg">
              <FormattedMessage defaultMessage="General" id="1iEPTM" />
            </Label>
            <Button
              compact
              onClick={() =>
                openCreateSupplierFromInvoiceModal({
                  isGeneralInformation: true,
                })
              }
              color="primary"
              variant="ghosted"
            >
              <FormattedMessage defaultMessage="Editar" id="VGHwB5" />
            </Button>
          </FlexContainer>
          <Spacer margin="4" />
          <S.Table>
            <tbody>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage defaultMessage="Nombre" id="hCOqfl" />
                  </S.TableTitle>
                </td>
                <td>{supplier.name}</td>
              </tr>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage
                      defaultMessage="Razón social"
                      id="pmiu07"
                    />
                  </S.TableTitle>
                </td>
                <td>{taxpayerInfo?.taxpayerName}</td>
              </tr>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage defaultMessage="RFC" id="wvCzn7" />
                  </S.TableTitle>
                </td>
                <td>{taxpayerInfo?.taxpayerIdentifier}</td>
              </tr>
            </tbody>
          </S.Table>
        </Grid>
        <Grid item lg={4} xs={12}>
          <FlexContainer justifyContent={'space-between'}>
            <Label strong size="lg">
              <FormattedMessage
                defaultMessage="Información bancaria"
                id="a5uzPv"
              />
            </Label>
            <Button
              compact
              onClick={openBankingInfoSupplierModal}
              color="primary"
              variant="ghosted"
            >
              <FormattedMessage defaultMessage="Editar" id="VGHwB5" />
            </Button>
          </FlexContainer>
          <Spacer margin="4" />
          <S.Table>
            <tbody>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage defaultMessage="Banco" id="2MvH8U" />
                  </S.TableTitle>
                </td>
                <td>{bankingInfo?.bankName}</td>
              </tr>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage defaultMessage="Clabe" id="C6JgPA" />
                  </S.TableTitle>
                </td>
                <td>
                  {bankingInfo?.clabe && <Clabe account={bankingInfo?.clabe} />}
                </td>
              </tr>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage defaultMessage="Titular" id="C2qvs5" />
                  </S.TableTitle>
                </td>
                <td>{bankingInfo?.recipient}</td>
              </tr>
            </tbody>
          </S.Table>
        </Grid>
        <Grid item lg={4} xs={12}>
          <FlexContainer justifyContent={'space-between'}>
            <Label strong size="lg">
              <FormattedMessage defaultMessage="Términos de pago" id="cFxjey" />
            </Label>
            <Button
              compact
              onClick={() =>
                openCreateSupplierFromInvoiceModal({
                  isGeneralInformation: false,
                })
              }
              color="primary"
              variant="ghosted"
            >
              <FormattedMessage defaultMessage="Editar" id="VGHwB5" />
            </Button>
          </FlexContainer>
          <Spacer margin="4" />
          <S.Table>
            <tbody>
              <tr>
                <td>
                  <S.TableTitle>
                    <FormattedMessage
                      defaultMessage="Días de crédito"
                      id="6qS7oL"
                    />
                  </S.TableTitle>
                </td>
                <td>
                  {typeof paymentTerms === 'number' ? (
                    <FormattedMessage
                      defaultMessage="{paymentTerms} Días"
                      id="4HI6MG"
                      values={{ paymentTerms: paymentTerms }}
                    />
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            </tbody>
          </S.Table>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SupplierInformation;
