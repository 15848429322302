import React from 'react';
import { FormattedMessage } from 'react-intl';

import Currency, { DEFAULT_CURRENCY } from '@src/components/i18n/Currency';
import PaymentStatusBadge, {
  optionsForPaymentStatus,
} from '@src/components/shared/PaymentStatusBadge';
import InvoiceStatusBadge from '@src/components/shared/InvoiceStatusBadge';
import InvoiceGlobalStatusBadge, {
  optionsForInvoiceGlobalStatus,
} from '@src/components/shared/InvoiceGlobalStatusBadge';
import FormattedDate, {
  defaultTimeFormat,
  defaultDateFormat,
} from '@src/components/i18n/FormattedDate';
import { InvoiceApprovalStatus, InvoiceVoucherType } from '@src/types/enums';
import { CellCentered } from './InvoicesTable.styles';

import { Invoice } from '@src/types/models';
import { Attributes } from '@src/types/resourceList';

import { ReactComponent as VerifiedSvg } from '@src/assets/img/verified.svg';
import { ReactComponent as SATSvg } from '@src/assets/img/verified-sat.svg';

const attributes: Attributes<Invoice> = {
  satVerified: {
    name: (
      <CellCentered>
        <SATSvg />
      </CellCentered>
    ),
    type: 'CUSTOM',
    render: (datum) => datum.satVerified && <VerifiedSvg />,
  },
  id: {
    name: <FormattedMessage defaultMessage="No. Factura" id="OWCm2x" />,
    type: 'STRING',
    render: (datum) => datum.id,
  },
  globalStatus: {
    name: <FormattedMessage defaultMessage="Estatus" id="dWihL7" />,
    type: 'MULTI_VALUE',
    options: optionsForInvoiceGlobalStatus(),
    render: (datum) =>
      datum.approvalStatus === InvoiceApprovalStatus.PENDING ? (
        <InvoiceStatusBadge status={datum.approvalStatus} />
      ) : (
        <InvoiceGlobalStatusBadge
          expiryDate={datum.expiryDate}
          status={datum.globalStatus}
        />
      ),
    shouldRender: (datum, props) => props.shouldRenderApprovalStatus,
  },
  receiverCountryTaxPayerInfo: {
    name: <FormattedMessage defaultMessage="No. Folio" id="0/n5o8" />,
    type: 'STRING',
    render: (datum) => datum?.receiverCountryTaxPayerInfo?.folio || 'NA',
  },
  voucherType: {
    name: <FormattedMessage defaultMessage="Documento" id="ZPVG8Y" />,
    type: 'STRING',
    render: (datum) => {
      return datum.voucherType === InvoiceVoucherType.CREDIT_NOTE ? (
        <FormattedMessage defaultMessage="Nota de crédito" id="URBInu" />
      ) : (
        <FormattedMessage defaultMessage="Factura" id="yZ36Da" />
      );
    },
    shouldRender: (datum, props) => props.shouldRenderDocument,
  },
  invoicingDate: {
    name: <FormattedMessage defaultMessage="Fecha de emisión" id="f4nC1Y" />,
    type: 'DATE_RANGE',
    sortable: true,
    filterable: true,
    render: (datum) =>
      datum.invoicingDate && (
        <FormattedDate value={datum.invoicingDate} {...defaultDateFormat} />
      ),
  },
  paymentDate: {
    name: <FormattedMessage defaultMessage="Fecha de pago" id="lnTg/E" />,
    type: 'DATE_RANGE',
    sortable: true,
    filterable: true,
    render: (datum) =>
      datum.paymentDate && (
        <FormattedDate value={datum.paymentDate} {...defaultTimeFormat} />
      ),
    shouldRender: (datum, props) => props.shouldRenderPaymentDate,
  },
  expiryDate: {
    name: (
      <FormattedMessage defaultMessage="Fecha de vencimiento" id="XF6Ipl" />
    ),
    type: 'DATE_RANGE',
    filterable: true,
    options: [],
    render: (datum) =>
      datum.expiryDate && <FormattedDate value={datum.expiryDate} />,
    sortable: true,
    shouldRender: (datum, props) => props.shouldRenderDueDate,
  },
  businessEmitter: {
    name: <FormattedMessage defaultMessage="Proveedor" id="RMvOYP" />,
    type: 'MULTI_VALUE',
    filterable: true,
    options: [],
    render: (datum) =>
      datum?.businessEmitter?.name || datum?.emitterTaxpayerName,
    shouldRender: (datum, props) => !props.shouldRenderProvider,
  },
  total: {
    name: <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />,
    type: 'CUSTOM',
    render: (datum) => (
      <Currency
        value={datum.foreignTotal ? datum.foreignTotal : datum.total}
        currency={datum.currency}
      />
    ),
    shouldRender: (datum, props) => !props.shouldRenderOutstandingBalance,
  },
  outstandingBalance: {
    name: <FormattedMessage defaultMessage="Saldo pendiente" id="h0TyH1" />,
    type: 'CUSTOM',
    render: (datum) => (
      <Currency value={datum.outstandingBalance} currency={DEFAULT_CURRENCY} />
    ),
    shouldRender: (datum, props) => props.shouldRenderOutstandingBalance,
  },
  paymentStatus: {
    name: <FormattedMessage defaultMessage="Pago" id="Lb3pmu" />,
    type: 'MULTI_VALUE',
    options: optionsForPaymentStatus(),
    render: (datum) => (
      <PaymentStatusBadge id={datum.id} status={datum.paymentStatus} tooltip />
    ),
    shouldRender: (datum, props) => props.shouldRenderPaymentStatus,
  },
  costCenter: {
    name: <FormattedMessage defaultMessage="Centro de costos" id="fWNdF2" />,
    options: [],
    render: (datum) => datum?.costCenter?.name,
    type: 'SINGLE_VALUE',
    filterable: false,
    shouldRender: (datum, props) => props.shouldRenderCostCenter,
  },
};

export default attributes;
