import React from 'react';
import { useIntl } from 'react-intl';

import Page from '@src/components/shared/Page';
import Card from '@src/components/ui/Card';
import Feature from '@src/components/shared/Feature';
import PageTitle from '@src/components/shared/PageTitle';
import AccountsReceivableList from './AccountsReceivableList';
import usePageTrack from '@src/hooks/usePageTrack';
import Permission from '@src/components/shared/Permission';

import { FeatureName, PermissionName } from '@src/types/enums';

function AccountsReceivableListContainer() {
  const intl = useIntl();
  usePageTrack('account_receivables', 'receivables');

  return (
    <Permission permissions={[PermissionName.MANAGE_INVOICES_EMITTED]} redirect>
      <Feature features={[FeatureName.ACCOUNTS_RECEIVABLE]} redirect>
        <Page
          title={intl.formatMessage({
            defaultMessage: 'Cuentas por cobrar',
            id: 'qiuiRz',
          })}
        >
          <PageTitle>
            {intl.formatMessage({
              id: 'qiuiRz',
              defaultMessage: 'Cuentas por cobrar',
            })}
          </PageTitle>
          <Card padding="8">
            <AccountsReceivableList />
          </Card>
        </Page>
      </Feature>
    </Permission>
  );
}

export default AccountsReceivableListContainer;
