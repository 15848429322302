import styled from 'styled-components';
import CardUI from '@src/components/ui/Card';

export const Form = styled.form`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 30rem;
`;

export const Card = styled(CardUI)`
  padding-bottom: ${({ theme }) => theme.spacing[2]};
`;

export const FormElement = styled.div`
  margin-top: ${({ theme }) => theme.spacing[8]};
`;
