import React from 'react';
import compose from 'lodash/flowRight';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import graphql from './graphql';
import messages from './messages';
import HigoSuite from './HigoSuite';
import toastr from '@src/lib/toastr';
import { trackEvent, trackPageView } from '@src/lib/analytics';
import Permission from '@src/components/shared/Permission';
import { withSettings } from '@src/components/SettingsProvider';
import { UPDATE_BUSINESS_FEATURE_GROUPS } from '@src/constants/events';

import { IntlShape } from 'react-intl';
import { UserRole, BusinessModules } from '@src/types/enums';
import { Business, Group } from '@src/types/models';

type HigoSuiteContainerProps = {
  intl: IntlShape;
  business?: Business;
  data: {
    loading: boolean;
    groups: Group[];
  };
  updateBusinessFeatureGroups: (
    businessId: string,
    businessGroups: any
  ) => Promise<{
    data: {
      updateBusinessFeatureGroups: {
        id: string;
      };
    };
  }>;
};

type HigoSuiteContainerState = {
  invoiceBuyerChecked: boolean;
  invoiceProviderChecked: boolean;
  procurementChecked: boolean;
  salesChecked: boolean;
  activeModules: Array<any>;
  deleteModules: Array<any>;
};

export const eventData = {
  view: 'HigoSuite',
};

export class HigoSuiteContainer extends React.Component<
  HigoSuiteContainerProps,
  HigoSuiteContainerState
> {
  constructor(props: HigoSuiteContainerProps) {
    super(props);
    this.state = {
      invoiceBuyerChecked: false,
      invoiceProviderChecked: false,
      procurementChecked: false,
      salesChecked: false,
      deleteModules: [],
      activeModules: [],
    };
  }

  componentDidMount() {
    trackPageView('higo_suite', 'core');
    const activeGroups = this.getActiveGroups();

    this.setState(() => ({
      invoiceBuyerChecked: activeGroups.includes(
        BusinessModules.INVOICES_BUYER
      ),
      invoiceProviderChecked: activeGroups.includes(
        BusinessModules.INVOICES_PROVIDER
      ),
      procurementChecked: activeGroups.includes(BusinessModules.PROCUREMENT),
      salesChecked: activeGroups.includes(BusinessModules.SALES),
    }));
  }

  getActiveGroups = (): Array<keyof typeof BusinessModules> => {
    const { business } = this.props;
    return business && business.businessGroups
      ? business.businessGroups.edges.map((edge) => edge.node.group.name)
      : [];
  };

  updateBusinessFeatureGroups = async () => {
    const {
      activeModules,
      deleteModules,
      salesChecked,
      invoiceBuyerChecked,
      invoiceProviderChecked,
      procurementChecked,
    } = this.state;
    const {
      business,
      updateBusinessFeatureGroups,
      data,
      intl: { formatMessage },
    } = this.props;

    const activeGroups =
      business && business.businessGroups
        ? business.businessGroups.edges.map((edge) => edge.node)
        : [];

    const modifiedGroups: Array<{
      businessId: string;
      groupId: string;
      id?: string;
      _destroy?: boolean;
    }> = [];

    if (
      business &&
      (invoiceBuyerChecked ||
        salesChecked ||
        invoiceProviderChecked ||
        procurementChecked)
    ) {
      activeModules.forEach((module) => {
        const groupId =
          data.groups &&
          data.groups.filter((group) => group.name === module)[0].id;

        modifiedGroups.push({
          businessId: business.id,
          groupId: groupId,
        });
      });

      deleteModules.forEach((module) => {
        const group = activeGroups.filter(
          (group) => group.group.name === module
        )[0];

        modifiedGroups.push({
          id: group.id,
          groupId: group.group.id,
          businessId: business.id,
          _destroy: true,
        });
      });

      try {
        await updateBusinessFeatureGroups(business.id, modifiedGroups);
        trackEvent(UPDATE_BUSINESS_FEATURE_GROUPS, eventData);

        toastr.success(
          formatMessage(messages.updateBusinessFeatureGroupsSuccessTitle),
          formatMessage(messages.updateBusinessFeatureGroupsSuccessMessages)
        );
      } catch (e) {
        toastr.error(
          formatMessage(messages.updateBusinessFeatureGroupsError),
          e.message
        );
      }
    } else {
      toastr.error(
        formatMessage(messages.notModulesSelectedTitleError),
        formatMessage(messages.notModulesSelectedTitleMessage)
      );
    }
  };

  handleSwitch = (switchName: keyof typeof BusinessModules) => {
    const { business } = this.props;
    const {
      invoiceBuyerChecked,
      invoiceProviderChecked,
      procurementChecked,
      salesChecked,
    } = this.state;
    const { deleteModules, activeModules } = this.state;

    const activeGroups =
      business && business.businessGroups
        ? business.businessGroups.edges.map((edge) => edge.node.group)
        : [];

    const getIfActiveGroup = () => {
      return (
        activeGroups.filter((activeGroup) => activeGroup.name === switchName)
          .length > 0
      );
    };

    let indexModule, switchValue;

    switch (switchName) {
      case BusinessModules.INVOICES_BUYER:
        switchValue = invoiceBuyerChecked;
        break;
      case BusinessModules.INVOICES_PROVIDER:
        switchValue = invoiceProviderChecked;
        break;
      case BusinessModules.PROCUREMENT:
        switchValue = procurementChecked;
        break;
      case BusinessModules.SALES:
        switchValue = salesChecked;
        break;
      default:
        break;
    }

    const modifiedDelete = deleteModules;
    const modifiedActive = activeModules;

    const modules = modifiedActive.includes(switchName);
    const deletes = modifiedDelete.includes(switchName);

    if (switchValue && getIfActiveGroup()) {
      modifiedDelete.push(switchName);
    }

    if (switchValue && modules) {
      indexModule = modifiedActive.indexOf(switchName);
      modifiedActive.splice(indexModule, 1);
    }

    if (!switchValue && !getIfActiveGroup() && !modules) {
      modifiedActive.push(switchName);
    }

    if (!switchValue && deletes) {
      indexModule = modifiedDelete.indexOf(switchName);
      modifiedDelete.splice(indexModule, 1);
    }

    this.setState(() => {
      if (switchName === BusinessModules.INVOICES_BUYER) {
        return {
          deleteModules: modifiedDelete,
          activeModules: modifiedActive,
          invoiceBuyerChecked: !invoiceBuyerChecked,
          invoiceProviderChecked: invoiceProviderChecked,
          procurementChecked: procurementChecked,
          salesChecked: salesChecked,
        };
      }

      if (switchName === BusinessModules.INVOICES_PROVIDER) {
        return {
          deleteModules: modifiedDelete,
          activeModules: modifiedActive,
          invoiceBuyerChecked: invoiceBuyerChecked,
          invoiceProviderChecked: !invoiceProviderChecked,
          procurementChecked: procurementChecked,
          salesChecked: salesChecked,
        };
      }

      if (switchName === BusinessModules.PROCUREMENT) {
        return {
          deleteModules: modifiedDelete,
          activeModules: modifiedActive,
          procurementChecked: !procurementChecked,
          invoiceBuyerChecked: invoiceBuyerChecked,
          invoiceProviderChecked: invoiceProviderChecked,
          salesChecked: salesChecked,
        };
      }

      if (switchName === BusinessModules.SALES) {
        return {
          deleteModules: modifiedDelete,
          activeModules: modifiedActive,
          salesChecked: !salesChecked,
          procurementChecked: procurementChecked,
          invoiceBuyerChecked: invoiceBuyerChecked,
          invoiceProviderChecked: invoiceProviderChecked,
        };
      }
    });
  };

  render() {
    const {
      invoiceBuyerChecked,
      invoiceProviderChecked,
      procurementChecked,
      salesChecked,
    } = this.state;

    const groups = this.props.data.groups ? this.props.data.groups : [];
    return (
      <Permission minimumRoles={[UserRole.ADMIN]} redirect>
        <HigoSuite
          groups={groups}
          invoiceBuyerChecked={invoiceBuyerChecked}
          invoiceProviderChecked={invoiceProviderChecked}
          procurementChecked={procurementChecked}
          salesChecked={salesChecked}
          handleSwitch={this.handleSwitch}
          updateBusinessFeatureGroups={this.updateBusinessFeatureGroups}
        />
      </Permission>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  withSettings,
  graphql
)(HigoSuiteContainer);
