import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import * as S from './BusinessUserValidationModal.styles';
import Header from '@src/components/ui/Header';
import BankingInformationForm from '@src/components/shared/form/BankingInformationForm';
import Tag from '@src/components/ui/Tag';
import useModal from '@src/hooks/useModal';
import usePageTrack from '@src/hooks/usePageTrack';
import { APP_DATA_QUERY_NAME } from '@src/graphql/queries';
import { START_BUSINESS_USER_VALIDATION } from '@src/graphql/mutations';
import useToast from '@src/hooks/useToast';

type BusinessUserValidationModalProps = {
  taxpayerIdentifier?: string;
  recipient?: string;
};
function BusinessUserValidationModal({
  taxpayerIdentifier,
  recipient,
}: BusinessUserValidationModalProps) {
  usePageTrack('business_user_validation', 'core');
  const intl = useIntl();
  const { toast } = useToast();
  const { close } = useModal(BusinessUserValidationModal);
  const rfc = taxpayerIdentifier;

  const [mutateBusinessValidation, { loading }] = useMutation(
    START_BUSINESS_USER_VALIDATION,
    {
      refetchQueries: [APP_DATA_QUERY_NAME],
      awaitRefetchQueries: true,
    }
  );

  const handleBusinessValidation = async ({ bankId, clabe }) => {
    try {
      await mutateBusinessValidation({
        variables: {
          bankingInfo: {
            bankId,
            clabe: clabe?.replace(/\s/g, ''),
            recipient,
          },
        },
      });

      close();
    } catch (e: any) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Hubo un problema al validar información',
          id: 'mgGk1z',
        }),
        e.message
      );
    }
  };

  return (
    <S.Container>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Agregar cuenta',
          id: 'NMM9Mz',
        })}
      </Header>

      <S.Body>
        {intl.formatMessage({
          defaultMessage:
            'Para verificar tu información bancaria, el titular de la CLABE debe tener el mismo RFC con la que diste de alta tu cuenta ',
          id: 'wVfYVe',
        })}
        {rfc && (
          <>
            {' '}
            <Tag compact color="gray" label={`(RFC: ${rfc})`} />
          </>
        )}
      </S.Body>

      <BankingInformationForm
        onCancel={close}
        onSubmit={handleBusinessValidation}
        loading={loading}
      />
    </S.Container>
  );
}

BusinessUserValidationModal.displayName = 'BusinessUserValidationModal';

export default BusinessUserValidationModal;
