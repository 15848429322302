import styled from 'styled-components';

export const ImageContainer = styled.div<{ small?: boolean }>`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme, small }) =>
    small ? theme.spacing[10] : theme.spacing[16]};
  height: ${({ theme, small }) =>
    small ? theme.spacing[10] : theme.spacing[16]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  color: ${({ theme }) => theme.colors.neutral[300]};
  margin-bottom: 0;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 3.5rem;
  max-height: 3rem;
`;
