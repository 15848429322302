import React from 'react';
import { useIntl } from 'react-intl';

import attributes from './attributes';
import EmptyResults from '@src/components/shared/EmptyResults';
import useInvoicesEmittedQuery from '@src/hooks/graphql/queries/useInvoicesEmittedQuery';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import useSettings from '@src/hooks/useSettings';
import InvoicesTable from '@src/components/shared/InvoicesTable';
import useQueryParams from '@src/hooks/useQueryParams';
import { InvoicesEmittedVariables } from '@src/graphql/queries/invoicesEmitted';

import { Business } from '@src/types/models';
import {
  InvoiceApprovalStatus,
  InvoicePaymentStatus,
  InvoiceEmissionStatus,
} from '@src/types/enums';

export type InvoiceEmitted = {
  id: string;
  approvalStatus: keyof typeof InvoiceApprovalStatus;
  businessReceiver: Business;
  createdAt: string;
  paymentStatus: keyof typeof InvoicePaymentStatus;
  emissionStatus: keyof typeof InvoiceEmissionStatus;
  total: number;
  currency: string;
  invoiceables: Array<{ id: string } | { hashId: string }>;
};

export type Provider = {
  id: string;
  name: string;
};

export function AccountsReceivableList() {
  const intl = useIntl();
  const [query] = useQueryParams();
  const limit = 50;
  const { business } = useSettings();
  const filters: InvoicesEmittedVariables = {
    first: limit,
    approvalStatus: query?.approvalStatus?.split(','),
    paymentStatus: query?.paymentStatus?.split(','),
    emissionStatus: query?.emissionStatus?.split(','),
    businessReceiverId: query?.businessReceiverId?.split(','),
    search: query?.search,
    sortBy: {
      field: 'CREATED_AT',
      direction: 'DESC',
    },
  };
  const {
    loading,
    data: { invoices },
    count,
    hasNext,
    hasPrevious,
    page,
    nodes,
    next,
    previous,
  } = useInvoicesEmittedQuery({
    businessId: business?.id,
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  return (
    <InvoicesTable
      columns={[
        'id',
        'createdAt',
        'businessReceiver',
        'globalStatus',
        'paymentStatus',
        'total',
      ]}
      customAttributes={attributes}
      nodes={nodes}
      invoices={invoices}
      loading={loading}
      onNext={next}
      hasNext={hasNext}
      onPrevious={previous}
      hasPrevious={hasPrevious}
      limit={50}
      selectionLimit={200}
      page={page}
      count={count}
      emptyElement={
        <EmptyResults
          label={intl.formatMessage({
            defaultMessage: 'No tienes cuentas por cobrar',
            id: 'ILEab7',
          })}
          description={intl.formatMessage({
            defaultMessage: 'Aquí podrás ver las facturas por cobrar.',
            id: 'qT56aE',
          })}
        />
      }
    />
  );
}

export default AccountsReceivableList;
