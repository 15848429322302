import styled from 'styled-components';

export const Terms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing[1]};

    a {
      color: ${({ theme }) => theme.colors.primary[500]};
    }
  }
`;
