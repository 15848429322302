import { useMemo } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import {
  InvoicesReceivedQueryReturn,
  INVOICES_RECEIVED_QUERY,
} from '@src/graphql/queries';
import { InvoiceFilters } from '@src/hooks/useInvoiceFilters';
import usePagination from '@src/hooks/usePagination';
import { InvoiceListAttributes } from '@src/graphql/fragments';

function useInvoicesListQuery(
  variables?: InvoiceFilters
): {
  loading: boolean;
  error?: ApolloError;
  data: {
    invoices: InvoiceListAttributes[];
    totalAmount: number;
  };
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  next: () => void;
  previous: () => void;
  page: number;
  nodes: InvoiceListAttributes[];
  resetPaginationParams: (otherParams?: Record<string, any>) => void;
} {
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery<InvoicesReceivedQueryReturn>(INVOICES_RECEIVED_QUERY, {
    variables: variables || {},
    skip: !variables?.businessId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    nodes,
    resetPaginationParams,
  } = usePagination<InvoiceListAttributes>(
    data?.invoicesReceived,
    refetch,
    variables?.first || variables?.last,
    variables?.page
  );

  const invoices: InvoiceListAttributes[] = useMemo(() => {
    if (!data?.invoicesReceived?.edges) {
      return [];
    }
    return data.invoicesReceived.edges.map((edge) => edge.node);
  }, [data]);

  return {
    error,
    loading: !data && loading,
    data: {
      invoices,
      totalAmount: data?.invoicesReceived?.pendingTotal || 0,
    },
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    nodes,
    resetPaginationParams,
  };
}

export default useInvoicesListQuery;
