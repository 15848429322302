import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PAID: {
    defaultMessage: 'Completo',
    id: '9u2+Yo',
  },
  PENDING: {
    defaultMessage: 'Pendiente',
    id: 'uZ+xW0',
  },
  PARTIAL: {
    defaultMessage: 'Parcial',
    id: 'Uc3Vm+',
  },
  EXPIRED: {
    defaultMessage: 'Vencida',
    id: 'V6Zcg0',
  },
  CLOSED: {
    defaultMessage: 'Cerrada',
    id: 'twIVv3',
  },
});

export default messages;
