import React, { forwardRef } from 'react';
import { trackEvent } from '@src/lib/analytics';
import { useQuery } from '@apollo/client';
import { Control, useWatch } from 'react-hook-form';

import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import {
  BANKING_INFO_CREATED,
  PAYMENT_METHOD_CHANGED,
  BANKING_INFO_MODAL_OPENED,
} from '@src/constants/events';
import SupplierBankingInformation from '@src/components/shared/modals/SupplierBankingInformationModal';
import PaymentMethodDropdown from '@src/components/shared/dropdown/PaymentMethodDropdown';
import { BUSINESS_RELATIONSHIP_QUERY } from '@src/graphql/queries';
import usePaymentWithoutInvoicePaymentMethods from '@src/hooks/usePaymentWithoutInvoicePaymentMethods';

import { FormData } from '../../PaymentWithoutInvoice';
import { InvoicePaymentType } from '@src/types/enums';
import { PaymentMethod as PaymentMethodType } from '@src/types/models';

interface PaymentMethod {
  control: Control<FormData>;
  businessRelationshipId?: string;
  error?: boolean;
  disabled?: boolean;
  watchName: string;
  onChange: (method: PaymentMethod) => void;
}

const PaymentMethod = ({
  control,
  onChange,
  disabled,
  error,
  watchName,
  ...props
}) => {
  const { business } = useSettings();
  const { open: openNewSupplierBankingInfoModal } = useModal(
    SupplierBankingInformation
  );
  const businessRelationshipId = useWatch({ control, name: watchName });

  const handlePaymentMethodChange = (method: PaymentMethodType) => {
    onChange(method);
    trackEvent(PAYMENT_METHOD_CHANGED, {
      value: method.paymentType,
    });
  };
  const { data, refetch } = useQuery(BUSINESS_RELATIONSHIP_QUERY, {
    variables: {
      businessId: business?.id,
      businessRelationshipId,
    },
    skip: !businessRelationshipId || !business,
  });

  const businessRelationship = data?.businessRelationship;

  const bankingInfos = businessRelationship?.bankingInfo
    ? [
        {
          ...businessRelationship.bankingInfo,
          clabe: businessRelationship.bankingInfo.clabe || '',
        },
      ]
    : [];

  const { paymentTypes } = usePaymentWithoutInvoicePaymentMethods(
    businessRelationship?.bankingInfo
  );

  const handleNewClick = () => {
    if (businessRelationshipId) {
      openNewSupplierBankingInfoModal({
        businessRelationshipId,
        recipient: businessRelationship?.supplier.name,
        onSuccess: (data) => {
          trackEvent(BANKING_INFO_CREATED, {
            bankId: data?.bankId,
          });
          if (data?.id) {
            refetch();
          }
          onChange?.({
            paymentType: InvoicePaymentType.STP_PAYMENT,
            value: data?.id,
          });
        },
      });
      trackEvent(BANKING_INFO_MODAL_OPENED);
    }
  };

  return (
    <PaymentMethodDropdown
      fullWidth
      bankingInfos={bankingInfos}
      onChange={handlePaymentMethodChange}
      disabled={disabled || !businessRelationshipId}
      error={!!error?.message}
      paymentMethods={paymentTypes}
      onNewClick={bankingInfos?.length === 0 ? handleNewClick : undefined}
      {...props}
    />
  );
};

export default forwardRef(PaymentMethod);
