import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import CreditTermsForm from '@src/components/pages/RequestFinancing/components/CreditTermsForm';
import useModal from '@src/hooks/useModal';
import useToast from '@src/hooks/useToast';
import useSettings from '@src/hooks/useSettings';
import {
  REQUEST_INVOICE_FINANCING,
  FinancingRequestInvoiceParams,
  FinancingRequestInvoiceReturn,
} from '@src/graphql/mutations';
import { APP_DATA_QUERY_NAME } from '@src/graphql/queries';
import { floatToCents } from '@src/utils/currency';
import useQueryParams from '@src/hooks/useQueryParams';
import InvoicePaymentForm from '@src/components/shared/form/InvoicePaymentForm';

import { InvoicePaymentType } from '@src/types/enums';
import { Invoice, PaymentMethod } from '@src/types/models';
import useInvoices from '@src/hooks/useInvoices';
import usePaymentDefaults from '@src/hooks/usePaymentDefaults';
import { InvoiceListAttributes } from '@src/graphql/fragments';

interface FormData {
  invoices: Array<
    Pick<Invoice, 'id'> & {
      amount: number;
      paymentConcept: string;
      paymentMethod: PaymentMethod;
      email: string[];
    }
  >;
}

function RequestFinancingForm() {
  const intl = useIntl();
  const [{ invoiceIds }] = useQueryParams();
  const { business } = useSettings();
  const { toast } = useToast();
  const { open: openCreditTerms, close: closeCreditTerms } = useModal(
    CreditTermsForm
  );
  const history = useHistory();
  const { data, loading } = useInvoices(invoiceIds, {
    sort: {
      key: 'emitterTaxpayerName',
      order: 'asc',
    },
  });
  const invoices = usePaymentDefaults<InvoiceListAttributes>(data, {
    paymentMethod: {
      paymentType: InvoicePaymentType.HIGO_PAYMENT,
      value: undefined,
    },
  });
  const [requestFinancing, { loading: submitting }] = useMutation<
    FinancingRequestInvoiceReturn,
    FinancingRequestInvoiceParams
  >(REQUEST_INVOICE_FINANCING, {
    refetchQueries: [APP_DATA_QUERY_NAME],
  });

  if (!invoiceIds || invoiceIds.length === 0) {
    history.push('/');
    toast.info(
      intl.formatMessage({
        defaultMessage:
          'Necesitas seleccionar al menos una factura para poder solicitar financiamiento',
        id: 'e0LDbY',
      })
    );
  }

  const handleSubmit = (data: FormData) => {
    const financingAmount = data.invoices.reduce(
      (previousValue, invoice) => previousValue + invoice.amount,
      0
    );
    const financingAmountCents = floatToCents(financingAmount);
    if (business?.id) {
      openCreditTerms({
        financingAmountCents,
        loading: submitting,
        onSubmit: async (creditDays) => {
          try {
            await requestFinancing({
              variables: {
                businessId: business.id,
                creditDays,
                invoicesPaymentsDetails: data.invoices.map((invoice) => ({
                  invoiceId: invoice.id,
                  amountCents: floatToCents(invoice.amount),
                  bankingInfoId:
                    invoice.paymentMethod.paymentType ===
                    InvoicePaymentType.STP_PAYMENT
                      ? invoice.paymentMethod.value
                      : undefined,
                  email: invoice.email?.join(','),
                  paymentConcept: invoice.paymentConcept,
                })),
              },
            });
            closeCreditTerms();
            history.replace('/accounts_payable');
            toast.success(
              intl.formatMessage({
                defaultMessage:
                  'Hemos recibido tu solicitud de financiamiento.',
                id: 'T7hp7o',
              })
            );
          } catch (error) {
            toast.error(error.message);
          }
        },
      });
    }
  };

  return (
    <InvoicePaymentForm
      wallet="credit"
      title={intl.formatMessage({
        defaultMessage: 'Solicitar financiamiento',
        id: 'vnyh9H',
      })}
      onSubmit={handleSubmit}
      invoices={invoices}
      loading={loading}
      submitting={submitting}
      ctaLabel={intl.formatMessage({
        defaultMessage: 'Revisar términos del crédito',
        id: 'DVeArO',
      })}
      invoiceCount={invoiceIds?.length}
      PaymentMethodDropdownProps={{
        disabled: true,
        paymentMethods: [InvoicePaymentType.HIGO_PAYMENT],
      }}
    />
  );
}

export default RequestFinancingForm;
