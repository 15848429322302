import styled, { css, Color, ColorStep } from 'styled-components';

type IconProps = {
  $color?: Color;
  $colorStep?: ColorStep;
};

export const StyledIcon = styled.div<IconProps>`
  color: ${({ theme, $color, $colorStep }) =>
    $color ? theme.colors[$color || 'neutral'][$colorStep || 900] : 'inherit'};
  font-size: inherit;
  line-height: 1;
  display: inline-block;
  position: relative;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        cursor: pointer;
        width: 1.4em;
        height: 1.4em;
        left: -0.2em;
        top: -0.2em;
        transition: 300ms;
        border-radius: 50%;
        background-color: currentColor;
        opacity: 0;
      }
      &:hover::after {
        opacity: 0.1;
      }
    `};
`;
