import { gql } from '@apollo/client';

export const CREATE_WITHDRAWAL_WALLET_ENTRY_MUTATION = gql`
  mutation CreateWithdrawalWalletEntry(
    $businessId: ID!
    $amount: Int!
    $paymentConcept: String
  ) {
    createWithdrawalWalletEntry(
      businessId: $businessId
      amount: $amount
      paymentConcept: $paymentConcept
    ) {
      id
      amount
    }
  }
`;

export type CreateWithdrawalWalletEntryParams = {
  businessId: string;
  amount: number;
  paymentConcept?: string;
};

export type CreateWithdrawalWalletEntryReturn = {
  id: number;
  amount: number;
};

export default CREATE_WITHDRAWAL_WALLET_ENTRY_MUTATION;
