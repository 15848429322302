import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import * as S from './CreateCostCenterForm.styles';

import { Header, TextField, Button } from '@src/components/ui';
import useSettings from '@src/hooks/useSettings';
import { trackEvent } from '@src/lib/analytics';
import { CREATE_COST_CENTER } from '@src/constants/events';
import useToast from '@src/hooks/useToast';

import CREATE_COST_CENTER_MUTATION from '@src/graphql/mutations/createCostCenter';
import COST_CENTERS_QUERY from '@src/graphql/queries/costCenters';
import { FlexContainer, Spacer } from '../../../../shared/layouts';

type CreateCostCenterFormProps = {
  onClose?: () => void;
};

function CreateCostCenterForm({ onClose }: CreateCostCenterFormProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const { toast } = useToast();
  const { register, handleSubmit } = useForm();
  const [createCostCenter, { loading }] = useMutation(
    CREATE_COST_CENTER_MUTATION,
    {
      refetchQueries: [COST_CENTERS_QUERY],
      onCompleted: () => {
        trackEvent(CREATE_COST_CENTER);
        toast.success(
          intl.formatMessage({
            defaultMessage: 'Centro de costos creado',
            id: '5gMX2P',
          }),
          intl.formatMessage({
            defaultMessage: 'Se ha creado un centro de costos exitosamente',
            id: 'ci/4Oe',
          })
        );
        onClose?.();
      },
      onError: () => {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error creando centro de costos',
            id: 'b8oLJo',
          })
        );
      },
    }
  );

  const onSubmit = (data) => {
    if (business?.id) {
      createCostCenter({
        variables: {
          name: data.name,
          businessId: business.id,
        },
      });
    }
  };
  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Nuevo centro de costos',
          id: 'aMU8mo',
        })}
      </Header>
      <Spacer />
      <TextField
        fullWidth
        {...register('name', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Centro de costos',
          id: 'fWNdF2',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'Comida y bebida',
          id: 'qAoSp4',
        })}
        autoFocus
      />

      <FlexContainer justifyContent="flex-end">
        <Button variant="ghosted" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button type="submit" loading={loading} disabled={loading}>
          {intl.formatMessage({
            defaultMessage: 'Guardar',
            id: 'oN4aGu',
          })}
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

CreateCostCenterForm.displayName = 'CreateCostCenterForm';

export default CreateCostCenterForm;
