import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import Dropdown, { DropdownItem } from '@src/components/ui/Dropdown';
import { trackEvent } from '@src/lib/analytics';
import { ARCHIVE_INVOICES, PAY_INVOICES } from '@src/constants/events';
import useToast from '@src/hooks/useToast';
import useQueryParams from '@src/hooks/useQueryParams';
import ConfirmationModal from '@src/components/shared/modals/ConfirmationModal';
import useModal from '@src/hooks/useModal';
import {
  EXPORT_INVOICES_MUTATION,
  ExportInvoicesReturn,
  ExportInvoicesVariables,
} from '@src/graphql/mutations/exportInvoices';
import {
  PAY_INVOICES_MUTATION,
  PayInvoicesReturn,
  PayInvoicesVariables,
} from '@src/graphql/mutations/payInvoices';
import ARCHIVE_INVOICES_MUTATION, {
  ArchiveInvoicesParams,
  ArchiveInvoicesReturn,
} from '@src/graphql/mutations/archiveInvoices';
import { useHistory } from 'react-router-dom';

interface InvoiceActionDropdownProps {
  invoiceIds: string[];
  businessId: string;
  userEmail?: string;
  allSelected?: boolean;
  count?: number;
  archivable?: boolean;
  unarchivable?: boolean;
  exportable?: boolean;
  payable?: boolean;
  onArchive?: (data: Array<{ id: string }>) => any;
  onUnarchive?: (data: Array<{ id: string }>) => any;
  onExport?: () => any;
  onMarkAsPaid?: () => any;
}

interface InvoiceActionDropdownContainerProps
  extends Omit<InvoiceActionDropdownProps, 'businessId'> {
  businessId?: string;
}

function InvoiceActionDropdown({
  businessId,
  userEmail,
  invoiceIds,
  allSelected,
  count,
  archivable,
  unarchivable,
  exportable,
  payable,
  onMarkAsPaid,
  onArchive,
  onExport,
  onUnarchive,
}: InvoiceActionDropdownProps) {
  const intl = useIntl();
  const { snack, toast } = useToast();
  const history = useHistory();
  const [query] = useQueryParams();
  const {
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModal(ConfirmationModal);
  const [archive] = useMutation<ArchiveInvoicesReturn, ArchiveInvoicesParams>(
    ARCHIVE_INVOICES_MUTATION,
    {
      variables: {
        businessId,
        invoiceIds,
        archived: true,
      },
      refetchQueries: ['InvoicesList'],
      onCompleted: (data) => {
        snack(
          intl.formatMessage({
            defaultMessage: 'Facturas archivadas con éxito',
            id: 'h7ZrN0',
          }),
          {
            label: intl.formatMessage({
              defaultMessage: 'Ir al archivo',
              id: 'Tiw7E/',
            }),
            onClick: () => history.push('/accounts_payable?tabIndex=2'),
          }
        );
        if (onArchive) onArchive(data?.archiveInvoices);
        trackEvent(ARCHIVE_INVOICES, {
          creator: 'Buyer',
          view: 'InvoicesListComponent',
        });
      },
      onError: (e) => {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al archivar las facturas',
            id: 'CXdE7Q',
          }),
          e.message
        );
      },
    }
  );
  const [unarchive] = useMutation<ArchiveInvoicesReturn, ArchiveInvoicesParams>(
    ARCHIVE_INVOICES_MUTATION,
    {
      variables: {
        businessId,
        invoiceIds,
        archived: false,
      },
      refetchQueries: ['InvoicesList'],
      onCompleted: (data) => {
        snack(
          intl.formatMessage({
            defaultMessage: 'Facturas desarchivadas con éxito',
            id: '4vabpW',
          })
        );
        if (onUnarchive) onUnarchive(data?.archiveInvoices);
        trackEvent(ARCHIVE_INVOICES, {
          creator: 'Buyer',
          view: 'InvoicesListComponent',
        });
      },
      onError: (e) => {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al archivar las facturas',
            id: 'CXdE7Q',
          }),
          e.message
        );
      },
    }
  );
  const [markAsPaid] = useMutation<PayInvoicesReturn, PayInvoicesVariables>(
    PAY_INVOICES_MUTATION,
    {
      variables: {
        businessId,
        invoiceIds,
      },
      refetchQueries: ['InvoicesList'],
      onCompleted: () => {
        snack(
          intl.formatMessage({
            defaultMessage: 'Facturas marcadas como pagadas',
            id: 'HooEN9',
          }),
          {
            label: intl.formatMessage({
              defaultMessage: 'Ir a cuentas pagadas',
              id: 'elh5ZD',
            }),
            onClick: () => history.push('/accounts_payable'),
          }
        );
        if (onMarkAsPaid) onMarkAsPaid();
        trackEvent(PAY_INVOICES, {
          creator: 'Buyer',
          view: 'InvoicesListComponent',
        });
      },
      onError: (e) => {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al marcar como pagadas las facturas',
            id: 'lZ4tws',
          }),
          e.message
        );
      },
    }
  );
  const [exportInvoices] = useMutation<
    ExportInvoicesReturn,
    ExportInvoicesVariables
  >(EXPORT_INVOICES_MUTATION, {
    onCompleted: () => {
      if (onExport) onExport();
      closeConfirmationModal();
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Las facturas fueron exportadas con éxito',
          id: 'C+DVrG',
        }),
        intl.formatMessage({
          defaultMessage: 'En unos minutos recibirás un email con los datos',
          id: 'IqQXCo',
        })
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al generar tus datos para exportar',
          id: '1yuAYN',
        }),
        e.message
      );
    },
  });

  const handleClickExport = () => {
    openConfirmationModal({
      title: intl.formatMessage(
        {
          defaultMessage: 'Exportar {count} facturas',
          id: 'GwNfxv',
        },
        {
          count: allSelected ? count : invoiceIds.length,
        }
      ),
      text: intl.formatMessage(
        {
          defaultMessage: 'Se enviará un archivo CSV al correo {email}',
          id: 'Q8VBye',
        },
        {
          email: userEmail,
        }
      ),
      confirmText: intl.formatMessage({
        defaultMessage: 'Si, enviar facturas',
        id: '2mW7N2',
      }),
      cancelText: intl.formatMessage({
        defaultMessage: 'Cancelar',
        id: 'nZLeaQ',
      }),
      onConfirm: () => {
        if (businessId) {
          if (allSelected) {
            exportInvoices({
              variables: {
                businessId,
                invoiceIds: undefined,
                businessEmitterId:
                  query?.businessEmitterId?.split(',') || undefined,
              },
            });
          } else {
            exportInvoices({
              variables: {
                businessId,
                invoiceIds,
              },
            });
          }
        }
      },
    });
  };

  return (
    <Dropdown
      label={intl.formatMessage({
        defaultMessage: 'Más acciones',
        id: 'GUG1Jz',
      })}
    >
      {payable && (
        <DropdownItem onClick={() => markAsPaid()}>
          {intl.formatMessage({
            defaultMessage: 'Marcar como pagadas',
            id: 'qRaWoR',
          })}
        </DropdownItem>
      )}
      {archivable && (
        <DropdownItem onClick={() => archive()}>
          {intl.formatMessage({
            defaultMessage: 'Archivar',
            id: 'zPPocI',
          })}
        </DropdownItem>
      )}
      {unarchivable && (
        <DropdownItem onClick={() => unarchive()}>
          {intl.formatMessage({
            defaultMessage: 'Desarchivar',
            id: 'PtKBh/',
          })}
        </DropdownItem>
      )}
      {exportable && (
        <DropdownItem onClick={handleClickExport}>
          {intl.formatMessage({
            defaultMessage: 'Exportar a CSV',
            id: 'GIB2xn',
          })}
        </DropdownItem>
      )}
    </Dropdown>
  );
}

function InvoiceActionDropdownContainer({
  businessId,
  ...otherProps
}: InvoiceActionDropdownContainerProps) {
  if (!businessId) return null;

  return <InvoiceActionDropdown businessId={businessId} {...otherProps} />;
}

export default InvoiceActionDropdownContainer;
