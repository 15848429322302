import React from 'react';
import { useIntl } from 'react-intl';

import Table from '@src/components/shared/TransactionsTable';
import useTransactionsListQuery from '@src/hooks/useTransactionsListQuery';
import useSettings from '@src/hooks/useSettings';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import Search from '@src/components/shared/Search';
import * as S from '@src/components/pages/TransactionList/components/TransactionsTable.styles';
import useQueryParams from '@src/hooks/useQueryParams';
import StatusFilter from '@src/components/shared/TransactionsTable/components/StatusFilter';
import DateFilter from '@src/components/shared/TransactionsTable/components/DateFilter';
import useSortFilters from '@src/hooks/useSortFilters';
import EmptyResults from '@src/components/shared/EmptyResults';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { Alert } from '@src/components/ui';
import { Grid } from '@src/components/shared/layouts';

interface SupplierTransactionsTableProps {
  supplierId: string;
  businessEmitterName: string;
}

function SupplierTransactionsTable({
  supplierId,
  businessEmitterName,
}: SupplierTransactionsTableProps) {
  const intl = useIntl();
  const [
    {
      search,
      status,
      createdAtTo,
      createdAtFrom,
      before,
      after,
      first,
      last,
      page: queryParamsPage,
    },
  ] = useQueryParams();
  const limit = RESULTS_PER_PAGE;
  const [sortBy, handleSort, sortParams] = useSortFilters();
  const business = useSettings('business');
  const {
    loading,
    error,
    data,
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
    resetPaginationParams,
  } = useTransactionsListQuery({
    businessId: business?.id,
    providerId: supplierId,
    search,
    status,
    createdAtFrom,
    createdAtTo,
    sortBy,
    ...getPaginationValuesForQuery(
      { page: queryParamsPage, before, after, first, last },
      limit
    ),
  });

  if (!loading && error) {
    return (
      <Alert color="danger" hasBorder>
        {intl.formatMessage({
          defaultMessage: 'Error al cargar los datos',
          id: 'yM0cd/',
        })}{' '}
        {error.message}
      </Alert>
    );
  }

  return (
    <>
      <S.TableActions>
        <S.Search>
          <Search fullWidth />
        </S.Search>
        <Grid container spacing={2}>
          <Grid item xs={3} md={4}>
            <DateFilter
              value={{ start: createdAtFrom, end: createdAtTo }}
              onChange={resetPaginationParams}
            />
          </Grid>
          <Grid item xs={3} md={4}>
            <StatusFilter value={status} onChange={resetPaginationParams} />
          </Grid>
        </Grid>
      </S.TableActions>
      <Table
        columns={{
          transactionIdentifier: 50,
          hashId: 80,
          createdAt: 100,
          recipient: 100,
          invoice: 80,
          providerContact: 100,
          transactionType: 100,
          amount: 80,
          sendEmail: 120,
        }}
        limit={limit}
        page={page}
        data={data}
        loading={loading}
        count={count}
        hasNext={hasNext}
        onNext={next}
        sort={sortParams}
        hasPrevious={hasPrevious}
        onPrevious={previous}
        onSort={handleSort}
        emptyElement={
          <EmptyResults
            label={intl.formatMessage(
              {
                defaultMessage:
                  'Aún no tienes transacciones con {supplierName}',
                id: '+K/A6F',
              },
              {
                supplierName: businessEmitterName,
              }
            )}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas las transacciones que tengan en Higo.',
              id: 'As3Ml5',
            })}
          />
        }
      />
    </>
  );
}

export default SupplierTransactionsTable;
