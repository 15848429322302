import { gql } from '@apollo/client';

export const CREATE_PROVIDER_CONTACT = gql`
  mutation CreateProviderContact(
    $businessId: ID!
    $businessRelationshipId: ID!
    $providerContact: ProviderContactInput!
  ) {
    createProviderContact(
      businessId: $businessId
      businessRelationshipId: $businessRelationshipId
      providerContact: $providerContact
    ) {
      id
    }
  }
`;

type ProviderContactInputType = {
  email: string;
  firstName?: string;
  lastName?: string;
};

export type CreateProviderContactParams = {
  businessId: string | number;
  businessRelationshipId: string;
  providerContact: ProviderContactInputType;
};
