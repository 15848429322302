import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const ArrowDownLine: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M12 18C11.4477 18 11 17.5523 11 17L11 3C11 2.44771 11.4477 2 12 2C12.5523 2 13 2.44771 13 3L13 17C13 17.5523 12.5523 18 12 18Z" />
        <path d="M12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071L4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289C4.68342 8.90237 5.31658 8.90237 5.70711 9.29289L12 15.5858L18.2929 9.29289C18.6834 8.90237 19.3166 8.90237 19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071L12.7071 17.7071Z" />
        <path d="M20 22C20 22.5523 19.5523 23 19 23L5 23C4.44771 23 4 22.5523 4 22C4 21.4477 4.44771 21 5 21L19 21C19.5523 21 20 21.4477 20 22Z" />
      </svg>
    );
  }
);

ArrowDownLine.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowDownLine.displayName = 'ArrowDownLine';

export default ArrowDownLine;
