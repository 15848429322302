import styled from 'styled-components';
import { CardTheme } from './types';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[6]} ${({ theme }) => theme.spacing[8]};
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  h3 {
    margin: 0;
  }
  padding-bottom: ${({ theme }) => theme.spacing[6]};
  padding-left: ${({ theme }) => theme.spacing[19]};
  padding-right: ${({ theme }) => theme.spacing[19]};
`;

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & :first-child {
    height: ${({ theme }) => theme.spacing[9]};
    width: ${({ theme }) => theme.spacing[40]};
  }
  & :last-child {
    height: ${({ theme }) => theme.spacing[4]};
    width: ${({ theme }) => theme.spacing[32]};
  }
`;

export const cardThemes: CardTheme = {
  primary: {
    card: {
      backgroundColor: 'primary',
      backgroundColorStep: '400',
    },
    errorTitle: {
      color: 'neutral',
      colorStep: '50',
    },
    errorMessage: {
      color: 'neutral',
      colorStep: '100',
    },
    skeleton: {
      backgroundColor: 'neutral',
      backgroundColorStep: '100',
      backgroundOpacity: '10',
    },
    icon: {
      backgroundColor: 'neutral',
      backgroundColorStep: '900',
      backgroundOpacity: '20',
      color: '#FFF',
    },
    cardTitle: {
      color: 'neutral',
      colorStep: '50',
    },
    owedAmount: {
      color: 'neutral',
      colorStep: '50',
    },
    availableAmount: {
      color: 'neutral',
      colorStep: '50',
    },
    progressBar: {
      backgroundColor: 'primary',
      backgroundColorStep: '300',
    },
    bar: {
      backgroundColor: 'neutral',
      backgroundColorStep: '50',
    },
    creditDetails: {
      color: 'neutral',
      colorStep: '50',
    },
  },
  neutral: {
    card: {
      backgroundColor: 'neutral',
      backgroundColorStep: '50',
    },
    skeleton: {
      backgroundColor: 'neutral',
      backgroundColorStep: '100',
      backgroundOpacity: '100',
    },
    errorTitle: {
      color: 'neutral',
      colorStep: '600',
    },
    errorMessage: {
      color: 'neutral',
      colorStep: '500',
    },
    icon: {
      backgroundColor: 'primary',
      backgroundColorStep: '50',
      backgroundOpacity: '100',
      color: '#0E22F1',
    },
    cardTitle: {
      color: 'primary',
      colorStep: '500',
    },
    owedAmount: {
      color: 'neutral',
      colorStep: '900',
    },
    availableAmount: {
      color: 'neutral',
      colorStep: '900',
    },
    progressBar: {
      backgroundColor: 'primary',
      backgroundColorStep: '50',
    },
    bar: {
      backgroundColor: 'primary',
      backgroundColorStep: '500',
    },
    creditDetails: {
      color: 'neutral',
      colorStep: '600',
    },
  },
};
