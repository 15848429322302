import styled from 'styled-components';

export const CancelButton = styled.button`
  outline: none;
  border: none;
  border-radius: 50%;
  height: ${({ theme }) => theme.spacing[6]};
  width: ${({ theme }) => theme.spacing[6]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${({ theme }) => theme.spacing[5]};
    width: ${({ theme }) => theme.spacing[5]};
    path {
      transition: stroke 200ms ease-in;
      stroke: ${({ theme }) => theme.colors.neutral[500]};
    }
  }

  &:hover svg path {
    stroke: ${({ theme }) => theme.colors.neutral[700]};
  }
`;
