import React, { useState } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Tab, TabList } from '@src/components/shared/Tabs';
import InvoiceActions from './components/InvoiceActions/InvoiceActions';
import Invoice from '@src/components/shared/Invoice';
import PageTitle from '@src/components/shared/PageTitle';
import HistoricalRecord from '@src/components/pages/InvoiceView/components/HistoricalRecord';
import { getExpiryDate } from '@src/utils/invoices';
import { getSupplierContacts } from '@src/utils/supplierContacts';
import useSettings from '@src/hooks/useSettings';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import EditableInvoiceInformation from './components/EditableInvoiceInformation';
import { Grid } from '@src/components/shared/layouts';
import { Button, Card, Divider } from '@src/components/ui';
import CodatSyncedAt from './components/CodatSyncedAt';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_INVOICE_STATUS } from '@src/constants/events';
import UPDATE_INVOICES_STATUS_MUTATION, {
  UpdateInvoiceStatusReturn,
  UpdateInvoiceStatusParams,
} from '@src/graphql/mutations/updateInvoiceStatus';
import IncompleteInvoiceNotification from './components/IncompleteInvoiceNotification/IncompleteInvoiceNotification';
import INVOICE_QUERY from '@src/graphql/queries/invoice';

import { CostCenter, Invoice as InvoiceType } from '@src/types/models';
import { InvoiceApprovalStatus } from '@src/types/enums';

interface InvoiceViewProps {
  invoice: Pick<
    InvoiceType,
    | 'id'
    | 'approvalStatus'
    | 'paymentStatus'
    | 'paymentTerms'
    | 'expiryDate'
    | 'codatSyncedAt'
    | 'costCenter'
    | 'businessRelationship'
    | 'invoicingDate'
    | 'exchangeRate'
    | 'invoiceLineItems'
    | 'businessEmitter'
    | 'hasXmlFile'
    | 'isComplete'
    | 'businessEmitter'
    | 'outstandingBalance'
    | 'currency'
    | 'foreignTotal'
    | 'total'
    | 'invoiceables'
    | 'voucherType'
    | 'emitterTaxpayerIdentifier'
    | 'emitterTaxpayerName'
    | 'globalStatus'
  >;
}

interface FormData {
  providerContacts: string[];
  paymentTerms: number;
  expiryDate: string;
  costCenter?: CostCenter;
  exchangeRate: number;
}

const getDefaultValues = (invoice) => ({
  paymentTerms:
    invoice?.paymentTerms || invoice?.businessRelationship?.paymentTerms,
  costCenter: invoice?.costCenter,
  expiryDate: invoice?.expiryDate
    ? invoice?.expiryDate
    : getExpiryDate(
        invoice?.invoicingDate,
        invoice?.businessRelationship?.paymentTerms
      ),
  providerContacts: getSupplierContacts(
    invoice?.businessRelationship?.providerContacts
  ),
  exchangeRate: invoice?.exchangeRate,
});

export function InvoiceView({ invoice }: InvoiceViewProps) {
  const intl = useIntl();
  const history = useHistory();
  const { business } = useSettings();
  const [editing, setEditing] = useState(false);
  const { toast, snack } = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState,
    control,
  } = useForm<FormData>({
    defaultValues: getDefaultValues(invoice),
  });
  const [updateInvoiceStatusMutation, { loading: submitting }] = useMutation<
    UpdateInvoiceStatusReturn,
    UpdateInvoiceStatusParams
  >(UPDATE_INVOICES_STATUS_MUTATION, {
    refetchQueries: [INVOICE_QUERY],
    onCompleted: (data) => {
      trackEvent(UPDATE_INVOICE_STATUS, {
        creator: 'Buyer',
        view: 'InvoiceView',
      });
      if (editing) {
        toast.success(
          intl.formatMessage({
            id: 'FZAoOc',
            defaultMessage: 'Factura actualizada',
          }),
          intl.formatMessage(
            {
              id: 'QrmaaW',
              defaultMessage: 'La factura {invoiceId} se ha editado.',
            },
            {
              invoiceId: invoice.id,
            }
          )
        );
      } else {
        snack(
          intl.formatMessage({
            id: 'osldg7',
            defaultMessage: 'Facturas agregadas',
          }),
          {
            label: intl.formatMessage({
              defaultMessage: 'Ir a cuentas por pagar',
              id: 'YQPju/',
            }),
            onClick: () => history.push('/accounts_payable'),
          }
        );
        let status = '';
        switch (data.updateInvoiceStatus.approvalStatus) {
          case InvoiceApprovalStatus.APPROVED:
            status = intl.formatMessage({
              id: '7X1iqf',
              defaultMessage: 'aprobada',
            });
            break;
          case InvoiceApprovalStatus.REJECTED:
            status = intl.formatMessage({
              id: 'WjnVID',
              defaultMessage: 'rechazada',
            });
            break;
          case InvoiceApprovalStatus.PENDING:
            status = intl.formatMessage({
              id: 'WjnVID',
              defaultMessage: 'rechazada',
            });
            break;
        }
        toast.success(
          intl.formatMessage(
            {
              id: '/+IT3n',
              defaultMessage: 'Factura {status}',
            },
            {
              status,
            }
          ),
          intl.formatMessage(
            {
              id: 'grujcq',
              defaultMessage: 'Se ha {status} la factura {invoiceId}',
            },
            {
              invoiceId: invoice.id,
              status,
            }
          )
        );
      }
      setEditing(false);
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          id: 'iXOiEp',
          defaultMessage: 'Error al actualizar factura',
        }),
        e.message
      );
    },
  });

  const handleCancelClick = () => {
    reset(getDefaultValues(invoice));
    setEditing(false);
  };

  const onSubmit = handleSubmit((data: FormData) => {
    if (invoice.id && business?.id) {
      updateInvoiceStatusMutation({
        variables: {
          businessId: business.id,
          invoiceId: invoice.id,
          status: InvoiceApprovalStatus.APPROVED,
          edited:
            invoice.approvalStatus === InvoiceApprovalStatus.APPROVED ||
            undefined,
          expiryDate: data.expiryDate,
          costCenterId: data?.costCenter?.id,
          paymentTerms: +data.paymentTerms,
          exchangeRate: data.exchangeRate,
          providerContacts: data?.providerContacts?.map((email) => ({
            email,
          })),
        },
      });
    }
  });

  if (invoice.businessEmitter && invoice.businessEmitter?.id === business?.id) {
    return (
      <>
        <PageTitle goBackLink>
          {intl.formatMessage(
            {
              id: '5RO01m',
              defaultMessage: 'Factura {invoiceId}',
            },
            {
              invoiceId: invoice.id,
            }
          )}
        </PageTitle>

        <Card>
          <Invoice invoice={invoice} />
        </Card>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        goBackLink
        actions={{
          render: () => (
            <FlexContainer gap="8px">
              {editing ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={handleCancelClick}
                    color="neutral"
                  >
                    {intl.formatMessage({
                      id: 'nZLeaQ',
                      defaultMessage: 'Cancelar',
                    })}
                  </Button>
                  <Button type="submit" disabled={submitting}>
                    {intl.formatMessage({
                      id: 'n4YOKU',
                      defaultMessage: 'Guardar',
                      description: 'Edit invoice action',
                    })}
                  </Button>
                </>
              ) : (
                <InvoiceActions invoice={invoice} onEdit={setEditing} />
              )}
            </FlexContainer>
          ),
        }}
      >
        {intl.formatMessage(
          {
            id: '5RO01m',
            defaultMessage: 'Factura {invoiceId}',
          },
          {
            invoiceId: invoice.id,
          }
        )}
      </PageTitle>

      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          {!invoice?.isComplete && (
            <>
              <IncompleteInvoiceNotification />
              <Spacer />
            </>
          )}
          <Card>
            <Tabs>
              <TabList>
                <Tab>
                  {intl.formatMessage({
                    id: 'yZ36Da',
                    defaultMessage: 'Factura',
                  })}
                </Tab>
                <Tab>
                  {intl.formatMessage({
                    id: 'zYkReX',
                    defaultMessage: 'Historial',
                  })}
                </Tab>
              </TabList>

              <TabPanel>
                <Invoice invoice={invoice} />
              </TabPanel>
              <TabPanel>
                <HistoricalRecord invoiceId={invoice.id} />
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {invoice.codatSyncedAt && (
            <>
              <CodatSyncedAt syncTime={invoice?.codatSyncedAt} />
              <Spacer />
            </>
          )}
          <Card padding="8">
            <EditableInvoiceInformation
              invoice={invoice}
              control={control}
              setValue={setValue}
              register={register}
              formState={formState}
              disabled={
                !editing &&
                invoice.approvalStatus !== InvoiceApprovalStatus.PENDING
              }
            />
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export default InvoiceView;
