import { useQuery } from '@apollo/client';
import TAX_PAYER_INFOS_QUERY, {
  TaxPayerInfosQueryReturn,
  TaxPayerInfosQueryVariables,
} from '@src/graphql/queries/taxpayerInfos';
import useSettings from './useSettings';

const defaultValue = {
  id: undefined,
  taxpayerIdentifier: undefined,
  taxpayerName: undefined,
  hasSatPassword: undefined,
  satSyncStatus: undefined,
  satUpdatedAt: undefined,
};

function useSatBot() {
  const { business } = useSettings();
  const { data } = useQuery<
    TaxPayerInfosQueryReturn,
    TaxPayerInfosQueryVariables
  >(TAX_PAYER_INFOS_QUERY, {
    variables: {
      businessId: business?.id,
    },
    pollInterval: 3000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !business?.id || !business?.hasTaxPayerInfo,
  });
  const primaryTaxPayerInfo = data?.taxPayerInfos?.edges?.[0]?.node;

  return primaryTaxPayerInfo || defaultValue;
}

export default useSatBot;
