import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './ProcessingLoaderPage.styles';
import { Header, Alert } from '@src/components/ui';
import { Spacer, CenteredFlexContainer } from '@src/components/shared/layouts';

import processing from '@src/assets/img/processing_payment.gif';
interface ProcessingLoaderPageProps {
  image?: JSX.Element | string;
  title?: string;
  description?: string;
}

const ProcessingLoaderPage = ({
  image,
  description,
  title,
}: ProcessingLoaderPageProps) => {
  const { formatMessage } = useIntl();
  return (
    <S.ConfirmationContainer>
      <S.Card>
        <CenteredFlexContainer direction="column">
          {image || (
            <S.ProcessingGif src={processing} alt="processing payment" />
          )}
          <Spacer margin="8" />
          <Header as="h3">
            {title ||
              formatMessage({
                defaultMessage: 'Estamos iniciando tu proceso de pago...',
                id: 'ctTK+b',
              })}
          </Header>
          <Spacer margin="8" />
          <div>
            <Alert color="neutral">
              {description ||
                formatMessage({
                  defaultMessage:
                    'El proceso de pago se está iniciando y se verá reflejado en la CLABE de tus proveedores en los próximos minutos.',
                  id: '/LG5+r',
                })}
            </Alert>
          </div>
        </CenteredFlexContainer>
      </S.Card>
    </S.ConfirmationContainer>
  );
};

ProcessingLoaderPage.displayName = 'ProcessingLoaderPage';

export default ProcessingLoaderPage;
