import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as S from './InvoicePaymentResults.styles';
import {
  Table,
  Button,
  TableRow,
  TableHeader,
  TableCell,
  Alert,
  TableHeaderCell,
  Header,
  Card,
  Label,
} from '@src/components/ui';
import Currency from '@src/components/i18n/Currency';
import STPPaymentTimeAlert from '@src/components/shared/alerts/STPPaymentTimeAlert';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import {
  STPAccount,
  HigoPayAccount,
  RequestAccount,
} from '@src/components/shared/AccountInformation';

import { InvoicePaymentType } from '@src/types/enums';

import { ReactComponent as ConfirmPayment } from './img/confirmPayment.svg';
import { BankingInfo, Invoice, PaymentMethod } from '@src/types/models';
import usePageTrack from '@src/hooks/usePageTrack';

type PaidInvoice = Pick<
  Invoice,
  | 'id'
  | 'emitterTaxpayerName'
  | 'businessEmitter'
  | 'receiverCountryTaxPayerInfo'
> & {
  amountCents: number;
  bankingInfo?: BankingInfo;
  paymentMethod: PaymentMethod;
};

interface InvoicePaymentResultsProps {
  invoices: Array<PaidInvoice>;
  onClose?: () => void;
}

function InvoicePaymentResults({
  invoices,
  onClose,
}: InvoicePaymentResultsProps) {
  usePageTrack('payment_results', 'core');
  const intl = useIntl();
  const history = useHistory();
  const totalPaid = invoices.reduce((acc, item) => acc + item.amountCents, 0);

  const handleClose = () => {
    history.push(`/accounts_payable?tabIndex=0`);
    onClose && onClose();
  };

  return (
    <S.ConfirmationContainer>
      <Card padding="8" depth="sm">
        <S.Content>
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <ConfirmPayment />
            <Spacer margin="4" />
            <Header as="h5">
              {intl.formatMessage({
                id: 'Gh3tDv',
                defaultMessage: 'Tu(s) pago(s) han sido enviados',
              })}
            </Header>
          </FlexContainer>
          <Spacer margin="2" />
          <FlexContainer justifyContent="center">
            <FlexContainer flexDirection="column" alignItems="center">
              <Label>
                {intl.formatMessage({
                  defaultMessage: 'Facturas',
                  id: '8NYgKp',
                })}
              </Label>
              <Label strong size="lg">
                {invoices.length}
              </Label>
            </FlexContainer>
            <Spacer margin="8" direction="horizontal" />
            <FlexContainer flexDirection="column" alignItems="center">
              <Label>
                {intl.formatMessage({
                  defaultMessage: 'Monto Total',
                  id: 'paxGZi',
                })}
              </Label>
              <Label strong size="lg">
                <Currency value={totalPaid} />
              </Label>
            </FlexContainer>
          </FlexContainer>
          <Spacer margin="8" />
          <S.NotificationWrapper>
            <STPPaymentTimeAlert />
            <Alert hasBorder>
              {intl.formatMessage({
                defaultMessage:
                  'Hemos enviado una notificación a tus proveedores via email',
                id: 'dhvftP',
              })}
            </Alert>
          </S.NotificationWrapper>
          <Spacer margin="8" />
          <Table className="table-payments">
            <TableHeader>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Folio',
                  id: 'x+TaH9',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Proveedor',
                  id: 'RMvOYP',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Monto pagado',
                  id: 'F3Fk3M',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Beneficiario',
                  id: 'dtkaPZ',
                })}
              </TableHeaderCell>
            </TableHeader>
            <tbody>
              {invoices.map((invoice, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {invoice?.receiverCountryTaxPayerInfo?.folio || '-'}
                  </TableCell>
                  <TableCell>{invoice.businessEmitter?.name}</TableCell>
                  <TableCell>
                    <Currency value={invoice.amountCents} />
                  </TableCell>
                  <TableCell>
                    {invoice.paymentMethod.paymentType ===
                    InvoicePaymentType.REQUEST_BANKING_INFO ? (
                      <RequestAccount />
                    ) : null}
                    {invoice.paymentMethod.paymentType ===
                    InvoicePaymentType.HIGO_PAYMENT ? (
                      <HigoPayAccount name={invoice.emitterTaxpayerName} />
                    ) : null}
                    {invoice.paymentMethod.paymentType ===
                      InvoicePaymentType.STP_PAYMENT && invoice.bankingInfo ? (
                      <STPAccount
                        bankName={invoice.bankingInfo.bankName}
                        clabe={invoice.bankingInfo.clabe}
                        logoUrl={invoice.bankingInfo?.bankLogoUrl}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <Spacer margin="8" />
          <div>
            <Button onClick={handleClose}>
              {intl.formatMessage({
                defaultMessage: 'Ir a cuentas por pagar',
                id: 'YQPju/',
              })}
            </Button>
          </div>
        </S.Content>
      </Card>
    </S.ConfirmationContainer>
  );
}

InvoicePaymentResults.displayName = 'InvoicePaymentResults';

export default InvoicePaymentResults;
