import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import * as S from './PaymentWithoutInvoice.styles';
import SupplierRelationshipsSelect from './components/SupplierRelationshipsSelect';
import PaymentMethod from './components/PaymentMethod';
import {
  Card,
  Button,
  Table,
  TableCell,
  TableHeaderCell,
  ModalHeader,
  TextField,
} from '@src/components/ui';
import useModal from '@src/hooks/useModal';
import useToast from '@src/hooks/useToast';
import useSettings from '@src/hooks/useSettings';
import { trackEvent } from '@src/lib/analytics';
import Modal from '@src/components/shared/Modal';
import { Plus } from '@src/components/ui/Icon/outline';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import { FlexContainer } from '@src/components/shared/layouts';
import { Container, Spacer } from '@src/components/shared/layouts';
import STPPaymentTimeAlert from '@src/components/shared/alerts/STPPaymentTimeAlert';
import ProcessingLoaderPage from '@src/components/shared/ProcessingLoaderPage';
import PaymentConceptField from '@src/components/shared/react-hook-fields/PaymentConceptField';
import InvoicePaymentFormHeader from '@src/components/shared/form/InvoicePaymentForm/components/InvoicePaymentFormHeader';
import InsufficientFundsNotification from '@src/components/shared/alerts/InsufficientFundsNotification';
import SupplierBankingInformation from '@src/components/shared/modals/SupplierBankingInformationModal';
import userIsAdmin from '@src/utils/roles/userIsAdmin';
import {
  CreatePaymentsMutationReturn,
  CreatePaymentsMutationVariables,
  CREATE_PAYMENTS,
} from '@src/graphql/mutations';
import {
  CREATE_PAYMENTS as CREATE_PAYMENTS_EVENT,
  CREATE_PAYMENTS_ERROR as CREATE_PAYMENTS_ERROR_EVENT,
} from '@src/constants/events';
import { PaymentMethod as PaymentMethodType } from '@src/types/models';
import { stringToCents } from '@src/utils/currency';
import {
  InvoicePaymentType,
  BankingAccountAccountType,
} from '@src/types/enums';
import PaymentResults from './components/PaymentResults';
import SupplierContactsSelect from './components/SupplierContactsSelect';

type PaymentField = {
  amount: number;
  businessRelationshipId: string;
  paymentConcept: string;
  emails: string[];
  recipient: PaymentMethodType;
};

export interface FormData {
  payments: PaymentField[];
}

const PaymentWithoutInvoice = () => {
  const intl = useIntl();
  const history = useHistory();
  const { toast } = useToast();
  const { business, currentUser } = useSettings();
  const isAdmin = userIsAdmin(currentUser);
  const {
    open: openProcessingPayment,
    close: closeProcessingPayment,
  } = useModal(ProcessingLoaderPage);
  const { open: openResults, close: closeResults } = useModal(
    PaymentResults,
    50
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues: { payments: [{}] },
    mode: 'onChange',
  });

  const { fields, remove, append } = useFieldArray({
    control,
    keyName: 'key',
    name: 'payments',
  });

  const [createPayments, { loading: creatingPayment }] = useMutation<
    CreatePaymentsMutationReturn,
    CreatePaymentsMutationVariables
  >(CREATE_PAYMENTS, {
    onCompleted: (data) => {
      trackEvent(CREATE_PAYMENTS_EVENT);
      setTimeout(() => {
        const successResults = data.createPayments.map((payment) => {
          const isHigoPay =
            payment.receiverAccount?.accountType ===
              BankingAccountAccountType.INTERNAL &&
            payment.senderAccount?.accountType ===
              BankingAccountAccountType.INTERNAL;
          return {
            receiverAccount: payment.receiverAccount,
            amountCents: payment.amountCents || 0,
            bankingInfo: {
              clabe: payment.receiverAccount?.clabe || '',
            },
            paymentMethod: {
              paymentType: isHigoPay
                ? (InvoicePaymentType.HIGO_PAYMENT as InvoicePaymentType.HIGO_PAYMENT)
                : (InvoicePaymentType.STP_PAYMENT as InvoicePaymentType.STP_PAYMENT),
            },
          };
        });

        closeProcessingPayment();
        openResults({ onClose: closeResults, payments: successResults });
      }, 5000);
    },
    onError: () => {
      setTimeout(() => {
        closeProcessingPayment();
        trackEvent(CREATE_PAYMENTS_ERROR_EVENT);
        toast.error(
          intl.formatMessage({
            id: 'Q6fgkp',
            defaultMessage: 'Hubo un problema al enviar los pagos.',
          })
        );
      }, 5000);
    },
  });

  const onSubmit = handleSubmit((data) => {
    openProcessingPayment({});
    const payments = data.payments.map((payment) => ({
      businessRelationshipId: payment.businessRelationshipId,
      amountCents: stringToCents(payment.amount),
      paymentConcept: payment.paymentConcept,
      higoPay:
        payment.recipient.paymentType === InvoicePaymentType.HIGO_PAYMENT,
      emails: payment.emails,
    }));

    if (business) {
      createPayments({
        variables: {
          businessId: business?.id,
          payments,
        },
      });
    }
  });

  if (!isAdmin) {
    history.replace('/p/403');
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader
        onClose={history.goBack}
        title={intl.formatMessage({
          id: 'PXhwAb',
          defaultMessage: 'Nuevo Anticipo',
        })}
        actions={
          <InvoicePaymentFormHeader
            wallet="balance"
            control={control}
            fieldNameToSum="payments"
            loading={creatingPayment}
            handleSubmit={handleSubmit}
          />
        }
        sticky
      />
      <Container maxWidth={false}>
        <Spacer margin="8" />
        <S.NotificationWrapper>
          <STPPaymentTimeAlert />
          <InsufficientFundsNotification
            fieldNameToSum="payments"
            control={control}
          />
        </S.NotificationWrapper>
        <Card padding="8">
          <Table columnsWidth={[180, 180, 150, 180, 200, 50]}>
            <SelectableTableHeader cancelable>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'RMvOYP',
                  defaultMessage: 'Proveedor',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'dtkaPZ',
                  defaultMessage: 'Beneficiario',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: '7QMfwz',
                  defaultMessage: 'Notificar al proveedor',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'NGmqCa',
                  defaultMessage: 'Concepto de Pago',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  id: 'Axixya',
                  defaultMessage: 'Monto',
                })}
              </TableHeaderCell>
            </SelectableTableHeader>
            <tbody>
              {fields.map((payment, index) => {
                return (
                  <SelectableTableRow
                    cancelable
                    key={index}
                    onCancel={() => {
                      if (fields.length === 1) history.goBack();
                      remove(index);
                    }}
                  >
                    <TableCell>
                      <Controller
                        control={control}
                        name={`payments.${index}.businessRelationshipId`}
                        rules={{
                          required: intl.formatMessage({
                            id: '7Vvfe3',
                            defaultMessage: 'Campo requerido',
                          }),
                        }}
                        render={({
                          field,
                          fieldState: { error },
                          formState: { isSubmitting },
                        }) => (
                          <SupplierRelationshipsSelect
                            isDisabled={isSubmitting}
                            error={!!error?.message}
                            {...field}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        control={control}
                        name={`payments.${index}.recipient`}
                        rules={{
                          required: intl.formatMessage({
                            id: '7Vvfe3',
                            defaultMessage: 'Campo requerido',
                          }),
                        }}
                        render={({
                          field,
                          fieldState: { error },
                          formState: { isSubmitting },
                        }) => {
                          return (
                            <PaymentMethod
                              fullWidth
                              watchName={`payments.${index}.businessRelationshipId`}
                              control={control}
                              disabled={isSubmitting}
                              error={!!error?.message}
                              {...field}
                            />
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Controller
                        control={control}
                        name={`payments.${index}.emails`}
                        rules={{
                          required: intl.formatMessage({
                            id: '7Vvfe3',
                            defaultMessage: 'Campo requerido',
                          }),
                        }}
                        render={({
                          field,
                          fieldState: { error },
                          formState: { isSubmitting },
                        }) => {
                          return (
                            <SupplierContactsSelect
                              watchName={`payments.${index}.businessRelationshipId`}
                              control={control}
                              error={error}
                              disabled={isSubmitting}
                              {...field}
                            />
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <PaymentConceptField
                        fullWidth
                        id={`payments.${index}.paymentConcept`}
                        name={`payments.${index}.paymentConcept`}
                        hiddenLabel="Payment concept"
                        placeholder={intl.formatMessage({
                          id: '1BZqPx',
                          defaultMessage: 'Pago de anticipo',
                        })}
                        error={
                          !!errors?.payments?.[index]?.paymentConcept?.message
                        }
                        helperText={
                          errors?.payments?.[index]?.paymentConcept?.message
                        }
                        register={register}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        {...register(`payments.${index}.amount`, {
                          required: intl.formatMessage({
                            id: '+Yg5pv',
                            defaultMessage: 'Campo Requerido',
                          }),
                          validate: {
                            positive: (v) =>
                              v > 0 ||
                              intl.formatMessage({
                                id: '1CA8ow',
                                defaultMessage: 'Debe ser mayor a 0',
                              }),
                          },
                          setValueAs: (value) => parseCurrency(`${value}`),
                        })}
                        fullWidth
                        id={`payments.${index}.amount`}
                        type="currency"
                        error={!!errors?.payments?.[index]?.amount?.message}
                        helperText={errors?.payments?.[index]?.amount?.message}
                      />
                    </TableCell>
                  </SelectableTableRow>
                );
              })}
            </tbody>
          </Table>
          <FlexContainer justifyContent="center">
            <Button
              onClick={() => append({})}
              startIcon={<Plus />}
              variant="transparent"
            >
              {intl.formatMessage({
                id: 'hJOd06',
                defaultMessage: 'Agregar fila',
              })}
            </Button>
          </FlexContainer>
        </Card>
      </Container>
      <Modal component={SupplierBankingInformation} />
      <Modal type="fullscreen" component={ProcessingLoaderPage} />
      <Modal type="fullscreen" component={PaymentResults} zIndex={50} />
    </form>
  );
};

export default PaymentWithoutInvoice;
