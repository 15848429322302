import { useMemo } from 'react';

import { hasBusinessFeature } from '@src/utils/features';
import useSettings from '@src/hooks/useSettings';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';

import { FeatureName, InvoicePaymentType } from '@src/types/enums';

const useUserPaymentMethods = (): Array<InvoicePaymentType> => {
  const { business } = useSettings();
  const isRequestCLABEEnabled = useFeatureFlag(FeatureFlag.REQUEST_CLABE);
  const paymentMethods = useMemo(() => {
    const methods = [InvoicePaymentType.STP_PAYMENT];
    const hasHigoPayment = hasBusinessFeature(
      [FeatureName.HIGO_PAYMENTS],
      business
    );
    if (hasHigoPayment) {
      methods.push(InvoicePaymentType.HIGO_PAYMENT);
    }
    if (isRequestCLABEEnabled) {
      methods.push(InvoicePaymentType.REQUEST_BANKING_INFO);
    }

    return methods;
  }, [business]);

  return paymentMethods;
};
export default useUserPaymentMethods;
