import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import App from './App';
import PublicApp from './PublicApp';

import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/high-res.css';
import 'react-datepicker/dist/react-datepicker.css';

function AppContainer({ history }) {
  return (
    <Router history={history}>
      <Helmet defaultTitle="Higo" titleTemplate="Higo | %s" />
      <Switch>
        <Route path="/p">
          <PublicApp />
        </Route>

        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppContainer;
