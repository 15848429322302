import styled from 'styled-components';
import { FlexContainer } from '@src/components/shared/layouts';

export const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.neutral[500]};
  line-height: ${({ theme }) => theme.fontSize.sm};
  margin: 0;
`;

export const Container = styled(FlexContainer)`
  min-width: 120px;
`;
