import React from 'react';
import { useIntl } from 'react-intl';

import { Header, Text, Card, Icon } from '@src/components/ui';
import LinkButton from '@src/components/shared/buttons/LinkButton';
import Clabe from '@src/components/shared/Clabe';
import { FlexContainer, Grid } from '@src/components/shared/layouts';
import Currency from '@src/components/i18n/Currency';
import { CenteredFlexContainer, Spacer } from '@src/components/shared/layouts';
import DataContent from '../DataContent';
import { Verified } from '@src/components/ui/Icon/solid';

type ValidatedPaymentProps = {
  totalAmount: number;
  taxPayerName?: string;
  taxpayerIdentifier?: string;
  email?: string;
  clabe?: string;
  bankName?: string;
  bankLogoUrl?: string;
};

export const ValidatedPayment = ({
  totalAmount,
  bankName,
  taxPayerName,
  taxpayerIdentifier,
  email,
  bankLogoUrl,
  clabe = '',
}: ValidatedPaymentProps) => {
  const intl = useIntl();
  return (
    <Card padding="8" depth="sm">
      <CenteredFlexContainer direction="column">
        <Icon
          variant="solid"
          name="Verified"
          color="secondary"
          colorStep="400"
          size={60}
        />
        <Spacer direction="vertical" margin="6" />
        <Header as="h5">
          {intl.formatMessage({
            defaultMessage: '¡Tu pago ha sido enviado!',
            id: 'AO4Gyl',
          })}
        </Header>
        <Spacer direction="vertical" margin="2" />
        <Text color="neutral" colorStep="600" light textAlign="center">
          {intl.formatMessage(
            {
              defaultMessage:
                'Hola {client}. Has recibido tu pago exitosamente. A continuación podrás encontrar los detalles básicos de la transacción:',
              id: 'EuQNy5',
            },
            {
              client: taxPayerName,
            }
          )}
        </Text>
        <Spacer direction="vertical" margin="12" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="User"
              label={intl.formatMessage({
                defaultMessage: 'Cliente',
                id: 'DshuY+',
              })}
              value={taxPayerName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="Money"
              label={intl.formatMessage({
                defaultMessage: 'Monto',
                id: 'Axixya',
              })}
              value={<Currency value={totalAmount} />}
            />
          </Grid>
        </Grid>
        <Spacer direction="vertical" margin="8" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <DataContent
              iconName="Send"
              label={intl.formatMessage({
                defaultMessage: 'CLABE destino',
                id: 'ZDHRsL',
              })}
              value={
                <FlexContainer alignItems="center">
                  <Clabe account={clabe} as="strong" />
                  <Spacer direction="horizontal" />
                  <Verified color="#42BBFF" size={14} />
                </FlexContainer>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DataContent
              imgSrc={bankLogoUrl}
              value={bankName}
              label={intl.formatMessage({
                defaultMessage: 'Institucion Bancaria',
                id: 'F8in/6',
              })}
            />
          </Grid>
        </Grid>
        <Spacer direction="vertical" margin="16" />
        <LinkButton
          target="_blank"
          href={`/p/sign-up?email=${email}&rfc=${taxpayerIdentifier}`}
          block
          variant="solid"
          color="primary"
        >
          {intl.formatMessage({
            defaultMessage: 'Prueba Higo',
            id: 'zj0FQO',
          })}
        </LinkButton>
      </CenteredFlexContainer>
    </Card>
  );
};

export default ValidatedPayment;
