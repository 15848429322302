import { gql } from '@apollo/client';
import USER_PROFILE_ATTRIBUTES, {
  UserProfileAttributes,
} from '@src/graphql/fragments/userProfileAttributes';

export const CURRENT_USER_QUERY = gql`
  query AppData {
    currentUser {
      ...UserProfile
    }
  }
  ${USER_PROFILE_ATTRIBUTES}
`;

export type CurrentUserReturn = {
  currentUser: UserProfileAttributes;
};

export default CURRENT_USER_QUERY;
