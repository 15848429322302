import React from 'react';

import * as outlined from './outline';
import * as solid from './solid';
import { StyledIcon } from './Icon.styles';

import type { IconProps } from './types';

function Icon({
  variant = 'outlined',
  name,
  color,
  colorStep,
  ...otherProps
}: IconProps) {
  const FeatherIcon = variant === 'solid' ? solid[name] : outlined[name];
  return (
    <StyledIcon
      {...otherProps}
      as={FeatherIcon}
      $color={color}
      $colorStep={colorStep}
    />
  );
}

export default Icon;
