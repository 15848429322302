import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const InvoiceToPay: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
        stroke="none"
        strokeWidth="0"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.878678 0.878678C1.44129 0.31607 2.20435 0 2.99999 0H11C11.2652 0 11.5195 0.105357 11.7071 0.292893L17.7071 6.29288C17.8946 6.48041 18 6.73477 18 6.99998V18.9999C18 19.7956 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7956 21.9999 15 21.9999H2.99999C2.20434 21.9999 1.44129 21.6839 0.878679 21.1213C0.316072 20.5587 0 19.7956 0 18.9999V13.0002L9.58552 13.0002L7.29287 15.2929C6.90235 15.6834 6.90235 16.3166 7.29287 16.7071C7.68339 17.0976 8.31656 17.0976 8.70708 16.7071L12.4139 13.0002H12.5V12.9142L12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L12.5 11.0858V11.0002H12.4144L8.70708 7.29289C8.31656 6.90237 7.6834 6.90237 7.29287 7.29289C6.90235 7.68342 6.90235 8.31658 7.29287 8.70711L9.58601 11.0002L0 11.0002V2.99999C0 2.20435 0.31607 1.44129 0.878678 0.878678Z"
        />
      </svg>
    );
  }
);

InvoiceToPay.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InvoiceToPay.displayName = 'InvoiceToPay';

export default InvoiceToPay;
