import { gql } from '@apollo/client';

export const MANUAL_SYNC_BILLS_MUTATION = gql`
  mutation ManualSyncBills($codatId: ID!, $businessId: String!) {
    manualSyncBills(codatId: $codatId, businessId: $businessId) {
      id
    }
  }
`;

export type ManualSyncBillsVariables = {
  codatId: string;
  businessId: string;
};

export type ManualSyncBillsReturn = {
  id: string;
};

export default MANUAL_SYNC_BILLS_MUTATION;
