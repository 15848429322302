import React from 'react';
import { useIntl } from 'react-intl';
import { useApolloClient } from '@apollo/client';

import * as S from './SignInLink.styles';
import { remove as logOut } from '@src/lib/auth';
import { resetUser } from '@src/lib/errorReporter';
import { useHistory } from 'react-router-dom';

function SignInLink() {
  const intl = useIntl();
  const history = useHistory();
  const client = useApolloClient();

  const handleLogin = async () => {
    await client.clearStore();
    logOut();
    resetUser();
    history.replace('/login');
  };

  return (
    <S.SignInContainer>
      <p>
        {intl.formatMessage({
          defaultMessage: '¿Ya tienes una cuenta?',
          id: '3esqOV',
        })}{' '}
        <S.SignInLink onClick={handleLogin}>
          {intl.formatMessage({
            defaultMessage: 'Iniciar sesión',
            id: 'Mwm1HV',
          })}
        </S.SignInLink>
      </p>
    </S.SignInContainer>
  );
}

export default SignInLink;
