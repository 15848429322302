import React from 'react';
import { useIntl } from 'react-intl';

import Clabe from '@src/components/shared/Clabe';
import * as S from './BankingInfo.styles';
import Divider from '@src/components/ui/Divider';
import Tag from '@src/components/ui/Tag';
import { Verified } from '@src/components/ui/Icon/solid';

import { ReactComponent as DefaultBank } from '@src/assets/img/default-bank.svg';
interface BankingInfoProps {
  id?: string;
  bankName?: string;
  clabe?: string;
  compact?: boolean;
  verified?: boolean;
  selected?: boolean;
  hasBorder?: boolean;
  showStatus?: boolean;
  onClick?: () => void;
}

const StatusBadge = ({ verified }) => {
  const intl = useIntl();
  return (
    <S.Status>
      {verified ? (
        <Verified color="#42BBFF" size={16} />
      ) : (
        <Tag
          label={intl.formatMessage({
            defaultMessage: 'En revisión',
            id: '71rxWR',
          })}
          color="gray"
          compact
        />
      )}
    </S.Status>
  );
};

const BankingInfo = ({
  id,
  bankName,
  clabe,
  compact,
  verified,
  selected,
  hasBorder,
  showStatus,
  onClick,
}: BankingInfoProps) => {
  const intl = useIntl();
  return (
    <S.Card
      key={id}
      selected={selected}
      hasBorder={hasBorder}
      onClick={onClick}
      compact={compact}
    >
      <S.Container compact={compact}>
        <S.BankLogo>
          <DefaultBank />
        </S.BankLogo>
        <Divider direction="row" margin="3" />
        {!bankName || !clabe ? (
          <S.Empty>
            {intl.formatMessage({
              defaultMessage: 'Agregar CLABE',
              id: 'LDKRfg',
            })}
          </S.Empty>
        ) : (
          <>
            <S.BankingInfo>
              <S.Clabe compact={compact}>
                <Clabe account={clabe} as="p" />
                {showStatus && <StatusBadge verified={verified} />}
              </S.Clabe>
              <S.BankName>{bankName}</S.BankName>
            </S.BankingInfo>
          </>
        )}
      </S.Container>
    </S.Card>
  );
};

export default BankingInfo;
