import styled from 'styled-components';
import TableUI from '@src/components/ui/Table';

export const TabPanel = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;

export const TableTitle = styled.label`
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const Table = styled(TableUI)`
  table-layout: fixed;
  td {
    border: none;
    padding: 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
