import styled from 'styled-components';

export const StyledSwitcher = styled.div<{ count?: number }>`
  background-color: ${({ theme }) => theme.colors.neutral[200]};
  border-radius: ${({ theme }) => theme.spacing[2]};
  display: inline-grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  position: relative;
  border: ${({ theme }) =>
    `${theme.spacing[1]} solid ${theme.colors.neutral[200]}`};
`;

export const Highlight = styled.div<{
  count?: number;
  index?: number;
  appear?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.neutral[50]};
  position: absolute;
  left: ${({ index = 0, count = 1 }) => index * (100 / count)}%;
  height: 100%;
  z-index: 1;
  border-radius: ${({ theme }) => theme.spacing[2]};
  border: ${({ theme }) =>
    `${theme.spacing.px} solid ${theme.colors.neutral[300]}`};
  width: ${({ count = 1 }) => 100 / count}%;
  visibility: ${({ appear }) => (appear ? 'visible' : 'hidden')};
  transition: all 0.15s ease-out;
`;
