import { Business } from '@src/types/models';

const WEWORK_INTEGRATION = 'wework';
const LISTO_INTEGRATION = 'listo';

export function hasWeWorkIntegration(business: Business) {
  const { integrations = [] } = business;
  return integrations.some(
    (integration) => integration.name === WEWORK_INTEGRATION
  );
}

export function hasListoIntegration(business: Business) {
  const { integrations = [] } = business;
  return integrations.some(
    (integration) => integration.name === LISTO_INTEGRATION
  );
}
