import { FactoringOffer } from '@src/types/models';

const useFactoringCalculations = (selectedOffers: FactoringOffer[]) => {
  const selectedOfferIds = selectedOffers.map((so) => so.id);

  const result = selectedOffers.reduce(
    (obj, offer) => {
      const {
        invoice: { total },
        discountAmountCents,
        cashableAmountCents,
        reserveAmountCents,
      } = offer;

      return {
        ...obj,
        totalInvoicesAmount: obj.totalInvoicesAmount + (total || 0),
        cashableAmountCents: obj.cashableAmountCents + cashableAmountCents,
        discountAmountCents: obj.discountAmountCents + discountAmountCents,
        reserveAmountCents: obj.reserveAmountCents + reserveAmountCents,
      };
    },
    {
      totalInvoicesAmount: 0,
      cashableAmountCents: 0,
      discountAmountCents: 0,
      reserveAmountCents: 0,
    }
  );

  return {
    cashableAmountCents: result.cashableAmountCents,
    discountAmountCents: result.discountAmountCents,
    reserveAmountCents: result.reserveAmountCents,
    totalInvoicesAmount: result.totalInvoicesAmount,
    subtotalAmount: result.totalInvoicesAmount - result.discountAmountCents,
    cashableAmountCentsWithoutDiscount:
      result.cashableAmountCents + result.discountAmountCents,
    selectedOfferIds,
  };
};

export default useFactoringCalculations;
