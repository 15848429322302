import { gql } from '@apollo/client';

export const CREATE_PASSWORD_RESET_MUTATION = gql`
  mutation CreatePasswordReset($email: String!) {
    createPasswordReset(email: $email) {
      id
    }
  }
`;

export default CREATE_PASSWORD_RESET_MUTATION;
