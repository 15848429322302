import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    height: 100vh;
    flex-direction: column-reverse;
  }
`;

// Calculte width without navbar size.
export const RightWrapper = styled.div`
  width: calc(100% - ${({ theme }) => theme.spacing[20]});
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  padding-top: ${({ theme }) => theme.spacing[4]};
  padding-left: ${({ theme }) => theme.spacing[8]};
  padding-right: ${({ theme }) => theme.spacing[8]};
`;
