import { useMutation } from '@apollo/client';

import { APP_DATA_QUERY_NAME } from '@src/graphql/queries';
import { USE_BUSINESS_USER_VALIDATION_TOKEN } from '@src/graphql/mutations';

import type { User } from '@src/types/models';

export type BusinessUserValidationTokenInput = {
  token: string;
};

export type BusinessUserValidationTokenResponse = {
  useBusinessUserValidationToken: User;
};

function useBusinessUserValidationToken() {
  const [mutate, { data, loading, error }] = useMutation<
    BusinessUserValidationTokenResponse,
    BusinessUserValidationTokenInput
  >(USE_BUSINESS_USER_VALIDATION_TOKEN, {
    refetchQueries: [APP_DATA_QUERY_NAME],
    awaitRefetchQueries: true,
  });

  return {
    mutate,
    data,
    loading,
    error,
  };
}

export default useBusinessUserValidationToken;
