import React, { useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';

import FINANCING_REQUESTS_QUERY, {
  FinancingRequestsReturn,
  FinancingRequestsVariables,
} from '@src/graphql/queries/financingRequests';

import * as S from './FinancingRequestsSelect.styles';
import TextField from '@src/components/ui/TextField';
import useSettings from '@src/hooks/useSettings';
import Currency from '@src/components/i18n/Currency';
import useCreditAccount from '@src/hooks/useCreditAccount';

const FinancingRequestsSelect = (props) => {
  const { defaultValue, onChange } = props;

  const { business } = useSettings();
  const intl = useIntl();
  const [creditAccount] = useCreditAccount();
  const { loading, data } = useQuery<
    FinancingRequestsReturn,
    FinancingRequestsVariables
  >(FINANCING_REQUESTS_QUERY, {
    variables: {
      businessId: business?.id,
      creditAccountId: creditAccount?.id,
      repaid: false,
    },
    skip: !creditAccount?.id || !business?.id,
    fetchPolicy: 'cache-and-network',
  });

  const options = useMemo(
    () =>
      data?.financingRequests.edges?.map((edge) => ({
        target: {
          value: edge.node,
        },
        ...edge.node,
      })),
    [data]
  );

  const formatOptionLabel = useCallback(({ owedAmountCents, id, overdue }) => {
    return (
      <S.Option>
        <span>{`No. ${id} `}</span>
        <strong>
          <Currency value={owedAmountCents} />
        </strong>
        {overdue && (
          <S.Tag
            color="danger"
            compact
            label={intl.formatMessage({
              defaultMessage: 'Vencido',
              id: 'mTJVjt',
            })}
          />
        )}
      </S.Option>
    );
  }, []);

  useEffect(() => {
    if (defaultValue) {
      const defaultValueOption = {
        target: {
          value: defaultValue,
        },
        ...defaultValue,
      };

      //Trigger on change to set option and show detail of payment
      onChange(defaultValueOption);
    }
  }, [defaultValue]);

  return (
    <TextField
      type="select"
      options={options}
      isLoading={loading}
      getOptionValue={(option) => option.id}
      formatOptionLabel={formatOptionLabel}
      {...props}
    />
  );
};

export default FinancingRequestsSelect;
