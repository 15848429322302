import React from 'react';
import { useWatch, Control } from 'react-hook-form';

import { Currency } from '@src/components/i18n/Currency';
import { stringToCents } from '@src/utils/currency';
import { Text } from '@src/components/ui';

interface OutstandingAmountContainerProps {
  control: Control<any>;
  totalCents: number;
  name: string;
}

interface OutstandingAmountProps {
  totalCents: number;
  amountCents: number;
}

export function OutstandingAmount({
  totalCents,
  amountCents,
}: OutstandingAmountProps) {
  if (amountCents > totalCents) {
    return (
      <Text color="danger" colorStep="500">
        <Currency value={0} />
      </Text>
    );
  }

  return <Currency value={totalCents - amountCents} />;
}

function OutstandingAmountContainer({
  control,
  totalCents,
  name,
}: OutstandingAmountContainerProps) {
  const amountToPay = useWatch({
    control,
    name,
  });

  return (
    <OutstandingAmount
      totalCents={totalCents}
      amountCents={stringToCents(amountToPay || 0)}
    />
  );
}

export default OutstandingAmountContainer;
