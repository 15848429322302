import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './DepositModal.styles';
import useWallet from '@src/hooks/useWallet';
import useSettings from '@src/hooks/useSettings';
import usePageTrack from '@src/hooks/usePageTrack';
import { Header, Label } from '@src/components/ui';
import { Grid, Spacer } from '@src/components/shared/layouts';

export function DepositModal() {
  usePageTrack('deposit_modal', 'core');
  const { stpClabe } = useWallet();
  const { business } = useSettings();
  const { formatMessage } = useIntl();

  if (!business || !stpClabe) {
    return null;
  }

  return (
    <S.DepositModalContainer>
      <Header as="h5">
        {formatMessage({
          defaultMessage: 'Información de depósito',
          id: 'aW0WmW',
        })}
      </Header>
      <Spacer margin="5" />
      <Grid container>
        <Grid item xs={6}>
          <Label size="lg" strong>
            {formatMessage({
              defaultMessage: 'CLABE:',
              id: '2X1Nna',
            })}
          </Label>
        </Grid>
        <Grid item xs={6}>
          <p>{stpClabe}</p>
        </Grid>
        <Grid item xs={6}>
          <Label size="lg" strong>
            {formatMessage({
              defaultMessage: 'Banco:',
              id: 'W9QYB9',
            })}
          </Label>
        </Grid>
        <Grid item xs={6}>
          <p>STP</p>
        </Grid>
        <Grid item xs={6}>
          <Label size="lg" strong>
            {formatMessage({
              defaultMessage: 'Nombre:',
              id: 'biswPx',
            })}
          </Label>
        </Grid>
        <Grid item xs={6}>
          <p>{business?.taxPayerInfos?.[0]?.taxpayerName}</p>
        </Grid>
      </Grid>
      <Spacer margin="6" />
      <S.TransferInfo>
        {formatMessage({
          defaultMessage:
            'Realiza la tranferencia por el valor que desees que sea adicionado a tu saldo disponible. Para realizar la recarga de tu saldo utiliza los datos de referencia mencionados arriba.',
          id: 'hMo0Ns',
        })}
      </S.TransferInfo>
      <S.TransferInfo>
        {formatMessage({
          defaultMessage:
            'Contáctanos para cualquier duda o problema que tengas.',
          id: '04pwGR',
        })}
      </S.TransferInfo>
    </S.DepositModalContainer>
  );
}

DepositModal.displayName = 'DepositModal';

export default DepositModal;
