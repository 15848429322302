import React from 'react';
import { useIntl } from 'react-intl';
import { Tabs, TabPanel } from 'react-tabs';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import * as S from './SupplierView.styles';
import { Tab, TabList } from '@src/components/shared/Tabs';
import messages from './messages';
import SupplierInformation from './components/SupplierInformation';
import SupplierContactsTable from './components/SupplierContactsTable';
import SupplierInvoicesTable from './components/SupplierInvoicesTable';
import SupplierTransactionsTable from './components/SupplierTransactionsTable';
import SupplierCreditNotesTable from './components/SupplierCreditNotesTable';
import { Alert, Card } from '@src/components/ui';
import Page from '@src/components/shared/Page';
import Loader from '@src/components/shared/Loader';
import Feature from '@src/components/shared/Feature';
import Modal from '@src/components/shared/Modal';
import Supplier from '@src/components/pages/Contacts/components/Supplier';
import CreateSupplierContactModal from '@src/components/pages/SupplierView/components/CreateSupplierContactModal';
import CreateSupplierFromInvoiceModal from '@src/components/pages/SupplierView/components/UpdateSupplierModal/UpdateSupplierModal';
import SupplierBankingInformationModal from '@src/components/shared/modals/SupplierBankingInformationModal';
import useQueryParams from '@src/hooks/useQueryParams';
import useSettings from '@src/hooks/useSettings';
import { FeatureName } from '@src/types/enums';
import SUPPLIER_RELATIONSHIP_QUERY from '@src/graphql/queries/supplierRelationship';
import GoBackButton from '@src/components/shared/GoBackButton';

export function SupplierView() {
  const intl = useIntl();
  const { suppliers_id: supplierHashId } = useParams<{
    suppliers_id: string;
  }>();
  const [query, setQuery] = useQueryParams();
  const { currentUser, business } = useSettings();
  const { data, loading, error } = useQuery(SUPPLIER_RELATIONSHIP_QUERY, {
    variables: {
      businessId: business?.id,
      supplierHashId: supplierHashId,
    },
    skip: !business?.id || !supplierHashId,
  });
  const supplierRelationship = data?.supplierRelationship;
  const selectedTabIndex = parseInt(query?.tabIndex) || 0;
  const handleTabSelect = (index: number) => {
    setQuery({
      invoicingDate: undefined,
      expiryDate: undefined,
      globalStatus: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
      status: undefined,
      tabIndex: index,
    });
  };

  if (loading) {
    return <Loader fullWidth />;
  }

  if (!loading && error) {
    return (
      <Alert color="danger">
        {intl.formatMessage(messages.error)} {error.message}
      </Alert>
    );
  }

  if (!supplierRelationship) {
    return null;
  }

  const { supplier, status, providerContacts } = supplierRelationship;

  if (!currentUser) {
    return null;
  }

  return (
    <Page title={supplier.name}>
      <GoBackButton />
      <Supplier businessId={business?.id} datum={supplierRelationship} />

      <Card>
        <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelect}>
          <TabList>
            <Tab>
              {intl.formatMessage({
                defaultMessage: 'Información',
                id: 'R760dc',
              })}
            </Tab>
            <Tab>
              {intl.formatMessage({
                defaultMessage: 'Contactos',
                id: 'nBwDGK',
              })}
            </Tab>
            <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
              <Tab>
                {intl.formatMessage({
                  defaultMessage: 'Facturas',
                  id: '8NYgKp',
                })}
              </Tab>
            </Feature>
            <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
              <Tab>
                {intl.formatMessage({
                  defaultMessage: 'Notas de crédito',
                  id: 'mdwqAr',
                })}
              </Tab>
            </Feature>
            <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
              <Tab>
                {intl.formatMessage({
                  defaultMessage: 'Transacciones',
                  id: 'Aqopvj',
                })}
              </Tab>
            </Feature>
          </TabList>
          <TabPanel>
            <S.TabPanel>
              <SupplierInformation
                supplier={supplier}
                bankingInfo={supplierRelationship.bankingInfo}
                paymentTerms={supplierRelationship.paymentTerms}
                status={status}
                providerContact={supplierRelationship.providerContact}
                supplierRelationship={supplierRelationship}
              />
            </S.TabPanel>
          </TabPanel>
          <TabPanel>
            <S.TabPanel>
              <SupplierContactsTable
                contacts={providerContacts || []}
                businessRelationshipId={supplierRelationship.id}
                businessEmitterName={supplier.name}
              />
            </S.TabPanel>
          </TabPanel>
          <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
            <TabPanel>
              <S.TabPanel>
                <SupplierInvoicesTable
                  businessEmitterId={supplier.businessId}
                  businessEmitterName={supplier.name}
                />
              </S.TabPanel>
            </TabPanel>
          </Feature>
          <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
            <TabPanel>
              <S.TabPanel>
                <SupplierCreditNotesTable
                  businessEmitterId={supplier.businessId}
                  businessEmitterName={supplier.name}
                />
              </S.TabPanel>
            </TabPanel>
          </Feature>
          <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
            <TabPanel>
              <S.TabPanel>
                <SupplierTransactionsTable
                  supplierId={supplier.businessId}
                  businessEmitterName={supplier.name}
                />
              </S.TabPanel>
            </TabPanel>
          </Feature>
        </Tabs>
      </Card>

      <Modal component={CreateSupplierContactModal} />
      <Modal component={CreateSupplierFromInvoiceModal} />
      <Modal component={SupplierBankingInformationModal} />
    </Page>
  );
}

export default SupplierView;
