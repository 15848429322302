import styled from 'styled-components';
import CardUI from '@src/components/ui/Card';

export const Card = styled(CardUI)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ConfirmationContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
  height: 100vh;
`;

export const ProcessingGif = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
`;
