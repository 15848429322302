import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import useToast from '@src/hooks/useToast';
import REJECT_PAYMENT_INSTRUCTION, {
  RejectPaymentInstructionMutationReturn,
  RejectPaymentInstructionMutationVariables,
} from '@src/graphql/mutations/rejectPaymentInstruction';
import { useIntl } from 'react-intl';
import useSettings from '@src/hooks/useSettings';
import userIsAdmin from '@src/utils/roles/userIsAdmin';

const useRejectPaymentInstruction = () => {
  const history = useHistory();
  const intl = useIntl();
  const { toast } = useToast();
  const { currentUser, business } = useSettings();
  const isAdmin = userIsAdmin(currentUser);

  const [rejectPaymentInstructionMutation, { data, loading }] = useMutation<
    RejectPaymentInstructionMutationReturn,
    RejectPaymentInstructionMutationVariables
  >(REJECT_PAYMENT_INSTRUCTION);

  const rejectPaymentInstruction = async (paymentInstructionId: number) => {
    if (business) {
      try {
        await rejectPaymentInstructionMutation({
          variables: { businessId: business?.id, paymentInstructionId },
        });

        history.push('/accounts_payable');
        if (isAdmin) {
          toast.success(
            intl.formatMessage({
              defaultMessage: 'Solicitud de pago rechazada.',
              id: 'cUYYfw',
            })
          );
        } else {
          toast.success(
            intl.formatMessage({
              defaultMessage: 'Solicitud de pago eliminada.',
              id: 'zQFqq6',
            })
          );
        }
      } catch (e) {
        if (isAdmin) {
          toast.error(
            intl.formatMessage({
              defaultMessage: 'Error al rechazar la solicitud.',
              id: 'VbFNC9',
            }),
            e.message
          );
        } else {
          toast.error(
            intl.formatMessage({
              defaultMessage: 'Error al eliminar la solicitud.',
              id: 'PgCOmq',
            }),
            e.message
          );
        }
      }
    }
  };

  return { rejectPaymentInstruction, success: data?.success, loading };
};

export default useRejectPaymentInstruction;
