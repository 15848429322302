import styled, { DefaultTheme } from 'styled-components';
import { inputStyle } from '@src/components/ui/Input/Input.styles';

type InputProps = {
  error?: boolean;
  fullWidth?: boolean;
  theme: DefaultTheme;
};

export const Container = styled.div<InputProps>`
  .phone-input-container {
    width: ${({ theme, fullWidth }) =>
      fullWidth ? '100%' : theme.spacing[64]};
  }
  .phone-input-input {
    ${inputStyle}
    padding: 0px ${({ theme }) => theme.spacing[4]} 0px
      ${({ theme }) => theme.spacing[16]};
    &:focus {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
    }
  }
  .phone-input-button {
    background-color: ${({ theme }) => theme.colors.neutral[200]};
    top: ${({ theme }) => theme.spacing['0.5']};
    bottom: ${({ theme }) => theme.spacing['0.5']};
    left: ${({ theme }) => theme.spacing['0.5']};
  }
  .phone-input-dropdown {
  }
  .phone-input-search {
  }
  .selected-flag.open:before,
  .selected-flag:focus:before {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;
