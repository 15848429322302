import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useInvoices from '@src/hooks/useInvoices';
import usePaymentDefaults from '@src/hooks/usePaymentDefaults';
import { PaymentInstruction } from '@src/types/models';
import { InvoiceListAttributes } from '@src/graphql/fragments';
import {
  Card,
  Table,
  TableCell,
  TableHeaderCell,
  TextField,
  ModalHeader,
  Button,
} from '@src/components/ui';
import { SelectableTableRow } from '@src/components/ui/TableRow';
import { SelectableTableHeader } from '@src/components/ui/TableHeader';
import {
  CenteredFlexContainer,
  Container,
  Spacer,
} from '@src/components/shared/layouts';
import MultiEmailSelect from '@src/components/shared/select/MultiEmailSelect';
import InvoicePaymentMethodDropdown from '@src/components/shared/form/InvoicePaymentForm/components/InvoicePaymentMethodDropdown';
import useUserPaymentMethods from '@src/hooks/useUserPaymentMethods';
import { centsToFloat } from '@src/utils/currency';
import WalletDropdown from '@src/components/shared/dropdown/WalletDropdown';
import useRejectPaymentInstruction from '@src/hooks/useRejectPaymentInstruction';
import useModal from '@src/hooks/useModal';
import ConfirmationModal from '@src/components/shared/modals/ConfirmationModal';
import { trackEvent } from '@src/lib/analytics';
import { PAYMENT_INSTRUCTION_DELETE_CLICKED } from '@src/constants/events';

interface PaymentInstructionReadOnlyProps {
  invoiceIds: string[];
  paymentInstruction: PaymentInstruction;
}

function PaymentInstructionReadOnly({
  invoiceIds,
  paymentInstruction,
}: PaymentInstructionReadOnlyProps) {
  const intl = useIntl();
  const history = useHistory();
  const defaultPaymentMethods = useUserPaymentMethods();
  const { data, loading } = useInvoices(invoiceIds, {
    sort: {
      key: 'emitterTaxpayerName',
      order: 'asc',
    },
  });

  const invoices = usePaymentDefaults<InvoiceListAttributes>(
    data,
    paymentInstruction
      ? paymentInstruction.paymentInstructionInvoices.map((instruction) => ({
          invoiceId: instruction.invoice.id,
          email: instruction.providerEmails || [],
          paymentConcept: instruction.paymentConcept,
          paymentMethod: {
            value: instruction.clabe,
            paymentType: instruction.paymentMethod,
          },
          amount: centsToFloat(instruction.amountCents),
        }))
      : undefined
  );

  const { open: openConfirmationModal } = useModal(ConfirmationModal);

  const {
    rejectPaymentInstruction: rejectPaymentInstructionMutation,
    loading: submitting,
  } = useRejectPaymentInstruction();

  const goToEdit = () => {
    history.push({
      pathname: '/invoice-payments',
      search: `?invoiceIds=${paymentInstruction.paymentInstructionInvoices
        .map((instructionInvoice) => instructionInvoice.invoice.id)
        .join(',')}&payment_instruction_id=${paymentInstruction.id}`,
      state: {
        paymentInstruction,
      },
    });
  };

  const deletePaymentInstruction = () => {
    openConfirmationModal({
      title: intl.formatMessage({
        defaultMessage: '¿Estás seguro que quieres eliminar esta solicitud?',
        id: '46BSv9',
      }),
      text: intl.formatMessage({
        defaultMessage:
          'Esta acción no puede deshacerse y el aprobador ya no verá disponible la solicitud de pago. Moveremos tus facturas a Cuentas por pagar.',
        id: 'JpYjbU',
      }),
      confirmText: intl.formatMessage({
        defaultMessage: 'Sí, eliminar solicitud',
        id: '7NNYTF',
      }),
      cancelText: intl.formatMessage({
        defaultMessage: 'No, conservar solicitud',
        id: 'QIBXLA',
      }),
      onConfirm: () => {
        rejectPaymentInstructionMutation(paymentInstruction.id);
        trackEvent(PAYMENT_INSTRUCTION_DELETE_CLICKED, {
          payment_instruction_id: paymentInstruction.id,
        });
      },
    });
  };

  return (
    <>
      <ModalHeader
        onClose={history.goBack}
        title={intl.formatMessage({
          defaultMessage: 'Detalles de solicitud',
          id: 'Ug15nO',
        })}
        actions={
          <CenteredFlexContainer>
            <WalletDropdown disabled defaultValue="balance" />
            <Spacer margin="2" direction="horizontal" />
            <Button
              variant="outlined"
              color="danger"
              loading={submitting}
              onClick={deletePaymentInstruction}
            >
              {intl.formatMessage({
                defaultMessage: 'Eliminar solicitud',
                id: 'BESEQ1',
              })}
            </Button>
            <Spacer margin="2" direction="horizontal" />
            <Button variant="solid" onClick={goToEdit}>
              {intl.formatMessage({
                defaultMessage: 'Editar solicitud de pago',
                id: 'rQS5Yy',
              })}
            </Button>
          </CenteredFlexContainer>
        }
        sticky
      />
      <Container maxWidth={false}>
        <Spacer margin="8" />
        <Card padding="8">
          <Table columnsWidth={[120, 180, 180, 320, 300, 220]}>
            <SelectableTableHeader>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Folio',
                  id: 'x+TaH9',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Proveedor',
                  id: 'RMvOYP',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Monto a pagar',
                  id: 'ZzlLUg',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Beneficiario',
                  id: 'dtkaPZ',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Notificar al proveedor',
                  id: '7QMfwz',
                })}
              </TableHeaderCell>
              <TableHeaderCell>
                {intl.formatMessage({
                  defaultMessage: 'Concepto de pago',
                  id: 'z8Gzqo',
                })}
              </TableHeaderCell>
            </SelectableTableHeader>
            <tbody>
              {invoices.map((invoice, index) => (
                <SelectableTableRow key={invoice.id}>
                  <TableCell>
                    {invoice?.receiverCountryTaxPayerInfo?.folio || '-'}
                  </TableCell>
                  <TableCell>{invoice.emitterTaxpayerName}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      type="currency"
                      hiddenLabel="Outstanding"
                      value={invoice.amount}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <InvoicePaymentMethodDropdown
                      invoice={invoice}
                      paymentMethods={defaultPaymentMethods}
                      supplierName={invoice?.businessEmitter?.name}
                      businessRelationshipId={invoice?.businessRelationship?.id}
                      bankingInfo={invoice?.businessRelationship?.bankingInfo}
                      value={invoice.paymentMethod}
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <MultiEmailSelect
                      options={invoice.email}
                      fullWidth
                      value={invoice.email}
                      id={`invoices.${index}.email`}
                      hiddenLabel="email"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Selecciona los correos a notificar',
                        id: 'fC1RGL',
                      })}
                      isDisabled
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      hiddenLabel="Payment concept"
                      type="text"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Pago Factura',
                        id: 'RSOShv',
                      })}
                      value={invoice.paymentConcept}
                      disabled
                    />
                  </TableCell>
                </SelectableTableRow>
              ))}

              {loading &&
                [...Array(invoiceIds.length || 10).keys()].map((i) => (
                  <SelectableTableRow key={i}>
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                    <TableCell loading />
                  </SelectableTableRow>
                ))}
            </tbody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

export default PaymentInstructionReadOnly;
