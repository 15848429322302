import React from 'react';

import Card from '@src/components/ui/Card';
import * as S from './WithdrawalSuccess.styles';
import { useIntl } from 'react-intl';
import { Currency } from '@src/components/i18n/Currency';
import BankingInfo from '@src/components/shared/BankingInfo';
import IconButton from '@src/components/ui/IconButton';
import { X as Close } from '@src/components/ui/Icon/outline';
import Header from '@src/components/ui/Header';
import useModal from '@src/hooks/useModal';
import { Label, Alert, Divider } from '@src/components/ui';
import {
  CenteredFlexContainer,
  FlexContainer,
  Grid,
} from '@src/components/shared/layouts';

import { ReactComponent as SuccessIcon } from '@src/assets/img/transfer-success.svg';

import type { BankingInfo as BankingInfoType } from '@src/types/models';
import { BankingInfoValidationStatus } from '@src/types/enums';

export interface WithdrawalSuccessProps {
  withdrawalAmount: number;
  bankingInfo: BankingInfoType;
}

function WithdrawalSuccess({
  bankingInfo,
  withdrawalAmount,
}: WithdrawalSuccessProps) {
  const intl = useIntl();
  const { close } = useModal(WithdrawalSuccess);

  return (
    <S.ConfirmationContainer>
      <Card padding="4">
        <FlexContainer justifyContent="flex-end">
          <IconButton
            onClick={close}
            variant="transparent"
            rounded
            color="neutral"
          >
            <Close />
          </IconButton>
        </FlexContainer>
        <Grid container justifyContent="center">
          <Grid xs={12} md={5}>
            <CenteredFlexContainer direction="column">
              <SuccessIcon />
              <Header as="h5">
                {intl.formatMessage({
                  defaultMessage: 'Tu retiro está en camino',
                  id: '6lzs4B',
                })}
              </Header>
            </CenteredFlexContainer>

            <S.ConfirmationInfo>
              <S.ContainerData>
                <Label>
                  {intl.formatMessage({
                    defaultMessage: 'Monto',
                    id: 'Axixya',
                  })}
                </Label>
                <S.CurrencyNumber>
                  <Currency value={withdrawalAmount} valueInDecimal={false} />
                </S.CurrencyNumber>
              </S.ContainerData>

              <Divider direction="column" margin="2" borderColorStep="300" />
              {bankingInfo && (
                <S.ContainerData>
                  <Label>
                    {intl.formatMessage({
                      defaultMessage: 'CLABE',
                      id: '56FzEv',
                    })}
                  </Label>
                  <BankingInfo
                    hasBorder
                    bankName={bankingInfo.bankName}
                    clabe={bankingInfo.clabe}
                    id={bankingInfo.id}
                    verified={
                      bankingInfo.validationStatus ===
                      BankingInfoValidationStatus.VALIDATED
                    }
                  />
                </S.ContainerData>
              )}
            </S.ConfirmationInfo>

            <Alert color="neutral">
              {intl.formatMessage({
                defaultMessage:
                  'Contáctanos para cualquier duda o problema que tengas',
                id: 'yzyN28',
              })}
            </Alert>
          </Grid>
        </Grid>
      </Card>
    </S.ConfirmationContainer>
  );
}

WithdrawalSuccess.displayName = 'WithdrawalSuccess';

export default WithdrawalSuccess;
