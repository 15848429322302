import React from 'react';

import Modal from '@src/components/shared/Modal';
import DepositModal from '@src/components/shared/modals/DepositModal';
import CreateBusinessForm from '@src/components/shared/form/CreateBusinessForm';
import ConfirmationModal from '@src/components/shared/modals/ConfirmationModal';
import WithdrawFromCreditModal from '@src/components/shared/modals/WithdrawFromCreditModal';

function GlobalModals() {
  return (
    <>
      <Modal component={DepositModal} />
      <Modal component={CreateBusinessForm} />
      <Modal component={ConfirmationModal} />
      <Modal component={WithdrawFromCreditModal} />
    </>
  );
}

export default GlobalModals;
