import { gql } from '@apollo/client';
import type { BankingInfo, TaxPayerInfo } from '@src/types/models';
import type {
  BankingInfoValidationStatus,
  WalletEntryStatus,
} from '@src/types/enums';

export const BANKING_INFO_REQUEST = gql`
  query BankingInfoRequest($token: String!) {
    bankingInfoRequest(token: $token) {
      amount
      email
      invoicesCount
      payeeTaxpayerInfo {
        id
        taxpayerIdentifier
        taxpayerName
      }
      payerTaxpayerInfo {
        id
        taxpayerIdentifier
        taxpayerName
      }
      bankingInfoValidation {
        id
        bankingInfo {
          bankLogoUrl
          bankId
          bankName
          clabe
        }
        validationStatus
      }
      invoicePayments {
        id
        status
        amount
      }
    }
  }
`;

export type BankingInfoRequestParams = { token: string };

export type BankingInfoRequestReturn = {
  bankingInfoRequest: {
    invoicesCount: number;
    email: string;
    bankingInfoValidation: {
      id: string;
      bankingInfo: Pick<
        BankingInfo,
        'bankLogoUrl' | 'bankId' | 'bankName' | 'clabe'
      >;
      validationStatus: keyof typeof BankingInfoValidationStatus;
    };
    payeeTaxpayerInfo: Pick<
      TaxPayerInfo,
      'id' | 'taxpayerName' | 'taxpayerIdentifier'
    >;
    payerTaxpayerInfo: Pick<
      TaxPayerInfo,
      'id' | 'taxpayerName' | 'taxpayerIdentifier'
    >;
    amount: number;
    invoicePayments: Array<{
      id: string;
      amount: number;
      status: keyof typeof WalletEntryStatus;
    }>;
  };
};
