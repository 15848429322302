import { gql } from '@apollo/client';

export const START_BANKING_INFO_VALIDATION = gql`
  mutation StartBankingInfoValidation(
    $businessId: ID!
    $bankingInfo: BankingInfoInput!
  ) {
    startBankingInfoValidation(
      businessId: $businessId
      bankingInfo: $bankingInfo
    ) {
      id
      bankId
      clabe
      recipient
    }
  }
`;
