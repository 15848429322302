import { gql } from '@apollo/client';
import type { WalletEntryStatus } from '@src/types/enums';

export const EXPORT_TRANSACTIONS_PDF = gql`
  mutation ExportTransactionsPDF(
    $status: [WalletEntryStatusEnum!]
    $sortBy: WalletEntrySortingParams
    $search: String
    $createdAtFrom: DateTime
    $createdAtTo: DateTime
    $providerId: ID
    $businessId: ID!
    $walletEntriesIds: [ID!]
    $pending: Boolean
  ) {
    exportTransactionsPdf(
      status: $status
      sortBy: $sortBy
      search: $search
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      providerId: $providerId
      businessId: $businessId
      walletEntriesIds: $walletEntriesIds
      pending: $pending
    ) {
      id
    }
  }
`;

export type ExportTransactionsPDFInput = {
  status?: Array<keyof typeof WalletEntryStatus>;
  search?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  providerId?: string;
  businessId: string;
  walletEntriesIds?: string[];
  pending?: boolean;
};

export type ExportTransactionsPDFResponse = {
  exportTransactions: { id: string }[];
};
