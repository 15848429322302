import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Currency from '@src/components/i18n/Currency';

import { Attributes } from '@src/types/resourceList';

type CreditNoteField = {
  id: string;
  currency: string;
  appliedCredit: number;
  pendingCredit: number;
  applied?: boolean;
};

const attributes: Attributes<CreditNoteField> = {
  id: {
    name: <FormattedMessage defaultMessage="Nota crédito" id="XyrDuO" />,
    render: (datum) => <Link to={`/credit_notes/${datum.id}`}>{datum.id}</Link>,
  },
  pendingCredit: {
    name: <FormattedMessage defaultMessage="Saldo por aplicar" id="xxSL5F" />,
    render: (datum) => (
      <Currency
        value={datum.applied ? datum.appliedCredit : datum.pendingCredit || 0}
        currency={datum.currency}
      />
    ),
  },
  appliedCredit: {
    name: <FormattedMessage defaultMessage="Saldo aplicado" id="8/cFG7" />,
    render: (datum) => (
      <Currency
        value={datum.applied ? datum.pendingCredit : datum.appliedCredit || 0}
        currency={datum.currency}
      />
    ),
  },
};

export default attributes;
