import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import usePagination from './usePagination';
import ClIENT_RELATIONSHIPS_QUERY, {
  ClientRelationshipsQueryReturn,
  ClientRelationshipsQueryVariables,
} from '@src/graphql/queries/clientRelationships';

import type { ClientRelationship } from '@src/types/models';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';

function useClientRelationshipsQuery(variables) {
  const { data, loading, error, refetch } = useQuery<
    ClientRelationshipsQueryReturn,
    ClientRelationshipsQueryVariables
  >(ClIENT_RELATIONSHIPS_QUERY, {
    variables: {
      ...variables,
      first: RESULTS_PER_PAGE,
      sortBy: { field: 'CREATED_AT', direction: 'DESC' },
    },
    skip: !variables.businessId,
    notifyOnNetworkStatusChange: true,
  });

  const {
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
  } = usePagination<ClientRelationship>(data?.clientRelationships, refetch);

  const clientRelationships = useMemo(
    () => data?.clientRelationships?.edges?.map((edge) => edge.node) || [],
    [data]
  );

  return {
    data: clientRelationships,
    loading,
    error,
    count,
    hasNext,
    hasPrevious,
    next,
    previous,
    page,
    refetch,
  };
}

export default useClientRelationshipsQuery;
