import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const AlertTriangle: Icon = forwardRef(
  ({ color = 'currentColor', size = 44, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={Number(size) * 1.18}
        height={size}
        viewBox={`0 0 20 20`}
        fill="none"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1073 2.88734L18.8528 14.7649C19.7593 16.3611 18.5947 18.3334 16.7457 18.3334H3.25464C1.40558 18.3334 0.240975 16.3611 1.1475 14.7649L7.89302 2.88734C8.81729 1.25989 11.183 1.25988 12.1073 2.88734ZM10.0002 6.66675C10.4604 6.66675 10.8335 7.03984 10.8335 7.50008V11.6667C10.8335 12.127 10.4604 12.5001 10.0002 12.5001C9.53993 12.5001 9.16683 12.127 9.16683 11.6667V7.50008C9.16683 7.03984 9.53993 6.66675 10.0002 6.66675ZM10.0002 15.4167C10.4604 15.4167 10.8335 15.0437 10.8335 14.5834C10.8335 14.1232 10.4604 13.7501 10.0002 13.7501C9.53993 13.7501 9.16683 14.1232 9.16683 14.5834C9.16683 15.0437 9.53993 15.4167 10.0002 15.4167Z"
          fill={color}
        />
      </svg>
    );
  }
);

AlertTriangle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AlertTriangle.displayName = 'AlertTriangle';

export default AlertTriangle;
