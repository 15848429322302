import { defineMessages } from 'react-intl';

export default defineMessages({
  loadingPlaceholder: {
    defaultMessage: 'Cargando...',
    id: 'ARhEHP',
  },
  firstNameContactLabel: {
    defaultMessage: 'Nombre(s)',
    id: '0t5BoY',
  },
  firstNameContactPlaceholder: {
    defaultMessage: 'Juan',
    id: 'Lw+19l',
  },
  lastNameContactLabel: {
    defaultMessage: 'Apellidos',
    id: 'nVSFBU',
  },
  lastNameContactPlaceholder: {
    defaultMessage: 'Velez',
    id: 'PX0aKJ',
  },
  phoneLabel: {
    defaultMessage: 'Teléfono',
    id: 'S6W4PT',
  },
  phonePlaceholder: {
    defaultMessage: '+52 55 2222 2222',
    id: 'Pslibq',
  },
  emailLabel: {
    defaultMessage: 'Correo electrónico corporativo',
    id: 'hv7ube',
  },
  emailPlaceholder: {
    defaultMessage: 'example@email.com',
    id: 'SvELQW',
  },
  passwordLabel: {
    defaultMessage: 'Nueva contraseña',
    id: 'Or996K',
  },
  passwordPlaceholder: {
    defaultMessage: 'Escribe tu contraseña',
    id: '6DPD4u',
  },
});
