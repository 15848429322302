import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router';

import * as S from './ResendEmailModal.styles';
import Header from '@src/components/ui/Header';
import Button from '@src/components/ui/Button';
import Input from '@src/components/ui/Input';
import { EMAIL_PATTERN } from '@src/constants/patterns';
import Card from '@src/components/ui/Card';
import Icon from '@src/components/ui/Icon';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import { RESEND_HIGO_PAYMENT_NOTIFICATION } from '@src/graphql/mutations';
import { trackEvent } from '@src/lib/analytics';
import { HIGO_PAY_PROOF_RESENT } from '@src/constants/events';
interface ResendEmailModalProps {
  higoPaymentSentEntryId: string;
  defaultEmail?: string;
}
function ResendEmailModal({
  higoPaymentSentEntryId,
  defaultEmail,
}: ResendEmailModalProps) {
  const intl = useIntl();
  const business = useSettings('business');
  const { pathname } = useLocation();
  const { toast } = useToast();
  const [sent, setSent] = useState(false);
  const { register, handleSubmit, formState } = useForm({
    mode: 'all',
    defaultValues: {
      email: defaultEmail,
    },
    shouldFocusError: true,
  });
  const [resendEmail, { loading }] = useMutation(
    RESEND_HIGO_PAYMENT_NOTIFICATION
  );

  const onSubmit = async ({ email }) => {
    if (business?.id && email && higoPaymentSentEntryId) {
      try {
        await resendEmail({
          variables: {
            businessId: business?.id,
            higoPaymentSentEntryId,
            user: {
              email,
            },
          },
        });
        setSent(true);
        trackEvent(HIGO_PAY_PROOF_RESENT, {
          view: pathname === '/transactions' ? 'transactions' : 'transaction',
        });
      } catch (error: any) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Hubo un error al enviar notificación.',
            id: 'pr0jdG',
          }),
          error.message
        );
      }
    }
  };

  return (
    <S.Container>
      <Header as="h5">
        <FormattedMessage defaultMessage="Reenviar comprobante" id="2NMTCx" />
      </Header>

      <p className="resend-subtitle">
        <FormattedMessage
          defaultMessage="Reenvia el comprobante de tu pago a tu proveedor para que pueda confirmar la recepción del pago."
          id="8wP4Lf"
        />
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Card padding="4" borderColor="neutral" borderColorStep="200">
          <S.InputHeader>
            <div className="mail-icon">
              <Icon name="Mail" color="neutral" colorStep="500" />
            </div>
            <p>
              <FormattedMessage
                defaultMessage="Reenviar por correo"
                id="YxQpTk"
              />
            </p>
          </S.InputHeader>
          <S.InputContainer>
            <Input
              placeholder="ejemplo@higo.io"
              type="email"
              error={!!formState.errors?.email}
              {...register('email', {
                required: true,
                pattern: EMAIL_PATTERN,
              })}
            />
            <Button compact loading={loading} disabled={sent} type="submit">
              {sent ? (
                <FormattedMessage defaultMessage="Enviado" id="tpK0Od" />
              ) : (
                <FormattedMessage defaultMessage="Enviar" id="hKJZJR" />
              )}
            </Button>
          </S.InputContainer>
        </Card>
      </form>
    </S.Container>
  );
}

ResendEmailModal.displayName = 'ResendEmailModal';

export default ResendEmailModal;
