import React from 'react';

import { Button } from '@src/components/ui';
import { Copy } from '@src/components/ui/Icon/outline';
import useToast from '@src/hooks/useToast';
import { useIntl } from 'react-intl';

interface CopyButtonProps {
  text: string;
  onCopy?: () => void;
}

function CopyButton({ text, onCopy }: CopyButtonProps) {
  const { snack, toast } = useToast();
  const intl = useIntl();

  if (!navigator?.clipboard?.writeText) {
    return null;
  }

  const handleClick = () => {
    try {
      navigator.clipboard.writeText(text);
      snack(
        intl.formatMessage({
          defaultMessage: 'Copiado al portapapeles',
          id: 'p6vWKZ',
        })
      );
      onCopy && onCopy();
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Hubo un problema',
          id: 'SZT1hj',
        })
      );
    }
  };

  return (
    <Button
      onClick={handleClick}
      variant="ghosted"
      startIcon={<Copy />}
      compact
    >
      {intl.formatMessage({
        defaultMessage: 'Copiar',
        id: 'N8gZ+J',
      })}
    </Button>
  );
}

export default CopyButton;
