import { useMemo } from 'react';

import { hasBusinessFeature } from '@src/utils/features';
import useSettings from '@src/hooks/useSettings';
import { isHigoAccount } from '@src/components/shared/dropdown/PaymentMethodDropdown/utils';

import { InvoicePaymentType } from '@src/types/enums';
import type { PaymentMethod } from '@src/types/models';

const usePaymentMethods = (
  bankingInfo
): {
  paymentTypes: InvoicePaymentType[];
  defaultPaymentMethod?: PaymentMethod;
} => {
  const { business } = useSettings();
  const paymentMethods = useMemo(() => {
    const methods = [InvoicePaymentType.STP_PAYMENT];
    return methods;
  }, [business]);

  if (isHigoAccount(bankingInfo?.clabe || '')) {
    return {
      paymentTypes: [InvoicePaymentType.HIGO_PAYMENT],
      defaultPaymentMethod: {
        paymentType: InvoicePaymentType.HIGO_PAYMENT,
        value: undefined,
      },
    };
  }

  if (
    paymentMethods?.includes(InvoicePaymentType.STP_PAYMENT) &&
    bankingInfo?.id
  ) {
    return {
      paymentTypes: paymentMethods || [],
      defaultPaymentMethod: {
        paymentType: InvoicePaymentType.STP_PAYMENT,
        value: bankingInfo?.clabe,
      },
    };
  }
  return {
    paymentTypes: paymentMethods || [],
    defaultPaymentMethod: undefined,
  };
};

export default usePaymentMethods;
