import styled from 'styled-components';

export const WalletItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    text-align: left;
  }
`;

export const Balance = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1;
`;

export const Name = styled.p`
  font-size: ${({ theme }) => theme.fontSize['2xs']};
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const Icon = styled.div`
  width: ${({ theme }) => theme.spacing[8]};
  height: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.primary[50]};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.primary[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[4]};
`;

export const TaxPayerDropdownContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${({ theme, fullWidth }) => (fullWidth ? '100%' : theme.spacing[64])};
`;
