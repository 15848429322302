import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: ${({ theme }) => theme.spacing[52]};

  grid-template-areas:
    'credit-use due-payments'
    'credit-use request-credit';
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

export const CreditUseContainer = styled.div`
  grid-area: credit-use;
`;

export const DuePaymentsContainer = styled.div`
  grid-area: due-payments;
`;

export const RequestCreditContainer = styled.div`
  grid-area: request-credit;
`;
