import { Tab as ReactTab } from 'react-tabs';
import React from 'react';
import TabUI from '@src/components/ui/Tab';

type TabProps = {
  children: any;
  selected?: boolean;
  count?: number;
  defaultIcon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
  isAlert?: boolean;
  onClick?: () => void;
};

function Tab({
  children,
  selected,
  count,
  defaultIcon,
  selectedIcon,
  isAlert,
  onClick,
}: TabProps) {
  const color = selected ? 'primary' : 'gray';
  return (
    <ReactTab selected={selected} onClick={onClick}>
      <TabUI
        selected={selected}
        icon={selected && selectedIcon ? selectedIcon : defaultIcon}
        label={children}
        BadgeProps={{
          label: count,
          color: isAlert ? 'danger' : color,
        }}
      />
    </ReactTab>
  );
}

Tab.tabsRole = 'Tab';

export default Tab;
