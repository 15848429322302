import React from 'react';

import * as S from './Loader.styles';

import loader from '@src/assets/img/higo_loader.gif';

interface LoaderProps {
  fullWidth?: boolean;
}

function Loader({ fullWidth }: LoaderProps) {
  if (fullWidth) {
    return (
      <S.FullWidthContainer>
        <S.Image src={loader} alt="loading" />
      </S.FullWidthContainer>
    );
  }

  return <S.Image src={loader} alt="loading" />;
}

export default Loader;
