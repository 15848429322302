import { gql } from '@apollo/client';
import SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES, {
  SupplierBusinessRelationshipAttributes,
} from '@src/graphql/fragments/supplierBusinessRelationshipAttributes';

export const BUSINESS_RELATIONSHIP_QUERY = gql`
  query BusinessRelationship($businessId: ID!, $businessRelationshipId: ID!) {
    businessRelationship(
      businessId: $businessId
      businessRelationshipId: $businessRelationshipId
    ) {
      ...SupplierBusinessRelationshipAttributes
    }
  }
  ${SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES}
`;

export default BUSINESS_RELATIONSHIP_QUERY;

export type ProviderRelationshipsQueryReturn = {
  providerRelationships: SupplierBusinessRelationshipAttributes;
};

export type ProviderRelationshipsQueryVariables = {
  businessId: string;
  businessRelationshipId: string;
};
