import styled from 'styled-components';

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
`;
