import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as S from './WithdrawalModal.styles';
import Button from '@src/components/ui/Button';
import Header from '@src/components/ui/Header';
import usePageTrack from '@src/hooks/usePageTrack';
import useModal from '@src/hooks/useModal';
import WithdrawalFullscreenModal from '../WithdrawalFullscreenModal';
import WithdrawalValidationModal from '@src/components/shared/modals/WithdrawalValidationModal';
import useSettings from '@src/hooks/useSettings';
import useBusinessBankingInfoValidation from '@src/hooks/useBusinessBankingInfoValidation';

import BankingInfoValidation from '@src/components/shared/BankingInfoValidation';

function WithdrawalModal() {
  usePageTrack('withdraw_modal', 'core');
  const business = useSettings('business');
  const { open } = useModal(WithdrawalFullscreenModal, 50);
  const { open: openBankingInfoForm } = useModal(WithdrawalValidationModal);
  const { close } = useModal(WithdrawalModal);

  const {
    isVerified,
    isRejected,
    isValidating,
    bankingInfo,
    rejectedAccount,
  } = useBusinessBankingInfoValidation(business);

  const handleAddClick = () => {
    close();
    openBankingInfoForm({});
  };

  const handleWithdrawClick = () => {
    close();
    open();
  };

  return !!bankingInfo && !isRejected ? (
    <S.Container>
      <Header as="h5">
        <FormattedMessage defaultMessage="Retirar" id="2OwuoE" />
      </Header>

      <S.Body>
        <FormattedMessage
          defaultMessage="Selecciona la cuenta a la que quieres recibir tu dinero."
          id="wbmyJD"
        />
      </S.Body>
      <BankingInfoValidation
        isValidating={isValidating}
        isVerified={isVerified}
        isRejected={isRejected}
        bankingInfo={bankingInfo}
        onAddClick={handleAddClick}
      />
      <S.Actions>
        <Button onClick={close} variant="outlined" color="neutral">
          <FormattedMessage defaultMessage="Cancelar" id="nZLeaQ" />
        </Button>
        <Button disabled={!isVerified} onClick={handleWithdrawClick}>
          <FormattedMessage defaultMessage="Retirar" id="2OwuoE" />
        </Button>
      </S.Actions>
    </S.Container>
  ) : (
    <S.Container>
      <BankingInfoValidation
        isValidating={isValidating}
        isVerified={isVerified}
        isRejected={isRejected}
        bankingInfo={bankingInfo}
        onAddClick={handleAddClick}
        rejectedAccount={rejectedAccount}
      />
    </S.Container>
  );
}

WithdrawalModal.displayName = 'WithdrawalModal';

export default WithdrawalModal;
