import { gql } from '@apollo/client';
import USER_PROFILE_ATTRIBUTES from '../fragments/userProfileAttributes';

export const APP_DATA_QUERY_NAME = 'AppData';

export const APP_DATA_QUERY = gql`
query ${APP_DATA_QUERY_NAME} {
  currentUser {
    ...UserProfile
  }
}
${USER_PROFILE_ATTRIBUTES}
`;
