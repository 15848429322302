import { defineMessages } from 'react-intl';

export default defineMessages({
  FINANCE_REPAYMENT: {
    defaultMessage: 'Pago de crédito',
    id: 'oiI7G9',
  },
  PAYMENT: {
    defaultMessage: 'Pago',
    id: 'Lb3pmu',
  },
  DEPOSIT: {
    defaultMessage: 'Depósito',
    id: 'OkH8p8',
  },
  HIGO_FUNDING: {
    defaultMessage: 'Fondeo Higo',
    id: 'sFqHOw',
  },
  CHARGE: {
    defaultMessage: 'Cobro',
    id: 'HmDC+5',
  },
  REFUND: {
    defaultMessage: 'Reembolso',
    id: 'k0uMwU',
  },
  WITHDRAWAL: {
    defaultMessage: 'Retiro',
    id: 'S82dF1',
  },
  STP_PAYMENT: {
    defaultMessage: 'SPEI',
    id: '5NK1FG',
  },
  HIGO_PAYMENT: {
    defaultMessage: 'Higo Pay',
    id: 'Re/h3s',
  },
  MANUAL: {
    defaultMessage: 'Manual',
    id: 'M59JhX',
  },
  CREDIT_NOTE: {
    defaultMessage: 'Nota de crédito',
    id: 'URBInu',
  },
  ADVANCE_PAYMENT: {
    id: 'ztnRJg',
    defaultMessage: 'Anticipo',
  },
});
