import { defineMessages } from 'react-intl';

export default defineMessages({
  suppliers: {
    defaultMessage: 'Proveedores',
    id: 'ZOFug8',
  },
  clients: {
    defaultMessage: 'Clientes',
    id: 'zBnTsv',
  },
});
