import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import useSettings from '@src/hooks/useSettings';
import useProvidersListQuery from '@src/hooks/useProvidersListQuery';
import useQueryParams from '@src/hooks/useQueryParams';
import { Grid } from '../layouts';
import { DateRangePicker, FormControl, TextField } from '@src/components/ui';

import { InvoiceGlobalStatus, InvoiceVoucherType } from '@src/types/enums';

type InvoiceFilterOption =
  | 'invoicingDate'
  | 'paymentDate'
  | 'expiryDate'
  | 'businessEmitterId'
  | 'voucherType'
  | 'globalStatus';

type GlobalStatusOption =
  | 'pendingPayment'
  | 'pendingApproval'
  | 'paid'
  | 'expired'
  | 'financingRequested';

interface InvoiceFiltersProps {
  fields: InvoiceFilterOption[];
  globalStatusOptions?: GlobalStatusOption[];
}

const Filters = ({ fields, globalStatusOptions }: InvoiceFiltersProps) => {
  const intl = useIntl();
  const { business } = useSettings();
  const [query, setQuery] = useQueryParams();
  const { data } = useProvidersListQuery(business?.id);
  const providerOptions = data.map((provider) => ({
    label: provider.name,
    value: provider.id,
  }));

  const globalStatusSelectedOptions = useMemo(() => {
    const options = {
      pendingPayment: {
        label: intl.formatMessage({
          id: 'YkWZGw',
          defaultMessage: 'Por pagar',
        }),
        value: InvoiceGlobalStatus.PENDING_PAYMENT,
      },
      expired: {
        label: intl.formatMessage({
          id: 'V6Zcg0',
          defaultMessage: 'Vencida',
        }),
        value: InvoiceGlobalStatus.EXPIRED,
      },
      financingRequested: {
        label: intl.formatMessage({
          id: 'KiXOgZ',
          defaultMessage: 'Financiamiento solicitado',
        }),
        value: InvoiceGlobalStatus.FINANCING_REQUESTED,
      },
      paid: {
        label: intl.formatMessage({
          id: 'AvWWd0',
          defaultMessage: 'Pagada',
        }),
        value: InvoiceGlobalStatus.PAID,
      },
      pendingApproval: {
        label: intl.formatMessage({
          id: 'djFdu3',
          defaultMessage: 'Por aprobar',
        }),
        value: InvoiceGlobalStatus.PENDING_APPROVAL,
      },
    };

    return (
      globalStatusOptions?.map((option) => options[option]) ||
      Object.values(options)
    );
  }, []);

  const voucherTypeOptions = [
    {
      label: intl.formatMessage({
        id: 'yZ36Da',
        defaultMessage: 'Factura',
      }),
      value: InvoiceVoucherType.INVOICE,
    },
    {
      label: intl.formatMessage({
        id: 'URBInu',
        defaultMessage: 'Nota de crédito',
      }),
      value: InvoiceVoucherType.CREDIT_NOTE,
    },
  ];

  const handleDateChange = (name, values) => {
    setQuery({
      [name]: values,
    });
  };

  const handleSelectChange = (name, option) => {
    setQuery({
      [name]: option?.value,
    });
  };

  const handleMultiSelectChange = (name, options) => {
    setQuery({
      [name]: options?.map((option) => option.value),
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        {fields.includes('invoicingDate') ? (
          <Grid item xs={4} md={3}>
            <FormControl
              fullWidth
              label={intl.formatMessage({
                id: 'f4nC1Y',
                defaultMessage: 'Fecha de emisión',
              })}
            >
              <DateRangePicker
                name="invoicingDate"
                fullWidth
                value={query.invoicingDate}
                onChange={(values) => handleDateChange('invoicingDate', values)}
              />
            </FormControl>
          </Grid>
        ) : null}

        {fields.includes('paymentDate') ? (
          <Grid item xs={4} md={3}>
            <FormControl
              fullWidth
              label={intl.formatMessage({
                id: 'lnTg/E',
                defaultMessage: 'Fecha de pago',
              })}
            >
              <DateRangePicker
                name="paymentDate"
                fullWidth
                value={query.paymentDate}
                onChange={(values) => handleDateChange('paymentDate', values)}
              />
            </FormControl>
          </Grid>
        ) : null}

        {fields.includes('expiryDate') ? (
          <Grid item xs={4} md={3}>
            <FormControl
              fullWidth
              label={intl.formatMessage({
                id: 'XF6Ipl',
                defaultMessage: 'Fecha de vencimiento',
              })}
            >
              <DateRangePicker
                name="expiryDate"
                fullWidth
                value={query.expiryDate}
                onChange={(values) => handleDateChange('expiryDate', values)}
              />
            </FormControl>
          </Grid>
        ) : null}

        {fields.includes('businessEmitterId') ? (
          <Grid item xs={4} md={3}>
            <TextField
              type="select"
              isMulti
              fullWidth
              name="businessEmitterId"
              options={providerOptions}
              label={intl.formatMessage({
                id: 'RMvOYP',
                defaultMessage: 'Proveedor',
              })}
              value={providerOptions?.find(
                (option) => option.value === query.businessEmitterId
              )}
              onChange={(values) =>
                handleMultiSelectChange('businessEmitterId', values)
              }
            />
          </Grid>
        ) : null}

        {fields.includes('voucherType') ? (
          <Grid item xs={4} md={3}>
            <TextField
              type="select"
              name="voucherType"
              fullWidth
              options={voucherTypeOptions}
              isClearable
              label={intl.formatMessage({
                id: 'yfH6xH',
                defaultMessage: 'Tipo de documento',
              })}
              value={voucherTypeOptions?.find(
                (option) => option.value === query.voucherType
              )}
              onChange={(values) => handleSelectChange('voucherType', values)}
            />
          </Grid>
        ) : null}

        {fields.includes('globalStatus') ? (
          <Grid item xs={4} md={3}>
            <TextField
              type="select"
              name="globalStatus"
              fullWidth
              options={globalStatusSelectedOptions}
              isClearable
              label={intl.formatMessage({
                id: 'dWihL7',
                defaultMessage: 'Estatus',
              })}
              value={globalStatusSelectedOptions.find(
                (option) => option.value === query.globalStatus
              )}
              onChange={(values) => handleSelectChange('globalStatus', values)}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default Filters;
