import React, { useMemo, useState } from 'react';
import SwitcherItem from '../SwitcherItem';
import { StyledSwitcher, Highlight } from './Switcher.styles';
import { SwitcherProps } from './types';

export default function Switcher(props: SwitcherProps) {
  const { children: childrenProp, value, onChange, ...otherProps } = props;
  const [index, setIndex] = useState(0);
  const children = useMemo(
    () =>
      React.Children.map(childrenProp, (child, index) => {
        if (!React.isValidElement(child) || child.type !== SwitcherItem) {
          return null;
        }

        const childValue = child.props.value;
        const selected = childValue === value;
        if (selected) {
          setIndex(index);
        }
        return React.cloneElement(child, {
          onChange,
          selected,
        });
      }),
    [childrenProp, value]
  );

  return (
    <StyledSwitcher count={children?.length} {...otherProps}>
      <Highlight index={index} count={children?.length} appear={!!value} />
      {children}
    </StyledSwitcher>
  );
}
