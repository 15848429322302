import React from 'react';
import { TableCellProps } from './types';
import { TableData } from './TableCell.styles';
import Skeleton from '../Skeleton';

export default function TableCell(props: TableCellProps) {
  const { children, loading, ...otherProps } = props;
  return (
    <TableData {...otherProps}>{loading ? <Skeleton /> : children}</TableData>
  );
}
