import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Control, UseFormHandleSubmit } from 'react-hook-form';

import TermsCheckbox from '@src/components/shared/TermsCheckbox';
import PaymentsTotalButton from '../PaymentsTotalButton';
import WalletDropdown from '@src/components/shared/dropdown/WalletDropdown';
import * as S from './InvoicePaymentFormHeader.styles';
import { trackEvent } from '@src/lib/analytics';
import { PAYMENT_TERMS_ACCEPTED } from '@src/constants/events';
import { Button } from '@src/components/ui';

interface InvoicePaymentFormHeaderProps {
  control: Control<any>;
  handleSubmit: UseFormHandleSubmit<any>;
  ctaLabel?: string;
  loading?: boolean;
  fieldNameToSum: 'invoices' | 'payments';
  totalProp?: string;
  wallet?: 'credit' | 'balance';
  hiddenTerms?: boolean;
  ctaCustomButton?: (handleSubmit: UseFormHandleSubmit<any>) => JSX.Element;
  onReject?: () => void;
}

function InvoicePaymentFormHeader({
  wallet,
  control,
  loading,
  ctaLabel,
  hiddenTerms,
  fieldNameToSum,
  totalProp = 'amount',
  ctaCustomButton,
  handleSubmit,
  onReject,
}: InvoicePaymentFormHeaderProps) {
  const intl = useIntl();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
    trackEvent(PAYMENT_TERMS_ACCEPTED);
  };
  return (
    <S.HeaderActions>
      {!hiddenTerms && (
        <TermsCheckbox
          onChange={handleTermsChange}
          label={intl.formatMessage({
            defaultMessage: 'Para pagar, acepto los',
            id: 'A5JncW',
          })}
        />
      )}
      <WalletDropdown disabled defaultValue={wallet} />
      {onReject && (
        <Button
          variant="outlined"
          color="danger"
          onClick={onReject}
          loading={loading}
        >
          {intl.formatMessage({
            defaultMessage: 'Rechazar pago',
            id: 'e7KtU6',
          })}
        </Button>
      )}
      {ctaCustomButton?.(handleSubmit) || (
        <PaymentsTotalButton
          name={fieldNameToSum}
          totalProp={totalProp}
          control={control}
          loading={loading}
          disabled={!termsAccepted}
          wallet={wallet}
        >
          {ctaLabel}
        </PaymentsTotalButton>
      )}
    </S.HeaderActions>
  );
}

export default InvoicePaymentFormHeader;
