import Storage from 'local-storage-fallback';
import posthog from 'posthog-js';

import { AUTH_TOKEN as AUTH_TOKEN_KEY } from '@src/constants/storageKeys';
import { isPosthogEnabled } from '../analytics';

export const get = () => Storage.getItem(AUTH_TOKEN_KEY);

export const set = (token: string) => Storage.setItem(AUTH_TOKEN_KEY, token);

export const remove = () => {
  if (isPosthogEnabled) posthog.reset();
  return Storage.removeItem(AUTH_TOKEN_KEY);
};

export const loggedIn = () => !!get();
