import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import Button from '@src/components/ui/Button';
import Permission from '@src/components/shared/Permission';
import useModal from '@src/hooks/useModal';
import RejectInvoiceForm from '@src/components/shared/form/RejectInvoiceForm';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_INVOICE_STATUS } from '@src/constants/events';
import useToast from '@src/hooks/useToast';
import Dropdown, { DropdownItem } from '@src/components/ui/Dropdown';
import UPDATE_INVOICES_STATUS_MUTATION, {
  UpdateInvoiceStatusReturn,
  UpdateInvoiceStatusParams,
} from '@src/graphql/mutations/updateInvoiceStatus';
import useSettings from '@src/hooks/useSettings';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import { FlexContainer } from '@src/components/shared/layouts';

import { PermissionName, InvoiceApprovalStatus } from '@src/types/enums';

type CreditNoteActionsProps = {
  approvalStatus: keyof typeof InvoiceApprovalStatus;
  invoiceId: string;
};

function CreditNoteActions({
  approvalStatus,
  invoiceId,
}: CreditNoteActionsProps) {
  const intl = useIntl();
  const { toast } = useToast();
  const { business } = useSettings();
  const { open: openRejectForm } = useModal(RejectInvoiceForm);
  const [updateInvoiceStatus, { loading }] = useMutation<
    UpdateInvoiceStatusReturn,
    UpdateInvoiceStatusParams
  >(UPDATE_INVOICES_STATUS_MUTATION, {
    refetchQueries: [INVOICE_QUERY],
    onCompleted: () => {
      trackEvent(UPDATE_INVOICE_STATUS, {
        creator: 'Buyer',
        view: 'CreditNoteView',
      });

      toast.success(
        intl.formatMessage(
          {
            id: 'isno0t',
            defaultMessage: 'Nota de crédito {status}',
          },
          {
            status: intl.formatMessage({
              id: '7X1iqf',
              defaultMessage: 'aprobada',
            }),
          }
        )
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar la nota de crédito',
          id: 'dvNYxN',
        }),
        e.message
      );
    },
  });

  const openRejectInvoiceModal = () => {
    if (invoiceId) {
      openRejectForm({
        invoiceId,
      });
    }
  };

  const handleApproveInvoice = () => {
    if (invoiceId && business?.id) {
      updateInvoiceStatus({
        variables: {
          businessId: business.id,
          invoiceId,
          status: InvoiceApprovalStatus.APPROVED,
        },
      });
    }
  };

  return (
    <FlexContainer gap="8px">
      {approvalStatus === InvoiceApprovalStatus.APPROVED ||
        (approvalStatus === InvoiceApprovalStatus.PENDING && (
          <Permission
            permissions={[
              PermissionName.MANAGE_INVOICES_EMITTED,
              PermissionName.MANAGE_INVOICES_RECEIVED,
            ]}
          >
            <Dropdown
              label={intl.formatMessage({
                id: 'GUG1Jz',
                defaultMessage: 'Más acciones',
              })}
            >
              <DropdownItem onClick={openRejectInvoiceModal}>
                {intl.formatMessage({
                  id: 'YRNntu',
                  defaultMessage: 'Rechazar',
                })}
              </DropdownItem>
            </Dropdown>
          </Permission>
        ))}

      {approvalStatus === InvoiceApprovalStatus.PENDING && (
        <Permission
          permissions={[
            PermissionName.MANAGE_INVOICES_EMITTED,
            PermissionName.MANAGE_INVOICES_RECEIVED,
          ]}
        >
          <Button onClick={handleApproveInvoice} loading={loading}>
            {intl.formatMessage({
              id: 'Ut4gR5',
              defaultMessage: 'Aprobar Nota',
            })}
          </Button>
        </Permission>
      )}
    </FlexContainer>
  );
}

export default CreditNoteActions;
