import styled from 'styled-components';
import Input from '../Input';

export const CurrencySpan = styled.span`
  font-size: ${({ theme }) => theme.fontSize['lg']};
  color: ${({ theme }) => theme.colors.neutral[300]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StyledInput = styled(Input)`
  font-size: ${({ theme }) => theme.fontSize['lg']};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: ${({ theme }) => theme.spacing[12]};
  padding: 0px
    ${({ theme, endIcon }) => (endIcon ? theme.spacing[15] : theme.spacing[4])}
    0px
    ${({ theme, startIcon }) =>
      startIcon ? theme.spacing[15] : theme.spacing[4]};
`;
