import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './SignUpStepBar.styles';
import {
  Smile,
  Briefcase,
  ContactBook,
  BankTransfer,
} from '@src/components/ui/Icon/outline';

import { ReactComponent as Check } from './img/check_green.svg';
import { ReactComponent as Step1Icon } from './img/step1.svg';
import { ReactComponent as Step2Icon } from './img/step2.svg';
import { ReactComponent as Step3Icon } from './img/step3.svg';

type SignUpStepBarProps = {
  step: 1 | 2 | 3;
  isPayeeFlow?: boolean;
};

const Step = ({ icon, active, title, description, done, stepIcon }) => (
  <S.StepContainer stepActive={active}>
    <S.StepIconContainer>{icon}</S.StepIconContainer>
    <S.StepDescription>
      <S.StepTitle>{title}</S.StepTitle>
      <S.StepText>{description}</S.StepText>
    </S.StepDescription>
    <S.Icon>{done ? <Check /> : stepIcon}</S.Icon>
  </S.StepContainer>
);
export const SignUpStepBar = (props: SignUpStepBarProps) => {
  const { step, isPayeeFlow = false } = props;
  const intl = useIntl();

  if (isPayeeFlow) {
    return (
      <>
        <S.StyledLogo />
        <S.StepsContainer>
          <Step
            icon={<Smile size={18} />}
            active={step === 1}
            title={intl.formatMessage({
              defaultMessage: 'Crea tu cuenta de Higo',
              id: 'zvwYZo',
            })}
            description={intl.formatMessage({
              defaultMessage: 'Establece un mail de ingreso y contraseña.',
              id: 'EH6LYX',
            })}
            stepIcon={<Step1Icon />}
            done={step > 1}
          />
          <Step
            icon={<BankTransfer size={18} />}
            active={step === 2}
            title={intl.formatMessage({
              defaultMessage: 'Enlaza tu cuenta bancaria',
              id: 'wbYi42',
            })}
            description={intl.formatMessage({
              defaultMessage:
                'Agrega tu CLABE para validar tu informacion bancaria.',
              id: 'uxARbc',
            })}
            stepIcon={<Step2Icon />}
            done={step > 2}
          />
        </S.StepsContainer>
      </>
    );
  }

  return (
    <>
      <S.StyledLogo />
      <S.StepsContainer>
        <Step
          icon={<Smile size={18} />}
          active={step === 1}
          title={intl.formatMessage({
            defaultMessage: 'Información personal',
            id: 'naI+m8',
          })}
          description={intl.formatMessage({
            defaultMessage:
              'Queremos conocerte, completa tu información personal.',
            id: '+0U+f8',
          })}
          stepIcon={<Step1Icon />}
          done={step > 1}
        />
        <Step
          icon={<Briefcase size={18} />}
          active={step === 2}
          title={intl.formatMessage({
            defaultMessage: 'Información de tu empresa',
            id: 'Ou0dFV',
          })}
          description={intl.formatMessage({
            defaultMessage:
              'Y para terminar, completa la informacion de tu empresa.',
            id: 'aDftNh',
          })}
          stepIcon={<Step2Icon />}
          done={step > 2}
        />
        <Step
          icon={<ContactBook size={18} />}
          active={step === 3}
          title={intl.formatMessage({
            defaultMessage: 'Destaca a tus proveedores',
            id: 'jeb0Rm',
          })}
          description={intl.formatMessage({
            defaultMessage:
              'Elige a los proveedores que quieras marcar como favoritos.',
            id: '7y5kAh',
          })}
          stepIcon={<Step3Icon />}
          done={step > 3}
        />
      </S.StepsContainer>
    </>
  );
};

export default SignUpStepBar;
