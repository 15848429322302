import React from 'react';
import { useIntl } from 'react-intl';

import SidebarUI from './components/Sidebar';
import Permission from '@src/components/shared/Permission';
import SidebarItem from '@src/components/navigation/Sidebar/components/SidebarItem';
import useUserFeatures from '@src/hooks/feature-flags/useUserFeatures';
import useBusinessFeatures from '@src/hooks/feature-flags/useBusinessFeatures';
import Feature from '@src/components/shared/Feature';
import useSettings from '@src/hooks/useSettings';
import userIsAdmin from '@src/utils/roles/userIsAdmin';
import { UserRole, FeatureName, PermissionName } from '@src/types/enums';

import { Factoring as FactoringIcon } from '@src/components/ui/Icon/outline';
import { ReactComponent as HomeIcon } from './assets/home.svg';
import { ReactComponent as InboxIcon } from './assets/inbox.svg';
import { ReactComponent as ToPayIcon } from './assets/to-pay.svg';
import { ReactComponent as ToChargeIcon } from './assets/to-charge.svg';
import { ReactComponent as ContactsIcon } from './assets/contacts.svg';
import { ReactComponent as HigoSuiteIcon } from './assets/higo-suite.svg';
import { ReactComponent as CreditIcon } from './assets/credit.svg';
import { ReactComponent as TransactionsIcon } from './assets/transactions.svg';
import { ReactComponent as HelpIcon } from './assets/help-circle.svg';

export const Sidebar = () => {
  const { formatMessage } = useIntl();
  const hasSuppliers = useUserFeatures([FeatureName.SUPPLIERS_LIST]);
  const hasClients = useUserFeatures([FeatureName.CLIENT_LIST]);
  const hasFactoring = useBusinessFeatures([FeatureName.FACTORING]);
  const { currentUser, business } = useSettings();
  const hasCreditActive = business?.creditAccount?.active;
  const isAdmin = userIsAdmin(currentUser);

  return (
    <SidebarUI
      footer={
        <>
          <SidebarItem
            icon={<HelpIcon />}
            label={formatMessage({
              defaultMessage: '¿Necesitas ayuda?',
              id: 'YyqOru',
            })}
            exact
            isExternal
            href="https://higo.zendesk.com/hc/es-mx"
          />
          <Permission minimumRoles={[UserRole.ADMIN]}>
            <SidebarItem
              icon={<HigoSuiteIcon />}
              label={formatMessage({
                defaultMessage: 'Higo Suite',
                id: 'Hr/ykW',
              })}
              href="/higo_suite"
            />
          </Permission>
        </>
      }
    >
      <SidebarItem
        href="/wallet"
        exact
        icon={<HomeIcon />}
        label={formatMessage({
          defaultMessage: 'Inicio',
          id: '6O+PLI',
        })}
      />
      <Permission permissions={[PermissionName.MANAGE_INVOICES_RECEIVED]}>
        <SidebarItem
          href="/inbox"
          icon={<InboxIcon />}
          label={formatMessage({
            id: 'y09zFl',
            defaultMessage: 'Buzón de entrada',
          })}
        />
      </Permission>
      <Permission permissions={[PermissionName.MANAGE_INVOICES_RECEIVED]}>
        <Feature features={[FeatureName.ACCOUNTS_PAYABLE]}>
          <SidebarItem
            href="/accounts_payable"
            icon={<ToPayIcon />}
            label={formatMessage({
              id: 'y0s+Vr',
              defaultMessage: 'Cuentas por pagar',
            })}
          />
        </Feature>
      </Permission>
      <Permission permissions={[PermissionName.MANAGE_INVOICES_EMITTED]}>
        <Feature features={[FeatureName.ACCOUNTS_RECEIVABLE]}>
          <SidebarItem
            href="/accounts_receivable"
            icon={<ToChargeIcon />}
            label={formatMessage({
              id: 'qiuiRz',
              defaultMessage: 'Cuentas por cobrar',
            })}
          />
        </Feature>
      </Permission>
      <SidebarItem
        href="/transactions"
        icon={<TransactionsIcon />}
        label={formatMessage({
          defaultMessage: 'Transacciones',
          id: 'Aqopvj',
        })}
      />

      {isAdmin && hasCreditActive && (
        <SidebarItem
          href="/credit"
          icon={<CreditIcon />}
          label={formatMessage({
            defaultMessage: 'Mi crédito',
            id: 'QCRWQM',
          })}
        />
      )}

      {hasFactoring && (
        <SidebarItem
          href="/factoring_offers"
          icon={<FactoringIcon />}
          label={formatMessage({
            defaultMessage: 'Factoraje',
            id: 'i9cV3r',
          })}
        />
      )}
      {(hasClients || hasSuppliers) && (
        <SidebarItem
          href="/contacts"
          icon={<ContactsIcon />}
          label={formatMessage({
            defaultMessage: 'Mis contactos',
            id: 'x3uPJz',
          })}
        />
      )}
    </SidebarUI>
  );
};

export default Sidebar;
