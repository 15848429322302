import { gql } from '@apollo/client';
import CLIENT_RELATIONSHIPS_ATTRIBUTES, {
  ClientRelationshipAttributes,
} from '@src/graphql/fragments/clientRelationshipAttributes';
import { PaginatedResponse } from '@src/types/utils';

const CLIENT_RELATIONSHIPS_QUERY = gql`
  query ClientRelationships(
    $businessId: ID!
    $search: String
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    clientRelationships(
      businessId: $businessId
      search: $search
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: { field: CREATED_AT, direction: DESC }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...ClientRelationshipsAttributes
        }
      }
    }
  }
  ${CLIENT_RELATIONSHIPS_ATTRIBUTES}
`;

export type ClientRelationshipsQueryReturn = {
  clientRelationships: PaginatedResponse<ClientRelationshipAttributes>;
};

export type ClientRelationshipsQueryVariables = {
  businessId: string;
  search?: string;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
};

export default CLIENT_RELATIONSHIPS_QUERY;
