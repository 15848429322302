import { gql } from '@apollo/client';

export const PAY_INVOICES_MUTATION = gql`
  mutation PayInvoices($businessId: ID!, $invoiceIds: [ID!]!) {
    payInvoices(businessId: $businessId, invoiceIds: $invoiceIds) {
      id
    }
  }
`;

export type PayInvoicesVariables = {
  businessId: string;
  invoiceIds: string[];
};

export type PayInvoicesReturn = {
  payInvoices: {
    id;
  };
};

export default PAY_INVOICES_MUTATION;
