import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { ACCEPT_USER_INVITATION } from '@src/constants/events';
import LinkButton from '@src/components/shared/buttons/LinkButton';
import useSettings from '@src/hooks/useSettings';
import ACCEPT_USER_INVITATION_MUTATION, {
  AcceptBusinessUserInvitationReturn,
  AcceptBusinessUserInvitationVariables,
} from '@src/graphql/mutations/acceptBusinessUserInvitation';
import BUSINESS_USER_INVITATION_QUERY, {
  BusinessUserInvitationReturn,
  BusinessUserInvitationVariables,
} from '@src/graphql/queries/businessUserInvitation';
import MinimalLayout from '@src/components/layouts/MinimalLayout';
import { Header, Button, Text } from '@src/components/ui';
import { Spacer } from '@src/components/shared/layouts';
import publicClient from '@src/lib/apollo/publicClient';
import * as S from './AcceptBusinessUserInvitation.styles';
import CURRENT_USER_QUERY from '@src/graphql/queries/currentUser';

type Params = {
  invitationId: string;
};

function AcceptBusinessUserInvitation() {
  const intl = useIntl();
  const { toast } = useToast();
  const history = useHistory();
  const { invitationId } = useParams<Params>();
  const { data, loading } = useQuery<
    BusinessUserInvitationReturn,
    BusinessUserInvitationVariables
  >(BUSINESS_USER_INVITATION_QUERY, {
    client: publicClient,
    variables: {
      token: invitationId,
    },
    skip: !invitationId,
    onError: () => {
      toast.error(
        intl.formatMessage({
          id: '7/ydiz',
          defaultMessage: 'Error al encontrar una invitación',
        })
      );
      history.replace('/');
    },
  });
  const { setCurrentBusinessId } = useSettings();
  const businessUserInvitation = data?.businessUserInvitation;
  const [acceptBusinessUserInvitation, { loading: submitting }] = useMutation<
    AcceptBusinessUserInvitationReturn,
    AcceptBusinessUserInvitationVariables
  >(ACCEPT_USER_INVITATION_MUTATION, {
    refetchQueries: [CURRENT_USER_QUERY],
    awaitRefetchQueries: true,
    onCompleted: () => {
      trackEvent(ACCEPT_USER_INVITATION);
      if (businessUserInvitation?.businessUser.business.id) {
        setCurrentBusinessId(businessUserInvitation?.businessUser.business.id);
      }
      history.replace('/');
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          id: 'hh4fBc',
          defaultMessage: 'Error al aceptar la invitación',
        }),
        e.message
      );
    },
  });

  const handleButtonClick = () => {
    if (businessUserInvitation?.token) {
      acceptBusinessUserInvitation({
        variables: {
          invitationToken: businessUserInvitation?.token,
        },
      });
    }
  };

  return (
    <MinimalLayout
      title={intl.formatMessage(
        {
          id: 'OxCXGr',
          defaultMessage:
            '{userName} te ha invitado a unirte a su organización {business}.',
        },
        {
          userName: businessUserInvitation?.inviterUser.firstName,
          business: businessUserInvitation?.businessUser.business.name,
        }
      )}
    >
      <S.Container>
        <div>
          <Header as="h5">
            {intl.formatMessage(
              {
                id: 'OxCXGr',
                defaultMessage:
                  '{userName} te ha invitado a unirte a su organización {business}.',
              },
              {
                userName: businessUserInvitation?.inviterUser.firstName,
                business: businessUserInvitation?.businessUser.business.name,
              }
            )}
          </Header>
          <Text colorStep="600">
            {intl.formatMessage(
              {
                id: 'GBF7Rp',
                defaultMessage: 'Al aceptar, tendrás acceso a {business}',
              },
              {
                business: businessUserInvitation?.businessUser.business.name,
              }
            )}
          </Text>
          <Spacer margin="8" />
          <Button
            block
            onClick={handleButtonClick}
            loading={submitting || loading}
          >
            {intl.formatMessage({
              id: 'g8ypIt',
              defaultMessage: 'Aceptar invitación',
            })}
          </Button>
          <Spacer />
          <LinkButton href="/" block variant="transparent">
            {intl.formatMessage({
              id: 'Z69C80',
              defaultMessage: 'Regresar a inicio',
            })}
          </LinkButton>
        </div>
      </S.Container>
    </MinimalLayout>
  );
}

export default AcceptBusinessUserInvitation;
