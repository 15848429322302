import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import useSettings from '@src/hooks/useSettings';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import Filters from '@src/components/shared/Filters';
import InvoicesTable from '@src/components/shared/InvoicesTable';
import { RESULTS_PER_PAGE } from '@src/constants/resourceList';
import EmptyResults from '@src/components/shared/EmptyResults';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { InvoiceVoucherType } from '@src/types/enums';

interface SupplierCreditNotesTableProps {
  businessEmitterId: string;
  businessEmitterName: string;
}

export function SupplierCreditNotesTable({
  businessEmitterId,
  businessEmitterName,
}: SupplierCreditNotesTableProps) {
  const intl = useIntl();
  const limit = RESULTS_PER_PAGE;
  const { business } = useSettings();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filters] = useInvoiceFilters({
    businessId: business?.id,
    businessEmitterId: [businessEmitterId],
    voucherType: [InvoiceVoucherType.CREDIT_NOTE],
    archived: false,
  });
  const {
    loading,
    data: { invoices, totalAmount },
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
    nodes,
  } = useInvoicesListQuery({
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  return (
    <>
      <Filters fields={['invoicingDate']} />
      <InvoicesTable
        columns={{
          id: 100,
          receiverCountryTaxPayerInfo: 100,
          invoicingDate: 200,
          total: 150,
        }}
        nodes={nodes}
        invoices={invoices}
        loading={loading}
        limit={limit}
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        page={page}
        count={count}
        onNext={next}
        hasNext={hasNext}
        onPrevious={previous}
        hasPrevious={hasPrevious}
        totalAmount={totalAmount}
        emptyElement={
          <EmptyResults
            label={intl.formatMessage(
              {
                defaultMessage:
                  'Aún no tienes notas de crédito con {supplierName}',
                id: 'UQ+tmm',
              },
              {
                supplierName: businessEmitterName,
              }
            )}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas las notas de crédito que tengan en Higo.',
              id: 'ia7n+6',
            })}
          />
        }
      />
    </>
  );
}

export default SupplierCreditNotesTable;
