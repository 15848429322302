import styled from 'styled-components';

export const StickyBar = styled.div`
  height: ${({ theme }) => theme.spacing[20]};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex[20]};

  padding-left: ${({ theme }) => theme.spacing[8]};
  padding-right: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const NavbarActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const NavbarRight = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;
