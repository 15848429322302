import React, { useEffect } from 'react';

import Feature from '@src/components/shared/Feature';
import AccountsPayableList from './AccountsPayableList';
import useSettings from '@src/hooks/useSettings';
import useSatBot from '@src/hooks/useSatBot';
import useModal from '@src/hooks/useModal';
import UpdateInvoiceEmailForm from './components/UpdateInvoiceEmailForm';
import Modal from '@src/components/shared/Modal';
import SuccessEmailUpdate from './components/SuccessEmailUpdate/SuccessEmailUpdate';
import Permission from '@src/components/shared/Permission';

import { FeatureName, PermissionName } from '@src/types/enums';

export function AccountsPayableListContainer() {
  const { business } = useSettings();
  const { open: openMailboxModal } = useModal(UpdateInvoiceEmailForm);
  useSatBot();

  useEffect(() => {
    if (business && !business.mailboxEmail) {
      openMailboxModal();
    }
  }, [business]);

  return (
    <Permission
      permissions={[PermissionName.MANAGE_INVOICES_RECEIVED]}
      redirect
    >
      <Feature features={[FeatureName.ACCOUNTS_PAYABLE]} redirect>
        <>
          <AccountsPayableList />

          <Modal component={UpdateInvoiceEmailForm} />
          <Modal component={SuccessEmailUpdate} />
        </>
      </Feature>
    </Permission>
  );
}

export default AccountsPayableListContainer;
