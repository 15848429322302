import React from 'react';
import { useIntl } from 'react-intl';

import IconButton from '@src/components/ui/IconButton';
import { Send } from '@src/components/ui/Icon/outline';
import Tooltip from '@src/components/ui/Tooltip';
import ResendEmailModal from '@src/components/shared/modals/ResendEmailModal';
import useModal from '@src/hooks/useModal';

interface EmailSendButtonProps {
  higoPaymentSentEntryId: string;
  defaultEmail?: string;
}

function EmailSendButton({
  higoPaymentSentEntryId,
  defaultEmail,
}: EmailSendButtonProps) {
  const intl = useIntl();
  const { open } = useModal(ResendEmailModal);
  const handleButtonClick = (event) => {
    event.stopPropagation();
    open({
      higoPaymentSentEntryId,
      defaultEmail,
    });
  };

  return (
    <Tooltip
      id={`entry-${higoPaymentSentEntryId}-send-button`}
      title={intl.formatMessage({
        defaultMessage: 'Reenviar comprobante',
        id: '2NMTCx',
      })}
      place="bottom"
      effect="solid"
    >
      <IconButton variant="ghosted" onClick={handleButtonClick}>
        <Send size={20} />
      </IconButton>
    </Tooltip>
  );
}

export default EmailSendButton;
