import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './InvoiceResult.styles';
import Result from '@src/components/shared/GlobalSearch/components/Result';
import { Currency } from '@src/components/i18n';
import InvoiceGlobalStatusBadge from '@src/components/shared/InvoiceGlobalStatusBadge';
import { Tag } from '@src/components/ui';

import {
  InvoiceApprovalStatus,
  InvoicePaymentStatus,
  InvoiceGlobalStatus,
} from '@src/types/enums';

interface InvoiceResultProps {
  total: number;
  supplierName: string;
  approvalStatus: keyof typeof InvoiceApprovalStatus;
  globalStatus: keyof typeof InvoiceGlobalStatus;
  paymentStatus: keyof typeof InvoicePaymentStatus;
  expiryDate: string;
  href: string;
  onClick: () => void;
}

const InvoiceResult = ({
  total,
  supplierName,
  approvalStatus,
  paymentStatus,
  globalStatus,
  expiryDate,
  href,
  onClick,
}: InvoiceResultProps) => {
  const intl = useIntl();
  let tag;
  if (approvalStatus === InvoiceApprovalStatus.PENDING) {
    tag = (
      <Tag
        color="warning"
        label={intl.formatMessage({
          defaultMessage: 'Por aprobar',
          id: 'djFdu3',
        })}
        compact
      />
    );
  } else if (
    approvalStatus === InvoiceApprovalStatus.APPROVED &&
    [InvoicePaymentStatus.PENDING, InvoicePaymentStatus.PARTIAL].includes(
      paymentStatus as InvoicePaymentStatus
    )
  ) {
    tag = (
      <InvoiceGlobalStatusBadge expiryDate={expiryDate} status={globalStatus} />
    );
  } else if (paymentStatus === InvoicePaymentStatus.PAID) {
    tag = (
      <Tag
        color="success"
        label={intl.formatMessage({
          defaultMessage: 'Pagada',
          id: 'AvWWd0',
        })}
        compact
      />
    );
  }

  return (
    <Result href={href} onClick={onClick}>
      <S.InvoiceResult>
        <div className="result-invoice-data">
          <p className="result-invoice-title">
            <Currency value={total} />
          </p>
          <p className="result-invoice-description">{supplierName}</p>
        </div>
        <div className="result-invoice-tag">{tag}</div>
      </S.InvoiceResult>
    </Result>
  );
};

export default InvoiceResult;
