import styled from 'styled-components';

export const BalanceContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing[7]};

  span {
    font-size: ${({ theme }) => theme.fontSize['3xl']};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-bottom: 0;
  }
`;

export const Section = styled.div`
  padding: ${({ theme }) => theme.spacing[6]} ${({ theme }) => theme.spacing[8]};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  p {
    margin: 0;
  }
  strong {
    color: ${({ theme }) => theme.colors.neutral[900]};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Container = styled.div`
  width: 32rem;

  > button {
    margin-top: ${({ theme }) => theme.spacing[6]};
  }
`;

export const Header = styled.div`
  margin: ${({ theme }) => theme.spacing[6]} 0
    ${({ theme }) => theme.spacing[4]} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
  }
`;
