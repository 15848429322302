import styled, { DefaultTheme } from 'styled-components';
import { TagProps } from './types';

const getColor = (theme: DefaultTheme, color: string) => {
  const combinations = {
    primary: {
      backgroundColor: theme.colors.primary[50],
      color: theme.colors.primary[400],
    },
    success: {
      backgroundColor: theme.colors.success[50],
      color: theme.colors.success[500],
    },
    warning: {
      backgroundColor: theme.colors.warning[50],
      color: theme.colors.warning[600],
    },
    danger: {
      backgroundColor: theme.colors.danger[50],
      color: theme.colors.danger[500],
    },
    info: {
      backgroundColor: theme.colors.info[50],
      color: theme.colors.info[500],
    },
    dark: {
      backgroundColor: theme.colors.primary[900],
      color: theme.colors.neutral[50],
    },
    gray: {
      backgroundColor: theme.colors.neutral[200],
      color: theme.colors.neutral[600],
    },
    light: {
      backgroundColor: theme.colors.neutral[50],
      color: theme.colors.primary[300],
    },
    secondary: {
      backgroundColor: theme.colors.secondary[200],
      color: theme.colors.neutral[900],
    },
  };
  return {
    backgroundColor: combinations[color].backgroundColor,
    color: combinations[color].color,
  };
};

export const StyledTag = styled.div<TagProps>`
  display: inline flex;
  align-items: center;
  background-color: ${({ color = 'primary', theme }) =>
    getColor(theme, color).backgroundColor};
  color: ${({ color = 'primary', theme }) => getColor(theme, color).color};
  padding: 0
    ${({ compact, theme }) => (compact ? theme.spacing[4] : theme.spacing[6])};
  border-radius: ${({ compact, theme }) =>
    compact ? theme.spacing[3.5] : theme.spacing[4]};
  font-size: ${({ compact, theme }) =>
    compact ? theme.fontSize.xs : theme.fontSize.md};
  font-weight: ${({ compact, theme }) =>
    compact ? theme.fontWeight.regular : theme.fontWeight.regular};
  height: ${({ compact, theme }) =>
    compact ? theme.spacing[6] : theme.spacing[8]};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  align-items: center;
  justify-content: center;

  .tag-close {
    background-color: transparent;
    height: ${({ theme, compact }) =>
      compact ? theme.spacing[4] : theme.spacing[5]};
    width: ${({ theme, compact }) =>
      compact ? theme.spacing[4] : theme.spacing[5]};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    margin-left: ${({ theme }) => theme.spacing[3]};
    padding: 0;

    &:hover {
      background-color: ${({ color = 'primary', theme }) =>
        getColor(theme, color).color + theme.opacityHex[10]};
    }
  }

  .tag-icon {
    display: flex;
    align-items: center;
    height: ${({ theme, compact }) =>
      compact ? theme.spacing[3.5] : theme.spacing[4]};
    width: ${({ theme, compact }) =>
      compact ? theme.spacing[3.5] : theme.spacing[4]};
  }

  .button-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: ${({ theme, compact }) =>
      compact ? theme.fontSize.sm : theme.fontSize.lg};};
  }

  .tag-icon + .button-label,
  .button-label + .tag-icon {
    margin-left: ${({ theme }) => theme.spacing[2]};
  }

  &:active {
    transform: ${({ onClick }) => (onClick ? 'scale(0.99)' : 'scale(1)')};
  }
`;
