import React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

import * as S from './BankingInformationForm.styles';
import { TextField, Button } from '@src/components/ui';
import Bank from '@src/components/shared/Bank';
import useBanksQuery from '@src/hooks/useBanksQuery';
import CLABEField from '@src/components/shared/react-hook-fields/CLABEField';
import { trackEvent } from '@src/lib/analytics';
import { BANKING_INFO_FAILED_VALIDATION } from '@src/constants/events';

interface BankingInformationFormProps {
  loading?: boolean;
  defaultValues?: {
    clabe?: string;
    bankId?: string;
    recipient?: string;
  };
  onCancel?: () => void;
  onSubmit: (data: {
    bankId: string;
    clabe: string;
    recipient?: string;
    bankName?: string;
    bankLogoUrl?: string;
  }) => void;
}

type FormData = {
  bankId: string;
  clabe: string;
};

function BankingInformationForm({
  loading,
  defaultValues,
  onSubmit: onSubmitProp,
  onCancel,
}: BankingInformationFormProps) {
  const intl = useIntl();
  const { data: bankData } = useBanksQuery();

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
    watch,
    setValue,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const bankId = watch('bankId');
  const bank = bankData?.banks.find((bank) => bank.id === bankId);

  const handleCLABEBlur = (event) => {
    const value = event?.target?.value?.replace(/(\s_)/g, '').trim();
    if (value && value.length === 18) {
      trackEvent(BANKING_INFO_FAILED_VALIDATION, {
        type: errors?.clabe?.type,
        value,
      });
    }
  };

  const onSubmit = (data: FormData) => {
    onSubmitProp({
      clabe: data.clabe,
      bankId: data.bankId,
      bankName: bank?.name,
      bankLogoUrl: bank?.logoUrl,
      recipient: defaultValues?.recipient,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {defaultValues && !defaultValues.recipient && (
        <TextField
          fullWidth
          type="text"
          required
          {...register('recipient', {
            required: intl.formatMessage({
              defaultMessage: 'Campo requerido',
              id: '7Vvfe3',
            }),
          })}
          label={intl.formatMessage({
            defaultMessage: 'Beneficiario',
            id: 'dtkaPZ',
          })}
          placeholder="Acme Inc"
          error={!!errors?.recipient?.message}
          helperText={errors?.recipient?.message}
        />
      )}

      <CLABEField
        control={control}
        onBankChange={(id) => setValue('bankId', id)}
        onBlur={handleCLABEBlur}
      />

      <Bank name={bank?.name} logoUrl={bank?.logoUrl} />

      <S.Actions>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined" color="neutral">
            {intl.formatMessage({
              defaultMessage: 'Cancelar',
              id: 'nZLeaQ',
            })}
          </Button>
        )}
        <Button disabled={!isValid} loading={loading} type="submit">
          {intl.formatMessage({
            defaultMessage: 'Guardar',
            id: 'oN4aGu',
          })}
        </Button>
      </S.Actions>
    </form>
  );
}

BankingInformationForm.displayName = 'BankingInformationForm';

export default BankingInformationForm;
