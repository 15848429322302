import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './HistoricalRecord.styles';
import messages from './messages';
import FormattedDate, {
  defaultTimeFormat,
} from '@src/components/i18n/FormattedDate';
import Currency from '@src/components/i18n/Currency';

import {
  COST_CENTER_EVENT,
  INVOICE_STATUS_EVENT,
  COMMENT_EVENT,
  INVOICE_MANUAL_PAYMENT_EVENT,
  INVOICE_RECEIVED_EVENT,
  INVOICE_STP_PAYMENT_EVENT,
} from '@src/constants/historicalRecord';
import { Divider, Label, Text } from '@src/components/ui';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { InvoiceApprovalStatusEnum } from '@src/generated/graphql';
import { RecordNotificationInvoiceFragment } from './__graphql__/recordNotificationInvoice.fragment';
import { RecordNotificationEventableFragment } from './__graphql__/recordNotificationEventable.fragment';
import { RecordNotificationUserFragment } from './__graphql__/recordNotificationUser.fragment';

interface RecordNotificationProps {
  invoice: RecordNotificationInvoiceFragment;
  createdAt: string;
  eventable: RecordNotificationEventableFragment;
  user?: RecordNotificationUserFragment | null;
}

const getTitleId = (eventable: RecordNotificationEventableFragment) => {
  switch (eventable.__typename) {
    case COST_CENTER_EVENT:
      return COST_CENTER_EVENT;
    case INVOICE_STATUS_EVENT:
      if (eventable.status === InvoiceApprovalStatusEnum.PENDING_REVISION)
        return InvoiceApprovalStatusEnum.PENDING_REVISION;
      if (eventable.status === InvoiceApprovalStatusEnum.PENDING)
        return InvoiceApprovalStatusEnum.PENDING;
      if (eventable.status === InvoiceApprovalStatusEnum.APPROVED)
        return InvoiceApprovalStatusEnum.APPROVED;
      if (eventable.status === InvoiceApprovalStatusEnum.EDITED)
        return InvoiceApprovalStatusEnum.EDITED;
      if (eventable.status === InvoiceApprovalStatusEnum.FINANCE_REQUESTED)
        return InvoiceApprovalStatusEnum.FINANCE_REQUESTED;
      return InvoiceApprovalStatusEnum.REJECTED;
    case INVOICE_RECEIVED_EVENT:
      return 'RECEIVED_EVENT';
    case INVOICE_STP_PAYMENT_EVENT:
      return INVOICE_STP_PAYMENT_EVENT;
    default:
      return INVOICE_MANUAL_PAYMENT_EVENT;
  }
};

const RecordNotification = ({
  invoice,
  createdAt,
  eventable,
  user,
}: RecordNotificationProps) => {
  const intl = useIntl();
  return (
    <>
      {eventable?.__typename === COMMENT_EVENT ? (
        <S.Comment>
          <FlexContainer justifyContent="space-between">
            <Label strong size="lg">
              {user ? `${user.firstName} ${user.lastName}` : '-'}
            </Label>
            <Text colorStep="600">
              <FormattedDate value={createdAt} {...defaultTimeFormat} />
            </Text>
          </FlexContainer>
          <Spacer />
          <div>
            <Text>
              {eventable.businessUsers?.map((user) => (
                <strong
                  key={user.id}
                >{`${user.firstName} ${user.lastName}, `}</strong>
              ))}
              {eventable.comment}
            </Text>
          </div>
        </S.Comment>
      ) : (
        <div>
          <FlexContainer justifyContent="space-between">
            <Label strong size="lg">
              {messages[getTitleId(eventable)] &&
                intl.formatMessage(messages[getTitleId(eventable)])}
            </Label>
            <Text colorStep="600">
              <FormattedDate value={createdAt} {...defaultTimeFormat} />
            </Text>
          </FlexContainer>

          <Spacer />

          {eventable?.__typename === COST_CENTER_EVENT && (
            <div>
              <span>
                {intl.formatMessage(
                  {
                    id: 'LttvtB',
                    defaultMessage: '{user} ha configurado la factura',
                  },
                  {
                    user: user && `${user.firstName} ${user.lastName}`,
                  }
                )}{' '}
              </span>
              <strong>{`No. ${invoice?.id}`}</strong>{' '}
              <span>
                {intl.formatMessage({
                  id: 'Y1rnvc',
                  defaultMessage: 'al centro de costos',
                })}{' '}
                <b>{eventable.costCenter && eventable.costCenter.name}</b>{' '}
                {intl.formatMessage({
                  id: 'IoVsc5',
                  defaultMessage: 'exitosamente.',
                })}
              </span>
            </div>
          )}

          {eventable?.__typename === INVOICE_STATUS_EVENT && (
            <div>
              <span>
                {intl.formatMessage(
                  {
                    id: 'cQwvqa',
                    defaultMessage: '{user} ha {messageStatus} la factura',
                  },
                  {
                    user: user && `${user.firstName} ${user.lastName}`,
                    messageStatus:
                      eventable.status &&
                      messages[`${eventable.status}_message`] &&
                      intl.formatMessage(
                        messages[`${eventable.status}_message`]
                      ),
                  }
                )}
              </span>{' '}
              <strong>{`No. ${invoice?.id}`}</strong>{' '}
              <span>
                {intl.formatMessage({
                  id: 'IoVsc5',
                  defaultMessage: 'exitosamente.',
                })}
              </span>
              {eventable?.status === InvoiceApprovalStatusEnum.REJECTED && (
                <p>
                  <strong>
                    {intl.formatMessage({
                      id: 'BdwPyO',
                      defaultMessage: 'Motivo: ',
                    })}{' '}
                  </strong>
                  {invoice.cancelationReason && invoice.cancelationReason}
                </p>
              )}
              {eventable?.status === InvoiceApprovalStatusEnum.EDITED && (
                <>
                  <Spacer />
                  <div>
                    <FlexContainer>
                      <S.Label>
                        {intl.formatMessage({
                          id: '/KjIbx',
                          defaultMessage: 'Términos de pago: ',
                        })}
                      </S.Label>
                      <span>{eventable?.paymentTerms}</span>
                    </FlexContainer>

                    <FlexContainer>
                      <S.Label>
                        {intl.formatMessage({
                          id: 'H0InJh',
                          defaultMessage: 'Fecha de vencimiento: ',
                        })}
                      </S.Label>

                      <span>
                        {eventable.expiryDate && (
                          <FormattedDate value={eventable.expiryDate} />
                        )}
                      </span>
                    </FlexContainer>
                  </div>
                </>
              )}
            </div>
          )}

          {eventable?.__typename === INVOICE_MANUAL_PAYMENT_EVENT && (
            <div>
              <span>
                {intl.formatMessage(
                  {
                    id: 'PlydQ/',
                    defaultMessage: '{user} ha adjuntado un pago manual por ',
                  },
                  {
                    user: user && `${user.firstName} ${user.lastName}`,
                  }
                )}{' '}
                <strong>
                  <Currency
                    value={eventable.amount}
                    currency={invoice.currency}
                  />
                </strong>{' '}
                {intl.formatMessage({
                  id: 'CXT/V9',
                  defaultMessage: 'a la factura',
                })}{' '}
              </span>
              <strong>{`No. ${invoice.id}`}</strong>
              {'. '}
            </div>
          )}

          {eventable?.__typename === INVOICE_RECEIVED_EVENT && (
            <div>
              <span>
                {intl.formatMessage({
                  id: 'bUFGds',
                  defaultMessage: 'La factura',
                })}
              </span>{' '}
              <strong>{`No. ${invoice.id}`}</strong>{' '}
              <span>
                {intl.formatMessage({
                  id: 'fTY/Nw',
                  defaultMessage: 'ha sido recibida exitosamente.',
                })}
              </span>
            </div>
          )}

          {eventable?.__typename === INVOICE_STP_PAYMENT_EVENT && (
            <div>
              <strong>
                {eventable.walletEntry &&
                  `${eventable.walletEntry?.user?.firstName} ${eventable.walletEntry?.user?.lastName}`}
              </strong>{' '}
              <span>
                {intl.formatMessage({
                  id: 'YSVl5K',
                  defaultMessage: 'envió',
                })}
              </span>{' '}
              <strong>
                {eventable.walletEntry?.amount && (
                  <Currency
                    value={Math.abs(eventable.walletEntry.amount)}
                    currency={invoice.currency}
                  />
                )}
              </strong>{' '}
              <span>
                {intl.formatMessage({
                  id: 'LpXfLT',
                  defaultMessage: 'como pago de la factura',
                })}
              </span>{' '}
              <strong>{`No. ${invoice.id}`}</strong>{' '}
              <span>
                {intl.formatMessage({
                  id: 'IoVsc5',
                  defaultMessage: 'exitosamente.',
                })}
              </span>
              {eventable.walletEntry?.recipient && (
                <>
                  <Spacer />
                  <div>
                    <div>
                      <S.Label>
                        {intl.formatMessage({
                          id: 'on5q0X',
                          defaultMessage: 'Clabe beneficiario: ',
                        })}{' '}
                      </S.Label>
                      <span>{eventable.walletEntry.recipient.clabe}</span>
                    </div>
                    <div>
                      <S.Label>
                        {intl.formatMessage({
                          id: 'AYBw0W',
                          defaultMessage: 'Banco: ',
                        })}{' '}
                      </S.Label>
                      <span>{eventable.walletEntry.recipient.bankName}</span>
                    </div>
                    <div>
                      <S.Label>
                        {intl.formatMessage({
                          id: 'kLqXGQ',
                          defaultMessage: 'Titular: ',
                        })}{' '}
                      </S.Label>
                      <span>{eventable.walletEntry.recipient.recipient}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Divider />
    </>
  );
};

export default RecordNotification;
