import { gql } from '@apollo/client';

export const REQUEST_FACTORING = gql`
  mutation RequestFactoring($businessId: ID!, $factoringOffersIds: [ID!]!) {
    acceptFactoringOffers(
      businessId: $businessId
      factoringOffersIds: $factoringOffersIds
    ) {
      id
      acceptedAt
    }
  }
`;

export type RequestFactoringQueryReturn = {
  id: string;
};

export type RequestFactoringQueryVariables = {
  businessId: string;
  factoringOffersIds: Array<string>;
};
