import { useMutation } from '@apollo/client';
import Breadcrumb from '@src/components/shared/Breadcrumb';
import Page from '@src/components/shared/Page';
import { FlexContainer, Grid } from '@src/components/shared/layouts';
import { ToggleItem } from '@src/components/pages/Integrations/components/ToggleItem/ToggleItem';
import { Button, Divider, Header, Text } from '@src/components/ui';
import { ManualSyncButton } from './components/ManualSyncButton';
import Card from '@src/components/ui/Card';
import {
  updateCodatDataSourceMutationReturn,
  updateCodatDataSourceMutationVariables,
  UPDATE_CODAT_DATA_SOURCE_MUTATION,
} from '@src/graphql/mutations/updateCodatDataSource';
import useSettings from '@src/hooks/useSettings';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { DELETE_CODAT_DATASOURCE } from '@src/graphql/mutations';

const IntegrationsMenu = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const {
    platformName,
    connectionId,
    autoSyncBillsInit,
    codatDataSourceId,
  } = location.state;
  const intl = useIntl();
  const { business } = useSettings();
  const [updateCodatDataSource] = useMutation<
    updateCodatDataSourceMutationReturn,
    updateCodatDataSourceMutationVariables
  >(UPDATE_CODAT_DATA_SOURCE_MUTATION);

  const [deleteCodatDataSource] = useMutation(DELETE_CODAT_DATASOURCE);

  const [autoSyncBills, setAutoSyncBills] = useState<boolean>(
    autoSyncBillsInit
  );

  const platform = {
    quickBooks: 'Quickbooks',
    quickBooksSandbox: 'Quickbooks Sandbox',
    netsuite: 'Netsuite',
  };

  const handleDisconnect = async () => {
    await deleteCodatDataSource({
      variables: {
        businessId: business?.id,
        codatDataSourceId,
      },
    });
    history.push({
      pathname: '/settings/integrations',
    });
  };
  const handleUpdateAutoSyncBills = (connectionId, autoSyncBills) => {
    if (connectionId && business?.id) {
      updateCodatDataSource({
        variables: {
          businessId: business?.id,
          codatConnectionId: connectionId,
          autoSyncBills: autoSyncBills,
        },
      });
    }
  };

  useEffect(() => {
    if (autoSyncBills !== undefined) {
      handleUpdateAutoSyncBills(connectionId, autoSyncBills);
    }
  }, [autoSyncBills]);

  return (
    <Page title={platform[platformName]}>
      <Grid container direction="row" justifyContent="center">
        <Grid xs={8} lg={10}>
          <Breadcrumb
            links={[
              {
                url: '/settings/integrations',
                name: intl.formatMessage({
                  id: 'q400kG',
                  defaultMessage: 'Regresar',
                }),
              },
            ]}
          />
          <FlexContainer flexDirection="row" alignItems="center">
            <Header as="h4">{`${
              platformName[0]?.toUpperCase() + platformName?.substring(1)
            }`}</Header>
            <ManualSyncButton
              businessId={business?.id}
              codatConnectionId={connectionId}
            />
          </FlexContainer>
          <Card padding="8">
            <Header as="h4">
              {intl.formatMessage({
                id: '0fnUg6',
                defaultMessage: 'Conexión',
              })}
            </Header>
            <Divider margin="8" />
            <Header as="h4">
              {intl.formatMessage({
                id: '/4ZkDY',
                defaultMessage: '¿Cómo funciona?',
              })}
              <Text color="neutral" colorStep="500">
                {intl.formatMessage(
                  {
                    id: '50GH/J',
                    defaultMessage: `Integrándote con Higo puedes intercambiar información enviando 
                y recibiendo datos desde y hacia {platform}. Puedes llevar un control automatizado
                sobre los pagos, facturas y proveedores generados en Higo.`,
                  },
                  {
                    platform: platform[platformName],
                  }
                )}
              </Text>
            </Header>
            <Divider margin="8" />
            <ToggleItem
              iconName="Diploma"
              checked={autoSyncBills}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAutoSyncBills(event.target.checked);
              }}
              id="crearFacturas"
              label={intl.formatMessage(
                {
                  id: '3PAGUZ',
                  defaultMessage:
                    'Crear facturas y proveedores en {platform} desde Higo',
                },
                {
                  platform: platform[platformName],
                }
              )}
              text={intl.formatMessage(
                {
                  id: 'iezhle',
                  defaultMessage: `Una vez se sincronizan los proveedores que te han 
              emitido facturas en Higo desde el SAT, se crearan en automático dentro 
              de {platform} al igual que sus facturas.`,
                },
                {
                  platform: platform[platformName],
                }
              )}
            />
            <ToggleItem
              iconName="User"
              disabled
              checked={false}
              onChange={(state) => console.log(state)}
              id="crearProveedores"
              label={intl.formatMessage(
                {
                  id: 'XbSU7Y',
                  defaultMessage: 'Crear proveedores en Higo desde {platform}',
                },
                {
                  platform: platform[platformName],
                }
              )}
              text={intl.formatMessage(
                {
                  id: 'iVpXbM',
                  defaultMessage:
                    'Los proveedores que crees en {platform} se crearan automaticamente en Higo.',
                },
                { platform: platform[platformName] }
              )}
            />
            <ToggleItem
              iconName="CheckFile"
              disabled
              checked={false}
              onChange={(state) => console.log(state)}
              id="crearInstrucciones"
              label={intl.formatMessage(
                {
                  id: 'x9KfIA',
                  defaultMessage:
                    'Crear instrucciones de pago desde Higo en {platform}.',
                },
                { platform: platform[platformName] }
              )}
              text={intl.formatMessage(
                {
                  id: 'Y+4jhZ',
                  defaultMessage:
                    'Registra en {platform} los pagos hechos en Higo aunque no tengas una factura asociada.',
                },
                { platform: platform[platformName] }
              )}
            />

            <ToggleItem
              iconName="Send"
              disabled
              checked={false}
              onChange={(state) => console.log(state)}
              id="marcarComoPagado"
              label={intl.formatMessage(
                {
                  id: 'XG4oZw',
                  defaultMessage:
                    'Marcar como pagadas en Higo usando la información de {platform}',
                },
                { platform: platform[platformName] }
              )}
              text={intl.formatMessage(
                {
                  id: 'yIPMbM',
                  defaultMessage:
                    'Marca como pagado en Higo todas las facturas que ya tienes pagadas en Quickbooks.',
                },
                { platform: platformName }
              )}
            />
            <Divider margin="8" />
            <FlexContainer justifyContent="space-between" flexDirection="row">
              <Header as="h4">
                {intl.formatMessage({
                  id: 'om++GQ',
                  defaultMessage: 'Remover integración',
                })}
              </Header>
              <Button
                color="danger"
                variant="outlined"
                onClick={handleDisconnect}
              >
                {intl.formatMessage({
                  id: 'w8vWqr',
                  defaultMessage: 'Desconectar',
                })}
              </Button>
            </FlexContainer>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default IntegrationsMenu;
