import React from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { TextField, Button } from '@src/components/ui';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_USER_PROFILE as UPDATE_USER_PROFILE_EVENT } from '@src/constants/events';
import {
  UpdateUserProfileReturn,
  UpdateUserProfileVariables,
  UPDATE_USER_PROFILE,
} from '@src/graphql/mutations';

export type ProfileSettingsFormData = {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
};

export type ProfileSettingsFormProps = {
  defaultValues: ProfileSettingsFormData;
};

export const eventData = {
  creator: 'admin',
  view: 'SettingsProfile',
};

export function ProfileSettingsForm(props: ProfileSettingsFormProps) {
  const { defaultValues } = props;
  const intl = useIntl();
  const { toast } = useToast();
  const [updateUserProfile, { loading }] = useMutation<
    UpdateUserProfileReturn,
    UpdateUserProfileVariables
  >(UPDATE_USER_PROFILE);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileSettingsFormData>({
    mode: 'onChange',
    defaultValues: {
      email: defaultValues?.email,
      firstName: defaultValues?.firstName,
      lastName: defaultValues?.lastName,
      phone: defaultValues?.phone,
    },
  });

  const onSubmit = async (data) => {
    try {
      await updateUserProfile({
        variables: {
          user: {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
          },
        },
      });

      trackEvent(UPDATE_USER_PROFILE_EVENT, eventData);

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Perfil actualizado',
          id: 'n3SUeV',
        }),
        intl.formatMessage({
          defaultMessage: 'Se ha actualizado la información de perfil.',
          id: 'fXKzlK',
        })
      );
    } catch (e) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar perfil',
          id: 'mDR51j',
        }),
        e.message
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        {...register('email', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Correo electrónico',
          id: 'pPCiwO',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'email@ejemplo.com',
          id: '+X9P87',
        })}
        type="email"
        fullWidth
        error={!!errors?.email?.message}
        helperText={errors?.email?.message}
      />
      <TextField
        {...register('firstName', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Nombre',
          id: 'hCOqfl',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'Juan',
          id: 'Lw+19l',
        })}
        type="text"
        fullWidth
        error={!!errors?.firstName?.message}
        helperText={errors?.firstName?.message}
      />
      <TextField
        {...register('lastName', {
          required: intl.formatMessage({
            defaultMessage: 'Campo requerido',
            id: '7Vvfe3',
          }),
        })}
        label={intl.formatMessage({
          defaultMessage: 'Apellidos',
          id: 'nVSFBU',
        })}
        placeholder={intl.formatMessage({
          defaultMessage: 'Valdez Valdivia',
          id: 'qqppyC',
        })}
        type="text"
        fullWidth
        error={!!errors?.lastName?.message}
        helperText={errors?.lastName?.message}
      />
      <TextField
        {...register('phone')}
        label={intl.formatMessage({
          defaultMessage: 'Teléfono',
          id: 'S6W4PT',
        })}
        placeholder="+52 55 6633 7890"
        type="number"
        fullWidth
        error={!!errors?.phone?.message}
        helperText={errors?.phone?.message}
      />
      <div>
        <Button type="submit" loading={loading} block>
          {intl.formatMessage({
            defaultMessage: 'Actualizar perfil',
            id: 'mOjs8T',
          })}
        </Button>
      </div>
    </form>
  );
}

export default ProfileSettingsForm;
