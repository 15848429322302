import { gql } from '@apollo/client';
import { Invoice } from '@src/types/models';

const CREATE_INVOICES_HIGO_PAYMENTS_MUTATION = gql`
  mutation CreateInvoicesHigoPayments(
    $businessId: ID!
    $invoiceHigoPayments: [InvoiceHigoPaymentInput!]!
  ) {
    createInvoicesHigoPayments(
      businessId: $businessId
      invoiceHigoPayments: $invoiceHigoPayments
    ) {
      id
    }
  }
`;

export type InvitedUserInputType = {
  email: string;
  firstName?: string;
  lastName?: string;
};

export type InvoiceHigoPaymentInputType = {
  invoiceId: string;
  amount: number;
  paymentConcept?: string;
  users?: Array<InvitedUserInputType>;
};

export type CreateInvoicesHigoPaymentsParams = {
  businessId: string;
  invoiceHigoPayments: Array<InvoiceHigoPaymentInputType>;
};

export type CreateInvoicesHigoPaymentsReturn = Array<Pick<Invoice, 'id'>>;

export default CREATE_INVOICES_HIGO_PAYMENTS_MUTATION;
