import React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  Button,
  Header,
  IconButton,
  Alert,
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TextField,
} from '@src/components/ui';
import * as S from './EnterManualPayment.styles';
import { useMutation } from '@apollo/client';
import UPDATE_INVOICES_PAYMENTS_MUTATION, {
  UpdateInvoicePaymentsReturn,
  UpdateInvoicePaymentsVariables,
} from '@src/graphql/mutations/updateInvoicePayment';
import INVOICE_QUERY from '@src/graphql/queries/invoice';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';
import INVOICE_PAYMENTS_QUERY from '@src/graphql/queries/invoicePayments';
import { stringToCents } from '@src/utils/currency';
import useToast from '@src/hooks/useToast';
import MultiEmailSelect from '@src/components/shared/select/MultiEmailSelect';
import { parseCurrency } from '@src/components/ui/CurrencyInput/CurrencyInput';
import {
  INVOICE_MANUALLY_PAID,
  UPDATE_INVOICE_PAYMENTS,
} from '@src/constants/events';
import { trackEvent } from '@src/lib/analytics';
import InvoicePayments from '../InvoicePayments';
import useModal from '@src/hooks/useModal';
import { FlexContainer, Spacer } from '@src/components/shared/layouts';
import { Plus } from '@src/components/ui/Icon/outline';
import FilePicker from '@src/components/shared/FilePicker';
import { Currency } from '@src/components/i18n/Currency';
import { Trash2 } from '@src/components/ui/Icon/outline';
import useSettings from '@src/hooks/useSettings';

import { Invoice, InvoicePayment } from '@src/types/models';
import { InvoicePaymentType } from '@src/types/enums';
import { HistoricalRecordAccountPayableEventsDocument } from '../HistoricalRecord/__graphql__/historicalRecordAccountPayableEvents.query';

interface EnterManualPaymentProps {
  invoice: Pick<
    Invoice,
    | 'id'
    | 'businessReceiver'
    | 'businessEmitter'
    | 'businessRelationship'
    | 'paymentStatus'
    | 'total'
    | 'outstandingBalance'
    | 'currency'
  >;
  onClose?: () => void;
}

type InvoicePaymentField = InvoicePayment & {
  amount: number;
};

function EnterManualPayment({ invoice, onClose }: EnterManualPaymentProps) {
  const intl = useIntl();
  const { business } = useSettings();
  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      payments: [] as InvoicePaymentField[],
      providerContacts: [] as string[],
    },
  });
  const { fields, remove, append } = useFieldArray({
    control,
    keyName: 'key',
    name: 'payments',
  });
  const { open: openInvoicePayments } = useModal(InvoicePayments);
  const { toast } = useToast();
  const [updateInvoicePayments, { loading }] = useMutation<
    UpdateInvoicePaymentsReturn,
    UpdateInvoicePaymentsVariables
  >(UPDATE_INVOICES_PAYMENTS_MUTATION, {
    refetchQueries: [
      HistoricalRecordAccountPayableEventsDocument,
      INVOICE_QUERY,
      INVOICE_PAYMENTS_QUERY,
      SUPPLIER_RELATIONSHIP_QUERY_NAME,
    ],
    onCompleted: () => {
      trackEvent(UPDATE_INVOICE_PAYMENTS, {
        creator: 'emitter',
        view: 'InvoiceView',
      });
      trackEvent(INVOICE_MANUALLY_PAID);
      toast.success(
        intl.formatMessage({
          defaultMessage: 'Factura actualizada',
          id: 'FZAoOc',
        }),
        intl.formatMessage(
          {
            defaultMessage:
              'El estatus de pago de la factura {invoiceId} ha sido actualizado.',
            id: 'mkY+va',
          },
          {
            invoiceId: invoice.id,
          }
        )
      );
    },
    onError: (e) => {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'Error al actualizar el estado de la factura',
          id: 'VcpAgx',
        }),
        e.message
      );
    },
  });

  const options =
    invoice?.businessRelationship?.providerContacts?.map(
      (contact) => contact.contactable.email
    ) || [];

  const onSubmit = async (data) => {
    if (invoice.id && business?.id) {
      updateInvoicePayments({
        variables: {
          businessId: business.id,
          invoiceId: invoice.id,
          invoicePayments: data.payments.map((payment) => ({
            amount: stringToCents(payment.amount),
            paymentProof: payment.paymentProof || '',
            paymentType: InvoicePaymentType.MANUAL,
          })),
          providerContacts:
            data.providerContacts?.map((email) => ({
              email,
            })) || [],
        },
      });
    }
  };

  const handleCancelClick = () => {
    openInvoicePayments({
      invoice,
    });
    onClose?.();
  };

  const payments = watch('payments');
  const appliedCents = payments.reduce(
    (acc, item) => acc + stringToCents(item.amount || 0),
    0
  );
  const pendingBalanceCents = invoice.outstandingBalance - appliedCents;

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Registrar pago parcial',
          id: 'yjlWwQ',
        })}
      </Header>
      <Spacer />
      <Alert color={pendingBalanceCents < 0 ? 'danger' : 'info'}>
        <FlexContainer justifyContent="space-between">
          <p>
            {intl.formatMessage({
              defaultMessage: 'Saldo pendiente',
              id: 'h0TyH1',
            })}
          </p>
          <p>
            <Currency
              value={pendingBalanceCents || 0}
              currency={invoice.currency}
            />
          </p>
        </FlexContainer>
      </Alert>

      <Spacer />

      <Table columnsWidth={['40%', '45%', '15%']}>
        <TableHeader>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Valor',
              id: 'xoYWAa',
            })}
          </TableHeaderCell>
          <TableHeaderCell>
            {intl.formatMessage({
              defaultMessage: 'Comprobante de pago',
              id: 'Y/vOdM',
            })}{' '}
            {intl.formatMessage({
              defaultMessage: '(Opcional)',
              id: 'fiJ84Y',
            })}
          </TableHeaderCell>
          <TableHeaderCell />
        </TableHeader>
        <tbody>
          {fields.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  fullWidth
                  type="currency"
                  placeholder="0.00"
                  {...register(`payments.${index}.amount`, {
                    required: intl.formatMessage({
                      defaultMessage: 'Campo requerido',
                      id: '7Vvfe3',
                    }),
                    setValueAs: (value) => parseCurrency(`${value}`),
                  })}
                  error={!!errors?.payments?.[index]?.amount?.message}
                  helperText={errors?.payments?.[index]?.amount?.message}
                  disabled={!!item?.id}
                />
              </TableCell>
              <TableCell>
                <Controller
                  control={control}
                  name={`payments.${index}.paymentProof`}
                  render={({ field }) => (
                    <FilePicker
                      disabled={!!item?.id}
                      accept=".jpg, .jpeg, .png, .pdf"
                      {...field}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <IconButton
                  compact
                  rounded
                  color="neutral"
                  variant="transparent"
                  onClick={() => remove(index)}
                >
                  <Trash2 size={20} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>

      <Spacer />

      <FlexContainer justifyContent="center">
        <Button
          onClick={() => append({})}
          startIcon={<Plus />}
          variant="transparent"
        >
          {intl.formatMessage({
            defaultMessage: 'Agregar pago',
            id: '1524AK',
          })}
        </Button>
      </FlexContainer>

      <Controller
        control={control}
        name="providerContacts"
        render={({ field }) => (
          <MultiEmailSelect
            label={intl.formatMessage({
              defaultMessage: 'Notifica',
              id: 'lvG6gG',
            })}
            options={options}
            fullWidth
            placeholder={intl.formatMessage({
              defaultMessage: 'Selecciona los correos a notificar',
              id: 'fC1RGL',
            })}
            {...field}
          />
        )}
      />
      <Spacer />
      <FlexContainer justifyContent="flex-end">
        <Button onClick={handleCancelClick} variant="ghosted">
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button
          type="submit"
          loading={loading}
          disabled={
            pendingBalanceCents < 0 ||
            pendingBalanceCents === invoice.outstandingBalance
          }
        >
          {intl.formatMessage({
            defaultMessage: 'Confirmar',
            id: '+/MNWw',
          })}
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

EnterManualPayment.displayName = 'EnterManualPayment';

export default EnterManualPayment;
