import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import * as S from './UpdateSupplierModal.styles';
import { Header, Button, TextField, Divider, Label } from '@src/components/ui';
import { UPDATE_SUPPLIER as UPDATE_SUPPLIER_EVENT } from '@src/constants/events';
import useSettings from '@src/hooks/useSettings';
import useToast from '@src/hooks/useToast';
import { trackEvent } from '@src/lib/analytics';
import { UPDATE_SUPPLIER } from '@src/graphql/mutations';
import { SUPPLIER_RELATIONSHIP_QUERY_NAME } from '@src/graphql/queries/supplierRelationship';

import { Spacer } from '@src/components/shared/layouts';

type UpdateSupplierModalProps = {
  initialValues?: {
    supplierBusinessName?: string;
    taxPayerName?: string;
    taxPayerId?: string;
    contactEmail?: string;
    bankingInfoId?: string;
    bankId?: string;
    bank?: string;
    clabe?: string;
    ownerName?: string;
    paymentTerms?: number;
  };
  isGeneralInformation?: boolean;
  invoiceId?: string;
  taxpayerId: string;
  taxPayerName: string;
  taxPayerIdentifier: string;
  businessRelationshipId?: string;
  onClose?: () => void;
};

export const eventData = {
  creator: 'buyer',
  view: 'UpdateSupplierModal',
};

export function UpdateSupplierModal({
  onClose,
  initialValues = {},
  taxpayerId,
  businessRelationshipId,
  isGeneralInformation = true,
}: UpdateSupplierModalProps) {
  const intl = useIntl();
  const { toast } = useToast();
  const business = useSettings('business');
  const [rfc, setRfc] = useState(initialValues.taxPayerId);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      supplierBusinessName: initialValues?.supplierBusinessName,
      taxPayerName: initialValues?.taxPayerName,
      taxPayerId: initialValues?.taxPayerId,
      paymentTerms: initialValues?.paymentTerms,
    },
  });
  const [updateSupplier, { loading }] = useMutation(UPDATE_SUPPLIER, {
    refetchQueries: [SUPPLIER_RELATIONSHIP_QUERY_NAME],
  });

  const onSubmit = async (data) => {
    const supplierBusinessName = data.supplierBusinessName;
    const taxpayerData = isGeneralInformation
      ? {
          id: taxpayerId,
          taxpayerName: data.taxPayerName,
          taxpayerIdentifier: data.taxPayerId.trim(),
        }
      : undefined;

    const paymentTerms = !isGeneralInformation
      ? parseInt(data.paymentTerms)
      : undefined;

    if (business && businessRelationshipId) {
      try {
        await updateSupplier({
          variables: {
            businessId: business.id,
            businessRelationshipId,
            supplierBusinessName,
            businessRelationship: {
              paymentTerms,
            },
            taxPayerInfo: taxpayerData,
          },
        });

        onClose && onClose();

        trackEvent(UPDATE_SUPPLIER_EVENT, eventData);

        toast.success(
          intl.formatMessage({
            defaultMessage: 'Proveedor editado',
            id: 'rsolBX',
          }),
          intl.formatMessage({
            defaultMessage: 'Se ha editado al proveedor con éxito',
            id: 'Zg9J/l',
          })
        );
      } catch (e) {
        toast.error(
          intl.formatMessage({
            defaultMessage: 'Error al editar proveedor',
            id: 'lEBDsY',
          }),
          e.message
        );
      }
    }
  };

  return (
    <S.Wrapper>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Editar proveedor',
          id: 'VmTG2Q',
        })}
      </Header>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        {isGeneralInformation && (
          <>
            <Label strong size="lg">
              {intl.formatMessage({
                defaultMessage: 'General',
                id: '1iEPTM',
              })}
            </Label>
            <Spacer margin="4" />
            <TextField
              fullWidth
              required
              {...register('supplierBusinessName', {
                required: intl.formatMessage({
                  defaultMessage: 'Campo requerido',
                  id: '7Vvfe3',
                }),
              })}
              label={intl.formatMessage({
                defaultMessage: 'Nombre',
                id: 'hCOqfl',
              })}
              placeholder={intl.formatMessage({
                defaultMessage: 'Nombre del negocio',
                id: 'RAp/Zt',
              })}
              error={!!errors?.supplierBusinessName?.message}
              helperText={errors?.supplierBusinessName?.message}
            />
            <TextField
              fullWidth
              required
              {...register('taxPayerName', {
                required: intl.formatMessage({
                  defaultMessage: 'Campo requerido',
                  id: '7Vvfe3',
                }),
              })}
              label={intl.formatMessage({
                defaultMessage: 'Razón social',
                id: 'pmiu07',
              })}
              placeholder={intl.formatMessage({
                defaultMessage: 'Nombre',
                id: 'hCOqfl',
              })}
              error={!!errors?.taxPayerName?.message}
              helperText={errors?.taxPayerName?.message}
            />
            <TextField
              fullWidth
              required
              {...register('taxPayerId', {
                required: intl.formatMessage({
                  defaultMessage: 'Campo requerido',
                  id: '7Vvfe3',
                }),
                maxLength: {
                  value: 13,
                  message: intl.formatMessage({
                    defaultMessage:
                      'Debe contener hasta 13 caracteres alfanuméricos.',
                    id: 'g7O2+a',
                  }),
                },
                minLength: {
                  value: 12,
                  message: intl.formatMessage({
                    defaultMessage:
                      'Debe contener mínimo 12 caracteres alfanuméricos.',
                    id: '2vKU/U',
                  }),
                },
                pattern: {
                  value: /[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}/,
                  message: intl.formatMessage({
                    defaultMessage: 'No cumple con el formato correcto',
                    id: '1yqXVx',
                  }),
                },
                setValueAs: (value) => {
                  setRfc(value.toUpperCase());
                  return value.toUpperCase();
                },
              })}
              value={rfc}
              type="text"
              label={intl.formatMessage({
                defaultMessage: 'RFC',
                id: 'wvCzn7',
              })}
              placeholder="ABCDE12345"
              error={!!errors?.taxPayerId?.message}
              helperText={errors?.taxPayerId?.message}
            />

            <Divider direction="column" />
          </>
        )}

        {!isGeneralInformation && (
          <>
            <Label strong size="lg">
              {intl.formatMessage({
                defaultMessage: 'Relacion comercial',
                id: 'mIKsy+',
              })}
            </Label>

            <Spacer margin="4" />

            <TextField
              fullWidth
              required
              {...register('paymentTerms', {
                required: intl.formatMessage({
                  defaultMessage: 'Campo requerido',
                  id: '7Vvfe3',
                }),
              })}
              type="number"
              label={intl.formatMessage({
                defaultMessage: 'Días de credito',
                id: 'UXeb6W',
              })}
              placeholder="30"
              error={!!errors?.paymentTerms?.message}
              helperText={errors?.paymentTerms?.message}
            />
          </>
        )}

        <S.Actions>
          <Button variant="outlined" color="neutral" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: 'Cancelar',
              id: 'nZLeaQ',
            })}
          </Button>
          <Button type="submit" loading={loading}>
            {intl.formatMessage({
              defaultMessage: 'Guardar',
              id: 'oN4aGu',
            })}
          </Button>
        </S.Actions>
      </S.Form>
    </S.Wrapper>
  );
}

UpdateSupplierModal.displayName = 'UpdateSupplierModal';

export default UpdateSupplierModal;
