import React from 'react';
import { useWatch, Control } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from '@src/components/ui/Button';
import useBalance from '@src/hooks/useBalance';
import { Currency } from '@src/components/i18n/Currency';

import { ButtonProps } from '@src/components/ui/Button/types';
import { stringToCents } from '@src/utils/currency';

interface PaymentsTotalButtonContainerProps extends ButtonProps {
  control: Control<any>;
  name: string;
  totalProp: string;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  wallet?: 'credit' | 'balance';
}

interface PaymentsTotalButtonProps extends ButtonProps {
  totalCents: number;
  balanceCents: number;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function PaymentsTotalButton({
  totalCents,
  balanceCents,
  loading,
  disabled,
  children,
}: PaymentsTotalButtonProps) {
  const intl = useIntl();
  const insufficientBalance = (balanceCents || 0) < totalCents;

  return (
    <Button
      type="submit"
      disabled={insufficientBalance || disabled}
      loading={loading}
    >
      {children || (
        <>
          {intl.formatMessage({
            defaultMessage: 'Pagar',
            id: 'KFphJU',
          })}{' '}
          <Currency value={totalCents} maximumFractionDigits={2} />
        </>
      )}
    </Button>
  );
}

function PaymentsTotalButtonContainer({
  control,
  loading,
  disabled,
  name,
  totalProp,
  children,
  wallet,
}: PaymentsTotalButtonContainerProps) {
  const { creditCents, walletCents } = useBalance();
  const invoices = useWatch({
    control,
    name,
  });
  const total = invoices.reduce(
    (previousValue, invoice) => previousValue + (invoice[totalProp] || 0),
    0
  );
  return (
    <PaymentsTotalButton
      balanceCents={(wallet === 'credit' ? creditCents : walletCents) || 0}
      totalCents={stringToCents(total)}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </PaymentsTotalButton>
  );
}

export default PaymentsTotalButtonContainer;
