import { gql } from '@apollo/client';

export const BANKS_QUERY = gql`
  query BanksData($after: String, $before: String, $first: Int, $last: Int) {
    banks(after: $after, before: $before, first: $first, last: $last) {
      edges {
        node {
          id
          name
          code
          logoUrl
          supported
        }
      }
    }
  }
`;
