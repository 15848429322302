import React, { ReactNode } from 'react';

import * as S from './Breadcrumb.styles';
import { ChevronLeft } from '@src/components/ui/Icon/outline';
import { Text } from '@src/components/ui';
import { FlexContainer } from '../layouts';

type Url = { name: ReactNode; url: string };
interface BreadcrumbProps {
  links: Array<Url>;
}

function Breadcrumb({ links = [] }: BreadcrumbProps) {
  return (
    <FlexContainer flexDirection="column">
      {links.map((link) => (
        <S.Breadcrumb key={`${link.url}-${link.name}`}>
          <S.BreadcrumbLink to={link.url}>
            <ChevronLeft size={16} />
            <Text>{link.name}</Text>
          </S.BreadcrumbLink>
        </S.Breadcrumb>
      ))}
    </FlexContainer>
  );
}

export default Breadcrumb;
