import { gql } from '@apollo/client';

export const REQUEST_INVOICE_FINANCING = gql`
  mutation RequestInvoiceFinancing(
    $businessId: ID!
    $creditDays: Int!
    $invoicesPaymentsDetails: [FinancingRequestInvoiceInputType!]!
  ) {
    requestInvoiceFinancing(
      businessId: $businessId
      creditDays: $creditDays
      invoicesPaymentsDetails: $invoicesPaymentsDetails
    ) {
      id
    }
  }
`;

export type FinancingRequestInvoiceInputType = {
  invoiceId: string;
  amountCents: number;
  bankingInfoId?: string;
  email?: string;
  paymentConcept?: string;
};

export type FinancingRequestInvoiceType = {
  id?: string;
  amountCents?: number;
  bankingInfoId?: string;
  email?: string;
  paymentConcept?: string;
};

export type FinancingRequestInvoiceParams = {
  businessId: string;
  creditDays: number;
  invoicesPaymentsDetails: Array<FinancingRequestInvoiceInputType>;
};

export type FinancingRequestInvoiceReturn = Array<
  Pick<FinancingRequestInvoiceType, 'id'>
>;
