import React from 'react';
import { useIntl } from 'react-intl';

import CreateBusinessForm from './components/CreateBusinessForm';
import SignInLink from './components/SignInLink';
import usePageTrack from '@src/hooks/usePageTrack';
import OnboardingLayout from '@src/components/layouts/OnboardingLayout';
import Header from '@src/components/ui/Header';

export function CreateBusiness() {
  usePageTrack('business_registration', 'sign_up');
  const intl = useIntl();

  return (
    <OnboardingLayout
      step={2}
      title={intl.formatMessage({
        defaultMessage: 'Crear una nueva empresa',
        id: '3CVZtR',
      })}
    >
      <Header as="h2">
        {intl.formatMessage({
          defaultMessage: 'Información de tu empresa',
          id: 'Ou0dFV',
        })}
      </Header>
      <p>
        {intl.formatMessage({
          defaultMessage:
            'Completa la informacion de tu empresa para continuar.',
          id: 'zyk0xz',
        })}
      </p>

      <CreateBusinessForm />
      <SignInLink />
    </OnboardingLayout>
  );
}

export default CreateBusiness;
