import React from 'react';
import { useIntl } from 'react-intl';

import CreditTermsForm from '@src/components/pages/RequestFinancing/components/CreditTermsForm';
import Modal from '@src/components/shared/Modal';
import Page from '@src/components/shared/Page';
import RequestFinancingForm from './components/RequestFinancingForm';
import SupplierBankingInformationModal from '@src/components/shared/modals/SupplierBankingInformationModal';

function RequestFinancing() {
  const intl = useIntl();

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Solicita financiamiento',
        id: 'Wri3Bg',
      })}
    >
      <RequestFinancingForm />
      <Modal component={CreditTermsForm} />
      <Modal component={SupplierBankingInformationModal} />
    </Page>
  );
}

export default RequestFinancing;
