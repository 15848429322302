import { gql } from '@apollo/client';

import { FinancingRequest } from '@src/types/models';

export const CREATE_FINANCING_REQUEST_REPAYMENT_MUTATION = gql`
  mutation CreateFinancingRequestRepayment(
    $businessId: ID!
    $financingRequestRepayment: FinancingRequestRepaymentInput!
  ) {
    createFinancingRequestRepayment(
      businessId: $businessId
      financingRequestRepayment: $financingRequestRepayment
    ) {
      financingRequestRepayments {
        id
        financingRequest {
          id
        }
      }
    }
  }
`;

export type CreateFinancingRequestRepaymentVariables = {
  businessId: string;
  financingRequestRepayment: {
    financingRequestId: string;
    amountCents: number;
    interestAmountCents?: number;
  };
};

export type CreateFinancingRequestRepaymentReturn = {
  createFinancingRequestRepayment: {
    financingRequestRepayment: {
      id: string;
      financingRequest: FinancingRequest[];
    };
  };
};

export default CREATE_FINANCING_REQUEST_REPAYMENT_MUTATION;
