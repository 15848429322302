import { defineMessages } from 'react-intl';

export default defineMessages({
  error: {
    defaultMessage: 'Error al cargar los datos',
    id: 'yM0cd/',
  },
  pageTitle: {
    defaultMessage: 'Mis proveedores',
    id: 'Qsywsv',
  },
  pendingValue: {
    defaultMessage: 'Pendiente',
    id: 'uZ+xW0',
  },
  clientsError: {
    defaultMessage: 'Error al cargar los datos',
    id: 'yM0cd/',
  },
  clientsPageTitle: {
    defaultMessage: 'Clientes',
    id: 'zBnTsv',
  },
  inviteClientsAction: {
    defaultMessage: 'Invitar cliente',
    id: 'IMezkt',
  },
  activeClientsSubtitle: {
    defaultMessage: 'Activo',
    id: '/61g0d',
  },
  pendingClientsSubtitle: {
    defaultMessage: 'Pendiente',
    id: 'uZ+xW0',
  },
  suppliersEmptyState: {
    defaultMessage: 'No se encontraron proveedores',
    id: 'SfXAhx',
  },
  clientSearchPlaceholder: {
    defaultMessage: 'Buscar cliente',
    id: 'CWdjTn',
  },
  supplierSearchPlaceholder: {
    defaultMessage: 'Buscar proveedor',
    id: 'CPP5LK',
  },
  suppliersEmptyFeaturedTitle: {
    defaultMessage: 'No tienes proveedores destacados',
    id: 'USSKdq',
  },
  suppliersEmptyFeaturedSubtitle: {
    defaultMessage:
      'Aquí verás todos tus proveedores que marques como destacado.',
    id: 'n/xXmQ',
  },
});
