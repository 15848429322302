import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import useQueryParams from '@src/hooks/useQueryParams';
import Modal from '@src/components/shared/Modal';
import ConfirmationContract from './components/ConfirmationContractModal';
import RequestFactoring from './RequestFactoring';
import { REQUEST_FACTORING_STEP } from './types';
import useModal from '@src/hooks/useModal';
import FactoringSuccessModal from './components/FactoringSuccessModal';
import useToast from '@src/hooks/useToast';
import { useMutation, useQuery } from '@apollo/client';
import FACTORING_OFFERS_QUERY, {
  FactoringOffersQueryReturn,
  FactoringOffersQueryVariables,
  FactoringOfferStatusEnum,
} from '@src/graphql/queries/factoringOffers';
import useSettings from '@src/hooks/useSettings';
import {
  RequestFactoringQueryReturn,
  RequestFactoringQueryVariables,
  REQUEST_FACTORING,
} from '@src/graphql/mutations/requestFactoring';
import { FeatureName } from '@src/types/enums';
import { hasBusinessFeature } from '@src/utils/features';

function RequestFactoringContainer() {
  const history = useHistory();
  const intl = useIntl();

  const { business } = useSettings();
  const hasFactoringFeature = hasBusinessFeature(
    [FeatureName.FACTORING],
    business
  );
  const [{ factoringOfferIds }] = useQueryParams();

  const { toast } = useToast();
  const { open: openFactoringSuccessModal } = useModal(FactoringSuccessModal);

  const [view, setView] = useState<REQUEST_FACTORING_STEP>(
    REQUEST_FACTORING_STEP.VIEW_INVOICES
  );

  const [requestFactoring] = useMutation<
    RequestFactoringQueryReturn,
    RequestFactoringQueryVariables
  >(REQUEST_FACTORING);

  const { data } = useQuery<
    FactoringOffersQueryReturn,
    FactoringOffersQueryVariables
  >(FACTORING_OFFERS_QUERY, {
    variables: {
      businessId: business?.id,
      factoringOffersIds: factoringOfferIds,
      status: FactoringOfferStatusEnum.AVAILABLE,
    },
    skip: !business?.id || !factoringOfferIds,
  });

  const handleSubmit = async (selectedInvoices, amount) => {
    if (!business?.id) {
      toast.error(
        intl.formatMessage({
          defaultMessage: 'No se encontró Business',
          id: 'cZbdw4',
        })
      );
      return;
    }

    setView(REQUEST_FACTORING_STEP.SUBMITTING_FACTORING);

    try {
      await requestFactoring({
        variables: {
          businessId: business?.id,
          factoringOffersIds: selectedInvoices.map((i) => i.id),
        },
      });

      openFactoringSuccessModal({ amount });
      history.push('/wallet');
    } catch (error) {
      setView(REQUEST_FACTORING_STEP.VIEW_INVOICES);
      toast.error(
        error.message ||
          intl.formatMessage({
            defaultMessage: 'Error al solicitar factoring',
            id: 'NVvfJb',
          })
      );
    }
  };

  if (!hasFactoringFeature) {
    history.push('/');
    return null;
  }

  if (!factoringOfferIds || factoringOfferIds.length === 0) {
    history.push('/');
    toast.info(
      intl.formatMessage({
        defaultMessage: 'No tienes ofertas disponibles para factoring',
        id: '/5eYdu',
      })
    );
    return null;
  }

  if (!data?.factoringOffers) {
    return null;
  }

  return (
    <>
      <RequestFactoring
        onSubmit={handleSubmit}
        view={view}
        factoringOffers={data.factoringOffers}
      />
      <Modal component={ConfirmationContract} />
    </>
  );
}

export default RequestFactoringContainer;
