import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

const merge = (_, incoming) => {
  return incoming;
};

const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      keyFields: ['hashId'],
    },
    Query: {
      fields: {
        codatCompany: { keyArgs: ['businessId'] },
        businessRelationshipsAsClient: relayStylePagination(['businessId']),
        financingRequests: {
          keyArgs: [
            'businessId',
            'creditAccountId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
          merge,
        },
        invoiceStatusCount: relayStylePagination(['businessId']),
        providers: relayStylePagination(['businessId']),
        supplierRelationship: relayStylePagination([
          'businessId',
          'supplierHashId',
        ]),
        wallet: relayStylePagination(['businessId']),
        creditAccount: {
          keyArgs: ['businessId'],
        },
        invoicesReceived: {
          keyArgs: [
            'approvalStatus',
            'globalStatus',
            'paymentStatus',
            'invoiceId',
            'archived',
            'expiryDateFrom',
            'expiryDateTo',
            'invoicingDateFrom',
            'invoicingDateTo',
            'paymentDateFrom',
            'paymentDateTo',
            'voucherType',
            'businessEmitterId',
            'sortBy',
            'before',
            'after',
            'fromActiveProvidersOnly',
          ],
          merge,
        },
        receivedCreditNotes: relayStylePagination([
          'businessId',
          'supplierHashId',
          'uncredited',
          'approvalStatus',
        ]),
        purchaseOrders: relayStylePagination(),
        invoicesEmitted: relayStylePagination(),
        clientRelationships: relayStylePagination([
          'businessId',
          'search',
          'before',
          'after',
        ]),
        salesOrders: relayStylePagination(),
        supplierProducts: relayStylePagination(),
        providerRelationships: {
          keyArgs: [
            'businessId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
          merge,
        },
        verifiedProviders: relayStylePagination(),
        walletEntries: {
          keyArgs: [
            'sortBy',
            'before',
            'after',
            'pending',
            'first',
            'last',
            'createdAtFrom',
            'createdAtTo',
            'status',
            'search',
          ],
          merge,
        },
        locations: relayStylePagination(),
        taxPayerInfos: relayStylePagination(['businessId']),
        businessUsers: relayStylePagination(['businessId', 'status']),
      },
    },
  },
  possibleTypes: {
    Invoiceable: ['SubscriptionStatement', 'Order'],
    CountryTaxPayerInfos: ['MexicanTaxPayerInfo'],
    Itemable: ['PriceListItem', 'SupplierProduct'],
    Eventable: [
      'CommentEvent',
      'CostCenterEvent',
      'InvoiceStatusEvent',
      'InvoiceManualPaymentEvent',
      'InvoiceSTPPaymentEvent',
    ],
    ProviderContact: ['User', 'Contact'],
    BusinessContact: ['Contact', 'User'],
    Entryable: [
      'DepositEntry',
      'InvoicePaymentEntry',
      'WithdrawalEntry',
      'RefundEntry',
      'BankingTransaction',
    ],
    Refundable: ['InvoicePayment', 'STPPayment', 'BankingTransaction'],
  },
  //@ts-ignore
  dataIdFromObject: (object) => {
    const _id = object.id || object.hashId;

    if (_id) {
      return `${object.__typename}:${_id}`;
    }

    return null;
  },
});

export default cache;
