import React from 'react';
import { useIntl } from 'react-intl';

import { ArrowDownLeft, ArrowUpRight } from '@src/components/ui/Icon/outline';
import * as S from './TransactionsIdentifier.styles';
import useTransaction from '@src/hooks/useTransaction';
import messages from '@src/translations/messages/transactions';

import type { WalletEntry } from '@src/types/models';

type TransactionsIdentifierProps = {
  walletEntry: WalletEntry;
  withLabel?: boolean;
};

function TransactionsIdentifier(props: TransactionsIdentifierProps) {
  const { walletEntry, withLabel = false } = props;
  const intl = useIntl();
  const { type, positive } = useTransaction(walletEntry);

  return (
    <S.IdentifierContainer withLabel={withLabel}>
      <S.IconColor name={positive ? 'refund' : 'payment'}>
        {positive ? <ArrowDownLeft /> : <ArrowUpRight />}
      </S.IconColor>
      {withLabel && type && <p>{intl.formatMessage(messages[type])}</p>}
    </S.IdentifierContainer>
  );
}

export default TransactionsIdentifier;
