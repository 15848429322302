import styled from 'styled-components';

export const Surface = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndex[40]};

  .full-screen-modal-body {
    flex-grow: 1;
    overflow-y: auto;
  }

  &.full-screen-modal-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  &.full-screen-modal-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
  }
  &.full-screen-modal-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  &.full-screen-modal-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }
  &.full-screen-modal-exit-active {
    transition: opacity 700ms, transform 700ms;
  }
`;
