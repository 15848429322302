import { gql } from '@apollo/client';
import { InvoicePaymentType } from '@src/types/enums';

const UPDATE_INVOICES_PAYMENTS_MUTATION = gql`
  mutation UpdateInvoicePayments(
    $businessId: ID!
    $invoiceId: ID!
    $providerContacts: [ProviderContactInput!]
    $invoicePayments: [InvoicePaymentInput!]!
  ) {
    updateInvoicePayments(
      businessId: $businessId
      invoiceId: $invoiceId
      providerContacts: $providerContacts
      invoicePayments: $invoicePayments
    ) {
      id
    }
  }
`;

export type ProviderContactInput = {
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
};

export type InvoicePaymentInput = {
  id?: string;
  amount?: number;
  paymentProof?: string;
  _destroy?: boolean;
  creditNoteId?: string;
  paymentType?: InvoicePaymentType;
};

export type UpdateInvoicePaymentsVariables = {
  businessId: string;
  invoiceId: string;
  invoicePayments: Array<InvoicePaymentInput>;
  providerContacts?: Array<ProviderContactInput>;
};

export type UpdateInvoicePaymentsReturn = {
  updateInvoicePayments: {
    id: string;
  };
};

export default UPDATE_INVOICES_PAYMENTS_MUTATION;
