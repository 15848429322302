import { ApolloLink, ApolloClient, HttpLink } from '@apollo/client';

import errorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';
import { API_URL, GRAPHQL_NAMESPACE } from '../api/utils';

const httpLink = new HttpLink({ uri: API_URL + GRAPHQL_NAMESPACE });

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
});

export default client;
