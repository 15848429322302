import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PROCESSING: {
    defaultMessage: 'Procesando',
    id: '4ujRn4',
  },
  PROCESSED: {
    defaultMessage: 'Procesado',
    id: 'dvMSHB',
  },
  FAILED: {
    defaultMessage: 'Fallo',
    id: 'L1wml9',
  },
  unclaimed: {
    defaultMessage: 'Pendiente de confirmación',
    id: 'Q9zkv+',
  },
});

export default messages;
