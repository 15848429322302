import { useMutation } from '@apollo/client';

import { INVOICES_RECEIVED_QUERY_NAME } from '@src/graphql/queries';
import { UPDATE_INVOICE_MAILBOX_STATUS } from '@src/graphql/mutations';

import type { Invoice } from '@src/types/models';
import type { InvoiceMailboxStatus } from '@src/types/enums';

export type InvoiceUpdateStatusParameters = {
  businessId: string;
  invoiceId: string;
  mailboxStatus: keyof typeof InvoiceMailboxStatus;
};

export type InvoiceUpdateStatusResponse = {
  updateInvoiceMailboxStatus: Invoice[];
};

function useUpdateInvoiceMailboxStatusMutation() {
  const [mutate, { data, loading, error }] = useMutation<
    InvoiceUpdateStatusResponse,
    InvoiceUpdateStatusParameters
  >(UPDATE_INVOICE_MAILBOX_STATUS, {
    refetchQueries: [INVOICES_RECEIVED_QUERY_NAME],
  });

  return { mutate, data: data?.updateInvoiceMailboxStatus, loading, error };
}

export default useUpdateInvoiceMailboxStatusMutation;
