import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Card from '@src/components/ui/Card';
import Filters from '@src/components/shared/Filters';
import useSettings from '@src/hooks/useSettings';
import useInvoiceFilters from '@src/hooks/useInvoiceFilters';
import useInvoicesListQuery from '@src/hooks/useInvoicesListQuery';
import InvoicesTable, {
  InvoiceActionDropdown,
} from '@src/components/shared/InvoicesTable';
import Permission from '@src/components/shared/Permission';
import {
  PermissionName,
  InvoiceApprovalStatus,
  InvoicePaymentStatus,
  InvoiceGlobalStatus,
  UserRole,
} from '@src/types/enums';
import { getPaginationValuesForQuery } from '@src/utils/pagination';
import { useMemo } from 'react';
import EmptyResults from '@src/components/shared/EmptyResults';
import LinkButton from '@src/components/shared/buttons/LinkButton';
import { trackEvent } from '@src/lib/analytics';
import {
  INVOICE_PAY_CLICKED,
  INVOICES_FOR_PAYMENT_INSTRUCTION_SELECTED,
} from '@src/constants/events';
import useCreditAccount from '@src/hooks/useCreditAccount';
import useFeatureFlag, { FeatureFlag } from '@src/hooks/useFeatureFlag';

const TableCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing[8]};
`;

const ToPayInvoicesTable = () => {
  const limit = 50;
  const intl = useIntl();
  const { business, currentUser } = useSettings();
  const [creditAccount] = useCreditAccount();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filters, setFilters] = useInvoiceFilters({
    approvalStatus: [InvoiceApprovalStatus.APPROVED],
    paymentStatus: [InvoicePaymentStatus.PENDING, InvoicePaymentStatus.PARTIAL],
    archived: false,
  });
  const hasAutomaticFinancingFunds = useFeatureFlag(
    FeatureFlag.AUTOMATIC_FINANCING_FUND
  );
  const {
    loading,
    data: { invoices, totalAmount },
    count,
    next,
    hasNext,
    previous,
    hasPrevious,
    page,
    nodes,
  } = useInvoicesListQuery({
    ...filters,
    ...getPaginationValuesForQuery(filters, limit),
  });

  const currentUserIsCollaborator = currentUser?.role === UserRole.COLABORATOR;

  const anyFinanced = useMemo(
    () =>
      invoices.some(
        (invoice) =>
          selectedIds.includes(invoice.id) &&
          invoice.globalStatus === InvoiceGlobalStatus.FINANCING_REQUESTED
      ),
    [selectedIds, invoices]
  );

  useEffect(() => {
    // Default sort
    setFilters({ sort: 'expiryDate', sortDirection: 'ascending' });
  }, []);

  const hasCreditAccount = !!creditAccount?.active;

  const clearSelection = () => {
    setSelectedIds([]);
  };

  const handlePayClick = () => {
    if (currentUserIsCollaborator) {
      trackEvent(INVOICES_FOR_PAYMENT_INSTRUCTION_SELECTED, {
        invoice_ids: selectedIds,
      });
    } else {
      trackEvent(INVOICE_PAY_CLICKED, { count });
    }
  };

  return (
    <TableCard>
      <Filters
        fields={[
          'invoicingDate',
          'expiryDate',
          'businessEmitterId',
          'globalStatus',
        ]}
        globalStatusOptions={[
          'pendingPayment',
          'expired',
          'financingRequested',
        ]}
      />

      <InvoicesTable
        columns={{
          id: 100,
          receiverCountryTaxPayerInfo: 100,
          invoicingDate: 200,
          businessEmitter: 300,
          globalStatus: 200,
          outstandingBalance: 120,
          expiryDate: 200,
          paymentStatus: 100,
        }}
        nodes={nodes}
        invoices={invoices}
        loading={loading}
        showActionBar
        selectedIds={selectedIds}
        onSelect={setSelectedIds}
        onNext={next}
        hasNext={hasNext}
        onPrevious={previous}
        hasPrevious={hasPrevious}
        selectable
        limit={50}
        selectionLimit={200}
        page={page}
        count={count}
        totalAmount={totalAmount}
        emptyElement={
          <EmptyResults
            label={intl.formatMessage({
              defaultMessage: 'Aún no tienes cuentas por pagar',
              id: 'DDj4bg',
            })}
            description={intl.formatMessage({
              defaultMessage:
                'Aquí verás todas las cuentas por pagar que tengas en Higo.',
              id: 'BH7PdI',
            })}
          />
        }
        actions={
          <>
            <InvoiceActionDropdown
              invoiceIds={selectedIds}
              count={count}
              businessId={business?.id}
              userEmail={currentUser?.email}
              archivable
              payable
              onMarkAsPaid={clearSelection}
              onArchive={clearSelection}
            />
            {!hasAutomaticFinancingFunds && hasCreditAccount && !anyFinanced && (
              <Permission permissions={[PermissionName.MANAGE_PAYMENTS]}>
                <LinkButton
                  href={`/request-financing?invoiceIds=${selectedIds.join(
                    ','
                  )}`}
                  variant="outlined"
                  data-testid="request-financing-submit"
                >
                  {intl.formatMessage({
                    defaultMessage: 'Solicitar financiamiento',
                    id: 'vnyh9H',
                  })}
                </LinkButton>
              </Permission>
            )}
            <Permission permissions={[PermissionName.MANAGE_PAYMENTS]}>
              <LinkButton
                onClick={handlePayClick}
                href={`/invoice-payments?invoiceIds=${selectedIds.join(',')}`}
              >
                {intl.formatMessage({
                  defaultMessage: 'Revisar y pagar',
                  id: 'cUuhwH',
                })}
              </LinkButton>
            </Permission>
            {currentUserIsCollaborator && (
              <LinkButton
                onClick={handlePayClick}
                href={`/invoice-payments?invoiceIds=${selectedIds.join(',')}`}
              >
                {intl.formatMessage({
                  defaultMessage: 'Solicitar aprobación de pago',
                  id: 'S3KdQW',
                })}
              </LinkButton>
            )}
          </>
        }
      />
    </TableCard>
  );
};

export default ToPayInvoicesTable;
