import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const Smile: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill={color}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 0C8.08359 0.00316456 5.28753 1.16687 3.22531 3.23577C1.16309 5.30468 0.00315432 8.10982 0 11.0357C0.55 25.658 21.45 25.6516 22 11.0357C21.9968 8.10982 20.8369 5.30468 18.7747 3.23577C16.7125 1.16687 13.9164 0.00316456 11 0V0ZM11 20.2321C8.56967 20.2294 6.23964 19.2597 4.52114 17.5356C2.80263 15.8115 1.836 13.4739 1.83333 11.0357C2.29167 -1.14771 19.7083 -1.14403 20.1667 11.0357C20.164 13.4739 19.1974 15.8115 17.4789 17.5356C15.7604 19.2597 13.4303 20.2294 11 20.2321ZM6.41667 8.73659C6.41667 8.37074 6.56153 8.01986 6.81939 7.76116C7.07726 7.50246 7.42699 7.35713 7.79167 7.35713C8.15634 7.35713 8.50608 7.50246 8.76394 7.76116C9.0218 8.01986 9.16667 8.37074 9.16667 8.73659C9.16667 9.10245 9.0218 9.45332 8.76394 9.71202C8.50608 9.97072 8.15634 10.1161 7.79167 10.1161C7.42699 10.1161 7.07726 9.97072 6.81939 9.71202C6.56153 9.45332 6.41667 9.10245 6.41667 8.73659ZM12.8333 8.73659C12.8333 8.37074 12.9782 8.01986 13.2361 7.76116C13.4939 7.50246 13.8437 7.35713 14.2083 7.35713C14.573 7.35713 14.9227 7.50246 15.1806 7.76116C15.4385 8.01986 15.5833 8.37074 15.5833 8.73659C15.5833 9.10245 15.4385 9.45332 15.1806 9.71202C14.9227 9.97072 14.573 10.1161 14.2083 10.1161C13.8437 10.1161 13.4939 9.97072 13.2361 9.71202C12.9782 9.45332 12.8333 9.10245 12.8333 8.73659ZM16.3552 14.2931C15.8166 15.2457 15.0389 16.0404 14.0995 16.5983C13.1601 17.1561 12.0916 17.4577 11 17.4732C9.90769 17.4579 8.83833 17.1564 7.89801 16.5985C6.95768 16.0407 6.17905 15.2459 5.63933 14.2931C5.54188 14.1404 5.49154 13.9623 5.49466 13.7811C5.49777 13.5999 5.55419 13.4236 5.65683 13.2745C5.75947 13.1253 5.90376 13.0099 6.07156 12.9428C6.23937 12.8756 6.42321 12.8597 6.6 12.897C8.00954 13.4298 9.495 13.7328 11 13.7946C12.5025 13.7321 13.9854 13.4291 15.3927 12.897C15.5696 12.8592 15.7537 12.8748 15.9218 12.9418C16.09 13.0087 16.2346 13.1241 16.3375 13.2734C16.4404 13.4226 16.497 13.5991 16.5001 13.7805C16.5033 13.962 16.4528 14.1403 16.3552 14.2931Z"
          fill={color}
        />
      </svg>
    );
  }
);

Smile.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Smile.displayName = 'Smile';

export default Smile;
