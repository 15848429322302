import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-feather';

const InvoiceToPay: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 5.99999 1H14C14.2652 1 14.5195 1.10536 14.7071 1.29289L20.7071 7.29288C20.8946 7.48041 21 7.73477 21 7.99998V11.9998H11.4145L13.7071 9.70711C14.0976 9.31658 14.0976 8.68342 13.7071 8.29289C13.3166 7.90237 12.6834 7.90237 12.2929 8.29289L8.58603 11.9998H8.5V12.0858L8.29289 12.2929C7.90237 12.6834 7.90237 13.3166 8.29289 13.7071L8.5 13.9142V13.9998H8.58554L12.2929 17.7071C12.6834 18.0976 13.3166 18.0976 13.7071 17.7071C14.0976 17.3166 14.0976 16.6834 13.7071 16.2929L11.414 13.9998H21V19.9999C21 20.7956 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7956 22.9999 18 22.9999H5.99999C5.20434 22.9999 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7956 3 19.9999V3.99999C3 3.20435 3.31607 2.44129 3.87868 1.87868Z" />
      </svg>
    );
  }
);

InvoiceToPay.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InvoiceToPay.displayName = 'InvoiceToPay';

export default InvoiceToPay;
