import { gql } from '@apollo/client';
import USER_PROFILE_ATTRIBUTES, {
  UserProfileAttributes,
} from '@src/graphql/fragments/userProfileAttributes';

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($user: UpdateUserInput!) {
    updateUserProfile(user: $user) {
      ...UserProfile
    }
  }
  ${USER_PROFILE_ATTRIBUTES}
`;

export type UpdateUserInput = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
};

export type UpdateUserProfileVariables = {
  user: UpdateUserInput;
};

export type UpdateUserProfileReturn = {
  updateUserProfile: UserProfileAttributes;
};
