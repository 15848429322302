import styled from 'styled-components';

type TableFilterProps = {
  rotate?: boolean;
  active?: boolean;
};

const TableFilter = styled.div<TableFilterProps>`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing[4]};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.neutral[900] : 'transparent'};
  width: ${({ theme }) => theme.spacing[6]};
  height: ${({ theme }) => theme.spacing[6]};
  border-radius: 50%;
  transform: ${({ rotate }) => rotate && 'rotate(180deg)'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: ${({ theme, active }) =>
    active ? theme.colors.neutral[50] : theme.colors.neutral[500]};
`;

export default TableFilter;
