import styled from 'styled-components';

import { StyledInput } from '@src/components/ui/Input/Input.styles';
import Avatar from '@src/components/ui/Avatar';

export const SupplierContainer = styled.div<{
  loading?: boolean;
  clickable?: boolean;
}>`
  border-radius: ${({ theme }) => theme.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[200]};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  transition: border-color 200ms ease-in;
  cursor: ${({ clickable }) => clickable && 'pointer'};
  height: ${({ theme }) => theme.spacing[28]};
  border: ${({ loading, clickable }) => (loading || !clickable) && 'none'};
  background-color: ${({ theme }) => theme.colors.neutral[50]};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.neutral[300]};
    border: ${({ loading, clickable }) => (loading || !clickable) && 'none'};
  }
`;

export const SupplierContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing[4]};
  align-items: center;
`;

export const SupplierAvatar = styled(Avatar)`
  flex-shrink: 0;
`;

export const PendingValue = styled.p<{ expiredTotal?: boolean }>`
  color: ${({ expiredTotal, theme }) =>
    expiredTotal && theme.colors.danger[500]};
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Actions = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  ${StyledInput} {
    height: ${({ theme }) => theme.spacing[11]};
  }
`;

export const SearchContainer = styled.div`
  flex-grow: 1;
  margin-right: ${({ theme }) => theme.spacing[4]};
`;

export const SupplierInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 ${({ theme }) => theme.spacing[8]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SupplierCredit = styled.div`
  flex-basis: 20%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing[4]};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const InformationLabel = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const SupplierName = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.neutral[900]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SupplierEmail = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  word-break: break-all;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const Actionable = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing[8]};
`;
