import intersection from 'lodash/intersection';
import { FeatureName } from '@src/types/enums';
import { CurrentUser, Business, Feature } from '@src/types/models';

export function hasFeature(
  requiredFeatures: Array<keyof typeof FeatureName>,
  currentUser?: CurrentUser
) {
  if (!currentUser) {
    return false;
  }

  const { features = [] } = currentUser;

  if (!features.length) {
    return false;
  }

  const userFeatures = features.map((feature: Feature) =>
    feature.name.toUpperCase()
  );

  const featureJoin = intersection(userFeatures, requiredFeatures);
  return featureJoin.length > 0;
}

export function hasBusinessFeature(
  requiredFeatures: Array<keyof typeof FeatureName>,
  currentBusiness?: Business
) {
  if (!currentBusiness) {
    return false;
  }

  const businessFeatures = currentBusiness?.businessFeatures?.map(
    (businessFeature) => businessFeature.feature.name
  );
  const matchedFeatures = intersection(businessFeatures, requiredFeatures);

  return matchedFeatures.length == requiredFeatures.length;
}
