import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Breadcrumb = styled.div`
  margin-right: ${({ theme }) => theme.spacing['3.5']};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const BreadcrumbLink = styled(Link)`
  display: flex;
  align-items: center;
`;
