import styled from 'styled-components';
import CardUI from '@src/components/ui/Card';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: ${({ theme }) => theme.fontSize.lg};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const Card = styled(CardUI)`
  min-height: ${({ theme }) => theme.spacing[80]};
`;
