import styled from 'styled-components';
import Card from '@src/components/ui/Card';

export const StyledCard = styled(Card)`
  position: relative;
  h5 {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
  p {
    color: ${({ theme }) => theme.colors.primary[300]};
  }

  .bottom-art {
    position: absolute;
    width: ${({ theme }) => theme.spacing[44]};
    height: ${({ theme }) => theme.spacing[19]};
    right: 0;
    bottom: 0;
  }
`;
